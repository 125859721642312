import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'firstWord'})
export class FirstWordPipe implements PipeTransform {
  transform(value: any, format?: any): string {
    if (value === null || value === undefined) {
      return '';
    }

    return value.split(format)[0];
  }
}
