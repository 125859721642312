export class surveyPeriod {
    id: number;
    periodName: string;
    period: string;
    isActive: boolean;  
    constructor(){

    }
    // constructor(id:number,periodName:string,period:string,isActive:boolean) {
    //     this.id=id;
    //     this.periodName=periodName;
    //     this.period=period;
    //     this.isActive=isActive;
    // }
    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            periodName: modelData['periodName'],
            id: modelData['id'],
            period: modelData['period'],
            isActive: modelData['isActive'],
        });
        return this;
    }
}

export class surveyPeriodList{
    surveyperiods: Array<surveyPeriod> = [];
    public uiMapper(modelData: any) {
        Object.assign(this, {
            surveyperiods: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new surveyPeriod().uiMapper(data));
                        }) : []
        });

        return this;
    }
    
}