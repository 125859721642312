<div class="ftlaImgcontainer">
    <div class="overcontainer">
        <div class="row">
            <div class="col-md-6">
                 <div class="headingjive">
                        <div class="headingContent">
                        </div>
                    </div>
            </div>
            <div class="col-md-6">
                <div class="heading">
                    <div class="headingContent">
                    <p>Begin your search below to access our online resources</p>
                </div>
                <div class="searchbar">
                        <app-search-component></app-search-component>
                    </div>
                </div>                    
            </div>
        </div>       
    </div>
    <app-news-feed-component></app-news-feed-component>
    <div class="row dashboardLinks">
        <div class="col-md-12">
                <div class="headingContent">
               
                <div class="dashboard-heading reporting-heading">
                </div>
                <carousel [singleSlideOffset]="true" [itemsPerSlide]="itemsPerSlide" [interval]="false" [showIndicators]="false">
                <!-- <div class="dashboard-content"> -->
                    <slide class="pr">
                    <a class="dashboardLinks-tiles" href="https://nethope.org/" target="_blank" title="NetHope">
                        <img src="../../../assets/images/nethope.png" alt="NetHope" class="responsive"/>
                    </a> 
                </slide>
                <slide class="pr">
                    <a class="dashboardLinks-tiles" href="https://www.guidestar.org/" target="_blank" title="GuideStar">
                        <img src="../../../assets/images/guidestar.png" alt="GuideStar" class="responsive" /></a>
                        </slide>
                        <slide class="pr">

                            <!-- Test channel -->
                    <!-- <a *ngIf="isNewUserAdded" class="dashboardLinks-tiles" target="_blank" href="https://ftla.slack.com/archives/C05R79FFJ20" title="FTLA"> -->
                        <a class="dashboardLinks-tiles" target="_blank" href="https://ftlaorg.slack.com/archives/C04AKKGS6" title="FTLA">

                        <h3>FTLA</h3>
                        <img  src="../../../assets/images/slack_logo.png" alt="FTLA" class="margintop10 responsive" /></a>
                        </slide>
                        <slide class="pr">
                    <a class="dashboardLinks-tiles" target="_blank" href="https://openchangeworkspace.slack.com" title="OpenChange" >
                        <h3>Open Change</h3>
                        <img  src="../../../assets/images/slack_logo.png" alt="OpenChange" class="margintop10 responsive"/></a>
                        </slide><slide class="pr">
                    <a class="dashboardLinks-tiles" target="_blank" href="https://tagtechorg.slack.com" title="TagTech" >
                        <h3>Tag Tech</h3>
                        <img  src="../../../assets/images/slack_logo.png" alt="TagTech" class="margintop10 responsive" /></a>
                        </slide> 
                        <slide class="pr tagslide">
                    <a class="dashboardLinks-tiles" target="_blank" href="https://www.tagtech.org/default.aspx" title="TAG Site">
                        <img src="../../../assets/images/tag.png" alt="TAG Site" class="responsive"/>
                    </a> 
                    </slide>
                             <!-- </div> -->
            </carousel>
        </div>
    </div>
</div>

</div>