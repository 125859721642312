export class PortalConfig {
    title: string;
    fileName: string;
    formFile: File;
    community: string;
    userServicePrinicipalName: string;
    userId: string;
    setId: string;
    isApproved: boolean;
    isActive: boolean;
    resourcesType: number;
    content: string;
    resourcePath: string;
    userFullName: string;

    public static modelMapper(uiModel: PortalConfig) {
        return {
            title: uiModel.title,
            fileName: uiModel.fileName,
            formFile: uiModel.formFile,
            community: uiModel.community,
            userServicePrinicipalName: uiModel.userServicePrinicipalName,
            userId: uiModel.userId,
            setId: uiModel.setId,
            isApproved: uiModel.isApproved,
            isActive: uiModel.isActive,
            resourcesType: uiModel.resourcesType,
            content: uiModel.content,
            resourcePath: uiModel.resourcePath,
            userFullName: uiModel.userFullName
        };
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            title: modelData['title'],
            fileName: modelData['fileName'],
            formFile: modelData['formFile'],
            community: modelData['community'],
            userServicePrinicipalName: modelData['userServicePrinicipalName'],
            userId: modelData['userId'],
            setId: modelData['setId'],
            isApproved: modelData['isApproved'],
            isActive: modelData['isActive'],
            resourcesType: modelData['resourcesType'],
            content: modelData['content'],
            resourcePath: modelData['resourcePath'],
            userFullName: modelData['userFullName']
        });
        return this;
    }
}

export enum ResourcesType {
    None = 0,
    Banner = 1,
    Background = 2,
    Footer = 3,
    FAQ = 4
}
export namespace ResourcesType {
  export function values() {
    return Object.keys(ResourcesType).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'None'
    );
  }
}


export class OktaSettings {
  id: number;
  name: string;
  clientId: string;
  issuer : string;
  oktaGroupId: string;
  oktaGroupName: string;
  isActive: boolean;
  updatedDateTime: string;
  updatedBy: string;
  
  public addOktaSettings(id:number,name:string,clientId:string,issuer:string,oktaGroupId:string,oktaGroupName:string,isActive:boolean){
          Object.assign(this, {
              id:id,
              name:name,
              clientId:clientId,
              issuer:issuer,
              oktaGroupId:oktaGroupId,
              oktaGroupName:oktaGroupName,
              isActive:isActive
          });
      return this;
      }
  }   
  
  