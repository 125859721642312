import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { RoleService } from '../roles/role.service';
import { Roles } from '../roles/role-settings.model';
import { StateService } from '../state-manager/appstateservice';
import { NavigationService } from '../navigations/navigation.service';
import { SystemPermissions } from './../../permissions';

@Injectable()
export class OktaAuthGuard implements CanActivate {
  constructor(private router: Router, private roleService: RoleService,
              private stateService: StateService, private navigationService: NavigationService) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.stateService.isTokenValid) {
      if (this.stateService.isUnAuthorized()) {
        this.router.navigate(['/not-authorized-page']);
        return;
      }
      const role = this.roleService.getRole();
      const currentRole = Roles[this.roleService.getRole()];
      const isViewasAdmin = this.roleService.isViewAsAdmin(role);
      if (!isViewasAdmin && route.data.permissions) {
        route.data.permissions.find((x: string | number) => {
                    if (SystemPermissions[x] === (undefined || null)) { this.router.navigate(['/not-authorized-page']);
                   }});
      }
      const group = this.stateService.getGroupSession();
      if (group && route.data.groups && route.data.groups.indexOf(group) === -1) {
        this.router.navigate(['/not-authorized-page']);
      }
      if (!this.navigationService.isFeatureSupported(group, route.data.feature)) {
        this.router.navigate(['/not-authorized-page']);
      }

      return true;
    } else {
      this.stateService.removeToken();
    }

    this.router.navigate(['/'], { queryParams: { redirecturl:  state.url }});
    return false;
  }
}
