import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { metadataTags, allMetadataTags, locationTags, visionlocationTags, AccessFor, ftlaMetadataTags } from '../shared/blob-upload.model';
import { FileUploaderService } from '../shared/file-uploader.service';
import { FileUploader } from 'ng2-file-upload';
import { catchError, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LinkItem } from '../shared/content-model';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { ContentService } from '../shared/content.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent implements OnInit, OnDestroy {
  @ViewChild('confirmDialog', {static: false}) confirmModalComponent: ModalComponent;
  @ViewChild('fileTypeErrorDialog', {static: false}) fileTypeErrorModalComponent: ModalComponent;
  @Input() contentData: any;
  @Input() showProgress: boolean;
  @Input() tabSelected: string;
  uploader: FileUploader;
  uploadCompleted: boolean;
  uploadError: boolean;
  retryEnabled: boolean;
  showRetryError: boolean;
  notificationSent: boolean;
  showError: boolean;
  showUploadError: boolean;
  @Output() uploadCompletedEvent = new EventEmitter<boolean>();
  @Output() backToReviewStepEvent = new EventEmitter<boolean>();
  accessForList: any;
  isAllGroup = false;
  isFtlaGroup= false;
  tags: any;
  metaTags: any;
  userRole: Roles;
  isThriveGroup =false;


  constructor(private fileUploader: FileUploaderService, public datepipe: DatePipe,
              private router: Router,
              private currentState: StateService,
              private contentService: ContentService) { }

  get locationTags(): any {
    if(this.isThriveGroup)
    return Object.keys(visionlocationTags);
    else
    return Object.keys(locationTags);
  }

  locationExists(tag): any {
    let locationExists = false;
    if (tag === 'location') {
      this.locationTags.forEach(element => {
        if (this.contentData[element] && this.contentData[element].length > 0) {
          locationExists = true;
        }
      });
      if(locationExists==false && this.isFtlaGroup){
        locationExists=true;
      }
    }
    return locationExists;
  }

  ngOnInit() {
    this.isThriveGroup = this.currentState.isThriveGroup();
    if (this.currentState.isAllGroup()) {
      this.isAllGroup = true;
      this.tags = Object.keys(allMetadataTags);
      this.metaTags = allMetadataTags;
    } 
    else if (this.currentState.isFTLAGroup()) {
      this.isFtlaGroup = true;
      this.tags = Object.keys(ftlaMetadataTags);
      this.metaTags = ftlaMetadataTags;
    }else {
      this.tags = Object.keys(metadataTags);
      this.metaTags = metadataTags;
    }
    if (this.showProgress) {
      this.showError = false;
      this.initFileUpload();
      this.uploader.uploadAll();
      if (this.contentData.linkField && this.contentData.linkField.length > 0) {
        this.contentData.linkField.map(item => item.externalUrlField.retry = false);
        this.uploadLinkItems(this.contentData.linkField.filter(x => !x.externalUrlField.isUploaded));
      }
    }
    //this.accessForList = AccessFor;
    this.userRole = this.currentState.getRole();
  }

  triggerFileUpload() {
    this.initFileUpload();
    this.uploader.uploadAll();
    if (this.contentData.linkField && this.contentData.linkField.length > 0) {
      this.contentData.linkField.map(item => item.externalUrlField.retry = false);
      this.uploadLinkItems(this.contentData.linkField.filter(x => !x.externalUrlField.isUploaded));
    }
  }

  uploadLinkItems(linkItems: any) {
    forkJoin(linkItems.map(linkItem => {
      return this.fileUploader.uploadLink(this.contentData, linkItem);
    }))
    .subscribe(async responseList => {
      this.uploadCompleted = this.contentData.linkField.some(item => item.externalUrlField.isUploaded);
      this.showError = true;
      this.backToReviewStepEvent.emit(this.contentData.linkField.some(x => x.externalUrlField.isError));
      if (this.uploadCompleted) {
        this.uploadCompletedEvent.emit(true);
      }
      const retry = await this.validateUploadLinks(linkItems);
      const retryUploadItems = linkItems.filter(item => item.externalUrlField.retry);
      if (retry && retryUploadItems && retryUploadItems.length > 0) {
        this.uploadLinkItems(retryUploadItems);
      }
    });
  }

  validateUploadLinks = async (linkItems: any) => {
    for (const item of linkItems) {
      item.externalUrlField.isFileTypeError = false;
      if (item.externalUrlField.isError) {
        if (item.externalUrlField.status !== 404 && item.externalUrlField.status !== 422) {
          item.externalUrlField.retry = true;
        }
        if (item.externalUrlField.status >= 100 && item.externalUrlField.status <= 199) {
          item.externalUrlField.message = 'Informational Message';
        } else if (item.externalUrlField.status >= 300 && item.externalUrlField.status <= 399) {
          item.externalUrlField.warning = true;
          item.externalUrlField.message = 'Redirect Error';
        } else if (item.externalUrlField.status >= 400 && item.externalUrlField.status <= 499) {
          if (item.externalUrlField.status === 412) {
            item.externalUrlField.message = 'Invalid File Type';
            item.externalUrlField.isFileTypeError = true;
          } else {
            item.externalUrlField.message = 'Invalid Link';
          }
        } else {
          item.externalUrlField.warning = true;
          item.externalUrlField.message = 'Invalid Link';
        }
      }
    }
    const errorItems = linkItems.filter(item => item.externalUrlField.isError);
    const fileTypeError = linkItems.filter(item => item.externalUrlField.isFileTypeError);
    if (fileTypeError && errorItems && fileTypeError.length > 0 && fileTypeError.length === errorItems.length) {
      return await this.showFileTypeErrorModal(fileTypeError, true);
    } else {
      return await this.showConfirmationModal(errorItems);
    }
  }

  async showConfirmationModal(linkItems: string) {
    if (!linkItems || linkItems.length === 0) {
      return false;
    }
    if (this.confirmModalComponent) {
      this.confirmModalComponent.entityData = {
        headerText: 'Upload Link Validation Failed',
        entityData: { linkItems }
      };
      this.confirmModalComponent.showModal();
      return await this.confirmModalComponent.closeModal.pipe(take(1)).toPromise();
    }
  }

  closeConfirmModal(reason: boolean) {
    if (this.confirmModalComponent) {
      this.confirmModalComponent.closeModalWindow(reason);
    }
  }

  async showFileTypeErrorModal(items: string, isLink: boolean) {
    if (!items || items.length === 0) {
      this.showUploadError = true;
      return false;
    }

    this.showUploadError = false;
    if (this.fileTypeErrorModalComponent) {
      this.fileTypeErrorModalComponent.entityData = {
        headerText: 'File Type Error',
        entityData: { items, isLink }
      };
      this.fileTypeErrorModalComponent.showModal();
      return await this.fileTypeErrorModalComponent.closeModal.pipe(take(1)).toPromise();
    }
  }

  closeFileTypeErrorModal(reason: boolean) {
    if (this.fileTypeErrorModalComponent) {
      this.fileTypeErrorModalComponent.closeModalWindow(reason);
    }
  }

  validateUploadItems = async (fileItems: any) => {
    for (const item of fileItems) {
      if (item.status === 412) {
        item.message = 'Invalid File Type';
        item.fileTypeError = true;
      } else {
        item.fileTypeError = false;
      }
    }

    return await this.showFileTypeErrorModal(fileItems.filter(item => item.fileTypeError), false);
  }

  retryUpload() {
    this.closeAlert();
    this.showRetryError = true;
    this.uploader.uploadAll();
    if (this.contentData.linkField && this.contentData.linkField.length > 0) {
      const pendingLinkItems = this.contentData.linkField.filter(item => item.externalUrlField.isUploaded === false);
      if (pendingLinkItems && pendingLinkItems.length > 0) {
        this.uploadLinkItems(pendingLinkItems);
      }
    }
  }

  initFileUpload() {
    this.uploader = this.fileUploader.bulkUploader;

    this.uploader.onBuildItemForm = (fileItem: any, formData: any) => {
      this.fileUploader.buildUploadItemForm(this.contentData, fileItem, formData);
    };

    this.uploader.onSuccessItem = (item: any, response: any, status: any) => {
      item.error = false;
      item.status = status;
    };

    this.uploader.onErrorItem = (item: any, response: any, status: any) => {
      item.isUploaded = false;
      item.error = true;
      item.status = status;
      this.uploadError = true;
      this.retryEnabled = true;
    };

    this.uploader.onCompleteAll = async () => {
      this.uploadCompleted = this.fileUploader.bulkUploader.queue.some(item => item.isSuccess);
      this.showUploadError = true;
      await this.validateUploadItems(this.uploader.queue);
      this.backToReviewStepEvent.emit(this.uploader.queue.some(x => x.isError));
      if (this.uploadCompleted) {
        this.uploadCompletedEvent.emit(true);
      }
    };
  }

  sendEmailNotification() {
    if (this.notificationSent) {
      return;
    }
    if (this.fileUploader.bulkUploader) {
      const uploadedItems = this.fileUploader.bulkUploader.queue.filter(item => item.isSuccess).map(item => item.file.name);
      if (uploadedItems && uploadedItems.length > 0) {
        this.notificationSent = true;
        if(!this.isFtlaGroup)
          this.fileUploader.sendUploadNotification(uploadedItems, this.fileUploader.guid);
      }
    }
    if (this.contentData.linkField) {
      const linkItems = this.contentData.linkField.filter(item => item.externalUrlField.isUploaded)
                                                  .map(item => item.externalUrlField.linkValue);
      if (linkItems && linkItems.length > 0) {
        this.notificationSent = true;
        if(!this.isFtlaGroup)
        this.fileUploader.sendUploadNotification(linkItems, this.fileUploader.guid);
      }
    }
    // for ALL & FTLA it should be auto approved content
    if (this.userRole === Roles.SuperAdmin || (this.isFtlaGroup && this.userRole === Roles.Admin)) {
      this.contentService.approveSet(this.fileUploader.guid).subscribe();
    }
  }

  closeWizard() {
    this.sendEmailNotification();
    this.router.navigate(['/']);
  }

  closeAlert() {
    this.uploadError = false;
  }

  ngOnDestroy() {
  }
}
