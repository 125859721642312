import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: []
})
export class FooterComponent implements OnInit {
  today = new Date();
  isAllGroup = false;
  isJJDataGroup =false;
  isGrantsGroup = false;
  version = '';
  isFooterShown:boolean=true;
  constructor(private currentState: StateService,public router: Router) {
  }

  ngOnInit() {
    this.version = environment.version;
    this.isFooterShown = this.router.url.indexOf('/dashboard')>0?false:true;
    this.isJJDataGroup = this.currentState.isJJGroup();
    this.isGrantsGroup = this.currentState.isGrantsGroup();
  }

  get isShow() {
    return this.currentState.isJDAIGroup() || this.currentState.isThriveGroup();
  }
}
