import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { DatePipe } from '@angular/common';

import { SearchService } from 'src/app/search-results/shared/search.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ReprioritizeFacets, filterTags, filterLocations, allFilterTags, filterTopics } from 'src/app/search-results/shared/search-model';
import { organizeLocationMetadataTags } from '../shared/blob-upload.model';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { FileUploaderService } from '../shared/file-uploader.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Documents, DocumentsMetaDataModel } from 'src/app/jj/models/DocumentsMetaData.model';
import { compareItems } from 'src/app/common/models/utility';


@Component({
  selector: 'app-content-remove-thrive',
  templateUrl: './content-remove-thrive.component.html',
  styleUrls: ['./content-remove-thrive.component.scss'],
  providers: [ DatePipe ]
})
export class ContentRemoveThriveComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  accordionPanelClass = 'refine-tags';
  results = [];
  documentsMetaData: DocumentsMetaDataModel;
  managedDocumentsMetaData: DocumentsMetaDataModel;
  facets = [];
  facetsGroup = {};
  searchQuery: string;
  sortParam = 0;
  facetsList: string[] = [];
  fileAttributes: string[] = [];
  tags: any[] = [];
  newFacets = [];
  /* Pagination */
  maxSize = 5;
  currentPage = 1;
  countFrom;
  countTo;
  totalItems;
  perPage = 10;
  itemPerPage;
  searchTerm: string;
  pagedData: DocumentsMetaDataModel;
  documentData = [];

  /* DatePicker */
  bsStartValue: Date = null;
  bsEndValue: Date = null;
  maxDate = new Date();
  colorTheme = 'default';
  bsConfig: Partial<BsDatepickerConfig>;

  searchEventSubscription: any;
  showFilters = true;
  showResults = false;
  geoLocationsData: any;
  locationMetaData: any;
  viewAsAdmin = this.commonService.getViewUserEventEmitter();
  facetItemKeys: any;
  isDateSearched = false;
  isAllGroup = false;
  topicsList: Array<any>;
  isFacetDisabled = false;
  selectedAccordion = "";
  selectedTopic = "";

  constructor(private uploadService: FileUploaderService, 
              private searchService: SearchService, 
              public sanitizer: DomSanitizer,
              public datepipe: DatePipe,
              private router: Router, 
              private commonService: CommonEntityService, 
              private currentState: StateService, 
              public googleAnalyticsService: GoogleAnalyticsService) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, maxDate: this.maxDate, showWeekNumbers:false });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.searchEventSubscription) {
      this.searchEventSubscription.unsubscribe();
    }
  }

  searchInBox() {
    this.loadBoxResults();
  }
  
  toggleRefineTag() {
    this.showFilters = !this.showFilters;
  }

  loadBoxResults() {
    this.facetsGroup = {};
    this.currentPage = 1;
    this.countTo = this.currentPage * this.perPage;
    this.countFrom = this.countTo - 9;

    this.searchService.getBoxData().subscribe(results => {
     
      var docMap = this.mapDocumentResponseModel(results);
    
      this.documentsMetaData = docMap;
      
      this.showResults = true;
      this.totalItems = this.documentsMetaData.totalCount
      this.pagedData=results;
      let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
      let doc = results.documents.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
      this.pagedData.documents = doc;
      this.resultFormatter(this.pagedData);
    });
  }

  hasDateRangeError(): boolean {
    if (isDateValid(this.bsStartValue) || isDateValid(this.bsEndValue)) {
      return !this.isValidDateRage();
    }
    return false;
  }

  pageChanged(event: PageChangedEvent): void {
    this.pagedData = new DocumentsMetaDataModel();
    var docResult = this.documentsMetaData;
    if(event.page!=undefined)
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    var doc;
    if ((this.searchTerm == undefined || this.searchTerm?.length === 0) && this.isDateSearched == false) {
      doc = docResult.documents?.slice(skipRows, this.currentPage * this.perPage);
    }
    else {
      doc = this.managedDocumentsMetaData.documents?.slice(skipRows, this.currentPage * this.perPage);
    }
    this.pagedData.documents = doc;
    this.pagedData.facets=this.documentsMetaData.facets;
    this.pagedData.totalCount = this.totalItems;
    this.resultFormatter(this.pagedData);
  }

  hasInvalidDate(date: Date): boolean {
    return date != null ? isNaN(date.valueOf()) : false;
  }

  onDateValueChange(value: Date): void {
    if (this.isValidDateRage()) {
      this.pagedData = new DocumentsMetaDataModel();
      this.managedDocumentsMetaData = new DocumentsMetaDataModel();
      this.managedDocumentsMetaData.documents = new Array<Documents>();
      this.searchTerm=null;
      var doc = this.documentsMetaData.documents.filter((item: any) =>
      new Date(item.uploadedDate).getTime() >= this.bsStartValue.getTime() && new Date(item.uploadedDate).getTime() <= this.bsEndValue.getTime());
        this.managedDocumentsMetaData.documents = doc;
        this.isDateSearched = true;
    this.totalItems = doc.length;
    this.pagedData.documents = doc.slice(0, 10);
    this.resultFormatter(this.pagedData);
      
    }
  }

  isValidDateRage(): boolean {
    if (isDateValid(this.bsStartValue) && isDateValid(this.bsEndValue) &&
        new Date(this.bsEndValue.getFullYear(), this.bsEndValue.getMonth(), this.bsEndValue.getDate()) >=
            new Date(this.bsStartValue.getFullYear(), this.bsStartValue.getMonth(), this.bsStartValue.getDate())) {
      return true;
    }
    return false;
  }

  onDateClear() {
    this.bsStartValue = null;
    this.bsEndValue = null;
    this.isDateSearched = false;
    this.totalItems = this.documentsMetaData.totalCount
      this.pagedData=new DocumentsMetaDataModel();
      this.pagedData.documents = new Array<Documents>();
      let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
      let doc = this.documentsMetaData.documents.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
      
      this.pagedData.documents = doc.sort((nodeA, nodeB) => compareItems(nodeA["fileName"]?.toLowerCase(), nodeB["fileName"]?.toLowerCase()));
      
      this.resultFormatter(this.pagedData);
  }

  goToUrl(url: string): void {
    if (url.indexOf('http') === -1) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }  

  resultFormatter(results) {
    this.facetsList = [];
    const re = /(?:\.([^.]+))?$/;
    this.itemPerPage = 10;
    results.documents.forEach(result => {
      this.fileAttributes = [];
      const ext = result.fileName && re.exec(result.fileName)[1].toLowerCase();
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'bmp' || ext === 'tif') {
        this.fileAttributes['CssClass'] = 'fa fa-file-image-o';
        this.fileAttributes['CssIcon'] = 'file-pdf';
        this.fileAttributes['FileContentType'] = 'image/' + ext;
        this.fileAttributes['FileType'] = ext;
        result.fileAttributes = this.fileAttributes;
      }
      if (ext === 'url' || ext === 'lnk') {
        this.fileAttributes['CssClass'] = 'fa fa-link';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/internet-shortcut';
        this.fileAttributes['FileType'] = 'WebSite';
        result.fileAttributes = this.fileAttributes;
      }
      if (ext === 'pdf') {
        this.fileAttributes['CssClass'] = 'far fa-file-pdf';
        this.fileAttributes['CssIcon'] = 'file-pdf';
        this.fileAttributes['FileContentType'] = 'application/pdf';
        this.fileAttributes['FileType'] = 'PDF';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'eml' || ext === 'mht') {
        this.fileAttributes['CssClass'] = 'far fa-envelope';
        this.fileAttributes['CssIcon'] = 'file-email';
        this.fileAttributes['FileContentType'] = 'message/rfc822';
        this.fileAttributes['FileType'] = 'Email';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'docx') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        this.fileAttributes['FileType'] = 'WORD';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'ppt') {
        this.fileAttributes['CssClass'] = 'far fa-file-powerpoint';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/vnd.ms-powerpoint';
        this.fileAttributes['FileType'] = 'POWER POINT';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'pptx') {
        this.fileAttributes['CssClass'] = 'far fa-file-powerpoint';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        this.fileAttributes['FileType'] = 'POWER POINT';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'doc') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/msword';
        this.fileAttributes['FileType'] = 'WORD';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'rtf') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/rtf';
        this.fileAttributes['FileType'] = 'WORD';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'xls' || ext === 'csv') {
        this.fileAttributes['CssClass'] = 'far fa-file-excel';
        this.fileAttributes['CssIcon'] = 'file-excel';
        this.fileAttributes['FileContentType'] = 'application/vnd.ms-excel';
        this.fileAttributes['FileType'] = 'EXCEL';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'xlsx') {
        this.fileAttributes['CssClass'] = 'far fa-file-excel';
        this.fileAttributes['CssIcon'] = 'file-excel';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        this.fileAttributes['FileType'] = 'EXCEL';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'htm' || ext === 'html') {
        this.fileAttributes['CssClass'] = 'far fa-file-code';
        this.fileAttributes['CssIcon'] = 'file-code';
        this.fileAttributes['FileContentType'] = 'text/html';
        this.fileAttributes['FileType'] = 'HTML';
        result.fileAttributes = this.fileAttributes;
      }
      if (ext === 'wmv') {
        this.fileAttributes['CssClass'] = 'far fa fa-film';
        this.fileAttributes['CssIcon'] = 'file-code';
        this.fileAttributes['FileContentType'] = 'video/x-ms-wmv';
        this.fileAttributes['FileType'] = 'Wmv';
        result.fileAttributes = this.fileAttributes;
      }

      if (ext === 'zip' || ext === 'rar' || ext === '7z') {
        this.fileAttributes['CssClass'] = 'far fa-file-archive';
        this.fileAttributes['CssIcon'] = 'file-archive';
        this.fileAttributes['FileContentType'] = 'application/octet-stream';
        this.fileAttributes['FileType'] = 'Archive';
        result.fileAttributes = this.fileAttributes;
      }

      if (!result.fileAttributes) {
        this.fileAttributes['CssClass'] = 'far fa fa-file';
        this.fileAttributes['CssIcon'] = 'file-archive';
        this.fileAttributes['FileType'] = 'Not Specified';
        result.fileAttributes = this.fileAttributes;
      }

      result.tag = this.tags;
      result.toggle = 0;
    });
    this.pagedData = results.documents.sort((nodeA, nodeB) => compareItems(nodeA["fileName"]?.toLowerCase(), nodeB["fileName"]?.toLowerCase()));
   
  }


  showBoxFile(result){
    window.open('https://aecf.app.box.com/file/'+result.id, "_blank");

  }

  returnZero(): number {
    return 0;
  }


  search(): void {
    this.pagedData = new DocumentsMetaDataModel();
    this.pagedData.documents = new Array<Documents>();
    if (this.searchTerm.length === 0) {
      this.currentPage = 0;
      this.pagedData.documents = this.documentsMetaData?.documents.sort((nodeA, nodeB) => compareItems(nodeA["fileName"]?.toLowerCase(), nodeB["fileName"]?.toLowerCase()))
        .filter(x => x.fileName?.toLowerCase()).slice(this.currentPage, this.perPage);
      this.totalItems = this.documentsMetaData.totalCount;
    }
    else {
    this.managedDocumentsMetaData = new DocumentsMetaDataModel();
    this.managedDocumentsMetaData.documents = new Array<Documents>();
    var searchFilename = this.documentsMetaData.documents.filter(x => x.fileName?.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(0, 10);

    var searchUploadedBy = this.documentsMetaData.documents.filter(x => x.uploadedBy?.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(0, 10);
    if(searchFilename!=null || searchFilename!=undefined)
    {
      this.managedDocumentsMetaData.documents = this.searchConcatBoxThrive(this.managedDocumentsMetaData.documents, searchFilename);
    }
    if(searchUploadedBy!=null || searchUploadedBy!=undefined)
    {
      this.managedDocumentsMetaData.documents = this.searchConcatBoxThrive(this.managedDocumentsMetaData.documents, searchUploadedBy);
    }

    let result = this.managedDocumentsMetaData.documents.filter(
      (doc, index) => index === this.managedDocumentsMetaData.documents.findIndex(
        other => doc.fileName === other.fileName
          && doc.uploadedBy === other.uploadedBy));

    this.managedDocumentsMetaData.documents = result.sort((nodeA, nodeB) => compareItems(nodeA["fileName"]?.toLowerCase(), nodeB["fileName"]?.toLowerCase()));
    this.totalItems = result.length;
      this.pagedData.documents = result.slice(0, 10);
    }
      this.resultFormatter(this.pagedData);
  }

  searchConcatBoxThrive(documentData: Documents[], parentDocument: Documents[]) : Documents[]
  {
    var result = documentData;
    if(parentDocument?.length>0)
    {
      parentDocument.forEach(a => {
        result = result.concat(a);
      });
    }
    return result;
  }

  mapDocumentResponseModel(result):DocumentsMetaDataModel
  {
    let documentData = new DocumentsMetaDataModel();
    documentData.documents = new Array<Documents>();
    documentData.facets = result.facets;
    documentData.totalCount=result.totalCount;
    result.documents.forEach(data => {
      let doc = new Documents();
      doc.id = data.id;
      doc.fileName = data.fileName;
      doc.uploadedBy = data.uploadedBy;
      doc.uploadedDate = data.uploadedDate;
      doc.fileTitle = data.fileTitle;
      documentData.documents.push(doc);
    });
    return documentData;
  }
}
