import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoxContainerComponent } from './box-container/box-container.component';
import { BoxExplorerComponent } from './box-explorer/box-explorer.component';
import { BoxService } from './shared/box.service';

@NgModule({
  declarations: [BoxContainerComponent, BoxExplorerComponent],
  imports: [
    CommonModule
  ],
  providers: [
    BoxService
  ]
})
export class BoxModule { }
