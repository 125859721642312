import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OktaAuthGuard } from '../core/guards/auth.guard';
import { ContentUploadContainerComponent } from './content-upload-container/content-upload-container.component';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { ContentRemoveComponent } from './content-remove/content-remove.component';
import { ContentEditComponent } from './content-edit/content-edit.component';
import { Features } from '../core/navigations/navigation-settings.model';
import { ContentRemoveThriveComponent } from './content-remove-thrive/content-remove-thrive.component';
import { features } from 'process';

const routes: Routes = [
  {path: 'content', component: ContentUploadContainerComponent, canActivate: [OktaAuthGuard],
          data: { permissions: ['UploadTemplate', 'UploadFile', 'UploadLink', 'UploadDataset'], feature: [Features.Upload] }},
  {path: 'content/edit', component: ContentEditComponent, canActivate: [OktaAuthGuard],
          data: { permissions: ['ModifiyDocument'], feature: [Features.Search] }},
  {path: 'content/manage', component: PendingApprovalComponent, canActivate: [OktaAuthGuard],
          data: { permissions: ['DatasetApprove', 'ApproveDocument', 'RejectDocument'], feature: [Features.Upload] }},
  {path: 'content/remove', component: ContentRemoveComponent, canActivate: [OktaAuthGuard],
          data: { permissions: ['ModifiyDocument'], feature: [Features.Search] }},
  {path:'content/remove-thrive', component:ContentRemoveThriveComponent, canActivate:[OktaAuthGuard],
        data:{Permissions:['ModifyDocument'], feature:[Features.Search]}}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
