import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vision-landing',
  styleUrls: [],
  template: `
    <div class="prelogin-vision-imgcontainer">
    </div>`
})
export class VisionLandingComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(environment.visionPageTitle);
  }
}
