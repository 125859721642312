import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { ReportInstruction } from './models/ReportInstruction.model';
import { Guid } from 'guid-typescript';

@Injectable()
export class ReportService {
  constructor(private repository: RepositoryService) {}

  public getReportObject(reportName: string = '',siteSelected:number): Observable<any> {
    return this.repository.get(environment.DashboardUrl, '/' + reportName+'/'+siteSelected);
  }
  public exportReport(exportReportParameters:any):Observable<any>{
    return this.repository.post(environment.DashboardUrl,'/export' , exportReportParameters);
  }

  public getReportInstructions(reportId:Guid, reportTab:string): Observable<any> {
    return this.repository.get(environment.DashboardUrl, '/instructions/'+reportId+'/'+reportTab);
  }

  public AddUpdateReportInstructions(reportInstruction: ReportInstruction): Observable<any> {
    return this.repository.post(environment.DashboardUrl, '/instructions', reportInstruction);
  }
}
