import { compareItems } from 'src/app/common/models/utility';

export const filterTags = {
    programArea: 'Program Area',
    topic: 'Topic',
    continent: 'Continent',
    country: 'Country',
    region: 'Region',
    division: 'Division',
    location: 'Location',
    state: 'State',
    jurisdiction: 'jurisdiction',
    contentType: 'Content Type',
    fileType: 'File Type',
    //publisher: 'publisher',
    //community: 'Community'
};

export const allFilterTags = {
  programArea: 'Competency Sub-area',
  topic: 'Competency Area',
  continent: 'Continent',
  country: 'Country',
  region: 'Region',
  division: 'Division',
  location: 'Location',
  state: 'State',
  jurisdiction: 'jurisdiction',
  contentType: 'Content Type',
  fileType: 'File Type',
  // publisher: 'Publisher',
  // community: 'Community'
};

export const ftlaFilterTags = {
  programArea: 'Subtopic',
  topic: 'Topic',
  continent: 'Continent',
  country: 'Country',
  region: 'Region',
  division: 'Division',
  location: 'Location',
  state: 'State',
  jurisdiction: 'jurisdiction',
  contentType: 'Content Type',
  fileType: 'File Type',
  // publisher: 'Publisher',
  // community: 'Community'
};

const facetsOrder = [
    'topic',
    'programArea',
    //'community',
    'location',
    'contentType',
    'fileType',
    //'publisher'
];

const visionfacetsOrder = [
  'topic',
  'programArea',
  //'community',
  'jurisdiction',
  'contentType',
  'fileType',
  //'publisher'
];

export const locationTags = {
  jurisdiction: 'jurisdiction',
  state: 'state',
  division: 'division',
  region: 'region',
  country: 'country',
  continent: 'continent'
};

export function ReprioritizeFacets(facets: any[], isFtla: boolean = false, isVisionGroup: boolean = false) {
  const RepriortizeFacets = [];
  if (isVisionGroup) {
    for (const facet of visionfacetsOrder) {
      const item = facets[facet];
      RepriortizeFacets[facet] = item.sort((nodeA, nodeB) => compareItems(nodeA['value'], nodeB['value']));
    }
  } else {
    for (const facet of facetsOrder) {
      const item = facets[facet];
      if (facet === 'location') {
        SortLocationFacets(item, isFtla);
        RepriortizeFacets[facet] = item;
      } else {
          RepriortizeFacets[facet] = item.sort((nodeA, nodeB) => compareItems(nodeA['value'], nodeB['value']));
      }
    }
  }
  return RepriortizeFacets;
}

export function SortLocationFacets(locationFacets: any[], isFtla: boolean = false) {
  if (locationFacets) {
    if (isFtla) {
      locationFacets.sort((nodeA, nodeB) => compareItems(nodeA['continent'], nodeB['continent']));
      for (const continent of locationFacets) {
        continent.countryList.sort((nodeA, nodeB) => compareItems(nodeA['country'], nodeB['country']));
        for (const country of continent.countryList) {
          country.regionList.sort((nodeA, nodeB) => compareItems(nodeA['region'], nodeB['region']));
          for (const region of country.regionList) {
            region.divisionList.sort((nodeA, nodeB) => compareItems(nodeA['division'], nodeB['division']));
            for (const division of region.divisionList) {
              division.stateList.sort((nodeA, nodeB) => compareItems(nodeA['state'], nodeB['state']));
              for (const state of division.stateList) {
                state.countyList.sort((nodeA, nodeB) => compareItems(nodeA['county'], nodeB['county']));
              }
            }
          }
        }
      }
    } else {
      locationFacets.sort((nodeA, nodeB) => compareItems(nodeA['country'], nodeB['country']));
      for (const country of locationFacets) {
        country.regionList.sort((nodeA, nodeB) => compareItems(nodeA['region'], nodeB['region']));
        for (const region of country.regionList) {
          region.divisionList.sort((nodeA, nodeB) => compareItems(nodeA['division'], nodeB['division']));
          for (const division of region.divisionList) {
            division.stateList.sort((nodeA, nodeB) => compareItems(nodeA['state'], nodeB['state']));
            for (const state of division.stateList) {
              state.countyList.sort((nodeA, nodeB) => compareItems(nodeA['county'], nodeB['county']));
            }
          }
        }
      }
    }
  }
  return locationFacets;
}

export function filterLocations(facets: any[], geoLocationMetaData: any[], isFtla: boolean = false) {
  let countryExists = false;
  let countyExists = false;
  let stateExists = false;
  let divisionExists = false;
  let regionExists = false;
  let locationMetaData = JSON.parse(JSON.stringify(geoLocationMetaData));
  if (isFtla) {
    for (let continentIndex = locationMetaData.length - 1; continentIndex > -1; continentIndex--) {
      const continent = locationMetaData[continentIndex];
      if(continent.countryList != undefined) {
        for (let countryIndex = continent.countryList.length - 1; countryIndex > -1; countryIndex--) {
          const country = continent.countryList[countryIndex];
          if(country.regionList != undefined) {
            for (let regionIndex = country.regionList.length - 1; regionIndex > -1; regionIndex--) {
              const region = country.regionList[regionIndex];
              if(region.divisionList != undefined) {
                for (let divisionIndex = region.divisionList.length - 1; divisionIndex > -1; divisionIndex--) {
                  const division = region.divisionList[divisionIndex];
                  if(division.stateList != undefined) {
                    for (let stateIndex = division.stateList.length - 1; stateIndex > -1; stateIndex--) {
                      const state = division.stateList[stateIndex];
                      if(state.countyList != undefined) {
                        for (let countyIndex = state.countyList.length - 1; countyIndex > -1; countyIndex--) {
                          const county = state.countyList[countyIndex];
                          const countyFacetItem = elementExists(county.county, facets[locationTags.jurisdiction]);
                          if (countyFacetItem) {
                            county.count = countyFacetItem.count;
                          } else {
                            if (countyIndex > -1) {
                              state.countyList.splice(countyIndex, 1);
                            }
                          }
                        }
                      }
                      countyExists = countyExists || state.countyList.length > 0;
                      const stateFacetItem = elementExists(state.state, facets[locationTags.state]);
                      if (stateFacetItem) {
                        state.count = stateFacetItem.count;
                      } else if (state.countyList.length === 0) {
                        if (stateIndex > -1) {
                          division.stateList.splice(stateIndex, 1);
                        }
                      }
                    }
                  }
                  stateExists = stateExists || division.stateList.length > 0;
                  const divisionFacetItem = elementExists(division.division, facets[locationTags.division]);
                  if (divisionFacetItem) {
                    division.count = divisionFacetItem.count;
                  } else if (division.stateList.length === 0) {
                    if (divisionIndex > -1) {
                      region.divisionList.splice(divisionIndex, 1);
                    }
                  }
                }
              }
              divisionExists = divisionExists || region.divisionList.length > 0;
              const regionFacetItem = elementExists(region.region, facets[locationTags.region]);
              if (regionFacetItem) {
                region.count = regionFacetItem.count;
              } else if (region.divisionList.length === 0) {
                if (regionIndex > -1) {
                  country.regionList.splice(regionIndex, 1);
                }
              }
            }
          }
          regionExists = regionExists || country.regionList.length > 0;
          const countryFacetItem = elementExists(country.country, facets[locationTags.country]);
          if (countryFacetItem) {
            country.count = countryFacetItem.count;
          } else if (country.regionList.length === 0) {
            if (countryIndex > -1) {
              continent.countryList.splice(countryIndex, 1);
            }
          }
        }
      }
      countryExists = countryExists || continent.countryList.length > 0;
      const continentFacetItem = elementExists(continent.continent, facets[locationTags.continent]);
      if (continentFacetItem) {
        continent.count = continentFacetItem.count;
      } else if (continent.countryList.length === 0) {
        if (continentIndex > -1) {
          locationMetaData.splice(continentIndex, 1);
        }
      }
    }
  } else {
    for (let countryIndex = locationMetaData.length - 1; countryIndex > -1; countryIndex--) {
      const country = locationMetaData[countryIndex];
      for (let regionIndex = country.regionList.length - 1; regionIndex > -1; regionIndex--) {
        const region = country.regionList[regionIndex];
        for (let divisionIndex = region.divisionList.length - 1; divisionIndex > -1; divisionIndex--) {
          const division = region.divisionList[divisionIndex];
          for (let stateIndex = division.stateList.length - 1; stateIndex > -1; stateIndex--) {
            const state = division.stateList[stateIndex];
            for (let countyIndex = state.countyList.length - 1; countyIndex > -1; countyIndex--) {
              const county = state.countyList[countyIndex];
              const countyFacetItem = elementExists(county.county, facets[locationTags.jurisdiction]);
              if (countyFacetItem) {
                county.count = countyFacetItem.count;
              } else {
                if (countyIndex > -1) {
                  state.countyList.splice(countyIndex, 1);
                }
              }
            }
            countyExists = countyExists || state.countyList.length > 0;
            const stateFacetItem = elementExists(state.state, facets[locationTags.state]);
            if (stateFacetItem) {
              state.count = stateFacetItem.count;
            } else if (state.countyList.length === 0) {
              if (stateIndex > -1) {
                division.stateList.splice(stateIndex, 1);
              }
            }
          }
          stateExists = stateExists || division.stateList.length > 0;
          const divisionFacetItem = elementExists(division.division, facets[locationTags.division]);
          if (divisionFacetItem) {
            division.count = divisionFacetItem.count;
          } else if (division.stateList.length === 0) {
            if (divisionIndex > -1) {
              region.divisionList.splice(divisionIndex, 1);
            }
          }
        }
        divisionExists = divisionExists || region.divisionList.length > 0;
        const regionFacetItem = elementExists(region.region, facets[locationTags.region]);
        if (regionFacetItem) {
          region.count = regionFacetItem.count;
        } else if (region.divisionList.length === 0) {
          if (regionIndex > -1) {
            country.regionList.splice(regionIndex, 1);
          }
        }
      }
      regionExists = regionExists || country.regionList.length > 0;
      const countryFacetItem = elementExists(country.country, facets[locationTags.country]);
      if (countryFacetItem) {
        country.count = countryFacetItem.count;
      } else if (country.regionList.length === 0) {
        if (countryIndex > -1) {
          locationMetaData.splice(countryIndex, 1);
        }
      }
    }
  }
  locationMetaData = updateWithFacets(locationMetaData, facets, isFtla);
  return locationMetaData;
}

export function filterTopics(facets: any[], topicsList: any[]) {
  let programExists = false;
  let topicMetaData = JSON.parse(JSON.stringify(topicsList));
  if(topicMetaData != undefined) {
    for (let topicIndex = topicMetaData.length - 1; topicIndex > -1; topicIndex--) {
      const topic = topicMetaData[topicIndex];
      if(topic.programs != undefined) {
        for (let programIndex = topic.programs.length - 1; programIndex > -1; programIndex--) {
          const program = topic.programs[programIndex];
          const programFacetItem = elementExists(program.program, facets['programArea']);
          if (programFacetItem) {
            program.count = programFacetItem.count;
          } else {
            if (programIndex > -1) {
              topic.programs.splice(programIndex, 1);
            }
          }
        }
        programExists = programExists || topic.programs.length > 0;
      } else {
        programExists = false;
      }
      const topicFacetItem = elementExists(topic.topic, facets['topic']);
      if (topicFacetItem) {
        topic.count = topicFacetItem.count;
      } else {
        if (topicIndex > -1) {
          topicMetaData.splice(topicIndex, 1);
        }
      }
    }
  }
  topicMetaData = updateTopicWithFacets(topicMetaData, facets);
  return topicMetaData;
}

export function updateWithFacets(locationMetaData: any, facets: any[], isFtla: boolean = false) {
  if (locationMetaData) {
    if (isFtla) {
      for (const continent of locationMetaData) {
        for (const country of continent.countryList) {
          for (const region of country.regionList) {
            for (const division of region.divisionList) {
              for (const state of division.stateList) {
                const jurisdictionFacetItem = elementExists('Not Specified', facets[locationTags.jurisdiction]);
                if (jurisdictionFacetItem) {
                  state.countyList.push({county: jurisdictionFacetItem.value, count: jurisdictionFacetItem.count});
                }
              }
              const stateFacetItem = elementExists('Not Specified', facets[locationTags.state]);
              if (stateFacetItem) {
                division.stateList.push({state: stateFacetItem.value, countyList: [], count: stateFacetItem.count});
              }
            }
            const divistionFacetItem = elementExists('Not Specified', facets[locationTags.division]);
            if (divistionFacetItem) {
              region.divisionList.push({division: divistionFacetItem.value, stateList: [], count: divistionFacetItem.count});
            }
          }
          const regionFacetItem = elementExists('Not Specified', facets[locationTags.region]);
          if (regionFacetItem) {
            country.regionList.push({region: regionFacetItem.value, divisionList: [], count: regionFacetItem.count});
          }
        }
        const countryfacetItem = elementExists('Not Specified', facets[locationTags.country]);
        if (countryfacetItem) {
          continent.countryList.push({country: countryfacetItem.value, regionList: [], count: countryfacetItem.count});
        }
      }
      const facetItem = elementExists('Not Specified', facets[locationTags.continent]);
      if (facetItem) {
        locationMetaData.push({continent: facetItem.value, countryList: [], count: facetItem.count});
      }
    } else {
      for (const country of locationMetaData) {
        for (const region of country.regionList) {
          for (const division of region.divisionList) {
            for (const state of division.stateList) {
              const jurisdictionFacetItem = elementExists('Not Specified', facets[locationTags.jurisdiction]);
              if (jurisdictionFacetItem) {
                state.countyList.push({county: jurisdictionFacetItem.value, count: jurisdictionFacetItem.count});
              }
            }
            const stateFacetItem = elementExists('Not Specified', facets[locationTags.state]);
            if (stateFacetItem) {
              division.stateList.push({state: stateFacetItem.value, countyList: [], count: stateFacetItem.count});
            }
          }
          const divistionFacetItem = elementExists('Not Specified', facets[locationTags.division]);
          if (divistionFacetItem) {
            region.divisionList.push({division: divistionFacetItem.value, stateList: [], count: divistionFacetItem.count});
          }
        }
        const regionFacetItem = elementExists('Not Specified', facets[locationTags.region]);
        if (regionFacetItem) {
          country.regionList.push({region: regionFacetItem.value, divisionList: [], count: regionFacetItem.count});
        }
      }
      const facetItem = elementExists('Not Specified', facets[locationTags.country]);
      if (facetItem) {
        locationMetaData.push({country: facetItem.value, regionList: [], count: facetItem.count});
      }
    }
  }
  return locationMetaData;
}

export function updateTopicWithFacets(topicMetaData: any, facets: any[]) {
  if (topicMetaData) {
    for (const topic of topicMetaData) {
      const programFacetItem = elementExists('Not Specified', facets['programArea']);
      if (programFacetItem) {
        if(topic.programs != undefined && topic.topic != 'Not Specified') {
          let index = topic.programs.find(program => program.program === programFacetItem.value);
          if(!index) {
            topic.programs.push({program: programFacetItem.value, count: programFacetItem.count});
          }
        }
      }
    }
    const facetItem = elementExists('Not Specified', facets['topic']);
    if (facetItem) {
      let index = topicMetaData.find(topic => topic.topic === facetItem.value);
      if(!index) {
        topicMetaData.push({topic: facetItem.value, programs: [], count: facetItem.count});
      }
    }
  }
  return topicMetaData;
}

export function elementExists(value: any, facetItem: any[]) {
  if (facetItem) {
    return facetItem.find(facet => facet.value === value);
  }
  return null;
}

export function getShareResultsModel(searchRequest, shareResultsOption, isFavorites) {
  return {
    query: searchRequest,
    ...shareResultsOption,
    isFavorites
  };
}
