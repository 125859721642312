<div class="container manage-users">
    <div class="heading d-flex">
          <div class="page-title">
            <div>
                <span calss="page-title"><h1>Manage Users</h1></span>
            </div>
        </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="search_icon_profile">
        <i class="fas fa-search"></i>
    </div>
    <div class="searchBoxForm_profile">
            <input type="text" id="searchTerm" name="searchTerm" placeholder="Search users..." autocomplete="off"
                         [(ngModel)]="searchTerm" (keyup)="search()"  class="searchBox_Profile"  />
    </div>
    <button type="button"  id="btnAddProfile" class="btn btn-success button-style" (click)="addUser()">Invite User</button>
    <button type="button"  id="btnExportProfile" class="btn btn-success button-style" (click)="exportUsers()">Export Users</button>
    <button type="button"  id="btnUserStatus" class="btn btn-success button-style" *ngIf="isJJDataPortal" (click)="GetOktaStatus()">Check Okta Status</button>
</div>
    <div class="row">
        <div class="col-12 table-responsive">
          <table class="table table-striped sortable" id="printableArea">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th><div style="display:inline-flex"> Full Name <a (click)="toggleTag('fullName')" [hidden]="fullnameAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!fullnameAsc" (click)="toggleTag('fullName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex">Email<a (click)="toggleTag('userPrincipalName')" [hidden]="emailAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!emailAsc" (click)="toggleTag('userPrincipalName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex">Title<a (click)="toggleTag('jobTitle')" [hidden]="titleAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!titleAsc" (click)="toggleTag('jobTitle')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex"> Organization <a (click)="toggleTag('organization')" [hidden]="organizationAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!organizationAsc" (click)="toggleTag('organization')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex">Role<a (click)="toggleTag('roleName')" [hidden]="roleAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!roleAsc" (click)="toggleTag('roleName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of manageUsers; let rowIndex=index;">
                <td>
                    <div class="delete-icon" [ngClass]="{ 'disable': !user.isPending && viewAsAdmin }">
                    <span *ngIf="currentUser.id !== user.id" class="role-icon" (click)="removeUser(user, rowIndex)">
                        <em class="far fa-trash-alt"></em>
                    </span>  
                </div>
            </td>
            <td>
                <div class="delete-icon" [ngClass]="{ 'disable': !user.isPending && viewAsAdmin }">
                <span *ngIf="currentUser.id !== user.id" class="role-icon" (click)="editUser(user, rowIndex)">
                    <em class="far fa-edit"></em>
                </span>  
            </div>
        </td>
         <td>
                <div class="delete-icon" title = "Resend Invitation Email" [ngClass]="{ 'disable': !user.isPending && viewAsAdmin }"  *ngIf="user.oktaStatus=='PROVISIONED' ">
                    <span *ngIf="currentUser.id !== user.id" class="role-icon" (click)="sendOktaReactivationEmail(user.userPrincipalName)">
                        <em class="fa fa-envelope"></em>
                    </span>  
                </div>
        </td>
                <td>{{user.fullName}}</td>
                <td>{{user.userPrincipalName}}</td>
                <td>{{user.jobTitle}}</td>
                <td>{{user.organization}}</td>
                <td>{{user.roleDetails.description}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
          <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)"  [boundaryLinks]="true" [maxSize]="10"></pagination>
        </div>    
        <div class="col-md-2 padding-2p">
            <div class="col-xs-12">
                <button type="button" id="btnImportUsers" class="btn btn-success button-style"  [routerLink]="['/importUsers']">Bulk Upload</button>
              </div>
          </div>    
        <div class="col-md-2 pull-right padding-2p">
          <div class="col-xs-12">
            <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
          </div>
        </div>
      </div>
    <div class="clearfix">
        <div class="pull-right">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
    </div>
<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <form [formGroup]="userForm">
            <div>Email</div>
                <div>
                    <input id="txtemail" type="text" name="txtemail" placeholder="Email Address" [ngClass]="{'input-error': userForm.controls.userPrincipalName.touched && userForm.controls.userPrincipalName.status=='INVALID'}"
                     autocomplete="disabled" formControlName="userPrincipalName" class="form-control" required (blur)="checkUserExists()" />
                </div>
                <div>First Name </div>
                    <div>
                        <input id="txtfname" type="text" name="txtfirstname" placeholder="First Name"  autocomplete="new-txtfname" 
                        [ngClass]="{'input-error': userForm.controls.firstName.touched && userForm.controls.firstName.status=='INVALID'}"
                        formControlName="firstName" class="form-control" required />
                    </div>
                <div>Last Name </div>
                <div>
                    <input id="txtlname" type="text"  placeholder="Last Name"  
                    [ngClass]="{'input-error': userForm.controls.lastName.touched && userForm.controls.lastName.status=='INVALID'}"
                    autocomplete="disabled" formControlName="lastName" class="form-control" required />
                </div>
                <div>Title </div>
                <div>
                    <input id="txttitle" type="text"  placeholder="Title"  autocomplete="disabled" formControlName="title" class="form-control" />
                </div>
                <div>Organization </div>
                <div>
                    <input id="txtOrganization" type="text"  placeholder="Organization"  autocomplete="disabled" formControlName="organization" class="form-control" />
                </div>
                <div *ngIf="isJJDataPortal">Site </div>
                <div *ngIf="isJJDataPortal">
                        <ng-multiselect-dropdown formControlName="site"
                                [placeholder]="'Select sites'"
                                [settings]="dropdownSettings"
                                [data]="dropdownList"
                                [(ngModel)]="selectedItems"
                                (onSelect)="onItemSelect($event)"
                                (onDeSelect)="onItemDeSelect($event)"
                        >
                    </ng-multiselect-dropdown>
                 <span *ngIf="isSiteNotSelected"  class="alert-warning">Please select site</span>
                </div>
                <div *ngIf="!isFTLAPortal">Role</div>
                <div *ngIf="!isJJDataPortal && !isFTLAPortal">
                    <input  id="rbUser" type="radio" value="User" formControlName="role" >User
                </div>
                <div *ngIf="!isJJDataPortal && !isFTLAPortal">
                    <input id="rbModerator" type="radio" value="Moderator" formControlName="role"  matTooltip="A Moderator has more responsibilities">Moderator
                </div>
                <div *ngIf="!isFTLAPortal">
                    <input id="rbAdmin" type="radio" value="Admin"  formControlName="role" matTooltip="A Admin has most responsibilities" > Admin
                </div>
                <div *ngIf="isJJDataPortal">
                    <input id="rbTA" type="radio" value="CaseyConsultant" formControlName="role"  matTooltip="Casey Consultant (TA)"> Casey Consultant (TA)
                </div>
                <div *ngIf="isJJDataPortal">
                    <input id="rbState" type="radio" value="State"  formControlName="role" matTooltip="State" > State (TA)
                </div>
                <div *ngIf="isJJDataPortal">
                    <input id="rbProbationChief" type="radio" value="ProbationChief" formControlName="role"  matTooltip="Probation Chief"> Probation Chief (Local)
                </div>
                <div *ngIf="isJJDataPortal">
                    <input id="rbOtherLocalLeader" type="radio" value="OtherLocalLeader"  formControlName="role" matTooltip="Other Local Leader" > Other Local Leader
                </div>
                <div *ngIf="isJJDataPortal">
                    <input id="rbDataLead" type="radio" value="DataLead"  formControlName="role" matTooltip="Data Lead" > Data Lead
                </div>
        </form>
    </div>
    
    <div class="col-md-4 px-5">
        <button type="button" (click) = "cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUsers()" [disabled]="isUserExists || userForm.invalid">{{buttonText}}</button>
    </div>
    <div class="row padding-top-20">
        <span class="alert alert-danger" role="alert" *ngIf='isUserExists==true'>User already exists</span>
    </div>
  </div>
  <div class="backdrop" *ngIf="isAddOpen"></div>

<app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <div class="col-sm-12 content-style">
            <div *ngIf="dataItem && dataItem.managersExists && dataItem.tasksExists">
                <span>{{dataItem.deleteUser.fullName}} will be deleted </span><span *ngIf="dataItem.manager">{{dataItem.manager.fullName}}</span>
                <select class="form-control select" id="selectModerator" [(ngModel)]="selectedModerator" *ngIf="!dataItem.manager">
                    <option *ngFor="let manager of dataItem.manageUsers" [ngValue]="manager">{{manager.fullName}}</option>
                </select>
            </div>
            <div *ngIf="dataItem && !dataItem.tasksExists">
                <span>Are you sure you want to delete {{dataItem.deleteUser.fullName}}?</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style" *ngIf="dataItem && (dataItem.managersExists || !dataItem.tasksExists)">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="confirmUserToRemove(dataItem.deleteUser, dataItem.rowIndex)">
                    <span>Ok</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>


<app-modal #saveModal contentStyle="data-save-model" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate>
        <div class="col-sm-12 content-style">
            <div>
                <span>{{saveMessage}}</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeSaveModel()">
                    <span>Ok</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<app-modal #userbulkuploadconfirmationModal contentStyle="delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <app-user-bluk-upload  (closeModal)="closeBulkUploadConfirmModal($event)"></app-user-bluk-upload>
    </ng-template>
</app-modal>