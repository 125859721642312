<div class="container approve-data">
    <div class="heading d-flex">
        <div class="page-title">
            <div>
                <span class="page-title"><h1>{{ pageTitle}}</h1></span>
            </div>
        </div>
        <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
            <div class="col-md-12">
                <div class="alert alert-danger" id="formSubmitError">
                {{ formSubmitError }}
                </div>
            </div>
        </div>
    </div>

    <div>
        <accordion [closeOthers]="isFromImportExcel">
            <ng-template ngFor let-setItem let-setIndex="index" [ngForOf]="importJobs">
                <accordion-group #job [isOpen]="isFromImportExcel">
                    <div id="accordianTab" accordion-heading class="tab" (click)="modifySet(setItem, false, job.isOpen)">
                        <!-- <div class="tab-label"> -->
                            <div class="tab-label-text">
                                <div class="col-md-4">
                                    <span class="fullName"><span ><b>Site Name :</b> {{setItem?.site?.name}}</span></span>
                                    </div>
                                    <div class="col-md-4">
                                    <span class="fullName"><span ><b>Job Status :</b> {{setItem?.status}}</span></span>
                                    </div>
                                    <div class="col-md-4">
                                    <span class="fullName"><span ><b>Uploaded Date :</b> {{setItem?.createdDateTime | timezone:'MM/DD/YYYY hh:mm A' }}</span></span>
                                    </div>
                            </div>
                        <!-- </div> -->
                    </div>
                    <div class="row">
                        <div class="col-12 table-responsive">
                            <table class="table table-striped sortable" id="printableArea">
                                <tbody>
                                    <tr>
                                        <td>Number of Rows: {{setItem?.numberOfRows}}</td>
                                        <td>Number of Warning Rows <b>(row numbers)</b>: {{setItem?.yearMissingRows?.length}} <b>({{setItem.yearMissingRows.join(", ")}})</b></td>
                                    </tr>
                                    <tr>
                                        <td>Number of Valid Rows: {{setItem?.numberOfRows- (setItem?.numberMissingRows?.length + setItem?.yearMissingRows?.length)}}</td>
                                        <td>Number of Invalid Rows <b>(row numbers)</b>: {{setItem?.numberMissingRows?.length}} <b>({{setItem?.numberMissingRows?.join(", ")}})</b> </td>
                                    </tr>
                                    <tr>
                                        <td><button type="button"  *ngIf="!isErrorWarnings" [ngClass]="{ 'disabled':  viewAsAdmin }"   id="btnApprove" class="btn btn-success" (click)="ApproveRejectMeasureData(setItem.jobId,true)">Approve</button></td>
                                        <td style="text-align:right;"><button type="button"  [ngClass]="{ 'disabled':  viewAsAdmin }"   id="btnReject" class="btn btn-warning " (click)="ApproveRejectMeasureData(setItem.jobId,false)">Reject</button></td>
                                    </tr>
                                    <!-- <tr *ngIf="isFromImportExcel">
                                        <td>
                                            <button *ngIf="!isErrorWarnings" [(ngModel)]="isErrorWarnings" type="button"  [ngClass]="{ 'disabled':  viewAsAdmin }"   id="btnImport" class="btn btn-success"  (click)="ImportMeasureData()">Import Data</button>
                                        </td>
                                        <td style="text-align:right;"><button type="button"  [ngClass]="{ 'disabled':  viewAsAdmin }"   id="btnImportReject" class="btn btn-warning " (click)="RejectImportMeasureData()">Reject Import</button></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12 table-responsive" *ngIf="!isApprovedRejected">
                            <table class="table sortable">
                                <thead>
                                    <tr>
                                        <th>Row Number</th>
                                        <th  class="comments-td">Comments</th>
                                        <th>Measure</th>
                                        <th>Year</th>
                                        <th>Time Period Type</th>
                                        <th>Time Period</th>

                                        <th>Female</th>
                                        <th>Male</th>
                                        <th>Non-Binary</th>
                                        <th>Not Available</th>
                                        
                                        <th>Hispanic</th>
                                        <th>Non-Hispanic</th>
                                        <th>Not Available</th>

                                        <th>Amer Indian/AK Native</th>
                                        <th>Asian</th>
                                        <th>Black/African American</th>
                                        <th>Latino/Hispanic</th>
                                        <th>White</th>
                                        <th>Other Race</th>
                                        <th>Two or more Races</th>
                                        <th>Not Available</th>

                                        <th>Gender Total</th>
                                        <th>Ethnicity Total</th>
                                        <th>Race Total</th>
                                        <th>Latino counted as Race, Ethnicity or Both?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of stagingData; index as rowIndex;" [ngClass]="(data.comments?.length > 0 ? ((data.comments.indexOf('warning') >=0 && !data.comments.includes('error :'))? 'data-warning':'data-error'): 'data-success')">
                                        <td>{{rowIndex+1}}</td>
                                        <td class="comments-td" style="white-space: pre-line">{{data?.comments}}</td>
                                        <td>{{data?.number}}</td>
                                        <td>{{data?.year}}</td>
                                        <td>{{data?.timePeriodType}}</td>
                                        <td>{{data?.timePeriod}}</td>
                                        <td>{{data?.genderFemale}}</td>
                                        <td>{{data?.genderMale}}</td>
                                        <td>{{data?.genderNonBinary}}</td>
                                        <td>{{data?.genderNA}}</td>

                                        <td>{{data?.ethnicityHispanic}}</td>
                                        <td>{{data?.ethnicityNonHispanic}}</td>
                                        <td>{{data?.ethnicityNA}}</td>

                                        <td>{{data?.raceAIAKNative}}</td>
                                        <td>{{data?.raceAsian}}</td>
                                        <td>{{data?.raceBAA}}</td>
                                        <td>{{data?.raceHispanic}}</td>
                                        <td>{{data?.raceWhite}}</td>
                                        <td>{{data?.raceOther}}</td>
                                        <td>{{data?.racesTwoOrMore}}</td>
                                        <td>{{data?.racesNA}}</td>

                                        <td>{{data?.genderValue}}</td>
                                        <td>{{data?.ethnicityValue}}</td>
                                        <td>{{data?.raceValue}}</td>
                                        <td>{{data?.latino}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="setItem.status =='Approved'" class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-4"> These data was approved and refreshed.</div>
                            <div class="col-md-3"></div>
                        </div>

                        <div *ngIf="setItem.status =='Rejected' " class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-4"> These data was removed.</div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                </accordion-group>
            </ng-template>
        </accordion>
    </div>
</div>