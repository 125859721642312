export const SystemPermissions = {
        UploadTemplate: 'UploadDatasetTemplate',
        UploadFile: 'UploadFile',
        UploadLink: 'UploadLink',
        UploadDataset: 'UploadDataset',
        ViewBox: 'ViewBox',
        ViewOneDrive: 'ViewOneDrive',
        ViewDropbox: 'ViewDropbox',
        DatasetApprove: 'DatasetApprove',
        ApproveDocument: 'ApproveDocument',
        RejectDocument: 'RejectDocument',
        ModifiyDocument: 'ModifiyDocument',
        RoleMapping: 'RoleMapping',
        ViewAuditLogs: 'ViewAuditLogs',
        ExportAuditLogs: 'ExportAuditLogs',
        PrintAuditLogs: 'PrintAuditLogs',
        ViewNewsfeed: 'ViewNewsfeed',
        CreateNewsfeed: 'CreateNewfeed',
        Secuirty : 'Security',
        Helpdesk: 'Helpdesk',
        REED : 'REED',
        HR: 'HR',
        JDAI: 'JDAI',
        GMS: 'GMS',
        Travel: 'Travel',
        Search : 'Search',
        ShareToSlack : 'ShareToSlack',
        ShareDocument : 'ShareDocument',
        ViewSubmitResource: 'ViewSubmitResource',
        ViewSupportLink : 'ViewSupportLink',
        ViewMyFiles: 'ViewMyFiles',
        ApproveBanner: 'ApproveBanner',
        RejectBanner: 'RejectBanner',
        ViewBanner: 'ViewBanner',
        SendToSlack: 'SendToSlack',
        CanSaveFavorites: 'CanSaveFavorites',
        CanViewFavorites: 'CanViewFavorites',
        ViewReports : 'ViewReports',
        ShareReports: 'ShareReports',
        ViewGoogleAnalytics: 'ViewGoogleAnalytics',
        ManageSuperAdmins: 'ManageSuperAdmins',
        ManageRoles: 'ManageRoles',
        ManageModerators: 'ManageModerators',
        ManageAdmins : 'ManageAdmins',
        ManagePermissions: 'ManagePermissions',
        ViewAsUser: 'ViewAsUser',
        DeleteDocument: 'DeleteDocument',
        ConfigureOKTASettings: 'ConfigureOKTASettings',
        ViewMetadataTags: 'ViewMetadataTags',
        ViewPortalConfiguration: 'ViewPortalConfiguration',
        DownloadReports :'DownloadReports',
        ReportsConfiguration:'ReportsConfiguration',
        ReportProfileConfiguration :'ReportProfileConfiguration',
        UploadReport:'UploadReport',
        ManageUsers: 'ManageUsers',
        ManageSurveyPeriods:'ManageSurveyPeriods',
        ViewFAQPage:'ViewFAQPage',
        ManageFAQPage:'ManageFAQPage',
        ViewSite:'ViewSite',
        ViewMeasures:'ViewMeasures',
        ManageSite:'ManageSite',
        ManageGroup:'ManageGroup',
        ManageMeasure:'ManageMeasure',
        ManageSiteMeasure:'ManageSiteMeasure',
        ViewSiteMeasure:'ViewSiteMeasure',
        ManageDataCollections:'ManageDataCollections',
        ViewDataCollections:'ViewDataCollections',
        ManageCustomMeasure:'ManageCustomMeasure',
        ManageMeasureValidations:'ManageMeasureValidations',
        ShowHideDocumentTags :'ShowHideDocumentTags',
        UpdateOrganizatinProfile:'UpdateOrganizationProfile',
        UpdateTechnologies: 'UpdateTechnologies'
    };
