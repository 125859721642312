import { Component, OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { StateService } from './core/state-manager/appstateservice';
import { AppInsightService } from './common/logging/appinsight.service';
import { environment } from 'src/environments/environment.dev';
import{ Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'AECF SPA Application';
  googleAnalyticsId = '';
  isReportOpened: Subscription;
  fullscreen:boolean = false;
  isportalConfig=false;
  public spinkit = Spinkit;
  isIframe: boolean=false;
  isGrantsPortal :boolean=false;
  isShowHideFooter:boolean=false;
  constructor(private bnIdle: BnNgIdleService,
    private stateService: StateService,
    private appInsightService: AppInsightService,
    public router: Router) { // initiate it in your component constructor
   }

  ngOnInit() {
    
    this.isIframe = window !== window.parent && !window.opener;
    if (environment.production) {
      this.appInsightService.logTrace('App initialised at ' + new Date().toString(), '', 'Information');
    }
    this.stateService.isReportOpened.subscribe(x=>{
      this.fullscreen =x;
    });

    const bodyElement = document.body;
    bodyElement.className= this.stateService.isJJGroup() ? 'jjportalfont' :'otherportalsfont';
    this.isGrantsPortal = this.stateService.isGrantsGroup();

    if(this.isGrantsPortal)
    bodyElement.style.paddingBottom='0px';
      this.isShowHideFooter = !this.isGrantsPortal ||  this.fullscreen;
   }


   get portalConfig()
   {
    return this.stateService.isPortalConfigGroup();
   }
}
