function sortComparator(valueA, valueB) {
    const valueAMissing = valueA === null || valueA === undefined;
    const valueBMissing = valueB === null || valueB === undefined;
    if (valueA && valueA.toNumber) {
        valueA = valueA.toNumber();
    }
    if (valueB && valueB.toNumber) {
        valueB = valueB.toNumber();
    }
    if (valueAMissing && valueBMissing) {
        return 0;
    }
    if (valueAMissing) {
        return 1;
    }
    if (valueBMissing) {
        return -1;
    }
    if (typeof valueA === 'string') {
        return doQuickCompare(valueA, valueB);
    }
    if (valueA < valueB) {
        return -1;
    } else if (valueA > valueB) {
        return 1;
    } else {
        return 0;
    }
    function doQuickCompare(a, b) {
        return (a > b ? 1 : (a < b ? -1 : 0));
    }
}

export function compareItems(sortedNodeA, sortedNodeB, sortOption = 'ASC') {
    let inverter = 1;
    if (sortOption === 'DESC') {
        inverter = -1;
    }

    let comparatorResult = sortComparator(sortedNodeA, sortedNodeB);
    if (comparatorResult !== 0) {
        comparatorResult = comparatorResult * inverter;
    }

    return comparatorResult;
}
