import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const uploadItemReqValidator = (requiredIf): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null  => {
      // self value
      const controlValue = control.value;
      let requiredIfValueExists = true;
      if (requiredIf) {
        requiredIfValueExists = false;
        const depControl = control.parent && control.parent.get(requiredIf);
        if (depControl && depControl.value && depControl.value.length > 0) {
          requiredIfValueExists = true;
        }
      }

      // require check
      if (requiredIfValueExists && (!controlValue || controlValue.length === 0)) {
          return {
            validateRequired: true
          };
        }

      return null;
    };
};
