import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GoogleAnalyticsService } from '../google/google-analytics.service';

@Injectable()
export class ErrorLogService {

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  // Log error method
  logError(error: any) {
    const date = new Date().toUTCString();

    if (error instanceof HttpErrorResponse) {
      // The response body may contain clues as to what went wrong
      this.googleAnalyticsService.eventEmitter(String((error as HttpErrorResponse).status),
        error.message, 'JS Error');
      console.error(date, 'There was an HTTP error.', error.message, 'Status code:',
                (error as HttpErrorResponse).status);
    } else {
      this.googleAnalyticsService.eventEmitter(error.stack, error.message, 'JS Error');
      console.error(date, 'Nobody threw an Error but something happened!', error.message, error.stack);
    }
  }
}
