import { StringUtils } from '@azure/msal-browser';
import { first, last } from 'rxjs/operators';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { SiteModel } from 'src/app/jj/models/site.model';
import internal from 'stream';

export class ReportDetail {
    reportId: string;
    reportName: string;
    reportTitle: string;
    owner: string;
    isSelected: boolean;

    public static modelMapper(uiModel: ReportDetail) {
        return {
            reportId: uiModel.reportId,
            reportName: uiModel.reportName,
            reportTitle: uiModel.reportTitle,
            accessEnabled: uiModel.isSelected
        };
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            reportId: modelData['reportId'],
            reportName: modelData['reportName'],
            reportTitle: modelData['reportTitle'],
            owner: modelData['owner'],
            isSelected: true
        });
        return this;
    }

    public clone(data: any) {
        Object.assign(this, {
            reportId: data.reportId,
            reportName: data.reportName,
            reportTitle: data.reportTitle,
            owner: data.owner,
            isSelected: data.isSelected,
        });
        return this;
    }
}

export class ReportsList {
    reports: Array<ReportDetail> = [];

    public dataMapper(modelData: ReportDetail[]) {
        return Object.assign(modelData ? modelData.map(data => {
                                    return  Object.assign(ReportDetail.modelMapper(data));
                                }) : []);
    }

    public uiMapper(modelData: any) {
        Object.assign(this, {
            reports: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new ReportDetail().uiMapper(data));
                        }) : []
        });

        return this;
    }

    public clone(modelData: any) {
        Object.assign(this, {
            reports: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new ReportDetail().clone(data));
                        }) : []
        });

        return this;
    }
}
export class RoleDetails{
    name:string;
    description:string;
    role:Roles
}
export class UserDetail {
    id: string;
    surName: string;
    userPrincipalName: string;
    preferredLanguage: string;
    officeLocation: string;
    mobilePhone: string;
    mail: string;
    jobTitle: string;
    givenName: string;
    fullName: string;
    businessPhones: Array<string>;
    isAdmin: boolean;
    isModerator: boolean;
    isSuperAdmin: boolean;
    isEmailNotification: boolean;
    isNew: boolean;
    isDeleted: boolean;
    isModified: boolean;
    role: Roles;
    roleDetails:RoleDetails;
    roleName:string;
    unit: string;
    isPending: boolean;
    accessEnabled: boolean;
    businessUnit: string;
    reports: Array<ReportDetail>;
    organization:string;
    contact:string;
    founded:number;
    staffSize:number;
    headquarter:string;
    url:string;
    isActive:boolean;
    sites: Array<SiteModel>;
    oktaStatus:string;
    public static addRole(uiModel: any, role: Roles) {
        return {
            id: uiModel['id'],
            isNew: uiModel['isNew'],
            isDeleted: uiModel['isDeleted'],
            isModified: uiModel['isModified'],
            isAdmin: Roles.Admin === role,
            isModerator: Roles.Moderator === role,
            isSuperAdmin: Roles.SuperAdmin === role
        };
    }

    public static modelMapper(uiModel: UserDetail) {
        return {
            id: uiModel.id,
            isAdmin: Roles.Admin === uiModel.role,
            isModerator: Roles.Moderator === uiModel.role,
            isSuperAdmin : Roles.SuperAdmin === uiModel.role,
            isEmailNotification: uiModel.isEmailNotification,
            isNew: uiModel.isNew,
            isDeleted: uiModel.isDeleted,
            isModified: uiModel.isModified,
        };
    }

    public static userReportModelMapper(uiModel: UserDetail) {
        return {
            userid: uiModel.id,
            fullName: uiModel.fullName,
            accessEnabled: uiModel.reports && uiModel.reports.findIndex(x => x.isSelected) >= 0,
            reports: new ReportsList().dataMapper(uiModel.reports)
        };
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            role: modelData.role,
            roleDetails:modelData.role,
            unit: modelData['department'],
            roleName:this.getRoleName(modelData),
            isActive:modelData['isActive']
        });
        return this;
    }

    private getRole(modelData: any) {
        if (modelData['isAdmin']) {
            return Roles.Admin;
        } else if (modelData['isModerator']) {
            return Roles.Moderator;
        } else if (modelData['isSuperAdmin']) {
            return Roles.SuperAdmin;
        } else {
            return Roles.User;
        }
    }

    private getRoleName(modelData: any) {
        if (modelData['isAdmin']) {
            return 'Admin';
        } else if (modelData['isModerator']) {
            return 'Moderator';
        } else if (modelData['isSuperAdmin']) {
            return 'SuperAdmin';
        } 
        else if (modelData['role']!==null) {
            return modelData['role'].description;
        }
        else {
            return 'User';
        }
    }

    public userReportUiMapper(modelData: any) {
        Object.assign(this, modelData, {
            id: modelData['userId'],
            unit: modelData['businessUnit'],
            fullName: modelData['givenName'] + ' ' + modelData['surName'],
            reports: new ReportsList().uiMapper(modelData['reports']).reports
        });
        return this;
    }

    public userOtherUnitUiMapper(modelData: any) {
        Object.assign(this, modelData, {
            id: modelData['id'],
            userPrincipalName: modelData['userPrincipalName'],
            fullName: modelData['givenName'] + ' ' + modelData['surName'],
            unit: modelData['department']
        });
        return this;
    }

    public updateRole(role: Roles) {
        this.role = role;
        this.isAdmin = Roles.Admin === role;
        this.isModerator = Roles.Moderator === role;
        this.isSuperAdmin = Roles.SuperAdmin === role;
        this.isPending = true;
    }

    public updateReportAccess(reports) {
        this.isPending = true;
        this.reports = new ReportsList().clone(reports).reports;
    }

    public addUser(email:string,roleName:string,firstName:string,lastName:string,title:string,organization:string,isActive:boolean,sites:Array<SiteModel>,role:RoleDetails){
        Object.assign(this, {
        userPrincipalName :email,
        roleName: roleName,
        givenName:firstName,
        surName:lastName,
        jobTitle:title,
        organization:organization,
        isAdmin : roleName == "Admin" ? true:false,
        isModerator : roleName=="Moderator"?true:false,
        isActive:isActive,
        sites:sites,
        role:role
        });
        return this;
    }

    
}

export class UsersList {
    kmsUsers: Array<UserDetail> = [];

    public static getMappedUser(modelData: UserDetail[]) {
        return Object.assign(modelData ? modelData.map(data => {
            return  data.userPrincipalName;
        }) : []);
    }

    public dataMapper(modelData: UserDetail[]) {
        return Object.assign(modelData ? modelData.map(data => {
                                    return  Object.assign(UserDetail.modelMapper(data));
                                }) : []);
    }

    public userReportDataMapper(modelData: UserDetail[]) {
        return Object.assign(modelData ? modelData.map(data => {
                                    return  Object.assign(UserDetail.userReportModelMapper(data));
                                }) : []);
    }

    public uiMapper(modelData: any) {
        Object.assign(this, {
            kmsUsers: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new UserDetail().uiMapper(data));
                        }) : []
        });

        return this;
    }

    public userReportUiMapper(modelData: any) {
        Object.assign(this, {
            kmsUsers: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new UserDetail().userReportUiMapper(data));
                        }) : []
        });

        return this;
    }

    public userOtherUnitUiMapper(modelData: any) {
        Object.assign(this, {
            kmsUsers: modelData ?
                        modelData.result.map(data => {
                            return  Object.assign(new UserDetail().userOtherUnitUiMapper(data));
                        }) : []
        });

        return this;
    }
}

export class BulkUploadUserStatus {
    totalRows:number;
    invalidEmailAddressRows: string;
    invalidFirstNameRows:string;
    invalidLastNameRows:string;
    invalidRoleRows:string;
    usersAlreadyExists:string;
    importedUsers:any;
}