<div class="container">
    <div class="role-heading">
        <div class="row">
            <div class="col-md-12">
                <label class="roleAssignmentHeading">Report User Access Management</label>        
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <span>Add or remove users for other business units</span>        
            </div>
        </div>  
    </div>  
    <div *ngIf="formSubmitSuccess" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-success" id="formSubmitSuccess">
            {{ formSubmitSuccess }}
            </div>
        </div>
    </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>
    <div class="addRow col-sm-12">
        <div class="col-xs-12 col-md-10 selectButton">
            <span>Users</span>          
            <input #typeahead=bs-typeahead [(ngModel)]="selectedUserName"
                    (typeaheadOnSelect)="onUserSelect($event)"
                    [typeahead]="pendingUsers"  
                    typeaheadOptionField="fullName"                    
                    [typeaheadMinLength]="0"
                    [typeaheadScrollable]="true"
                    [typeaheadOptionsInScrollableView]=7
                    [typeaheadOptionsLimit]=itemsTosearch
                    placeholder="Select User to Add"
                    class="form-control select">
        </div>        
        <div class="col-xs-12 col-sm-2 searchUserButton">
            <button type="button" id="btnSearchUsers" class="btn btn-secondary button-style btn-block" (click)="getOtherUsers()">Search</button>
        </div>
    </div>
    <div class="assignReportsContainer">
        <div class="row header">
            <div class="col-sm-4 align-left">
               <span class="user-icon"><em class="fas fa-user"></em></span>User
            </div>
            <div class="col-sm-3 align-left">               
                <span class="header-icon"><em class="fas fa-users"></em></span>Unit
            </div>
            <div class="col-sm-5">
                <span><span class="header-icon"><em class="far fa-chart-bar"></em></span>Accessible Reports</span>
            </div> 
        </div>
        <div class="row">
            <ng-container *ngFor="let user of manageUsers; let rowIndex=index;">
                <div class="col-sm-12 userContainer">
                    <div class="col-sm-4 align-left align-row-items">                      
                        {{user.fullName}}
                    </div>
                    <div class="col-sm-3 align-left align-row-items">
                        {{user.unit}}
                    </div> 
                    <div class="col-sm-5 align-left align-row-items">
                        <ng-container *ngFor="let report of user.reports">
                            <label *ngIf="report.isSelected" class="Rectangle" (click)="removeReportFromUser(report, user)">{{ report.reportName }}  <em class='close_icon fas fa-times'></em></label>
                        </ng-container>                          
                        <span class="addBtn" (click)="showReportsModal(user)" ><em class="fa fa-plus"></em></span>                     
                    </div>                    
                </div>                
            </ng-container>               
        </div>
    </div>
    <div class="row addRowBtn">
        <button type="button" id="btnadd" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Back</button>
        <button type="button" id="btnadd" class="btn btn-secondary button-style" (click)="updateReportAccess()"  [ngClass]="{ 'disable-color': viewAsAdmin | async }">Submit</button>
    </div>
</div>


<app-modal #assignReportsDialog contentStyle="assign-reports-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-reports="dataItem.entityData.reports" let-user="dataItem.entityData.user">
        <div class="assign-reports-content">
            <div class="row header">
                <div class="col-sm-1 align-left">
                    
                </div>
                <div class="col-sm-3 align-left">
                    Report Name
                </div>
                <div class="col-sm-4 align-left">               
                    Report Title
                </div>
                <div class="col-sm-4">
                    Owner
                </div> 
            </div>
            <div class="row">
                <ng-container *ngFor="let report of reports; let rowIndex=index;">
                    <div class="col-sm-12 reportsContainer">
                        <div class="col-sm-1 align-role-items">
                            <input [(ngModel)]="report['isSelected']" class="selectCheck" [value]="report['isSelected']" name="reportSelect{{rowIndex}}" id="btn-{{rowIndex}}-reportSelect"
                            type="checkbox">
                        </div> 
                        <div class="col-sm-3 align-left">                      
                            {{report.reportName}}
                        </div>
                        <div class="col-sm-4 align-left">
                            {{report.reportTitle}}
                        </div> 
                        <div class="col-sm-4 align-role-items">
                            {{report.owner}}
                        </div>                    
                    </div>                
                </ng-container>               
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" style="width: 100px;" (click)="closeAssignReportsModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
            <button type="button" class="btn button-style" (click)="AddReportsToUser(reports, user)">
                <span>Grant Access</span>
            </button>
            </div>
        </div>
   </ng-template>  
</app-modal>
