export enum Roles {
    None = 0,
    Admin = 1,
    Moderator = 2,
    SLT = 3,
    User = 4,
    SuperAdmin = 5,
    CaseyConsultant=6,
    State=7,
    ProbationChief=8,
    OtherLocalLeader=9,
    DataLead=10,
    CaseyAllSites=14
}
