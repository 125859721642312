import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { SystemPermissions } from 'src/app/permissions';
import { PortalConfig, ResourcesType } from 'src/app/portal-config/shared/portal-config.model';
import { PortalConfigService } from 'src/app/portal-config/shared/portal-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-jj-home',
  templateUrl: './jj-home.component.html',
  styleUrls: ['./jj-home.component.scss']
})
export class JJHomeComponent implements OnInit {
  bannerConfig: PortalConfig;
  resourcesType = ResourcesType;
  title = '';
  bgImage = '/assets/images/JJdataHomePage.png';
  permissions = SystemPermissions;
  portalbannerConfig:boolean=false;
  reportName='landingPageSingleSiteUser';
  displayOption='FitToPage';
  hideVisuals=new Array<string>('JDAI Since ','Probation Transformation since ',' County, ');
  constructor(private portalService: PortalConfigService,
               private currentState: StateService,
               private titleService: Title ) { }
  ngOnInit() {
    //this.getConfig();
    this.titleService.setTitle(environment.jjdataPageTitle);
  }

  ischeckPermissions(permission: string): boolean {  return this.currentState.permissionCheck(permission); }

  getConfig() {
    this.portalService.getConfig(this.resourcesType.Banner).subscribe(data => {
      this.bannerConfig = new PortalConfig().uiMapper(data);
      if (this.bannerConfig.resourcePath !== null && this.bannerConfig.resourcePath !== undefined) {
        this.title = this.bannerConfig.title;
        this.bgImage =  decodeURIComponent(decodeURIComponent(this.bannerConfig.resourcePath));
        this.portalbannerConfig=true;
      }
    });
  }
}
