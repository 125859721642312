import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StateService } from '../../state-manager/appstateservice';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {
  static googleAnalyticsId = '';
  user: any;

  constructor(private currentState: StateService) {
    this.user = this.currentState.getUserData();
  }

  static loadGoogleAnalytics(trackingID: string, gtmID: string): void {
    this.googleAnalyticsId = trackingID;
    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);

    if (environment.gtmEnable) {
      let gaScript3 = document.createElement('script');
      gaScript3.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
        `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
        `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=`+
        `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
        `})(window,document,'script','dataLayer','${ gtmID }');`;

      let gaScript4 = document.createElement('noscript');
      let gaIframe = document.createElement('iframe');
      gaIframe.setAttribute('height', '0');
      gaIframe.setAttribute('width', '0');
      gaIframe.setAttribute('style', 'display:none;visibility:hidden');
      gaIframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${ gtmID }`);
      gaScript4.append(gaIframe);
      document.documentElement.firstChild.appendChild(gaScript3);
      document.body.appendChild(gaScript4);
    }
  }

  public eventEmitter(
    eventAction: string,
    eventLabel: string,
    eventCategory: string,
    eventValue: number = null
  ){
    if (environment.gaEnable) {
      this.user = this.currentState.getUserData();
      if (this.user != null && typeof this.user.id !== 'undefined' && this.user.id != null) {
         gtag('event', eventAction, {
          event_category: eventCategory,
          event_label: eventLabel,
          value: eventValue,
          userCustId: this.user.id
        });
      } else {
        gtag('event', eventAction, {
          event_category: eventCategory,
          event_label: eventLabel,
          value: eventValue
        });
      }
    }
  }

  public pageViewTrack(
    pagePath: string,
    pageTitle: string = null
  ) {
    if (environment.gaEnable) {
      this.user = this.currentState.getUserData();
      if (this.user != null && typeof this.user.id !== 'undefined' && this.user.id != null) {
        gtag('config', GoogleAnalyticsService.googleAnalyticsId, {
          custom_map: {
            dimension1: 'userCustId'
          },
          page_path: pagePath,
          page_title: pageTitle,
          userCustId: this.user.id
        });
      } else {
         gtag('config', GoogleAnalyticsService.googleAnalyticsId, {page_path: pagePath, page_title: pageTitle});
      }
    }
  }
}
