<div class="container">
    <div class="page-title">
        <div>
            <span calss="page-title"><h1>Download Reports</h1></span>
        </div>
    </div>
        <div class="row">
                <p>View your exported reports below</p>        
        </div> 
    <div class="querySearchContainer">
        <div class="row header">
            <div class="col-sm-4 align-left">
                <i class="fas fa-chart-line"></i>&nbsp;&nbsp;Report Name
            </div>
            <div class="col-sm-4 align-left">               
             File Type
            </div>
            <div class="col-sm-4 align-left">               
                Download <i class="fas fa-download"></i>
            </div>    
        </div>
        <div class="row items">
            <ng-container *ngFor="let report of exportedReports; let rowIndex=index;">
                <div class="col-sm-12 userContainer">
                    <div class="col-sm-4">
                            &nbsp;&nbsp;&nbsp;&nbsp;{{report.reportName}}
                    </div>
                    <div class="col-sm-4" >
                        <ng-container *ngIf="report.fileExtension==='PDF'">
                            <i class="far fa-file-pdf"></i> Adobe PDF
                        </ng-container>
                        <ng-container *ngIf="report.fileExtension==='PPTX'">
                            <i class="far fa-file-powerpoint"></i> PowerPoint
                        </ng-container>
                    </div>
                    <div class="col-sm-3 align-left" *ngIf="report.status==='Completed'"> 
                        <a (click)="downloadReport(report)" class="form-control searchButton download_button"><span>Download </span><em class='button-icon fas fa-download'></em></a>
                    </div>    
                    <div class="col-sm-3 align-left" *ngIf="report.status!=='Completed'"> 
                        {{report.status}}
                    </div>                                          
                </div>                
            </ng-container>   
        </div>
        </div> 
        <div class="row addRowBtn">
        </div>
    </div>