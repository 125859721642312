<div class="">
    <div class="overcontainer">
        <div class="row" *ngIf="portalbannerConfig" [ngClass]="{'titleClass':portalbannerConfig}">
            <div class="col-md-3"></div>
            <div class="col-md-9">
                <div>
                    <div>
                      <h1>{{title}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 jjdatacontainer">
                <h2>WELCOME TO PRO-DATA</h2>
                <p>
                    Pro-DATA is the Annie E. Casey Foundation's dashboard and portal for jurisdictions transforming juvenile probation. 
                    DATA in this case stands for Data Accessible to All. 
                </p>
                <p>
                    This tool was created to support justice system leaders and staff; the communities most affected by the justice system; 
                    young people and their families; advocates; and everyone with a vested interest in getting young people on track for long-term success. 
                </p>
                <p>
                    Pro-DATA supports data-driven decision making. With Pro-DATA, users can visualize trends and monitor whether trend lines are moving in the right direction, and, if not, what actions may make a difference. 
                    The tool helps users identify opportunities for improvement and track what interventions work — and don't work, especially for the most overrepresented groups in youth justice.  
                </p>
            </div>


 <!-- <div class="col-md-6 jjdatacontainerNew">

            <ul>
                <li style="margin-bottom: 4%;">
                    <a target="_blank" href="https://aecf.az1.qualtrics.com/jfe/form/SV_1M7tQcim0ZHlSku">Sign-up for a live demo</a> of Pro-DATA and receive a temporary account allowing you to explore. 
                </li>
                <li style="margin-bottom: 4%;">
                    <a target="_blank" href="https://app.powerbi.com/view?r=eyJrIjoiNmQxMmI0NjItNmNjNC00MjgxLWExMzktNWNhNWJlNDgwM2YwIiwidCI6IjIzYWI3Mjg1LWU4ODAtNDAzMy1hNTVjLTJlODBhZTk3MTcxYSIsImMiOjF9">
                    See a list of jurisdictions</a> using Pro-DATA.
                </li>
                <li style="margin-bottom: 4%;">
                    Learn more about the 
                    <a target="_blank" href="https://www.aecf.org/work/juvenile-justice/jdai/pro-data/getting-started-with-the-juvenile-probation-practice-survey">Probation Practice Survey.</a>
                </li>
                <li style="margin-bottom: 4%;">
                    Go to the <a target="_blank" href="https://www.aecf.org/work/juvenile-justice/jdai/juvenile-probation-transformation-training-series">online Probation Transformation training series.</a>
                </li>
            </ul>
    
            </div> -->
            <div class="col-md-6" style="padding-top: 5%;">
                <app-embed-report [reportName]="reportName" 
                [displayOption]="displayOption" 
                [hideVisuals]="hideVisuals"
                [navigation]="false"></app-embed-report>
            </div>
            <div class="col-md-6"  *ngIf="ischeckPermissions(permissions.Search)">
                <div class="heading">
                    <div class="headingContent"></div>
                    <div class="searchbar" >
                        <app-search-component></app-search-component>
                    </div>
                </div>
            </div>
        </div>       
    </div>
</div>
