<ng-template #content let-d="dismiss">
    <div class="modal-container" [ngClass]="contentStyle">
        <div class="modal-header" *ngIf="showHeader">
            <legend class="heading" id="ModalEntityName">{{headerText}}</legend>
            <div *ngIf="showClose">
                <button type="button" class="close pull-right" id="modalTemplateXButton" aria-label="Close" (click)="closeModalWindow()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal-body center-content">
            <ng-template [ngTemplateOutlet]="modalContentTemplate"
            [ngTemplateOutletContext]="{dataItem: entityData}"></ng-template>
        </div>
        <div class="modal-footer" *ngIf="showFooter">
            <div *ngIf="!footerContentTemplate" class="footer-style"></div>
            <ng-template [ngTemplateOutlet]="footerContentTemplate"></ng-template>
        </div>
    </div>
</ng-template>
