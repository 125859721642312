import * as pbi from 'powerbi-client';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgxPowerBiService } from 'ngx-powerbi';
import {  Page, IEmbedConfiguration } from 'powerbi-client';
import { Observable, Subscription } from 'rxjs';
import { ReportService } from '../shared/report.service';
import { StateService } from '../../core/state-manager/appstateservice';
import { Report } from '../shared/models/ReportModel';
import { Router, ActivatedRoute } from '@angular/router';
import { AppInsightService } from 'src/app/common/logging/appinsight.service';
import { GoogleAnalyticsService } from './../../core/services/google/google-analytics.service';
import { environment } from 'src/environments/environment';
import { SystemPermissions } from '../../permissions';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { compareItems } from 'src/app/common/models/utility';
import { SiteServices } from 'src/app/jj/services/site.services';
import { ReportInstruction } from '../shared/models/ReportInstruction.model';
import { Guid } from 'guid-typescript';
import { SiteModel } from 'src/app/jj/models/site.model';
@Component({
  selector: 'aecf-report',
  templateUrl: './reportobj.component.html',
  styleUrls: ['./reportobj.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy  {
    public screenHeight: number;
    private powerBiService: NgxPowerBiService;
    config: IEmbedConfiguration;
    private pbiContainerElement: HTMLElement;
    isLoggedIn$: Observable<boolean>;
    report: Report;
    reportmodel: any;
    pages: pbi.Page[];
    currentPage: pbi.Page;
    tilesClicked: boolean;
    dashboardLoadError: boolean;
    showDashboard = false;
    loggedinSubscription: Subscription;
    permissions = SystemPermissions;
    isOptionEnabled  = true;
    resizeButtonName:any;
    userDetail:any
    showDialog:boolean;
    reportobj:pbi.Report;
    isJJDataGroup:boolean;
    isFTLAGroup:boolean;
    isGrantsPortal:boolean;
    viewAsAdmin = false;
    currentUserRole: Roles;
    siteList: any;
    activePage:pbi.Page;
    pageSlicers:pbi.VisualDescriptor[];
    reportInstruction:string;
    isEditMode:boolean;
    currentReportTab:string;
    currentUser: any;
    constructor(private reportService: ReportService,
                private currentState: StateService,
                private appInsights: AppInsightService,
                private router: Router,
                private route: ActivatedRoute,
                public googleAnalyticsService: GoogleAnalyticsService,
                private siteService: SiteServices,
                private cdr: ChangeDetectorRef) {
        this.isLoggedIn$ = this.currentState.isLoggedIn$;
        this.loggedinSubscription = this.currentState.isLoggedIn$.subscribe(logged => {
            if (logged && this.currentState.isTokenValid) {
                this.appInsights.logPageView('Dashboard' , this.router.url);
                if(this.currentState.isViewAsAdmin){
                    this.userDetail = this.currentState.getViewingUserData();
                }else{
                    this.userDetail = this.currentState.getUserData();
                }
                this.isJJDataGroup = this.currentState.isJJGroup();
                this.isFTLAGroup = this.currentState.isFTLAGroup();
                this.isGrantsPortal = this.currentState.isGrantsGroup();
                this.showDashboard = (this.userDetail && this.userDetail.reports && this.userDetail.reports.length > 0) 
                || this.router.url == '/admin/Analytics' 
                || this.router.url == '/admin/ftlareport'
                || this.router.url == '/admin/ftla-grants'
                || this.router.url == '/admin/ftla-vendor'
                || this.router.url == '/admin/Technologies'
                || this.router.url == '/admin/OrganizationProfiles';
            }
        });
       
        this.viewAsAdmin = this.currentState.isViewAsAdmin();
    }
    
    get isCaseyConsultant() { return this.currentState.getRole() === Roles.CaseyConsultant ||  this.currentState.getViewUserRole() === Roles.CaseyConsultant; }
    get isAdmin() {return this.currentState.getRole() === Roles.Admin;}

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    editReportInstruction(){
        this.isEditMode =true;
    }

    getSites() {
        this.siteService.getSites()
        .subscribe(data => {
          this.siteList =data;
          let currentUserSites =  this.currentUser.sites as SiteModel[];
          let results = currentUserSites.map(s=>s.id);
        this.siteList= this.siteList.filter(s=>results.includes(s.id));
            this.siteList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
        });
    }

    getReportInstructions(reportId: Guid,reportTab: string){
        this.reportService.getReportInstructions(reportId,reportTab).subscribe(data=>{
            this.reportInstruction =data.instructions;
            console.log(this.reportInstruction);
        });
    }

    saveReportInstructions(){
        let rptInstruction = new ReportInstruction();
        rptInstruction.instructions = this.reportInstruction;
        rptInstruction.reportTab =this.currentReportTab;
        rptInstruction.reportId= this.reportmodel.id;
        this.reportService.AddUpdateReportInstructions(rptInstruction).subscribe(x=>{
                this.isEditMode = false;
        });
    }

    cancelReportInstructions()
    {
        this.isEditMode=false;
    }

    ngOnInit() {
        if (this.viewAsAdmin) {
            this.currentUserRole = this.currentState.getViewUserRole();
            this.currentUser = this.currentState.getViewingUserData();
          } else {
            this.currentUserRole = this.currentState.getRole();
            this.currentUser = this.currentState.getUserData();
          }
        this.screenHeight = (window.screen.height);
        this.route.params.subscribe(params => {
            let reportName = params['reportName'];
            localStorage.setItem('reportName',reportName);
            this.bindReportObject(reportName);
            this.currentState.isReportOpened.next(true);
            this.resizeButtonName="OFF";
            this.getSites();
        });

       
    }

    async onSiteSelected(value:any){
        this.activePage = await this.reportobj.getActivePage();
        let reportName = localStorage.getItem('reportName');
        if(value==-1 ){
            this.bindReportObject(reportName);
            return;
        }
        else if(value==0){
            this.bindReportObject(reportName,0);
        }
        else{
            let selectedSite = this.siteList.filter(x => x.name === value)[0]; 
            this.bindReportObject(reportName,selectedSite.id);
        }
    }

    async onOptionsSelected(value:string) {
        // todo : in future implementation - v1.0 API dont have export report with filters
        const pages = await this.reportobj.getPages();
        let filters=[];
            // const page = pages[0];
            // const pageFilters = await page.getFilters();
            // const visuals = await page.getVisuals();
            // let slicers = visuals.filter(function (visual) {
            //     return visual.type === "slicer";
            // });
        
            // for (let sindex = 0; sindex < slicers.length; sindex++) {
            //     const slicer = slicers[sindex];
            //     const state = await slicer.getSlicerState();
            //     filters.push(JSON.stringify(state.filters));
            // }

        this.route.params.subscribe(params => {
            const reportName = params['reportName'];
            const fileContentType = value ==='PDF' ? 'application/pdf': 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            if(value ==='PDF' || value==='PPTX') {
                this.isOptionEnabled=false;
                let exportReportParameters ={ reportName : reportName, format : value, filters : filters};
                this.reportService.exportReport(exportReportParameters).subscribe(res => {
                this.showDialog=true;
            }, error => {  this.isOptionEnabled=true; });
                    this.googleAnalyticsService
                        .eventEmitter('Export Report', reportName, 'Report');
            }
        });       
    }

   cancelDialog():void{
    this.showDialog=false;
   }
   
    ngOnDestroy() {
        if (this.loggedinSubscription) {
            this.loggedinSubscription.unsubscribe();
        }
        this.currentState.isReportOpened.next(false);
    }

    onToggleScreen():void{
        if(this.currentState.showheaderAndFooter){
            this.currentState.isReportOpened.next(false);
            this.resizeButtonName="ON";
        }
        else {
            this.currentState.isReportOpened.next(true);
            this.resizeButtonName="OFF";
        }
    }
    gotoDownloads():void {
        this.router.navigateByUrl('exportReports');
    }

    getGAIdentifier() {
            if (this.currentState.isKmsGroup()) {
                return 'kms-' + environment.server.toLowerCase();
            } else  if (this.currentState.isReportingGroup()) {
                return 'reporting-' + environment.server.toLowerCase();
            } else  if (this.currentState.isAllGroup()) {
                return 'all-' + environment.server.toLowerCase();
            } else  if (this.currentState.isJDAIGroup()) {
                return 'jdai-' + environment.server.toLowerCase();
            } else  if (this.currentState.isThriveGroup()) {
                return 'vision-' + environment.server.toLowerCase();
            } else  if (this.currentState.isFTLAGroup()) {
                return 'ftla-' +  environment.server.toLowerCase();
            } else  if (this.currentState.isJCYOIGroup()) {
                return 'jcyoi-' +  environment.server.toLowerCase();
            }
            return '';
    }
    reportReset(){
        let bookmark='';
        this.reportobj.bookmarksManager.getBookmarks().then( bookmarks => {
            bookmarks.forEach( b => {
                bookmark=b.name;
            });
            this.reportobj.bookmarksManager.apply(bookmark);
          }).catch( err => {
            console.log("Error while getBookmarks Error:" +  err);
          });
    }

    bindReportObject(reportName: string = '',siteSelected:number =-1) {
        this.googleAnalyticsService
                    .eventEmitter('Render', reportName, 'Report');

        this.reportService.getReportObject(reportName,siteSelected).subscribe(res => {
            this.reportmodel = res;
            let navigation =  false;
            if (res.reportType === 'report') {
                if (res.name === 'GMS_Executive_Report' || res.name === 'Finance_Travel_Report') {
                    navigation = true;
                } else {
                    navigation = true;
                }
            }
           
            if (reportName === 'Portal Analytics') {
                res.embedUrl = res.embedUrl + '&filter=GAID-Details/EnvironmentName eq %27' + environment.server + '%27';
            } else if (reportName === 'Analytics') {
                res.embedUrl = res.embedUrl + '&filter=GAID-Details/Identifer eq %27' + this.getGAIdentifier() + '%27 and GAID-Details/EnvironmentName eq %27' + environment.server + '%27';
            }
            this.config = {
                type: res.reportType,
                id: res.id,
                accessToken: res.embedToken.token,
                embedUrl: res.embedUrl,
                permissions: pbi.models.Permissions.All,
                tokenType: pbi.models.TokenType.Embed,
                viewMode: pbi.models.ViewMode.View,
                pageName:this.activePage?.name,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: (this.isJJDataGroup || this.isFTLAGroup) ? false : true,
                    layoutType: pbi.models.LayoutType.Custom,
                    customLayout: {
                       displayOption: (this.isJJDataGroup )  ? pbi.models.DisplayOption.FitToWidth : pbi.models.DisplayOption.FitToPage
                    },
                    bars: {
                        actionBar: {
                            visible:  false
                        }
                    }
                }
            };
            this.pbiContainerElement =  document.getElementById('pbi-container');
            this.powerBiService = new NgxPowerBiService();
            this.powerBiService.reset(this.pbiContainerElement);
            this.reportobj = this.powerBiService.embed(this.pbiContainerElement, this.config) as pbi.Report;
            this.reportobj.off('loaded');
            this.reportobj.on('loaded', () => {
                console.log(this.reportmodel.pageName);
                this.reportobj.setPage(this.reportmodel.pageName);
                let iframe = this.pbiContainerElement.getElementsByTagName('iframe')[0];
                iframe.setAttribute("frameborder","0");
                iframe.setAttribute("padding-bottom","3%");
                    if(this.isJJDataGroup){
                        this.getReportInstructions(res.id,this.currentReportTab);
                    }

                });
             this.reportobj.on<{ newPage: Page }>('pageChanged', event => {
                                this.currentReportTab = event.detail.newPage.name;
                                if(this.isJJDataGroup){
                                    this.getReportInstructions(res.id,this.currentReportTab);
                                }
                              });
        },
        err => {
            console.log(err);
            this.dashboardLoadError = true;
            throw new Error('Exception occured while rendering the dashboard:' + err);
        });
    }

}
