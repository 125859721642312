import { UserDetail } from 'src/app/account/shared/user.model';

export class StagingFile {
    UniqueKey: string;
    programArea: string;
    topic: string;
    country: string;
    continent: string;
    region: string;
    division: string;
    state: string;
    county: string;
    fileType: string;
    jurisdiction: string;
    contentType: string;
    privacyType: string;
    community: string;
    focus: string;
    author: string;
    publisher: string;
    ExternalUrl: string;
    FileName: string;
    Lastupdated: string;
    uploadedDate: string;
    UploadedBy: string;
    ContainerName: string;
    KCTitle: string;
    PublicationDate: string;
    AccessFor: string;
    Description: string;
    GrantNumber: string;
    isDataset: boolean;
    datasetType: string;
    moderator: UserDetail;

    public static modelMapper(uiModel: any) {
        return Object.assign({
            accessFor: uiModel['accessFor'],
            datasetType: uiModel['datasetType'],
            isDataset: uiModel['isDataset'],
            programArea: uiModel['programArea'],
            topic: uiModel['topic'] && uiModel['topic'].length > 0 &&  uiModel['topic'] || undefined,
            continent: StagingFile.mapLocationItem(uiModel['continent']),
            country: StagingFile.mapLocationItem(uiModel['country']),
            region: StagingFile.mapLocationItem(uiModel['region']),
            division: StagingFile.mapLocationItem(uiModel['division']),
            state: StagingFile.mapLocationItem(uiModel['state']),
            county: StagingFile.mapLocationItem(uiModel['county']),
            focus: uiModel['focus'] && uiModel['focus'].length > 0 &&  uiModel['focus'] || undefined,
            jurisdiction: StagingFile.mapLocationItem(uiModel['jurisdiction']),
            contentType: uiModel['contentType'] && uiModel['contentType'].length > 0 &&  uiModel['contentType'] || undefined,
            privacyType: uiModel['privacyType'] && uiModel['privacyType'].length > 0 &&  uiModel['privacyType'] || undefined,
            personalAuthor: uiModel['authors'] && uiModel['authors'].length > 0 &&  uiModel['authors'] || undefined,
            description: uiModel['description'],
            community : uiModel['community']
        });
    }

    public static mapLocationItem(item: any) {
        if (item && item.length > 0) {
            return item.map(element => element.item);
        } else {
            return undefined;
        }
    }

    public static mapFileItem(fileItem: any) {
        return {
            fileName: fileItem.FileField.file.name,
            fileTitle: fileItem.kcTitleField,
            fileType: fileItem.fileTypeField
        };
    }

    public static mapLinkItem(linkItem: any) {
        return {
            fileName: linkItem.fileName,
            externalUrl: linkItem.externalUrlField.linkValue,
            fileTitle: linkItem.kcTitleField,
            fileType: linkItem.fileTypeField
        };
    }

    public uiMapper(modelData: any, moderator: UserDetail) {
        Object.assign(this, modelData, {
            programArea: modelData['ProgramArea'],
            topic: modelData['Topic'],
            country: modelData['Country'],
            continent: modelData['Continent'],
            region: modelData['Region'],
            division: modelData['Division'],
            state: modelData['State'],
            county: modelData['County'],
            fileType: modelData['FileType'],
            jurisdiction: modelData['Jurisdiction'],
            contentType: modelData['ContentType'],
            privacyType: modelData['privacyType'],
            author: modelData['personalAuthor'],
            community: modelData['Community'],
            isDataset: modelData['isDataset'],
            datasetType: modelData['datasetType'],
            moderator
        });
        return this;
    }
}

export class FileDetail {
    FileType: string;
    FileName: string;
    KCTitle: string;

    public uiMapper(modelData: any) {
        Object.assign(this, {
            FileName: modelData['FileName'],
            KCTitle: modelData['FileTitle'],
            FileType: modelData['fileType']
        });
        return this;
    }
}

export class LinkDetail {
    FileType: string;
    ExternalUrl: string;
    KCTitle: string;
    FileName: string;

    public uiMapper(modelData: any) {
        Object.assign(this, {
            FileName: modelData['FileName'],
            ExternalUrl: modelData['ExternalUrl'],
            KCTitle: modelData['FileTitle'],
            FileType: modelData['fileType']
        });
        return this;
    }
}

export class UserSet {
    uniqueKey: string;
    userDisplayName: string;
    isDataset: boolean;
    datasetType: string;
    grantNumber: string;
    moderatorId: string;
    moderatorName: string;
    moderatorPrincipal: string;
    uploadedDate: string;
    usersSet: StagingFile;
    fileSet: Array<FileDetail> = [];
    linkSet: Array<LinkDetail> = [];
    viewItems = false;

    constructor(key: string, stagingFile?: StagingFile, fileSet?: Array<FileDetail>, linkSet?: Array<LinkDetail>) {
        this.uniqueKey = key;
        this.usersSet = stagingFile;
        this.fileSet = fileSet;
        this.linkSet = linkSet;
    }

    public static getUserSet(modelData: any): UserSet[] {
        const userSets = UserSet.uiMapper(modelData);
        const uniqueSets = userSets.filter((item, i, a) => a.findIndex(x => x.uniqueKey === item.uniqueKey) === i);
        return uniqueSets;
    }

    public static uiMapper(modelData: any): UserSet[] {
        return modelData ? modelData.map(data => {
                                return  Object.assign(new UserSet(data['setId']).usersetMapper(data));
                         }) : [];
    }

    public getUserMetaData(modelData: any) {
        const moderator = new UserDetail();
        moderator.fullName = this.moderatorName;
        const stagingFiles = modelData ? modelData.map(data => Object.assign(new StagingFile().uiMapper(data, moderator))) : [];
        this.usersSet = stagingFiles[0];
        this.fileSet = stagingFiles.filter(data => data.FileName !== 'Not Specified' && data.ExternalUrl === 'Not Specified')
                            .map(data => Object.assign(new FileDetail().uiMapper(data)));
        this.linkSet = stagingFiles.filter(data => data.ExternalUrl !== 'Not Specified')
                            .map(data => Object.assign(new LinkDetail().uiMapper(data)));
    }

    public usersetMapper(modelData: any): UserSet {
        Object.assign(this, {
            uniqueKey: modelData['setId'],
            userDisplayName: modelData['userDisplayName'],
            datasetType: modelData['datasetType'],
            isDataset: modelData['isDataset'],
            moderatorName: modelData['moderatorDisplayName'],
            moderatorPrincipal: modelData['moderatorServicePrincipal'],
            uploadedDate: modelData['uploadedDateTime'],
            grantNumber: modelData['grantNumber']
        });
        return this;
    }
}

export function updateSet(metadataItems: any): any {
    const usersetArray: any[] = [];
    const mapStagingFile = StagingFile.modelMapper(metadataItems);
    metadataItems.formFilesField.forEach(fileItem => {
        usersetArray.push(Object.assign({}, mapStagingFile, StagingFile.mapFileItem(fileItem)));
    });
    metadataItems.linkField.forEach(linkItem => {
        usersetArray.push(Object.assign({}, mapStagingFile, StagingFile.mapLinkItem(linkItem)));
    });
    return usersetArray;
}
