<div class="maincontainer">
    <div class="col-md-1">
    </div>
    <div class="col-md-10" id="align">
        <p>Find Answers To Your Questions Here</p>
        <div class="content" #purpose id="purpose" name="purpose">
            <div id="question">
                What are we doing?
            </div>
            <div id="answer">
                Data at the Annie E. Casey Foundation will continue to steadily grow in amount and importance for
                organizational decision-making. This platform has been created as the Annie E. Casey Foundation’s
                central repository for curating important data and data sets. Not all data belongs in this repository.
                Other Foundation data should be kept in box folders, or other designated locations. If you have any
                questions about what data should be in the repository, please submit a request for assistance via the
                support portal.
            </div>
        </div>
        <div class="content" #support id="support">
            <div id="question">
                How do I get support?
            </div>
            <div id="answer">
                If you need assistance, please visit the 
                <a rel="noopener noreferrer" href="https://technicalsupport.freshservice.com/support/home" target="_blank">employee/staff</a> 
                or the <a rel="noopener noreferrer" href="https://partners.aecf.org/" target="_blank">grantee/partner</a> 
                support site for announcements, solutions, tickets and service requests.
            </div>
        </div>
        <div class="content" #working id="working">
            <div id="question">
                How does the data warehouse technology work?
            </div>
            <div id="answer">
                A Power BI Tutorial - <a rel="noopener noreferrer" href="https://www.youtube.com/watch?v=AuYzsfXKkbM" target="_blank">Video</a><br>
                Azure Data Warehouse - <a rel="noopener noreferrer" href="https://www.youtube.com/watch?v=G8e55N-ityg" target="_blank">Video</a><br>
                <br/>
                Specific tutorials for our implementation are currently under development. Please submit a request for
                assistance via the support portal for additional information.
            </div>
        </div>
        <div class="content" *ngFor="let faqs of faq">
            <div id="question">
                {{faqs.question}}
            </div>
            <div id="answer" [innerHtml]=faqs.answer>               
            </div>
        </div>
        <div class="content" id="advacacyLearningLink">
            <div id="question">
                How can I search for external links or resources?
            </div>
            <div id="answer">
                Click <a (click)="getAdvacacyLeanringLabResource()">here</a> to see the external links or resources.
            </div>
        </div>
    </div>
    <div class="col-md-1">
    </div>
</div>