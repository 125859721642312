<div class="container manage-users">
    <div class="overlay" *ngIf="loader">
        <i class="loader" *ngIf="loader"></i>
    </div>
    <div class="heading d-flex">
        <div class="page-title">
            <div>
                <span calss="page-title"><h1>Manage Sites Measures - {{selectedSite.name}}</h1></span>
            </div>
        </div>
        <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
            <div class="col-md-12">
                <div class="alert alert-danger" id="formSubmitError">
                    {{ formSubmitError }}
                </div>
            </div>
        </div>
    </div>
<div class="row">
    <div class="search_icon_profile">
        <i class="fas fa-search"></i>
    </div>
    <div class="searchBoxForm_profile">
            <input type="text" id="searchTerm" name="searchTerm" placeholder="Search site measures..." autocomplete="off"
                         [(ngModel)]="searchTerm" (keyup)="search()"  class="searchBox_Profile"  />
            <span class="fullName"><span><b>Measures</b></span></span>
                <label class="switch" *ngIf="isAdmin">
                    <input type="checkbox" (change)="toggleSiteMeasureStatus($event)" [checked]="isAllMeasuresActive"  [(ngModel)]="isAllMeasuresActive">
                    <span class="slider round"></span>
                </label>
            <span class="fullName"><span><b>Activate</b></span></span>
    </div>
</div>
<br>
<div>
    <accordion [closeOthers]="true" >
        <ng-template ngFor let-setItem let-setIndex="index" [ngForOf]="groupList">    
            <accordion-group #grp  [isOpen]="accordinGroup">
                <div accordion-heading class="tab" (click)="modifySet(setItem, false, grp.isOpen)">
                    <div class="tab-label">
                        <div class="tab-label-text">                               
                            <div class="col-sm-7">
                                <span class="fullName"><span ><b>Group Order :</b> {{padLeadingZeros(setItem?.order,2)}}</span></span>
                                <span class="fullName"><span ><b>Group Name :</b> {{setItem?.name}}</span></span>
                            </div>
                            <div class="col-sm-3">
                                <span class="fullName"><span><b>Measures</b></span></span>
                                <label class="switch" *ngIf="isAdmin">
                                    <input type="checkbox" [checked]="setItem.isAllMeasuresActive" [(ngModel)]="setItem.isAllMeasuresActive" title="click here to activate measures" (change)="toggleSiteGroupMeasureStatus($event,setItem);">
                                    <span class="slider round"></span>
                                </label>
                                <span class="fullName"><span><b>Activate</b></span></span>
                                </div>
                        </div>
                    </div>                        
                </div>
                 <div class="row">
                    <div class="col-12 table-responsive">
                          <table class="table table-striped sortable" id="printableArea">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Number</th>
                                <th>Measure</th>
                                <th>Measure Short Name</th>
                                <th *ngIf="!isJJDataPortal">Last Updated</th>
                                <th *ngIf="!isJJDataPortal">Last Updated Date</th>
                                <th *ngIf="isAdmin">Is Active</th>
                              </tr>
                            </thead>
                            <tbody id="tbGroup">
                        <tr *ngFor="let measure of selectedGroupMeasures; let rowIndex=index;">
                            <td title="edit site measure">
                                <div class="delete-icon">
                                <span class="role-icon" (click)="editMeasure(measure, rowIndex)">
                                    <em class="far fa-edit"></em>
                                </span>  
                            </div>
                         </td>
                                <td>{{padLeadingZeros(measure.measure.number,2)}}</td>
                                <td>{{measure.measure.measure}}</td>
                                <td>{{measure.measure.measureShortName}}</td>
                                <td *ngIf="!isJJDataPortal">{{measure.lastUpdated}}</td>
                                <td *ngIf="!isJJDataPortal">{{measure.lastUpdatedDateTime | timezone:'MM/DD/YYYY hh:mm A' }}</td>
                                <td *ngIf="isAdmin">{{measure.isActive ? 'Yes':'No'}}</td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </accordion-group>                                  
        </ng-template> 
    </accordion>          
</div>

<div class="row">
    <div class="col-md-2 padding-2p" *ngIf="manageSiteMeasures?.length>0">
        <button type="button"   [ngClass]="{ 'disable':  viewAsAdmin }" *ngIf="isAdmin && !isModerator"  id="btnprintyears" class="btn btn-success activeyears"  (click)="exportSiteMeasure()">Export Active Measures</button>
    </div>
    <div class="col-md-4 padding-2p margin-left-40">
        <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
    </div>
    <!-- <div class="col-md-4 padding-2p pull-right">
        <button type="button" id="btnsave"  class="btn btn-secondary button-style" [disabled]="!enableSave && (isAdmin || isCaseyConsultant || isState)" (click)="saveSiteMeasures()" [ngClass]="{ 'disabled': viewAsAdmin }">Save</button>
    </div> -->
 </div>
<div class="row" *ngIf="manageSiteMeasures?.length===0">
    <div class="col-md-4" ></div>
    <div class="col-md-4" >  <p>No site measures Found.</p></div>
    <div class="col-md-4" ></div>
</div>
<div class="clearfix">
        <div class="pull-right">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
</div>
<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAddUpdateModel()"><i class="fa fa-times"></i></a>
    <div class="row">
        <b>Edit Measure - {{selectedSite.name}} </b>
    </div>
    <div class="form-group">
        <form [formGroup]="siteMeasureForm">
            <div><b>Group </b>  <span class="padding-2p">{{selectedSiteMeasure.group.name}} </span></div>
                <div><b>Number</b> <span class="padding-2p">{{selectedSiteMeasure.measure.number}} </span></div>
                <div><b>Short Name</b> <span class="padding-2p">{{selectedSiteMeasure.measure.measureShortName}} </span></div>
                <div><b>Measure</b> <span class="padding-2p">{{selectedSiteMeasure.measure.measure}} </span></div>
                <div><b>Definition</b> </div>
                <div><span class="padding-2p">{{selectedSiteMeasure.measure.definition}} </span> </div>
                    <div>Site Definition </div>
                    <div>
                        <textarea id="txtSiteDefinition"  name="txtSiteDefinition" placeholder="Site Definition"  autocomplete="new-txtSiteDefinition" 
                        formControlName="siteDefinition" class="form-control" [readonly]="!(isAdmin || isDataLead)" ></textarea>
                    </div>
                    <div>Source </div>
                    <div>
                        <input id="txtSource" type="text" name="txtSource" placeholder="Source"  autocomplete="new-txtSource" 
                        formControlName="source" class="form-control" required  [readonly]="!(isAdmin || isDataLead)" />
                    </div>
                    <div>Casey TA Notes </div>
                    <div>
                        <textarea id="txtTANOTES"  name="txtcaseyTANOTES" placeholder="Casey TA Notes"  autocomplete="new-txtcaseyTANOTES"  [disabled]="!isAdmin" 
                        formControlName="caseyTANotes" class="form-control" [readonly]="!(isCaseyConsultant || isAdmin)" ></textarea>
                    </div>
                    <div>State TA Notes </div>
                    <div>
                        <textarea id="txtStateTANOTES"  name="txtStateTANOTES" placeholder="State TA Notes"  autocomplete="new-txtstateTANOTES"  [disabled]="!isAdmin" 
                        formControlName="stateTANotes" class="form-control" [readonly]="!(isState || isAdmin)" ></textarea>
                    </div>
                    <div *ngIf="isAdmin">Select Site Active/InActive</div>
                <div *ngIf="isAdmin">
                    <input id="chkSite" type="checkbox" value="site" formControlName="isActive" > IsActive
                </div>
        </form>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" (click)="cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateSite()" [ngClass]="{ 'disabled': viewAsAdmin }"  [disabled]="!hasInitalValueChange && (isAdmin || isCaseyConsultant || isState)">Add</button>
    </div>
  </div>
<div class="backdrop" *ngIf="isAddOpen"></div>
</div>
<app-modal #confirmationModal contentStyle="role-delete-modal" [showClose]=false [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <div class="col-sm-12 content-style">
            <div *ngIf="dataItem">
                <span>{{dataItem.message}}?</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal(dataItem.group)">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="confirmToUpdateSiteMeasures(dataItem)">
                    <span>Ok</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>