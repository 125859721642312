import { Injectable } from '@angular/core';

import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class DropboxService {

  constructor(private repository: RepositoryService) { }

  getClientBoxAccessToken(code: string) {
    return this.repository.get(environment.apiBaseURL, 'api/User/boxaccess/' + code, 'text');
  }

  getInternalBoxAccessToken() {
    return this.repository.get(environment.apiBaseURL, 'api/User/internalboxaccess', 'text');
  }
}
