import { Injectable } from '@angular/core';

import { RepositoryService } from '../services/http/repository.service';
import { SessionStorageService } from '../state-manager/session-storage/session-storage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Roles } from './role-settings.model';

@Injectable()
export class RoleService {

  constructor(private sessionStorage: SessionStorageService, private repository: RepositoryService) { }

  public getRole() {
    const userDetail = this.sessionStorage.getItem('user');
    if (userDetail && userDetail.role) {
        if(userDetail['role'].name === 'Admin')
              return  Roles.Admin;
        else if(userDetail['role'].name === 'Moderator')
              return  Roles.Moderator;
          else if(userDetail['role'].name === 'SuperAdmin')
              return  Roles.SuperAdmin;
          else if(userDetail['role'].name === 'CaseyConsultant')
              return  Roles.CaseyConsultant;
          else if(userDetail['role'].name === 'State')
              return  Roles.State;
          else if(userDetail['role'].name === 'OtherLocalLeader')
              return  Roles.OtherLocalLeader;
          else if(userDetail['role'].name === 'DataLead')
              return  Roles.DataLead;
          else if(userDetail['role'].name === 'ProbationChief')
              return  Roles.ProbationChief;
          else
              return Roles.User;
    }
    return Roles.User;
  }

  public isViewAsAdmin(role: Roles) {
    const isView = this.sessionStorage.getItem('viewasAdmin') === 'true';
    if (isView && (role === Roles.Admin || role === Roles.SuperAdmin)) {
      return true;
    }
    return false;
  }

  public getUserDetail() {
    return new Observable((observer) => {
      this.repository.get(environment.apiBaseURL, 'api/User')
      .subscribe(data => {
        this.sessionStorage.setItem('user', data);
        this.sessionStorage.setItem('viewuser', data);
        observer.next(data);
        observer.complete();
      }, (error) => { observer.error(error); });
    });
  }

  checkUserAccess(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/User/isAccess');
  }
}
