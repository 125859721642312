export class NewsFeed {
    id: number;
    title: string;
    body: string;
    community: string;
    createdDateTime: string;
    createdBy: string;
    removed: boolean = false;

    public static modelMapper(uiModel: NewsFeed) {
        return {
            title: uiModel.title,
            body: uiModel.body,
            removed: uiModel.removed
        };
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            title: modelData['title'],
            id: modelData['id'] ? modelData['id'] : undefined,
            body: modelData['body'],
            community: modelData['community'],
            createdBy: modelData['createdBy'],
            removed: modelData['removed'],
            createdDateTime: modelData['createdDateTime']
        });
        return this;
    }
}

export class NewsFeedList {
    newsFeeds: Array<NewsFeed> = [];

    public dataMapper(modelData: NewsFeed[]) {
        return Object.assign(modelData ? modelData.map(data => {
            return  Object.assign(NewsFeed.modelMapper(data));
        }) : []);
    }

    public uiMapper(modelData: any) {
        Object.assign(this, {
            newsFeeds: modelData ?
                modelData.map(data => {
                    return  Object.assign(new NewsFeed().uiMapper(data));
                }) : []
        });

        return this;
    }
}
