import {formatDate} from '@angular/common';

export class SiteModel {
    id: number;
    name: string;
    isActive:boolean;
    state:string;
    lastUpdated: string;
    lastUpdatedDateTime:string;
    copyFromSite:number;
    baseLineYear:number;
    jdaiSince:number;
    probationTransformationSince:number;
    statusOffenses:boolean;
    is17YearsOld:boolean;
    youthJusticeInitiatives:string;
    fiscalYearStart:number;
    siteImageURL:string;
    constructor(id:number,name:string,isActive:boolean,lastUpdated:string,state :string,copyFromSite:number,baseLineYear:number,jdaiSince:number,probationTransformationSince:number){
        this.id=id;
        this.name=name;
        this.isActive = isActive;
        this.state = state;
        this.copyFromSite = copyFromSite;
        this.lastUpdated = lastUpdated;
        this.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");
        this.baseLineYear = baseLineYear;
        this.jdaiSince=jdaiSince;
        this.probationTransformationSince =probationTransformationSince;
    }    
}

export class SiteDataCollection {
    dataCollection:string;
    siteResponse: string;
    constructor(dataCollection:string,siteResponse: string)
        {
            this.dataCollection= dataCollection;
            this.siteResponse= siteResponse;
        }
}

export class StateList {
    state :string;
    stateId:string;
}