import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoleManagementComponent } from './role-management/role-management.component';
import { AccountService } from './shared/account.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DashboardManagementComponent } from './dashboard-management/dashboard-management.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PermissionManagementComponent } from './permission-management/permission-management.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { UserProfilesComponent } from './user-profiles/user-profiles.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserBlukUploadComponent } from './user-bluk-upload/user-bluk-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    RoleManagementComponent, 
    DashboardManagementComponent, 
    PermissionManagementComponent, 
    ManageUsersComponent, 
    UserProfilesComponent, 
    UserBlukUploadComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    TypeaheadModule,
    ModalModule.forRoot(),
    FileUploadModule,
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    AccountService
  ]
})
export class AccountModule { }
