<div class="import-site-data-container">
    <div id="formTemplate" *ngIf="!importSucceeded">
        <form [formGroup]="sitedataImportForm">
            <div class="row">
                <label for="site">Select Site:</label>
                <span class="file-item-span">{{SelectedSite}}</span>
            </div>
            <div class="browser-file" >
                <div class="content-style">
                    <label for="myfile">Select a file:</label>
                    <input #fileInput type="file" ng2FileSelect  [uploader]="uploader" class="fileupload" (change)="initUpload($event)"/>
                    <button id="button-browse" type="button" class="btn button-style upload" (click)="fileInput.click();">
                        <span>Browse Files</span>
                    </button>
                    <span class="file-item-span">{{filename}}</span>
                </div>
            </div>
            </form>
            <div class="col-sm-12 footer-style">
                <div class="downloadTemplateButton">
                    <button type="button" class="btn button-style downloadTemplateButton" (click)="downloadImportSiteDataTemplate()">
                    <span>Download Import Template</span>
                    </button>
                </div>
                <div class="savebtn">
                    <button (click)="uploadFile()" class="btn button-style" style="width: 120px;" [disabled]="sitedataImportForm.invalid">
                        <span>Run Validation</span>
                    </button>
                </div>
            </div>
            <div *ngIf="showQueueAlert">
                <alert type="info" class="toast" [dismissible]=true [dismissOnTimeout]=5000 (onClosed)="onClosedAlert()">{{message}}</alert>
            </div>
    </div>
    <div id="messageTemplate" *ngIf="importSucceeded" style="color: #270; padding-top: 50px; font-size: large; text-align: center">
        Data imported and validated. Click OK to close.<br>On the next page, click your site name to review validation results.
    </div>
    <button  id="closeModalButtonAfter" *ngIf="importSucceeded" type="button" class="btn button-style" style="margin:auto; background-color: #270; display:block; width: 100px;" (click)="Cancel()">OK</button>
</div>

