import { Component, OnInit, Renderer2, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalConfigService } from '../shared/portal-config.service';
import { OktaSetting } from '../shared/okta-setting.model';
import { FormGroup,  FormBuilder,  Validators, ReactiveFormsModule  } from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';

@Component({
  selector: 'app-okta-setting-component',
  templateUrl: './okta-setting.component.html',
  styleUrls: ['./okta-setting.component.scss'],
  providers: []
})
export class OktaSettingComponent implements OnInit {
  oktaSetting: OktaSetting;
  formSubmitSuccess: string;
  formSubmitError: string;
  oktaForm: FormGroup;

  constructor( private portalService: PortalConfigService, private router: Router, 
    private formBuilder: FormBuilder, public googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit() {
    this.getoktaSetting();
    this.resetForm();
  }

  resetForm() {
    this.oktaForm = this.formBuilder.group({
      oktaClientId: ['', Validators.required ],
      oktaIssuer: ['', Validators.required ]
   });
  }

  getoktaSetting() {
    this.portalService.getOktaSettings()
    .subscribe(data => {
        this.oktaSetting = new OktaSetting().uiMapper(data);
        this.oktaForm.setValue({
          oktaClientId: this.oktaSetting.oktaClientId,
          oktaIssuer: this.oktaSetting.oktaIssuer
        });
    });
  }

  saveOktaSetting() {
    this.portalService.saveOktaSettings(this.oktaForm.value).subscribe(data => {
      this.googleAnalyticsService
                    .eventEmitter('Edit', this.oktaSetting.oktaClientId, 'OKTA Settings');
    });
  }
}
