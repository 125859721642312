import { Component, OnInit, ViewChild } from '@angular/core';

import { AccountService } from '../shared/account.service';
import { UsersList, UserDetail, ReportsList, ReportDetail } from '../shared/user.model';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { compareItems } from 'src/app/common/models/utility';
import { TypeaheadMatch, TypeaheadDirective } from 'ngx-bootstrap/typeahead';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';

@Component({
  selector: 'app-dashboard-management',
  templateUrl: './dashboard-management.component.html',
  styleUrls: ['./dashboard-management.component.scss']
})

export class DashboardManagementComponent implements OnInit {
  @ViewChild('typeahead', {static: false}) typeahead: TypeaheadDirective;
  @ViewChild('assignReportsDialog', {static: false}) assignReportsDialogComponent: ModalComponent;
  userList: Array<UserDetail> = [];
  userRoles: any;
  currentUserRole: Roles;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageUsers: Array<UserDetail>;
  pendingUsers: Array<UserDetail> = [];
  selectedUser: UserDetail = null;
  currentUser: any;
  addRowInvalid = false;
  selectedModerator: any;
  selectedUserName: string;
  itemsTosearch = 15;
  reports: Array<ReportDetail> = [];
  viewAsAdmin = this.commonService.getViewUserEventEmitter();

  constructor(private accountService: AccountService,
              private commonService: CommonEntityService,
              public googleAnalyticsService: GoogleAnalyticsService) {
    this.userRoles = Roles;
  }

  get isModerator() { return this.currentUserRole === Roles.Moderator; }

  ngOnInit() {
    this.getUsersWithAccess();
    this.accountService.getReports()
    .subscribe(data => {
      this.reports = new ReportsList().uiMapper(data).reports;
    });
  }

  getUsersWithAccess() {
    this.accountService.getUsersWithAccess()
    .subscribe(data => {
        this.manageUsers = new UsersList().userReportUiMapper(data).kmsUsers;
        this.manageUsers.sort((nodeA, nodeB) => compareItems(nodeA['fullName'], nodeB['fullName']));
    });
  }

  getOtherUsers() {
    this.accountService.getOtherUsers(this.selectedUserName)
    .subscribe(data => {
        this.userList = new UsersList().userOtherUnitUiMapper(data).kmsUsers;
        this.userList = this.userList.filter(user => (this.manageUsers.findIndex(x => x.id === user.id) === -1));
        this.userList.sort((nodeA, nodeB) => compareItems(nodeA['fullName'], nodeB['fullName']));
        this.pendingUsers = this.userList;
        this.itemsTosearch = this.pendingUsers.length;
        this.typeahead.dropup = true;
        this.typeahead.show();
    });
  }

  onUserSelect(event: TypeaheadMatch): void {
    this.selectedUser = event.item;
    this.showReportsModal(this.selectedUser);
    this.selectedUser = null;
    this.selectedUserName = null;
  }

  showReportsModal(user: UserDetail) {
    if (this.assignReportsDialogComponent) {
      const reports = this.reports.map(data => {
        if (user.reports) {
          const report = user.reports.find(x => x.reportId === data.reportId);
          data.isSelected = report && report.isSelected;
        } else {
          data.isSelected = false;
        }
        return data;
      });
      this.assignReportsDialogComponent.entityData = {
        headerText: 'Select Report(s)',
        entityData: { reports, user }
      };
      this.assignReportsDialogComponent.showModal();
    }
  }

  AddReportsToUser(reports, user) {
    if (user) {
      reports.forEach(element => {
        this.googleAnalyticsService.eventEmitter('Grant Access', element.reportName + ' to ' + user.givenName , 'Dashboard');
      });
      this.addRowInvalid = false;
      const selectedReports = reports.filter(x => x.isSelected);
      if (user.reports) {
        user.reports.forEach(data => {
          const report = selectedReports.find(x => x.reportId === data.reportId);
          if (!report) {
            selectedReports.push(data);
          }
        });
      }
      user.updateReportAccess(selectedReports);
      const index = this.manageUsers.findIndex(x => x.id === user.id);
      if (index < 0) {
        this.manageUsers.push(user);
        this.pendingUsers = this.userList.filter(x => !x.isPending);
        this.itemsTosearch = this.pendingUsers.length;
      }
      this.closeAssignReportsModal(true);
    } else {
      this.addRowInvalid = true;
    }
  }

  removeReportFromUser(report, user) {
    report.isSelected = false;
    this.googleAnalyticsService
          .eventEmitter('Revoke Access', report.reportName + ' from ' + user.givenName , 'Dashboard');
  }

  closeAssignReportsModal(reason: boolean) {
    if (this.assignReportsDialogComponent) {
      this.assignReportsDialogComponent.closeModalWindow(reason);
    }
  }

  updateReportAccess() {
    if (this.manageUsers) {
      this.accountService.SaveUser(this.manageUsers)
      .subscribe((data) => {
        this.getUsersWithAccess();
      });
    }
  }
}
