import { Injectable, QueryList } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';

@Injectable()
export class ReportExportService {

  constructor(private repository: RepositoryService) { }

  getListOfExportRequests() {
    return this.repository.get(environment.DashboardUrl, '/queue/');
  }

  downloadReportFile(requestId: number, format:string,reportName: string ) {
    return this.repository.get(environment.DashboardUrl, '/download/'+requestId+'/'+format+'/'+reportName, 'arraybuffer');
  }
}
