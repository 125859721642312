<div class="container">
    <div class="page-title">
        <div>
            <span calss="page-title"><h1>FAQ</h1></span>
        </div>
    </div>
<div *ngIf="formSubmitError" class="row alertContainer" role="alert">
    <div class="col-md-12">
        <div class="alert alert-danger" id="formSubmitError">
        {{ formSubmitError }}
        </div>
    </div>
</div>
<div>
    <div class="col-md-12">
        <div *ngIf="formSubmitSuccess">
            <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
        </div>
    </div>
</div>
<div *ngFor="let faq of faqContents; let rowIndex=index;">
        <div class="row question-row">
            <div class="col-md-10">
                <button class="accordion" (click)="toggelFAQ($event)"> {{faq.question}}</button>
            </div>
            <div class="col-md-1" *ngIf="isAdmin || isSuperAdmin">
                <div class="delete-icon" [ngClass]="{ 'disable': viewAsAdmin }">
                    <span class="role-icon" (click)="removeFAQ(faq, rowIndex)">
                        <em class="far fa-trash-alt"></em>
                    </span>  
                </div>
            </div>
            <div class="col-md-1" *ngIf="isAdmin || isSuperAdmin">
                <div class="edit-icon" [ngClass]="{ 'disable': viewAsAdmin }">
                    <span  class="role-icon" (click)="editFAQ(faq, rowIndex)">
                        <em class="far fa-edit"></em>
                    </span>  
                </div>
            </div>
        </div>
        <div class="row">
                <div class="panel" [innerHTML]="parseHtml(faq.answer)"> </div>
        </div>
    </div>
<div class="form-group" *ngIf="isAdmin || isSuperAdmin">
        <form [formGroup]="faqForm">
            <div>Question</div>
                    <div>
                        <input id="txtQuestion" type="text" name="txtQuestion" placeholder="Enter Question"  autocomplete="disabled" formControlName="question" class="form-control" required [ngClass]="{'invalid-input': faqForm.get('question')?.errors }"/>
                    </div>
                    <div>Answer</div>
                    <div>
                    <textarea id="txtAnswer" type="text" name="txtAnswer" placeholder="Enter Answer"  autocomplete="disabled" formControlName="answer" class="form-control" required [ngClass]="{'invalid-input': faqForm.get('answer')?.errors }"></textarea>
                </div>
        <div class="row">
            <div class="col-xs-8"></div>
            <div class="col-xs-2">
                <button type="button" id="btnsave" class="btn btn-block btn-warning" [disabled]="faqForm.invalid" (click)="SaveFAQContent()">Save</button>
            </div>
            <div class="col-xs-2">
                <button type="button" id="btncancel" class="btn btn-block btn-dark" (click)="CancelFAQ()">Cancel</button>
            </div>
        </div>
        </form>
</div>
</div>
