import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { SearchModule } from '../search/search.module';
import { RouterModule } from '@angular/router';
import { AllHomeComponent } from './all-home/all-home.component';
import { ReportingHomeComponent } from './reporting-home/reporting-home.component';
import { JdaiHomeComponent } from './jdai-home/jdai-home.component';
import { VisionHomeComponent } from './vision-home/vision-home.component';
import { NewsFeedModule } from '../news-feed/news-feed.module';
import { FtlaHomeComponent } from './ftla-home/ftla-home.component';
import { JcyoiHomeComponent } from './jcyoi-home/jcyoi-home.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GrantsHomeComponent } from './grants-home/grants-home.component';
import { OktaconfigHomeComponent } from './oktaconfig-home/oktaconfig-home.component';
import { SearchWidgetComponent } from '../widgets/search-widget/search-widget.component';
import { JJHomeComponent } from './jj-home/jj-home.component';
import { ReportModule } from '../reports/report.module';
@NgModule({
  declarations: [HomeComponent,
    AllHomeComponent,
    ReportingHomeComponent,
    JdaiHomeComponent,
    VisionHomeComponent,
    FtlaHomeComponent,
    JcyoiHomeComponent,
    GrantsHomeComponent,
    OktaconfigHomeComponent,
    SearchWidgetComponent,
    JJHomeComponent
  ],
  imports: [
    CommonModule,
    SearchModule,
    RouterModule,
    NewsFeedModule,
    CarouselModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule,
    ReportModule
  ]
})
export class HomeModule { }
