import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grants-landing',
  styleUrls: [],
  template: `
    <div class="prelogin-grants-imgcontainer">
    <div class="overcontainer">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
              <div class="heading">
                  <div class="headingContent">
                    <div class="mainheading">
                      <h1>{{title}}</h1>
                    </div>
                  <div class="subheading">
                      <h5></h5>
                    </div>
                  </div>
              </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>`
})
export class GrantsLandingComponent implements OnInit {
  title = '';
  constructor(private titleService: Title) {
    const bodyElement = document.body;
    bodyElement.style.paddingBottom='0px';
   }

  ngOnInit() {
    this.titleService.setTitle(environment.grantsPageTitle);
    this.title= environment.grantsPageTitle;
  }
}
