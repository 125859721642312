<ng-container *ngIf="defaultGroup">
    <app-landing></app-landing>
  </ng-container>
  <ng-container *ngIf="allGroup">
    <app-all-landing></app-all-landing>
  </ng-container>
  <ng-container *ngIf="reportingGroup">
    <app-reporting-landing></app-reporting-landing>
  </ng-container>
  <ng-container *ngIf="jdaiGroup">
    <app-jdai-landing></app-jdai-landing>
  </ng-container>
  <ng-container *ngIf="thriveGroup">
    <app-vision-landing></app-vision-landing>
  </ng-container>
  <ng-container *ngIf="ftlaGroup">
    <app-ftla-landing></app-ftla-landing>
  </ng-container>
  <ng-container *ngIf="jcyoiGroup">
    <app-jcyoi-landing></app-jcyoi-landing>
  </ng-container>
  <ng-container *ngIf="jjdataGroup">
    <app-jj-data-pre-login></app-jj-data-pre-login>
  </ng-container>
  <ng-container *ngIf="grantsGroup">
    <app-grants-landing></app-grants-landing>
  </ng-container>
  <ng-container *ngIf="portalConfig">
    <app-portalconfig-landing></app-portalconfig-landing>
  </ng-container>