import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { environment } from 'src/environments/environment';
import { StateService } from 'src/app/core/state-manager/appstateservice';

@Component({
  selector: 'app-ftla-home',
  templateUrl: './ftla-home.component.html',
  styleUrls: ['./ftla-home.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 1500, noPause: true, showIndicators: true } }
  ]
})
export class FtlaHomeComponent implements OnInit {
  itemsPerSlide = 10;
  innerWidth: number;
  mobileBreakpoint = 480;
  tabBreakpoint = 980;
  isNewUserAdded:boolean;
  constructor(private titleService:Title,
              private stateService: StateService) { }

  ngOnInit() {
    this.titleService.setTitle(environment.ftlaPageTitle);
    this.adjustsItemsPerSlide();
    this.getNewUserAddedStatus();
  }
  adjustsItemsPerSlide() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < this.mobileBreakpoint) {
      this.itemsPerSlide = 4;
    } else if (this.innerWidth < this.tabBreakpoint) {
      this.itemsPerSlide = 6;
    } else {
      this.itemsPerSlide = 10;
    }
  }

  getNewUserAddedStatus()
  {
    var userData = this.stateService.getNewUserSession();
    if(userData!=null || userData!=undefined )
    {
      if(userData=='true')
        this.isNewUserAdded=true;
        else
        this.isNewUserAdded=false;
    }
    else
      this.isNewUserAdded=false;
  }
}
