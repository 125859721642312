import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { compareItems } from 'src/app/common/models/utility';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { Guid } from "guid-typescript";
import { environment } from 'src/environments/environment';
import { AccountService } from '../shared/account.service';
import { FoundationProfiles, FoundationProfilesList } from '../shared/profile.model';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { UserDetail, UsersList } from '../shared/user.model';
import { url } from 'inspector';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';

@Component({
  selector: 'app-user-profiles',
  templateUrl: './user-profiles.component.html',
  styleUrls: ['./user-profiles.component.scss']
})
export class UserProfilesComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  profileList: Array<FoundationProfiles>;
  currentPage: number;
  manageUsers: FoundationProfiles[];
  reportingProfiles: UserDetail[];
  reportingProfileList:UserDetail[];
  totalRows: number;
  perPage: number=10;
  storage:string = environment.blobStorage;
  searchTerm: string;
  profileForm: FormGroup;
  isAddOpen:boolean=false;
  imageSrc:string;
  currentUser: any;
  viewAsAdmin = false;
  currentUserRole: Roles;
  profileOrgList:string[];
  isUserExists:boolean=false;
  isValidURL:boolean=true;
  selectedPorfile:any;
  isFTLAGroup:boolean;
  isReportingGroup:boolean;
  isJJDataGroup:boolean;
  manageProfileList: Array<FoundationProfiles>;
  memberslist: any;
  isFromFilterDropdown:boolean=false;
  fromFilterDropdownValue: string;
  timeStamp: number;
  reportingProfileData:UserDetail[];
  constructor(private accountService: AccountService,
              private stateService: StateService,
              private commonService: CommonEntityService,
              public googleAnalyticsService: GoogleAnalyticsService) { }

    get isSuperAdmin() { return this.currentUserRole === Roles.SuperAdmin; }
    get isAdmin() { return this.currentUserRole === Roles.Admin; }

  ngOnInit(): void {
    this.isFTLAGroup = this.stateService.isFTLAGroup();
    this.isReportingGroup = this.stateService.isReportingGroup();
    this.isJJDataGroup =this.stateService.isJJGroup();
    this.getUsers();
    this.ftlaMembers();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }

    this.timeStamp = Date.now();

    this.profileForm = new FormGroup({
      id:new FormControl(''),
      firstName:new FormControl('', Validators.required),
      lastName:new FormControl('', Validators.required),
      title:new FormControl(''),
      organization:new FormControl('',Validators.required),
      userPrincipalName:new FormControl('', [Validators.required, Validators.email]),
      contact:new FormControl('', Validators.nullValidator),
      // headquarters:new FormControl('', Validators.required),
      // founded:new FormControl('', Validators.required),
      url:new FormControl('',Validators.required),
      // staffsize:new FormControl(''),
      file: new FormControl(''),
      userId:new FormControl(''),
      // endowment:new FormControl(''),
      fileSource: new FormControl('')
    });

    this.profileForm.controls['userPrincipalName'].valueChanges.subscribe(value=>{
      this.isUserExists =  this.profileList.filter(x=>x.userPrincipalName == value &&  this.selectedPorfile.userPrincipalName!=value).length>0;
    });

    this.profileForm.controls['url'].valueChanges.subscribe(value=>{
    //   this.isValidURL = /^(http|https):\/\/[^ "]+$/.test(value);    

    });

    this.profileForm.get('userPrincipalName').valueChanges.subscribe(val => {
      if (this.isFTLAGroup) {
        this.profileForm.controls['firstName'].setValidators([Validators.required]);
        this.profileForm.controls['lastName'].setValidators([Validators.required]);
        this.profileForm.controls['organization'].setValidators([Validators.required]);
        this.profileForm.controls['userPrincipalName'].setValidators([Validators.required]);
        this.profileForm.controls['url'].setValidators([Validators.required]);
      } else {
        this.profileForm.controls['firstName'].clearValidators();
        this.profileForm.controls['lastName'].clearValidators();
        this.profileForm.controls['organization'].clearValidators();
        this.profileForm.controls['userPrincipalName'].clearValidators();
        this.profileForm.controls['url'].clearValidators();
      }
      this.profileForm.controls['firstName'].updateValueAndValidity();
      this.profileForm.controls['lastName'].updateValueAndValidity();
      this.profileForm.controls['organization'].updateValueAndValidity();
      this.profileForm.controls['userPrincipalName'].updateValueAndValidity();
      this.profileForm.controls['url'].updateValueAndValidity();
    });

  }

  onOrganizationSelect(event: TypeaheadMatch): void {
    let selected = this.profileList.filter(x=>x.name == event.value)[0];
    if(selected !== null || selected !== undefined){
        this.profileForm.get('organization').setValue(selected.name);
        this.profileForm.get('contact').setValue(selected.contact);
        this.profileForm.get('url').setValue(selected.website);
        // this.profileForm.get('staffsize').setValue(selected.staffSize);
        // this.profileForm.get('founded').setValue(selected.founded);
        // this.profileForm.get('headquarters').setValue(selected.headquarters);
        // this.profileForm.get('staffsize').setValue(selected.staffSize);
        // this.profileForm.get('endowment').setValue(selected.endowment);
        this.profileForm.get('id').setValue(selected.id);
        this.profileForm.get('userId').setValue(Guid.create());
    }
  }

  getUsers() {
    if(this.isFTLAGroup){
    this.accountService.getFTLAProfileUsers()
    .subscribe(data => {
        this.profileList = new FoundationProfilesList().uiMapper(data).profiles;
        this.profileList.sort((nodeA, nodeB) => compareItems(nodeA['surName'].trim(), nodeB['surName'].trim()));
        this.profileList.map((profile,i)=>{
          this.profileList[i].userId =  profile.userId.toLowerCase();
        });
        this.currentPage=0;
        this.manageUsers = this.profileList.slice(this.currentPage, this.perPage);
        this.totalRows = this.profileList.length;
        this.profileOrgList  = Array.from(new Set(this.profileList.map((itemInArray) => itemInArray.name)));
    });
  }
  else if(this.isReportingGroup || this.isJJDataGroup) {
    this.accountService.getCommunitySpecificUsers().subscribe(data=>{
      this.reportingProfileList = new UsersList().uiMapper(data).kmsUsers;
      this.reportingProfileList.sort((nodeA, nodeB) => compareItems(nodeA['fullName'].trim(), nodeB['fullName'].trim()));
      this.currentPage=0;
      this.reportingProfiles = this.reportingProfileList.slice(this.currentPage, this.perPage);
      this.totalRows = this.reportingProfileList.length;
    });
  }
  }
  search(value: string):void{
    if(this.isFTLAGroup){
      var profileFilter = new Array<FoundationProfiles>;
      if(this.isFromFilterDropdown)
      {
        this.manageUsers =this.profileList.filter(x => x.name === this.fromFilterDropdownValue); 
        profileFilter = this.manageUsers;
      }
      else
      {
        profileFilter = this.profileList;
      }

    if(this.searchTerm.length===0){
      this.currentPage=0;
      this.manageUsers = profileFilter.slice(this.currentPage, this.perPage);
      this.totalRows = profileFilter.length;
    }
    else{

      this.manageProfileList =new Array<FoundationProfiles>;

      
      var searchNameList = profileFilter.filter(x=> x.name.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(this.currentPage, this.perPage);
                         // || x.foundationLead.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(this.currentPage, this.perPage);
      var searchFoundationList = profileFilter.filter(x => x.foundationLead?.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(0, 10);

      if(searchNameList!=null || searchNameList!=undefined)
        this.manageProfileList = this.searchConcatFtla(this.manageProfileList, searchNameList);
      if(searchFoundationList!=null || searchFoundationList!=undefined)
        this.manageProfileList = this.searchConcatFtla(this.manageProfileList, searchFoundationList);


      let result = this.manageProfileList.filter(
        (person, index) => index === this.manageProfileList.findIndex(
          other => person.name === other.name
            && person.foundationLead === other.foundationLead
        ));

      this.manageUsers = result;
      console.log(this.manageUsers);
      console.log(result);

      this.totalRows = this.manageUsers.length;
    }
  }
  else if(this.isReportingGroup || this.isJJDataGroup){
    if(this.searchTerm.length===0){
      this.currentPage=0;
      this.reportingProfiles = this.reportingProfileList.slice(this.currentPage, this.perPage);
      this.totalRows = this.reportingProfileList.length;
    }
    else{
      this.reportingProfileData =new Array<UserDetail>;
      var searchFullNameList = this.reportingProfileList.filter(x=> x.fullName.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(this.currentPage, this.perPage);
                         // || x.foundationLead.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(this.currentPage, this.perPage);
      var searchRoleList = this.reportingProfileList.filter(x => x.roleName?.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(0, 10);
      var searchOrganizationList = this.reportingProfileList.filter(x => x.organization?.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(this.currentPage, this.perPage);
      var searchUserTitleList = this.reportingProfileList.filter(x => x.jobTitle?.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(0, 10);
      var searchUserPrincipalList = this.reportingProfileList.filter(x => x.userPrincipalName?.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(0, 10);

      if(searchFullNameList!=null || searchFullNameList!=undefined)
        this.reportingProfileData = this.searchConcatJJData(this.reportingProfileData, searchFullNameList);

      if(searchRoleList!=null || searchRoleList!=undefined)
        this.reportingProfileData = this.searchConcatJJData(this.reportingProfileData, searchRoleList);

      if(searchOrganizationList!=null || searchFoundationList!=undefined)
        this.reportingProfileData = this.searchConcatJJData(this.reportingProfileData, searchOrganizationList);      
      
      if(searchUserTitleList!=null || searchUserTitleList!=undefined)
        this.reportingProfileData = this.searchConcatJJData(this.reportingProfileData, searchUserTitleList);      

      if(searchUserPrincipalList!=null || searchUserPrincipalList!=undefined)
        this.reportingProfileData = this.searchConcatJJData(this.reportingProfileData, searchUserPrincipalList);

      let result = this.reportingProfileData.filter(
        (person, index) => index === this.reportingProfileData.findIndex(
          other => person.fullName === other.fullName
            && person.roleName === other.roleName
            && person.userPrincipalName===other.userPrincipalName
        ));

      this.reportingProfiles = result;
      this.totalRows = this.reportingProfiles.length;
    }
  }
  }

  searchConcatFtla(userTotalList: FoundationProfiles[], userList: FoundationProfiles[]) : FoundationProfiles[]
  {
    var result = userTotalList;
    if(userList?.length>0)
    {
      userList.forEach(a => {
        result = result.concat(a);
      });
    }
    return result;
  }

  searchConcatJJData(userTotalList: UserDetail[], userList: UserDetail[]) : UserDetail[]
  {
    var result = userTotalList;
    if(userList?.length>0)
    {
      userList.forEach(a => {
        result = result.concat(a);
      });
    }
    return result;
  }


  resetAddForm() {
    this.searchTerm = null;
    this.isAddOpen = false;
    this.profileForm.reset();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
        reader.readAsDataURL(file); // toBase64
      reader.onload = () => {
        this.imageSrc = reader.result as string; // base64 Image src
        this.profileForm.patchValue({fileSource: file});
      };

    }
  }

  // pageChanged(event: PageChangedEvent): void {
  //   this.currentPage = event.page;
  //   let skipRows = (this.currentPage - 1) * this.perPage;
  //   if(this.isFTLAGroup)
  //   this.manageUsers = this.profileList.slice(skipRows, this.currentPage*this.perPage);
  //   else if(this.isReportingGroup || this.isJJDataGroup)
  //   this.reportingProfiles = this.reportingProfileList.slice(skipRows, this.currentPage*this.perPage);
// }

    pageChanged(event: PageChangedEvent): void {
      this.currentPage = event.page;
      let skipRows = (this.currentPage - 1) * this.perPage;
      if (this.isFTLAGroup && (this.searchTerm ==undefined || this.searchTerm?.length === 0))
      {
        this.manageUsers = this.profileList?.slice(skipRows, this.currentPage*this.perPage);
      }
      else if(this.isReportingGroup || this.isJJDataGroup)
      {
        this.reportingProfiles = this.reportingProfileList?.slice(skipRows, this.currentPage*this.perPage);
      }
    }
  

  addNewProfile():void{
    this.isAddOpen = true;
    this.imageSrc=null;
  }
  
  AddUpdateUsers():void {
    let email = this.profileForm.get('userPrincipalName').value;
    let contact = this.profileForm.get('contact').value;
    let firstName = this.profileForm.get('firstName').value;
    let lastName = this.profileForm.get('lastName').value;
    let title = this.profileForm.get('title').value;
    let organization = this.profileForm.get('organization').value;
    // let founded = this.profileForm.get('founded').value;
    // let staffsize = this.profileForm.get('staffsize').value;
    let website = this.profileForm.get('url').value;
    let id = this.profileForm.get('id').value == "" ? 0 : this.profileForm.get('id').value;
    // let headquarters = this.profileForm.get('headquarters').value;
    let userId = this.profileForm.get('userId').value === undefined ? id: this.profileForm.get('userId').value;
    let file = this.profileForm.get('fileSource').value;
    // let endowment = this.profileForm.get('endowment').value;
    const formData = new FormData();
    formData.set('userPrincipalName',email);
    formData.set('contact',contact);
    formData.set('givenName',firstName);
    formData.set('surName',lastName);
    formData.set('title',title);
    formData.set('name',organization);
    // formData.set('founded',founded);
    if(this.isJJDataGroup){
        // formData.set('headquarters',null);
        formData.set('contact',null);
        formData.set('website',null);
        // formData.set('endowment','0');
        // formData.set('staffsize','0');
        formData.set('id','0');
    }
    else{
        formData.set('contact',contact);
        formData.set('website',website);
        // formData.set('headquarters',headquarters);
        // formData.set('endowment',endowment);
        // formData.set('staffsize',staffsize);
        formData.set('id',id);
    }
   
    formData.set('userId',userId);
    formData.set('profilePhoto',file);
    formData.set('foundationLead',firstName +' '+lastName);
       this.accountService.AddOrUpdateFTLAProfile(formData).subscribe(x=>{
        this.getUsers();
        this.ftlaMembers();
   });
    this.resetAddForm();
  }

  cancelAdd() {
    this.resetAddForm();
  }
  public handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src="./../../../assets/images/blank_profile.png";
  }
  editProfile(profile:FoundationProfiles):void{
    if((this.isSuperAdmin || this.isAdmin && this.isFTLAGroup) || (this.isAdmin && this.isJJDataGroup) || this.currentUser.userPrincipalName === profile.userPrincipalName) {
        this.profileForm.get('id').setValue(profile.id);
        this.profileForm.get('userPrincipalName').setValue(profile.userPrincipalName.toLowerCase());
        this.profileForm.get('firstName').setValue(profile.givenName);
        this.profileForm.get('lastName').setValue(profile.surName);
        this.profileForm.get('title').setValue(profile.title);
        this.profileForm.get('organization').setValue(profile.name);
        if(this.isJJDataGroup){
          this.profileForm.get('title').setValue(profile.jobTitle);
          this.profileForm.get('organization').setValue(profile.organization);
        }
        this.profileForm.get('contact').setValue(profile.contact);
        // this.profileForm.get('founded').setValue(profile.founded);
        this.profileForm.get('url').setValue(profile.website);
        // this.profileForm.get('staffsize').setValue(profile.staffSize);
        // this.profileForm.get('headquarters').setValue(profile.headquarters);
        this.profileForm.get('userId').setValue(profile.userId);
        // this.profileForm.get('endowment').setValue(profile.endowment);
//        this.setLinkPicture(profile)
        this.imageSrc= this.storage + '/' + profile.userId + '.jpg';
        this.addNewProfile();
        this.selectedPorfile = profile;
    }
  }

  confirmMemberToRemove(userId:any,organizationId:any):void {
    if(userId && organizationId)
    {
      this.accountService.RemoveMemberFromFTLA(userId,organizationId).subscribe(x=> {
        this.getUsers();
      });
    }
    this.closeConfirmModal();
  }

  removeProfile(profile:FoundationProfiles):void{
    if (this.confirmationModalCompoent && profile) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      this.defaultConfirmationModalEntity(profile);
    }
  }


  defaultConfirmationModalEntity(profile: FoundationProfiles) {
    this.confirmationModalCompoent.entityData = { entityData: {
        fullname: profile.givenName + ' '+profile.surName,
        userId: profile.userId,
        organizationId: profile.id
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.closeModalWindow();
    }
  }

  ftlaMembers(){
    this.commonService.getFTLAProfiles().subscribe(data => {
      this.memberslist = data;
      this.memberslist.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
      console.log(this.memberslist);
      return this.memberslist;
    });
  }

  onSiteSelected(value: any){
    if(value==0)
    {
      this.isFromFilterDropdown = false;
      this.manageUsers = this.profileList.slice(this.currentPage, this.perPage);
      this.totalRows = this.profileList.length;
    }
    else
    {
      this.isFromFilterDropdown = true;
      this.fromFilterDropdownValue = value;
      this.manageUsers =this.profileList.filter(x => x.name === value); 
    }
  }
}