import { formatDate } from "@angular/common";
import { Guid } from "guid-typescript";
import { GroupModel } from "./group.model";
import { SiteModel } from "./site.model";

export class MeasureModel {
    id: number;
    number: string;
    measure:string;
    group:GroupModel;
    lastUpdated: string;
    lastUpdatedDateTime: string;
    definition: string;
    isActive: boolean;
    groupOrder:number;
    measureShortName:string;  
    sites: Array<SiteModel>;
    constructor(id:number,number:string,measure:string,group:GroupModel,lastUpdated:string,definition:string,measureShortName:string){
        this.id=id;
        this.number=number;
        this.measure = measure;
        this.group = group;
        this.lastUpdated = lastUpdated;
        this.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");
        this.definition=definition;
        this.measureShortName=measureShortName;
    }    
}

export class ImportSiteDataResults{
    numberMissingRows :number;
    yearMissingRows:number;
    numberOfRows:number;
    jobId:Guid;
}

