import { Component, OnInit } from '@angular/core';
import { ReportConfigurationService } from '../shared/report-config.service';
import { surveyPeriod, surveyPeriodList } from '../shared/survey-period-model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { StateService } from 'src/app/core/state-manager/appstateservice';

@Component({
  selector: 'app-survery-period-selection',
  templateUrl: './survery-period-selection.component.html',
  styleUrls: ['./survery-period-selection.component.scss']
})
export class SurveryPeriodSelectionComponent implements OnInit {
  surveyPeriods:Array<surveyPeriod>;
  pagedSurveyPeriods:Array<surveyPeriod>;
  selectSurvey:string;
  updatedsurveyPeriods:Array<surveyPeriod>;
  viewAsAdmin = false;
  currentPage = 0;
  perPage = 10;
  totalRows = 0;
  formSubmitSuccess: string;
  formSubmitError: string;
  constructor(private reportconfigSrv: ReportConfigurationService,
                private stateService: StateService) { }

  ngOnInit(): void {
    this.surveyPeriods = new Array<surveyPeriod>();
    this.updatedsurveyPeriods=new Array<surveyPeriod>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();

    this.getSurveyPeriods();
  }

  getSurveyPeriods(){
    this.reportconfigSrv.getSurveyPeriods()
    .subscribe(data => {
      this.surveyPeriods = new surveyPeriodList().uiMapper(data).surveyperiods;
      this.pagedSurveyPeriods = this.surveyPeriods.slice(this.currentPage, 10);
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    this.pagedSurveyPeriods = this.surveyPeriods.slice(skipRows, this.currentPage*this.perPage);
  }

  saveSurveyPeriods():void{
    this.reportconfigSrv.updateSurveyPeriods(this.updatedsurveyPeriods)
      .subscribe((data) => {
        this.formSubmitSuccess = "Successfully saved survey periods";
        this.getSurveyPeriods();
    },
    ()=> {
      this.formSubmitSuccess = "Failed save survey periods";
    });
    this.updatedsurveyPeriods = new Array<surveyPeriod>();
  }

  checkSurveyPeriod(suvery:surveyPeriod,event:any){
    suvery.isActive=event.target.checked;
    this.updatedsurveyPeriods.push(suvery);
  }
}
