import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SearchService } from '../shared/search.service';
import { getSupportedFileAttributes } from 'src/app/common/models/file-definitions';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { filterTags } from '../shared/search-model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';

@Component({
  selector: 'app-shared-document',
  templateUrl: './shared-document.component.html',
  styleUrls: ['./shared-document.component.scss']
})
export class SharedDocumentComponent implements OnInit {
  fileDocument: any;
  showResults = false;
  previewAvailable = false;
  previewError = false;
  showDownload = false;
  extentionRegEx = /(?:\.([^.]+))?$/;

  constructor(private route: ActivatedRoute,
              private searchService: SearchService,
              public sanitizer: DomSanitizer,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    const fileId = this.route.snapshot.params['id'];
    if (fileId) {
      this.getFileContent(fileId);
    }
  }

  getFileContent(fileId: string) {
    this.googleAnalyticsService
                .eventEmitter('Get File Content', fileId, 'Shared Documents');
    this.searchService.getRawFileContent(fileId).subscribe(results => {
      if (results) {
        this.fileDocument = results;
        this.fileDocument.publicationDate = this.fileDocument.publicationDate + 'Z';
        this.updateFileAttribute(this.fileDocument);
        this.showResults = true;
      }
    });
  }

  updateFileAttribute(fileDocument) {
    const fileName = fileDocument.fileName;
    const extention = this.extentionRegEx.exec(fileName)[1].toLowerCase();
    fileDocument.fileAttributes = getSupportedFileAttributes(extention);
    this.previewError = fileDocument.containerName == null;
    this.showDownload = fileDocument.fileType[0] !== 'WebSite';
    fileDocument.toggle = 0;
    this.previewAvailable = !(fileDocument.fileType[0] === 'Email' || fileDocument.fileAttributes.fileType === 'Archive'
      || fileDocument.fileType[0] === 'Wmv' || fileDocument.fileType[0] === 'WebSite');

    fileDocument.tags = [];
    const facetsList = Object.keys(filterTags);
    facetsList.forEach((facet) => {
      if (facet !== null) {
        if (fileDocument[facet] !== null && fileDocument[facet] !== 'null') {
          fileDocument.tags.push({ key: facet, value: fileDocument[facet] });
        }
      }
    });
  }

  showPreview(fileDocument) {
    if (fileDocument.containerName !== null) {
      if (fileDocument.fileAttributes.fileType === 'PDF'
          || fileDocument.fileType[0] === 'Text'
          || fileDocument.fileType[0] === 'Data'
          || fileDocument.fileType[0] === 'Form') {
        this.searchService.getFileContent(fileDocument.fileName, fileDocument.containerName).subscribe(results => {
          const file = new Blob([results], {type: fileDocument.fileAttributes.fileContentType});
          const fileURL = URL.createObjectURL(file);
          if (fileDocument.fileType[0] === 'Text' || fileDocument.fileType[0] === 'Data' || fileDocument.fileType[0] === 'Form') {
            fileDocument.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          } else {
            fileDocument.fileURL = fileURL;
          }
          fileDocument.toggle = !fileDocument.toggle;
        });
      } else if (fileDocument.fileType[0] === 'Image') {
        this.searchService.getBlobSASUrl(fileDocument.fileName, fileDocument.containerName).subscribe(results => {
          fileDocument.fileURL =  decodeURIComponent(results);
          fileDocument.toggle = !fileDocument.toggle;
        });
     } else {
        this.searchService.getBlobSASUrl(fileDocument.fileName, fileDocument.containerName).subscribe(results => {
          fileDocument.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src='
                                    + results + '&embedded=true');
          fileDocument.toggle = !fileDocument.toggle;
        });
      }

      if (!fileDocument.toggle) {
        this.googleAnalyticsService.eventEmitter('Show Preview', fileDocument.fileName, 'Shared Documents');
      } else {
        this.googleAnalyticsService.eventEmitter('Hide Preview', fileDocument.fileName, 'Shared Documents');
      }
    }
  }

  downloadFile(fileDocument) {
    if (fileDocument.containerName !== null) {
      const headers = new HttpHeaders();
      headers.set('Accept', 'application/pdf');
      this.searchService.getFileContent(fileDocument.fileName, fileDocument.containerName).subscribe(results => {
        const file = new Blob([results], {type: fileDocument.fileAttributes.fileContentType});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileDocument.fileName;
        this.googleAnalyticsService
                    .eventEmitter('Download File', fileDocument.fileName, 'Shared Documents');
        link.click();
      });
    }
  }
}
