import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchService } from 'src/app/search-results/shared/search.service';

@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss']
})
export class SearchWidgetComponent implements OnInit, OnDestroy {

  document:any;
  isDocumentReady:boolean=false;

  constructor(private searchService: SearchService) {

   }
  ngOnDestroy(): void {
    this.searchService =null;
  }
 async ngOnInit() {
     this.getDocument();
  }

    getDocument() {
    this.searchService.getTopSearch().subscribe(data => {
      this.document= data.documents[0].document;
      this.isDocumentReady =true;
    });
    
  }
}