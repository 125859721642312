import { Component, OnInit, EventEmitter, Input, Output, ContentChild, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @ViewChild('content', {static: false}) contentTemplate;
  @ContentChild('modalContentTemplate', { static: false }) modalContentTemplate: TemplateRef<any>;
  @ContentChild('footerContentTemplate', { static: false }) footerContentTemplate: TemplateRef<any>;
  @Input() entityData: any;
  @Input() class = 'modal-lg modal-centered';
  @Input() showHeader = true;
  @Input() showFooter = true;
  // @Input() onCloseFunction:Function;
  // @Input() onCloseFunctionArgs:any = null;
  @Input() showClose = true;
  @Input() headerText: string;
  @Input() contentStyle: string;
  @Output() closeModal = new EventEmitter<string>();
  modalRef: BsModalRef;

  

  constructor(private modelService: BsModalService) { }

  ngOnInit() {
  }

  showModal() {
    this.modalRef = this.modelService.show(this.contentTemplate, { class: this.class, ignoreBackdropClick: true, keyboard: false });
    if (this.entityData) {
      this.headerText = this.entityData['headerText'];
    }
  }

  closeModalWindow(closeReason?: any) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    // if (this.onCloseFunction !== null){
    //   if(this.onCloseFunctionArgs !== null)
    //     this.onCloseFunction(this.onCloseFunctionArgs);
    //   else   
    //     this.onCloseFunction();
    // }
    this.closeModal.emit(closeReason);
  }
}
