import { Injectable, QueryList } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';

@Injectable()
export class MetaTagsService {

  constructor(private repository: RepositoryService, private currentState: StateService) { }

  getTagsList(tag: string) {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/' + tag);
  }

  getTagCount(tagName: string, tagValue: string) {
    return this.repository.get(environment.apiBaseURL, 'api/Search/documentsCount/' + tagName + '/' + tagValue);
  }

  sendMetaTagRequest(data: any) {
    return this.repository.post(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/request', data);
  }

  addTag(data: any, tag) {
    return this.repository.post(environment.apiBaseURL, 'api/ResourcesConfiguration/meatadata/' + tag, data);
  }

  removeTag(data: any, tag) {
    return this.repository.delete(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/' + tag, data);
  }

  public getSearchResults(input: string): Observable<any> {
    const newValue = encodeURIComponent(input);
    return this.repository.get(environment.apiBaseURL, 'api/Search/' + newValue);
  }
}
