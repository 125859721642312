import { Pipe, PipeTransform } from '@angular/core';

const SIZES = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

@Pipe({name: 'formatbytes'})
export class BytesTransformPipe implements PipeTransform {
  transform(value: any): string {
    if (isNaN(parseFloat(value))) {
      return '';
    }

    let unit = 0;
    while (value >= 1024) {
      value /= 1024;
      unit ++;
    }

    return value.toFixed(2) + ' ' + SIZES[unit];
  }
}
