<div class="search-results-container">
    <div class="row refine-row">
        <div class="row" *ngIf="searchOption=='searchByTagAndFileTitle' || searchOption=='searchByFileTitle' || searchOption=='searchByTagAndFileTitleWithOptions'">
          <div class="searchComponentContainer col-md-12">
            <app-search-component [resultSubmitted]=true [hideSearchByFilter]=false></app-search-component>
          </div>
        </div>
        <div class="row" *ngIf="showSearchOptions">
          <div class="col-md-4">
              <ng-multiselect-dropdown
              [placeholder]="'Search Topics'"
              [settings]="dropdownSettings"
              [data]="topicList | async"
              [(ngModel)]="selectedTopics"
              (onSelect)="onTopicSelect($event)"
              (onDeSelect)="onTopicDeSelect($event)"
              (onSelectAll)="onTopicSelectAll($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="col-md-4">
              <ng-multiselect-dropdown
              [placeholder]="'Search Program Area'"
              [settings]="dropdownSettings"
              [data]="programAreaList | async"
              [(ngModel)]="selectedProgramArea"
              (onSelect)="onProgramAreaSelect($event)"
              (onDeSelect)="onProgramAreaDeSelect($event)"
              (onSelectAll)="onProgramAreaSelectAll($event)"
            >
            </ng-multiselect-dropdown>
          </div>
          <div class="col-md-4">
            <ng-multiselect-dropdown
            [placeholder]="'Search Location'"
            [settings]="dropdownSettings"
            [data]="locationList | async"
            [(ngModel)]="selectedLocations"
            (onSelect)="onLocationSelect($event)"
            (onDeSelect)="onLocationDeSelect($event)"
            (onSelectAll)="onLocationSelectAll($event)"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 searchOptionBytag"><input type="radio" name="rbvisionsearch"  value="searchByTagAndFileTitle" checked (click)="onItemChanged($event.target.value, false)"> Refine by Tag and Title</div>
        <div class="col-md-2 searchOptionBytag"><input type="radio" name="rbvisionsearch"  value="searchByFileTitle"  (click)="onItemChanged($event.target.value, false)"> Refine by file title only</div>
        <div class="col-md-3 searchOptionBytagOnly"><input type="radio" name="rbvisionsearch"  value="searchByTagAndFileTitleWithOptions" (click)="onItemChanged($event.target.value, true)"> Refine by topic/program area/location</div>
      </div>
   </div>

    <div class="row dv-search-results-container">
        <div class="dvSearchResults col-md-12">
          <ng-container *ngIf="searchResultsReady | async; else emptyContainer">
            <div class="overlayContainer">
              <div class="row result_count_container">
                <div class="col-md-12 result_count">
                    {{itemPerPage}} Results shown for ‘<b>{{ searchQuery }}</b>’
                    <span *ngIf="showFilterDescription && filterDescriptions">filtered by <i>‘{{filterDescriptions}}’</i></span>
                    / 
                    <b>{{ totalItems }} Results Total</b>
                </div>
              </div>
              <div *ngIf="showAlert">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000 (onClosed)="onClosedAlert()">{{message}}</alert>
              </div>
              <div class="row result_count_container">
                <div class="col-md-10 favorites_button_container">
                  <a (click)="showShareResultsModal(true, false)" id="favoritesButton" class="btn share_button" [ngClass]="{ 'disable': viewAsAdmin | async }" *ngIf="ischeckPermissions(permissions.CanSaveFavorites)"><span>Save as Favorite</span><em class="button-icon fas fa-star"></em></a>
                  <a (click)="shareResults(true, true)" *ngIf="isAllGroup || isFTLA" id="copyLinkButton" class="btn share_button"><span>Copy Link</span><em class="button-icon fas fa-copy"></em></a>
  
                </div>
                <div class="col-md-2 recent_button_container">
                  <a id="lnkrelevant" (click)="sortSearch(0)" [ngClass]="{'arrow_box':sortParam === 0}" class="revelant_button btn">RELEVANT</a>
                  <label></label>
                  <a id="lnkrecent" (click)="sortSearch(1)" [ngClass]="{'arrow_box':sortParam === 1}" class="btn recent_button">RECENT</a>
                </div>
              </div>
              <ng-container *ngFor="let result of results; let i = index">
                <div class="row row_padding">
                </div>
                <div class="search-results-row">
                  <div *ngIf="result.document.externalUrl && result.document.externalUrl != 'Not Specified'">
                    <a href="javascript:void(0)" (click)="goToUrl(result.document.externalUrl);" >
                      <label *ngIf="result.document.fileTitle == 'Not Specified'">{{ result.document.fileName | titlecase }} </label>
                      <label *ngIf="result.document.fileTitle != 'Not Specified'">{{ result.document.fileTitle | titlecase }} </label>
                    </a>
                  </div>
                  <div *ngIf="result.document.externalUrl == 'Not Specified' || result.document.externalUrl === null">
                    <label *ngIf="result.document.fileTitle== 'Not Specified'">{{ result.document.fileName | titlecase }} </label>
                    <label *ngIf="result.document.fileTitle!= 'Not Specified'">{{ result.document.fileTitle | titlecase }} </label>
                  </div>
                  <div class="tags_container" *ngIf="ischeckPermissions(permissions.ShowHideDocumentTags) && result.document.showTags == 1" >
                    <ng-container *ngFor="let item of result.document.tag">
                      <label *ngFor="let itemval of item.value" (click)="filterTag(item.key, itemval)" [hidden]="itemval == 'Not Specified' || itemval== 'false' || isHidden(item.key)" class="selectedFacet Rectangle">{{ itemval }}</label>
                    </ng-container>
                  </div>
                  <p class="author_details"> Uploaded {{ result.document.uploadedDate | date: 'MMM d, y hh:mm a' }} by {{ result.document.uploadedBy }} </p>
                  <div class="col-md-12 result-description" *ngIf="result.document.description && result.document.description != 'Not Specified'">
                      {{result.document.description}}
                  </div>
                  <a (click)="showTags(result)" *ngIf="ischeckPermissions(permissions.ShowHideDocumentTags)" class="showtags"><span>{{result.document.showTagsButtonText}}</span></a>
                  <div class="row">
                      <div class="col-md-11 col-xs-11 search-row-buttons">
                        <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'Email' && result.document.fileAttributes['FileType'] !== 'Wmv' && result.document.fileAttributes['FileType'] !== 'WebSite' && result.document.fileAttributes['FileType'] !== 'Archive';">
                          <a *ngIf="result.document.toggle == 0" href="javascript:void(0)" (click)="showPreview(result)"  class="form-control preview_button">
                            <span>Show Preview</span><em class="button-icon fas far fa-eye"></em>
                          </a>
                          <a *ngIf="result.document.toggle == 1" href="javascript:void(0)" (click)="showPreview(result)"  class="form-control preview_button">
                              <span>Hide Preview</span><em class="button-icon fas far fa-eye"></em>
                          </a>
                        </ng-container>
                        <span *ngIf="result.document.fileAttributes['FileType'] === 'Email' || result.document.fileAttributes['FileType'] === 'Wmv' || result.document.fileAttributes['FileType'] === 'WebSite' || result.document.fileAttributes['FileType'] === 'Archive';"
                          class="preview_text_button">
                          Preview Not Available
                        </span>
                        <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'WebSite'">
                          <a (click)="downloadFile(result)" class="form-control searchButton download_button"><span>Download </span><em class='button-icon fas fa-download'></em></a>
                        </ng-container>
                        <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'WebSite' && (ischeckPermissions(permissions.ShareToSlack))">
                          <a (click)="sendToSlack(result)" class="form-control searchButton slack_button"><span>Send To Slack </span><em class='button-icon fas fa-share-square'></em></a>
                        </ng-container>
                        <a (click)="showShareDocumentModal(result.document)" id="shareDocumentButton" class="form-control share_document_button" [ngClass]="{ 'disable': viewAsAdmin | async }" *ngIf="ischeckPermissions(permissions.ShareDocument)"><span>Share Document</span><em class="button-icon fas fa-share-square"></em></a>
                      </div>
                      <div class="col-md-1 col-xs-1 search-row-file-type">
                        <span class="file_type_label {{ result.document.fileAttributes.CssIcon }}"><em class="{{ result.document.fileAttributes.CssClass }}"></em></span>
                      </div>
                  </div>
                  <div id="divpreview" class="preview_container_wrapper" *ngIf="result.document.toggle && result.document.containerName != null">
                    <div class="col-md preview_container" id="dvpreviewcol">
                      <iframe *ngIf="result.document.fileAttributes['FileType'] !== 'PDF' && result.document.fileAttributes['FileType'] !== 'image'"
                        [src]='result.document.fileURL'
                        class="preview_iframe"></iframe>
                      <object *ngIf="result.document.fileAttributes['FileType'] === 'PDF';" [data]="result.document.fileURL | safeUrl" type="application/pdf">Preview not available</object>
                      <img [src]="result.document.fileURL | safeUrl"  width="100%" height="100%"
                        *ngIf="result.document.fileAttributes['FileType'] == 'image'"
                      />
                    </div>
                  </div>
                  <div id="divpreview" class="preview_container_wrapper" *ngIf="result.document.toggle && result.document.containerName == null">
                    <div class="col-md preview_container" id="dvpreviewcol">
                      <p class="filePreviewWarning">Unable to show file preview as container name value is NULL.</p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div>
                <div class="text-right paginationContainer">
                  <!-- pager -->
                  <pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [maxSize]="maxSize" class="pagination"
                    [boundaryLinks]="true" [rotate]="false"  (click)="getPagedSearchResults()"></pagination>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #emptyContainer>
            <p class="noResults" *ngIf="(searchQuery=='' || searchQuery===undefined)">Start new search</p>
            <p class="noResults" *ngIf="(searchQuery!==undefined)">No results for <b>"{{ searchQuery }}"</b></p>
          </ng-template>
        </div>
    </div>
  </div>
  
  <app-modal #shareResultsDialog contentStyle="share-results-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-metaDataForm="dataItem.entityData.shareResultsForm" let-isFavouriteForm="dataItem.entityData.isFavouriteForm">
      <form [formGroup]="shareResultsForm" (ngSubmit)="shareResults(isFavouriteForm, false)">
          <div class="share-results-content">
            <div class="row">
              <div  class="col-sm-4">
                  <span>Search Query Title</span>
              </div>
              <div class="col-sm-8">
                <div class="display-text">
                  <input id="titleText" type="text" placeholder="Search Query Title" class="form-control" formControlName="title" [ngClass]="{ 'invalid': !validSearchQueryTitle() }" maxlength="250">
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!isFavouriteForm">
              <div  class="col-sm-4">
                  <span>Email Address</span>
              </div>
              <div class="col-sm-8">
                <div class="display-text">
                  <input id="emailAddressText" type="email" placeholder="Email Address" class="form-control" [(ngModel)]="shareResultEmailAddress"  [pattern]="emailPattern" #emailAddressText="ngModel" [ngModelOptions]="{standalone: true}"  [ngClass]="{ 'invalid': (shareResultsSubmitted && shareResultsFormFields['shareWith'].errors) || (!validateEmailAddress(emailAddressText)) }">
                  <span (click)="addEmailAddress(emailAddressText)" ><em class="fa fa-plus"></em></span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="emailAddressAdded" formArrayName="shareWith" class="share-email-addresses">
            <div class="file-row" *ngFor="let item of emailAddressToShare.controls; let i=index">
              <div  class="col-sm-10">
                  {{item.value}}
              </div>
              <div class="col-sm-2 delete-icon-col">
                  <button class="btn delete-icon" (click)="removeEmailAddress(item.value)">
                      <span class="glyphicon glyphicon-trash"></span>
                  </button>
              </div>
            </div>
          </div>
          <div class="col-sm-12 footer-style">
              <div>
                  <button type="button" class="btn button-style cancelbtn" style="width: 100px;" (click)="closeShareResultsModal()">
                      <span>Cancel</span>
                  </button>
              </div>
              <div class="savebtn" *ngIf="isFavouriteForm">
                <button type="submit" class="btn button-style">
                    <span *ngIf="!isCopyLink">Save as Favorite</span>
                    <span *ngIf="isCopyLink">Copy Link</span>
                </button>
             </div>
              <div class="savebtn" *ngIf="!isFavouriteForm">
                  <button type="submit" class="btn button-style">
                      <span>Share Results</span>
                  </button>
              </div>
          </div>
      </form>
   </ng-template>
  </app-modal>
  
  
  <app-modal #shareDocumentDialog contentStyle="share-document-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-document="dataItem.entityData.document">
      <form [formGroup]="shareDocumentForm" (ngSubmit)="shareDocument(document)">
          <div class="share-document-content">
            <div class="row">
              <div  class="col-sm-4">
                  <span>Email Address</span>
              </div>
              <div class="col-sm-8">
                <div class="display-text">
                  <input id="emailAddressText" type="email" placeholder="Email Address" class="form-control" formControlName="emailAddress" [ngClass]="{ 'invalid': (!validateEmailAddress(shareDocumentFormFields['emailAddress'])) }">
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 footer-style">
              <div>
                  <button type="button" class="btn button-style cancelbtn" style="width: 100px;" (click)="closeShareDocumentModal()">
                      <span>Cancel</span>
                  </button>
              </div>
              <div class="savebtn">
                  <button type="submit" class="btn button-style">
                      <span>Share Document</span>
                  </button>
              </div>
          </div>
      </form>
   </ng-template>
  </app-modal>
  
  <div *ngIf="showShareAlert">
    <alert type="success" class="toastr" [dismissible]=true [dismissOnTimeout]=5000 (onClosed)="onClosedAlert()">{{message}}</alert>
  </div>