import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { OAuthService } from 'angular-oauth2-oidc';

const tokenKey = 'Bearer';

@Injectable()
export class TokenStorage {

    constructor(
        private oktaAuth:OktaAuth
        ) { }

    clearToken() {
        window.localStorage.removeItem(tokenKey);
       // this.oktaAuth.signOut();
    }

    public saveToken(token: string) {
        window.localStorage.removeItem(tokenKey);
        window.localStorage.setItem(tokenKey, token);
        window.sessionStorage.setItem('id_token',token);
    }

    public getToken(): string {
       return window.localStorage.getItem(tokenKey);
    }
}
