import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { StateService } from '../state-manager/appstateservice';

export class TokenInterceptor implements HttpInterceptor {
    constructor(private stateService: StateService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('Anonymous') !== null) {
            const newHeaders = request.headers.delete('Anonymous')
            const newRequest = request.clone({ headers: newHeaders });
            return next.handle(newRequest);
          } else {
        const authRequest = this.addAuthenticationToken(request);
        return next.handle(authRequest)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        this.stateService.removeToken();
                        this.router.navigate(['/']);
                    } else if (error.status === 403) {
                        if (error.error === 'User is not active'){
                            this.stateService.setUserInActive();
                            this.router.navigate(['/not-authorized-page']);
                            return;
                        }else{
                        this.stateService.setUnAuthorized();
                        this.router.navigate(['/not-authorized-page']);
                        return;
                        }
                    }
                    else{
                        this.stateService.setAuthorized();
                        return throwError(error);
                    }
                    
                })
            );
          }
    }

    addAuthenticationToken(request) {
        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!this.stateService.isTokenExists()) {
            return request;
        }

        //// get view user
        const userEmail = this.stateService.getViewingUserEmail() || '';
        // Get id token
        const idToken = this.stateService.getToken();
        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: idToken,
                'X-View-As-User': userEmail
            }
        });
    }
}
