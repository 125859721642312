import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NewsFeedComponent } from './news-feed/news-feed.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TruncatePipe } from '../shared/pipes/truncate.pipe';
import { NewsFeedService } from './shared/news-feed.service';
import { NewsFeedManageComponent } from './news-feed-manage/news-feed-manage.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [NewsFeedComponent, NewsFeedManageComponent, TruncatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    AccordionModule,
    CarouselModule.forRoot(),
    BsDropdownModule,
    SharedModule
  ],
  exports: [NewsFeedComponent],
  bootstrap: [NewsFeedComponent],
  providers: [
    NewsFeedService,
    DatePipe
  ]
})
export class NewsFeedModule { }
