import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';

import { RepositoryService } from './services/http/repository.service';
import { GlobalErrorHandler } from './error-handler/global-error-handler';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { TokenStorage } from './state-manager/token-storage';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { LocalStorageService } from './state-manager/local-storage/local-storage.service';
import { StateService } from './state-manager/appstateservice';
import { ErrorLogService } from './services/logger/error-log.service';
import { OktaAuthGuard } from './guards/auth.guard';
import { SessionStorageService } from './state-manager/session-storage/session-storage.service';
import { RoleService } from './roles/role.service';
import { NavigationService } from './navigations/navigation.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [ RepositoryService, TokenStorage, LocalStorageService, SessionStorageService, NavigationService,
    StateService, ErrorLogService, OktaAuthGuard, RoleService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true, deps: [StateService, Router] },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: 'LocationRef', useValue: window.location }
  ]
})
export class CoreModule { }
