import { Component, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { DataCollectionsModel } from '../models/datacollections.model';
import { ImportJobStatus } from '../models/import-job-details.model';
import { SiteServices } from '../services/site.services';
import { SiteMeasureServices } from '../services/siteMeasure.services';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";

@Component({
  selector: 'app-approve-site-data',
  templateUrl: './approve-site-data.component.html',
  styleUrls: ['./approve-site-data.component.scss']
})
export class ApproveSiteDataComponent implements OnInit {

  importJobs: Array<ImportJobStatus>;
  stagingData :Array<DataCollectionsModel>;
  formSubmitSuccess: string;
  formSubmitError: string;
  isApprovedRejected:boolean =false;
  siteId:number;
  Id: Guid;
  isFromImportExcel:boolean = false;
  pageTitle:string;
  isErrorWarnings:boolean = false;
  constructor(private siteMeasureService: SiteMeasureServices,
              public googleAnalyticsService: GoogleAnalyticsService,private route: ActivatedRoute, private router: Router ) { 

    }

  ngOnInit(): void {
    
    this.route.queryParams
      .subscribe(params => {
        this.Id = params.Id;
        if(this.Id == undefined) { this.getImportJobDetails(); this.isFromImportExcel = false; this.pageTitle = "Approve Imported Site Measure Data" }
        else{ this.getImportJobDetailsValidate(this.Id); }
      }
    );
    
    this.siteId = (JSON.parse(window.sessionStorage.getItem('site')).id);
  }

  getImportJobDetails(){
    this.siteMeasureService.getImportJobStatus().subscribe(data=>{
      this.importJobs = data;
        let results = this.importJobs.filter(a=>a.site.id==this.siteId && (a.status!='Approved' ||'Review Pending-validate' || 'NotStarted-Validate') );
        this.importJobs.length=0;
        this.importJobs = results;
    });
  }

  getImportJobDetailsValidate(Id?){
    this.siteMeasureService.getImportJobStatusValidate(Id).subscribe(data => {
      this.importJobs = data;
      if (this.importJobs != null || this.importJobs != undefined) {
        this.modifySet(this.importJobs[0],false,true);
        this.isFromImportExcel = true;
        this.pageTitle = "Imported Site Measure Data - Validation Result"
      }
    });
  }

  modifySet(job:any, openModal: boolean, isOpen: boolean) {
   this.getImportSiteData(job.jobId);
    this.isApprovedRejected = job.status == "Approved" || job.status == "Rejected";
   }

  getImportSiteData(jobId:Guid){
    this.siteMeasureService.getImportJobSiteData(jobId).subscribe(data=>{
      this.stagingData =data;
      this.isErrorWarnings=false;
      const errorRegex:RegExp = /error ?:/;
      var errorCommentsData = this.stagingData.filter( d => errorRegex.test(d.comments.toLowerCase()) );
      if(errorCommentsData.length>0)
        this.isErrorWarnings=true;
    });
  }

  ApproveRejectMeasureData(jobId:Guid, isApproved:boolean){
    this.siteMeasureService.approveImportJobs(jobId,isApproved).subscribe(x=>{
      this.getImportJobDetails();
    });
  }

  ImportMeasureData(){
    this.siteMeasureService.importMeasureData(this.Id).subscribe(x=>{
      alert("Data Imported Successfully....!!!!");
      this.ngOnInit();
      this.router.navigate(['/measureApprove']).then(() => {window.location.reload()});
    });
  }

  RejectImportMeasureData(){
    this.siteMeasureService.rejectImportMeasureData(this.Id).subscribe(x=>{
      alert("Data Rejected Successfully....!!!!");
      window.location.reload();
      this.router.navigate(['/manageDataCollections']).then(() => {window.location.reload()});
    });
  }

}
