<div class="container manage-users">
    <div class="heading d-flex">
          <div class="page-title">
            <div>
                <span calss="page-title"><h1>Manage Groups</h1></span>
            </div>
        </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="search_icon_profile">
        <i class="fas fa-search"></i>
    </div>
    <div class="searchBoxForm_profile">
            <input type="text" id="searchTerm" name="searchTerm" placeholder="Search groups..." autocomplete="off"
                         [(ngModel)]="searchTerm" (keyup)="search()"  class="searchBox_Profile"  />
    </div>
    <button type="button"  id="btnAddGroup" class="btn btn-success button-style" (click)="addGroup()">Add Group</button>
</div>
<div class="row">
    <div class="col-12 table-responsive">
          <table class="table table-striped sortable" id="printableArea">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th><div style="display:inline-flex"> Group Name <a (click)="toggleTag('name')" [hidden]="groupAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!groupAsc" (click)="toggleTag('name')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex">Last Updated<a (click)="toggleTag('lastUpdated')" [hidden]="lastUpdatedAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedAsc" (click)="toggleTag('lastUpdated')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex">Last Updated Date Time<a (click)="toggleTag('lastUpdatedDateTime')" [hidden]="lastUpdatedDateTimeAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedDateTimeAsc" (click)="toggleTag('lastUpdatedDateTime')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex"> Is Active <a (click)="toggleTag('isActive')" [hidden]="isActiveAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!isActiveAsc" (click)="toggleTag('isActive')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> 
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let group of manageGroups; let rowIndex=index;">
                <td title="delete group">
                    <div class="delete-icon" [ngClass]="{ 'disabled':  viewAsAdmin }">
                    <span *ngIf="isAdmin" class="role-icon" (click)="removeGroup(group)">
                        <em class="far fa-trash-alt"></em>
                    </span>  
                </div>
            </td>
            <td title="edit group">
                <div class="delete-icon" [ngClass]="{ 'disabled': viewAsAdmin }">
                <span *ngIf="isAdmin" class="role-icon" (click)="editGroup(group, rowIndex)">
                    <em class="far fa-edit"></em>
                </span>  
            </div>
        </td>
                <td>{{group.name}}</td>
                <td>{{group.lastUpdated}}</td>
                <td>{{group.lastUpdatedDateTime | timezone:'MM/DD/YYYY hh:mm A' }}</td>
                <td>{{group.isActive ? 'Yes':'No'}}</td>
              </tr>
            </tbody>
          </table>
    </div>
</div>
<div class="row" *ngIf="manageGroups?.length>0">
    <div class="col-md-2 padding-2p" *ngIf="manageGroups?.length>0">
        <button type="button"   [ngClass]="{ 'disabled':  viewAsAdmin }" *ngIf="isAdmin"  id="btnprintyears" class="btn btn-success activeyears"  (click)="exportGroups()">Excel Groups</button>
    </div>
    <div class="col-md-6" >
        <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)"  [boundaryLinks]="true" [maxSize]="10"></pagination>
    </div>    
    <div class="col-md-4 pull-right padding-2p">
        
            <div class="col-xs-6 px-5">
                <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
            </div>
            <div class="col-xs-6 px-5">
                <button type="button" id="btnsave" class="btn btn-secondary button-style" [disabled]="!enableSave"  (click)="saveGroup()" [ngClass]="{ 'disable-color': viewAsAdmin }">Save</button>
            </div>
    </div>
</div>
<div class="row" *ngIf="manageGroups?.length===0">
    <div class="col-md-4" ></div>
    <div class="col-md-4" >  <p>No Groups Found.</p></div>
    <div class="col-md-4" ></div>
  
</div>
<div class="clearfix">
        <div class="pull-right">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
</div>
<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <form [formGroup]="groupForm">
                <div>Group Name </div>
                    <div>
                        <input id="txtgroupName" type="text" name="txtgroupName" placeholder="Group Name"  autocomplete="new-txtgroupname" 
                        [ngClass]="{'input-error': groupForm.controls.name.touched && groupForm.controls.name.status=='INVALID'}"
                        formControlName="name" class="form-control" required />
                    </div>
                <div>Select Group Active/InActive</div>
                <div>
                    <input id="chkGroup" type="checkbox" value="isActive" formControlName="isActive" > Is Active
                </div>
        </form>
    </div>
    <div *ngIf="isGroupExists">
        <span class="alert alert-danger">Group name already exists.</span>
    </div>
    
    <div class="col-md-4 px-5">
        <button type="button" (click) = "cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
          <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateGroup()" [disabled]="groupForm.invalid">{{buttonText}}</button>
    </div>
  </div>
<div class="backdrop" *ngIf="isAddOpen"></div>

<app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <div class="col-sm-12 content-style">
            <div *ngIf="dataItem">
                <span>Are you sure you want to delete {{dataItem.deleteGroup.name}}?</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="confirmGroupToRemove(dataItem.deleteGroup)">
                    <span>Ok</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<app-modal #warningModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate>
        <div class="col-sm-12 content-style">
            <div>
                <span>You cannot delete this group because measures are associated</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeWarningModal()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

</div>