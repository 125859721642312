<div class="container manage-users">
    <div class="heading d-flex">
          <div class="page-title">
            <div>
                <span calss="page-title"><h1>Manage Okta Settings</h1></span>
            </div>
        </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>
</div>
    <div class="row">
        <div class="col-12 table-responsive">
          <table class="table table-striped" id="printableArea" [ngClass]="{ 'loading': isLoading }">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Client Id</th>
                <th>Issuer URL</th>
                <th>Okta Group Id</th>
                <th>Okta Group Name</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let setting of manageOktaSettingsPage; let rowIndex=index;">
                <div class="col-sm-1 align-left">
                  
                      <span class="role-icon" (click)="editPortalOktaSettings(setting, rowIndex)">
                          <em class="fa fa-edit"></em>
                      </span>  
              </div>
                <td>{{setting.name}}</td>
                <td>{{setting.clientId}}</td>
                <td>{{setting.issuer}}</td>
                <td>{{setting.oktaGroupId}}</td>
                <td>{{setting.oktaGroupName}}</td>
                <td>Active</td> 
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-8">
            <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)"  [boundaryLinks]="true"></pagination>
          </div>    
          <div class="col-md-4 padding2p addRowBtn">
              <button type="button" id="btnadd" class="btn btn-success button-style mr-1rem" (click)="addUser()">Add Portal</button>
              <button type="button" id="btncancel" class="btn btn-secondary button-style back-button">Cancel</button>
          </div>
        </div>
      </div>
    <div class="clearfix">
        <div class="pull-right">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
    </div>
<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <form [formGroup]="oktaSettingsForm">
            <div>Name</div>
                <div>
                    <input id="txname" type="text" name="txname" placeholder="Portal Name" 
                    [ngClass]="{'input-error': oktaSettingsForm.controls.name.touched && oktaSettingsForm.controls.name.status=='INVALID'}"
                     autocomplete="disabled" formControlName="name" class="form-control" required (blur)="checkPortalExists()" />
                </div>
                <div>Client Id </div>
                    <div>
                        <input id="txtclientId" type="text" name="txtclientId" placeholder="Client Id"  autocomplete="new-txtclientId" 
                        [ngClass]="{'input-error': oktaSettingsForm.controls.clientId.touched && oktaSettingsForm.controls.clientId.status=='INVALID'}"
                        formControlName="clientId" class="form-control" required />
                    </div>
                <div>Issuer </div>
                <div>
                    <input id="txtissuer" type="text"  placeholder="Issuer Url"  
                    [ngClass]="{'input-error': oktaSettingsForm.controls.issuer.touched && oktaSettingsForm.controls.issuer.status=='INVALID'}"
                    autocomplete="disabled" formControlName="issuer" class="form-control" required />
                </div>
                <div>Okta Group Id </div>
                <div>
                    <input id="txtGroupId" type="text"  placeholder="Okta Group Id"  autocomplete="disabled" formControlName="oktaGroupId" class="form-control" />
                </div>
                <div>Okta Group Name </div>
                <div>
                    <input id="txtGroupName" type="text"  placeholder="Okta Group Name"  autocomplete="disabled" formControlName="oktaGroupName" class="form-control" />
                </div>
                <div>Active</div>
                <div>
                    <input id="chkActive" type="checkbox"  formControlName="isActive"  checked readonly >Active
                </div>
        </form>
    </div>
    
    <div class="col-md-4 px-5">
        <button type="button" (click)="cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddOktaSettings()" [disabled]="isOktaExists || oktaSettingsForm.invalid">Save</button>
    </div>
    <div class="row padding-top-20">
        <span class="alert alert-danger" role="alert" *ngIf='isOktaExists==true'>Okta portal already exists</span>
    </div>
    <!-- <div>
      <span class="alert alert-warning" role="alert">Saving these settings will cause the web application to restart and temporarily become unavailable. Please make changes outside business hours (if possible).</span>
    </div> -->
  </div>
  <div class="backdrop" *ngIf="isAddOpen"></div>