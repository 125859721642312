import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportExportService } from '../shared/reportexport.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { ReportExportDetails } from '../shared/report-export.model';

@Component({
  selector: 'app-report-export',
  templateUrl: './report-export.component.html',
  styleUrls: ['./report-export.component.scss']
})
export class ReportExportComponent implements OnInit {

  exportedReports: Array<ReportExportDetails>;
  viewAsAdmin = this.commonService.getViewUserEventEmitter();

  constructor(private reportexportsrv: ReportExportService,  
              private router: Router, 
              private commonService: CommonEntityService, 
              public googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.getListOfExportRequests();
  }

  getListOfExportRequests() {
    this.reportexportsrv.getListOfExportRequests()
    .subscribe(data => {
      this.exportedReports = data;
    });
  }

  downloadReport(report: ReportExportDetails){
    this.reportexportsrv.downloadReportFile( report.id,report.fileExtension,report.reportName)
      .subscribe(data =>{
        const fileContentType  = report.fileExtension ==='PDF' ? 'application/pdf': 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        const file = new Blob([data], {type: fileContentType});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = report.reportName+'.'+report.fileExtension;
        link.click();
        this.hideloader(); 
    });
  }

  hideloader() :void { 
    // Setting display of spinner 
    // element to none 
    document.getElementById('loading').style.display = 'none'; 
} 

showloader() :void { 
    // Setting display of spinner 
    document.getElementById('loading').style.display = 'block'; 
} 
}