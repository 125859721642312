import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { EVENT_EXPIRED, OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { LocalStorageService } from 'src/app/core/state-manager/local-storage/local-storage.service';
import { SystemPermissions } from 'src/app/permissions';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { environment } from 'src/environments/environment';
import { AppInsightService } from '../../logging/appinsight.service';
import { CommonEntityService } from '../../services/common-entity.service';
declare let gtag: Function;
@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {
  @ViewChild('searchUserDialog', {static: false}) searchUserModalComponent: ModalComponent;
  isCollapsed = true;
  isAllGroup = false;
  isJdaiGroup = false;
  isThriveGroup = false;
  isFtlaGroup = false;
  isJJDataGroup = false;
  isJcyoiGroup = false;
  singleLogin = false;
  searchUserForm: FormGroup;
  searchUserSubmitted = false;
  userRole: Roles;
  searchUserResult: Observable<any>;
  typedUser: string;
  viewUserName: string;
  isViewasAdmin = false;
  viewUserSelected = false;
  googleAnalyticsId = environment.defaultGAId;
  permissions = SystemPermissions;
  constructor(public authStateService: OktaAuthStateService, 
    private oktaAuth: OktaAuth,
    public googleAnalyticsService: GoogleAnalyticsService,
    private currentState: StateService,
    private storageService: LocalStorageService,
    private appInsights: AppInsightService,
    private router: Router,
    private formBuilder: FormBuilder,
    private commonService: CommonEntityService,
    private route: ActivatedRoute
    ) {
    this.setGroupSession();
    this.configureGoogleAnalytics();
  }

  get isFtla() {return this.currentState.isFTLAGroup(); }

  setGroupSession() {
    if (!this.currentState.getGroupSession()) {
      this.currentState.setGroupSession();
    }
  }

  ischeckPermissions(permission: string): boolean {
    return this.currentState.permissionCheck(permission);
  }
  
 async ngOnInit() {
   
    this.initSearchUsertForm();
    this.isAllGroup = this.currentState.isAllGroup();
    this.isJdaiGroup = this.currentState.isJDAIGroup();
    this.isThriveGroup = this.currentState.isThriveGroup();
    this.isFtlaGroup = this.currentState.isFTLAGroup();
    this.isJcyoiGroup = this.currentState.isJCYOIGroup();
    this.isJJDataGroup = this.currentState.isJJGroup();
    this.singleLogin = this.currentState.isAllGroup() || this.currentState.isJDAIGroup() || this.currentState.isGrantsGroup()
                        // tslint:disable-next-line: max-line-length
                        || this.currentState.isThriveGroup()
                        || this.currentState.isReportingGroup()
                        || this.currentState.isKmsGroup()
                        || this.currentState.isFTLAGroup()
                        || this.currentState.isJJGroup()
                        || this.currentState.isJCYOIGroup();

    this.searchUserResult = new Observable<any>((observer: any) => {
      // Runs on every search
      observer.next(this.typedUser);
    }).pipe(mergeMap((searchTerm: string) => searchTerm.length > 0 ? this.commonService.getSearchedUsers(searchTerm) : []));

    this.userRole = this.currentState.getRole();
    this.currentState.getSessionEvent().subscribe(() => {
      this.userRole = this.currentState.getRole();
    });
    this.commonService.emitViewUserEvent(this.currentState.isViewAsAdmin());
    this.commonService.getViewUserEventEmitter().subscribe(data => {
      this.isViewasAdmin = data;
      if (data) {
        const userData = this.currentState.getViewingUserData();
        this.viewUserName =  userData ? userData.fullName : this.userName;
      }
    });

    this.authStateService.authState$.subscribe(x=> {
      if(x.isAuthenticated && this.currentState.getOktaUserName())
      {
        this.configureGoogleAnalytics();
       this.currentState.setToken(this.oktaAuth.getIdToken());
         this.oktaAuth.getUser().then(user=> {
          if (user) {
            if (user && user.name) {
              this.appInsights.setAuthenticatedUserId(user.name);
              return user.name;
            } 
          }
        });
      }
    });

    this.configureGAForPortals();
    
  }

  configureGoogleAnalytics()
  {
    if (environment.gaEnable) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            const user = this.currentState.getUserData();
            if (user != null && typeof user.id !== 'undefined' && user.id != null) {
              gtag('set', {user_id: user.id});
              gtag('config', this.googleAnalyticsId, {
                custom_map: {
                  dimension1: 'userCustId'
                },
                page_path: event.urlAfterRedirects,
                userCustId: user.id
              });
              window['dataLayer'] = window['dataLayer'] || [];
              window['dataLayer'].push({
                dlUserId : user.id
              });
            } else {
              gtag('config', this.googleAnalyticsId, {page_path: event.urlAfterRedirects});
            }
        }
      });
    }
  }
  configureGAForPortals()
  {
    if (this.currentState.isAllGroup()) {
      if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.allGAId, environment.allGTMId);
        this.googleAnalyticsId = environment.allGAId;
      }
    } else if (this.currentState.isReportingGroup()) {
       if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.reportingGAId, environment.reportingGTMId);
        this.googleAnalyticsId = environment.reportingGAId;
       }
    } else if (this.currentState.isJDAIGroup()) {
      if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.jdaiGAId, environment.jdaiGTMId);
        this.googleAnalyticsId = environment.jdaiGAId;
      }
    } else if (this.currentState.isThriveGroup()) {
      if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.visionGAId, environment.visionGTMId);
        this.googleAnalyticsId = environment.visionGAId;
      }
    } else if (this.currentState.isFTLAGroup()) {
      if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.ftlaGAId, environment.ftlaGTMId);
        this.googleAnalyticsId = environment.ftlaGAId;
      }
    } else if (this.currentState.isJCYOIGroup()) {
      if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.jcyoiGAId, environment.jcyoiGTMId);
        this.googleAnalyticsId = environment.jcyoiGAId;
      }
    } else {
      if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.defaultGAId, environment.defaultGTMId);
        this.googleAnalyticsId = environment.defaultGAId;
      }
    }
  }
  get isAdmin() { return this.userRole === Roles.Admin; }

  get isSuperAdmin() { return this.userRole === Roles.SuperAdmin; }

  isValidUser() {
    const userControl = this.searchUserForm.controls['user'];
    if (userControl.value && !userControl.errors) {
      return true;
    }

    return !this.searchUserSubmitted;
  }

  // Selected value event
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.searchUserForm.controls.user.setValue(e.item);
    this.typedUser = e.item;
    this.viewUserSelected = true;
  }

  resetForm() {
    this.searchUserForm.reset({user: ''});
  }

  initSearchUsertForm() {
    this.searchUserForm = this.formBuilder.group({
      user:  ['', Validators.required]
    });
  }

  viewasAdmin() {
    this.currentState.resetToAdminUser();
    this.commonService.emitViewUserEvent(false);
    this.googleAnalyticsService
                .eventEmitter('View As Admin', this.userName, 'Security Log');
    this.navigateToRoutes(this.storageService.getItem('rootRoute'));
  }

  showSearchUserModal() {
    this.searchUserSubmitted = false;
    this.removeUserSelect();
    if (this.searchUserModalComponent) {
      this.searchUserModalComponent.entityData = {
        headerText: 'Search User'
      };
      this.searchUserModalComponent.showModal();
    }
  }

  removeUserSelect() {
    this.viewUserSelected = false;
    this.typedUser = '';
    this.resetForm();
  }

  selectUser() {
    this.searchUserSubmitted = true;
    if (!this.isValidUser()) {
      return;
    }

    this.viewUserName = this.searchUserForm.value.user.fullName;
    this.currentState.setViewingUserData(this.searchUserForm.value.user);
    this.commonService.emitViewUserEvent(true);
    this.closeSelectUserModal(true);
    this.googleAnalyticsService
                .eventEmitter('View As User', this.userName + ' View As ' + this.viewUserName, 'Security Log');
    this.navigateToRoutes(this.router.url);
  }

  closeSelectUserModal(reason: boolean) {
    if (this.searchUserModalComponent) {
      this.searchUserModalComponent.closeModalWindow(reason);
    }
  }

  async login() {
    sessionStorage.removeItem('notauthorized');
    sessionStorage.removeItem('userIsActive');
    this.currentState.setUserActive();
    await this.oktaAuth.signInWithRedirect({ originalUri: '/' });
    if (this.userName) {
      this
      .googleAnalyticsService
      .eventEmitter('User Login', 'p' + '/' + this.userName, 'Security Log');
    }
  }

  async logout() {
    await this.oktaAuth.signOut();
    this.currentState.clearAllSessions();
    this.googleAnalyticsService.eventEmitter('User Logout', 'Logout', 'Security Log');
    this.router.navigate(['/']);
  }

  get userName() {
    if (this.currentState.isLoggedIn) {
      const user = this.currentState.getUserData();
      if (user && user.fullName) {
        this.appInsights.setAuthenticatedUserId(user.fullName);
        return user.fullName;
      } 
    }
  }

  navigateToRoutes(route: string) {
    //this.router.navigate([route]);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([route], { relativeTo: this.route });
  }
}
