import { IEmbedConfiguration } from 'powerbi-client';
import * as pbi from 'powerbi-client';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { NgxPowerBiService } from 'ngx-powerbi';
import { ReportService } from '../shared/report.service';
import { StateService } from '../../core/state-manager/appstateservice';
import { Report } from '../shared/models/ReportModel';
@Component({
  selector: 'app-embed-report',
  templateUrl: './embed-report.component.html',
  styleUrls: ['./embed-report.component.scss']
})
export class EmbedReportComponent implements OnInit {
  public screenHeight: number;
  private powerBiService: NgxPowerBiService;
  config: IEmbedConfiguration;
  private pbiContainerElement: HTMLElement;
  report: Report;
  reportmodel: any;
  pages: pbi.Page[];
  currentPage: pbi.Page;
  @Input() reportName:string;
  @Input() displayOption:string;
  @Input() navigation:boolean;
  @Input() hideVisuals:Array<string>;
  activePage:pbi.Page;
  embedReport:pbi.Report;
  dashboardLoadError:boolean;
  currentUser: any;
  constructor(private reportService: ReportService,
              public currentState: StateService) { }

  ngOnInit(): void {
    this.currentUser = this.currentState.getUserData();
    this.BindPowerBiEmbedReport();
  }

  BindPowerBiEmbedReport()
  {
    this.reportService.getReportObject(this.reportName,-1).subscribe(res => {
      this.reportmodel = res;
      this.config = {
          type: res.reportType,
          id: res.id,
          accessToken: res.embedToken.token,
          embedUrl: res.embedUrl,
          permissions: pbi.models.Permissions.All,
          tokenType: pbi.models.TokenType.Embed,
          viewMode: pbi.models.ViewMode.View,
          pageName:this.activePage?.name,
          settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: this.navigation,
              layoutType: pbi.models.LayoutType.Custom,
              customLayout: {
                 displayOption: this.displayOption==='FitToWidth' ? pbi.models.DisplayOption.FitToWidth : pbi.models.DisplayOption.FitToPage
              },
          }
      };
      this.pbiContainerElement =  document.getElementById('pbi-container');
      this.powerBiService = new NgxPowerBiService();
      this.powerBiService.reset(this.pbiContainerElement);
      this.embedReport = this.powerBiService.embed(this.pbiContainerElement, this.config) as pbi.Report;
      this.embedReport.off('loaded');
      this.embedReport.on('loaded', () => {
          console.log(this.reportmodel.pageName);
              if(this.reportmodel.pageName!==null){
                this.embedReport.setPage(this.reportmodel.pageName);
            }
            else{
              this.embedReport.getPages().then(pages=>{
                console.log(pages);
                let isSingleSite = false;
                if(this.currentState.isViewAsAdmin()){
                  isSingleSite = this.currentState.getViewingUserData().sites?.length== 1 ? true : false;
                }
                else{
                  if(this.currentUser==null)
                  this.currentUser = this.currentState.getUserData();
                  isSingleSite = this.currentUser?.sites?.length ==1  ? true : false;
                }
                let pageName = isSingleSite ? pages[0].name : pages[1].name;
                this.embedReport.setPage(pageName);
              });
        }
          let iframe = this.pbiContainerElement.getElementsByTagName('iframe')[0];
          iframe.setAttribute("frameborder","0");
         }),
       this.embedReport.on('rendered', ()=>
         {
         })
  },
  err => {
      console.log(err);
      this.dashboardLoadError = true;
      throw new Error('Exception occured while rendering the dashboard:' + err);
  });
  }
}
