import { Component, OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { organizationProfileService } from '../services/organizationprofile.services';
import { organizationProfileModel } from '../models/organizationprofile.model';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { SystemPermissions } from '../../permissions';
import { debug } from 'console';
import { FoundationProfiles } from 'src/app/account/shared/profile.model';

@Component({
  selector: 'app-organizationprofile',
  templateUrl: './organizationprofile.component.html',
  styleUrls: ['./organizationprofile.component.scss']
})
export class OrganizationprofileComponent implements OnInit {
  @ViewChild('confirmationModal', { static: false }) confirmationModalCompoent: ModalComponent;
  @ViewChild('warningModal', { static: false }) warningModal: ModalComponent;
  foundationProfile: organizationProfileModel = null;
  managedProfileList: Array<organizationProfileModel>;
  removedProfileList: Array<organizationProfileModel>;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageProfile: Array<organizationProfileModel>;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  profileForm: FormGroup;
  searchTerm: string;
  currentUserRole: Roles;
  isProfileExists: boolean;
  selectedProfile: organizationProfileModel;
  currentUser: any;
  lastSort: string;
  isAddDataCollectionOpen: boolean;
  dataCollectionForm: FormGroup;
  selectedfoundation: FoundationProfiles;
  technologiesAsc: boolean = true;
  stateAsc: boolean = true;
  lastUpdatedAsc: boolean = true;
  lastUpdatedDateTimeAsc: boolean = true;
  isActiveAsc: boolean = true;
  permissions = SystemPermissions;
  isNew: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  TechnologiesImageURL: string;
  foundationName: string;
  constructor(private profileService: organizationProfileService,
    private stateService: StateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public datepipe: DatePipe,
    private router: Router
  ) {
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }

  ischeckPermissions(permission: string): boolean {
    return this.stateService.permissionCheck(permission);
  }

  ngOnInit() {
    this.managedProfileList = new Array<organizationProfileModel>();
    this.removedProfileList = new Array<organizationProfileModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();

    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    this.foundationName = this.currentUser.organization;
    
    this.dropdownList.push({ item_id: 'JDAI', item_text: 'JDAI' });
    this.dropdownList.push({ item_id: 'PT', item_text: 'PT' });
    this.dropdownList.push({ item_id: 'RJJ', item_text: 'RJJ' });
    this.dropdownList.push({ item_id: 'ELS', item_text: 'ELS' });

    this.profileForm = new FormGroup({
      itStaffCount: new FormControl(''),
      itBudget: new FormControl(''),
      offshoreEmployeeCount: new FormControl(''),
      fullTimeEmployeeCount: new FormControl(''),
      itConsultantsCount: new FormControl(''),
      allowInternationalGrants: new FormControl(''),
      officeLocationCount: new FormControl(''),
      founded: new FormControl(''),
      staffSize: new FormControl(''),
      headquarters: new FormControl(''),
      endowment: new FormControl(''),
      itOperatingBudget: new FormControl(''),
      itcapexBudget: new FormControl(''),
      itopexBudget: new FormControl(''),
      itCybersecurityBudget: new FormControl('')
    });

    this.dataCollectionForm = new FormGroup({
      dataCollection: new FormControl('', Validators.required),
    });

    this.getProfile();
    this.resetAddForm();

  }

  getProfile() {
    this.managedProfileList.length = 0;
    this.removedProfileList.length = 0;
    this.profileService.getOrganizationProfile(this.foundationName)
      .subscribe(data => {
        this.foundationProfile = data[0];
        this.selectedProfile = this.foundationProfile;
        this.currentPage = 1;
        this.resetAddForm();
      });
  }

  resetAddForm() {
    this.selectedProfile = this.foundationProfile;
    this.isAddOpen = false;
    // this.TechnologiesImageURL = null;
    if(this.foundationProfile != null){
      this.profileForm.get('itStaffCount').setValue(this.foundationProfile.itStaffCount);
      this.profileForm.get('itBudget').setValue(this.foundationProfile.itBudget);
      this.profileForm.get('offshoreEmployeeCount').setValue(this.foundationProfile.offshoreEmployeeCount);
      this.profileForm.get('fullTimeEmployeeCount').setValue(this.foundationProfile.fullTimeEmployeeCount);
      this.profileForm.get('itConsultantsCount').setValue(this.foundationProfile.itConsultantsCount);
      if(this.foundationProfile.allowInternationalGrants==null)
        this.profileForm.get('allowInternationalGrants').setValue(null);
      else
        this.profileForm.get('allowInternationalGrants').setValue(
          this.foundationProfile.allowInternationalGrants ? "1":"0"
        );
      this.profileForm.get('officeLocationCount').setValue(this.foundationProfile.officeLocationCount);
      this.profileForm.get('founded').setValue(this.foundationProfile.founded);
      this.profileForm.get('staffSize').setValue(this.foundationProfile.staffSize);
      this.profileForm.get('headquarters').setValue(this.foundationProfile.headquarters);
      this.profileForm.get('endowment').setValue(this.foundationProfile.endowment);
      this.profileForm.get('itOperatingBudget').setValue(this.foundationProfile.itOperatingBudget);
      this.profileForm.get('itcapexBudget').setValue(this.foundationProfile.itcapexBudget);
      this.profileForm.get('itopexBudget').setValue(this.foundationProfile.itopexBudget);
      this.profileForm.get('itCybersecurityBudget').setValue(this.foundationProfile.itCybersecurityBudget);
    }
    else {
      this.profileForm.reset();
    }
  }

  openConfirmationModal(Technologies: organizationProfileModel) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      this.defaultConfirmationModalEntity(Technologies);
    }
  }

  defaultConfirmationModalEntity(Technologies: organizationProfileModel) {
    this.confirmationModalCompoent.entityData = {
      entityData: {
        deleteTechnologies: Technologies
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.closeModalWindow();
    }
  }

  openWarningModal() {
    if (this.warningModal) {
      this.warningModal.showModal();
    }
  }

  closeWarningModal() {
    if (this.warningModal) {
      this.warningModal.closeModalWindow();
    }
  }

  addOrganizationProfile() {
    this.isAddOpen = true;
    this.isNew = true;
  }

  cancelAddDC() {
    this.isAddDataCollectionOpen = false;
  }

  get enableSave(): boolean {
    if (this.foundationProfile != null && this.dataCollectionForm.get('dataCollection').value != '') {
      return true;
    } else if (this.managedProfileList.length > 0) {
      return true;
    }
    else if (this.removedProfileList.length > 0) {
      return true;
    }
    return false;
  }

  AddUpdateOrganizationProfile(): void {

    if (this.selectedProfile === null || this.selectedProfile === undefined) {
      this.selectedProfile = new organizationProfileModel().addOrganizationProfile(-(this.managedProfileList.length + 1),0, 0, 0, 0, 0, true, 0, 0, 0, " ", 0, " ",0,0,0,0)
     
    }
    this.selectedProfile.itStaffCount = this.profileForm.get('itStaffCount').value;
    this.selectedProfile.itBudget = this.profileForm.get('itBudget').value;
    this.selectedProfile.offshoreEmployeeCount = this.profileForm.get('offshoreEmployeeCount').value;
    this.selectedProfile.fullTimeEmployeeCount = this.profileForm.get('fullTimeEmployeeCount').value;
    this.selectedProfile.itConsultantsCount = this.profileForm.get('itConsultantsCount').value;
    this.selectedProfile.allowInternationalGrants = (this.profileForm.get('allowInternationalGrants').value =='' || this.profileForm.get('allowInternationalGrants').value =='0' || this.profileForm.get('allowInternationalGrants').value == null ) ? false:true;
    this.selectedProfile.officeLocationCount = this.profileForm.get('officeLocationCount').value;
    this.selectedProfile.founded = this.profileForm.get('founded').value;
    this.selectedProfile.staffSize = this.profileForm.get('staffSize').value;
    this.selectedProfile.headquarters = this.profileForm.get('headquarters').value;
    this.selectedProfile.endowment = this.profileForm.get('endowment').value;

    this.selectedProfile.itOperatingBudget = this.profileForm.get('itOperatingBudget').value;
    this.selectedProfile.itcapexBudget = this.profileForm.get('itcapexBudget').value;
    this.selectedProfile.itopexBudget = this.profileForm.get('itopexBudget').value;
    this.selectedProfile.itCybersecurityBudget = this.profileForm.get('itCybersecurityBudget').value;
    this.selectedProfile.foundationName = this.foundationName;
    this.selectedProfile.updatedDateTime = formatDate(Date.now().toString(), "yyyy-MM-dd HH:mm", "en-US");
   
    if(this.manageProfile==undefined)
    this.manageProfile=new Array<organizationProfileModel>();

      this.manageProfile.push(this.selectedProfile);
      this.managedProfileList.push(this.selectedProfile);

    let profile = new organizationProfileModel().addOrganizationProfile(this.selectedProfile.id, this.selectedProfile.itStaffCount,this.selectedProfile.itBudget,this.selectedProfile.offshoreEmployeeCount, this.selectedProfile.fullTimeEmployeeCount
      ,this.selectedProfile.itConsultantsCount,this.selectedProfile.allowInternationalGrants,this.selectedProfile.officeLocationCount,this.selectedProfile.founded, this.selectedProfile.staffSize
      , this.selectedProfile.headquarters, this.selectedProfile.endowment, this.selectedProfile.foundationName,this.selectedProfile.itOperatingBudget,this.selectedProfile.itcapexBudget,this.selectedProfile.itopexBudget,this.selectedProfile.itCybersecurityBudget );
    this.saveProfile(profile);
    this.resetAddForm();
  }

  onItemSelect(item: any) {
  }

  onItemDeSelect(item: any) {
  }

  saveProfile(Profiledata: any): void {
    if (this.managedProfileList.length > 0 || this.removedProfileList.length > 0) {
      this.managedProfileList.map(s => {
        s.updatedDateTime = new Date().toJSON();
        return s;
      });
      if (this.managedProfileList.length > 0) {
        this.profileService.saveOrganizationProfile(Profiledata)
          .subscribe((data) => {
            this.formSubmitSuccess = "Successfully saved Organization Profile";
            this.getProfile();
            this.managedProfileList = new Array<organizationProfileModel>();
          });
      }
      if (this.removedProfileList.length > 0) {
        this.profileService.removeOrganizationProfile(this.removedProfileList)
          .subscribe((data) => {
            this.formSubmitSuccess = "Successfully removed Organization Profile";
            this.getProfile();
          });
      }
    }
  }

  AddUpdateDataCollection(): void {
    this.isAddDataCollectionOpen = false;
  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }

  editProfile(Profile: organizationProfileModel): void {
    this.selectedProfile = Profile;
    console.log(this.selectedProfile);
    this.isAddOpen = true;
    this.isNew = false;

    this.profileForm.get('itStaffCount').setValue(this.selectedProfile.itStaffCount);
    this.profileForm.get('itBudget').setValue(this.selectedProfile.itBudget);
    this.profileForm.get('offshoreEmployeeCount').setValue(this.selectedProfile.offshoreEmployeeCount);
    this.profileForm.get('fullTimeEmployeeCount').setValue(this.selectedProfile.fullTimeEmployeeCount);
    this.profileForm.get('itConsultantsCount').setValue(this.selectedProfile.itConsultantsCount);
    this.profileForm.get('allowInternationalGrants').setValue(this.selectedProfile.allowInternationalGrants ? "1":"0");
    this.profileForm.get('officeLocationCount').setValue(this.selectedProfile.officeLocationCount);
    this.profileForm.get('founded').setValue(this.selectedProfile.founded);
    this.profileForm.get('staffSize').setValue(this.selectedProfile.staffSize);
    this.profileForm.get('headquarters').setValue(this.selectedProfile.headquarters);
    this.profileForm.get('endowment').setValue(this.selectedProfile.endowment);
    this.profileForm.get('itOperatingBudget').setValue(this.selectedProfile.itOperatingBudget);
    this.profileForm.get('itcapexBudget').setValue(this.selectedProfile.itcapexBudget);
    this.profileForm.get('itopexBudget').setValue(this.selectedProfile.itopexBudget);
    this.profileForm.get('itCybersecurityBudget').setValue(this.selectedProfile.itCybersecurityBudget);
    this.selectedItems = [];
  }
}
