<div class="container">
    <div class="page-title">
        <div>
            <span calss="page-title"><h1>Saved Favorites</h1></span>
        </div>
    </div>
        <div class="row">
                <p>View your saved searches below</p>        
        </div> 
    <div class="querySearchContainer">
        <div class="row header">
            <div class="col-sm-4 align-left">
                <i class="fas fa-search"></i>&nbsp;&nbsp;Search Query Title
            </div>
            <div class="col-sm-4 align-left">               
             
            </div>
            <div class="col-sm-4 align-left">               
            
            </div>    
        </div>
        <div class="row items">
            <ng-container *ngFor="let query of searchQuery; let rowIndex=index;">
                <div class="col-sm-12 userContainer">
                    <div class="col-sm-4">
                            &nbsp;&nbsp;&nbsp;&nbsp;{{query.searchTitle}}
                    </div>
                    <div class="col-sm-4" >
                                
                    </div>
                    <div class="col-sm-4 fav-buttons"> 
                        <button type="button" (click)="removeFavoritesQuery(query, rowIndex)" class="removeButton" [ngClass]="{ 'disable': viewAsAdmin | async }">Remove&nbsp;&nbsp;<em class="far fa-trash-alt"></em></button>
                        <button type="button" (click)="searchFavoriteQuery(query)" class="searchQueryButton">Search&nbsp;&nbsp;<em class="fas fa-search"></em></button>
                    </div>                       
                </div>                
            </ng-container>   
        </div>
        </div> 
        <div class="row addRowBtn">
        </div>
    </div>