import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'labeltransform'})
export class LabelTransformPipe implements PipeTransform {
  transform(value: any): string {
    let transformedLabel = '';
    transformedLabel = value.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
    return transformedLabel;
  }
}
