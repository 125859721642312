import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dropbox } from 'dropbox';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

declare const Box: any;

@Component({
  selector: 'app-dropbox-explorer',
  templateUrl: './dropbox-explorer.component.html',
  styleUrls: ['./dropbox-explorer.component.scss']
})
export class DropboxExplorerComponent implements OnInit {
  @Input() accessToken: string;
  @Output() authenticationFailed = new EventEmitter<boolean>();
  dbx: any;
  entries: Array<any>;
  public pathArray;
  public parentFolder;
  public query;
  public matches = 0;
  public gotMatch = false;
  currentPath = '';
  files;
  filename = {
    name: ''
  };
  private httpClient: HttpClient;

  constructor(private http: HttpClient, private sanitizer:DomSanitizer,  handler: HttpBackend) { 
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit() {
    this.entries = [];
    this.dbx = new Dropbox({ accessToken: this.accessToken });
    this.getFiles('');
    this.pathArray = this.getPathsToRenderFromUrl('');
    this.goBackFn();
  }
  getFiles(path: string) {
    this.dbx.filesListFolder({path: decodeURI(path), recursive: false, include_media_info: false, include_deleted: false, include_has_explicit_shared_members: false})
    .then((response) => {
      this.renderItems(response.entries);
    })
    .catch(function(error: DropboxTypes.Error<DropboxTypes.files.ListFolderError>) {
      console.error(error);
    });
  }

  // Render a list of items to #files
  renderItems(items: any) {
    this.entries = [];
    items.forEach((item) => {
      item.formatedSize = this.formatBytes(item.size);
      item.typeClass = this.getFileType(item.name);
      if (this.isImage(item.path_lower)) {
        this.dbx.filesGetThumbnail({ path: item.path_lower })
            .then((result) => {
                item.fileUrl = URL.createObjectURL((<any>result).fileBlob);
            })
            .catch((error) => {
                console.log(error);
            });
    }
      this.entries.push(item);
    });
  }
  sanitize(url:string){
    if(url != undefined) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
  }
  private formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  isImage(fileName: string) {
    if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
        const supportedImages = ['jpg', 'jpeg', 'png', 'tiff', 'tif', 'gif', 'bmp'];
        const fileEnding = fileName.split('.').pop();
        if (supportedImages.some(imgType => imgType === fileEnding)) {
            return true;
        }
    }
    return false;
  }

  getFileType(fileName: string) {
    if (fileName !== '' && fileName.length > 4) { // Fixed bug by K
        const fileEnding = fileName.split('.').pop();
        let fileType = ''; // Fixed bug by K
        switch (fileEnding) {
            case 'pdf':
                fileType = 'fa fa-file-pdf';
                break;
            case 'docx' || 'docx':
                fileType = 'fa fa-file-word';
                break;
            case 'pptx':
                fileType = 'fa fa-file-powerpoint';
                break;
            case 'xlsx':
                fileType = 'fa fa-file-excel';
                break;
            case 'html' || 'js':
                fileType = 'fa fa-file-code';
                break;
            default:
                fileType = 'fa fa-file';
        }
        return fileType;
    }
    return false;
  }
  moveToFolder(path: string) {
    if(path == '/') {
      path = '';
    }
    this.currentPath = path;
    this.getFiles(path);
    this.pathArray = this.getPathsToRenderFromUrl(path);
    this.goBackFn();
  }
  delete(event: any, path: string) {
    event.preventDefault();
    this.dbx.filesDeleteV2({ path: decodeURI(path) })
      .then(response => {
          this.getFiles(path.substring(0, path.lastIndexOf('/')));
          alert('Your delete was successful!');
      }, error => {
          console.log(error);
      });
  }

  download(event: any, filePath: string, fileName: string) {
    event.preventDefault();
    this.dbx.filesDownload({ path: filePath })
            .then(data => {
                const fileurl = URL.createObjectURL((<any>data).fileBlob);
                const a = document.createElement('a');
                a.setAttribute('href', fileurl);
                a.setAttribute('download', fileName);
                a.click();
            })
            .catch((error) => {
                console.log(error);
            });
  }

  getPathsToRenderFromUrl(currentPath) {
    let paths = currentPath.split('/');
    if (currentPath === '' || currentPath === '/') {
        paths = [''];
    }
    let fullpath = '';
    const pathsToRender = [];
    for (let i = 0; i < paths.length; i++) {
        const path = decodeURI(paths[i]);
        fullpath += `/${path}`;
        pathsToRender.push({
            path,
            fullpath,
        });
    }
    return pathsToRender;
  }

  goBackFn() {
    return this.parentFolder = this.pathArray.slice(0, -1);
  }

  decodeWithoutParams(url) {
    try {
        let cleanUrl = decodeURIComponent(url).split('?')[0] || '';
        if (cleanUrl === '/') {
            cleanUrl = '';
        }
        return cleanUrl;
    } catch (error) {
        console.log(error);
        return null;
    }
  }

  storeFiles(files) {
    const filepath = this.decodeWithoutParams(this.currentPath);
    this.files = files;
    this.upload(filepath, 'json') .subscribe((response) => {
      this.getFiles(filepath);
    });
  }

  upload(filepath: string, responseType: any): Observable<any> {
    const name = this.filename.name.split('\\').pop();
    const arg = {
      path: filepath + '/' + name,
      mode: 'add',
      autorename: true,
      mute: false
    };
    const httpObserve: any = 'response';
    const httpResponseType = responseType;
    let reqHeaders =  new HttpHeaders({
      'Authorization': 'Bearer ' + this.accessToken,
      'Dropbox-API-Arg': JSON.stringify(arg),
      'Content-Type': 'application/octet-stream'
    });
    const httpOptions  = {
      body : this.files.item(0),
      headers: reqHeaders,
      params: null,
      responseType: httpResponseType,
      observe : httpObserve
    };
    return new Observable((observer) => {
      this.httpClient.request('POST', environment.dropboxUploadAPI, httpOptions)
        .subscribe(
        (response) => {
          const resBody = response['body'] !== null ? response['body'] : {};
          observer.next(resBody);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  searchFiles(event) {
    const filepath = this.decodeWithoutParams(this.currentPath);
    this.search(filepath, 'json') .subscribe((response) => {
    setTimeout(() => {
      this.gotMatch = false;
    }, 3000);
    });
  }

  search(filepath: string, responseType: any): Observable<any> {
    const payload = {
      path: filepath,
      query: this.query,
      mode: 'filename_and_content'
    };
    const httpObserve: any = 'response';
    const httpResponseType = responseType;
    let reqHeaders =  new HttpHeaders({
      Authorization: 'Bearer ' + this.accessToken,
      'Content-Type': 'application/json'
    });
    const httpOptions  = {
      body : payload,
      headers: reqHeaders,
      params: null,
      responseType: httpResponseType,
      observe : httpObserve
    };
    return new Observable((observer) => {
      this.httpClient.request('POST', environment.dropboxSearchAPI, httpOptions)
        .subscribe(
        (response) => {
          const resBody = response['body'] !== null ? response['body'] : {};
          const numbers = resBody.matches.length;
          this.matches = numbers;
          this.gotMatch = true;
          this.renderSearchItems(resBody.matches);
          observer.next(resBody);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }
  

  // Render a list of items to #files
  renderSearchItems(items: any) {
    this.entries = [];
    items.forEach((item) => {
      item.formatedSize = this.formatBytes(item.metadata.size);
      item.name = item.metadata.name;
      item.path_lower = item.metadata.path_lower;
      item.client_modified = item.metadata.client_modified;
      item.typeClass = this.getFileType(item.metadata.name);
      if (this.isImage(item.metadata.path_lower)) {
        this.dbx.filesGetThumbnail({ path: item.path_lower })
            .then((result) => {
                item.fileUrl = URL.createObjectURL((<any>result).fileBlob);
            })
            .catch((error) => {
                console.log(error);
            });
    }
      this.entries.push(item);
    });
  }
}
