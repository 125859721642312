import { formatDate } from '@angular/common';

export class DocumentsMetaDataModel {
    documents: Array<Documents>;
    facets: Array<Facets>;
    totalCount: number;
  }

export class Documents {
  id: string;
  accessFor: number;
  community:string[];
  containerName:string;
  content: string;
  contentType:string[];
  continent:string[];
  country:string[];
  description:string;
  division:string[];
  externalUrl:string;
  fileName: string;
  fileTitle: string;
  fileType:string[];
  fileAttributes: FileAttributes;
  jurisdiction:string[];
  keywords:string[];
  locations:string[];
  merged_text:string;
  metadata_author:string;
  metadata_creation_date:Date;
  metadata_last_modified:Date;
  metadata_storage_path:string;
  programArea:string[];
  publicationDate:Date;
  publisher:string[];
  region:string[];
  state:string[];
  text:string;
  topic:string[];
  uploadedBy: string;
  uploadedDate: Date;
}

export class FileAttributes {
  CssClass: string;
  CssIcon: string;
  FileContentType: string;
  FileType: string;
}

export class Facets {
    contentType: Array<any>;
    fileType:  Array<any>;
    jurisdiction:  Array<any>;
    programArea:  Array<any>;
    state:  Array<any>;
    topic:  Array<any>;
  }
