import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Injectable, EventEmitter } from '@angular/core';
import { TokenStorage } from './token-storage';
import { RoleService } from '../roles/role.service';
import { SessionStorageService } from './session-storage/session-storage.service';
import { LocalStorageService } from './local-storage/local-storage.service';
import { Groups } from '../navigations/navigation-settings.model';
import { NavigationService } from '../navigations/navigation.service';
import { Roles } from '../roles/role-settings.model';
import { environment } from 'src/environments/environment';
import { OktaAuthStateService } from '@okta/okta-angular';
import { Observable } from 'rxjs';

@Injectable()
export class StateService {
    constructor(public authStateService: OktaAuthStateService,
                private tokenStorage: TokenStorage,
                private roleService: RoleService,
                private sessionStorage: SessionStorageService,
                private localStorage: LocalStorageService,
                private navigationService: NavigationService) {}

    islogged = new BehaviorSubject<boolean>(false);
    sessionInitiated: EventEmitter<boolean> = new EventEmitter();
    isReportOpened = new BehaviorSubject<boolean>(false);

    // Expose the observable$ part of the _todos subject (read only stream)
    isLoggedIn$ = this.islogged.asObservable();
    isReportOpened$ = this.isReportOpened.asObservable();

    get isLoggedIn(): boolean {
        return this.islogged.getValue();
    }
    get showheaderAndFooter():boolean{
        return this.isReportOpened.getValue();
    }
   
    set isLoggedIn(val: boolean) {
        this.islogged.next(val);
    }

    triggerSessionInitiatedEvent(isInitiated: boolean) {
        this.sessionInitiated.emit(isInitiated);
    }

    getSessionEvent() {
        return this.sessionInitiated;
    }

    get isTokenValid(): boolean {
            if(this.localStorage.getItem('okta-token-storage')!=null && this.isLoggedIn)
            {
                return true;
            }
            return false;
    }

    setToken(token: string) {
        this.tokenStorage.saveToken(token);
    }

    clearLocalSession() {
        this.sessionStorage.removeItem('user');
        this.sessionStorage.removeItem('viewuser');
        this.sessionStorage.removeItem('viewasAdmin');
        this.sessionStorage.removeItem('notauthorized');
    }

    removeToken() {
        this.clearLocalSession();
        window.sessionStorage.removeItem('id_token');
    }

    clearAllSessions(){
        window.sessionStorage.clear();
        window.localStorage.clear();
    }

    isTokenExists() {
        return window.sessionStorage.getItem('id_token')!=null;
    }

    getToken() {
        return `Bearer ${window.sessionStorage.getItem('id_token')}`;
    }

    getUserDetail() {
        if (this.isViewAsAdmin()) {
            return new Observable((observer) => {
                observer.next(this.getUserData());
                observer.complete();
            });
        } else {
            return this.roleService.getUserDetail();
        }
    }

    checkUserAccess(){
            this.roleService.checkUserAccess().subscribe(x=> {
               if(!x)
                   this.setUnAuthorized();
               else
                this.setUserActive();
            });
    }

    getRole() {
        return this.roleService.getRole();
    }

    getViewUserRole() {
        const userDetail = this.getViewingUserData();
        if (userDetail && userDetail.role) {
           if(userDetail['role'].name === 'Admin')
                return  Roles.Admin;
           else if(userDetail['role'].name === 'Moderator')
                return  Roles.Moderator;
            else if(userDetail['role'].name === 'SuperAdmin')
                return  Roles.SuperAdmin;
            else if(userDetail['role'].name === 'CaseyConsultant')
                return  Roles.CaseyConsultant;
            else if(userDetail['role'].name === 'State')
                return  Roles.State;
            else if(userDetail['role'].name === 'OtherLocalLeader')
                return  Roles.OtherLocalLeader;
            else if(userDetail['role'].name === 'DataLead')
                return  Roles.DataLead;
            else if(userDetail['role'].name === 'ProbationChief')
                return  Roles.ProbationChief;
            else
                return Roles.User;
        }
        return Roles.User;
    }

    getUserData() {
        return this.sessionStorage.getItem('user');
    }
    
    getOktaUserName()
    {
        return this.sessionStorage.getItem('oktaUserName');
    }

    getViewingUserData() {
        return this.sessionStorage.getItem('viewuser');
    }

    setViewingUserData(data) {
        this.sessionStorage.setItem('viewuser', data);
        this.sessionStorage.setItem('viewasAdmin', 'true');
    }

    setUnAuthorized() {
        this.sessionStorage.setItem('notauthorized', 'true');
    }

    setUserActive() {
        this.sessionStorage.setItem('userIsActive', 'true');
    }
    setUserInActive() {
        this.sessionStorage.setItem('userIsActive', 'false');
    }
    
    IsUserActive()  {
        if(this.sessionStorage.getItem('userIsActive') === null || this.sessionStorage.getItem('userIsActive') === 'true')
            return true;
        return false;
    }

    setAuthorized() {
        this.sessionStorage.setItem('notauthorized', 'false');
    }

    isUnAuthorized() {
        return this.sessionStorage.getItem('notauthorized') === 'true';
    }

    getViewingUserEmail() {
        return this.getViewingUserData() ? this.getViewingUserData()['userPrincipalName'] : '';
    }

    isViewAsAdmin() {
        return this.sessionStorage.getItem('viewasAdmin') === 'true';
    }

    resetToAdminUser() {
        this.sessionStorage.setItem('viewuser', this.getUserData());
        this.sessionStorage.setItem('viewasAdmin', 'false');
    }

    getUserfullName() {
        return this.sessionStorage.getItem('oktaUserName');
    }

    getGroupSession() {
        return this.localStorage.getItem('group');
    }

    setGroupSession() {
        const groupKeys = Object.keys(Groups);
        let group = groupKeys.find(x => Groups[x] === Groups.Kms);
        const subDomain = this.navigationService.getSubdomainFromUrl();
        if (subDomain) {
          const domain = groupKeys.find(x => Groups[x] === subDomain);
          if (domain) {
            group = domain;
          }
        }
        if(subDomain===Groups.aecfsearchdev || subDomain===Groups.aeckmsapp ) { // localhost ||   environment.azureADAuth
            group = (environment.server==='Development' || environment.server === 'Local') ? Groups.aecfsearchdev : Groups.aeckmsapp;
        }
        this.localStorage.setItem('group', group);
    }

    isKmsGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.Kms) {
         return true;
        }

        return false;
    }

    isAllGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.All) {
         return true;
        }

        return false;
    }

    isReportingGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.Reporting) {
         return true;
        }

        return false;
    }

    isJDAIGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.JDAI) {
         return true;
        }

        return false;
    }

    isJJGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.JJdata) {
         return true;
        }

        return false;
    }

    isGrantsGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.grants) {
         return true;
        }

        return false;
    }

    isThriveGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.Thrive) {
         return true;
        }

        return false;
    }
    isFTLAGroup(): boolean {
        const group = this.getGroupSession();
        if (group && Groups[group] === Groups.FTLA) {
         return true;
        }

        return false;
    }

    isJCYOIGroup(): boolean {
        const group = this.getGroupSession();
        if (group && (Groups[group] === Groups.Jcyoi || Groups[group] === Groups.JimCasey)) {
         return true;
        }

        return false;
    }

    isPortalConfigGroup(): boolean {
        const group = this.getGroupSession();
        if (group && (Groups[group] === Groups.aecfsearchdev || group === Groups.aeckmsapp || environment.azureADAuth)) {
         return true;
        }

        return false;
    }

    getOKTAClientId(): string {
        return localStorage.getItem('clientId');
    }

    getOKTAIssuer(): string {
        return localStorage.getItem('issuer');
    }

    permissionCheck(permission: string): boolean {
        const userdetails = this.sessionStorage.getItem('viewasAdmin') ==="true" ? this.getViewingUserData() : this.getUserData();
        if (userdetails === (undefined || null)) {
            return false;
        } else if (userdetails.permissions.indexOf(permission) >= 0) {
            return true;
        }
        return false;
    }

    setNewUserSession() {
        return this.sessionStorage.setItem('isNewUserAdded','true');
    }

    getNewUserSession() {
        return this.sessionStorage.getItem('isNewUserAdded');
    }

    removeNewUserSession() {
        return this.sessionStorage.removeItem('isNewUserAdded');
    }

     stringToEnumValue = <T extends Record<string, number>, K extends keyof T>(
        enumObj: T,
        value: string,
      ): T[keyof T] | undefined =>
        enumObj[
          Object.keys(enumObj).filter(
            (k) => enumObj[k as K].toString() === value,
          )[0] as keyof typeof enumObj
        ];
}
