import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { organizationProfileModel } from "../models/organizationprofile.model";

@Injectable()
export class organizationProfileService {

  constructor(private repository: RepositoryService) { }

  getOrganizationProfile(foundationName:string) : Observable<organizationProfileModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/ITOrganizationStats/GetOrganizationProfile/'+ foundationName );
  }

  saveOrganizationProfile(organizationProfile: organizationProfileModel){
    return this.repository.post(environment.apiBaseURL, 'api/ITOrganizationStats', organizationProfile);
  }
   
  removeOrganizationProfile(organizationProfile: organizationProfileModel[]){
    return this.repository.delete(environment.apiBaseURL, 'api/ITOrganizationStats', organizationProfile);
  }
}