import { Component, OnInit, Renderer2, Input, TemplateRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AuditLogging, AuditLoggingList } from '../shared/audit-logging.model';
import { AuditLoggingService } from '../shared/audit-logging.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AuditLoggingFilter, EventAction, EventType } from '../model/audit-logging-filter.model';
import * as FileSaver from 'file-saver';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { SystemPermissions } from './../../permissions';
import { StateService } from 'src/app/core/state-manager/appstateservice';

@Component({
  selector: 'app-audit-logging-component',
  templateUrl: './audit-logging.component.html',
  styleUrls: ['./audit-logging.component.scss'],
  providers: []
})
export class AuditLoggingComponent implements OnInit {
  @ViewChild('viewDetailModal', {static: false}) viewDetailModalComponent: ModalComponent;
  auditLoggings: Array<AuditLogging>;
  displayItems: Array<AuditLogging>;
  auditLogging: AuditLogging;
  viewNewsFeed = -1;
  selectedId = 0;
  selectedRowIndex = -1;
  bsStartValue: Date = null;
  bsEndValue: Date = null;
  changes = [];
  fromDate: string;
  toDate: string;
  currentPage = 0;
  perPage = 10;
  totalRows = 0;
  searchUserResult: Observable<any>;
  typedUser: string;
  auditFilterForm: FormGroup;
  searchUserSubmitted = false;
  EventType = EventType;
  EventAction = EventAction;
  eventActionSelected = null;
  eventTypeSelected = null;
  isPrintable = false;
  isExporting = false;
  isLoading = false;
  permissions = SystemPermissions;
  constructor( private auditLoggingService: AuditLoggingService,
               private formBuilder: FormBuilder,
               public datepipe: DatePipe,
               private commonService: CommonEntityService,
               public googleAnalyticsService: GoogleAnalyticsService,
               private currentState: StateService) {
    }

  ngOnInit() {
    this.initFilterForm();
    this.getAuditFilter();
    this.searchUserResult = new Observable<any>((observer: any) => {
      // Runs on every search
      observer.next(this.typedUser);
    }).pipe(mergeMap((searchTerm: string) => searchTerm.length > 0 ? this.commonService.getSearchedUsers(searchTerm) : []));
  }

  ischeckPermissions(permission: string): boolean { return this.currentState.permissionCheck(permission); }

  isValidUser() {
    const userControl = this.auditFilterForm.controls['user'];
    if (userControl.value && !userControl.errors) {
      return true;
    }

    return !this.searchUserSubmitted;
  }
  // Selected value event
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.auditFilterForm.controls.user.setValue(e.item.userPrincipalName);
    this.typedUser = e.item.fullName;
  }

  initFilterForm() {
    this.auditFilterForm = this.formBuilder.group({
      user:  ['']
    });
  }

  clearFilter() {
    this.initFilterForm();
    this.eventActionSelected = null;
    this.eventTypeSelected = null;
    this.fromDate = null;
    this.toDate = null;
    this.bsStartValue = null;
    this.bsEndValue = null;
    this.currentPage = 0;
    this.typedUser = '';
    this.googleAnalyticsService.eventEmitter('Clear Search Results Filter', 'ClearFilter', 'Audit Log');
    this.getAuditFilter();
    this.isPrintable = false;
  }

  getAuditLoggings() {
    this.auditLoggingService.getAudits(this.currentPage, this.perPage)
    .subscribe(data => {
      this.totalRows = data.totalRows;
      this.auditLoggings = new AuditLoggingList().uiMapper(data.logs).auditLoggings;
    });
  }
  hasDateRangeError(): boolean {
    if (isDateValid(this.bsStartValue) || isDateValid(this.bsEndValue)) {
      return !this.isValidDateRage();
    }
    return false;
  }

  hasInvalidDate(date: Date): boolean {
    return date != null ? isNaN(date.valueOf()) : false;
  }

  isValidDateRage(): boolean {
    if (isDateValid(this.bsStartValue) && isDateValid(this.bsEndValue) &&
        new Date(this.bsEndValue.getFullYear(), this.bsEndValue.getMonth(), this.bsEndValue.getDate()) >=
            new Date(this.bsStartValue.getFullYear(), this.bsStartValue.getMonth(), this.bsStartValue.getDate())) {
      return true;
    }
    return false;
  }

  getAuditFilter() {
    this.fromDate = this.datepipe.transform(this.bsStartValue, 'yyyy-MM-dd');
    this.toDate = this.datepipe.transform(this.bsEndValue, 'yyyy-MM-dd');
    this.auditLoggingService.getAudits(this.currentPage, this.perPage, this.auditFilterForm.controls['user'].value,
                                      this.eventActionSelected,
                                      this.eventTypeSelected,
                                      this.fromDate,
                                      this.toDate)
    .subscribe(data => {
      if (this.currentPage === 0 && this.ischeckPermissions(this.permissions.PrintAuditLogs)) {
        this.auditLoggingService.getAudits(this.currentPage,
                                  this.perPage,
                                  this.auditFilterForm.controls['user'].value,
                                  this.eventActionSelected,
                                  this.eventTypeSelected,
                                  this.fromDate, this.toDate, true)
        .subscribe(data => {
          this.isPrintable = true;
          this.displayItems = new AuditLoggingList().uiMapper(data.logs).auditLoggings;
        });
      }
      this.totalRows = data.totalRows;
      this.auditLoggings = new AuditLoggingList().uiMapper(data.logs).auditLoggings;
      this.isLoading = false;
    });

    let eventActionString = ((this.eventTypeSelected) ? 'Event Type: ' + this.eventTypeSelected : '') +
    ((this.eventActionSelected) ? '/Event Action: ' + this.eventActionSelected : '') +
    ((this.auditFilterForm.controls['user'].value) ? '/User Email: ' + this.auditFilterForm.controls['user'].value : '') +
    ((this.fromDate) ? '/From Date: ' + this.fromDate : '') +
    ((this.toDate) ? '/To Date: ' + this.toDate : '');

    if (eventActionString.indexOf('/') === 0) {
      eventActionString = eventActionString.substring(1);
    }

    this.googleAnalyticsService.eventEmitter('Apply Search Results Filter', eventActionString, 'Audit Log');
 }

  pageChanged(event: PageChangedEvent): void {
    this.isLoading = true;
    this.googleAnalyticsService.eventEmitter('Page Navigation Search Results', String(this.currentPage) + ' to ' + event.page, 'Audit Log');
    this.currentPage = event.page;
    this.getAuditFilter();
  }

  public getauditLogging(index: number) {
    this.changes = [];
    this.auditLogging = this.auditLoggings[index];
    const oldValue = JSON.parse(this.auditLogging.oldValue);
    const newValue = JSON.parse(this.auditLogging.newValue);
    let keys: any;
    if (oldValue === null) {
      keys = newValue;
    } else {
      keys = oldValue;
    }
    for (const prop in keys) {
      if (prop !== 'Id' && prop !== 'SetId' && prop !== 'UserId' && prop !== 'ModeratorId' && prop != 'UserId' && prop != 'UserId') {
        const change = [];
        change['key'] = prop;
        if (this.auditLogging.action !== 'Inserted') {
          if (oldValue === null || oldValue[prop] === null || oldValue[prop] === undefined) {
            change['oldValue'] = null;
          } else {
            change['oldValue'] = oldValue[prop];
          }
         }
        if (this.auditLogging.action !== 'Deleted') {
          if (newValue === null || newValue[prop] === null || newValue[prop] === undefined) {
            change['newValue'] = null;
          } else {
            change['newValue'] = newValue[prop];
          }
        }
        change['isUpdated'] = false;
        if (this.auditLogging.action !== 'Inserted' && this.auditLogging.action != 'Deleted') {
          if (JSON.stringify(change['oldValue']) !== JSON.stringify(change['newValue'])) {
            change['isUpdated'] = true;
          }
        }
        this.changes.push(change);
      }
    }
  }

openModal(rowIndex: number) {
    this.getauditLogging(rowIndex);
    this.selectedRowIndex = rowIndex;
    if (this.viewDetailModalComponent) {
        this.viewDetailModalComponent.entityData = {
          entityData: null
        };
        this.viewDetailModalComponent.showModal();
    }
    this.googleAnalyticsService.eventEmitter('View Details', 'ViewDetails', 'Audit Log');
  }

closeModal() {
    if (this.viewDetailModalComponent) {
      this.selectedRowIndex = -1;
      this.selectedId = -1;
      this.viewDetailModalComponent.closeModalWindow();
      this.googleAnalyticsService.eventEmitter('Close Details', 'Close', 'Audit Log');
    }
  }
print() {
    this.googleAnalyticsService.eventEmitter('Print Search Results', 'Print', 'Audit Log');
    window.print();
  }

exportAuditLoggings() {
    this.isExporting = true;
    this.fromDate = this.datepipe.transform(this.bsStartValue, 'yyyy-MM-dd');
    this.toDate = this.datepipe.transform(this.bsEndValue, 'yyyy-MM-dd');
    this.auditLoggingService.exportAuditLoggings(this.currentPage,
                                                this.perPage,
                                                this.auditFilterForm.controls['user'].value,
                                                this.eventActionSelected,
                                                this.eventTypeSelected,
                                                this.fromDate,
                                                this.toDate)
    .subscribe(data => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      FileSaver.saveAs(data, 'AuditLogs.xlsx');
      this.isExporting = false;
    });
    this.googleAnalyticsService.eventEmitter('Export Search Results', 'ExportDetails', 'Audit Log');
  }
}
