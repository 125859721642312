<div class="container">
    <div class="page-title">
        <div>
            <span calss="page-title"><h1>Shared Documents</h1></span>
        </div>
    </div>
        <div class="row">
                <p>View documents you have shared with others below.</p>        
        </div> 
    <div class="shared-doument-container">
        <div class="row header">
            <div class="col-sm-4 align-left">
                <em class="far fa-file-alt"></em>
                <span>Document Name</span>
            </div>
            <div class="col-sm-4 align-left">               
                <em class="fas fa-user"></em>
                <span>Shared With</span>
            </div>
            <div class="col-sm-4 align-left">               
                <em class="far fa-calendar-alt"></em>
                <span>Date Shared</span>
            </div>    
        </div>
        <div class="row items">
            <ng-container *ngFor="let document of sharedDocuments; let rowIndex=index;">
                <div class="col-sm-12 doc-container">
                    <div class="col-sm-4">
                        <a class="dropdown-item nav-link-item" (click)="openDocument(document)">
                            {{document.documentName}}
                        </a>                        
                    </div>
                    <div class="col-sm-4" >
                        {{document.sharedWith}}       
                    </div>
                    <div class="col-sm-4"> 
                        {{ document.dateShared | timezone:'MM/DD/YYYY'}}
                    </div>                       
                </div>                
            </ng-container>   
        </div>
    </div> 
</div>