import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WizardStepComponent, MovingDirection } from 'angular-archwizard';

import { ContentViewComponent } from '../content-view/content-view.component';
import { BlobMetadataComponent } from '../blob-metadata/blob-metadata.component';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { FileUploaderService } from '../shared/file-uploader.service';
import { FileType, organizeFileTypeTags } from '../shared/blob-upload.model';
import { StateService } from 'src/app/core/state-manager/appstateservice';


@Component({
  selector: 'app-content-upload-container',
  templateUrl: './content-upload-container.component.html',
  styleUrls: ['./content-upload-container.component.scss']
})
export class ContentUploadContainerComponent implements OnInit {
  @ViewChild('chooseTags', {static: false}) chooseTags: WizardStepComponent;
  @ViewChild('selectSource', {static: false}) selectSource: WizardStepComponent;
  @ViewChild('completion', {static: false}) completion: WizardStepComponent;
  @ViewChild('blobMetadataView', {static: false}) blobMetadataView: BlobMetadataComponent;
  @ViewChild('contentView', {static: false}) contentView: ContentViewComponent;
  contentUploadForm: FormGroup;
  contentAdded = false;
  completionStep = false;
  contentSubmitted = false;
  navigateToTagsSubmitted = false;
  uploadCompleted = false;
  backToContentView = false;
  viewAsAdmin = this.commonService.getViewUserEventEmitter();
  tabSelected = null;
  blobFileTypes: Array<FileType>;
  

  constructor(private uploadService: FileUploaderService, private formBuilder: FormBuilder, private commonService: CommonEntityService,private stateService: StateService,) { }

  get contentData(): any {
    return this.contentUploadForm.value;
  }

  ngOnInit() {
    this.initForm();
    this.uploadService.getFiletypesMetadata().subscribe((fileTypes: FileType[])  => {
      this.blobFileTypes = organizeFileTypeTags(fileTypes);
    });
  }

  isContentUpdated(event) {
    this.contentAdded = event;
  }

  selectTab(event) {
    this.tabSelected = event;
  }

  isSelectSourceValid(event) {
    this.contentAdded = event;
  }

  progressStep(event) {
    if (MovingDirection.Forwards === event) {
      this.completionStep = true;
    }
  }

  initForm() {
    this.contentUploadForm = this.formBuilder.group({
      formFilesField: this.formBuilder.array([]),
      programArea: this.formBuilder.array([], Validators.required),
      topic: this.formBuilder.array([], Validators.required),
      //community: this.formBuilder.array([], Validators.required),
      country: this.formBuilder.array([]),
      continent: this.formBuilder.array([]),
      region: this.formBuilder.array([]),
      division: this.formBuilder.array([]),
      state: this.formBuilder.array([]),
      focus: this.formBuilder.array([]),
      jurisdiction: this.formBuilder.array([]),
      publisher: this.formBuilder.array([]),
      contentType: this.formBuilder.array([], Validators.required),
      privacyType: this.formBuilder.array([]),
      authors: this.formBuilder.array([]),
      //accessFor: ['', Validators.required],
      publicationDate: [''],
      description: [''],
      linkField: this.formBuilder.array([]),
      grantNumber: [''],
      moderator: ['', Validators.required],
      datasetType: [''],
      isDataset: [false]
    });
  }

  stepBack() {
    this.chooseTags.canExit = true;
  }

  navigateToContentView() {
    this.contentSubmitted = true;
    this.chooseTags.canExit = this.contentUploadForm.valid && !this.blobMetadataView.hasLocationFieldError();
  }

  navigateToTags() {
    this.navigateToTagsSubmitted = true;
    this.selectSource.canExit = this.contentUploadForm.controls.moderator.valid && this.contentUploadForm.controls.datasetType.valid;
    if (this.selectSource.canExit) {
      this.blobMetadataView.addFileTypeTagItems();
    }
  }

  reviewStep(event) {
    this.completion.canEnter = true;
  }

  goBackToReviewStep(upload) {
    this.backToContentView = upload;
    this.completion.canExit = upload;
  }

  isUploadCompleted(upload) {
    console.log(this.contentUploadForm);
    this.uploadCompleted = upload;
  }

  exitCompletion(event) {
    if (MovingDirection.Stay !== event) {
      this.completionStep = false;
      this.backToContentView = false;
      this.completion.canEnter = false;
    }
  }

  closeWizard() {
    var doc = document.getElementsByClassName("file-item-span")[0].innerHTML;
    var user = this.stateService.getUserData();
    var uploadedBy = user.fullName;
    var org = user.organization;
    this.uploadService.resourceUploadNewsFeed(doc,uploadedBy,org).subscribe(s => {
    });
    this.contentView.closeWizard();
  }
}
