<div class="container organization-profile" style="width:90%">

    <div class="heading d-flex">
            <div class="page-title">
                <div>
                    <span calss="page-title"><h1>Organization Profile - {{foundationName}}</h1></span>
                </div>
            </div>
        <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
            <div class="col-md-12">
                <div class="alert alert-danger" id="formSubmitError">
                    {{ formSubmitError }}
                </div>
            </div>
        </div>
    </div>

    <br>

<div class="row">

  <div class="form-group col-12 table-responsive">
    <form [formGroup]="profileForm">
      <table class="table">
        <tbody>
          <tr>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtHeadQuarter" class="required-field">Headquarters</label>
                <span class="orgprofiletooltiptext">Foundation Headquarters Location</span>
              </div>
              <div>
                  <input id="txtHeadQuarter" type="text"  placeholder="Headquarters"  autocomplete="disabled" formControlName="headquarters" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtFounded" class="required-field">Founded</label>
                <span class="orgprofiletooltiptext">Foundation Year Founded</span>
              </div>
              <div>
                  <input id="txtFounded" type="number"  placeholder="Founded"  autocomplete="disabled" formControlName="founded" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtStaffSize">Staff Size</label>
                <span class="orgprofiletooltiptext">Foundation Staff Count</span>
              </div>
              <div>
                  <input id="txtStaffSize" type="number"  placeholder="Staff Size"  autocomplete="disabled" formControlName="staffSize" class="form-control" />
              </div>
            </td>
          </tr>
          <tr>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtendowment">Endowment</label>
                <span class="orgprofiletooltiptext">Foundation Endowment in dollars</span>
              </div>
              <div>
                  <input id="txtendowment" type="number"  placeholder="Endowment"  autocomplete="disabled" formControlName="endowment" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtOfficeLocationCount">Office Locations</label>
                <span class="orgprofiletooltiptext">Number of Office Locations</span>
              </div>
              <div>
                  <input id="txtOfficeLocationCount" type="number"  placeholder="Office Location"  autocomplete="disabled" formControlName="officeLocationCount" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txt-role">Allow International Grants</label>
                <span class="orgprofiletooltiptext">Allows International Grants</span>
              </div>
              <div>
                <label>
                  <input type="radio" value="1" formControlName="allowInternationalGrants">
                  <span>Yes</span>
                </label>
                <label>
                  <input type="radio" value="0" formControlName="allowInternationalGrants">
                  <span>No</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtItBudget">IT Budget</label>
                <span class="orgprofiletooltiptext">Operating Budget excluding Salaries</span>
              </div>
              <div>
                  <input id="txtItBudget" type="number"  placeholder="IT Budget"  autocomplete="disabled" formControlName="itBudget" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtItStaffCount">IT Staff</label>
                <span class="orgprofiletooltiptext">Technology Total Heacount</span>
              </div>
              <div>
                  <input id="txtItStaffCount" type="number"  placeholder="IT Staff"  autocomplete="disabled" formControlName="itStaffCount" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtFullTimeEmployeeCount">IT Full-time</label>
                <span class="orgprofiletooltiptext">Number of Full-time (non-contract) Employees</span>
              </div>
              <div>
                <input id="txtFullTimeEmployeeCount" type="number"  placeholder="IT Full-time"  autocomplete="disabled" formControlName="fullTimeEmployeeCount" class="form-control" />
              </div>
            </td>
          </tr>
          <tr>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtItConsultantsCount">IT Contractors</label>
                <span class="orgprofiletooltiptext">Number of Contractor Employees</span>
              </div>
              <div>
                  <input id="txtItConsultantsCount" type="number"  placeholder="IT Contractors"  autocomplete="disabled" formControlName="itConsultantsCount" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtOffshoreEmployeeCount">IT Offshore</label>
                <span class="orgprofiletooltiptext">Number of Offshore Employees</span>
              </div>
              <div>
                <input id="txtOffshoreEmployeeCount" type="number"  placeholder="IT Offshore"  autocomplete="disabled" formControlName="offshoreEmployeeCount" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtItOperatingBudget">Operating Budget</label>
                <span class="orgprofiletooltiptext">Operating Budget</span>
              </div>
              <div>
                <input id="txtItOperatingBudget" type="number"  placeholder="IT Operating Budget"  autocomplete="disabled" formControlName="itOperatingBudget" class="form-control" />
              </div>
            </td>
          </tr>
          <tr>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtItCAPEXBudget">CAPEX Budget</label>
                <span class="orgprofiletooltiptext">CAPEX Budget</span>
              </div>
              <div>
                <input id="txtItCAPEXBudget" type="number"  placeholder="IT Capex Budget"  autocomplete="disabled" formControlName="itcapexBudget" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtItOPEXBudget">OPEX Budget</label>
                <span class="orgprofiletooltiptext">OPEX Budget</span>
              </div>
              <div>
                <input id="txtItOPEXBudget" type="number"  placeholder="IT Opex Budget"  autocomplete="disabled" formControlName="itopexBudget" class="form-control" />
              </div>
            </td>
            <td class="col-xs-4">
              <div class="orgprofiletooltip">
                <label for="txtItCybersecurityBudget">Cybersecurity Budget</label>
                <span class="orgprofiletooltiptext">Cybersecurity Budget</span>
              </div>
              <div>
                <input id="txtItCybersecurityBudget" type="number"  placeholder="IT Cybersecurity Budget"  autocomplete="disabled" formControlName="itCybersecurityBudget" class="form-control" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

<div class="row">
    <div class="col-md-2 pull-right padding-2p">
        <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateOrganizationProfile()" [ngClass]="{ 'disable-color': viewAsAdmin }" >Save</button>
    </div>
    <div class="col-md-2 pull-right padding-2p">
        <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" (click)="cancelAdd()">Reset</button>
    </div>
  </div>

  <div class="clearfix">
    <div class="pull-right">
        <div *ngIf="formSubmitSuccess">
            <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
        </div>
    </div>
</div>

<div class="add-form" *ngIf="isAddDataCollectionOpen">
    <a class="close" (click)="cancelAddDC()"><i class="fa fa-times"></i></a>
    <div class="form-group">
      <form [formGroup]="dataCollectionForm">
        <div>Data Collection </div>
        <div>
          <textarea id="txtdataCollection" type="text" name="txtdataCollection" placeholder="Data Collection" autocomplete="new-txtfname"
                    [ngClass]="{'input-error': dataCollectionForm.controls.dataCollection.touched && dataCollectionForm.controls.dataCollection.status=='INVALID'}"
                    formControlName="dataCollection" class="form-control" required></textarea>
        </div>
      </form>
    </div>
    <div class="col-md-4 px-5">
      <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateDataCollection()" [disabled]="dataCollectionForm.controls.dataCollection.status=='INVALID'">Save</button>
    </div>
  </div>


</div>
<div class="backdrop" *ngIf="isAddDataCollectionOpen"></div>