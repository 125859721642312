<div class="pending-approve">
    <div class="approveHeader">
        <div class="fileApproveHeading col-md-12">
            <label>Approve/Reject Content</label>        
        </div>
        <div class="col-md-12">
            <label class="fileApproveSubHeading">Review content to approve, reject, or modify files below</label>        
        </div>
        <div class="moderator-display-type">
            <span class="caption">Show Sets</span>
            <select class="form-control select" id="selectModeratorType" [(ngModel)]="moderatorType" (change)="showPendingApproval()">
                <option value="assignedToMe">Assigned to Me</option>
                <option value="notAssignedToMe">Not Assigned to Me</option>
            </select>
        </div>
    </div>
    <div>
        <accordion [closeOthers]="true">
            <ng-template ngFor let-setItem let-setIndex="index" [ngForOf]="userSets">    
                <accordion-group #grp>
                    <div accordion-heading class="tab" (click)="modifySet(setItem, false, grp.isOpen)">
                        <div class="tab-label">
                            <div class="tab-label-text">                               
                                <div class="col-sm-8">
                                    <span class="fullName"><strong>User:</strong> <span >{{setItem.userDisplayName}}</span></span>
                                    <span class="uploadeddate"><strong>Date Uploaded:</strong><span> {{ setItem.uploadedDate | timezone:'MMM DD, YYYY hh:mm A' }}</span></span>
                                    <span class="grantNumber"><strong>Grant #:</strong><span> {{ setItem.grantNumber }}</span></span>                                    
                                </div>
                                <div class="btn-group col-sm-4" *ngIf="moderatorType === 'assignedToMe'">
                                    <button type="button" class="btn btn-secondary button-style" (click)="modifySet(setItem, true)" [ngClass]="{ 'disable-color': viewAsAdmin | async }">Modify All</button>
                                    <button type="button" class="btn btn-secondary button-style" (click)="approveSet(setItem.uniqueKey)" [ngClass]="{ 'disable-color': viewAsAdmin | async }">Approve All</button>                                  
                                    <button type="button" class="btn btn-secondary button-style reject-button" (click)="rejectSet(setItem.uniqueKey)" [ngClass]="{ 'disable-color': viewAsAdmin | async }">Reject All</button>
                                </div>
                                <div class="btn-group col-sm-4 moderator" *ngIf="moderatorType === 'notAssignedToMe'">
                                    <span *ngIf="moderatorType === 'notAssignedToMe'"><strong>Moderator:</strong><span> {{ setItem.moderatorName }}</span></span>
                                    <button type="button" class="btn btn-secondary button-style" [ngClass]="{ 'disable-color': viewAsAdmin | async }" (click)="assignToMe(setItem.uniqueKey)">Assign to Me</button>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div class="tab-content" *ngIf="setItem.usersSet || validationErrors.length > 0">                        
                        <div *ngIf="setItem.usersSet">
                            <div class="header row">
                                <div class="col-sm-3">
                                    File Name
                                </div>
                                <div class="col-sm-3">
                                    Display Title
                                </div>
                                <div class="col-sm-2">
                                    File Type
                                </div>
                                <div class="col-sm-4">
                                </div>
                            </div>
                            <div *ngFor="let fileItem of setItem.fileSet; let rowIndex=index;" class="row">
                                <div class="col-sm-3">
                                    {{fileItem.FileName}}
                                </div>
                                <div class="col-sm-3">
                                    {{fileItem.KCTitle}}
                                </div>
                                <div class="col-sm-2">
                                    <ng-container *ngFor="let item of fileItem.FileType | splitArray:','"><label class="Rectangle" *ngIf="item !== NotSpecified">{{ item }}</label></ng-container>
                                </div>
                                <div class="col-sm-4 align-right">
                                    <button type="button" id="btn-modify" *ngIf="moderatorType === 'assignedToMe'" class="btn btn-secondary button-style" [ngClass]="{ 'disable-color': viewAsAdmin | async }" (click)="approve(fileItem.FileName)">Approve</button>
                                    <button type="button" *ngIf="moderatorType === 'assignedToMe'" class="btn btn-secondary button-style reject-button" [ngClass]="{ 'disable-color': viewAsAdmin | async }" (click)="reject(fileItem.FileName)">Reject</button>
                                </div>
                            </div>                        
                            <div *ngFor="let linkItem of setItem.linkSet; let rowIndex=index;" class="row">
                                    <div class="col-sm-3">
                                        {{(linkItem.ExternalUrl != undefined)?linkItem.ExternalUrl:linkItem.FileName}}
                                    </div>
                                    <div class="col-sm-3">
                                        {{linkItem.KCTitle}}
                                    </div>
                                    <div class="col-sm-3">
                                        <ng-container *ngFor="let item of linkItem.FileType | splitArray:','"><label class="Rectangle" *ngIf="item !== NotSpecified">{{ item }}</label></ng-container>
                                    </div>
                                    <div class="col-sm-3">
                                        <button id="btn-modify" *ngIf="moderatorType === 'assignedToMe'" type="button" class="btn btn-secondary button-style" (click)="approve(linkItem.FileName)">Approve</button>
                                        <button *ngIf="moderatorType === 'assignedToMe'" type="button" class="btn btn-secondary button-style" (click)="reject(linkItem.FileName)">Reject</button>
                                    </div>
                            </div>
                            <div class="line"></div>
                        </div>
                        <!-- <div *ngIf="setItem.usersSet && setItem.usersSet.AccessFor">
                            <div class="header-tag row">
                                <div class="col-sm-12">
                                    Set
                                </div>
                            </div>
                            <div class="row">
                                <div class="label-tag col-sm-12">
                                    <label class="Rectangle">{{setItem.usersSet.AccessFor}}</label>
                                    <ng-container *ngFor="let metaDataItem of tags; let i = index">
                                        <ng-container *ngFor="let item of setItem.usersSet[metaDataItem] | splitArray:','"><label class="Rectangle" *ngIf="item !== NotSpecified">{{ item }}</label></ng-container>
                                    </ng-container>  
                                </div>    
                            </div>
                        </div> -->
                        <div *ngIf="validationErrors.length > 0">
                            <div class="header-tag row">
                                <div class="col-sm-12">
                                    Validation Errors
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                            <th>S. No</th>
                                            <th>File</th>
                                            <th>Error</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let error of validationErrors; let i = index">
                                                <td>{{i + 1}}</td>
                                                <td>{{error.fileName}}</td>
                                                <td>{{error.error}}</td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                </div>    
                            </div> 
                        </div>
                        <div *ngIf="setItem.usersSet && setItem.usersSet.Description !== 'Not Specified'">
                            <div class="line">
                            </div>
                            <div class="row">
                                <div class="col-sm-12 ">
                                    {{setItem.usersSet.Description}}
                                </div>                                                        
                            </div>
                        </div>
                    </div>
                </accordion-group>                                  
            </ng-template> 
        </accordion>          
    </div>
</div>
<app-modal #metatagsModal contentStyle="metatags-modal" [showFooter]=false (closeModal)="closeUserDisplayModalEvent($event)">
    <ng-template #modalContentTemplate let-metaDataForm="dataItem.entityData.metaDataForm" let-setId="dataItem.entityData.setId">
        <form [formGroup]="metaDataForm" (ngSubmit)="onSubmit(setId)" #metaTagsDataForm="ngForm">
            <app-blob-metadata #blobMetadataView [contentForm]="metaDataForm" [contentSubmitted]=contentSubmitted [tabSelected]=tabSelected [blobFileTypes]=blobFileTypes></app-blob-metadata>
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" style="width: 100px;" (click)="closeConfirmModal()">
                        <span>Cancel</span>
                    </button>
                </div>
                <div class="savebtn">
                    <button type="submit" class="btn button-style" style="width: 100px;"  [ngClass]="{ 'disable-color': viewAsAdmin | async }">
                        <span>Submit</span>
                    </button>
                </div>
            </div>
        </form>
    </ng-template>
</app-modal>