import { Injectable } from '@angular/core';

import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { UsersList, UserDetail, ReportDetail } from './user.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { FoundationProfiles } from './profile.model';

@Injectable()
export class AccountService {

  constructor(private repository: RepositoryService) { }

  getAllUsers(): Observable<UsersList> {
    return this.repository.get(environment.apiBaseURL, 'api/User/all');
  }
  getOtherPortalUsers(searchTerm:string):Observable<UsersList>{
    return this.repository.get(environment.apiBaseURL,'api/User/otherPortalUsers/'+searchTerm)
  }
  getCommunitySpecificUsers():Observable<UsersList>{
    return this.repository.get(environment.apiBaseURL, 'api/User/communityspecific');
  }
  getFTLAProfileUsers():Observable<FoundationProfiles>{
    return this.repository.get(environment.apiBaseURL, 'api/User/ftlaProfiles');
  }
  
  getOtherUsers(userQuery: string): Observable<UsersList> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/department/OtherBUUsers/' + userQuery);
  }

  getUsersWithAccess(): Observable<UsersList> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/department/AssingedReportsToUser');
  }

  getModerators(userId): Observable<UserDetail[]> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/userspecific/' + userId);
  }

  getReports(): Observable<ReportDetail[]> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/Reports');
  }

  assignTasksToModerator(userId: string, assignToManagerId: string): void {
    this.repository.get(environment.apiBaseURL, 'api/Moderator/taskassignment?fromId=' + userId + '&toId=' + assignToManagerId).subscribe();
  }

  getPendingTask(userId: string): Observable<boolean> {
    return this.repository.get(environment.apiBaseURL, 'api/User/pendingtask?UserId=' + userId);
  }

  getUsersExport(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/User/export','blob');
  }

  RemoveUserRoles(userDetail: UserDetail, role: Roles) {
    const model = UserDetail.addRole(userDetail, role);
    return this.saveUserRoles([model]);
  }

  UpdateUserRoles(userDetails: any) {
    return this.saveUserRoles(userDetails);
  }

  ManageUsers(userDetails: UserDetail[]){
    return this.repository.post(environment.apiBaseURL, 'api/User/manage', userDetails);
  }

  SaveUser(userDetails: UserDetail[]) {
    const model = new UsersList().userReportDataMapper(userDetails);
    return this.saveReportAccess(model);
  }

  saveUserRoles(userDetail: any): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/User/mapping', userDetail);
  }

  saveReportAccess(userDetail: any): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/Moderator/department/SaveReportAccess', userDetail);
  }

  getAllPermissions(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Admin/permissions');
  }

  getAllRolesPermissions(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/rolesandpermissions');
  }

  savePermissions(changedPermissions): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/ResourcesConfiguration/rolesandpermission', changedPermissions);
  }

  AddOrUpdateFTLAProfile(data: FormData): Observable<FoundationProfiles> {
    return this.repository.upload(environment.apiBaseURL, 'api/User/Profile', data,false);
  }
  RemoveMemberFromFTLA(userId:any,organizationId:any):Observable<any> {
    return this.repository.delete(environment.apiBaseURL,'api/User/Profile/'+userId+'/'+organizationId,'');
  }
  usersBulkUpload(data:any): Observable<any> {
    const formData = new FormData();
    formData.append('FormFile', data, data.name);
    return this.repository.upload(environment.apiBaseURL , 'api/User/bulkupload', formData);
  }
  ApproveRejectStagingUsers(isApproved:boolean):Observable<any>{
    return this.repository.get(environment.apiBaseURL,'api/user/approve/'+isApproved);
  }

  sendReactivationEmail(user:string):Observable<any>{
    return this.repository.get(environment.apiBaseURL,'api/user/reactive/'+user);
  }

  getOktaStatus(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/User/getOktaStatus');
  }
  RemoveUserFromFTLA(userId:any):Observable<any> {
    return this.repository.delete(environment.apiBaseURL,'api/User/RemoveProfile/'+userId,'');
  }
}
