export class FileDefinitions {
    fileTypes: [];
    fileAttribures: FileAttributes;
}

export class FileAttributes {
    cssClass: string;
    cssIcon: string;
    fileContentType: string;
    fileType?: string;
}

export const maxUploadFileSize = {
    'maxFileSize': 28*1024*1024
}

export const fileSupportedAttributes = [
    {
        fileTypes : ['jpg', 'jpeg', 'png', 'bmp', 'tif'],
        fileAttributes: {
            cssClass: 'fa fa-file-image',
            cssIcon: 'file-pdf',
            fileContentType: 'image/',
        }
    },
    {
        fileTypes : ['url', 'lnk'],
        fileAttributes: {
            cssClass: 'fa fa-link',
            cssIcon: 'file-powerpoint',
            fileContentType: 'application/internet-shortcut',
            fileType: 'WebSite'
        }
    },
    {
        fileTypes: ['pdf'],
        fileAttributes: {
            cssClass: 'far fa-file-pdf',
            cssIcon: 'file-pdf',
            fileContentType: 'application/pdf',
            fileType: 'PDF'
        }
    },
    {
        fileTypes: ['eml', 'mht'],
        fileAttributes: {
            cssClass: 'far fa-envelope',
            cssIcon: 'file-email',
            fileContentType: 'message/rfc822',
            fileType: 'Email'
        }
    },
    {
        fileTypes: ['docx'],
        fileAttributes: {
            cssClass: 'far fa-file-word',
            cssIcon: 'file-word',
            fileContentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            fileType: 'WORD'
        }
    },
    {
        fileTypes: ['ppt'],
        fileAttributes: {
            cssClass: 'far fa-file-powerpoint',
            cssIcon: 'file-powerpoint',
            fileContentType: 'application/vnd.ms-powerpoint',
            fileType: 'POWER POINT'
        }
    },
    {
        fileTypes: ['pptx'],
        fileAttributes: {
            cssClass: 'far fa-file-powerpoint',
            cssIcon: 'file-powerpoint',
            fileContentType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            fileType: 'POWER POINT'
        }
    },
    {
        fileTypes: ['doc'],
        fileAttributes: {
            cssClass: 'far fa-file-word',
            cssIcon: 'file-word',
            fileContentType: 'application/msword',
            fileType: 'WORD'
        }
    },
    {
        fileTypes: ['rtf'],
        fileAttributes: {
            cssClass: 'far fa-file-word',
            cssIcon: 'file-word',
            fileContentType: 'application/rtf',
            fileType: 'WORD'
        }
    },
    {
        fileTypes: ['xls', 'csv'],
        fileAttributes: {
            cssClass: 'far fa-file-excel',
            cssIcon: 'file-excel',
            fileContentType: 'application/vnd.ms-excel',
            fileType: 'EXCEL'
        }
    },
    {
        fileTypes: ['xlsx'],
        fileAttributes: {
            cssClass: 'far fa-file-excel',
            cssIcon: 'file-excel',
            fileContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            fileType: 'EXCEL'
        }
    },
    {
        fileTypes: ['htm', 'html'],
        fileAttributes: {
            cssClass: 'far fa-file-code',
            cssIcon: 'file-code',
            fileContentType: 'text/html',
            fileType: 'HTML'
        }
    },
    {
        fileTypes: ['wmv'],
        fileAttributes: {
            cssClass: 'far fa fa-film',
            cssIcon: 'file-code',
            fileContentType: 'video/x-ms-wmv',
            fileType: 'Wmv'
        }
    },
    {
        fileTypes: ['zip', 'rar', '7z'],
        fileAttributes: {
            cssClass: 'far fa-file-archive',
            cssIcon: 'file-archive',
            fileContentType: 'application/octet-stream',
            fileType: 'Archive'
        }
    }
];

export function getSupportedFileAttributes(extention: string) {
    let fileAttributes: FileAttributes;
    fileSupportedAttributes.forEach(element => {
       if (element.fileTypes.indexOf(extention) > -1) {
           fileAttributes = element.fileAttributes;
           if (!fileAttributes.fileType) {
                fileAttributes.fileContentType += extention;
                fileAttributes.fileType = extention;
            }
        }
    });
    if (!fileAttributes) {
        fileAttributes = {
            cssClass: 'far fa-file',
            cssIcon: 'file-archive',
            fileContentType: 'Not Specified',
            fileType: 'Not Specified'
        };
    }
    return fileAttributes;
}
