<div class="container title-manage">
  <div class="heading d-flex">
    <div class="d-flex left-heading">
      <div class="col-md-12">
          <label class="title">Manage Banner / Title Settings</label>
      </div>
      <div class="col-md-12">
        <span>Update Banner Settings</span>        
      </div>
    </div>
    <div class="d-flex right-heading">
      <button class="btn btn-secondary button-style" (click)="isAddOpen = true">Add</button>
    </div>
  </div>
  <div *ngIf="formSubmitSuccess" class="row alertContainer" role="alert">
      <div class="col-md-12">
          <div class="alert alert-success" id="formSubmitSuccess">
          {{ formSubmitSuccess }}
          </div>
      </div>
  </div>
  <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
      <div class="col-md-12">
          <div class="alert alert-danger" id="formSubmitError">
          {{ formSubmitError }}
          </div>
      </div>
  </div>
  <div class="clearfix">
    <div class="col-md-12 table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th><i class="fa fa-file"></i> Title</th>
            <th><i class="fa fa-image"></i> Banner</th>
            <th><i class="fa fa-user"></i> User </th>
            <th><i class="fa fa-user"></i> Date Time </th>
            <th><i class="fa fa-cog"></i> Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let config of resorceList; let rowIndex=index;">
            <td>{{config.title}}</td>
            <td><img [src]="config.fileURL" width="50" /></td>
            <td>{{config.userFullName}}</td>
            <td>{{config.createdDateTime + "Z" | date:'MMM d, y hh:mm a'}}</td>
            <td>
              <span *ngIf="config.isApproved">Approved</span>
              <button *ngIf="!config.isApproved && (isAdmin || isSuperAdmin)" type="button" class="btn btn-secondary button-style mr-10" (click)="approveConfig(config)">Approve</button>
              <button *ngIf="!config.isApproved && (isAdmin || isSuperAdmin)" type="button" class="btn btn-secondary button-style reject-button" (click)="rejectConfig(config)">Reject</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  
</div>
<div class="add-form" *ngIf="isAddOpen">
  <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
  <form [formGroup]="configForm" novalidate>
    <div class="form-group mb-5">
      <label for="txt-title">Title</label>
      <input formControlName="title" class="form-control" name="title" id="txt-title" type="text">
      <div *ngIf="configForm.hasError('required', 'title') && (configForm.dirty || configForm.touched)" class="text-danger small">
        Title is required.
      </div>
    </div>
    <div class="form-group mb-5">
      <label for="txt-title">Banner</label>
      <input type="file" formControlName="banner" (change)="initUpload($event)"/>
      <div *ngIf="configForm.hasError('required', 'banner') && (configForm.dirty || configForm.touched)" class="text-danger small">
        Banner is required.
      </div>
      <div *ngIf="!configForm.hasError('required', 'banner') && configForm.hasError('inValidExt', 'banner') && (configForm.dirty || configForm.touched)" class="text-danger small">
        Invalid File Type.
      </div>
    </div>
    <div class="col-md-6 px-5">
      <button type="button" (click) = "cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-6 px-5">
      <button type="button" [disabled]="configForm.pristine || configForm.invalid" class="btn btn-block btn-warning" (click)="saveConfig()">Save</button>
    </div>
  </form>
</div>
<div class="backdrop" *ngIf="isAddOpen"></div>