import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SearchService } from '../search-results/shared/search.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, AfterViewInit {
  faq: any[]; 
  constructor(private route: ActivatedRoute, public element: ElementRef,
              private router: Router, private searchService: SearchService) { }

  ngOnInit() {
    const parser = new DOMParser();
    const permission = parser.parseFromString('There following roles have been developed within the repository including:<ul class="test"><li>1.&nbsp;&nbsp;&nbsp;&nbsp;Grantee - Access based on the security of the available data and data sets. </li><li>2.&nbsp;&nbsp;&nbsp;&nbsp;Partner – Access based on the security of the available data and data sets. </li><li> 3.&nbsp;&nbsp;&nbsp;&nbsp;Staff - Access to data and data sets based on the security of the available data and data sets. </li><li>4.&nbsp;&nbsp;&nbsp;&nbsp;Moderator – Designated unit staff who review and approve content submissions. </li><li> 5.&nbsp;&nbsp;&nbsp;&nbsp;Administrator – System Administrator of the platform.</li></ul>', 'text/html');

    this.faq = [
      { question: 'How is this platform different, or how do we use it instead of other Casey tools?', answer: 'This platform is not a replacement for the Grants Management System, Box, or other Foundation tools.  It is meant to curate and analyze specific data types and data sets.  Power BI, a Microsoft product, is available to analyze and report on data sets.  Additional information about what types of data should be uploaded to this repository may be found on our intranet, Horizon. If you have any questions about what data should be in the repository, please submit a request for assistance via the support portal.'},
      { question: 'Who will have access to this repository?', answer: 'All staff will have access to the repository by selecting the appropriate Okta tile.  Non-Casey staff may be granted access via the grantee/partner Okta portal. Non-Casey staff access is limited to specific data and data sets by the permission setting of the data.'},
      { question: 'How will permissions and access levels be managed?', answer: 'When data or data sets are uploaded, a unit repository moderator must tag the data with the appropriate permissions.  Data and data sets may be tagged as public, all-staff, or for a specific unit.  All data is accessible by SLT members by default.  The repository is meant to promote collaboration and sharing, and not be overly restrictive.  If your team has sensitive data sets, this is not the platform for that data.  Please contact BT to discuss how to manage any sensitive data or data sets.'},
      { question: 'What are the different user roles and associated permission levels? ', answer: permission.body.innerHTML},
      { question: 'How can access be removed for a grantee or partner?', answer: 'Please submit a support request to remove a grantee or partner.'},
      { question: 'Can data be accessed at any time from any device?', answer: 'The repository is available 24/7 from any device with good internet access.  The only exceptions are scheduled maintenance windows, and vendor outages.'},
      { question: 'Who is responsible for cleaning my data?', answer: 'All data and data sets must be in final form prior to submitting it to be included in the repository.'}
    ];
  }

  ngAfterViewInit() {
    this.scrollPosition();
  }

  scrollPosition() {
    const question = this.route.snapshot.queryParamMap.get('question');
    if (this.element && question) {
      const scrollElement = document.querySelector('#' + question);
      if (scrollElement) {
        scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  getAdvacacyLeanringLabResource() {
    this.searchService.emitSearchRouteEvent({externalLink: true});
    this.router.navigate(['/search']);
  }
}
