import { Component, OnInit } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { StateService } from 'src/app/core/state-manager/appstateservice';

@Component({
  selector: 'app-not-authorized-page',
  templateUrl: './not-authorized-page.component.html',
  styleUrls: ['./not-authorized-page.component.scss']
})
export class NotAuthorizedPageComponent implements OnInit {
  otherGroup = false;
  isUserActive =false;
  isUnAuthorized=false;

  constructor(private currentState: StateService, private oktaAuth: OktaAuth) { }

  ngOnInit() {
    this.otherGroup = this.currentState.isAllGroup() || this.currentState.isJDAIGroup()
                    || this.currentState.isThriveGroup() || this.currentState.isReportingGroup() || this.currentState.isFTLAGroup();
    this.isUserActive =this.currentState.IsUserActive();
    this.isUnAuthorized = this.currentState.isUnAuthorized();
  
  }
}
