import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { ReportService } from './shared/report.service';
import { SearchModule } from '../search/search.module';
import { ReportComponent } from './report/reportobj.component';
import { SiteServices } from '../jj/services/site.services';
import { EmbedReportComponent } from './embed-report/embed-report.component';
@NgModule({
    declarations: [ReportComponent, EmbedReportComponent],
    imports: [
      CommonModule,
      FormsModule,
      NgxPowerBiModule,
      SearchModule
    ],
    providers: [
      ReportService,
      SiteServices
    ],
    exports:[EmbedReportComponent],
    bootstrap: [ReportComponent]
    })
    export class ReportModule {}
