<div class="container title-manage">
  <div class="heading d-flex">
    <div class="d-flex left-heading">
      <div class="col-md-12">
          <label class="title">Manage Meta Tags</label>
      </div>
      <div class="col-md-12">
        <span>Add / Remove Meta Tags</span>        
      </div>
    </div>
  </div>
  <div class="clearfix d-flex filters mb-10">
    <div class="col-xs-6">
      <label>Meta Tag</label>
      <select [(ngModel)]="metaTag" class="form-control">
        <option value="" disabled selected>Select</option>
        <ng-container *ngFor="let tag of metadataTags | keyvalue">
          <option value="{{tag.key}}" *ngIf="isTagVisible(tag.key)">{{tag.value}}</option>
        </ng-container>
      </select>
    </div>
    <div class="col-xs-12 px-5 filter-buttons d-flex">
      <div class="col-xs-6 px-5">
        <button class="btn btn-secondary button-style" (click)="getTagsList()">Filter</button>
      </div>
    </div>
  </div>
  <div class="clearfix" *ngIf="metaTagSelected">
    <div class="col-md-12 table-responsive" *ngIf="(metaTagSelected != 'topics' && isAllGroup) || !isAllGroup">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="action"><i class="fa fa-cog"></i> Action</th>
            <th><i class="fa fa-tag"></i> Tag</th>
            <th *ngIf="displayDescription"><i class="fa fa-file"></i> Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tag of returnedArray; let rowIndex=index;">
            <td align="center">
              <div dropdown container="body">
                <a dropdownToggle class="dropdown-toggle action" aria-controls="dropdown-container">
                  <i class="fa fa-list"></i></a>
                <ul id="dropdown-container" *dropdownMenu class="dropdown-menu p-0"
                    role="menu" aria-labelledby="button-container">
                  <li role="menuitem"><a class="dropdown-item" (click)="editTag(tag, rowIndex)">Edit</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="openConfirmationModal(tag, rowIndex)">Delete</a></li>
                </ul>
              </div>
            <td>{{tag.title}}</td>
            <td *ngIf="displayDescription">{{tag.description}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12 table-responsive" *ngIf="metaTagSelected == 'topics' && isAllGroup">
      <accordion [closeOthers]="true">
        <ng-template ngFor let-tag let-rowIndex="index" [ngForOf]="returnedArray">    
          <accordion-group #grp>
            <div accordion-heading class="tab">
              <div class="tab-label">
                <div class="tab-label-text">
                  <div class="col-md-1">
                    <div dropdown container="body">
                      <a dropdownToggle class="dropdown-toggle action" aria-controls="dropdown-container">
                        <i class="fa fa-list"></i></a>
                      <ul id="dropdown-container" *dropdownMenu class="dropdown-menu p-0"
                          role="menu" aria-labelledby="button-container">
                        <li role="menuitem"><a class="dropdown-item" (click)="editTag(tag, rowIndex)">Edit</a></li>
                        <li role="menuitem"><a class="dropdown-item" (click)="openConfirmationModal(tag, rowIndex)">Delete</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-8"> {{tag.title}}</div>
                </div>
              </div>
            </div>
            <div class="tab-content">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="action"><i class="fa fa-cog"></i> Action</th>
                    <th><i class="fa fa-tag"></i> Tag</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let program of tag.programs; let programIndex=index;">
                    <td align="center">
                      <div dropdown container="body">
                        <a dropdownToggle class="dropdown-toggle action" aria-controls="dropdown-container">
                          <i class="fa fa-list"></i></a>
                        <ul id="dropdown-container" *dropdownMenu class="dropdown-menu p-0"
                            role="menu" aria-labelledby="button-container">
                          <li role="menuitem"><a class="dropdown-item" (click)="editProgram(tag, rowIndex, program, programIndex)">Edit</a></li>
                          <li role="menuitem"><a class="dropdown-item" (click)="deleteProgram(tag, rowIndex, program, programIndex)">Delete</a></li>
                        </ul>
                      </div>
                    </td>
                    <td>{{program.title}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </accordion-group>
        </ng-template>
      </accordion>
    </div>
    <div class="clearfix">
      <div class="col-md-8" *ngIf="metaTagSelected">
        <pagination [totalItems]="totalRows" [maxSize]="7" [boundaryLinks]="true" (pageChanged)="pageChanged($event)"></pagination>
      </div>
    </div>
    <div class="clearfix">
      <div class="addRowBtn">
        <button type="button" id="btnadd" class="btn btn-success button-style mr-1rem" (click)="isAddOpen = true">Add</button>
        <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/metatags']">Cancel</button>
        <button type="button" id="btnsave" class="btn btn-secondary button-style" (click)="updateTags()" [ngClass]="{ 'disable-color': viewAsAdmin }">Save</button>
      </div>
      <div class="pull-right col-md-6">
        <div *ngIf="formSubmitSuccess">
            <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
        </div>
      </div>
  </div>  
</div>
<div class="add-form" *ngIf="isAddOpen">
  <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
  <form [formGroup]="tagForm" novalidate>
    <div class="form-group mb-5" *ngIf="metaTagSelected == 'topics' && isAllGroup">
      <label for="drp-tagType">Tag Type</label>
      <select formControlName="tagType" class="form-control" id="drp-tagType" (change)="showHideTopic()">
        <option value="topic">{{metadataTags.topics}}</option>
        <option value="program">{{metadataTags.programs}}</option>
      </select>
      <div *ngIf="tagForm.hasError('required', 'tagType') && (tagForm.dirty || tagForm.touched)" class="text-danger small">
        Tag Type is required.
      </div>
    </div>
    <div class="form-group mb-5" *ngIf="showTopic">
      <label for="drp-topic">Competency Area</label>
      <select formControlName="topic" class="form-control" id="drp-topic">
        <option *ngFor="let topic of returnedArray" value="{{topic.title}}">{{topic.title}}</option>
      </select>
      <div *ngIf="tagForm.hasError('required', 'topic') && (tagForm.dirty || tagForm.touched)" class="text-danger small">
        Competency Area is required.
      </div>
    </div>
    <div class="form-group mb-5">
      <label for="txt-title">Title</label>
      <input formControlName="title" class="form-control" name="title" id="txt-title" type="text">
      <div *ngIf="tagForm.hasError('required', 'title') && (tagForm.dirty || tagForm.touched)" class="text-danger small">
        Title is required.
      </div>
    </div>
    <div class="form-group mb-5" *ngIf="displayDescription">
      <label for="txt-description">Description</label>
      <textarea formControlName="description" class="form-control" name="description" id="txt-description"></textarea>
      <div *ngIf="tagForm.hasError('required', 'description') && (tagForm.dirty || tagForm.touched)" class="text-danger small">
        Description is required.
      </div>
    </div>
    <div class="col-md-6 px-5">
      <button type="button" (click) = "cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-6 px-5">
      <button type="button" [disabled]="tagForm.pristine || tagForm.invalid" class="btn btn-block btn-warning" (click)="addTag()">Save</button>
    </div>
  </form>
</div>
<div class="backdrop" *ngIf="isAddOpen"></div>
<app-modal #confirmationModal contentStyle="delete-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
      <div class="col-sm-12 content-style" *ngIf="dataItem && dataItem.type && dataItem.type == 'delete'">
          <div *ngIf="dataItem && dataItem.tag && dataItem.tag.isEditable && !dataItem.tag.count">
              <span>Are sure want to delete? </span>
          </div>
          <div *ngIf="dataItem && dataItem.tag && dataItem.tag.isEditable && dataItem.tag.count">
              <span>This tag is associated with <strong>{{dataItem.tag.count}}</strong> resources. So changes will be not reflect immediatialy in search results. Are you want to delete?</span>
          </div>
      </div>
      <div class="col-sm-12 content-style" *ngIf="dataItem && dataItem.type && dataItem.type == 'edit'">
        <div *ngIf="dataItem && dataItem.tag && dataItem.tag.isEditable && !dataItem.tag.count">
              <span>Are sure want to edit? </span>
          </div>
        <div *ngIf="dataItem && dataItem.tag && dataItem.tag.isEditable && dataItem.tag.count">
            <span>This tag is associated with <strong>{{dataItem.tag.count}}</strong> resources. So changes will be not reflect immediatialy in search results. Are sure want to edit?</span>
        </div>
    </div>
      <div class="col-sm-12 footer-style" *ngIf="dataItem && dataItem.tag && dataItem.tag.isEditable && dataItem.type == 'delete'">
          <div>
              <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                  <span>Cancel</span>
              </button>
          </div>
          <div class="savebtn">
              <button type="submit" class="btn button-style" (click)="removeTag(dataItem.tag, dataItem.rowIndex)">
                  <span>Ok</span>
              </button>
          </div>
      </div>
      <div class="col-sm-12 footer-style" *ngIf="dataItem && dataItem.tag && dataItem.tag.isEditable && dataItem.type == 'edit'">
          <div>
              <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                  <span>Cancel</span>
              </button>
          </div>
          <div class="savebtn">
              <button type="submit" *ngIf="dataItem.tag.tagType === undefined" class="btn button-style" (click)="closeConfirmModal(); openEditTagSection(dataItem.tag, dataItem.rowIndex)">
                  <span>Ok</span>
              </button>
              <button type="submit" *ngIf="dataItem.tag.tagType == 'program'" class="btn button-style" (click)="closeConfirmModal(); openEditProgramSection(dataItem.tag, dataItem.rowIndex)">
                  <span>Ok</span>
              </button>
          </div>
      </div>
      <div class="col-sm-12 footer-style" *ngIf="dataItem && dataItem.tag && !dataItem.tag.isEditable">
          <div class="savebtn">
              <button type="button" class="btn button-style" (click)="closeConfirmModal()">
                  <span>Ok</span>
              </button>
          </div>
      </div>
  </ng-template>
</app-modal>