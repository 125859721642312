import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { PortalConfigService } from '../shared/portal-config.service';
import { OktaSettings } from '../shared/portal-config.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-okta-config',
  templateUrl: './okta-config.component.html',
  styleUrls: ['./okta-config.component.scss']
})
export class OktaConfigComponent implements OnInit {
  OktaSettings: Array<OktaSettings>;
  managedOktaSettings: Array<OktaSettings>;
  manageOktaSettingsPage: Array<OktaSettings>;
  formSubmitSuccess: string;
  formSubmitError: string;
  selectedOktaSettings: OktaSettings = null;
  addRowInvalid = false;
  itemsTosearch = 15;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 0;
  perPage = 10;
  totalRows = 0;
  isLoading = false;
  isAddOpen:boolean=false;
  oktaSettingsForm: FormGroup;
  isOktaExists =false;
  constructor(private portalConfigService: PortalConfigService,
              public googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    this.managedOktaSettings = new Array<OktaSettings>();
    this.getOktaSettings();
    this.oktaSettingsForm = new FormGroup({
      name:new FormControl('', Validators.required),
      clientId:new FormControl('', Validators.required),
      issuer:new FormControl('', Validators.required),
      oktaGroupId:new FormControl(''),
      oktaGroupName:new FormControl(''),
      isActive:new FormControl(''),
    });
  }

 getOktaSettings() {
    this.isLoading = true;
    this.portalConfigService.getOktaConfigSettings()
    .subscribe(data => {
        this.managedOktaSettings = data;
        this.currentPage=0;
        this.manageOktaSettingsPage = this.managedOktaSettings.slice(this.currentPage, 10);
        this.isLoading = false;
        this.totalRows = this.managedOktaSettings.length;
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    this.manageOktaSettingsPage = this.managedOktaSettings.slice(skipRows, this.currentPage*this.perPage);
  }

  resetAddForm() {
    this.selectedOktaSettings = null;
    this.isAddOpen = false;
    this.oktaSettingsForm.reset();
  }

  addUser() {
    this.isAddOpen = true;
  }

  AddOktaSettings():void {
    let name = this.oktaSettingsForm.get('name').value;
    let clientId = this.oktaSettingsForm.get('clientId').value;
    let issuer = this.oktaSettingsForm.get('issuer').value;
    let oktaGroupId = this.oktaSettingsForm.get('oktaGroupId').value;
    let oktaGroupName = this.oktaSettingsForm.get('oktaGroupName').value;
    let active = this.oktaSettingsForm.get('isActive').value;
    let oktasettingdata = new OktaSettings();
    oktasettingdata.isActive =active;
    oktasettingdata.oktaGroupId = oktaGroupId;
    oktasettingdata.oktaGroupName = oktaGroupName;
    oktasettingdata.name = name;
    oktasettingdata.clientId = clientId;
    oktasettingdata.issuer = issuer;

    if(this.selectedOktaSettings!=null)
      oktasettingdata.id=this.selectedOktaSettings.id;
    else
    oktasettingdata.id=0;

    this.portalConfigService.AddOrUpdateOktaProfiles(oktasettingdata)
      .subscribe((data) => {
        this.formSubmitSuccess = "Successfully saved okta settings";
        this.getOktaSettings();
    });
    this.resetAddForm();
    
  }

  checkPortalExists():void{
    let name = this.oktaSettingsForm.get('name').value;
    let itemIndex = this.managedOktaSettings.findIndex(x=>x.name.toLocaleLowerCase() == name.toLocaleLowerCase());
    if(itemIndex>0){
      this.isOktaExists=true;
    }else{
      this.isOktaExists=false;
    }
  }

  editPortalOktaSettings(oktaSettings:OktaSettings){
    console.log(oktaSettings);
    this.oktaSettingsForm.get('name').setValue(oktaSettings.name);
    this.oktaSettingsForm.get('clientId').setValue(oktaSettings.clientId);
    this.oktaSettingsForm.get('issuer').setValue(oktaSettings.issuer);
    this.oktaSettingsForm.get('oktaGroupId').setValue(oktaSettings.oktaGroupId);
    this.oktaSettingsForm.get('oktaGroupName').setValue(oktaSettings.oktaGroupName);
    this.oktaSettingsForm.get('isActive').setValue(oktaSettings.isActive);
    this.selectedOktaSettings = oktaSettings;
    this.isAddOpen = true;
  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    
  }
}
