export const environment = {
  production: true,
  server: 'Development',
  version: '2022.08.10',
  gaEnable: true,
  gtmEnable: true,
  // GA IDs
  defaultGAId: 'UA-172781461-1',
  allGAId: 'UA-172781461-3',
  jdaiGAId: 'UA-172781461-4',
  visionGAId: 'UA-172781461-6',
  ftlaGAId: 'UA-172781461-5',
  reportingGAId: 'UA-172781461-2',
  jcyoiGAId: 'UA-172781461-7',
  // GTM IDs
  defaultGTMId: 'GTM-TJLK3GC',
  allGTMId: 'GTM-MFSVKQJ',
  jdaiGTMId: 'GTM-P2VH86C',
  visionGTMId: 'GTM-WFG2H4X',
  ftlaGTMId: 'GTM-MB2DMPR',
  reportingGTMId: 'GTM-N8VG6PF',
  jcyoiGTMId: 'GTM-5T3G5MV',

  // Page Title
  jcyoiPageTitle: 'Jim Casey Youth Opportunities Initiative Data Dashboards',
  ftlaPageTitle:'Foundation Technology Leaders Alliance',
  visionPageTitle:'Thrive by 25',
  allPageTitle:'Advocacy Learning Lab',
  grantsPageTitle :'Grants Data Insights',
  reportingPageTitle:'AECF Reporting Portal',
  jdaiPageTitle:'Juvenile Justice Strategy Group',
  jjdataPageTitle:'Transforming Juvenile Probation Data Portal',
  
  slackWebHook: 'BADURLhttps://hooks.slack.com/services/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',
  apiBaseURL: 'https://kms-dev.aecf.org/SearchApi/',
  DashboardUrl: 'https://kms-dev.aecf.org/SearchApi/api/reports',
  // apiBaseURL: 'http://localhost:5100/',
  // DashboardUrl: 'http://localhost:5100/api/reports',
  instrumentationKey: '1522ac55-da65-406b-8a3f-95347eed7145',
  boxClientId: '4mbls5fkbem8z6az6e1u7qy698083md1',
  dropboxClientId: '8uvrkjrbvsssik3',
  dropboxSearchAPI: 'https://api.dropboxapi.com/2/files/search',
  dropboxUploadAPI: 'https://content.dropboxapi.com/2/files/upload',
  blobStorage:'https://aecfdevstorage.blob.core.windows.net/ftla-profiles',
  azureADAuth:false,
  clientId:'bcfd7448-eaac-4596-b1d0-ac2d6929f259',
  tenantId:'23ab7285-e880-4033-a55c-2e80ae97171a',
  authority: 'https://login.windows-ppe.net/23ab7285-e880-4033-a55c-2e80ae97171a',
  portalConfigPageTitle: 'AECF OKTA Portal Configuration',
};
