<div class="container audit-logging">
  <div class="heading row">
      <div class="page-title">
        <div>
            <span calss="page-title"><h1>Audit Details</h1></span>
        </div>
    </div>
    <div class="row d-flex filters no-print">
      <div class="col-xs-12">
        <label>Event Type</label>
        <select [(ngModel)]="eventTypeSelected" class="form-control">
          <option value="null" disabled selected>Select</option>
          <option *ngFor="let type of EventType.values();">{{type}}</option>
        </select>
      </div>
      <div class="col-xs-12">
        <label>Event Action</label>
        <select [(ngModel)]="eventActionSelected" class="form-control">
          <option value="null" disabled selected>Select</option>
          <option *ngFor="let type of EventAction.values();">{{type == 'Reassigned'?'Re-Assigned':type}}</option>
        </select>
      </div>
      <div class="col-xs-12">
        <label>User</label>
        <input [(ngModel)]="typedUser"
                  [ngClass]="{ 'invalid': !isValidUser() }"
                  class="form-control"
                  autocomplete="off"
                  id="userText"
                  name="userText"
                  placeholder="Email"
                  type="text"
                  [typeahead]="searchUserResult"  
                  [typeaheadItemTemplate]="typeaheadItemTemplate"
                  (typeaheadOnSelect)="typeaheadOnSelect($event)"                  
                  [typeaheadOptionsLimit]="10"
                />
      </div>
      <div class="col-xs-12 px-5">
        <div class="col-xs-6 px-5">
          <label>Start Date</label>
          <input type="text" placeholder="Start Date" class="form-control"  [ngClass]="{'invalid': hasInvalidDate(bsStartValue) || hasDateRangeError() }"
                [(bsValue)]="bsStartValue" bsDatepicker [bsConfig]="{ isAnimated: true, containerClass:'theme-orange', dateInputFormat: 'MM/DD/YYYY' }">
        </div>
        <div class="col-xs-6 px-5">
          <label>End Date</label>
          <input type="text" placeholder="End Date" class="form-control" [ngClass]="{'invalid': hasInvalidDate(bsEndValue) || hasDateRangeError() }"
                [(bsValue)]="bsEndValue" bsDatepicker [bsConfig]="{ isAnimated: true, containerClass:'theme-orange', dateInputFormat: 'MM/DD/YYYY' }">
        </div>
        <div class="row d-flex">
          <div class="col-xs-12">
            <span *ngIf="hasDateRangeError()" class="invalid-error">Please enter valid date range</span>
          </div>
        </div>
      </div>
      <div class="col-xs-12 px-5 filter-buttons">
        <div class="col-xs-6 px-5">
          <button class="btn btn-block btn-warning" (click)="getAuditFilter()">Filter</button>
        </div>
        <div class="col-xs-6 px-5">
          <button class="btn btn-block btn-danger" (click)="clearFilter()">Clear</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-print">
    <div class="col-12 table-responsive">
      <table class="table table-striped" id="printableArea" [ngClass]="{ 'loading': isLoading }">
        <thead>
          <tr>
            <th><i class="fa fa-file"></i> Event Type</th>
            <th><i class="fa fa-list"></i> Event Action</th>
            <th><i class="fa fa-key"></i> Event Key</th>
            <th><i class="fa fa-user"></i> User</th>
            <th><i class="fa fa-clock"></i> Date Time</th>
            <th class="no-print"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let auditLogging of auditLoggings; let rowIndex=index;">
            <td>{{auditLogging.eventType}}</td>
            <td>{{auditLogging.action == 'Reassigned'?'Re-Assigned':auditLogging.action}}</td>
            <td>{{auditLogging.primaryKey}}</td>
            <td>{{auditLogging.userPrincipalName}}</td>
            <td>{{auditLogging.dateTime + "Z" | date:'MMM d, y hh:mm a'}}</td>
            <td class="no-print"><a (click)="openModal(rowIndex)" title="View Details" class="pointer"><i class="fa fa-search-plus"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-8 no-print">
      <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)"  [boundaryLinks]="true" [maxSize]="10"></pagination>
    </div>    
    <div class="col-md-4 px-5 py-20 no-print">
      <div class="col-xs-6 px-5">
        <button class="btn btn-block btn-success" (click)="exportAuditLoggings()" [disabled]="isExporting" *ngIf="ischeckPermissions(permissions.ExportAuditLogs)">
          <span *ngIf="!isExporting"><i class="fa fa-table"></i> Export</span>
          <span *ngIf="isExporting"><i class="fa fa-spinner fa-spin"></i> Exporting</span>
        </button>
      </div>
      <div class="col-xs-6 px-5">
        <button class="btn btn-block btn-warning" [disabled]="!isPrintable" (click)="print()" *ngIf="ischeckPermissions(permissions.PrintAuditLogs)">
          <span><i class="fa fa-print"></i> Print</span></button>
      </div>
    </div>
  </div>
  <div class="row printableArea">
    <div class="col-12 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Event Type</th>
            <th>Event Action</th>
            <th class="w-175">Event Key</th>
            <th>User</th>
            <th>Date Time</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let auditLogging of displayItems; let rowIndex=index;">
            <td>{{auditLogging.eventType}}</td>
            <td>{{auditLogging.action}}</td>
            <td><div class="w-175">{{auditLogging.primaryKey}}</div></td>
            <td>{{auditLogging.userPrincipalName}}</td>
            <td>{{auditLogging.dateTime + "Z" | date:'MM-dd-y hh:mm a'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-modal #viewDetailModal contentStyle="share-results-modal" [showFooter]=false class="modal-lg modal-centered">
  <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
      <div class="col-sm-12 content-style">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Key</th>
              <th *ngIf="auditLogging.action != 'Inserted' && auditLogging.action != 'Exception'">Old Value</th>
              <th *ngIf="auditLogging.action != 'Deleted'">New Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let change of changes">
              <td>{{change.key}}</td>
              <td [ngClass]="{'bg-danger': change.isUpdated || auditLogging.action == 'Deleted'}" *ngIf="auditLogging.action != 'Inserted' && auditLogging.action != 'Exception' && change.key != 'UploadedDateTime' && change.key != 'CreatedDateTime' && change.key != 'UpdatedDateTime'">
                <div class="w-325">{{change.oldValue | json}}</div>
              </td>
              <td [ngClass]="{'bg-danger': change.isUpdated || auditLogging.action == 'Deleted'}" *ngIf="auditLogging.action != 'Inserted' && (change.key == 'UploadedDateTime' || change.key == 'CreatedDateTime' || change.key == 'UpdatedDateTime')">
                <div class="w-325">{{change.oldValue | date:'MMM d, y hh:mm a'}}</div>
              </td>
              <td [ngClass]="{'bg-success': change.isUpdated || auditLogging.action == 'Inserted' || auditLogging.action == 'Exception'}" *ngIf="auditLogging.action != 'Deleted'">
                <div class="w-325">{{change.newValue | json}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  </ng-template>
</app-modal>

<ng-template #typeaheadItemTemplate let-model="item" let-index="index">
  <h4><span class="user-icon"><em class="far fa-user-circle"></em></span> {{model.fullName}}</h4>
</ng-template>