import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PortalConfigService } from './shared/portal-config.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared/shared.module';
import { OktaSettingComponent } from './okta-setting/okta-setting.component';
import { TitleManageComponent } from './title-manage/title-manage.component';
import { MetaTagsService } from './shared/meta-tags.service';
import { MetaTagsManageComponent } from './meta-tags-manage/meta-tags-manage.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { OktaConfigComponent } from './okta-config/okta-config.component';

@NgModule({
  declarations: [OktaSettingComponent, TitleManageComponent, MetaTagsManageComponent, OktaConfigComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    AccordionModule,
    CarouselModule.forRoot(),
    BsDropdownModule,
    SharedModule,
    AlertModule.forRoot(),
    PaginationModule.forRoot()
  ],
  bootstrap: [OktaSettingComponent],
  providers: [
    PortalConfigService,
    MetaTagsService
  ]
})
export class PortalConfigModule { }
