import { Meta } from '@angular/platform-browser';
import { compareItems } from 'src/app/common/models/utility';
export const  extentionRegEx = /(?:\.([^.]+))?$/;

export const metadataTags = {
    topics: 'Topic',
    programs: 'Program Area',
    contentTypes: 'ContentTypes'
};

export const allMetadataTags = {
    topics: 'Competency Area',
    programs: 'Competency Sub-Area',
    contentTypes: 'Content Types',
    publishers: 'Publisher'
};

export class MetadataChangeRequest
{
    tagId: number;
    tagType: string;
    oldValue:string;
    newValue:string;
    action:MetadataAction;
    documentCount: number;

        constructor(tagId:number,tagType:string, oldValue:string, newValue:string,action:MetadataAction,documentCount:number) {
        
        this.tagId = tagId;
        this.tagType= tagType,
        this.oldValue = oldValue;
        this.newValue = newValue;
        this.action= action;
        this.documentCount= documentCount;
        }
}

export enum MetadataAction{
    Updated,
    Deleted
}
