<div class="container okta-manage">
  <div class="heading d-flex">
    <div class="d-flex left-heading">
      <div class="col-md-12">
          <label class="title">Manage Okta Settings</label>
      </div>
      <div class="col-md-12">
        <span>Update Okta Settings</span>        
      </div>
    </div>
  </div>
  <div *ngIf="formSubmitSuccess" class="row alertContainer" role="alert">
      <div class="col-md-12">
          <div class="alert alert-success" id="formSubmitSuccess">
          {{ formSubmitSuccess }}
          </div>
      </div>
  </div>
  <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
      <div class="col-md-12">
          <div class="alert alert-danger" id="formSubmitError">
          {{ formSubmitError }}
          </div>
      </div>
  </div>
  <div class="clearfix">
    <div class="col-md-12">
      <form [formGroup]="oktaForm" novalidate>
        <div class="form-group">
          <label for="txt-title">Client ID</label>
          <input formControlName="oktaClientId" class="form-control" name="oktaClientId" id="txt-oktaClientId" type="text">
          <div *ngIf="oktaForm.hasError('required', 'oktaClientId')" class="text-danger small">
            Client ID is required.
          </div>
        </div>
        <div class="form-group">
          <label for="txt-title">Issuer</label>
          <input formControlName="oktaIssuer" class="form-control" name="oktaIssuer" id="txt-oktaIssuer" type="text">
          <div *ngIf="oktaForm.hasError('required', 'oktaIssuer')" class="text-danger small">
            Issuer is required.
          </div>
        </div>
        <div class="col-md-3 pull-right">
          <button type="button" [disabled]="oktaForm.pristine || oktaForm.invalid" class="btn btn-block btn-warning" (click)="saveOktaSetting()">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>