import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'splitArray'})
export class FormatArrayPipe implements PipeTransform {
  transform(value: any, format?: any): Array<string> {
    if (value === null || value === undefined) {
      return [];
    }

    return value.split(format);
  }
}
