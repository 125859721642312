<div class="container manage-users">
    <div class="heading d-flex">
          <div class="page-title">
            <div>
                <span calss="page-title"><h1>Manage Sites</h1></span>
            </div>
        </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="search_icon_profile">
        <i class="fas fa-search"></i>
    </div>
    <div class="searchBoxForm_profile">
            <input type="text" id="searchTerm" name="searchTerm" placeholder="Search sites..." autocomplete="off"
                         [(ngModel)]="searchTerm" (keyup)="search()"  class="searchBox_Profile"  />
    </div>
    <button type="button"  [ngClass]="{ 'disable':  viewAsAdmin }" *ngIf="isAdmin" id="btnAddSite" class="btn btn-success" (click)="addSite()">Add Site</button>
</div>
<div class="row">
    <div class="col-12 table-responsive">
          <table class="table table-striped sortable" id="printableArea">
            <thead>
              <tr>
                <th *ngIf="ischeckPermissions(permissions.ManageSite) && isAdmin"> </th>
                <th title="Measure Definitions and Notes"> Measure</th>
                <th title="Enter/Edit Data">Data</th>
                <th><div style="display:inline-flex"> Site Name <a (click)="toggleTag('name')" [hidden]="siteAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!siteAsc" (click)="toggleTag('name')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th><div style="display:inline-flex"> State Name <a (click)="toggleTag('state')" [hidden]="stateAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!stateAsc" (click)="toggleTag('state')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th *ngIf="isAdmin"><div style="display:inline-flex">Last Updated<a (click)="toggleTag('lastUpdated')" [hidden]="lastUpdatedAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedAsc" (click)="toggleTag('lastUpdated')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th *ngIf="isAdmin"><div style="display:inline-flex">Last Updated Date Time<a (click)="toggleTag('lastUpdatedDateTime')" [hidden]="lastUpdatedDateTimeAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedDateTimeAsc" (click)="toggleTag('lastUpdatedDateTime')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let site of manageSites; let rowIndex=index;">
            <td *ngIf="ischeckPermissions(permissions.ManageSite) && isAdmin" title="edit site"> 
            <div class="delete-icon" [ngClass]="{ 'disabled': viewAsAdmin }">
                <span  class="role-icon"  (click)="editSite(site, rowIndex)">
                    <em class="far fa-edit"></em>
                </span>  
            </div>
        </td>
        <td>
            <div class="delete-icon" title="Measure Definitions and Notes"  *ngIf="site.id>0">
                <span  class="role-icon"  (click)="manageMeasures(site, rowIndex)"> 
                <i class="fas fa-tachometer-alt" style="size:9px;"></i>
              </span>
            </div>
        </td>
        <td>
            <div class="delete-icon" title="Enter/Edit Data" *ngIf="site.id>0">
                <span  class="role-icon"  (click)="manageMeasuresData(site, rowIndex)"> 
                    <i class="fas fa-database"  style="size:10px;"></i>
              </span>
            </div>
        </td>
                <td>{{site.name}}</td>
                <td>{{site.state}}</td>
                <td *ngIf="isAdmin">{{site.lastUpdated}} </td>
                <td *ngIf="isAdmin">{{site.lastUpdatedDateTime | timezone:'MM/DD/YYYY hh:mm A' }} </td>
              </tr>
            </tbody>
          </table>
    </div>
</div>
<div class="row" *ngIf="manageSites?.length>0">
    <!-- Disabling Data collections button  -->
    <!-- <div class="col-md-2 padding-2p">
        <button type="button"   [ngClass]="{ 'disable':  viewAsAdmin }" *ngIf="isAdmin"  id="btnDataCollection" class="btn btn-success datacollection" (click)="openDataCollection()">Data Collection</button>
    </div> -->
    <div class="col-md-2 padding-2p" *ngIf="manageSites?.length>0">
        <button type="button"   [ngClass]="{ 'disable':  viewAsAdmin }" *ngIf="isAdmin"  id="btnprintyears" class="btn btn-success activeyears"  (click)="exportSites()">Export Sites</button>
    </div>
    <div class="col-md-5" *ngIf="totalRows>1" >
        <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)"  [boundaryLinks]="true" [maxSize]="10"></pagination>
    </div>    

    <div class="col-md-3 pull-right padding-2p">
            <div class="col-xs-6 px-5">
                <button type="button" *ngIf="isAdmin " id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
            </div>
            <div class="clearfix">
                <div class="pull-right">
                    <div *ngIf="formSubmitSuccess">
                        <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
                    </div>
                </div>
        </div>
    </div>

</div>
<div class="row" *ngIf="manageSites?.length===0">
    <div class="col-md-4" ></div>
    <div class="col-md-4" >  <p>No Sites Found.</p></div>
    <div class="col-md-4" ></div>
  
</div>

<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <form [formGroup]="siteForm">
                    <div>
                        <label class="required-field">Site Name</label>
                        <input id="txtSiteName" type="text" name="txtsiteName" placeholder="Site Name"  autocomplete="new-txtfname" 
                        [ngClass]="{'input-error': siteForm.controls.name.touched && siteForm.controls.name.status=='INVALID'}"
                        formControlName="name" class="form-control" required />
                    </div>
                    <label  for="txt-role" class="required-field">State</label>
                    <div >
                        <select formControlName="state" class="form-control" 
                        [ngClass]="{'input-error': siteForm.controls.name.touched && siteForm.controls.name.status=='INVALID'}" 
                        required>
                            <option value = ''> Select State</option>
                            <option *ngFor="let state of stateList; let rowIndex=index;" [ngValue]="state.state" [selected]="state.state == this"> 
                            {{state.state}} 
                            </option>
                        </select>
                    </div>  
                    <label for="txt-role" >Copy From Site</label>
                    <div >
                        <select formControlName="copyFromSite" class="form-control">
                            <option value = 0> Select State</option>
                            <option *ngFor="let site of siteList; let rowIndex=index;" [ngValue]="site.id" [selected]="site.name == this"> 
                            {{site.name}} 
                            </option>
                        </select>
                    </div>
                    <label  for="txtBaseLineYear">BaseLine Year</label>
                    <div>
                        <input id="txtBaseLineYear" type="number" name="txtBaseLineYear" placeholder="BaseLine Year"  autocomplete="new-txtbaselineyear" 
                        formControlName="baseLineYear" class="form-control"  />
                    </div>
                    <label  for="txtJDAISince">JDAI Since</label>
                    <div>
                        <input id="txtJDAISince" type="number" name="txtJDAISince" placeholder="JDAI Since"  autocomplete="new-txtjdaisince" 
                        formControlName="jdaiSince" class="form-control"  />
                    </div>
                    <label  for="txtPTSince">Probation Transformation Since</label>
                    <div>
                        <input id="txtPTSince" type="number" name="txtPTSince" placeholder="Probation Transformation Since"  autocomplete="new-txtPTSince" 
                        formControlName="probationTransformationSince" class="form-control"  />
                    </div>
                    <div>Select Site Active/InActive <input id="chkSite" type="checkbox" value="site" formControlName="isActive" > Is Active</div>
                    <div>Are status offenses included in referrals? 
                            <label>
                                <input type="radio" value="1" formControlName="statusOffenses">
                                <span>Yes</span>
                            </label>
                            <label>
                                <input type="radio" value="0" formControlName="statusOffenses">
                                <span>No</span>
                            </label>
                    </div>
                <div>Do juvenile courts have jurisdiction over 17-year-olds
                    <label>
                        <input type="radio" value="1" formControlName="is17YearsOld">
                          <span>Yes</span>
                      </label>
                      <label>
                        <input type="radio" value="0" formControlName="is17YearsOld">
                          <span>No</span>
                      </label>
                </div>
                <div>
                    <label for="txt-role">What is the starting month of your fiscal year?</label>
                    <select
                        formControlName="fiscalYearStart" class="form-control" required>
                        <option *ngFor="let m of monthVals" [value]="m">
                            {{Month2LabelMapping[m]}} </option>
                    </select>
                </div>
                <label  for="txtPTSince">Youth Justice Initiatives </label>
                <div>
                                    <ng-multiselect-dropdown formControlName="youthJusticeInitiatives"
                                    [placeholder]="'Select Youth Justice Initiatives'"
                                    [settings]="dropdownSettings"
                                    [data]="dropdownList"
                                    [(ngModel)]="selectedItems"
                                    (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="onItemDeSelect($event)"
                            >
                        </ng-multiselect-dropdown>
                </div>
                <div>Site Image</div>
                <div class="siteImgPreview img" *ngIf="siteImageURL!=null">Preview Uploaded Image
                    <img [src]="siteImageURL" alt="Site Image" class="img-top" >
                  </div>
                <div>
                    <input formControlName="siteImage" id="siteImage" type="file" class="form-control"  (change)="onFileChange($event)">
                </div>
        </form>
    </div>    
    <div class="col-md-4 px-5">
        <button type="button" (click) = "cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateSite()" [disabled]="isSiteExists || siteForm.invalid">{{buttonText}}</button>
    </div>
    <div *ngIf="isSiteExists" class="site-exist-error-container">
        <span class="alert alert-danger">Site name already exists.</span>
    </div>
  </div>
<div class="backdrop" *ngIf="isAddOpen"></div>

<app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <div class="col-sm-12 content-style">
            <div *ngIf="dataItem">
                <span>Are you sure you want to delete {{dataItem.deleteSite.name}}?</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="confirmSiteToRemove(dataItem.deleteSite)">
                    <span>Ok</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<app-modal #warningModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate>
        <div class="col-sm-12 content-style">
            <div>
                <span>You cannot delete this site because measures are associated</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeWarningModal()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<div class="add-form" *ngIf="isAddDataCollectionOpen">
    <a class="close" (click)="cancelAddDC()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <form [formGroup]="dataCollectionForm">
                <div>Data Collection </div>
                    <div>
                        <textarea id="txtdataCollection" type="text" name="txtdataCollection" placeholder="Data Collection"  autocomplete="new-txtfname" 
                        [ngClass]="{'input-error': dataCollectionForm.controls.dataCollection.touched && dataCollectionForm.controls.dataCollection.status=='INVALID'}"
                        formControlName="dataCollection" class="form-control" required ></textarea>
                    </div>
        </form>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateDataCollection()" [disabled]="dataCollectionForm.controls.dataCollection.status=='INVALID'">Save</button>
    </div>
  </div>

</div>
<div class="backdrop" *ngIf="isAddDataCollectionOpen"></div>