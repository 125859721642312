<div class="container manage-users">
    <div class="heading d-flex">
        <div class="page-title">
            <div>
                <span class="page-title">
                    <h1>Manage Data Collections - {{selectedSite.name}}</h1>
                </span>
            </div>
        </div>
        
        <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
            <div class="col-md-12">
                <div class="alert alert-danger" id="formSubmitError">
                    {{ formSubmitError }}
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-1 search_icon_profile">
            <i class="fas fa-search"></i>
        </div>
        <div class="col-md-8 searchBoxForm_profile">
            <input type="text" id="searchTerm" name="searchTerm" placeholder="Search site measures..."
                autocomplete="off" [(ngModel)]="searchTerm" (keyup)="search()" class="searchBox_Profile" />
        </div>
        <div class="col-md-3">
            <button type="button" [ngClass]="{ 'disabled':  viewAsAdmin }" id="btnSiteResponse"
                class="btn btn-success datacollection" (click)="openSiteResponse()">How are Race and Ethnicity
                Determined?</button>
        </div>
    </div>
    <br>
    <div>
        <accordion [closeOthers]="true">
            <ng-template ngFor let-setItem let-setIndex="index" [ngForOf]="groupList">
                <accordion-group #grp *ngIf="setItem.groupMeasures && setItem.groupMeasures.length > 0">
                    <div accordion-heading class="tab">
                        <div class="tab-label">
                            <div class="tab-label-text">
                                <div class="col-sm-8">
                                    <span class="fullName"><span><b>Group Order :</b>
                                            {{padLeadingZeros(setItem?.order,2)}}</span></span>
                                    <span class="fullName"><span><b>Group Name :</b> {{setItem?.name}}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 table-responsive">
                            <table class="table table-striped sortable" id="printableArea">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Number</th>
                                        <th>Measure</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let measure of setItem.groupMeasures; let rowIndex=index;">
                                        <td title="edit site measure data">
                                            <div class="delete-icon">
                                                <span class="role-icon" (click)="editDataCollections(measure)">
                                                    <em class="far fa-edit"></em>
                                                </span>
                                            </div>
                                        </td>
                                        <td>{{padLeadingZeros(measure.measure.number,2)}}</td>
                                        <td title="{{measure.measure.definition}}&#013; {{measure.siteDefinition}}">
                                            {{measure.measure.measure}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </accordion-group>
            </ng-template>
        </accordion>
    </div>


    <div class="row">
        <div class="col-md-3 padding-2p">
            <button type="button" [ngClass]="{ 'disabled':  viewAsAdmin }" id="btnExportSiteMeasureData"
                class="btn btn-success datacollection" (click)="exportSiteMeasureData()">Export Site Data</button>
        </div>
        <div class="col-md-3 padding-2p">
            <button type="button" *ngIf="isAdmin || isDataLead" [ngClass]="{ 'disabled':  viewAsAdmin }"
                id="btnImportSiteMeasureData" class="btn btn-success datacollection"
                (click)="openImportSiteDataConfirmationModal()">Import Site Data</button>
        </div>
        <div class="col-md-3 padding-2p">
            <button type="button" *ngIf="(isAdmin || isDataLead) && hasImportJobs"
                [ngClass]="{ 'disabled':  viewAsAdmin }" id="btnImportSiteMeasureDataApprove"
                class="btn btn-success datacollection" [routerLink]="['/measureApprove']">Approve Site Data</button>
        </div>
        <div class="col-md-3 pull-right padding-2p">
            <div class="col-xs-6 px-5">
                <button type="button" id="btncancel" class="btn btn-secondary button-style back-button"
                    [routerLink]="['/']">Cancel</button>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="manageSiteMeasures?.length===0">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <p>No Measure Found.</p>
        </div>
        <div class="col-md-4"></div>

    </div>


    <div class="add-form1" *ngIf="isAddOpen">
        <a class="close" (click)="closeAddForm()"><i class="fa fa-times"></i></a>
        <div class="form-group">
            <form [formGroup]="dataCollectionsForm">
                <div>Manage Data Collection</div>
                <div class="clearfix">
                    <div class="pull-right">
                        <div *ngIf="formSubmitSuccess">
                            <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000 (dismiss)="onSuccessDismiss()">
                                {{formSubmitSuccess}}
                            </alert>
                        </div>
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-md-6">
                        <div>
                            <label for="txt-role">For this measure, our data system treats Latino as: </label>
                            <br>
                            <input formControlName="latino" type="radio" id="Race" value="Race" (change)="hideEthnicity();">
                            <label for="race">Race</label>
                            <input formControlName="latino" type="radio" id="Ethnicity" value="Ethnicity"
                                (change)="showEthnicity();">
                            <label for="ethnicity">Ethnicity</label>
                            <input formControlName="latino" type="radio" id="Both" value="Both" (change)="showEthnicity();">
                            <label for="both">Both</label>
                        </div>
                        <div>
                            <label for="txt-role">For the selected year, enter data as: </label>
                            <br>
                            <div class = "radioGroup" *ngFor="let tp of timePeriodTypes | keyvalue : originalOrder" >
                                <label for="{{tp.key}}">
                                <input formControlName="timePeriodType" type="radio" id="{{tp.key}}" name = "timePeriodType" [(ngModel)]="selectedTimePeriodType" [checked]="selectedTimePeriodType==tp.value" [value]="tp.value" (change)="changeTimePeriodType();" >
                                    {{tp.value}}</label>
                            </div>
                        </div> 
                    </div>
                    <div class="col-md-4 pull-right padding-2p">
                        <div class="col-xs-6 px-5">
                            <label for="txt-role">Fiscal Year</label>
                            <div>
                                <select [(ngModel)]="selectedYear" formControlName="year" class="form-control" required
                                    (change)="changeFiscalYear();">
                                    <option *ngFor="let site of siteYears;let rowIndex=index;" value="{{site.year}}">
                                        {{site.year}} </option>
                                </select>
                            </div>
                            <div *ngIf = "selectedTimePeriodType===timePeriodTypes.Quarterly">
                                <label for="txt-role">Quarter</label>
                                <select [(ngModel)]="selectedTimePeriod" 
                                    formControlName="timePeriod" class="form-control" required (change)="changeData();">
                                    <option *ngFor="let q of quarterVals" [value]="q">
                                        {{Quarter2LabelMapping[q]}} </option>
                                </select>
                            </div>
                            <div *ngIf = "selectedTimePeriodType===timePeriodTypes.Monthly">
                                <label for="txt-role">Month</label>
                                <select [(ngModel)]="selectedTimePeriod" 
                                    formControlName="timePeriod" class="form-control" required (change)="changeData();">
                                    <option *ngFor="let m of monthVals" [value]="m">
                                        {{Month2LabelMapping[m]}} </option>
                                </select>
                            </div>                            
                        </div>
                    </div>
                   
                    <!-- <div class="col-md-4 pull-right padding-2p">
                        <div class="col-xs-10 px-5" *ngIf="isAdult">
                            <label for="txt-role">Data is for System </label>
                            <select id="ageGroup" formControlName="ageGroup" class="form-control" [(ngModel)]="selectedAgeGroup">
                                <option value = "Juvenile" hidden></option>
                                <option *ngFor="let opt of ageGroupOptions" value="{{opt.value}}">{{opt.label}}</option>
                            </select>
                        </div>
                    </div> -->
                </div>
                <div *ngIf="true">
                    <table id='tbldatacollection' style="border: 1px solid black;">
                        <tr style="background-color: silver;">
                            <th style="border-left: 1px solid black;text-align: left;padding: 8px; width:50%; "></th>
                            <th class="header-col0">{{manageDataCollections[dataCollectionsCount-3].calendarDisplay}}</th>
                            <th class="header-col1">{{manageDataCollections[dataCollectionsCount-2].calendarDisplay}}</th>
                            <th class="header-col2">{{manageDataCollections[dataCollectionsCount-1].calendarDisplay}}</th>
                        </tr>

                        <tr *ngIf="this.selectedMeasure.group.order==10"
                            style="background-color: rgb(142, 177, 255);">
                            <td style=" border-left: 1px solid black;text-align: left;padding: 8px;"
                                title="{{selectedMeasure.measure.definition}}&#013; {{selectedMeasure.siteDefinition}} ">
                                Overall {{selectedMeasure.measure.measure}}
                            </td>
                            <td class="dataRow">
                            </td>
                            <td class="dataRow">
                            </td>
                            <td class="dataRow">
                                <input formControlName="total" id="txtTotal" type="number" class="form-control" step="1"
                                    required>
                            </td>
                        </tr>

                        <tr *ngIf="this.selectedMeasure.group.order !== 10"
                            style="background-color: rgb(142, 177, 255);">
                            <td style=" border-left: 1px solid black;text-align: left;padding: 8px;"
                                title="{{selectedMeasure.measure.definition}}&#013; {{selectedMeasure.siteDefinition}}">
                                Overall {{selectedMeasure.measure.measure}} </td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="dataRow">
                                {{manageDataCollections[dataCollectionsCount-3].total | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="dataRow">
                                {{manageDataCollections[dataCollectionsCount-2].total | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="dataRow">
                                {{dataCollectionsForm.get('total').value | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="dataRow">
                                {{manageDataCollections[dataCollectionsCount-3].total | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="dataRow">
                                {{manageDataCollections[dataCollectionsCount-2].total | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="dataRow">
                                {{dataCollectionsForm.get('total').value | number : '0.0-2'}}</td>
                        </tr>

                        <tr style="background-color: whitesmoke">
                            <td class="genderRow">Gender</td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="genderRow"></td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="genderRow"></td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="genderRow"></td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="genderRow">
                                {{manageDataCollections[dataCollectionsCount-3].genderValue | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="genderRow">
                                {{manageDataCollections[dataCollectionsCount-2].genderValue | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="genderRow">
                                {{dataCollectionsForm.get('genderValue').value | number : '0.0-2'}}</td>
                           
                        </tr>

                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Female</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-3].genderFemale}}</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-2].genderFemale}}</td>
                            <td class="dataRow">
                                <input formControlName="genderFemale" id="txtfemale" type="number" class="form-control"
                                    value="test123" step="1" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('genderFemale')?.errors?.femaleIsGreaterThanMale ||  dataCollectionsForm.get('genderFemale')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                                <span class="text-danger"
                                    *ngIf="dataCollectionsForm.get('genderFemale')?.errors?.femaleIsGreaterThanMale">
                                    Female should not be greater than male
                                </span>
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Male</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].genderMale}}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].genderMale}}</td>
                            <td class="dataRow">
                                <input formControlName="genderMale" id="txtmale" type="number" class="form-control"
                                    value="" step="1" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('genderMale')?.errors?.femaleIsGreaterThanMale ||  dataCollectionsForm.get('genderMale')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Non-Binary</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].genderNonBinary}}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].genderNonBinary}}</td>
                            <td class="dataRow">
                                <input formControlName="genderNonBinary" id="txtNonBinary" type="number"
                                    class="form-control" step="1" value="" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('genderNonBinary')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Not Available</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].genderNA }}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].genderNA }}</td>
                            <td class="dataRow">
                                <input formControlName="genderNA" id="txtgenderNotAvailable" type="number"
                                    class="form-control" value="0" step="1" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('genderNA')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke" *ngIf="isEthnicityRequired">
                            <td class="ethnicityRow">Ethnicity</td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="ethnicityRow-old"></td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="ethnicityRow-old"></td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="ethnicityRow-old"></td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="ethnicityRow-old">
                                {{manageDataCollections[dataCollectionsCount-3].ethnicityValue | number : '0.0-2' }}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="ethnicityRow-old">
                                {{manageDataCollections[dataCollectionsCount-2].ethnicityValue | number : '0.0-2' }}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="ethnicityRow">
                                {{dataCollectionsForm.get('ethnicityValue').value | number : '0.0-2' }}</td>
                        </tr>
                        <tr style="background-color: whitesmoke" *ngIf="isEthnicityRequired">
                            <td class="dataRow">Hispanic</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].ethnicityHispanic}}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].ethnicityHispanic}}</td>
                            <td class="dataRow">
                                <input formControlName="ethnicityHispanic" id="txthispanic" type="number"
                                    class="form-control" value="" required
                                    [ngClass]="{'disable': (!(isAdmin || isDataLead) || viewAsAdmin)}">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke" *ngIf="isEthnicityRequired">
                            <td class="dataRow">Non-Hispanic</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].ethnicityNonHispanic}}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].ethnicityNonHispanic}}</td>
                            <td class="dataRow">
                                <input formControlName="ethnicityNonHispanic" id="txtnonHispanic" type="number"
                                    class="form-control" value="209" required
                                    [ngClass]="{'disable': (!(isAdmin || isDataLead) || viewAsAdmin)}">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke" *ngIf="isEthnicityRequired">
                            <td class="dataRow">Not Available</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].ethnicityNA}}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].ethnicityNA}}</td>
                            <td class="dataRow">
                                <input formControlName="ethnicityNA" id="ethnicityNotAvailable" type="number"
                                    class="form-control" value="0" required
                                    [ngClass]="{'disable': (!(isAdmin || isDataLead) || viewAsAdmin)}">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="raceRow">Race</td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="raceRow-old"></td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="raceRow-old"></td>
                            <td *ngIf="this.selectedMeasure.group.order==10" class="raceRow-old"></td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="raceRow-old">
                                {{manageDataCollections[dataCollectionsCount-3].raceValue | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="raceRow-old">
                                {{manageDataCollections[dataCollectionsCount-2].raceValue | number : '0.0-2'}}</td>
                            <td *ngIf="this.selectedMeasure.group.order!=10" class="raceRow">
                                {{dataCollectionsForm.get('raceValue').value | number : '0.0-2'}}</td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Amer Indian/AK Native</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].raceAIAKNative}}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].raceAIAKNative}}</td>
                            <td class="dataRow">
                                <input formControlName="raceAIAKNative" id="txtamerIndian" type="number"
                                    class="form-control" value="0" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('raceAIAKNative')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Asian</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-3].raceAsian}}</td>
                            <td class="dataRow-old">{{manageDataCollections[dataCollectionsCount-2].raceAsian}}</td>
                            <td class="dataRow">
                                <input formControlName="raceAsian" id="txtasian" type="number" class="form-control"
                                    value="0" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('raceAsian')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Black/ African American </td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-3].raceBAA}}</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-2].raceBAA}}</td>
                            <td class="dataRow">
                                <input formControlName="raceBAA" id="txtblack" type="number" class="form-control"
                                    value="204" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('raceBAA')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Latino/Hispanic</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-3].raceHispanic}}</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-2].raceHispanic}}</td>
                            <td class="dataRow">
                                <input formControlName="raceHispanic" id="txtlatino" type="number" class="form-control"
                                    value="0" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('raceHispanic')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">White</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-3].raceWhite}}</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-2].raceWhite}}</td>
                            <td class="dataRow">
                                <input formControlName="raceWhite" id="txtwhite" type="number" class="form-control"
                                    value="" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('raceWhite')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Other Race </td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-3].raceOther}}</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-2].raceOther}}</td>
                            <td class="dataRow">
                                <input formControlName="raceOther" id="txtotherRace" type="number" class="form-control"
                                    value="0" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('raceOther')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Two or more Races</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-3].racesTwoOrMore}}</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-2].racesTwoOrMore}}</td>
                            <td class="dataRow">
                                <input formControlName="racesTwoOrMore" id="txttwoOrMoreRacesr" type="number"
                                    class="form-control" value="0" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('racesTwoOrMore')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                        <tr style="background-color: whitesmoke">
                            <td class="dataRow">Not Available </td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-3].racesNA}}</td>
                            <td class="dataRow">{{manageDataCollections[dataCollectionsCount-2].racesNA}}</td>
                            <td class="dataRow">
                                <input formControlName="racesNA" id="txtraceNotAvailable" type="number"
                                    class="form-control" value="0" required
                                    [ngClass]="{'invalid-input': dataCollectionsForm.get('racesNA')?.errors?.genderAndRaceNotEqual, 'disable': (!(isAdmin || isDataLead) || viewAsAdmin) }">
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-2 px-5 pull-right padding-2p" *ngIf="isAdmin || isDataLead">
                        <button type="button" class="btn btn-success" (click)="AddUpdateDataCollections(false)"
                            [ngClass]="{ 'hidden':  viewAsAdmin }">Save</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="row" *ngIf="manageMeasure?.length>0">
            <div class="col-md-4 pull-right padding-2p">
                <div class="col-xs-6 px-5">
                    <button type="button" id="btncancel" class="btn btn-secondary button-style back-button"
                        [routerLink]="['/']">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <div class="backdrop" *ngIf="isAddOpen"></div>

    <div class="add-form" *ngIf="isAddSiteResponseOpen">
        <a class="close" (click)="cancelAddSR()"><i class="fa fa-times"></i></a>
        <div class="form-group">
            <form [formGroup]="siteResponseForm">
                <div>Site Response </div>
                <div>How is race and ethnicity information collected? Is it self reported?</div>
                <div>
                    <textarea id="txtsiteResponse" type="text" name="txtsiteResponse" placeholder="Site Response"
                        autocomplete="new-txtfname"
                        [ngClass]="{'input-error': siteResponseForm.controls.siteResponse.touched && siteResponseForm.controls.siteResponse.status=='INVALID', 'disable': (!(isAdmin || isDataLead) || viewAsAdmin)}"
                        formControlName="siteResponse" class="form-control" required></textarea>
                </div>
            </form>
        </div>
        <div class="col-md-4 px-5">
            <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateSiteResponse()"
                [ngClass]="{ 'disabled':  viewAsAdmin }"
                [disabled]="siteResponseForm.controls.siteResponse.status=='INVALID'">Save</button>
        </div>
    </div>

</div>
<div class="backdrop" *ngIf="isAddSiteResponseOpen"></div>

<app-modal #confirmationModal contentStyle="role-delete-modal" [showClose]=false [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem?.entityData">
        <div class="col-sm-12 content-style">
            <div>
                <span>Do you want to save your changes before changing time periods?</span>
            </div>
        </div>
        <div class="row footer-style">
            <div class="col-md-2"></div>
            <div class="col-md-2">
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal(true)">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="col-md-3">
                <button type="submit" class="btn button-style discardContinue" (click)="discardAndContinue()">
                    <span>Discard & Continue</span>
                </button>
            </div>
            <div class="col-md-3">
                <button type="submit" class="btn button-style saveContinue" (click)="saveAndContinue()">
                    <span>Save & Continue</span>
                </button>
            </div>
            <div class="col-md-2"></div>
        </div>
    </ng-template>
</app-modal>

<app-modal #confirmDeleteDataModal contentStyle="role-delete-modal"  [showClose]=false [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem?.entityData">
        <div class="col-sm-12 content-style">
            <div>
                <span>Are you sure you want to delete this row (all inputs are blank)?</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmDeleteDataModal(true)">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="continueToDelete()"
                    [ngClass]="{ 'disabled':  viewAsAdmin }">
                    <span>Delete</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<app-modal #hardstopModal contentStyle="role-delete-modal"  [showClose]=false [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem?.entityData">
        <div class="col-sm-12 content-style">
            <div>
                <span>{{validationRule.validationMessage}}</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeHardStopModal()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<app-modal #areyousureModal contentStyle="role-delete-modal"  [showClose]=false [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem?.entityData">
        <div class="col-sm-12 content-style">
            <div>
                <span>{{validationRule.validationMessage}}</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeAreyouSureModal(true)">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="continueToSave()"
                    [ngClass]="{ 'disabled':  viewAsAdmin }">
                    <span>Continue</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<app-modal #warnOverwriteDataModal contentStyle="role-delete-modal" [showClose]=false [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem?.entityData">
        <div class="col-sm-12 content-style">
            <div>
                <span>{{validationRule.validationMessage}}</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeWarnOverwriteDataModal()">
                    <span>OK</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>

<app-modal #importSiteDataconfirmationModal contentStyle="delete-modal" [showFooter]=false
    class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem?.entityData">
        <app-import-data-model (closeModal)="closeImportSiteDataConfirmModal($event)"></app-import-data-model>
    </ng-template>
</app-modal>
