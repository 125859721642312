import { Injectable } from '@angular/core';

const APP_PREFIX = '';

@Injectable()
export class LocalStorageService {

  constructor() {}

  setItem(key: string, value: string): void {
    if (value !== undefined && value !== '' && value.length > 0) {
      window.localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value).slice(1, -1));
    }
  }

  getItem(key: string): any {
    const returnValue = window.localStorage.getItem(`${APP_PREFIX}${key}`);

    if (returnValue === null) {
      return null;
    } else {
      return returnValue;
    }
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(`${APP_PREFIX}${key}`);
  }
}
