import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ftla-landing',
  styleUrls: [],
  template: `
    <div class="prelogin-ftla-imgcontainer">
    </div>`
})
export class FtlaLandingComponent implements OnInit {

  constructor(private titleService: Title, private router : Router) { }

  ngOnInit() {
    this.titleService.setTitle(environment.ftlaPageTitle);
    this.router.navigate(['/ftlahome']);
  }

}