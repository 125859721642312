import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AccountService } from '../shared/account.service';
import { BulkUploadUserStatus } from '../shared/user.model';

@Component({
  selector: 'app-user-bluk-upload',
  templateUrl: './user-bluk-upload.component.html',
  styleUrls: ['./user-bluk-upload.component.scss']
})
export class UserBlukUploadComponent implements OnInit {
  @ViewChild('confirmationModal', { static: false }) confirmationModalComponent: ModalComponent;
  @ViewChild('warningModal', { static: false }) warningModal: ModalComponent;
  bulkuploadForm: FormGroup;
  extentionRegEx = /(?:\.([^.]+))?$/;
  message: string;
  uploadContentType = 'files';
  showQueueAlert = false;
  importUserStatus:BulkUploadUserStatus;
  uploader: FileUploader;
  filename:string;

  constructor(private router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
    private accountServices: AccountService)
  {
  }

  ngOnInit(): void {
      this.bulkuploadForm = new FormGroup({
        file:new FormControl('', Validators.required),
      });
  }
initUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.bulkuploadForm.get('file').setValue(file);
      this.bulkuploadForm.get('file').updateValueAndValidity();
      console.log(file);
      this.filename = file.name;
    }
  }

  get formFields(): FormArray {
    return this.bulkuploadForm.get('file') as FormArray;
  }
  
  uploadFile(){
    this.accountServices.usersBulkUpload(this.bulkuploadForm.get('file').value)
      .subscribe(data=>{
          console.log('File upload success');
          console.log(data);
          this.importUserStatus = data.body;
          this.bulkuploadForm.reset();
    },
    err=>{
      console.log('File upload faile', err);
    });
  }

  downloadUserBulkUploadTemplate(){
      window.open('https://aecfdevstorage.blob.core.windows.net/jjdata-resources/JJData_Users_Bulk_Upload_Template.xlsx');
  }

  downloadUserBulkUploadInstructions(){
    window.open('https://aecfdevstorage.blob.core.windows.net/jjdata-resources/Bulk Invite Upload Instructions.docx');    
}

ApproveRejectUsers(isApproved){
  this.accountServices.ApproveRejectStagingUsers(isApproved).subscribe(x=>{
    this.openWarningModal();
    this.router.navigate(['/users']);
  });
}

  ApproveRejectUsers1(isApproved):void{
    this.accountServices.ApproveRejectStagingUsers(isApproved).subscribe(x=>{
      this.openConfirmationModal();
      this.router.navigate(['/users']);
    });
  }

  private formatBytes(bytes, decimals?) {
    if (bytes === 0) { return '0 Bytes'; }
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onClosedAlert() {
    this.showQueueAlert = false;
  }

  openWarningModal() {
    if (this.warningModal) {
      this.warningModal.showModal();
    }
  }

  closeWarningModal() {
    if (this.warningModal) {
      this.warningModal.closeModalWindow();
    }
  }

  openConfirmationModal() {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.showModal();
    }
  }

  closeConfirmationModal() {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }
}


