import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ActivatedRoute, Router } from '@angular/router';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NavigationRoute, Features, Groups } from 'src/app/core/navigations/navigation-settings.model';
import { NavigationService } from 'src/app/core/navigations/navigation.service';
import { LocalStorageService } from 'src/app/core/state-manager/local-storage/local-storage.service';
import { CommonEntityService } from '../services/common-entity.service';
import { SystemPermissions } from '../../permissions';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { compareItems } from '../models/utility';
import { ReportService } from 'src/app/reports/shared/report.service';
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  userRole: Roles;
  userDetail: any = null;
  showDashboardLink = false;
  homeRoutes = ['reportinghome', 'allhome', 'visionhome', 'jdaihome', 'ftlahome', 'jcyoihome', 'home','grantshome','jjhome'];
  adminRoutes = ['/content/manage', '/roles', '/reportassignment', '/newsfeed', '/audit', '/admin/Analytics', '/permissions','/sites','/groups','/measures','/sitemeasures','/manageDataCollections','/organizationProfile','/technologies'];
  favRoutes = ['/savedfavorites', '/sharedresults', '/sharedwithme', '/shareddocuments'];
  resourceRoutes = ['/content', '/content/remove'];
  ftlaReportRoutes = ['/admin/ftlareport', '/admin/ftla-vendor', '/admin-grants'];
  filesRoutes = ['/boxview', '/dropboxview'];
  dashboardRoutes = ['/dashboard/'];
  dashboardOptionRoutes = ['/dashboard/'];
  configRoutes = ['/okta', '/title', '/metatags'];
  dashboardLinks = [];
  portals = [];
  hasReports$: Observable<boolean>;
  navigationRoutedata: NavigationRoute;
  Features: any;
  isSLT = false;
  loginType: string;
  showNavigationSupport = false;
  isAllGroup = false;
  isJcyoiGroup = false;
  isKmsGroup =false;
  isFTLAGroup =false;
  isVisionGroup= false;
  isJJDataGroup=false;
  isThriveGroup = false;
  permissions = SystemPermissions;
  isCollapsed = true;
  isReportingGroup: Boolean =false;
  memberslist: any;
  foundations:string[];
  currentUserRole:string;
  sessionStorage: any;
  measureLinks = [];
  surveyLinks = [];
  showDialog:boolean;
  showJJReportOption:boolean;
  constructor(public currentState: StateService,
              private router: Router,
              private repository: RepositoryService,
              private navigationService: NavigationService,
              private storageService: LocalStorageService,
              private commonService: CommonEntityService,
              private route: ActivatedRoute,
              private reportService: ReportService,
              public googleAnalyticsService: GoogleAnalyticsService                           
             ) {
    this.Features = Features;
    this.loginType = this.storageService.getItem('LoginType');
   
  }

  get isUser() { return this.userRole === Roles.User; }

  get isAdmin() { return this.userRole === Roles.Admin; }

  get isModerator() { return this.userRole === Roles.Moderator; }

  get isSuperAdmin() { return this.userRole === Roles.SuperAdmin; }

  get isCaseyConsultant() { return this.userRole === Roles.CaseyConsultant; }

  get isState() { return this.userRole === Roles.State; }

  get isProbationChief() { return this.userRole === Roles.ProbationChief; }

  get isOtherLocalLeader() { return this.userRole === Roles.OtherLocalLeader; }

  get isDataLead() { return this.userRole === Roles.DataLead; }

  get isPartners() { return this.currentState.isKmsGroup() && (!this.userDetail ||  this.userDetail.role.name === 'Partners'); }

  get isFtla() {return this.currentState.isFTLAGroup(); }

  get isPortalConfig() {return this.currentState.isPortalConfigGroup();}

  get currentRole() { if(this.userDetail!==null) { return this.userDetail['role']?.description;}}

  ischeckPermissions(permission: string): boolean {
    return this.currentState.permissionCheck(permission);
  }

  isActive(routes: string[]): boolean {
    return routes.some(route => this.router.isActive(route, true));
  }
exportReport(value:string){
  let filters=[];
  this.route.params.subscribe(params => {
    const reportName = localStorage.getItem('reportName');
    //const fileContentType = value ==='PDF' ? 'application/pdf': 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    if(value ==='PDF' || value==='PPTX') {
        let exportReportParameters ={ reportName : reportName, format : value, filters : filters};
        this.reportService.exportReport(exportReportParameters).subscribe(res => {
        this.showDialog=true;
    }, error => {  });
            this.googleAnalyticsService
                .eventEmitter('Export Report', reportName, 'Report');
    }
});   
}
cancelDialog():void{
  this.showDialog=false;
 }
  isDashboardActive(dashboard): boolean {
    if (dashboard && dashboard.reportType !== 'Dashboard') {
      return this.router.isActive('/dashboard/' + dashboard.reportName, true);
    } else {
      return this.router.isActive('/dashboard/', true);
    }
  }

  ngOnInit() {
    this.isKmsGroup =this.currentState.isKmsGroup();
    this.isAllGroup = this.currentState.isAllGroup();
    this.isJcyoiGroup = this.currentState.isJCYOIGroup();
    this.isFTLAGroup =this.currentState.isFTLAGroup();
    this.isJJDataGroup =this.currentState.isJJGroup();
    this.isThriveGroup = this.currentState.isThriveGroup();
    this.currentUserRole= this.currentState.getUserData()?.roleName;
    //console.log(this.sessionStorage.getItem('user'));
    this.showJJReportOption =false;
    this.isReportingGroup = this.currentState.isReportingGroup();
    if (this.isAllGroup) {
      this.adminRoutes.push('/content');
    }
    if (this.currentState.getUserData()) {
      this.getSessionData();
    }
    this.currentState.getSessionEvent().subscribe(() => {
      this.getSessionData();
      if(this.isFTLAGroup)
        this.ftlaMembers();
  });
  
    this.commonService.getViewUserEventEmitter().subscribe(data => this.setViewUserInfo(data));
    this.hasReports$ = this.getUserReportAccess();
    this.showNavigationSupport = this.currentState.isAllGroup() 
                                  || this.currentState.isJDAIGroup()
                                  || this.currentState.isThriveGroup() 
                                  || this.currentState.isFTLAGroup() 
                                  || this.currentState.isPortalConfigGroup();
  }

  getSessionData() {
    const group = this.currentState.getGroupSession();
    this.navigationRoutedata = this.navigationService.getCurrentnavigationRoutes(Groups[group]);
    if (this.showNavigation) {
      this.setViewUserInfo(this.currentState.isViewAsAdmin());
    }
  }

   ftlaMembers(){
    this.commonService.getFTLAProfiles().subscribe(data => {
      this.memberslist = data;
      this.memberslist.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
      return this.memberslist;
    });
  }

  get showNavigation() {
    return ((this.currentState.isTokenValid  ||
           (this.currentState.isPortalConfigGroup() && window.sessionStorage.getItem('id_token')!==null)) && 
           !this.currentState.isUnAuthorized() && this.currentState.IsUserActive());
  }

  setViewUserInfo(isView) {
    if (this.showNavigation) {
      this.setRole();
      if (isView) {
        this.userRole = this.currentState.getViewUserRole();
        this.userDetail = this.currentState.getViewingUserData();
      } else {
        this.userRole = this.currentState.getRole();
        this.userDetail = this.currentState.getUserData();
      }
      this.hasReports$ = this.getUserReportAccess();
      this.showDashboard();
      this.portals =this.userDetail?.communityPortals ?? [];
    }
  }

  navigateAllLink() {
      this.googleAnalyticsService.eventEmitter('Community Link', 'ALL', 'Navigation');
      window.open('https://community.aecf.org/community/advocacy-learning-lab', '_blank');
  }

  navigateExternal(url){
    window.open(url, '_blank');
  }

  NavigateJiveLink():void  {
    if (this.isJcyoiGroup) {
      this.googleAnalyticsService.eventEmitter('Jive Link', 'JCYOI', 'Navigation');
      window.open('https://community.aecf.org/community/jimcasey?fromLogin=true', '_blank');
    }
  }

  NavigateSupportLink() {
    
    if(this.currentState.isGrantsGroup()){
      this.googleAnalyticsService.eventEmitter('Support Link', 'Reporting', 'Navigation');
      window.open('mailto:helpdesk@aecf.org', '_blank');
    }
    else if(this.isJJDataGroup  || this.isFTLAGroup ){
      this.googleAnalyticsService.eventEmitter('Support Link', 'JJData', 'Navigation');
      window.open('https://programmaticportalsupport.zendesk.com/hc/en-us/requests/new');
      return;
    }
   else if (this.isJcyoiGroup || this.isVisionGroup) {
      this.googleAnalyticsService.eventEmitter('Support Link', 'JCYOI', 'Navigation');
      window.open('https://aecfpartners.zendesk.com/hc/en-us');
    } else if (this.currentState.isReportingGroup() || this.currentState.isKmsGroup()) {
      this.googleAnalyticsService.eventEmitter('Support Link', 'Reporting', 'Navigation');
      window.open('mailto:helpdesk@aecf.org', '_blank');
    }  else {
      if (!this.userDetail || this.userDetail.role.name === 'Partners') {
        this.googleAnalyticsService.eventEmitter('Support Link', 'Partners', 'Navigation');
        window.open('https://aecfpartners.zendesk.com/hc/en-us');
      } else {
        this.googleAnalyticsService.eventEmitter('Support Link', 'Fresh Service', 'Navigation');
        window.open('https://aecfpartners.zendesk.com/hc/en-us', '_blank');
      }
    }
  }

  NavigateJdaiLink(){
    window.open('https://www.aecf.org/work/juvenile-justice/jdai/jdaiconnect', '_blank');
  }
  NavigateJdaikidscountLink(){
    window.open('https://datacenter.kidscount.org/', '_blank');
  }
  NavigateProbationTransformation(){
    window.open('https://www.aecf.org/topics/juvenile-probation', '_blank');
  }
  NavigateDashboardTipSheets(){
    window.open('https://www.aecf.org/work/juvenile-justice/jdai/pro-data/pro-data-resources', '_blank');
  }

  setRole() {
    if (this.userDetail) {
      this.isSLT = this.userDetail.isSLT;
    }
  }

  showDashboard() {
    if (this.userDetail && this.userDetail.reports) {
      this.dashboardLinks = this.userDetail.reports;
      if (this.isJJDataGroup) {
        this.dashboardLinks = this.dashboardLinks.slice(0,2);
        if (this.isDataLead || this.isOtherLocalLeader) {
          var surveyreport = this.dashboardLinks.filter(x=>x.reportName!='Probation Practice Survey Dashboard'); 
          this.dashboardLinks = surveyreport;
        } 
      } else {
      this.dashboardLinks = this.dashboardLinks;
      }
      this.dashboardLinks.forEach(data => {
        this.dashboardRoutes.push('/dashboard/' + data.reportName);
      });

      if(this.isJJDataGroup){
       this.surveyLinks= this.dashboardLinks.filter(x=>{ return x.reportName.toLowerCase().indexOf('survey') !== -1 });
        this.measureLinks= this.dashboardLinks.filter(x=>{return x.reportName.toLowerCase().indexOf('survey') == -1});
      }

      this.showDashboardLink = this.dashboardLinks && this.dashboardLinks.length > 0;
    }
  }

  IsFeatureSupported(feature) {
    if(this.isPortalConfig){
      return false;
    }
    else if (this.navigationRoutedata && this.navigationRoutedata.supportedFeatures) {
      return this.navigationRoutedata.supportedFeatures.some(x => x.name === feature);
    }

    return false;
  }

  navigateToHome(newPage?: boolean) {
    if (newPage) {
      window.open(localStorage.getItem('rootRoute'), '_blank');
    } else {
      this.navigateToRoutes(localStorage.getItem('rootRoute'));
    }
  }

  navigateToRoutes(route: string) {
    this.router.navigate([route]);
  }

  gotoDashbaord(reportLink) {
     document.getElementById('appbody').click();
    if (reportLink && reportLink.reportType !== 'Dashboard') {
      this.googleAnalyticsService.eventEmitter('Menu Link', reportLink.reportName, 'Report');
      this.router.navigate(['/dashboard/' + reportLink.reportName]);
    } else {
      this.router.navigate(['/dashboard']);
    }
    this.showJJReportOption = this.isJJDataGroup;
  }

  showDefinationReport() {
    var defReport = this.userDetail.reports.filter(x=>{ return x.reportName.toLowerCase().indexOf('defintions') !== -1 });
    
   if (defReport !== null || defReport != undefined) {
    console.log(defReport);
    console.log(defReport[0].reportName);
     this.router.navigate(['/dashboard/' + defReport[0].reportName]);
   }
   this.showJJReportOption = this.isJJDataGroup;
 }

  NavigatFaqLink(){
    this.router.navigate(['/newfaq']);
  }

  gotoDashbaordStateSite(reportLink) {
    if (reportLink && reportLink.reportType !== 'Dashboard') {
      this.googleAnalyticsService.eventEmitter('Menu Link', reportLink.reportName, 'Report');
      this.router.navigate(['/dashboard/' + reportLink.reportName+'/statesite']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  navigateBoxLink(){
    window.open('https://app.box.com/folder/0','_blank');
  }

  getUserReportAccess(): Observable<boolean> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/department/HasReports');
  }

  getReportMenuLabel() {
    if (this.isJcyoiGroup) {
      return 'Dashboards';
    }else if (this.isJJDataGroup){
      return 'DASHBOARDS';
    } else if (this.isReportingGroup ) {
      return 'Management Dashboards';
    } else {
      return 'Reports';
    }
  }
}
