import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OktaAuthGuard } from '../core/guards/auth.guard';
import { Features } from '../core/navigations/navigation-settings.model';
import { MetaTagsManageComponent } from './meta-tags-manage/meta-tags-manage.component';
import { OktaSettingComponent } from './okta-setting/okta-setting.component';
import { TitleManageComponent } from './title-manage/title-manage.component';

const routes: Routes = [
  
  {path: 'okta', component: OktaSettingComponent, canActivate: [OktaAuthGuard],
                  data: { roles: ['Admin', 'SuperAdmin'], feature: [Features.Config] }},
  {path: 'title', component: TitleManageComponent, canActivate: [OktaAuthGuard],
                  data: { roles: ['Admin', 'SuperAdmin', 'Moderator'], feature: [Features.Config] }},
  {path: 'metatags', component: MetaTagsManageComponent, canActivate: [OktaAuthGuard],
                  data: { roles: ['Admin', 'SuperAdmin', 'Moderator'], feature: [Features.Config] }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalConfigRoutingModule { }
