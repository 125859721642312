import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { NavigationRoute, Features, Groups } from 'src/app/core/navigations/navigation-settings.model';
import { NavigationService } from 'src/app/core/navigations/navigation.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { LocalStorageService } from 'src/app/core/state-manager/local-storage/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-jcyoi-home',
  templateUrl: './jcyoi-home.component.html',
  styleUrls: ['./jcyoi-home.component.scss']
})
export class JcyoiHomeComponent implements OnInit {

  userDetail: any;
  showDashboardLink = false;
  dashboardLinks = [];
  hasReports$: Observable<boolean>;
  navigationRoutedata: NavigationRoute;
  Features: any;
  loginType: string;
  constructor(public currentState: StateService, private router: Router,
              private repository: RepositoryService,
              private navigationService: NavigationService,
              private storageService: LocalStorageService,
              private commonService: CommonEntityService,
              public googleAnalyticsService: GoogleAnalyticsService,
              private titleService: Title) {
    this.Features = Features;
    this.loginType = this.storageService.getItem('LoginType');
  }

  ngOnInit() {
    this.titleService.setTitle(environment.jcyoiPageTitle);
    if (this.currentState.getUserData()) {
      this.getSessionData();
    }
    this.currentState.getSessionEvent().subscribe(() => this.getSessionData());
    this.commonService.getViewUserEventEmitter().subscribe(data => this.setViewUserInfo(data));
    this.hasReports$ = this.getUserReportAccess();
  }

  getSessionData() {
    const group = this.currentState.getGroupSession();
    this.navigationRoutedata = this.navigationService.getCurrentnavigationRoutes(Groups[group]);
    if (this.showNavigation) {
      this.setViewUserInfo(this.currentState.isViewAsAdmin());
    }
  }

  get showNavigation() {
    return this.currentState.isTokenValid;
  }

  setViewUserInfo(isView) {
    if (this.showNavigation) {
      if (isView) {
        this.userDetail = this.currentState.getViewingUserData();
      } else {
        this.userDetail = this.currentState.getUserData();
      }
      this.hasReports$ = this.getUserReportAccess();
      this.showDashboard();
    }
  }

  showDashboard() {
    if (this.userDetail && this.userDetail.reports) {
      this.dashboardLinks = this.userDetail.reports;
      this.showDashboardLink = this.dashboardLinks && this.dashboardLinks.length > 0;
    }
  }

  IsFeatureSupported(feature) {
    if (this.navigationRoutedata && this.navigationRoutedata.supportedFeatures) {
      return this.navigationRoutedata.supportedFeatures.some(x => x.name === feature);
    }

    return false;
  }

  gotoDashbaord(reportLink) {
    if (reportLink && reportLink.reportType !== 'Dashboard') {
      this.googleAnalyticsService.eventEmitter('Homepage Tile', reportLink.reportName, 'Report');
      this.router.navigate(['/dashboard/' + reportLink.reportName]);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  gotoCommunity() {
    this.googleAnalyticsService.eventEmitter('Community Link', 'JCIE', 'Navigation');
    window.open('https://community.aecf.org/community/jcyoi', '_blank');
  }

  getUserReportAccess(): Observable<boolean> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/department/HasReports');
  }
}
