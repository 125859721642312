<div class="allImgcontainer">
    <div class="overcontainer">
        <div class="row">
            <div class="col-md-6">
                 <div class="headingjive">
                        <div class="headingContent">
                                <p>Access our jive community to view current updates 
                                <br> happening in the Juvenile Detention Alternatives Initiative Community</p>                    
                        </div>
                        <div class="cancelbutton">
                               <a class="btn button-style" href="https://community.aecf.org/">Community</a>
                        </div>
                    </div>
            </div>
            <div class="col-md-6">
                <div class="heading">
                    <div class="headingContent">
                    <p>Begin your search below to access our online resources</p>
                </div>
                <div class="searchbar">
                        <app-search-component></app-search-component>
                    </div>
                </div>                    
            </div>
        </div>       
    </div>
    <app-news-feed-component></app-news-feed-component>
</div>
    