<div class="container manage-users">

    <div class="heading d-flex">
            <div class="page-title">
                <div>
                    <span calss="page-title"><h1>Manage Measure Validations</h1></span>
                </div>
            </div>
        <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
            <div class="col-md-12">
                <div class="alert alert-danger" id="formSubmitError">
                {{ formSubmitError }}
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="search_icon_profile">
            <i class="fas fa-search"></i>
        </div>
        <div class="searchBoxForm_profile">
                <input type="text" id="searchTerm" name="searchTerm" placeholder="Search measures validation..." autocomplete="off"
                             [(ngModel)]="searchTerm" (keyup)="search()"  class="searchBox_Profile" />
        </div>
        <button type="button"  id="btnAddMeasure" class="btn btn-success button-style" (click)="addMeasure()">Add Measure Validation</button>
    </div>
    
    <div class="row">
        <div class="col-12 table-responsive">
            <table class="table table-striped sortable" id="printableArea">
                <thead>
                  <tr>
                  <th style="width:5%;"></th>
                  <th style="width:5%;"></th>
                  
                <!--  <th style="width:100px;"><div style="display:inline-flex"></div><div> Number <a (click)="toggleTag('numberA')" [hidden]="NumberAAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!NumberAAsc" (click)="toggleTag('numberA')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
                <!--    <th style="width:100px;"><div style="display:inline-flex"></div><div> Measure <a (click)="toggleTag('measureA')" [hidden]="measureAAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!measureAAsc" (click)="toggleTag('measureA')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
                <!--    <th style="width:100px;"><div style="display:inline-flex"></div><div> Operator <a (click)="toggleTag('operator')" [hidden]="operatorAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!operatorAsc" (click)="toggleTag('operator')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
                <!--    <th style="width:100px;"><div style="display:inline-flex"></div><div> Number <a (click)="toggleTag('numberB')" [hidden]="NumberBAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!NumberBAsc" (click)="toggleTag('numberB')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
                <!--    <th style="width:100px;"><div style="display:inline-flex"></div><div> Measure <a (click)="toggleTag('measureB')" [hidden]="measureBAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!measureBAsc" (click)="toggleTag('measureB')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
                <th style="width:20%;"><div style="display:inline-flex"></div><div>Validation Rule<a (click)="toggleTag('validationRule')" [hidden]="validationRuleAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!validationRuleAsc" (click)="toggleTag('validationRule')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th style="width:15%;"><div style="display:inline-flex"></div><div>Validation Type<a (click)="toggleTag('validationType')" [hidden]="validationTypeAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!validationTypeAsc" (click)="toggleTag('validationType')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th style="width:35%;"><div style="display:inline-flex"></div><div>Validation Message<a (click)="toggleTag('validationMessage')" [hidden]="validationMessageAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!validationMessageAsc" (click)="toggleTag('validationMessageAsc')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th style="width:10%;"><div style="display:inline-flex"></div><div >Last Updated<a (click)="toggleTag('lastUpdated')" [hidden]="lastUpdatedAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedAsc" (click)="toggleTag('lastUpdated')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                <th style="width:10%;"><div style="display:inline-flex"></div><div >Last Updated Date Time<a (click)="toggleTag('lastUpdatedDateTime')" [hidden]="lastUpdatedDateTimeAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedDateTimeAsc" (click)="toggleTag('lastUpdatedDateTime')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let validation of manageMeasureValidations; let rowIndex=index;">
                <td title="delete measure">
                        <div class="delete-icon" [ngClass]="{ 'disabled':  viewAsAdmin }">
                        <span *ngIf="isAdmin" class="role-icon" (click)="removeMeasure(validation)">
                            <em class="far fa-trash-alt"></em>
                        </span>  
                    </div>
                </td>
                <td title="edit measures">
                    <div class="delete-icon" [ngClass]="{ 'disabled': viewAsAdmin }">
                    <span *ngIf="isAdmin" class="role-icon"  (click)="editMeasure(validation, rowIndex)">
                        <i class="far fa-edit"></i>
                    </span>  
                    </div>
                </td>
                    <td>{{validation.validationRule}}</td>
                    <td>{{getValidationType(validation.validationType)}}</td>
                    <td>{{validation.validationMessage}}</td>
                    <td>{{validation.lastUpdated}}</td>
                    <td>{{validation.lastUpdatedDateTime | timezone:'MM/DD/YYYY hh:mm A' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="row" *ngIf="manageMeasureValidations?.length>0 || managedMeasureValidationList.length>0">
        <div class="col-md-6" >
            <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage"  [rotate]="false"  [boundaryLinks]="true" [maxSize]="10"></pagination>
        </div>     
        <div class="col-md-4 pull-right padding-2p">
            <div class="col-xs-6 px-5">
                <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
            </div>
        </div>
    </div>
    
    <div class="row" *ngIf="manageMeasureValidations?.length===0">
        <div class="col-md-4" ></div>
        <div class="col-md-4" >  <p>No Measure Found.</p></div>
        <div class="col-md-4" ></div>
    </div>
    
    <div class="clearfix">
            <div class="pull-right">
                <div *ngIf="formSubmitSuccess">
                    <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
                </div>
            </div>
    </div>
    
    <div class="add-form" *ngIf="isAddOpen">
        <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
        <div class="form-group">
            <form [formGroup]="measureValidationForm">
                <div><b>Add/Edit Measure Validation</b></div>
                <label for="txt-role">Measure</label>
                <!-- <div>
                    <select formControlName="measureA" id="measureA" class="form-control" required (change)='onMeasureASelected($event.target.value)'>
                        <option value=0> Select Measure</option>
                        <option *ngFor="let measure of measuresList; let rowIndex=index;" [ngValue]="measure.id" [selected]="measure.name == this"> 
                        {{measure.number}} - {{measure.measure}} 
                        </option>
                    </select>
                </div>  
                <label for="txt-role">Operator</label>
                <div>
                    <select formControlName="operator" class="form-control" required>
                        <option value=0> Select Operator</option>
                        <option value='='> Equal</option>
                        <option value='!'> Not Equal</option>
                        <option value='<'> Less than</option>
                        <option value=">"> Greater than</option>
                        <option value='<='> Less than or Equal</option>
                        <option value=">="> Greater than or Equal</option>
                    </select>
                </div>  
                <label for="txt-role">Compare Measure</label>
                <div>
                    <select formControlName="measureB" id="measureB" class="form-control" required (change)='onMeasureBSelected($event.target.value)'>
                        <option value=0> Select Measure</option>
                        <option *ngFor="let measure of measuresList; let rowIndex=index;" [ngValue]="measure.id" [selected]="measure.name == this"> 
                            {{measure.number}} -  {{measure.measure}} 
                        </option>
                    </select>
                </div> -->
                <div>Validation Rule
                    <textarea formControlName="validationRule" id="txtrule" placeholder="Validation Rule" name="txtValidationRule" rows="3" class="form-control"></textarea>
                </div>                
                <div>Validation Type
                    <select formControlName="validationType" class="form-control" required>
                        <option value='0'> Select validation Type</option>
                        <option value='warning'>Are you sure?</option>
                        <option value='error'> Hard stop</option>
                    </select>
                </div>
                <div>Validation Message
                    <textarea formControlName="validationMessage" id="txtmessage" placeholder="Validation Message" name="txtValidationMessage" rows="3" class="form-control"></textarea>
                </div>
            </form>
        </div>
        <div class="col-md-4 px-5">
            <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateMeasureValidation()" [disabled]="measureValidationForm.invalid">{{buttonText}}</button>
        </div>
    </div>
    
    <div class="backdrop" *ngIf="isAddOpen"></div>
    
    <app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
        <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
            <div class="col-sm-12 content-style">
                <div *ngIf="dataItem">
                    <span>Are you sure you want to delete?</span>
                </div>
            </div>
    
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                        <span>Cancel</span>
                    </button>
                </div>
    
                <div class="savebtn">
                    <button type="submit" class="btn button-style" (click)="confirmMeasuretoRemove(dataItem.deleteMeasure)">
                        <span>Ok</span>
                    </button>
                </div>
    
            </div>
        </ng-template>
    </app-modal>

    <app-modal #errorModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
        <ng-template #modalContentTemplate let-dataItem="dataItem?.entityData">
            <div class="col-sm-12 content-style">
                <div>
                    <span>{{errorModalMsg}}</span>
                </div>
            </div>
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" (click)="closeErrorModal()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </ng-template>
    </app-modal>
        
  </div>