<div class="content-edit-container">
    <div *ngIf="metaDataForm">
        <app-blob-metadata #blobMetadataView [contentForm]="metaDataForm" [showAccess]="false" [contentSubmitted]=contentSubmitted [blobFileTypes]=blobFileTypes></app-blob-metadata>
    </div>
    <div class="buttonstyle">
            <div class="savebutton">
                    <button type="submit" class="btn button-style" (click)="updateMetadata()" [ngClass]="{ 'disable-color': viewAsAdmin | async }">
                        <span>Update</span>
                    </button>
                </div>
        <div class="cancelbutton">
            <button type="submit" class="btn button-style" [routerLink]="['/content/remove']" >
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>