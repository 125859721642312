import { Injectable, Inject } from '@angular/core';

import { NavigationRoute, NavigationRoutes, SubGroups, Groups } from './navigation-settings.model';

@Injectable()
export class NavigationService {

  constructor(@Inject('LocationRef') private locationRef) { }

  public getSubdomainFromUrl(): string {
    const tokens = this.locationRef.hostname.toString().split('.');
    let subDomain = '';
    if (tokens.length > 1) {
      const domainArray = Object.keys(SubGroups);
      const domain = domainArray.find(key => tokens[0].lastIndexOf(SubGroups[key], 0) === 0);
      if (domain) {
        subDomain = SubGroups[domain];
      }
    }

    return subDomain;
  }

  public getCurrentnavigationRoutes(groupKey: string): NavigationRoute {
    let route = null;
    Object.keys(NavigationRoutes).forEach(element => {
    if (element === groupKey) {
        route = NavigationRoutes[element];
    }
    });

    return route ? route : NavigationRoutes['default'];
  }

  public isFeatureSupported(currentGroup, feature): boolean {
    if (currentGroup && feature) {
      const navigationRoute = this.getCurrentnavigationRoutes(Groups[currentGroup]);
      if (navigationRoute && navigationRoute.supportedFeatures) {
        return navigationRoute.supportedFeatures.some(x => feature.indexOf(x.name) > -1);
      }
    }

    return true;
  }
}
