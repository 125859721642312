export class FaqData {
    id: number;
    question: string;
    answer: string;

    constructor(id:number,question:string,answer:string){
        this.id=id;
        this.answer=answer;
        this.question=question;
    }
    
}