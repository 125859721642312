<div class="jcyoiImgcontainer">
    <div class="overcontainer">
        <div class="row">
            <div class="col-md-12">
                <div class="heading">
                    <div class="headingContent">
                        <div *ngIf="showDashboardLink && IsFeatureSupported(Features.Reports)">
                            <div class="dashboard-heading">
                                <p>Dashboards</p>
                            </div>
                            <div class="dashboard-content">
                                <a *ngFor="let dashboard of dashboardLinks" class="report-name-tile" (click)="gotoDashbaord(dashboard);dropdown.hide();">
                                    <div class="dashboard-inline">
                                        <div class="dashboard-wrap">
                                            <p>{{dashboard.reportName}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>       
    </div>    
</div>