import { Component, OnInit, Renderer2, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NewsFeedService } from '../shared/news-feed.service';
import { NewsFeed, NewsFeedList } from '../shared/news-feed.model';
import { FormGroup,  FormBuilder,  Validators, ReactiveFormsModule  } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { SystemPermissions } from './../../permissions';
@Component({
  selector: 'app-news-feed-manage-component',
  templateUrl: './news-feed-manage.component.html',
  styleUrls: ['./news-feed-manage.component.scss'],
  providers: []
})
export class NewsFeedManageComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  newsFeeds: Array<NewsFeed>;
  newsFeed: NewsFeed;
  viewNewsFeed = -1;
  selectedId = 0;
  selectedRowIndex = -1;
  formSubmitSuccess: string;
  formSubmitError: string;
  newsFeedForm: FormGroup;
  isAddOpen = false;
  userDetails: any;
  permissions = SystemPermissions;
  constructor( private newsFeedService: NewsFeedService,
               private router: Router, private formBuilder: FormBuilder,
               public datepipe: DatePipe,
               public googleAnalyticsService: GoogleAnalyticsService,
               private currentState: StateService) {
      this.createForm();
    }

  ngOnInit() {
    this.getNewsFeeds();
    this.userDetails = this.currentState.getUserData();
  }

  createForm() {
    this.resetForm();
  }

  resetForm() {
    const date = new Date();
    const createdDateTime = this.datepipe.transform(date, 'yyyy-MM-ddThh:mm:ss');
    this.newsFeedForm = this.formBuilder.group({
      title: ['', Validators.required ],
      id: [''],
      body: ['', Validators.required ],
      community: ['' ],
      createdBy: ['' ],
      removed: [false],
      createdDateTime: [createdDateTime ]
   });
  }

  getNewsFeeds() {
    this.newsFeedService.getNewsFeeds()
    .subscribe(data => {
        this.newsFeeds = new NewsFeedList().uiMapper(data).newsFeeds;
    });
  }
  public getNewsFeed(index: number) {
    this.newsFeed = this.newsFeeds[index];
  }

  openConfirmationModal(id: number, rowIndex: number) {
    this.selectedId = id;
    this.selectedRowIndex = rowIndex;
    if (this.confirmationModalCompoent) {
        this.confirmationModalCompoent.entityData = {
          entityData: null
        };
        this.confirmationModalCompoent.showModal();
    }
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.selectedRowIndex = -1;
      this.selectedId = -1;
      this.confirmationModalCompoent.closeModalWindow();
    }
  }
  confirmDelete(id: number, rowIndex: number): void {
    this.getNewsFeed(rowIndex);
    this.newsFeedService.deleteNewsFeed(id)
    .subscribe(() => {
        this.formSubmitSuccess = 'News Feed deleted successfully.';
        this.newsFeeds.splice(rowIndex, 1);
      });
    this.selectedRowIndex = -1;
    this.selectedId = -1;
    this.googleAnalyticsService
                .eventEmitter('Delete', this.newsFeed.title, 'News Update');
    this.cancelAddUpdate();
    this.confirmationModalCompoent.closeModalWindow();
  }

  editNewsFeed(rowIndex: number) {
    this.selectedRowIndex = rowIndex;
    this.newsFeed = this.newsFeeds[rowIndex];
    this.selectedId = this.newsFeed.id;
    this.newsFeedForm.setValue({
      id: this.newsFeed.id,
      title: this.newsFeed.title,
      body: this.newsFeed.body,
      community: this.newsFeed.community,
      createdDateTime: this.newsFeed.createdDateTime,
      removed: this.newsFeed.removed,
      createdBy: this.newsFeed.createdBy
    });
    this.isAddOpen = true;
  }

  saveNewsFeed() {
    this.newsFeedService.saveNewsFeed(this.newsFeedForm.value).subscribe(data => {
      if (this.selectedRowIndex >= 0) {
        this.newsFeeds.splice(this.selectedRowIndex, 1, data);
        this.selectedRowIndex = -1;
        this.selectedId = -1;
        this.googleAnalyticsService
                    .eventEmitter('Edit', data.title, 'News Update');
      } else {
        this.newsFeeds.splice(0, 0, data);
        this.googleAnalyticsService
                    .eventEmitter('Add', data.title, 'News Update');
      }
      this.resetForm();
    });
    this.isAddOpen = false;
  }

  cancelAddUpdate() {
    this.formSubmitSuccess = '';
    this.resetForm();
    this.isAddOpen = false;
  }
}
