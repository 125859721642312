import { Injectable, Injector } from '@angular/core';
import { Router, ActivatedRoute, ResolveEnd, ActivatedRouteSnapshot, RouterEvent } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class AppInsightService {
    
    private config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.instrumentationKey
    };

    private routerSubscription: Subscription;

    constructor(private injector: Injector
        ) {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
            const router = this.injector.get(Router);

            this.routerSubscription = router.events.pipe(
                filter(e => e instanceof ResolveEnd)
              ).subscribe((e: ResolveEnd) => {
                const activatedComponent = this.getActivatedComponent(e.state.root);
                if (activatedComponent) {
                    this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(e.state.root)}`, e.urlAfterRedirects);
                }
              });
        }
    }

    logPageView(
        name?: string,
        url?: string,
        properties?: any,
        measurements?: any,
        duration?: number
    ) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }

    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name, properties, measurements);
    }

    logException(
        exception: Error,
        handledAt?: string,
        properties?: any,
        measurements?: any
    ) {
        AppInsights.trackException(exception, handledAt, properties, measurements);
    }

    logTrace(message: string, properties?: any, severityLevel?: any) {
        AppInsights.trackTrace(message, properties, severityLevel);
    }
    
    setAuthenticatedUserId(userId: string): void {
        AppInsights.setAuthenticatedUserContext(userId);
    }

    clearAuthenticationContext() :void
    {
        AppInsights.clearAuthenticatedUserContext();
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }

    private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }

        //add your custom properties such as app version

        return properties;
    }
}