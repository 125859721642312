export class OktaSetting {
    oktaClientId: string;
    oktaIssuer: string;

    public static modelMapper(uiModel: OktaSetting) {
        return {
            oktaClientId: uiModel.oktaClientId,
            oktaIssuer: uiModel.oktaIssuer
        };
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            oktaIssuer: modelData['oktaIssuer'],
            oktaClientId: modelData['oktaClientId']
        });
        return this;
    }
}
