import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { DatePipe } from '@angular/common';

import { SearchService } from 'src/app/search-results/shared/search.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ReprioritizeFacets, filterTags, filterLocations, allFilterTags, filterTopics } from 'src/app/search-results/shared/search-model';
import { organizeLocationMetadataTags } from '../shared/blob-upload.model';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { FileUploaderService } from '../shared/file-uploader.service';

@Component({
  selector: 'app-content-remove',
  templateUrl: './content-remove.component.html',
  styleUrls: ['./content-remove.component.scss'],
  providers: [ DatePipe ]
})
export class ContentRemoveComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  accordionPanelClass = 'refine-tags';
  results = [];
  facets = [];
  facetsGroup = {};
  searchQuery: string;
  sortParam = 0;
  facetsList: string[] = [];
  fileAttributes: string[] = [];
  tags: any[] = [];
  newFacets = [];
  /* Pagination */
  maxSize = 5;
  currentPage = 1;
  countFrom;
  countTo;
  totalItems;
  perPage = 10;
  itemPerPage;

  /* DatePicker */
  bsStartValue: Date = null;
  bsEndValue: Date = null;
  maxDate = new Date();
  colorTheme = 'default';
  bsConfig: Partial<BsDatepickerConfig>;

  searchEventSubscription: any;
  showFilters = true;
  showResults = false;
  geoLocationsData: any;
  locationMetaData: any;
  viewAsAdmin = this.commonService.getViewUserEventEmitter();
  facetItemKeys: any;
  isFtlaGroup = false;
  isAllGroup = false;
  topicsList: Array<any>;
  isFacetDisabled = false;
  selectedAccordion = "";
  selectedTopic = "";

  constructor(private uploadService: FileUploaderService, 
              private searchService: SearchService, 
              public sanitizer: DomSanitizer,
              public datepipe: DatePipe,
              private router: Router, 
              private commonService: CommonEntityService, 
              private currentState: StateService, 
              public googleAnalyticsService: GoogleAnalyticsService) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, maxDate: this.maxDate, showWeekNumbers:false });
  }

  ngOnInit() {
    this.isFtlaGroup = this.currentState.isFTLAGroup();
    if(this.currentState.isAllGroup()) {
      this.isAllGroup = true;
      this.gettopicsList();
    }
    this.searchService.getlocationsMetadata().subscribe(results => {
      this.geoLocationsData = organizeLocationMetadataTags(results, this.isFtlaGroup);
    });
    this.searchEventSubscribe();
    this.facetItemKeys = this.currentState.isAllGroup() ? allFilterTags : filterTags;
  }

  ngOnDestroy() {
    if (this.searchEventSubscription) {
      this.searchEventSubscription.unsubscribe();
    }
    this.closeConfirmModal('close');
  }

  isOpenChange(key: string, isOpen: boolean) {
    if(isOpen) {
      this.selectedAccordion = key
    } else {
      this.selectedAccordion = "";
    }
  }
  
  gettopicsList() {
    this.uploadService.gettopicsList().subscribe(results => {
      this.topicsList = results;
      this.topicsList.sort((a,b) => (a.topic > b.topic) ? 1 : ((b.topic > a.topic) ? -1 : 0));
      this.topicsList.forEach((topic) => {
        topic.programs.sort((a,b) => (a.program > b.program) ? 1 : ((b.program > a.program) ? -1 : 0));
      });
    });
  }

  toggleRefineTag() {
    this.showFilters = !this.showFilters;
  }

  loadBasicSearchResults() {
    this.facetsGroup = {};
    this.currentPage = 1;
    this.countTo = this.currentPage * this.perPage;
    this.countFrom = this.countTo - 9;

    this.searchService.getSearchResults(this.searchQuery).subscribe(results => {
      this.showResults = true;
      this.resultFormatter(results);
    });
  }

  searchEventSubscribe() {
    this.searchEventSubscription = this.searchService.getSearchEventEmitter()
      .subscribe(item => {
         //// after search results loaded
         if (item.renderSearchResults) {
            this.searchQuery = item.search;
            this.loadBasicSearchResults();
         }
      });
  }

  clearFacetSelection(facetName, facetValue) {
    if (facetName === 'locationFacet') {
      this.clearFacetSelection('continentFacet', facetValue);
      this.clearFacetSelection('countryFacet', facetValue);
      this.clearFacetSelection('regionFacet', facetValue);
      this.clearFacetSelection('divisionFacet', facetValue);
      this.clearFacetSelection('stateFacet', facetValue);
      this.clearFacetSelection('jurisdictionFacet', facetValue);
    }
    if (facetValue !== 'clearAllChecked') {
      let finalString = '';
      if (typeof this.facetsGroup[facetName] !== 'undefined') {
        this.facetsGroup[facetName].split('|').forEach(key => {
          if (key !== facetValue) {
            finalString += key + '|';
          }
        });
        finalString = finalString.slice(0, -1);
        this.facetsGroup[facetName] = finalString;
        finalString = '';
      }
    } else {
      delete this.facetsGroup[facetName];
    }

    if (this.facetsGroup[facetName] === '') {
      delete this.facetsGroup[facetName];
    }

    let input = this.getBasicSearchParameters();
    input = {...input, ...(this.facetsGroup)};

    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    });
  }

  hasDateRangeError(): boolean {
    if (isDateValid(this.bsStartValue) || isDateValid(this.bsEndValue)) {
      return !this.isValidDateRage();
    }
    return false;
  }

  hasInvalidDate(date: Date): boolean {
    return date != null ? isNaN(date.valueOf()) : false;
  }

  onDateValueChange(value: Date): void {
    if (this.isValidDateRage()) {
      const publicationDateFacet = this.datepipe.transform(this.bsStartValue, 'yyyy-MM-dd') + '|' +
                                                        this.datepipe.transform(this.bsEndValue, 'yyyy-MM-dd');
      this.facetsGroup['publicationDateFacet'] = publicationDateFacet;
      this.currentPage = 1;
      this.getPagedSearchResults();
    }
  }

  NavigateEditContent(fileName: string, containerName: string) {
    this.router.navigate(['content/edit'], { queryParams: {fileName: fileName, folderName: containerName  } });
  }

  isValidDateRage(): boolean {
    if (isDateValid(this.bsStartValue) && isDateValid(this.bsEndValue) &&
        new Date(this.bsEndValue.getFullYear(), this.bsEndValue.getMonth(), this.bsEndValue.getDate()) >=
            new Date(this.bsStartValue.getFullYear(), this.bsStartValue.getMonth(), this.bsStartValue.getDate())) {
      return true;
    }
    return false;
  }

  onDateClear() {
    this.bsStartValue = null;
    this.bsEndValue = null;
    let input = this.getBasicSearchParameters();
    if (typeof this.facetsGroup['publicationDateFacet'] !== 'undefined') {
      delete this.facetsGroup['publicationDateFacet'];
    }
    input = {...input, ...(this.facetsGroup)};
    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    });
  }

  getBasicSearchParameters() {
    this.countTo = this.currentPage * this.perPage;
    this.countFrom = this.countTo - 9;
    const input = {};
    input['searchQuery'] = this.searchQuery;
    input['pageNumber'] = this.currentPage;
    input['sortOrder'] = this.sortParam;
    return input;
  }

  isChecked(facetsGroup, facetValue): boolean {
    if (typeof facetsGroup !== 'undefined') {
      return facetsGroup.split('|').map(key => key).includes(facetValue);
    }
    return false;
  }

  filterTag(facetName, facetValue) {
    if (typeof facetName !== 'undefined') {
      const event = {
        target: {
          checked: true
        }
      };
      this.getFacetedSearchResults(facetName, facetValue, event);
    }
  }

  getFacetedSearchResults(facetName, facetValue, event) {
    this.isFacetDisabled = true;
    let finalString = '';
    const facet = facetName + 'Facet';
    if (typeof this.facetsGroup[facet] !== 'undefined') {
      this.facetsGroup[facet].split('|').forEach((key: string) => {
        if (key !== facetValue) {
          finalString += key + '|';
        }
      });

      if (event.target.checked) {
        finalString += facetValue + '|';
      }
      finalString = finalString.slice(0, -1);
      this.facetsGroup[facet] = finalString;
      finalString = '';
    }

    if (this.facetsGroup[facet] === '') {
      delete this.facetsGroup[facet];
    }

    this.currentPage = 1;
    let input = this.getBasicSearchParameters();
    if (typeof this.facetsGroup[facet] === 'undefined' && event.target.checked) {
      this.facetsGroup[facet] = facetValue;
    }

    input = {...input, ...(this.facetsGroup)};

    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    });
  }

  sortSearch(sortParam) {
    this.sortParam = sortParam;
    let input = this.getBasicSearchParameters();
    input = {...input, ...(this.facetsGroup)};
    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    });
  }

  getPagedSearchResults() {
    let input = this.getBasicSearchParameters();
    input = {...input, ...(this.facetsGroup)};
    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    });
  }

  getFileContent(fileName: string, containerName: string, fileContentType: string) {
    this.searchService.getFileContent(fileName, containerName).subscribe(results => {
      const file = new Blob([results], {type: fileContentType});
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    });
  }

  goToUrl(url: string): void {
    if (url.indexOf('http') === -1) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }  

  isHidden(key: string) {
    return (this.isAllGroup && (key == 'community' || key == 'continent' || key == 'country' || key == 'division' || key == 'jurisdiction' || key == 'region' || key == 'state' || key == 'location'))
  }

  resultFormatter(results) {
    this.facetsList = [];
    const re = /(?:\.([^.]+))?$/;
    this.totalItems = results.totalCount;
    this.itemPerPage = results.documents.length;
    this.facetsList = Object.keys(filterTags);
    results.documents.forEach(result => {
      this.fileAttributes = [];
      this.tags = [];
      this.facetsList.forEach((facet) => {
        if (facet !== null) {
          if (result.document[facet] !== null && result.document[facet] !== 'null') {
            this.tags.push({ key: facet, value: result.document[facet] });
          }
        }
      });

      const ext = result.document.fileName && re.exec(result.document.fileName)[1].toLowerCase();
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'bmp' || ext === 'tif') {
        this.fileAttributes['CssClass'] = 'fa fa-file-image-o';
        this.fileAttributes['CssIcon'] = 'file-pdf';
        this.fileAttributes['FileContentType'] = 'image/' + ext;
        this.fileAttributes['FileType'] = ext;
        result.document.fileAttributes = this.fileAttributes;
      }
      if (ext === 'url' || ext === 'lnk') {
        this.fileAttributes['CssClass'] = 'fa fa-link';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/internet-shortcut';
        this.fileAttributes['FileType'] = 'WebSite';
        result.document.fileAttributes = this.fileAttributes;
      }
      if (ext === 'pdf') {
        this.fileAttributes['CssClass'] = 'far fa-file-pdf';
        this.fileAttributes['CssIcon'] = 'file-pdf';
        this.fileAttributes['FileContentType'] = 'application/pdf';
        this.fileAttributes['FileType'] = 'PDF';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'eml' || ext === 'mht') {
        this.fileAttributes['CssClass'] = 'far fa-envelope';
        this.fileAttributes['CssIcon'] = 'file-email';
        this.fileAttributes['FileContentType'] = 'message/rfc822';
        this.fileAttributes['FileType'] = 'Email';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'docx') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        this.fileAttributes['FileType'] = 'WORD';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'ppt') {
        this.fileAttributes['CssClass'] = 'far fa-file-powerpoint';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/vnd.ms-powerpoint';
        this.fileAttributes['FileType'] = 'POWER POINT';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'pptx') {
        this.fileAttributes['CssClass'] = 'far fa-file-powerpoint';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        this.fileAttributes['FileType'] = 'POWER POINT';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'doc') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/msword';
        this.fileAttributes['FileType'] = 'WORD';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'rtf') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/rtf';
        this.fileAttributes['FileType'] = 'WORD';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'xls' || ext === 'csv') {
        this.fileAttributes['CssClass'] = 'far fa-file-excel';
        this.fileAttributes['CssIcon'] = 'file-excel';
        this.fileAttributes['FileContentType'] = 'application/vnd.ms-excel';
        this.fileAttributes['FileType'] = 'EXCEL';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'xlsx') {
        this.fileAttributes['CssClass'] = 'far fa-file-excel';
        this.fileAttributes['CssIcon'] = 'file-excel';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        this.fileAttributes['FileType'] = 'EXCEL';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'htm' || ext === 'html') {
        this.fileAttributes['CssClass'] = 'far fa-file-code';
        this.fileAttributes['CssIcon'] = 'file-code';
        this.fileAttributes['FileContentType'] = 'text/html';
        this.fileAttributes['FileType'] = 'HTML';
        result.document.fileAttributes = this.fileAttributes;
      }
      if (ext === 'wmv') {
        this.fileAttributes['CssClass'] = 'far fa fa-film';
        this.fileAttributes['CssIcon'] = 'file-code';
        this.fileAttributes['FileContentType'] = 'video/x-ms-wmv';
        this.fileAttributes['FileType'] = 'Wmv';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'zip' || ext === 'rar' || ext === '7z') {
        this.fileAttributes['CssClass'] = 'far fa-file-archive';
        this.fileAttributes['CssIcon'] = 'file-archive';
        this.fileAttributes['FileContentType'] = 'application/octet-stream';
        this.fileAttributes['FileType'] = 'Archive';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (!result.document.fileAttributes) {
        this.fileAttributes['CssClass'] = 'far fa fa-file';
        this.fileAttributes['CssIcon'] = 'file-archive';
        this.fileAttributes['FileType'] = 'Not Specified';
        result.document.fileAttributes = this.fileAttributes;
      }

      result.document.tag = this.tags;
      result.document.toggle = 0;
    });
    this.results = results.documents;
    const searchFacets = results.facets;
    searchFacets.location = filterLocations(searchFacets, this.geoLocationsData, this.isFtlaGroup);
    if(this.isAllGroup) {
      searchFacets.topic = filterTopics(searchFacets, this.topicsList);
    }
    this.facets = ReprioritizeFacets(searchFacets, this.isFtlaGroup);
    this.isFacetDisabled = false;
  }

  showPreview(result) {
    if (result.document.containerName !== null) {
      if (result.document.fileAttributes['FileType'] === 'PDF') {
        this.searchService.getFileContent(result.document.fileName, result.document.containerName).subscribe(results => {
          const file = new Blob([results], {type: result.document.fileAttributes.FileContentType});
          const fileURL = URL.createObjectURL(file);
          result.document.fileURL = fileURL;
          result.document.toggle = !result.document.toggle;
        });
      } else {
        this.searchService.getBlobSASUrl(result.document.fileName, result.document.containerName).subscribe(results => {
          result.document.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src='
                                    + results + '&embedded=true');
          result.document.toggle = !result.document.toggle;
        });
      }
      if (!result.document.toggle) {
        this.googleAnalyticsService.eventEmitter('Show Preview', result.document.fileName, 'Admin Content');
      } else {
        this.googleAnalyticsService.eventEmitter('Hide Preview', result.document.fileName, 'Admin Content');
      }
    }
  }

  downloadFile(result) {
    if (result.document.containerName !== null) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      this.searchService.getFileContent(result.document.fileName, result.document.containerName).subscribe(results => {
        const file = new Blob([results], {type: result.document.fileAttributes.FileContentType});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = result.document.fileName;
        link.click();
      });
      this.googleAnalyticsService
                  .eventEmitter('Download File', result.document.fileName, 'Admin Content');
    }
  }
  returnZero(): number {
    return 0;
  }

  removeFile(result) {
    let selectedFiles = {};
    if (result.document.containerName !== null) {
      selectedFiles[result.document.containerName] = [result.document.fileName];
    } else {
      selectedFiles = [result.document.fileName];
    }

    this.searchService.removeFile(selectedFiles)
    .subscribe(data => {
      this.closeConfirmModal('close');
      this.getPagedSearchResults();
    });
    this.googleAnalyticsService
                .eventEmitter('Remove File', result.document.fileName, 'Admin Content');
  }

  openConfirmationModal(result) {
    if (this.confirmationModalCompoent) {
        this.confirmationModalCompoent.entityData = {
          headerText: 'Remove Content',
          entityData: result
        };
        this.confirmationModalCompoent.showModal();
    }
  }

  closeConfirmModal(event) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.closeModalWindow();
    }
  }
}
