import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultsComponent } from './search-results.component';
import { SharedDocumentComponent } from './shared-document/shared-document.component';
import { SearchModule } from '../search/search.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { VisionSearchResultsComponent } from './vision-search/vision-search-result.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [SearchResultsComponent, SharedDocumentComponent,VisionSearchResultsComponent],
  imports: [
    CommonModule,
    SearchModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    FormsModule,
    SharedModule,
    BsDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  bootstrap: [SearchResultsComponent]
})
export class SearchResultsModule { }
