import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OktaAuthGuard } from '../core/guards/auth.guard';
import { SavedFavoritesComponent } from './saved-favorites/saved-favorites.component';
import { SharedWithOthersComponent } from './shared-with-others/shared-with-others.component';
import { SharedWithMeComponent } from './shared-with-me/shared-with-me.component';
import { Features } from '../core/navigations/navigation-settings.model';
import { ReportExportComponent } from './report-export/report-export.component';
const routes: Routes = [
  {path: 'savedfavorites', component: SavedFavoritesComponent, canActivate: [OktaAuthGuard], data: { permissions: ['CanViewFavorites'] }},
  {path: 'shareddocuments', component: SharedWithOthersComponent, canActivate: [OktaAuthGuard], data: { permissions: ['ShareDocument'] }},
  {path: 'sharedwithme', component: SharedWithMeComponent, canActivate: [OktaAuthGuard], data: { permissions: ['ShareDocument'] }},
  {path: 'exportReports', component: ReportExportComponent, canActivate: [OktaAuthGuard], data: { permissions: ['DownloadReports'] }}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FavoritesRoutingModule { }
