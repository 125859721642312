import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';

import { environment } from 'src/environments/environment';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { DropboxService } from '../shared/dropbox.service';
declare const screen: any;
const windowConfiguration = {
  height: 520,
  width: 590
};

@Component({
  selector: 'app-dropbox-container',
  templateUrl: './dropbox-container.component.html',
  styleUrls: ['./dropbox-container.component.scss']
})
export class DropboxContainerComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  accessToken: string;
  boxAuthLoadingError = false;
  boxAuthWindow: Window;
  monitoringPopupClose = false;
  isAuthInProgress = false;
  otherGroup = false;
  allGroup = false;
  ftlaGroup = false;

  constructor(private currentState: StateService, private dropboxService: DropboxService) { }

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    try {
      if (e.data && e.data.dropbox_auth_code) {
        this.isAuthInProgress = true;
            this.accessToken = e.data.dropbox_auth_code;
            this.isAuthenticated = true;
      } else {
          this.closeBoxAuth();
      }
    } catch (error) {
      console.log(error);
    }
  }

  validateAuthentication(event) {
    if (event) {
      this.isAuthInProgress = false;
      this.isAuthenticated = false;
      this.boxAuthLoadingError = false;
      this.loadBoxAuthLogin();
    }
  }

  closeBoxAuth() {
    this.boxAuthLoadingError = true;
    if (this.boxAuthWindow)  {
      this.boxAuthWindow.close();
    }
  }

  validateUserForBoxAccess() {
    this.dropboxService.getInternalBoxAccessToken().subscribe(data => {
      if (data.length > 0) {
        this.accessToken = data;
        this.isAuthenticated = true;
      } else {
        this.loadBoxAuthLogin();
      }
    }, (error) => {
      console.log(error);
      this.loadBoxAuthLogin();
    });
  }

  loadBoxAuthLogin() {
    this.boxAuthLoadingError = false;
    const redirecturi = `${window.location.origin}/dropbox-auth.html`;
    //const urlAuth = `https://www.dropbox.com/oauth2/authorize?client_id=${environment.dropboxClientId}&redirect_uri=${redirecturi}&response_type=token`;
    const urlAuth ='https://www.dropbox.com/home';
    this.boxAuthWindow = window.open(urlAuth, 'boxAuthPopup', this.getWindowSpecs());
    if (this.boxAuthWindow)  {
      this.boxAuthWindow.focus();
      this.detectPopupClose();
    }
  }

  detectPopupClose() {
      this.monitoringPopupClose = true;
      if (this.boxAuthWindow && !this.boxAuthWindow.closed) {
        window.setTimeout(() => {
          if (this.monitoringPopupClose) {
            if (this.boxAuthWindow.closed) {
              if (!this.isAuthInProgress) {
                this.boxAuthLoadingError = true;
              }
            } else {
                this.detectPopupClose();
            }
          }
        }, 1000);
      }
  }

  getWindowSpecs() {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;
    let width = screen.width;
    if (window.innerWidth) {
        width = window.innerWidth;
    } else if (document.documentElement.clientWidth) {
        width = document.documentElement.clientWidth;
    }
    let height = screen.height;
    if (window.innerHeight) {
        height = window.innerHeight;
    } else if (document.documentElement.clientHeight) {
        height = document.documentElement.clientHeight;
    }
    let left = ((width / 2) - (windowConfiguration.width / 2)) + dualScreenLeft;
    let top = ((height / 2) - (windowConfiguration.height / 2)) + dualScreenTop;
    left = left > 0 ? left : 0;
    top = top > 0 ? top : 0;
    const specs = 'width=' + windowConfiguration.width + ',height=' + windowConfiguration.height + ',top=' + top + ',left=' + left +
                  ',resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no';
    return specs;
  }

  ngOnInit() {
    this.allGroup = this.currentState.isAllGroup();
    this.ftlaGroup = this.currentState.isFTLAGroup();
    this.otherGroup = this.currentState.isJDAIGroup()
                    || this.currentState.isThriveGroup();
    this.validateUserForBoxAccess();
  }

  ngOnDestroy() {
    if (this.boxAuthWindow)  {
      this.boxAuthWindow.close();
    }
  }
}
