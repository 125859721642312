import { environment } from "src/environments/environment";

export default {
    oidc: {
      clientId:(localStorage.getItem('clientId') == null || localStorage.getItem('clientId') == undefined) ? '00g3a7na6fxbym6KA357': localStorage.getItem('clientId'),
      issuer: (localStorage.getItem('issuer') == null ||  localStorage.getItem('issuer') == undefined) ? 'https://dev-608395.okta.com/oauth2/default': localStorage.getItem('issuer'),
      redirectUri: '/login/callback',
      scopes: ['openid', 'profile', 'email','groups','groups_okta_users','org_info','offline_access'],
      responseType: 'code',
      pkce: true,
      devMode:environment.production ? false : true,
      tokenManager: {
        autoRenew: true
      }
    }    
  };