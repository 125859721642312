import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { OktaSetting } from './okta-setting.model';
import { OktaSettings, PortalConfig } from './portal-config.model';

@Injectable()
export class PortalConfigService {

  constructor(private repository: RepositoryService) { }
  
  getOktaSettings() {
    return this.repository.get(environment.apiBaseURL, 'api/OktaConfig');
  }

  getConfig(configtype: number) {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/' + configtype);
  }
  
  getResourceList() {
    return this.repository.get(environment.apiBaseURL, 'api/Admin/resourceList');
  }

  saveOktaSettings(oktasetting: OktaSetting) {
    const model = new OktaSetting().uiMapper(oktasetting);
    return this.repository.post(environment.apiBaseURL, 'api/Admin/oktasettings', model);
  }

  approveConfig(setId: string) {
    let data = {'setId': setId};
    return this.repository.get(environment.apiBaseURL, 'api/Admin/approve/' + setId);
  }

  rejectConfig(setId: string) {
    let data = {'setId': setId};
    return this.repository.get(environment.apiBaseURL, 'api/Admin/reject/' + setId);
  }

  getOktaConfigSettings(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/oktaPortalConfig');
  }

    AddOrUpdateOktaProfiles(data: OktaSettings): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/ResourcesConfiguration/oktaPortalConfig', data);
  }

  saveConfig(data: PortalConfig) {
    const model = new PortalConfig().uiMapper(data);
    let formData = new FormData();
    formData.append('FormFile', data.formFile, model.formFile.name);
    formData.append('Title', model.title);
    formData.append('Community', model.community);
    formData.append('UserServicePrinicipalName', model.userServicePrinicipalName);
    formData.append('UserId', model.userId);
    if(model.setId != undefined) {
      formData.append('SetId', model.setId);
    }
    formData.append('IsApproved', (model.isApproved)?'true':'false');
    formData.append('IsActive', (model.isActive)?'true':'false');
    formData.append('ResourcesType', model.resourcesType.toString());
    if(model.content != undefined) {
      formData.append('Content', model.content);
    }
    if(model.resourcePath != undefined) {
      formData.append('ResourcePath', model.resourcePath);
    }
    formData.append('UserFullName', model.userFullName);
    formData.append('FileName', model.formFile.name);
    return this.repository.upload(environment.apiBaseURL, 'api/ResourcesConfiguration/uploadobjects', formData);
  }
}
