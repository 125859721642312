import { Injectable, QueryList } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';

@Injectable()
export class FavoritesService {

  constructor(private repository: RepositoryService) { }

  getSavedFavorites() {
    return this.repository.get(environment.apiBaseURL, 'api/Search/ListOfSavedQueries');
  }

  getSharedWithMeDocuments() {
    return this.repository.get(environment.apiBaseURL, 'api/User/documents/sharedbyothers');
  }

  getSharedWithOthersDocuments() {
    return this.repository.get(environment.apiBaseURL, 'api/User/documents/sharedbyme');
  }

  deleteSavedFavorites(queryId: string): Observable<boolean> {
    return this.repository.delete(environment.apiBaseURL, 'api/Search/' + queryId, {});
  }
}
