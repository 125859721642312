import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ReportConfigComponent } from './report-config.component';
import { ReportProfileComponent } from './report-profile/report-profile.component';
import { ReportConfigurationService} from './shared/report-config.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SurveryPeriodSelectionComponent } from './survery-period-selection/survery-period-selection.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
  declarations: [ReportConfigComponent,
                 ReportProfileComponent,
                 SurveryPeriodSelectionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModule,
    BsDropdownModule,
    PaginationModule.forRoot()
  ],
  exports: [],
  providers: [
    ReportConfigurationService
  ]
})
export class ReportConfigurationModule { }
