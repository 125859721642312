import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { FormBuilder, FormControl, FormGroup, Validators,AbstractControl , ValidatorFn, ValidationErrors } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DataCollectionsSiteResponse, DataCollectionsModel, TimePeriodType, Month, Month2LabelMapping, Quarter, Quarter2LabelMapping } from '../models/datacollections.model';
import { DataCollectionsServices } from '../services/datacollections.services';
import { Router } from '@angular/router';
import { GroupModel } from '../models/group.model';
import { GroupServices } from '../services/group.services';
import { SiteMeasureModel, SiteYears } from '../models/siteMeasure.model';
import { SiteMeasureServices } from '../services/siteMeasure.services';
import { SiteModel } from '../models/site.model';
import * as FileSaver from 'file-saver';
import { MeasureServices } from '../services/measure.services';
import { MeasureValidation, MeasurePrediciton } from '../models/measureValidation.model';
import { utc } from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-collections',
  templateUrl: './data-collections.component.html',
  styleUrls: ['./data-collections.component.scss']
})
export class DataCollectionsComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationModal', { static: false }) confirmationModalComponent: ModalComponent;
  @ViewChild('areyousureModal', { static: false }) areYouSureModalComponent: ModalComponent;
  @ViewChild('hardstopModal', { static: false }) hardstopModalCompoent: ModalComponent;
  @ViewChild('importSiteDataconfirmationModal', { static: false }) importSiteDataconfirmationModal: ModalComponent;
  @ViewChild('confirmDeleteDataModal', { static: false}) confirmDeleteDataModal: ModalComponent;
  @ViewChild('warnOverwriteDataModal',{static: false}) warnOverwriteDataModal: ModalComponent;

  originalOrder = () => 0;
  formSubmitSuccess: string;
  formSubmitError: string;
  viewAsAdmin = false;
  isAddOpen = false;
  currentPage = 1;
  perPage = 10;
  isLoading = false;
  searchTerm = '';
  currentUserRole: Roles;
  currentUser: any;
  lastSort: string;
  dataCollectionsForm: FormGroup;
  currentPeriodData: DataCollectionsModel = null;
  manageDataCollections: Array<DataCollectionsModel>;
  existingDataCollections: string = null;
  dataToSave: Array<DataCollectionsModel>;
  dataCollectionsList: DataCollectionsModel[];
  isAddSiteResponseOpen: boolean;
  siteResponseForm: FormGroup;
  dataCollectionsSiteResponse: DataCollectionsSiteResponse;
  groupList: Array<GroupModel>;
  hasInitialValueChange = false;
  selectedGroupMeasures: Array<SiteMeasureModel>;
  manageSiteMeasures: Array<SiteMeasureModel>;
  selectedSite: SiteModel;
  selectedMeasure: SiteMeasureModel;
  isActiveYearsOpen: boolean;
  siteYears: SiteYears[];
  selectedYear: number;
  selectedTimePeriodType: string;
  selectedTimePeriod: number;
  isEthnicityRequired = true;
  validationRule: Array<MeasureValidation> = null;
  currentValidationRulesList: Array<MeasureValidation> = null;
  initialDataCollections:any = {};
  isChangeTimePeriodType = false;
  isDiscardChanges:boolean = false;
  isAdult = false;
  // selectedAgeGroup:string;
  prediction:MeasurePrediciton;
  hasImportJobs:boolean = false;
  genderRegex:RegExp = /gender(?!Value|Total).*/;
  raceRegex:RegExp = /race(?!Value|Total).*/
  ethnicityRegex:RegExp = /ethnicity(?!Value|Total).*/
  genderToSum:Array<string>;
  raceToSum:Array<string>;
  ethnicityToSum:Array<string>;
  timePeriodTypes = TimePeriodType;
  quarterVals = Object.values(Quarter).filter(value => typeof value === 'number');
  Quarter2LabelMapping = Quarter2LabelMapping;
  monthVals = Object.values(Month).filter(value => typeof value === 'number');
  Month2LabelMapping = Month2LabelMapping;
  refreshDataAfterSave:boolean = false;
  dataCollectionsCount:number = 0;
  observableSubscriptions:Array<Subscription> = [];

  // ageGroupOptions:Array<Object> = [
  //   { "value":"18-20",
  //     "label":"18-20 years old"},
  //   { "value":"21-24",
  //     "label":"21-24 years old"},
  //   { "value":"25_or_older",
  //     "label":"25 years and older"},
  //   { "value":"Not_Available",
  //     "label":"Age Not Available"}
  // ];

  constructor(
    private datacollectionsService: DataCollectionsServices,
    private siteMeasureService: SiteMeasureServices,
    private groupService: GroupServices,
    private stateService: StateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public datepipe: DatePipe,
    private router: Router,
    private measureService: MeasureServices,
    private fb: FormBuilder) {
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }
  get isCaseyConsultant() { return this.stateService.getRole() === Roles.CaseyConsultant; }
  get isState() { return this.stateService.getRole() === Roles.State; }
  get isProbationChief() { return this.stateService.getRole() === Roles.ProbationChief; }
  get isOtherLocalLeader() { return this.stateService.getRole() === Roles.OtherLocalLeader; }
  get isDataLead() { return this.stateService.getRole() === Roles.DataLead; }

  ngOnInit(): void {
    try {
      this.siteResponseForm = this.fb.group({
        siteResponse: new FormControl('', Validators.required),
      });

      this.dataCollectionsForm = this.fb.group(
        {
          year:new FormControl(new Date().getFullYear()),
          timePeriodType:new FormControl(TimePeriodType.Annual),
          timePeriod:new FormControl(new Date().getFullYear()),
          latino:new FormControl('Race'),
          genderFemale:new FormControl(null,[Validators.required]),
          genderMale:new FormControl(null,[Validators.required]),
          genderNA: [null],
          genderNonBinary:[null],
          ethnicityHispanic:new FormControl(null),
          ethnicityNonHispanic:new FormControl(null),
          ethnicityNA:new FormControl(null),
          raceAIAKNative:new FormControl(null),
          raceAsian:new FormControl(null),
          raceBAA:new FormControl(null),
          raceHispanic:new FormControl(null),
          raceWhite:new FormControl(null),
          raceOther:new FormControl(null),
          racesTwoOrMore:new FormControl(null),
          racesNA:[null],
          ethnicityValue:new FormControl(0),
          genderValue:new FormControl(0),
          raceValue:new FormControl(0),
          total:new FormControl(0),
          // ageGroup:new FormControl('Juvenile')
        }
        ,{
          validators: this.ValidateFeMaleShouldNotGreaterThanMale,
        }
      );

      this.genderToSum = Object.keys(new DataCollectionsModel(0)).filter( x => this.genderRegex.test(x) );
      this.raceToSum = Object.keys(new DataCollectionsModel(0)).filter( x => this.raceRegex.test(x) );
      this.ethnicityToSum = Object.keys(new DataCollectionsModel(0)).filter( x => this.ethnicityRegex.test(x) );

      for(var g of this.genderToSum){
        this.observableSubscriptions.push(
          this.dataCollectionsForm.get(g).valueChanges.subscribe(x => {
            this.updateSubTotals(this.genderToSum, this.dataCollectionsForm.get("genderValue"));
            this.updateSubTotals(this.ethnicityToSum, this.dataCollectionsForm.get("ethnicityValue"));
            this.updateSubTotals(this.raceToSum, this.dataCollectionsForm.get("raceValue"));
            this.checkHasInitialValueChanges();
          })
        );
      }
      for(var e of this.ethnicityToSum){
        this.observableSubscriptions.push(
          this.dataCollectionsForm.get(e).valueChanges.subscribe(x => {
            this.updateSubTotals(this.genderToSum, this.dataCollectionsForm.get("genderValue"));
            this.updateSubTotals(this.ethnicityToSum, this.dataCollectionsForm.get("ethnicityValue"));
            this.updateSubTotals(this.raceToSum, this.dataCollectionsForm.get("raceValue"));
            this.checkHasInitialValueChanges();
          })
        );
      }
      for(var r of this.raceToSum){
        this.observableSubscriptions.push(
          this.dataCollectionsForm.get(r).valueChanges.subscribe(x => {
            this.updateSubTotals(this.genderToSum, this.dataCollectionsForm.get("genderValue"));
            this.updateSubTotals(this.ethnicityToSum, this.dataCollectionsForm.get("ethnicityValue"));
            this.updateSubTotals(this.raceToSum, this.dataCollectionsForm.get("raceValue"));
            this.checkHasInitialValueChanges();
          })
        );
      }
      this.observableSubscriptions.push(
        this.dataCollectionsForm.get("latino").valueChanges.subscribe(x => { 
          this.checkHasInitialValueChanges();
        })
      );

      this.selectedSite = JSON.parse(sessionStorage.getItem('site')) as SiteModel;
      this.dataToSave = new Array<DataCollectionsModel>();
      this.viewAsAdmin = this.stateService.isViewAsAdmin();

      if (this.viewAsAdmin) {
        this.currentUserRole = this.stateService.getViewUserRole();
        this.currentUser = this.stateService.getViewingUserData();
      } else {
        this.currentUserRole = this.stateService.getRole();
        this.currentUser = this.stateService.getUserData();
      }

      this.fetchGroups();
      this.getSiteActiveYears();

      this.selectedYear = null;
      this.selectedTimePeriodType = null;
      this.selectedTimePeriod = null;

      this.getImportJobDetails();
      this.getDataCollections();
    }
    catch
    {
      console.log("Error occured in manage data collection");
      window.location.reload();
    }
  }

  updateSubTotals( inputsToSum:string[], ctrlToUpdate:AbstractControl ):void{
    if(this.selectedMeasure.group.order === 10) return;
   
    var runningSum:number = 0;
    for(var i of inputsToSum){
      runningSum += this.dataCollectionsForm.get(i).value ?? 0;
    }

    ctrlToUpdate.setValue(runningSum, {emitEvent:false});
    
    this.dataCollectionsForm.get('total').setValue(runningSum, {emitEvent:false});
    
  }

  checkHasInitialValueChanges(){
    this.hasInitialValueChange = Object.keys(this.initialDataCollections)
                                        .some(key => this.dataCollectionsForm.value[key] !== this.initialDataCollections[key]);
    if(this.hasInitialValueChange)
      this.isDiscardChanges = false;
  }

  updateDataCollectionsForm(newData:DataCollectionsModel){
    this.initialDataCollections = {
      year: this.selectedYear ?? new Date().getFullYear(),
      timePeriodType: this.selectedTimePeriodType ?? TimePeriodType.Annual,
      timePeriod: this.selectedTimePeriod ?? this.selectedYear ?? new Date().getFullYear(),
      latino: newData.latino ?? 'Race',
      genderFemale: newData.genderFemale,
      genderMale: newData.genderMale,
      genderNA: newData.genderNA,
      genderNonBinary: newData.genderNonBinary,
      ethnicityHispanic: newData.ethnicityHispanic,
      ethnicityNonHispanic: newData.ethnicityNonHispanic,
      ethnicityNA: newData.ethnicityNA,
      raceAIAKNative: newData.raceAIAKNative,
      raceAsian: newData.raceAsian,
      raceBAA: newData.raceBAA,
      raceHispanic: newData.raceHispanic,
      raceWhite: newData.raceWhite,
      raceOther: newData.raceOther,
      racesTwoOrMore: newData.racesTwoOrMore,
      racesNA: newData.racesNA,
      genderValue: this.selectedMeasure.group.order === 10 ? null :  (newData.genderValue),
      ethnicityValue: this.selectedMeasure.group.order === 10 ? null :  (newData.ethnicityValue),
      raceValue: this.selectedMeasure.group.order === 10 ? null :  (newData.raceValue),
      total: newData.total ?? 0
      //, ageGroup: this.isAdult ? this.selectedAgeGroup : "Juvenile"
    };

    this.dataCollectionsForm.reset(this.initialDataCollections);
  }

  getCurrentDataFromForm(){
    this.currentPeriodData.SiteMeasure = this.selectedMeasure;
    this.currentPeriodData.measure = this.selectedMeasure.measure;
    this.currentPeriodData.isActive = this.selectedMeasure.isActive;
    this.currentPeriodData.number = this.selectedMeasure.number;
    this.currentPeriodData.group = this.selectedMeasure.group;
    // this.currentPeriodData.ageGroup = this.selectedAgeGroup;
    this.currentPeriodData.year = this.selectedYear;
    this.currentPeriodData.timePeriodType = this.selectedTimePeriodType;
    this.currentPeriodData.timePeriod = this.selectedTimePeriod;
    this.currentPeriodData.latino = this.dataCollectionsForm.get('latino').value;
    this.currentPeriodData.ethnicityHispanic = this.dataCollectionsForm.get('ethnicityHispanic').value;
    this.currentPeriodData.ethnicityNA = this.dataCollectionsForm.get('ethnicityNA').value;
    this.currentPeriodData.ethnicityNonHispanic = this.dataCollectionsForm.get('ethnicityNonHispanic').value;
    this.currentPeriodData.ethnicityValue = this.dataCollectionsForm.get('ethnicityValue').value;
    this.currentPeriodData.genderFemale = this.dataCollectionsForm.get('genderFemale').value;
    this.currentPeriodData.genderMale = this.dataCollectionsForm.get('genderMale').value;
    this.currentPeriodData.genderNA = this.dataCollectionsForm.get('genderNA').value;
    this.currentPeriodData.genderNonBinary = this.dataCollectionsForm.get('genderNonBinary').value;
    this.currentPeriodData.genderValue = this.dataCollectionsForm.get('genderValue').value;
    this.currentPeriodData.raceAIAKNative = this.dataCollectionsForm.get('raceAIAKNative').value;
    this.currentPeriodData.raceAsian = this.dataCollectionsForm.get('raceAsian').value;
    this.currentPeriodData.raceBAA = this.dataCollectionsForm.get('raceBAA').value;
    this.currentPeriodData.raceHispanic = this.dataCollectionsForm.get('raceHispanic').value;
    this.currentPeriodData.raceOther = this.dataCollectionsForm.get('raceOther').value;
    this.currentPeriodData.raceWhite = this.dataCollectionsForm.get('raceWhite').value;
    this.currentPeriodData.racesTwoOrMore = this.dataCollectionsForm.get('racesTwoOrMore').value;
    this.currentPeriodData.racesNA = this.dataCollectionsForm.get('racesNA').value;
    this.currentPeriodData.raceValue = this.dataCollectionsForm.get('raceValue').value;
    this.currentPeriodData.total = this.dataCollectionsForm.get('total').value;
  }

  padLeadingZeros(num, size): string {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  convertDateTime(lastupdated: any) {
    return this.datepipe.transform(lastupdated, 'dd/MM/yyyy')
  }

  modifySet(group: GroupModel, openModal: boolean, isOpen: boolean) {
    group.groupMeasures = this.manageSiteMeasures.filter(x => x.group.id === group.id);
    group.groupMeasures.sort((a, b) => (a.measure.number > b.measure.number) ? 1 : -1);
    if (this.searchTerm.length > 2) {
      group.groupMeasures =
        group.groupMeasures.filter(x => x.measure.measure.toLowerCase().includes(this.searchTerm.toLowerCase())
          || x.measure.number.toLowerCase().includes(this.searchTerm.toLowerCase()));
      console.log(group.groupMeasures);
    }
  }

  fetchGroups() {
    this.groupService.getGroup()
      .subscribe(groups => {
        this.isLoading = true;
        this.siteMeasureService.getSiteMeasures(this.selectedSite.id)
          .subscribe(data => {
            this.manageSiteMeasures = data;
            this.manageSiteMeasures = this.manageSiteMeasures.filter(x => { return x.isActive === true; });
            this.manageSiteMeasures.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));

            this.groupList = groups.filter(x => { return x.order !== 15; });

            this.groupList.sort((nodeA, nodeB) => compareItems(nodeA['order'], nodeB['order']));
            this.groupList.forEach((group) => {
              group.groupMeasures = this.manageSiteMeasures.filter(x => x.group.id === group.id);
              group.groupMeasures.sort((a, b) => (a.measure.number > b.measure.number) ? 1 : -1);
              if (this.searchTerm.length > 2) {
                group.groupMeasures =
                  group.groupMeasures.filter(x => x.measure.measure.toLowerCase().includes(this.searchTerm.toLowerCase())
                    || x.measure.number.toLowerCase().includes(this.searchTerm.toLowerCase()));
                console.log(this.selectedGroupMeasures);
              }
            });

          });
      });
  }

  isValidDataCollections() {
    const datacollectionsControl = this.dataCollectionsForm.get('name');
    if (datacollectionsControl.value && !datacollectionsControl.errors) {
      return true;
    }
  }

  getDataCollections() {
    this.datacollectionsService.getSiteResponse(this.selectedSite.id)
      .subscribe(data => {
        this.dataCollectionsSiteResponse = data;
        this.siteResponseForm.get('siteResponse').setValue(data.response);
      });
  }

  getSiteMeasures() {
    this.isLoading = true;
    this.siteMeasureService.getSiteMeasures(this.selectedSite.id)
      .subscribe(data => {
        this.manageSiteMeasures = data;
        this.manageSiteMeasures = this.manageSiteMeasures.filter(x => { return x.isActive === true; });
        this.manageSiteMeasures.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
      });
  }

  getSiteActiveYears() {
    this.siteYears = [];
    for (let year = 2012; year < new Date().getFullYear() + 1; year++) {
      const sitYear = new SiteYears(year, null, true);
      this.siteYears.push(sitYear);
    }
  }
  closeAddForm(){
    this.isAddOpen = false;
    this.manageDataCollections.length = this.dataCollectionsCount = 0;
    this.dataCollectionsForm.reset();
    this.formSubmitSuccess = null;
  }

  openConfirmationModal() {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.showModal();
    }
  }

  openConfirmDeleteDataModal() {
    if( this.confirmDeleteDataModal )
      this.confirmDeleteDataModal.showModal();
    if( this.confirmationModalComponent)
      this.confirmationModalComponent.closeModalWindow();
  }

  openHardStopModal() {
    if (this.hardstopModalCompoent) {
      this.hardstopModalCompoent.showModal();
    }
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }

  openAreyouSureModal() {
    if (this.areYouSureModalComponent) {
      this.areYouSureModalComponent.showModal();
    }
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }

  openWarnOverwriteDataModal(measureValidation: MeasureValidation) {
    if (this.warnOverwriteDataModal) {
      if(this.warnOverwriteDataModal?.headerText!="")
        this.warnOverwriteDataModal.headerText="";
      if (measureValidation?.validationMessage != null || measureValidation != undefined) {
          this.warnOverwriteDataModal.entityData = {
            headerText: measureValidation.validationMessage,
            entityData: null
          };
      }      
      this.warnOverwriteDataModal.showModal();
    }
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }

  closeConfirmModal(cancel:boolean) {
    if(cancel){
      this.cancelChangeData();
    }
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }

  closeConfirmDeleteDataModal(cancel:boolean) {
    if (this.confirmDeleteDataModal)
      this.confirmDeleteDataModal.closeModalWindow();
  }

  closeAreyouSureModal(cancel:boolean) {
    if(cancel){
      this.cancelChangeData();
    } 

    if (this.areYouSureModalComponent)
      this.areYouSureModalComponent.closeModalWindow();

  }

  closeWarnOverwriteDataModal() {

    if (this.warnOverwriteDataModal)
      this.warnOverwriteDataModal.closeModalWindow();

  }

  closeHardStopModal() {
    if (this.hardstopModalCompoent)
      this.hardstopModalCompoent.closeModalWindow();
    this.cancelChangeData();
  }

  addDataCollections() {
    this.isAddOpen = true;
  }

  continueToDelete(){
    this.closeConfirmDeleteDataModal(false);
    this.deleteDataCollections([Object.assign({},this.currentPeriodData)]);
    this.closeAddForm();
  }

  AddUpdateDataCollections() {
    this.dataToSave.length = 0;
    
    this.getCurrentDataFromForm();

    if(this.currentPeriodData.total === null){
      this.openConfirmDeleteDataModal();
      return;
    }

    this.setupMeasureValidations();
    this.continueToSave();
  
  }

  setupMeasureValidations(): void {

    this.cancelValidateData();
    const dat = this.currentPeriodData;
    this.currentValidationRulesList.find(x=> x.id === -100).measureValues["r"] = dat.raceValue;
    this.currentValidationRulesList.find(x=> x.id === -100).measureValues["g"] = dat.genderValue;
    this.currentValidationRulesList.find(x=> x.id === -101).measureValues["m"] = dat.genderMale;
    this.currentValidationRulesList.find(x=> x.id === -101).measureValues["f"] = dat.genderFemale;
   if(this.prediction.noData || this.prediction.prediction === null || this.prediction.prediction == 0 || this.selectedTimePeriodType != TimePeriodType.Annual){
        this.currentValidationRulesList = [ ...this.currentValidationRulesList.filter(x=> x.id !== -102)];
    }
    else{
      const predictionThreshold = 0.25;
      const absPrecitionDiff = Math.abs((dat.total - this.prediction.prediction) / this.prediction.prediction );
      var newMsgText = this.currentValidationRulesList.find(x=> x.id === -102).validationMessage;
        newMsgText = newMsgText.replace(/\[a\]/,dat.total.toFixed(0)).replace(/\[b\]/,this.prediction.prediction.toFixed(0));
      this.currentValidationRulesList.find(x=> x.id === -102).validationMessage = newMsgText;
      this.currentValidationRulesList.find(x=> x.id === -102).measureValues["a"] = absPrecitionDiff;
      this.currentValidationRulesList.find(x=> x.id === -102).measureValues["t"] = predictionThreshold;
    }    const curMeasureNumber = this.selectedMeasure.measure.number;
    for( var i=0; i < this.currentValidationRulesList.length; i++){
      if(this.currentValidationRulesList[i].id < 0)
        continue;
      this.currentValidationRulesList[i].measureValues[curMeasureNumber] = dat.total;
    }    this.currentValidationRulesList.sort((a:MeasureValidation,b:MeasureValidation) => 
      a.validationType === b.validationType ? a.id - b.id : Number(b.validationType === "error" ) - Number(a.validationType === "error" )
    ).reverse()
  }

  continueToSave():boolean {
    this.closeAreyouSureModal(false);
    while(this.currentValidationRulesList.length > 0){
      if(!this.checkMeasureValidation()){
        return false;
      }
    }
    this.dataToSave.push(this.currentPeriodData);
    this.saveDataCollections(this.dataToSave);
    if(this.refreshDataAfterSave)
      this.changeData();
    
    return true;

  }

  checkMeasureValidation():boolean{
    const currentValidation = this.currentValidationRulesList.pop()
    var valid:boolean = true;

    try {      
      var measureValues = currentValidation.measureValues;
      const rule = currentValidation.validationRule;
      const symbolRe = new RegExp('\\w+','gi');
      const equalsSignRe = new RegExp("([^\\=\\<\\>])\\=([^\\=])",'gi')
      const notEqualsSignRe = new RegExp("\\<\\>",'gi')
      const formulaWithValue = currentValidation.id === -199 ? rule : rule.replace(equalsSignRe,"$1==$2").replace(notEqualsSignRe,"!=").replace(symbolRe, m=> measureValues[m] ?? (currentValidation.id < 0 ? 0 : null) );
      if( Object.values(measureValues).includes(null) && currentValidation.id >= 0 )
        return valid;
      valid = eval(formulaWithValue);
    }
    catch {
      
    }
    finally {
      if(!valid){
        const measurePlaceholderRe = /\*(\w+)\*/gi;
        const msgWithValue = currentValidation.validationMessage.replaceAll(measurePlaceholderRe, (m,p1) => measureValues[p1]);
        if(msgWithValue)
          currentValidation.validationMessage = msgWithValue;
        this.openErrorWarning(currentValidation);
        return valid;
      }
      return valid;
    }
  }

  openErrorWarning(measureValidation: MeasureValidation) {

    if (measureValidation.validationType === 'error') {
      if (measureValidation?.validationMessage != null || measureValidation != undefined) {
        this.hardstopModalCompoent.entityData = {
          headerText: measureValidation.validationMessage,
          entityData: null
        };
      }
      this.openHardStopModal();
    }
    else if (measureValidation.validationType === 'warning') {
      if(this.areYouSureModalComponent?.headerText!="")
      this.areYouSureModalComponent.headerText="";
      if (measureValidation?.validationMessage != null || measureValidation != undefined) {
        this.areYouSureModalComponent.entityData = {
          headerText: measureValidation.validationMessage,
          entityData: null
        };
      }
      this.openAreyouSureModal();
    }
  }

  openSiteResponse() {
    this.isAddSiteResponseOpen = true;
  }

  cancelAddSR() {
    this.isAddSiteResponseOpen = false;
  }

  cancelChangeData(){
    this.selectedTimePeriodType = this.initialDataCollections.timePeriodType;
    this.selectedTimePeriod = this.initialDataCollections.timePeriod;
    this.selectedYear = this.initialDataCollections.year;
    this.cancelValidateData();
  }

  cancelValidateData(){
    this.currentValidationRulesList = [...this.validationRule];
  }

  saveDataCollections(selectedDataList: DataCollectionsModel[]): void {
      this.datacollectionsService.saveDataCollections(selectedDataList,true).subscribe((data) => {
        this.dataToSave.length = 0;
        this.formSubmitSuccess = 'Successfully saved data collections';
        this.isDiscardChanges = true;
        this.hasInitialValueChange = false;
        this.updateDataCollectionsForm(this.currentPeriodData);
      });
  }

  deleteDataCollections(seletedDataList: DataCollectionsModel[]): void {
    this.datacollectionsService.deleteDataCollections(seletedDataList)
      .subscribe((data) => {
        this.formSubmitSuccess = 'Successfully deleted data.';
      });
  }


  AddUpdateSiteResponse(): void {
    this.isAddSiteResponseOpen = false;
    this.dataCollectionsSiteResponse =
      new DataCollectionsSiteResponse('', this.siteResponseForm.get('siteResponse').value, this.selectedSite);
    this.datacollectionsService.saveSiteResponse(this.dataCollectionsSiteResponse).subscribe((data) => {
      this.formSubmitSuccess = 'Successfully saved site response';
    });
  }

  ngOnDestroy() {
    this.hardstopModalCompoent.closeModalWindow();
    this.areYouSureModalComponent.closeModalWindow();
    this.confirmationModalComponent.closeModalWindow();
    this.confirmDeleteDataModal.closeModalWindow();

    for(var s of this.observableSubscriptions) { s.unsubscribe(); }
    this.dataCollectionsList = null;
  }

  mngDataCollections(id: number) {
    this.router.navigate(['/datacollections/' + id]);
  }

  search(): void {
    if (this.searchTerm.length > 2) {
      this.groupList.forEach(group => {
        this.modifySet(group, false, true);
      });
    } else {
      this.groupList.sort((nodeA, nodeB) => compareItems(nodeA['order'], nodeB['order']));
      this.groupList.forEach((group) => {
        group.groupMeasures = this.manageSiteMeasures.filter(x => x.group.id === group.id);
        group.groupMeasures.sort((a, b) => (a.measure.number > b.measure.number) ? 1 : -1);
        if (this.searchTerm.length > 2) {
          group.groupMeasures =
            group.groupMeasures.filter(x => x.measure.measure.toLowerCase().includes(this.searchTerm.toLowerCase())
              || x.measure.number.toLowerCase().includes(this.searchTerm.toLowerCase()));
          console.log(this.selectedGroupMeasures);
        }
      });
    }
  }

  sortOn(column: string): void {
    if (column !== null) {
      if (this.lastSort === column) {
        if (column === 'number') {
          this.manageDataCollections = this.dataCollectionsList.sort((nodeA, nodeB) => compareItems(nodeB['number'], nodeA['number']));
        }
        else if (column === 'measure') {
          this.manageDataCollections = this.dataCollectionsList.sort((nodeA, nodeB) => compareItems(nodeB['measure'], nodeA['measure']));
        }
      } else {
        if (column === 'number') {
          this.manageDataCollections = this.dataCollectionsList.sort((nodeA, nodeB) => compareItems(nodeB['number'], nodeA['number']));
        }
        else if (column === 'measure') {
          this.manageDataCollections = this.dataCollectionsList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
        }
      }
      const skipRows = (this.currentPage - 1) * this.perPage;
      this.manageDataCollections = this.dataCollectionsList.slice(skipRows, this.currentPage * this.perPage);
      this.lastSort = column;
    }
  }

  editDataCollections(siteMeasure: SiteMeasureModel): void {
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if(this.selectedMeasure !== siteMeasure){
      this.selectedMeasure = siteMeasure;
      this.selectedYear = null;
      this.selectedTimePeriodType = null;
      this.selectedTimePeriod = null;
    };
    if(this.areYouSureModalComponent?.entityData!=null)
      this.areYouSureModalComponent.entityData = null;
    if(this.areYouSureModalComponent?.headerText!="")
      this.areYouSureModalComponent.headerText="";
    this.refreshSiteMeasureData();
  }

  getValidationRule() {
    this.measureService.GetMeasureValidationsBySiteId(
      this.selectedMeasure.measure.id,
      this.selectedSite.id,
      this.currentPeriodData.calendarPeriod
    ).subscribe((rule: MeasureValidation[]) => {
        console.log(rule);
        this.validationRule = rule;
       this.validationRule.push(new MeasureValidation(
          -100,
          "g = r",
          "error",
          "Sub total of gender and race should be same",
          "g,r",
          {
            "g":null,
            "r":null
          },
          this.currentUser.userPrincipalName));
       this.validationRule.push(new MeasureValidation(
          -101,
          "m >= f",
          "warning",
          "Are you sure?  In most cases, male would be higher than female.",
          "m,f",
          {
            "m":null,
            "f":null
          },
          this.currentUser.userPrincipalName));
       this.validationRule.push(new MeasureValidation(
          -102,
          "a < t",
          "warning",
          'The value you entered is significantly different from recent trends. Please make a note to confirm this value for accuracy.',
          "a,t",
          {
            "a":null,
            "t":null
          },
          this.currentUser.userPrincipalName));
          
      }
    );
  }
  
  getMeasureTrendValue():void {
    const siteId: number = this.selectedSite.id;
    const measureId: number = this.selectedMeasure.id;
    const year: number = this.selectedYear;

    this.measureService.getMeasureValidationPrediction(measureId, siteId, year).subscribe(
      (y: MeasurePrediciton) => {
        this.prediction = y;
      });
  }

  openActiveYear() {
    this.isActiveYearsOpen = true;
  }

  getExistingToOverwrite(){
    this.datacollectionsService.getDataCollections(this.selectedSite.id,
                                                    this.selectedMeasure.id,
                                                    this.selectedYear,
                                                    null,
                                                    null
    ).subscribe(data => {    
      const results = data[data.length-1];
      if(results.timePeriodType != null && results.timePeriodType !== this.selectedTimePeriodType && (results.total ?? 0) !== 0){
          this.existingDataCollections = results.timePeriodType;
          var overwriteValidation = new MeasureValidation(
            -199,
            "1 == 0",
            "warning",
            'As a reminder, you will overwrite existing ' + this.existingDataCollections + ' data for fiscal year ' + this.selectedYear + ' if you continue.',
            "",
            {},
            this.currentUser.userPrincipalName);
          this.validationRule.push(overwriteValidation);
          this.openWarnOverwriteDataModal(overwriteValidation);
      }
      else
        this.existingDataCollections = null;
    });
  }
  
  refreshSiteMeasureData(){
    this.datacollectionsService.getDataCollections(this.selectedSite.id,
                                                    this.selectedMeasure.id,
                                                    this.selectedYear,
                                                    this.selectedTimePeriodType,
                                                    this.selectedTimePeriod
    ).subscribe(data => {
      this.manageDataCollections = data;
      this.dataCollectionsCount = this.manageDataCollections.length;
      this.manageDataCollections = this.manageDataCollections.sort((nodeA, nodeB) => compareItems(nodeA['calendarPeriod'], nodeB['calendarPeriod']));
      this.currentPeriodData = this.manageDataCollections[this.dataCollectionsCount-1];

      this.selectedYear = this.currentPeriodData.year;
      this.selectedTimePeriodType = this.currentPeriodData.timePeriodType;
      this.selectedTimePeriod = this.currentPeriodData.timePeriod;
      // this.selectedAgeGroup = this.currentPeriodData.ageGroup;

      this.updateDataCollectionsForm(this.currentPeriodData);

      this.getValidationRule();
      this.getMeasureTrendValue();
      this.getExistingToOverwrite();

      this.isChangeTimePeriodType = false;
      this.isAddOpen = true;
      this.isDiscardChanges = true;
      this.hasInitialValueChange = false;
    });
  }

  changeTimePeriodType(){
    this.isChangeTimePeriodType = true;
    if(this.selectedTimePeriodType == TimePeriodType.Annual)
      this.selectedTimePeriod = this.selectedYear;
    else
      this.selectedTimePeriod = null;
    this.changeData();
  }

  changeFiscalYear(){
    if(this.selectedTimePeriodType == TimePeriodType.Annual)
      this.selectedTimePeriod = this.selectedYear;
    this.changeData();
  }

  changeData() {
    if(this.hasInitialValueChange && !this.isDiscardChanges){
      this.openConfirmationModal();
      return;
    }
    
    this.refreshSiteMeasureData();
  }

  hideEthnicity() {
    this.isEthnicityRequired = false;
  }

  showEthnicity() {
    this.isEthnicityRequired = true;
  }

  saveAndContinue() {
    if (this.confirmationModalComponent) {
      this.closeConfirmModal(false);
    }
    this.refreshDataAfterSave = true;
    this.AddUpdateDataCollections();
    
  }

  discardAndContinue() {
    this.isDiscardChanges = true;
    if (this.confirmationModalComponent) {
      this.closeConfirmModal(false);
    }
    this.changeData();
  }

  exportSiteMeasureData() {
    this.datacollectionsService.exportSiteMeasureData(this.selectedSite.id).subscribe(data => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      FileSaver.saveAs(data, +'sites_list.xlsx');
    });
  }

  ValidateFeMaleShouldNotGreaterThanMale(control:AbstractControl): ValidationErrors | null  {

    const genderTotalValue = control.get('genderValue')?.value;
    const raceTotalValue = control.get('raceValue')?.value;

    if (raceTotalValue !== genderTotalValue ) {
      control.get('genderFemale').setErrors({ genderAndRaceNotEqual: true });
      control.get('genderMale').setErrors({ genderAndRaceNotEqual: true });
      control.get('genderNA').setErrors({ genderAndRaceNotEqual: true });
      control.get('genderNonBinary').setErrors({ genderAndRaceNotEqual: true });
      control.get('raceAIAKNative').setErrors({ genderAndRaceNotEqual: true });
      control.get('raceAsian').setErrors({ genderAndRaceNotEqual: true });
      control.get('raceBAA').setErrors({ genderAndRaceNotEqual: true });
      control.get('raceHispanic').setErrors({ genderAndRaceNotEqual: true });
      control.get('raceOther').setErrors({ genderAndRaceNotEqual: true });
      control.get('raceWhite').setErrors({ genderAndRaceNotEqual: true });
      control.get('racesTwoOrMore').setErrors({ genderAndRaceNotEqual: true });
      control.get('racesNA').setErrors({ genderAndRaceNotEqual: true });
      return ({ genderAndRaceNotEqual: true})
    }
    else {
      control.get('genderFemale').setErrors(null);
      control.get('genderMale').setErrors(null);
      control.get('genderNA').setErrors(null);
      control.get('genderNonBinary').setErrors(null);
      control.get('raceAIAKNative').setErrors(null);
      control.get('raceAsian').setErrors(null);
      control.get('raceBAA').setErrors(null);
      control.get('raceHispanic').setErrors(null);
      control.get('raceOther').setErrors(null);
      control.get('raceWhite').setErrors(null);
      control.get('racesTwoOrMore').setErrors(null);
      control.get('racesNA').setErrors(null);
      ({ genderAndRaceNotEqual: false})
    }

  }

  openImportSiteDataConfirmationModal() {
    if (this.importSiteDataconfirmationModal) {
      this.importSiteDataconfirmationModal.entityData = {
        headerText: 'Import Site Data',
        entityData: null
      };
      this.importSiteDataconfirmationModal.showModal();
    }
  }

  closeBulkUploadConfirmModal(event) {
    if (this.importSiteDataconfirmationModal) {
      this.importSiteDataconfirmationModal.closeModalWindow();
    }
  }

  getImportJobDetails(){
    this.siteMeasureService.getImportJobStatus().subscribe(data=>{
        if(data.some(a=>a.site.id==this.selectedSite.id && (a.status!='Approved' ||'Review Pending-validate' || 'NotStarted-Validate') ))
          this.hasImportJobs = true;
        else
          this.hasImportJobs = false;
    });
  }

  onSuccessDismiss(){
    this.formSubmitSuccess = null;
  }

  // toggleShowAdult() {
  //   if(this.isAdult)
  //     this.selectedAgeGroup = "Juvenile"
  //   else
  //     this.selectedAgeGroup = "Juvenile";
  // }

}