<div class="navbar-top header-row no-print">  
  <div class="navbar-top-header">   
    <div class="header-logo">   
      <a href="/">
          <img src="../../../assets/images/CaseyPrimaryLogoColor.png" class="logo" alt="logo"/>
      </a>
    </div>
    <div class="header-all">
      <p class="jdai-title">AECF Portal Configuration</p>
    </div>
  </div>
  <div class="navbar-right-link">
      <ul class="header-link">
        <li *ngIf="userName" class="login_links logout_links">
          <ng-container *ngIf="userName">
            <div class="btn-group" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                <ng-container><span class="user-icon"><em class="far fa-user-circle"></em></span> {{ userName | firstWord : ' '}}</ng-container>
                <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">   
                <li role="menuitem"><a (click)="logout()" class="dropdown-item">LOG OUT</a></li>
              </ul>
            </div>
          </ng-container>
        </li>
        <li *ngIf="!loginDisplay" class="login_links logout_links">
          <button mat-menu-item (click)="loginRedirect()">Login</button>
        </li>
      </ul>
  </div>
</div>