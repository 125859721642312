import { FileAttributes } from 'src/app/common/models/file-definitions';

export class LinkItem {
    linkValue: string;
    fileAttributes: FileAttributes;
    isUploading: boolean;
    isUploaded: boolean;
    isError: boolean;
    warning: boolean;
    message: string;
    retry: boolean;

    constructor(linkValue: string, fileAttributes: FileAttributes) {
        this.linkValue = linkValue;
        this.fileAttributes = fileAttributes;
        this.isUploaded = false;
        this.isError = false;
        this.warning = false;
        this.retry = false;
    }
}

export class TagItem {
    item: string;
    tagged: boolean;
    continent: string;
    country: string;
    region: string;
    division: string;
    state: string;

    constructor(item: string, tagged: boolean, continent: string, country: string, region: string, division: string, state: string) {
        this.item = item;
        this.tagged = tagged;
        this.continent = continent;
        this.country = country;
        this.region = region;
        this.division = division;
        this.state = state;
    }
}

export class EditDetail {
    fileTitle: string;
    externalUrl: string;
    description: string;
    fileName: string;
    programArea: Array<string>;
    topic: Array<string>;
    country: Array<string>;
    continent: Array<string>;
    region: Array<string>;
    division: Array<string>;
    state: Array<string>;
    fileType: Array<string>;
    jurisdiction: Array<string>;
    contentType: Array<string>;
    privacyType: Array<string>;
    community: Array<string>;
    publisher: Array<string>;
    publicationDate: string;
    uploadedDate: string;
    uploadedBy: string;
    containerName: string;
    formFilesField: any[];
    linkField: any[];

    public static fileMetadataModelMapper(uiModel: any, fileName: string, containerName: string) {
        return Object.assign({
            fileName,
            containerName,
            fileTitle: EditDetail.mapFileTitle(uiModel),
            externalUrl: uiModel['externalUrl'],
            description: uiModel['description'],
            programArea: uiModel['programArea'],
            topic: uiModel['topic'],
            continent: EditDetail.mapLocationItem(uiModel['continent']),
            country: EditDetail.mapLocationItem(uiModel['country']),
            region: EditDetail.mapLocationItem(uiModel['region']),
            division: EditDetail.mapLocationItem(uiModel['division']),
            state: EditDetail.mapLocationItem(uiModel['state']),
            jurisdiction: EditDetail.mapLocationItem(uiModel['jurisdiction']),
            fileType: EditDetail.mapFileType(uiModel),
            contentType: uiModel['contentType'],
            privacyType: uiModel['privacyType'],
            community : uiModel['community'],
            publisher : uiModel['publisher'],
            publicationDate : uiModel['publicationDate']
        });
    }

    public static mapFileTitle(uiModel: EditDetail) {
        let fileTitle = '';
        if (uiModel.formFilesField && uiModel.formFilesField.length > 0) {
            fileTitle = uiModel.formFilesField[0].kcTitleField;
        } else if (uiModel.linkField && uiModel.linkField.length > 0) {
            fileTitle = uiModel.linkField[0].kcTitleField;
        }

        return fileTitle;
    }

    public static mapFileType(uiModel: EditDetail) {
        let fileTitle = '';
        if (uiModel.formFilesField && uiModel.formFilesField.length > 0) {
            fileTitle = uiModel.formFilesField[0].fileTypeField;
        } else if (uiModel.linkField && uiModel.linkField.length > 0) {
            fileTitle = uiModel.linkField[0].fileTypeField;
        }

        return fileTitle;
    }

    public static mapLinkItem(linkItem: any) {
        return {
            fileName: linkItem.fileName,
            externalUrl: linkItem.externalUrlField.linkValue,
            fileTitle: linkItem.kcTitleField
        };
    }

    public static mapLocationItem(item: any) {
        if (item && item.length > 0) {
            return item.map(element => element.item);
        } else {
            return undefined;
        }
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData);
        return this;
    }
}
