import { compareItems } from 'src/app/common/models/utility';
export const  extentionRegEx = /(?:\.([^.]+))?$/;

export const metadataTags = {
    topic: 'Topic',
    programArea: 'Program Area',
    location: 'Location',
    contentType: 'Content Type',
    privacyType: 'Privacy Type',
    fileType: 'File Type',
   // community: 'Community'
};

export const allMetadataTags = {
    topic: 'Competency Area',
    programArea: 'Competency Sub-area',
    location: 'Location',
    contentType: 'Content Type',
    privacyType: 'Privacy Type',
    fileType: 'File Type',
   // community: 'Community',
    //publisher: 'Publisher'
};

export const ftlaMetadataTags = {
    topic: 'Topic ',
    programArea: 'Topic Sub-area',
    location: 'Location',
    contentType: 'Content Type',
    privacyType: 'Privacy Type',
    fileType: 'File Type',
};


export const locationTags = {
    continent: 'continent',
    country: 'country',
    region: 'region',
    division: 'division',
    state: 'state',
    jurisdiction: 'jurisdiction'
};
export const visionlocationTags = {
    jurisdiction: 'jurisdiction'
};
export enum AccessFor {
    None = 0,
    Grantee = 1,
    Staff = 2,
    Partner = 3
}
export namespace AccessFor {
  export function values() {
    return Object.keys(AccessFor).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

export class FileType {
    extension: string;
    extensionList: string[];
    fileType: string;

    constructor(fileType: string, extension: string) {
        this.fileType = fileType;
        this.extensionList = [extension];
    }
}

export function organizeFileTypeTags(fileTypes: Array<FileType>) {
    return fileTypes.reduce((groups, element) => {
        const item = elementExists(groups, 'fileType', element);
        if (item) {
            item.extensionList.push(element['extension']);
        } else {
            groups.push(new FileType(element['fileType'], element['extension']));
        }
        return groups;
    }, []);
}

export function organizeMetadataTags(metadataItems: Array<string>, is_all: boolean = false) {
    const organizedItems = {};
    if(is_all) {
        Object.keys(allMetadataTags).forEach(element => {
            if (element !== 'fileType' && element !== 'topic' && element !== 'programArea' && (metadataItems.indexOf(element) >= 0 || element === 'location')) {
                organizedItems[element] = allMetadataTags[element];
            }
        });
    } else {
        Object.keys(metadataTags).forEach(element => {
            if (element !== 'fileType' && (metadataItems.indexOf(element) >= 0 || element === 'location')) {
                    organizedItems[element] = metadataTags[element];
                }
            });
    }

    return organizedItems;
}

export function sortLocationMetadataTags(locationItems: any, isFtla: boolean = false) {
    if(locationItems) {
        if (isFtla) {
            locationItems.sort((nodeA, nodeB) => compareItems(nodeA['continent'], nodeB['continent']));
            for (const continent of locationItems) {
                continent.countryList.sort((nodeA, nodeB) => compareItems(nodeA['country'], nodeB['country']));
                for (const country of continent.countryList) {
                    country.regionList.sort((nodeA, nodeB) => compareItems(nodeA['region'], nodeB['region']));
                    for (const region of country.regionList) {
                        region.divisionList.sort((nodeA, nodeB) => compareItems(nodeA['division'], nodeB['division']));
                        for (const division of region.divisionList) {
                            division.stateList.sort((nodeA, nodeB) => compareItems(nodeA['state'], nodeB['state']));
                            for (const state of division.stateList) {
                                state.countyList.sort((nodeA, nodeB) => compareItems(nodeA['county'], nodeB['county']));
                            }
                        }
                    }
                }
            }
        } else {
            locationItems.sort((nodeA, nodeB) => compareItems(nodeA['country'], nodeB['country']));
            for (const country of locationItems) {
                country.regionList.sort((nodeA, nodeB) => compareItems(nodeA['region'], nodeB['region']));
                for (const region of country.regionList) {
                    region.divisionList.sort((nodeA, nodeB) => compareItems(nodeA['division'], nodeB['division']));
                    for (const division of region.divisionList) {
                        division.stateList.sort((nodeA, nodeB) => compareItems(nodeA['state'], nodeB['state']));
                        for (const state of division.stateList) {
                            state.countyList.sort((nodeA, nodeB) => compareItems(nodeA['county'], nodeB['county']));
                        }
                    }
                }
            }
        }
    }
    return locationItems;
}

export function organizeLocationMetadataTags(locationItems: any, isFtla: boolean = false) {
    if(locationItems.length==1) {
        return isFtla ? 
        {
            continent: locationItems[0].continent,
            countryList:[
                {
                    country: locationItems[0].country,
                    regionList:[
                            {
                                region:locationItems[0].region,
                                divisionList:[
                                {
                                    division:locationItems[0].division,
                                    stateList:[
                                    {
                                        state:locationItems[0].state,
                                        countyList:[
                                            {
                                                county:locationItems[0].county
                                            }                           
                                        ]
                                    }
                                    ]
                                }               
                            ]
                        }                                 
                    ]
                }
            ]
         }: 
                    {
                        country:locationItems[0].country,
                        regionList:[
                            {
                                region:locationItems[0].region,
                                divisionList:[
                                {
                                    division:locationItems[0].division,
                                    stateList:[
                                        {
                                            state:locationItems[0].state,
                                            countyList:[
                                            {
                                                county:locationItems[0].county
                                            }                           
                                            ]
                                        }
                                    ]
                                }               
                                ]
                            }                                 
                        ]
                    };
    }
    else if (isFtla) {
        return locationItems.reduce((groups, element) => {
            const continent = elementExists(groups, locationTags.continent, element);
            if (continent) {
                const country = elementExists(continent[locationTags.country + 'List'], locationTags.country, element);
                if (country) {
                    const region = elementExists(country[locationTags.region + 'List'], locationTags.region, element);
                    if (region) {
                        const division = elementExists(region[locationTags.division + 'List'], locationTags.division, element);
                        if (division) {
                            const state = elementExists(division[locationTags.state + 'List'], locationTags.state, element);
                            if (state) {
                                const county = elementExists(state['countyList'], 'county', element);
                                if (!county) {
                                    state['countyList'].push({county: element['county']});
                                }
                            } else {
                                division[locationTags.state + 'List'].push({state: element[locationTags.state],
                                    ['countyList']: []});
                            }
                        }  else {
                            region[locationTags.division + 'List'].push({division: element[locationTags.division],
                                [locationTags.state + 'List']: []});
                        }
                    }  else {
                        country[locationTags.region + 'List'].push({region: element[locationTags.region],
                            [locationTags.division + 'List']: []});
                    }
                } else {
                    continent[locationTags.country + 'List'].push({country: element[locationTags.country],
                        [locationTags.region + 'List']: []});
                }
            } else {
                groups.push({continent: element[locationTags.continent], [locationTags.country + 'List']: []});
            }
            return groups;
        }, []);
    } else {
        return locationItems.reduce((groups, element) => {
            const country = elementExists(groups, locationTags.country, element);
            if (country) {
                if(element[locationTags.country] == 'USA') {
                    const region = elementExists(country[locationTags.region + 'List'], locationTags.region, element);
                    if (region) {
                        const division = elementExists(region[locationTags.division + 'List'], locationTags.division, element);
                        if (division) {
                            const state = elementExists(division[locationTags.state + 'List'], locationTags.state, element);
                            if (state) {
                                const county = elementExists(state['countyList'], 'county', element);
                                if (!county) {
                                    state['countyList'].push({county: element['county']});
                                }
                            } else {
                                division[locationTags.state + 'List'].push({state: element[locationTags.state],
                                    ['countyList']: []});
                            }
                        }  else {
                            region[locationTags.division + 'List'].push({division: element[locationTags.division],
                                [locationTags.state + 'List']: []});
                        }
                    }  else {
                        country[locationTags.region + 'List'].push({region: element[locationTags.region], [locationTags.division + 'List']: []});
                    }
                }
            } else {
                if(element[locationTags.country] == 'USA') {
                    groups.push({country: element[locationTags.country], [locationTags.region + 'List']: []});
                }
            }
            return groups;
        }, []);
    }
}

export function elementExists(groups: Array<any>, key: string, src: any) {
    if (groups) {
        return groups.find(ele => ele[key] === src[key]);
    }
    return null;
}

export function updateMetadataTags(metadataItems: any, formData: any) {
    metadataItems.programArea.forEach(formValue => {
        formData.append('programArea[]', formValue);
    });
    metadataItems.topic.forEach(formValue => {
        formData.append('Topic[]', formValue);
    });
    // metadataItems.community.forEach(formValue => {
    //     formData.append('Community[]', formValue);
    // });
    metadataItems.contentType.forEach(formValue => {
        formData.append('ContentType[]', formValue);
    });
    metadataItems.privacyType.forEach(formValue => {
        formData.append('PrivacyType[]', formValue);
    });
    metadataItems.jurisdiction.forEach(formValue => {
        formData.append('Jurisdiction[]', formValue.item);
    });
    metadataItems.focus.forEach(formValue => {
        formData.append('Focus[]', formValue);
    });
    metadataItems.continent.forEach(formValue => {
        formData.append('Continent[]', formValue.item);
    });
    metadataItems.country.forEach(formValue => {
        formData.append('Country[]', formValue.item);
    });
    metadataItems.region.forEach(formValue => {
        formData.append('Region[]', formValue.item);
    });
    metadataItems.division.forEach(formValue => {
        formData.append('Division[]', formValue.item);
    });
    metadataItems.state.forEach(formValue => {
        formData.append('State[]', formValue.item);
    });
    metadataItems.authors.forEach(formValue => {
        formData.append('PersonalAuthor[]', formValue);
    });
    // metadataItems.publisher.forEach(formValue => {
    //     formData.append('Publisher[]', formValue);
    // });

    formData.set('Description', metadataItems.description);
    //formData.set('AccessFor', metadataItems.accessFor);
   // formData.set('PublicationDate', metadataItems.publicationDate);
}
