import { Component, OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {formatDate} from '@angular/common';
import { GroupServices } from '../services/group.services';
import { GroupModel } from '../models/group.model';
import { SiteMeasureServices } from '../services/siteMeasure.services';
import { SiteMeasureModel } from '../models/siteMeasure.model';
import { SiteModel } from '../models/site.model';
import * as FileSaver from 'file-saver';
import { SystemPermissions } from 'src/app/permissions';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, Event, NavigationEnd, RouterEvent } from '@angular/router';
// import { Observable } from 'rxjs/internal/Observable';
// import 'rxjs/add/observable/timer';

@Component({
  selector: 'app-sitemeasures',
  templateUrl: './sitemeasures.component.html',
  styleUrls: ['./sitemeasures.component.scss']
})

export class SitemeasuresComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  managedSiteMeasureList: Array<SiteMeasureModel>;
  removedSiteMeasureList: Array<SiteMeasureModel>;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageSiteMeasures: Array<SiteMeasureModel>;
  selectedSiteMeasure: SiteMeasureModel = null;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  siteMeasureForm: FormGroup;
  searchTerm: string='';
  currentUserRole: Roles;
  isSiteExists:boolean;
  selectedSite: SiteModel;
  currentUser: any;
  lastSort:string;
  groupList:Array<GroupModel>;
  selectedGroupMeasures:Array<SiteMeasureModel>;
  hasInitalValueChange : boolean =false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  permissions = SystemPermissions;
  isAllMeasuresActive:boolean;
  selectedGroupPanel:any;
  accordinGroup:boolean= false;
  isAllSiteMeasureClicked:boolean=false;
  isLoading:boolean=false;
  public showloader: boolean = false;      
  private subscription: Subscription;
  //private timer: Observable<any>;
  timeout;
  loader = false;
  isJJDataPortal:boolean;
  constructor(
              private stateService: StateService,
              public googleAnalyticsService: GoogleAnalyticsService,
              public datepipe: DatePipe,
              private groupService: GroupServices,private siteMeasureService: SiteMeasureServices
              ) {
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin && this.stateService.getViewUserRole() === Roles.Admin; }

 get isCaseyConsultant() { return this.stateService.getRole() === Roles.CaseyConsultant; }

  get isState() { return this.stateService.getRole() === Roles.State; }

  get isProbationChief() { return this.stateService.getRole() === Roles.ProbationChief; }

  get isOtherLocalLeader() { return this.stateService.getRole() === Roles.OtherLocalLeader; }

  get isDataLead() { return this.stateService.getRole() === Roles.DataLead; }

  ngOnInit() {
    this.selectedSite= JSON.parse(sessionStorage.getItem('site')) as SiteModel;
    this.managedSiteMeasureList = new Array<SiteMeasureModel>();
    this.removedSiteMeasureList = new Array<SiteMeasureModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    this.fetchGroups();
    
    this.siteMeasureForm = new FormGroup({
      siteDefinition:new FormControl(''),
      source:new FormControl(''),
      caseyTANotes:new FormControl(''),
      stateTANotes:new FormControl(''),
      isActive:new FormControl('')
    });

    this.onSiteMeasureFormValueChange();
    this.isJJDataPortal = this.stateService.isJJGroup();

  }

  modifySet(group: GroupModel, openModal: boolean, isOpen: boolean) {
    this.selectedGroupMeasures=new Array<SiteMeasureModel>();
    this.accordinGroup=isOpen;
    this.selectedGroupPanel = group;
    this.isLoading = isOpen;
    this.loader = true;
    this.selectedGroupMeasures = this.manageSiteMeasures.filter(x=>x.group.id == group.id);
   this.selectedGroupMeasures.sort((a,b)=>(a.measure.number >b.measure.number) ?1 :-1);
    if(this.searchTerm.length>2){
      this.selectedGroupMeasures = this.selectedGroupMeasures.filter(x=>x.measure.measure.toLowerCase().includes(this.searchTerm.toLowerCase()) 
              || x.measure.number.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
    this.timeout = setTimeout(() => {
    clearTimeout(this.timeout);
    document.getElementById("tbGroup").onload;
    this.loader = false;
  }, 1000);
  } 
 
  fetchGroups() {
    this.groupService.getSiteMeasuresGroup()
    .subscribe(data => {
      this.groupList =data.filter(x=>{return x.order!=15;});
        this.groupList.sort((nodeA, nodeB) => compareItems(nodeA['order'], nodeB['order']));
        this.getSiteMeasures();
    });
  }

  AddUpdateActiveYears()
  {
    this.resetAddForm();
  }

  padLeadingZeros(num, size):string {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

  
  isValidSite() {
    const siteControl = this.siteMeasureForm.get('name');
    if (siteControl.value && !siteControl.errors) {
      return true;
    }
  }

  getSiteMeasures() {
    this.siteMeasureService.getSiteMeasures(this.selectedSite.id)
    .subscribe(data => {
      this.manageSiteMeasures =data;
      if(!this.isAdmin){
        this.manageSiteMeasures= this.manageSiteMeasures.filter(x=> {return x.isActive==true;});
      }
      this.isAllMeasuresActive = this.manageSiteMeasures.filter(m => m.isActive == true).length>0;
        this.manageSiteMeasures.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
        this.groupList=  this.groupList.filter(p=>p).map(p=> {
            let sitemeasures = this.manageSiteMeasures.filter(s=>s.group.id == p.id);
              if(sitemeasures.filter(s=>s.isActive == false).length>0)
                p.isAllMeasuresActive=false;
              else  if(sitemeasures.filter(s=>s.isActive == true).length>0)
                p.isAllMeasuresActive=true;
                else
                p.isAllMeasuresActive=false;
          return p;
        });
        console.log(this.groupList);
    });
  }

  resetAddForm() {
    this.selectedSiteMeasure = null;
    this.isAddOpen = false;
    this.siteMeasureForm.reset();
  }

  addSite() {
    this.isAddOpen = true;
  }
 
  get enableSave():boolean{
    if(this.managedSiteMeasureList.length>0){
      return true;
    }
    else if(this.selectedItems.length>1){
      return true;
    }
    return false;
  }

  onSiteMeasureFormValueChange(){
    const initialValue = this.siteMeasureForm.value
    this.siteMeasureForm.valueChanges.subscribe(value => {
      this.hasInitalValueChange = Object.keys(initialValue).some(key => this.siteMeasureForm.value[key] != 
                        initialValue[key]);
    });
}

cancelAddUpdateModel(){
  this.resetAddForm();
}
ischeckPermissions(permission: string): boolean {
  return this.stateService.permissionCheck(permission);
}
  AddUpdateSite():void {
      this.selectedSiteMeasure.siteDefinition = this.siteMeasureForm.get('siteDefinition').value;
      this.selectedSiteMeasure.source = this.siteMeasureForm.get('source').value;
      this.selectedSiteMeasure.caseyTANotes = this.siteMeasureForm.get('caseyTANotes').value;
      this.selectedSiteMeasure.stateTANotes = this.siteMeasureForm.get('stateTANotes').value;
      this.selectedSiteMeasure.isActive = (this.siteMeasureForm.get('isActive').value == '' || this.siteMeasureForm.get('isActive').value == null) ? false:true;
      this.selectedSiteMeasure.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");
      this.selectedSiteMeasure.site = this.selectedSite;
      this.managedSiteMeasureList.push(this.selectedSiteMeasure);
      this.saveSiteMeasures();
     this.resetAddForm();
  }

  exportSiteMeasure(){
    this.siteMeasureService.exportMeasures(this.selectedSite.id).subscribe(data=>{
      const blob = new Blob([data], { type: 'application/octet-stream' });
      FileSaver.saveAs(data, this.selectedSite.name +'_measures_list.xlsx');
    });
  }
  saveSiteMeasures() :void {
    if(this.managedSiteMeasureList.length>0) {
      this.managedSiteMeasureList.map(s=>{
        s.lastUpdatedDateTime = new Date().toJSON();
        return s;
      });
      this.siteMeasureService.saveSiteMeasures(this.managedSiteMeasureList)
        .subscribe((data) => {
          this.formSubmitSuccess = "Successfully saved sites";
          this.getSiteMeasures();
          this.managedSiteMeasureList =new Array<SiteMeasureModel>();
      });
    }
  }
  
  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.resetAddForm();
  }

  search():void{
    if(this.searchTerm.length>2){
        this.groupList.forEach(group => {
          this.modifySet(group, false, true);
      });
    }
  }

  editMeasure(siteMeasure:SiteMeasureModel):void {
    this.selectedSiteMeasure = siteMeasure;
    this.isAddOpen=true;
    this.siteMeasureForm.get('siteDefinition').setValue(siteMeasure.siteDefinition);
    this.siteMeasureForm.get('source').setValue(siteMeasure.source);
    this.siteMeasureForm.get('stateTANotes').setValue(siteMeasure.stateTANotes);
    this.siteMeasureForm.get('caseyTANotes').setValue(siteMeasure.caseyTANotes);
    this.siteMeasureForm.get('isActive').setValue(siteMeasure.isActive);
  }

  toggleSiteMeasureStatus(event: any) {
    let group= new GroupModel(0,'',false,'');
    const isChecked: boolean = event.target['checked'];
    this.isAllSiteMeasureClicked = true;
    this.isAllMeasuresActive = isChecked;
    this.openConfirmationModal(event.target.checked,this.selectedSite.id,group);
  }
  
toggleSiteGroupMeasureStatus(event: any, group: GroupModel){
    console.log("Toggle group "+event.target.checked);
    const isChecked: boolean = event.target['checked'];
    group.isAllMeasuresActive = isChecked;
    this.openConfirmationModal(event.target.checked,this.selectedSite.id,group);
  }

  closeConfirmModal(group:GroupModel) {
    if (this.confirmationModalCompoent) {
      
      if(this.isAllSiteMeasureClicked == true)
      {
        this.isAllMeasuresActive = this.isAllMeasuresActive==true ? false:true;
        this.isAllSiteMeasureClicked = false;
      }
      group.isAllMeasuresActive = group.isAllMeasuresActive ? false:true;
      this.confirmationModalCompoent.closeModalWindow();
      this.accordinGroup=false;
    }
  }

  openConfirmationModal(isActive:boolean,siteId:number,group:GroupModel) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      this.defaultConfirmationModalEntity(isActive,siteId,group);
    }
  }

  defaultConfirmationModalEntity(isActive:boolean,siteId:number,group:GroupModel) {
    let status = isActive ? 'activate' :'deactivate';
    this.confirmationModalCompoent.entityData = { entityData: {
        message: 'Are you sure you want to ' + status + ' all measures',
        isActive:isActive,
        siteId:siteId,
        group:group
      }
    };
  }

  confirmToUpdateSiteMeasures(data:any){
    this.siteMeasureService.updateSiteMeasuresStatus(data.siteId,data.group.id,data.isActive).subscribe(x=>{
      this.formSubmitSuccess = "Successfully updated site/group measures status";
      if(this.isAllSiteMeasureClicked == true)
      {
        this.isAllMeasuresActive = this.isAllMeasuresActive;
        this.isAllSiteMeasureClicked = false;
      }
      this.confirmationModalCompoent.closeModalWindow();
      this.getSiteMeasures();
      this.accordinGroup=false;
   });  
  }
}

