import { Injectable, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';

@Injectable()
export class ReportConfigurationService {

  constructor(private repository: RepositoryService) { 

  }

  getReports(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/reports/community/all');
   }
  getAllDepartments():Observable<any>{
     return this.repository.get(environment.apiBaseURL, 'api/user/departments/all');
    }
    getAllJobTitles(departments:string):Observable<any>{
      return this.repository.post(environment.apiBaseURL, 'api/user/jobtitles', departments);
    }
    getReportProfiles():Observable<any>{
      return this.repository.get(environment.apiBaseURL, 'api/reports/profiles');
    }
    updateReportProfiles(reportProfiles:any):Observable<any>{
      return this.repository.post(environment.apiBaseURL, 'api/reports/update/profiles',reportProfiles);
    }
    updateReports(reports:any):Observable<any>
    {
    return this.repository.post(environment.apiBaseURL, 'api/reports/update', reports);
    }

    getSurveyPeriods():Observable<any>{
      return this.repository.get(environment.apiBaseURL, 'api/reports/surveyperiods');
    }

    updateSurveyPeriods(surveyperiods:any):Observable<any>{
        return this.repository.post(environment.apiBaseURL, 'api/reports/surveyperiods', surveyperiods);
    }
}
