import { formatDate } from '@angular/common';
import { SiteMeasureModel } from './siteMeasure.model';

export class GroupModel {
    groupMeasures:Array<SiteMeasureModel>
    id: number;
    isActive:boolean;
    isAllMeasuresActive:boolean;
    lastUpdated: string;
    lastUpdatedDateTime:string;
    name: string;
    order: number;
    constructor(id:number,name:string,isActive:boolean,lastUpdated:string){
        this.id=id;
        this.name=name;
        this.isActive = isActive;
        this.lastUpdated = lastUpdated;
        this.lastUpdatedDateTime = formatDate(Date.now().toString(),'yyyy-MM-dd HH:mm','en-US');
    }
}