import { Component, OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { GroupModel } from '../models/group.model';
import { GroupServices } from '../services/group.services';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  @ViewChild('warningModal', {static: false}) warningModal: ModalComponent;
  groupList: Array<GroupModel>;
  managedGroupList: Array<GroupModel>;
  removedGroupList: Array<GroupModel>;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageGroups: Array<GroupModel>;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  isLoading = false;
  groupForm: FormGroup;
  searchTerm: string;
  currentUserRole: Roles;
  isGroupExists:boolean;
  selectedGroup: GroupModel;
  currentUser: any;
  lastSort:string;
  regionName:string;
  groupAsc: boolean = true;
  lastUpdatedAsc:boolean = true;
  lastUpdatedDateTimeAsc:boolean = true;
  isActiveAsc:boolean = true;
  buttonText:string = "Add Group";

  constructor(private groupService: GroupServices,
              private stateService: StateService,
              public googleAnalyticsService: GoogleAnalyticsService,
              public datepipe: DatePipe) {
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }

  ngOnInit() {
    this.managedGroupList = new Array<GroupModel>();
    this.removedGroupList = new Array<GroupModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    
    this.fetchGroups();
    this.groupForm = new FormGroup({
      name:new FormControl('', Validators.required),
      isActive:new FormControl('')
    });

    this.groupForm.controls['name'].valueChanges.subscribe(x=>{
        let groupName = this.groupForm.get('name').value;
        if(groupName !=null && groupName!=''){
        if(this.manageGroups.length!==0 || this.groupList.length !== 0){
        let itemIndex = this.groupList.findIndex(x=>x.name.toLocaleLowerCase() == groupName.toLocaleLowerCase());
        let manageGroupIndex = this.manageGroups.findIndex(x=>x.name.toLocaleLowerCase() == groupName.toLocaleLowerCase());
        if((itemIndex>=0 || manageGroupIndex>=0) && this.selectedGroup===null){
          this.isGroupExists = true;
        }else{
          this.isGroupExists = false;
        }
      }
    }
    });
  }

  get enableSave():boolean{
    if(this.groupList.length>0){
      return true;
    }else if(this.managedGroupList.length>0){
      return true;
    }
    else if(this.removedGroupList.length>0){
      return true;
    }
    return false;
  }


  isValidGroup() {
    const groupControl = this.groupForm.get('name');
    if (groupControl.value && !groupControl.errors) {
      return true;
    }
  }

  getGroups() {
    this.managedGroupList.length=0;
    this.removedGroupList.length=0;
    this.groupService.getGroup()
    .subscribe(data => {
      this.groupList =data;
        this.groupList.sort((nodeA, nodeB) => compareItems(nodeA['number'], nodeB['number']));
        this.manageGroups = this.groupList.slice(0, 10);
        this.currentPage=1;
        this.totalRows = this.groupList.length;
    });
  }

  fetchGroups() {
    this.isLoading = true;
    this.groupService.getGroup()
    .subscribe(data => {
      this.groupList =data;
        this.groupList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
        this.manageGroups = this.groupList.slice(0, 10);
        this.currentPage=1;
        this.isLoading = false;
        this.totalRows = this.groupList.length;
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    this.manageGroups = this.groupList.slice(skipRows, this.currentPage*this.perPage);
  }

  resetAddForm() {
    this.selectedGroup = null;
    this.isAddOpen = false;
    this.groupForm.reset();
  }

   confirmGroupToRemove(group: GroupModel) {
    if (group) {
      this.removedGroupList.push(group);
      this.groupList = this.groupList.filter(x=>x.id!==group.id);
      this.managedGroupList =this.managedGroupList.filter(x=>x.id!==group.id);
      let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
      if(this.groupList.length===0)
      this.groupList= this.managedGroupList;
      this.manageGroups = this.groupList.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
      this.closeConfirmModal();
    }
  }

  openConfirmationModal(group: GroupModel) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      this.defaultConfirmationModalEntity(group);
    }
  }

  defaultConfirmationModalEntity(group: GroupModel) {
    this.confirmationModalCompoent.entityData = { entityData: {
        deleteGroup: group
      }
    };
  }

  updateConfirmationModalEntity(groupToDelete: GroupModel, moderators: GroupModel[]) {
    const users = moderators.filter(x => x.id !== groupToDelete.id);
    this.confirmationModalCompoent.entityData = {
      entityData: {
        deleteGroup: groupToDelete
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.closeModalWindow();
    }
  }

  addGroup() {
    this.isAddOpen = true;
  }

  exportGroups(){
    this.groupService.exportGroups().subscribe(data=>{
      const blob = new Blob([data], { type: 'application/octet-stream' });
      FileSaver.saveAs(data,  +'groups_list.xlsx');
    });
  }
  
  AddUpdateGroup():void {
  
    if(this.selectedGroup ===null || this.selectedGroup === undefined) {
      this.selectedGroup= new GroupModel(-(this.managedGroupList.length+1),'',false,this.currentUser.fullName)
    }
      this.selectedGroup.name = this.groupForm.get('name').value;
      this.selectedGroup.isActive = (this.groupForm.get('isActive').value == '' || this.groupForm.get('isActive').value == null) ? false:true;

      let item = this.manageGroups.find(item => item.id === this.selectedGroup.id);
      let mitem = this.managedGroupList.find(item => item.id === this.selectedGroup.id);
      if(item || mitem){
        if(item){
          item.name = this.selectedGroup.name;
          item.isActive = this.selectedGroup.isActive;
          item.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");;
        }

        if(mitem){
          mitem.name = this.selectedGroup.name;
          mitem.isActive = this.selectedGroup.isActive;
        }
        else{
          this.managedGroupList.push(this.selectedGroup);
        }

      }
      else if(this.selectedGroup.id<0){
      this.manageGroups.push(this.selectedGroup);
      this.managedGroupList.push(this.selectedGroup);
    }
    this.resetAddForm();
  }

  saveGroup() :void {
    if(this.managedGroupList.length>0 || this.removedGroupList.length>0) {
      this.managedGroupList.map(s=>{
        s.lastUpdatedDateTime = new Date().toJSON();
        return s;
      });
      if(this.managedGroupList.length>0){
      this.groupService.saveGroup(this.managedGroupList)
        .subscribe((data) => {
          this.formSubmitSuccess = "Successfully saved groups";
          // this.managedGroupList.length=0;
          this.getGroups();
        });
      }
          if(this.removedGroupList.length>0){
            this.groupService.removeGroup(this.removedGroupList)
             .subscribe((data) => {
              this.formSubmitSuccess = "Successfully removed groups";
              this.getGroups();
           });
          }
   }
  }


  removeGroup(group: GroupModel):void{
    if(group.id>0){
    this.groupService.validateBeforeDelete(group.id)
    .subscribe((data :boolean) => {
      if(data){
        this.openWarningModal();
      }else{
        this.openConfirmationModal(group);
      }
    });
    }
    else{
      this.openConfirmationModal(group);
    }
  }

  openWarningModal() {
    if (this.warningModal) {
      this.warningModal.showModal();
    }
  }

  closeWarningModal(){
    if (this.warningModal) {
      this.warningModal.closeModalWindow();
    }
  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }

  search():void{
    if(this.searchTerm.length===0){
      this.currentPage=0;
      this.manageGroups = this.groupList.slice(this.currentPage, this.perPage);
      this.totalRows = this.groupList.length;
    }
    else{
      this.manageGroups = this.groupList.filter(x=> x.name.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(this.currentPage, this.perPage);
      this.totalRows = this.manageGroups.length;
    }
  }

  toggleTag(column: string) {

    let value = "asc";
    if (column == 'name') {
      this.groupAsc = !this.groupAsc;
      value = this.groupAsc ? "asc" : "desc"
    }
    else if(column == 'lastUpdated')
    {
      this.lastUpdatedAsc = !this.lastUpdatedAsc;
      value = this.lastUpdatedAsc ? "asc" : "desc"
    }
    else if(column == 'lastUpdatedDateTime')
    {
      this.lastUpdatedDateTimeAsc = !this.lastUpdatedDateTimeAsc;
      value = this.lastUpdatedDateTimeAsc ? "asc" : "desc"
    }
    else if(column == 'isActive')
    {
      this.isActiveAsc = !this.isActiveAsc;
      value = this.isActiveAsc ? "asc" : "desc"
    }
   
      this.sortOn(column, value);
  }

  sortOn(column: string, type: string): void {
    if (column !== null) {
      if (type == "desc") {

        this.manageGroups = this.groupList.sort((nodeA, nodeB) => compareItems(nodeB[column], nodeA[column]));

      } else {
        this.manageGroups = this.groupList.sort((nodeA, nodeB) => compareItems(nodeA[column], nodeB[column]));
      }
      let skipRows = (this.currentPage - 1) * this.perPage;
      this.manageGroups = this.groupList.slice(skipRows, this.currentPage * this.perPage);
      this.lastSort = column;
    }
  }

  editGroup(group:GroupModel):void {
    this.selectedGroup = group;
    this.isAddOpen=true;
    this.groupForm.get('name').setValue(group.name);
    this.groupForm.get('isActive').setValue(group.isActive);
    this.buttonText="Save Group";
  }
}