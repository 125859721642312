import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ArchwizardModule } from 'angular-archwizard';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { BlobMetadataComponent } from './blob-metadata/blob-metadata.component';
import { FileUploaderService } from './shared/file-uploader.service';
import { PendingApprovalComponent } from './pending-approval/pending-approval.component';
import { ContentUploadContainerComponent } from './content-upload-container/content-upload-container.component';
import { ContentFileUploadComponent } from './content-file-upload/content-file-upload.component';
import { ContentViewComponent } from './content-view/content-view.component';
import { ContentService } from './shared/content.service';
import { SharedModule } from '../shared/shared.module';
import { ContentRemoveComponent } from './content-remove/content-remove.component';
import { SearchModule } from '../search/search.module';
import { SearchResultsModule } from '../search-results/search-results.module';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ContentEditComponent } from './content-edit/content-edit.component';
import { ContentRemoveThriveComponent } from './content-remove-thrive/content-remove-thrive.component';

@NgModule({
  declarations: [BlobMetadataComponent, PendingApprovalComponent,
    ContentUploadContainerComponent, ContentFileUploadComponent, ContentViewComponent, ContentRemoveComponent, 
    ConfirmationModalComponent, ContentEditComponent, ContentRemoveThriveComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    BsDropdownModule,
    FileUploadModule,
    ArchwizardModule,
    SharedModule,
    SearchModule,
    SearchResultsModule,
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    TypeaheadModule.forRoot()
  ],
  providers: [
    FileUploaderService,
    ContentService
  ]
})
export class ContentModule { }
