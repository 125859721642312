import { Component, OnInit, Renderer2, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup,  FormBuilder,  Validators, ReactiveFormsModule  } from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { allMetadataTags, metadataTags, MetadataChangeRequest, MetadataAction } from '../shared/meta-tags.model';
import { MetaTagsService } from '../shared/meta-tags.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-meta-tags-manage-component',
  templateUrl: './meta-tags-manage.component.html',
  styleUrls: ['./meta-tags-manage.component.scss'],
  providers: []
})
export class MetaTagsManageComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  formSubmitSuccess: string;
  formSubmitError: string;
  tagForm: FormGroup;
  tagsList: Array<any>;
  returnedArray: Array<any>;
  facetsList: Array<any>;
  inputList= [];
  deleteList= [];
  deleteProgramList = [];
  inputListAssociated = [];
  deleteListAssociated = [];
  deleteProgramListAssociated = [];
  userRole: Roles;
  isAdmin = false;
  isAddOpen = false;
  isAllGroup = false;
  isKmsGroup = false;
  displayDescription = false;
  showTopic = false;
  isAllProgram = false;
  metadataTags: any;
  totalRows = 0;
  metaTag = '';
  metaTagSelected = '';
  selectedRowIndex = 0;
  changeRequestList: Array<MetadataChangeRequest> =[];

  constructor( private metaTagsService: MetaTagsService, private router: Router, private currentState: StateService, 
    private formBuilder: FormBuilder, public googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit() {
    if(this.currentState.isKmsGroup()) {
      this.isKmsGroup = true;
    }
    if(this.currentState.isAllGroup()) {
      this.isAllGroup = true,
      this.metadataTags = allMetadataTags;
    } else {
      this.metadataTags = metadataTags;
    }
    this.userRole = this.currentState.getRole();
    if(this.userRole === Roles.Admin) {
      this.isAdmin = true;
    }
    this.resetForm();
  }

  getTagCount(tag, isProgram = false) {
    let tagName = '';
    let tagValue = '';
    if (this.metaTagSelected === 'contentTypes') {
      tagName = 'contentTypes';
      tagValue = tag.contentType;
    } else if (this.metaTagSelected === 'privacyTypes') {
      tagName = 'privacyTypes';
      tagValue = tag.privacyType;
    } else if (this.metaTagSelected === 'publishers') {
      tagName = 'Publisher';
      tagValue = tag.publisher;
    } else if (this.metaTagSelected === 'topics') {
      tagName = (isProgram) ? 'ProgramArea' : 'Topic';
      tagValue = (isProgram) ? tag.program : tag.topic;
    } else if (this.metaTagSelected === 'programs') {
      tagName = 'ProgramArea';
      tagValue = tag.program;
    }
    return this.metaTagsService.getTagCount(tagName, tagValue);
  }

  elementExists(value: any, facetItem: any) {
    if (facetItem) {
      return this.facetsList[facetItem].find(facet => facet.value === value);
    }
    return null;
  }

  isTagVisible(key: any) {
    if(key == 'programs' && this.isAllGroup) {
      return false;
    } else {
      return true;
    }
  }

  resetForm() {
    this.tagForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['' ],
      tagType: ['' ],
      count: ['' ],
      oldTitle: ['' ],
      topic: ['' ],
      id: ['0' ]
    });
  }
  getTagsList() {
    this.tagsList = [];
    this.returnedArray = [];
    this.inputList= [];
    this.deleteList= [];
    this.deleteProgramList= [];
    this.displayDescription = false;
    this.totalRows = 0;
    this.metaTagsService.getTagsList(this.metaTag).subscribe(data => {
      this.metaTagSelected = this.metaTag;
      this.tagForm.get('description').clearValidators();
      if(this.metaTagSelected == 'contentTypes') {
        data.forEach(tag => {
          tag.title = tag.contentType;
        });
      } else if(this.metaTagSelected == 'privacyTypes') {
        data.forEach(tag => {
          tag.title = tag.privacyType;
        });
      } else if(this.metaTagSelected == 'publishers') {
        data.forEach(tag => {
          tag.title = tag.publisher;
        });
      } else if(this.metaTagSelected == 'topics') {
        data.forEach(tag => {
          tag.title = tag.topic;
          if(this.isAllGroup) {
            tag.programs.forEach(program => {
              program.title = program.program;
            });
          }
        });
        if(this.isAllGroup) {
          this.tagForm.get('tagType').setValidators([Validators.required]);
        } else {
          this.tagForm.get('tagType').clearValidators();
        }
      } else if(this.metaTagSelected == 'programs') {
        data.forEach(tag => {
          tag.title = tag.program;
        });
      }
      data.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
      this.tagsList = data;
      this.returnedArray = this.tagsList.slice(0, 10);
      this.totalRows = data.length;
    });
  }
 
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.tagsList.slice(startItem, endItem);
    this.googleAnalyticsService.eventEmitter('Page Navigation Search Results', String(event.page - 1) + ' to ' + event.page, 'Meta Tags');
  }

  showHideTopic() {
    if(this.tagForm.value.tagType == 'program') {
      this.showTopic = true;
    } else {
      this.showTopic = false;
    }
  }

  addTag() {
    
    if (this.tagForm.value.count>0) {
      let changeRequest = {} as MetadataChangeRequest;
      changeRequest.tagId = this.tagForm.value.id;
      changeRequest.tagType = this.metaTagSelected;
      changeRequest.oldValue = this.tagForm.value.oldTitle;
      changeRequest.newValue= this.tagForm.value.title;
      changeRequest.action = MetadataAction.Updated;
      changeRequest.documentCount = this.tagForm.value.count;
     this.changeRequestList.push(changeRequest);
    }
    if(this.metaTagSelected == 'contentTypes') {
        this.inputList.push({
          'id': this.tagForm.value.id,
          'contentType': this.tagForm.value.title,
        });
    } else if(this.metaTagSelected == 'privacyTypes') {
      this.inputList.push({
        'id': this.tagForm.value.id,
        'privacyType': this.tagForm.value.title,
      });
  } else if(this.metaTagSelected == 'publishers') {
        this.inputList.push({
          'id': this.tagForm.value.id,
          'publisher': this.tagForm.value.title
        });
      }
    else if(this.metaTagSelected == 'topics') {
      if(!this.isAllGroup || (this.isAllGroup && this.tagForm.value.tagType == 'topic')) {
          this.inputList.push({
            'id': this.tagForm.value.id,
            'topic': this.tagForm.value.title
          });
      } else {
        let topicIndex = -1;
        let topic = this.inputList.find(tag => tag.topic === this.tagForm.value.topic);
        if(topic != undefined) {
          topicIndex = this.inputList.findIndex(tag => tag.topic === this.tagForm.value.topic);
          if(this.inputList[topicIndex].programs) {
            let programIndex = this.inputList[topicIndex].programs.findIndex(tag => tag.program === this.tagForm.value.title);
            this.inputList[programIndex].programs.push({
              'id': this.tagForm.value.id,
              'program': this.tagForm.value.title
            });
          } else {
            this.inputList[topicIndex].programs = [{
              'id': this.tagForm.value.id,
              'program': this.tagForm.value.title
            }];
          }
        } else {
          topic = this.returnedArray.find(tag => tag.title === this.tagForm.value.topic);
            this.inputList.push({
              'id': topic.id,
              'topic': topic.title,
              'programs': [{
                'id': this.tagForm.value.id,
                'program': this.tagForm.value.title
              }]
            });
        }
        if((topic.id == 0 || topic.id > 0) && this.tagForm.value.id == 0) {
          topicIndex = this.returnedArray.findIndex(tag => tag.title === this.tagForm.value.topic);
          const value = this.tagForm.value;
          value.isEditable = true;
          value.count = (value.count) ? value.count : 0;
          if(this.returnedArray[topicIndex].programs) {
            this.returnedArray[topicIndex].programs.push(value);
          } else {
            this.returnedArray[topicIndex].programs = [value];
          }
          this.formSubmitSuccess = 'Added Successfully';
        } else {
          topicIndex = this.returnedArray.findIndex(tag => tag.title === this.tagForm.value.topic);
          const value = this.tagForm.value;
          value.isEditable = true;
          value.count = (value.count) ? value.count : 0;
          this.returnedArray[topicIndex].programs.splice(this.selectedRowIndex, 1, value);
          this.formSubmitSuccess = 'Updated Successfully';
        }
      }
    } else if(this.metaTagSelected == 'programs') {
        this.inputList.push({
          'id': this.tagForm.value.id,
          'program': this.tagForm.value.title
        });
    }
    if(!this.isAllGroup || (this.isAllGroup && this.metaTagSelected != 'topics') 
                        || (this.isAllGroup && this.metaTagSelected == 'topics' && this.tagForm.value.tagType == 'topic')) {
      if(this.tagForm.value.id == 0) {
        const value = this.tagForm.value;
        value.isEditable = true;
        value.count = (value.count) ? value.count : 0;
        if(this.returnedArray.length < 10) {
          this.returnedArray.push(value);
        } else {
          this.tagsList.push(value);
        }
        this.formSubmitSuccess = 'Added Successfully';
      } else {
        const value = this.tagForm.value;
        value.isEditable = true;
        value.count = (value.count) ? value.count : 0;
        this.returnedArray.splice(this.selectedRowIndex, 1, value);
        this.formSubmitSuccess = 'Updated Successfully';
      }
    }
    this.tagsList.concat(this.inputList);
    this.tagsList =this.tagsList.sort((a, b) => (a.title < b.title ? -1 : 1));
    this.isAddOpen = false;
    this.showTopic = false;
    this.resetForm();
    this.googleAnalyticsService
                  .eventEmitter('Add', this.tagForm.value.title, 'Meta Tag Management');
  }

  removeTag(tag: any, rowIndex: number) {
    if (tag.count>0) {
      const changeRequest = {} as MetadataChangeRequest;
      changeRequest.tagId =  tag.id;
      changeRequest.tagType = this.metaTagSelected;
      changeRequest.oldValue = tag.oldTitle;
      changeRequest.newValue= 'Not Specified';
      changeRequest.action = MetadataAction.Deleted;
      changeRequest.documentCount = tag.count;
      this.changeRequestList.push(changeRequest);
    }
    if(tag.id == 0) { 
      if(this.isAllProgram) {
        this.returnedArray[this.selectedRowIndex].programs.splice(rowIndex, 1);
      } else {
        this.returnedArray.splice(rowIndex, 1);
      }
    } else {
      if(this.metaTagSelected == 'contentTypes') {
         this.deleteList.push({
            'id': tag.id,
            'contentType': tag.title
          });
      } else if(this.metaTagSelected == 'privacyTypes') {
        this.deleteList.push({
           'id': tag.id,
           'privacyType': tag.title
         });
     } else if(this.metaTagSelected == 'publishers') {
          this.deleteList.push({
            'id': tag.id,
            'publisher': tag.title
          });
      } else if(this.metaTagSelected == 'topics') {
        if(this.isAllProgram) {
            this.deleteProgramList.push({
              'id': tag.id,
              'program': tag.title
            });
        } else {
          
            this.deleteList.push({
              'id': tag.id,
              'topic': tag.title
            });
          }
      } else if(this.metaTagSelected == 'programs') {
          this.deleteList.push({
            'id': tag.id,
            'program': tag.title
          });
      }
      if(this.isAllProgram) {
        this.returnedArray[this.selectedRowIndex].programs.splice(rowIndex, 1);
      } else {
        this.returnedArray.splice(rowIndex, 1);
      }
    }
    this.closeConfirmModal();
    this.formSubmitSuccess = 'Deleted Successfully';
    this.googleAnalyticsService
                  .eventEmitter('Delete', this.tagForm.value.title, 'Meta Tag Management');
  }

  editTag(tag: any, rowIndex: number) {
    this.getTagCount(tag).subscribe(count => {
      if (count !== null && count !== undefined && count !== 0) {
        tag.isEditable = true;
        tag.count = count;
      } else {
        tag.isEditable = true;
        tag.count = 0;
      }

      if(tag.isEditable && tag.count === 0) {
        this.selectedRowIndex = rowIndex;
        this.tagForm.patchValue({
          id: tag.id,
          title: tag.title,
          count: tag.count,
          oldTitle: tag.title,
        });
        if(this.metaTagSelected == 'contentTypes') {
          this.tagForm.patchValue({
            description: tag.description,
            oldDescription: tag.description
          });
        }
        this.isAddOpen = true;
      } else {
        if (this.confirmationModalCompoent) {
          this.confirmationModalCompoent.entityData = { entityData: null };
          this.confirmationModalCompoent.showModal();
          this.updateConfirmationModalEntity(tag, rowIndex, 'edit');
        }
      }
    });
  }

  editProgram(tag: any, rowIndex: number, program: any, programIndex: number) {   
 
    this.getTagCount(program, true).subscribe(count => {
      if (count !== null && count !== undefined && count !== 0) {
        program.isEditable = true;
        program.count = count;
        program.tagType = 'program';
      } else {
        program.isEditable = true;
        program.count = 0;
        program.tagType = 'program';
      }

      if(program.isEditable && program.count === 0) {
        this.selectedRowIndex = programIndex;
        this.tagForm.patchValue({
          id: program.id,
          title: program.title,
          tagType: 'program',
          count: program.count,
          oldTitle: program.title,
        });
        this.isAddOpen = true;
      } else {
        if (this.confirmationModalCompoent) {
          this.confirmationModalCompoent.entityData = { entityData: null };
          this.confirmationModalCompoent.showModal();
          this.updateConfirmationModalEntity(program, rowIndex, 'edit');
        }
      }
    });
  }


  openEditTagSection(tag, rowIndex) {
    this.closeConfirmModal();
    if (tag.isEditable) {
      this.selectedRowIndex = rowIndex;
      this.tagForm.patchValue({
        id: tag.id,
        title: tag.title,
        count: tag.count,
        oldTitle: tag.title,
      });
      if(this.metaTagSelected == 'contentTypes') {
        this.tagForm.patchValue({
          description: tag.description,
          oldDescription: tag.description
        });
      }
      this.isAddOpen = true;
    }
  }

  openEditProgramSection(program, programIndex) {
    this.closeConfirmModal();
    if (program.isEditable) {
      this.selectedRowIndex = programIndex;
      this.tagForm.patchValue({
        id: program.id,
        title: program.title,
        tagType: 'program',
        count: program.count,
        oldTitle: program.title,
      });
      this.isAddOpen = true;
    }
    
  }

  deleteProgram(tag: any, rowIndex: number, program: any, programIndex: number) {
    this.getTagCount(tag, true).subscribe(count => {
      if (count !== null && count !== undefined && count !== 0) {
        program.isEditable = true;
        program.count = count;
      } else {
        program.isEditable = true;
        program.count = 0;
      }

      this.isAllProgram = true;
      if (this.confirmationModalCompoent) {
        this.confirmationModalCompoent.entityData = { entityData: null };
        this.confirmationModalCompoent.showModal();
        if (program.isEditable) {
          this.selectedRowIndex = rowIndex;
          this.defaultConfirmationModalEntity(program, programIndex, 'delete');
        } else {
          this.updateConfirmationModalEntity(program, programIndex, 'delete');
        }
      }
    });
  }

  updateTags() {
    let tag = "";
    if(this.metaTagSelected == 'contentTypes') {
      tag = "contentTypes";
    } else if(this.metaTagSelected == 'publishers') {
      tag = "publishers";
    } else if(this.metaTagSelected == 'topics') {
      tag = "topic";
    } else if(this.metaTagSelected == 'programs') {
      tag = "program";
    }
    if(this.inputList.length > 0 ) {
      this.metaTagsService.addTag(this.inputList, tag).subscribe(data => {});
    }
    if(this.deleteList.length > 0 ) {
      this.metaTagsService.removeTag(this.deleteList, this.metaTagSelected).subscribe(data => {});
    }
    if(this.deleteProgramList.length > 0) {
      this.metaTagsService.removeTag(this.deleteList, 'programs').subscribe(data => {});
    }
    // if tags are associated with any document then it will be send to queue
    if(this.changeRequestList.length>0)
    {
      this.metaTagsService.sendMetaTagRequest(this.changeRequestList).subscribe(data => {});
    }
    this.formSubmitSuccess = 'Tags saved successfully';
  }

  cancelAdd() {
    this.formSubmitSuccess = '';
    this.resetForm();
    this.isAddOpen = false;
    this.showTopic = false;
  }

  openConfirmationModal(tag: any, rowIndex) {
    this.getTagCount(tag).subscribe(count => {
      if (count !== null && count !== undefined && count !== 0) {
        tag.isEditable = true;
        tag.count = count;
      } else {
        tag.isEditable = true;
        tag.count = 0;
      }

      this.isAllProgram = false;
      if (this.confirmationModalCompoent) {
        this.confirmationModalCompoent.entityData = { entityData: null };
        this.confirmationModalCompoent.showModal();
        if (tag.isEditable) {
          this.defaultConfirmationModalEntity(tag, rowIndex, 'delete');
        } else {
          this.updateConfirmationModalEntity(tag, rowIndex, 'delete');
        }
      }
    });
  }

  defaultConfirmationModalEntity(tag: any, rowIndex: number, type: string) {
    this.confirmationModalCompoent.entityData = { entityData: {
        tag: tag,
        rowIndex: rowIndex,
        type: type
      }
    };
  }

  updateConfirmationModalEntity(tag: any, rowIndex: number, type: string) {
    this.confirmationModalCompoent.entityData = {
      entityData: {
        tag: tag,
        rowIndex: rowIndex,
        type: type
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.closeModalWindow();
    }
  }
}
