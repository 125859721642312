<nav class="navbar navbar-inverse no-print" *ngIf="showNavigation">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbar-collapse" [collapse]="isCollapsed">
        <ul class="nav navbar-nav">
            <li [ngClass]="{'link-active': isActive(homeRoutes)}" ><a *ngIf="!isJJDataGroup && !isFTLAGroup" href="/">Home</a></li>
            <li dropdown class="dropdown" [insideClick]=true #favorites="bs-dropdown" *ngIf="ischeckPermissions(permissions.CanViewFavorites) && !isFTLAGroup && !isThriveGroup" [ngClass]="{'link-active': isActive(favRoutes)}">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-favorites">Favorites <span class="caret"></span></a>
                <ul class="dropdown-menu" id="dropdown-favorites" *dropdownMenu>
                    <li role="menuitem"  *ngIf="ischeckPermissions(permissions.CanSaveFavorites)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/savedfavorites']" (click)="favorites.hide();">
                            Saved Favorites
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ShareDocument)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/shareddocuments']" (click)="favorites.hide();">
                            Shared Documents 
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ShareDocument)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/sharedwithme']" (click)="favorites.hide();">
                            Shared with Me
                        </a>
                    </li>
                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true #favorites="bs-dropdown" *ngIf="isFTLAGroup" [ngClass]="{'link-active': isActive(ftlaReportRoutes)}">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-favorites">Reports <span class="caret"></span></a>
                <ul class="dropdown-menu" id="dropdown-favorites" *dropdownMenu>
                    <li role="menuitem"  routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/admin/OrganizationProfiles']">
                            Organization Profiles
                        </a>
                    </li>
                    <li role="menuitem" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/admin/Technologies']">
                            Technologies
                        </a>
                    </li>
                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" *ngIf="showDashboardLink && IsFeatureSupported(Features.Reports)" [ngClass]="{'link-active': isActive(dashboardRoutes)}">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports">{{ getReportMenuLabel() }} <span class="caret"></span></a>
                <ul class="dropdown-menu" id="dropdown-reports" *dropdownMenu>
                    <li role="menuitem" *ngFor="let dashboard of dashboardLinks" routerLinkActive="link-active" [ngClass]="{'link-active': isDashboardActive(dashboard)}">
                        <a class="dropdown-item nav-link-item" (click)="gotoDashbaord(dashboard);dropdown.hide();">
                            {{dashboard.reportName}}
                        </a>
                    </li>
                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" *ngIf="IsFeatureSupported(Features.Reports) && isReportingGroup">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports">Management Links <span class="caret"></span></a>
                <ul class="dropdown-menu" id="dropdown-reports" *dropdownMenu>
                    <li role="menuitem"  routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="navigateExternal('https://aecf.okta.com/home/concur/0oa402w1mq13Vl18k0x7/615');dropdown.hide();" >Concur</a></li>
                    <li role="menuitem"  routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="navigateExternal('https://aecf.okta.com/home/intacct/0oakm65c19O6y9sbU0x7/176');dropdown.hide();" >Intaact</a></li>
                    <li role="menuitem"  routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="navigateExternal('https://aecf.okta.com/home/docusign/0oaohgkgniZBvTqXQ0x7/292');dropdown.hide();" >Docusign</a></li>
                    <li role="menuitem"  routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="navigateExternal('https://aecf.okta.com/home/bookmark/0oamsurasjFjvs5au0x7/2557');dropdown.hide();" >GMS</a></li>
                    <li role="menuitem"  routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="navigateExternal('https://aecf.okta.com/home/adp/0oaa5wbjtcKxxsdWq0x7/aln5nvs4b7K6u2qfZ0g7');dropdown.hide();" >ADP</a></li>
                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" [ngClass]="{'link-active': isActive(resourceRoutes)}" *ngIf= "isThriveGroup">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports" *ngIf= "isThriveGroup || isFTLAGroup">Resources <span class="caret"></span></a>
                <ul class="dropdown-menu " id="dropdown-reports" *dropdownMenu>

                        <li *ngIf="ischeckPermissions(permissions.ViewSubmitResource)" role="menuitem" routerLinkActive="link-active" >
                        <a class="dropdown-item nav-link-item" (click)="navigateExternal('https://aecf.app.box.com/s/9s9kcb0zb3es8ct2jrhsn734llv27gyi/folder/104805687459'); dropdown.hide();" >
                            Submit Resource
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.DeleteDocument) || ischeckPermissions(permissions.ModifiyDocument))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/content/remove-thrive']" (click)="dropdown.hide();">
                            Manage Resources
                        </a>
                    </li>

                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" [ngClass]="{'link-active': isActive(resourceRoutes)}" *ngIf= "isFTLAGroup">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports" *ngIf= "isThriveGroup || isFTLAGroup">Resources <span class="caret"></span></a>
                <ul class="dropdown-menu " id="dropdown-reports" *dropdownMenu>

                        <li *ngIf="ischeckPermissions(permissions.ViewSubmitResource)" role="menuitem" routerLinkActive="link-active" >
                        <a class="dropdown-item nav-link-item" [routerLink]="['/content']" (click)="dropdown.hide();" >
                            Submit Resource
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.DeleteDocument) || ischeckPermissions(permissions.ModifiyDocument))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/content/remove']" (click)="dropdown.hide();">
                            Manage Resources
                        </a>
                    </li>

                </ul>
            </li>
            <li *ngIf="ischeckPermissions(permissions.ViewSubmitResource) && !isAllGroup && !isFTLAGroup && !isThriveGroup && !isJcyoiGroup" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/content']">Submit Resource</a></li>
            <li dropdown class="dropdown" [insideClick]=true #myFiles="bs-dropdown" *ngIf="(ischeckPermissions(permissions.ViewBox) || ischeckPermissions(permissions.ViewDropbox)) &&  (IsFeatureSupported(Features.Box) || IsFeatureSupported(Features.Dropbox))  && !isPortalConfig && !isFTLAGroup && !isThriveGroup" [ngClass]="{'link-active': isActive(filesRoutes)}">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-files">My Files <span class="caret"></span></a> 
                <ul class="dropdown-menu" id="dropdown-filles" *dropdownMenu>
                    <li role="menuitem" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" (click)="navigateBoxLink()" *ngIf="ischeckPermissions(permissions.ViewBox)" (click)="myFiles.hide();" [routerLinkActiveOptions]="{exact: true}">
                            Box
                        </a>
                    </li>
                    <li role="menuitem" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/dropboxview']" *ngIf="ischeckPermissions(permissions.ViewDropbox)" (click)="myFiles.hide();" [routerLinkActiveOptions]="{exact: true}">
                            Dropbox
                        </a>
                    </li>
                </ul>
            </li>
            <li *ngIf= "isJJDataGroup" routerLinkActive="link-active" ><a (click)="showDefinationReport();">DEFINITIONS</a></li>
            <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" *ngIf= "isJJDataGroup" >
                <a *ngIf= "isJJDataGroup" dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports">RESOURCES <span class="caret"></span></a>
                <ul class="dropdown-menu " id="dropdown-reports" *dropdownMenu>

                <li *ngIf="isJJDataGroup" routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="NavigateDashboardTipSheets();dropdown.hide();">Dashboard Tip Sheets</a></li>
                <li *ngIf="isJJDataGroup" routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="NavigateProbationTransformation();dropdown.hide();">Probation Transformation</a></li>
                <li *ngIf="isJJDataGroup" routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="NavigateJdaiLink();dropdown.hide();">JDAIconnect</a></li>
                <li *ngIf="isJJDataGroup" routerLinkActive="link-active"><a class="dropdown-item nav-link-item" (click)="NavigateJdaikidscountLink();dropdown.hide();">KIDS COUNT </a></li>
                </ul>
            </li>
            <li *ngIf="isFTLAGroup || isReportingGroup" routerLinkActive="link-active" ><a [routerLink]="['/profiles']">Profiles</a></li>
            <li *ngIf="isJJDataGroup" routerLinkActive="link-active" ><a [routerLink]="['/profiles']">USERS</a></li>
            <li dropdown class="dropdown" #favorites="bs-dropdown" [insideClick]=true #dropdown="bs-dropdown" >
                <a *ngIf="isFTLAGroup" dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports">Data Entry <span class="caret"></span></a>
                <ul class="dropdown-menu " id="dropdown-reports" *dropdownMenu>

                <li role="menuitem" routerLinkActive="link-active"  [routerLinkActiveOptions]="{exact: true}">
                    <a class="dropdown-item nav-link-item"  [routerLink]="['/organizationprofile']" (click)="favorites.hide();" >
                        Organization Profile
                    </a>
                </li>

                <li role="menuitem"  routerLinkActive="link-active"  [routerLinkActiveOptions]="{exact: true}">
                    <a class="dropdown-item nav-link-item"  [routerLink]="['/technologies']" (click)="favorites.hide();">
                    Technologies
                </a>
            </li>

                <!-- <li role="menuitem" *ngIf="ischeckPermissions(permissions.JobDescriptions)" routerLinkActive="link-active">
                    <a class="dropdown-item nav-link-item"  [routerLink]="['/jobdescriptions']" (click)="dropdown.hide();" >
                        Job Descriptions
                    </a>
                </li>  -->



                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" *ngIf= "isJJDataGroup && isAdmin || isDataLead || isCaseyConsultant || isState" >
                <a *ngIf="isJJDataGroup" dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports">DATA ENTRY <span class="caret"></span></a>
                <ul class="dropdown-menu " id="dropdown-reports" *dropdownMenu>

                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ManageRoles)" routerLinkActive="link-active"><a class="dropdown-item nav-link-item"  [routerLink]="['/roles']" (click)="admin.hide();" >Roles</a></li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ManageUsers)" routerLinkActive="link-active"><a class="dropdown-item nav-link-item"  [routerLink]="['/users']" (click)="dropdown.hide();" >Manage Users</a></li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ManagePermissions)" routerLinkActive="link-active"><a class="dropdown-item nav-link-item"  [routerLink]="['/permissions']" (click)="dropdown.hide();">Permissions</a></li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ViewSubmitResource) && isAllGroup" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact: true}"><a class="dropdown-item nav-link-item"  [routerLink]="['/content']" (click)="admin.hide();">Submit Resource</a></li>
                    <li role="menuitem" *ngIf="isModerator && (ischeckPermissions(permissions.ApproveDocument) || ischeckPermissions(permissions.RejectDocument)) && !isAllGroup && IsFeatureSupported(Features.Upload)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/content/manage']" (click)="admin.hide();">
                            Approve Resources
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ShareReports)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/reportassignment']" (click)="admin.hide();">
                            Reports 
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageSite) || ischeckPermissions(permissions.ViewSite))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/sites']" (click)="dropdown.hide();">
                            Manage Sites 
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageGroup))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/groups']" (click)="dropdown.hide();">
                            Manage Groups 
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageMeasure))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/measure']" (click)="dropdown.hide();">
                            Manage Measure
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageCustomMeasure))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/custommeasure']" (click)="dropdown.hide();">
                            Manage Custom Measure
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageMeasureValidations))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/measureValidations']" (click)="dropdown.hide();">
                            Manage Measure Validation
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.DeleteDocument) || ischeckPermissions(permissions.ModifiyDocument))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/content/remove']" (click)="admin.hide();">
                            Manage Resources
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ViewAuditLogs)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/audit']" (click)="dropdown.hide();">
                            Auditing
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ViewGoogleAnalytics)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/admin/Analytics']" (click)="dropdown.hide();">
                            Analytics
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.CreateNewsfeed)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/newsfeed']" (click)="admin.hide();">
                            News Update
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ReportsConfiguration)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/reportconfig']" (click)="admin.hide();">
                            Reports Configuration
                        </a>
                    </li>
                    <li role="menuitem"  *ngIf="ischeckPermissions(permissions.ReportProfileConfiguration)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/reportprofile']" (click)="admin.hide();">
                            Reports Profile
                        </a>
                    </li>
                    <li role="menuitem"  *ngIf="ischeckPermissions(permissions.ManageSurveyPeriods)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/surveyperiod']" (click)="admin.hide();">
                            Survey Period
                        </a>
                    </li>

                </ul>
            </li>  
            <li dropdown class="dropdown" [insideClick]=true #admin="bs-dropdown" *ngIf="(IsFeatureSupported(Features.Admin) && !isUser)" [ngClass]="{'link-active': isActive(adminRoutes)}">
                <a *ngIf="!isJJDataGroup" dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-admin">
                    <ng-container >{{currentRole}}</ng-container>
                    <span class="caret"></span>
                </a>
                <ul class="dropdown-menu" id="dropdown-admin" *dropdownMenu>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ManageRoles)" routerLinkActive="link-active"><a class="dropdown-item nav-link-item"  [routerLink]="['/roles']" (click)="admin.hide();" >Roles</a></li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ManageUsers)" routerLinkActive="link-active"><a class="dropdown-item nav-link-item"  [routerLink]="['/users']" (click)="admin.hide();" >Manage Users</a></li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ManagePermissions)" routerLinkActive="link-active"><a class="dropdown-item nav-link-item"  [routerLink]="['/permissions']" (click)="admin.hide();">Permissions</a></li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ViewSubmitResource) && isAllGroup" routerLinkActive="link-active" [routerLinkActiveOptions]="{exact: true}"><a class="dropdown-item nav-link-item"  [routerLink]="['/content']" (click)="admin.hide();">Submit Resource</a></li>
                    <li role="menuitem" *ngIf="isModerator && (ischeckPermissions(permissions.ApproveDocument) || ischeckPermissions(permissions.RejectDocument)) && !isAllGroup && IsFeatureSupported(Features.Upload)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/content/manage']" (click)="admin.hide();">
                            Approve Resources
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ShareReports)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/reportassignment']" (click)="admin.hide();">
                            Reports 
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageSite) || ischeckPermissions(permissions.ViewSite))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/sites']" (click)="admin.hide();">
                            Manage Sites 
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageGroup))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/groups']" (click)="admin.hide();">
                            Manage Groups 
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageMeasure))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/measure']" (click)="admin.hide();">
                            Manage Measure
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageCustomMeasure))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/custommeasure']" (click)="admin.hide();">
                            Manage Custom Measure
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="(ischeckPermissions(permissions.ManageMeasureValidations))" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/measureValidations']" (click)="admin.hide();">
                            Manage Measure Validation
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ViewAuditLogs)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/audit']" (click)="admin.hide();">
                            Auditing
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ViewGoogleAnalytics)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/admin/Analytics']" (click)="admin.hide();">
                            Analytics
                        </a>
                    </li>

                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.CreateNewsfeed)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/newsfeed']" (click)="admin.hide();">
                            News Update
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ReportsConfiguration)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/reportconfig']" (click)="admin.hide();">
                            Reports Configuration
                        </a>
                    </li>
                    <li role="menuitem"  *ngIf="ischeckPermissions(permissions.ReportProfileConfiguration)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/reportprofile']" (click)="admin.hide();">
                            Reports Profile
                        </a>
                    </li>
                    <li role="menuitem"  *ngIf="ischeckPermissions(permissions.ManageSurveyPeriods)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/surveyperiod']" (click)="admin.hide();">
                            Survey Period
                        </a>
                    </li>
                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true *ngIf="ischeckPermissions(permissions.ViewPortalConfiguration) && IsFeatureSupported(Features.Config)" #config="bs-dropdown" [ngClass]="{'link-active': isActive(configRoutes)}">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-config">Portal Configuration <span class="caret"></span></a>
                <ul class="dropdown-menu" id="dropdown-config" *dropdownMenu>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ConfigureOKTASettings)" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/okta']" (click)="config.hide();">
                            Okta Settings
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ApproveBanner) || ischeckPermissions(permissions.RejectDocument) || ischeckPermissions(permissions.ViewBanner)  " routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/title']" (click)="config.hide();">
                            Title / Banner
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="ischeckPermissions(permissions.ViewMetadataTags) " routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" [routerLink]="['/metatags']" (click)="config.hide();">
                            Meta Tags
                        </a>
                    </li>
                </ul>
            </li>
            <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" *ngIf="(isKmsGroup || isReportingGroup) && portals!=null && !isPortalConfig">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports">Portals <span class="caret"></span></a>
                <ul class="dropdown-menu" id="dropdown-reports" *dropdownMenu>
                    <li role="menuitem" *ngFor="let portal of portals" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" (click)="navigateExternal(portal.url);dropdown.hide();">
                            {{portal.name}}
                        </a>
                    </li>
                </ul>
            </li>
            <!-- <li dropdown class="dropdown" [insideClick]=true #dropdown="bs-dropdown" *ngIf="isFtla">
                <a dropdownToggle class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" aria-controls="dropdown-reports">Members <span class="caret"></span></a>
                <ul class="dropdown-menu force-scroll" id="dropdown-reports" *dropdownMenu>
                    <li role="menuitem" *ngFor="let member of memberslist" routerLinkActive="link-active">
                        <a class="dropdown-item nav-link-item" (click)="navigateExternal(member.website);dropdown.hide();">
                            {{member.name}}
                        </a>
                    </li>
                </ul>
            </li> -->
            <li *ngIf="isJcyoiGroup" routerLinkActive="link-active"><a (click)="NavigateJiveLink()">Jim Casey Initiative Exchange</a></li>
            <li *ngIf="ischeckPermissions(permissions.ViewSupportLink)" routerLinkActive="link-active"><a *ngIf="!isJJDataGroup" (click)="NavigateSupportLink()">Support</a></li>
            <li *ngIf="ischeckPermissions(permissions.ViewSupportLink)" routerLinkActive="link-active" style="float:right"><a *ngIf="isJJDataGroup" (click)="NavigateSupportLink()">HELP</a></li>
            <li *ngIf="ischeckPermissions(permissions.ViewFAQPage) || ischeckPermissions(permissions.ManageFAQPage) && !isFTLAGroup " [ngStyle]="isJJDataGroup && {'float': 'right'} || !isJJDataGroup && {'float': 'left'}" routerLinkActive="link-active"><a (click)="NavigatFaqLink()">FAQ</a></li>
            <li *ngIf="isAllGroup" routerLinkActive="link-active"><a (click)="navigateAllLink()">Visit ALL</a></li>
            <li *ngIf="isPortalConfig" routerLinkActive="link-active"><a [routerLink]="['/portalconfig']">OKTA Configuration</a></li>
            <li *ngIf="isPortalConfig" routerLinkActive="link-active"><a [routerLink]="['/portalroles']">Roles</a></li>
        </ul>
      </div>
    </div>
</nav>
<div class="model-dialog" *ngIf="showDialog">
    <a class="close" (click)="cancelDialog()"><i class="fa fa-times"></i></a>
    <div>Export report</div>
    <div class="row"></div>
    <div class="row">
      <div class="col-md-12">
        <p>Report export is in progress..</p>
      </div>
    </div>
    <div class="row"> 
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <button id="cancel"class="btn btn-secondary button-style" (click)="cancelDialog()"> Ok</button>
      </div>
      <div class="col-md-4"></div>
    </div>
</div>
<div class="backdrop" *ngIf="showDialog"></div>