import { formatDate } from '@angular/common';
import { FoundationProfiles } from 'src/app/account/shared/profile.model';

export class organizationProfileModel {
    foundationName: string;
    id: number;
    foundationid: number;
    itStaffCount: number;
    itBudget: number;
    offshoreEmployeeCount: number;
    fullTimeEmployeeCount: number;
    itConsultantsCount: number;
    allowInternationalGrants: boolean;
    officeLocationCount: number;
    createdDateTime: string;
    updatedDateTime: string;
    createdBy: string;
    updatedBy: string;  
    founded:number;
    staffSize:number;
    headquarters:string;
    endowment:number;
    itOperatingBudget: number;
    itcapexBudget: number;
    itopexBudget: number;
    itCybersecurityBudget: number;

    public addOrganizationProfile(id:number, itStaffCount: number, itBudget: number, offshoreEmployeeCount: number, fullTimeEmployeeCount: number, itConsultantsCount: number, allowInternationalGrants: boolean, officeLocationCount:number, founded:number,
        staffsize:number,headquarters:string,endowment:number, foundationName: string, itOperatingBudget: number, itcapexBudget: number, itopexBudget: number, itCybersecurityBudget: number){
        Object.assign(this, {
        id:id,
        itStaffCount:itStaffCount,
        itBudget:itBudget,
        offshoreEmployeeCount:offshoreEmployeeCount,
        fullTimeEmployeeCount:fullTimeEmployeeCount,
        itConsultantsCount:itConsultantsCount,
        allowInternationalGrants:allowInternationalGrants,
        officeLocationCount:officeLocationCount,
        founded:founded,
        staffsize:staffsize,
        headquarters:headquarters,
        endowment:endowment,
        foundationName:foundationName,
        itOperatingBudget:itOperatingBudget,
        itcapexBudget:itcapexBudget,
        itopexBudget:itopexBudget,
        itCybersecurityBudget:itCybersecurityBudget
    });
    return this;
}
}