import { formatDate } from "@angular/common";
import { GroupModel } from "./group.model";
import { MeasureModel } from "./measure.model";
import { SiteModel } from "./site.model";

export class SiteMeasureModel {
    id: number;
    number: string;
    measure:MeasureModel;
    group:GroupModel;
    site:SiteModel;
    lastUpdated: string;
    lastUpdatedDateTime: string;
    definition: string;
    siteDefinition:string;
    source:String;
    caseyTANotes:string;
    stateTANotes:string;
    isActive:boolean;
//    ageAtTimeOf:string; 
    constructor(id:number,
                number:string
                ,measure:MeasureModel,
                group:GroupModel,
                site:SiteModel,
                lastUpdated:string,
                definition:string,
                siteDefinition:string,
                source:string,
                caseyTANotes:string,
                 stateTANotes:string,
                isActive:boolean
//                ,ageAtTimeOf:string
                ){
        this.id=id;
        this.number=number;
        this.measure = measure;
        this.group = group;
        this.lastUpdated = lastUpdated;
        this.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");
        this.definition=definition;
        this.caseyTANotes = caseyTANotes;
        this.stateTANotes = stateTANotes;
        this.source=source;
        this.siteDefinition = siteDefinition;
        this.isActive = isActive;
        this.site =site;
//        this.ageAtTimeOf = ageAtTimeOf;
    }    
}

export class SiteYears {
      year:number;
      site:SiteModel;
      isActive:boolean;
      constructor(year:number, site:SiteModel,isActive:boolean){
          this.year = year;
          this.site = site;
          this.isActive = isActive;
      }  
}
