import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare const Box: any;

@Component({
  selector: 'app-box-explorer',
  templateUrl: './box-explorer.component.html',
  styleUrls: ['./box-explorer.component.scss']
})
export class BoxExplorerComponent implements OnInit {
  @Input() accessToken: string;
  @Output() authenticationFailed = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    const contentExplorer = new Box.ContentExplorer();
    contentExplorer.show('0', this.accessToken, {
        container: '.box-container',
        responseInterceptor: (data) => {
          if (data && data.response && data.response.status === 401) {
            this.authenticationFailed.emit(true);
            return {};
          } else if (data && data.status === 200) {
            return data;
          }
        }
    });
  }
}
