<div class="container role-manage">
        <div class="page-title">
            <div>
                <span calss="page-title"><h1>Manage Permissions</h1></span>
            </div>
        </div>
        <div class="col-md-12" *ngIf="isAdmin">
          <span>Select the permission for the corresponding roles</span>        
        </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>

    <div class="adminRoleContainer col-md-12">
            <div class="row header m-0">
                <div class="col-sm-{{nameColumnSize}} align-left" style="width: 15%;">
                   <span class="user-icon"><em class="fas fa-user"></em></span>Name
                </div>  
                <div class="col-sm-{{roleColumnSize}}" *ngIf="(isSuperAdmin|| isAdmin) && (isAllGroup || isFTLAGroup) ">
                    <span><span class="header-icon"><em class="fas fa-unlock"></em></span>Super Admin</span>
                </div>        
                <div class="col-sm-{{roleColumnSize}}" style="width: 10%;" *ngIf="isSuperAdmin || isAdmin">
                    <span><span class="header-icon"><em class="fas fa-unlock"></em></span>Admin</span>
                </div>
                <div class="col-sm-{{roleColumnSize}}" *ngIf="(isSuperAdmin || isAdmin) && !(isAllGroup || isJJGroup)">
                    <span *ngIf="(isSuperAdmin || isAdmin) && !(isAllGroup ||isJJGroup)"><span class="header-icon"><em class="fas fa-unlock"></em></span>Moderator</span>
                </div>
                <div class="col-sm-{{roleColumnSize}}" *ngIf="(isSuperAdmin || isAdmin) && !( isJJGroup || isFTLAGroup )">
                    <span *ngIf="(isSuperAdmin || isAdmin)"><span class="header-icon"><em class="fas fa-unlock"></em></span>User</span>
                </div>
                <div class="col-sm-{{jjroleColumnSize}}" style="width: 16%;" *ngIf="(isSuperAdmin || isAdmin) && isJJGroup"> 
                    <span *ngIf="(isSuperAdmin || isAdmin)"><span class="header-icon"><em class="fas fa-unlock"></em></span>Casey Consultants (TA)</span>
                </div>
                <div class="col-sm-{{jjroleColumnSize}}" style="width: 10%;" *ngIf="(isSuperAdmin || isAdmin) && isJJGroup">
                    <span *ngIf="(isSuperAdmin || isAdmin)"><span class="header-icon"><em class="fas fa-unlock"></em></span>State (TA)</span>
                </div>
                <div class="col-sm-{{jjroleColumnSize}}" style="width: 12%;" *ngIf="(isSuperAdmin || isAdmin) && isJJGroup">
                    <span *ngIf="(isSuperAdmin || isAdmin)"><span class="header-icon"><em class="fas fa-unlock"></em></span>Probation Chief</span>
                </div>
                <div class="col-sm-{{jjroleColumnSize}}" style="width: 15%;" *ngIf="(isSuperAdmin || isAdmin) && isJJGroup">
                    <span *ngIf="(isSuperAdmin || isAdmin)"><span class="header-icon"><em class="fas fa-unlock"></em></span>Other Local Leader</span>
                </div>
                <div class="col-m-{{jjroleColumnSize}}" style="width: 10%;" *ngIf="(isSuperAdmin || isAdmin) && isJJGroup">
                    <span *ngIf="(isSuperAdmin || isAdmin)"><span class="header-icon"><em class="fas fa-unlock"></em></span>Data Lead</span>
                </div>
                <div class="col-m-{{jjroleColumnSize}}" style="width: 12%;" *ngIf="(isSuperAdmin || isAdmin) && isJJGroup">
                    <span *ngIf="(isSuperAdmin || isAdmin)"><span class="header-icon"><em class="fas fa-unlock"></em></span>Casey All Sites</span>
                </div>
            </div>

        <div class="row m-0">
            <ng-container *ngFor="let permission of permissionsAssignment | keyvalue; let rowIndex=index;">
                <div class="col-sm-12 userContainer">
                    <div class="col-sm-{{nameColumnSize}} align-left" style="width: 15%;">
                        <span [tooltip]="permission.key"> {{ permission.key }}</span>
                    </div>
                    <div class="col-sm-{{roleColumnSize}} align-role-items" *ngIf="(isSuperAdmin || isAdmin) && (isAllGroup || isFTLAGroup)">
                        <input  [ngModel]="checkPermission(permission.value, 'SuperAdmin')" (change)="checkboxSelect($event, permission.value, permission.key, 'SuperAdmin')" class="selectCheck" [value]="userRoles.SuperAdmin" name="{{permission.key}}" id="btn-{{rowIndex}}-superadmin"
                        type="checkbox">
                    </div>
                    <div class="col-sm-{{roleColumnSize}} align-role-items" style="width: 10%;" *ngIf="isSuperAdmin || isAdmin">
                        <input *ngIf="isSuperAdmin || isAdmin" [ngModel]="checkPermission(permission.value, 'Admin')" (change)="checkboxSelect($event, permission.value, permission.key, 'Admin')" class="selectCheck" [value]="userRoles.Admin" name="{{permission.key}}" id="btn-{{rowIndex}}-admin"
                        type="checkbox">
                    </div>
                    <div class="col-sm-{{roleColumnSize}} align-role-items" *ngIf="(isAdmin || isSuperAdmin) && !(isAllGroup  || isJJGroup) ">
                        <input *ngIf="(isAdmin || isSuperAdmin) && !(isAllGroup || isJJGroup)" [ngModel]="checkPermission(permission.value, 'Moderator')" (change)="checkboxSelect($event, permission.value, permission.key, 'Moderator')" class="selectCheck" [value]="userRoles.Moderator" name="{{permission.key}}" id="btn-{{rowIndex}}-moderator"
                        type="checkbox">
                    </div>
                    <div class="col-sm-{{roleColumnSize}} align-role-items" *ngIf="(isAdmin || isSuperAdmin) && !( isJJGroup || isFTLAGroup)">
                        <input *ngIf="(isAdmin || isSuperAdmin)" [ngModel]="checkPermission(permission.value, 'User')" (change)="checkboxSelect($event, permission.value, permission.key, 'User')" class="selectCheck" [value]="userRoles.User" name="{{permission.key}}" id="btn-{{rowIndex}}-moderator"
                        type="checkbox">
                    </div> 
                    <div class="col-sm-{{jjroleColumnSize}} align-role-items" style="width: 16%;" *ngIf="(isAdmin || isSuperAdmin) && isJJGroup">
                        <input *ngIf="(isAdmin || isSuperAdmin)" [ngModel]="checkPermission(permission.value, 'CaseyConsultant')" (change)="checkboxSelect($event, permission.value, permission.key, 'CaseyConsultant')" class="selectCheck" [value]="userRoles.CaseyConsultant" name="{{permission.key}}" id="btn-{{rowIndex}}-CaseyConsultant"
                        type="checkbox">
                    </div> 
                    <div class="col-sm-{{jjroleColumnSize}} align-role-items" style="width: 10%;" *ngIf="(isAdmin || isSuperAdmin) && isJJGroup">
                        <input *ngIf="(isAdmin || isSuperAdmin)" [ngModel]="checkPermission(permission.value, 'State')" (change)="checkboxSelect($event, permission.value, permission.key, 'State')" class="selectCheck" [value]="userRoles.State" name="{{permission.key}}" id="btn-{{rowIndex}}-State"
                        type="checkbox">
                    </div>
                    <div class="col-sm-{{jjroleColumnSize}} align-role-items" style="width: 12%;" *ngIf="(isAdmin || isSuperAdmin) && isJJGroup">
                        <input *ngIf="(isAdmin || isSuperAdmin)" [ngModel]="checkPermission(permission.value, 'ProbationChief')" (change)="checkboxSelect($event, permission.value, permission.key, 'ProbationChief')" class="selectCheck" [value]="userRoles.ProbationChief" name="{{permission.key}}" id="btn-{{rowIndex}}-ProbationChief"
                        type="checkbox">
                    </div>
                    <div class="col-sm-{{jjroleColumnSize}} align-role-items" style="width: 15%;" *ngIf="(isAdmin || isSuperAdmin) && isJJGroup">
                        <input *ngIf="(isAdmin || isSuperAdmin)" [ngModel]="checkPermission(permission.value, 'OtherLocalLeader')" (change)="checkboxSelect($event, permission.value, permission.key, 'OtherLocalLeader')" class="selectCheck" [value]="userRoles.OtherLocalLeader" name="{{permission.key}}" id="btn-{{rowIndex}}-OtherLocalLeader"
                        type="checkbox">
                    </div>
                    <div class="col-m-{{jjroleColumnSize}} align-role-items" style="width: 10%;" *ngIf="(isAdmin || isSuperAdmin) && isJJGroup">
                        <input *ngIf="(isAdmin || isSuperAdmin)" [ngModel]="checkPermission(permission.value, 'DataLead')" (change)="checkboxSelect($event, permission.value, permission.key, 'DataLead')" class="selectCheck" [value]="userRoles.DataLead" name="{{permission.key}}" id="btn-{{rowIndex}}-DataLead"
                        type="checkbox">
                    </div>
                    <div class="col-m-{{jjroleColumnSize}} align-role-items" style="width: 12%;" *ngIf="(isAdmin || isSuperAdmin) && isJJGroup">
                        <input *ngIf="(isAdmin || isSuperAdmin)" [ngModel]="checkPermission(permission.value, 'CaseyAllSites')" (change)="checkboxSelect($event, permission.value, permission.key, 'CaseyAllSites')" class="selectCheck" [value]="userRoles.CaseyAllSites" name="{{permission.key}}" id="btn-{{rowIndex}}-CaseyAllSites"
                        type="checkbox">
                    </div>
                </div>                
            </ng-container>
        </div>
    </div>
    <div class="clearfix">
        <div class="px-15 addRowBtn">
            <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
            <button type="button" id="btnsave" class="btn btn-secondary button-style" (click)="updatePermission()" [ngClass]="{ 'disable-color': viewAsAdmin }">Save</button>
        </div>
        <div class="pull-right">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
    </div>
</div>