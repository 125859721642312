import { Component, OnInit } from '@angular/core';
import { PortalConfigService } from 'src/app/portal-config/shared/portal-config.service';
import { PortalConfig, ResourcesType } from 'src/app/portal-config/shared/portal-config.model';
import { SystemPermissions } from './../../permissions';
import { StateService } from 'src/app/core/state-manager/appstateservice';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  bannerConfig: PortalConfig;
  resourcesType = ResourcesType;
  title = 'Casey’s Center for Knowledge and Analytics';
  bgImage = '/assets/images/HomePageImg.jpg';
  permissions = SystemPermissions;
  portalbannerConfig:boolean=false;
  constructor(private portalService: PortalConfigService, private currentState: StateService ) { }

  ngOnInit() {
    this.getConfig();
  }

  ischeckPermissions(permission: string): boolean {  return this.currentState.permissionCheck(permission); }

  getConfig() {
    this.portalService.getConfig(this.resourcesType.Banner).subscribe(data => {
      this.bannerConfig = new PortalConfig().uiMapper(data);
      if (this.bannerConfig.resourcePath !== null && this.bannerConfig.resourcePath !== undefined) {
        this.title = this.bannerConfig.title;
        this.bgImage =  decodeURIComponent(decodeURIComponent(this.bannerConfig.resourcePath));
        this.portalbannerConfig=true;
      }
    });
  }
}
