<div class="container manage-users">

    <div class="heading d-flex">
            <div class="page-title">
                <div>
                    <span calss="page-title"><h1>Manage Custom Measure</h1></span>
                </div>
            </div>
        <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
            <div class="col-md-12">
                <div class="alert alert-danger" id="formSubmitError">
                {{ formSubmitError }}
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="search_icon_profile">
            <i class="fas fa-search"></i>
        </div>
        <div class="searchBoxForm_profile">
                <input type="text" id="searchTerm" name="searchTerm" placeholder="Search custom measures..." autocomplete="off"
                             [(ngModel)]="searchTerm" (keyup)="search()"  class="searchBox_Profile" />
        </div>
        <button type="button"  id="btnAddMeasure" class="btn btn-success button-style" (click)="addMeasure()">Add Custom Measure</button>
    </div>
    
    <div class="row">
        <div class="col-12 table-responsive">
            <table class="table table-striped sortable" id="printableArea">
                <thead>
                  <tr>
                  <th style="width:10px;"></th>
                  <th style="width:10px;"></th>
                  <th style="width:10px;"><div > Measure <a (click)="toggleTag('measure')" [hidden]="measureAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!measureAsc" (click)="toggleTag('measure')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                  <th style="width:150px;"><div >Group<a (click)="toggleTag('group.name')" [hidden]="groupAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!groupAsc" (click)="toggleTag('group.name')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                  <th style="width:10px;"><div >Site<a (click)="toggleTag('site.name')" [hidden]="siteAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!siteAsc" (click)="toggleTag('site.name')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                  <th style="width:100px;"><div >Last Updated<a (click)="toggleTag('lastUpdated')" [hidden]="lastUpdatedAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedAsc" (click)="toggleTag('lastUpdated')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                  <th style="width:150px;"><div >Last Updated Date Time<a (click)="toggleTag('lastUpdatedDateTime')" [hidden]="lastUpdatedDateTimeAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedDateTimeAsc" (click)="toggleTag('lastUpdatedDateTime')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                </tr>
                </thead>
                
                <tbody>
                <tr *ngFor="let measure of manageMeasure; let rowIndex=index;">
                <td title="delete measure">
                        <div class="delete-icon" [ngClass]="{ 'disabled':  viewAsAdmin }">
                        <span *ngIf="isAdmin || isDataLead" class="role-icon" (click)="removeMeasure(measure)">
                            <em class="far fa-trash-alt"></em>
                        </span>  
                    </div>
                </td>
                <td title="edit measures">
                    <div class="delete-icon" [ngClass]="{ 'disabled': viewAsAdmin }">
                    <span *ngIf="isAdmin || isDataLead" class="role-icon"  (click)="editMeasure(measure, rowIndex)">
                        <i class="far fa-edit"></i>
                    </span>  
                    </div>
                </td>
                    <td>{{measure.measure}}</td>
                    <td>{{measure.group.name}}</td>
                    <td>{{measure.site.name}}</td>
                    <td>{{measure.lastUpdated}}</td>
                    <td>{{measure.lastUpdatedDateTime | timezone:'MM/DD/YYYY hh:mm A' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="row" *ngIf="manageMeasure?.length>0">
        <div class="col-md-2 padding-2p" *ngIf="manageMeasure?.length>0">
            <button type="button"   [ngClass]="{ 'disabled':  viewAsAdmin }" *ngIf="isAdmin"  id="btnexportCustomMeasure" class="btn btn-success activeyears"  (click)="exportCustomMeasures()">Export Measure</button>
        </div> 
        <div class="col-md-4" >
            <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage"  [rotate]="false"  [boundaryLinks]="true" [maxSize]="10"></pagination>
        </div>    
        <div class="col-md-4 pull-right padding-2p">
                <div class="col-xs-6 px-5">
                    <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
                </div>
        </div>
    </div>
    
    <div class="row" *ngIf="manageMeasure?.length===0">
        <div class="col-md-4" ></div>
        <div class="col-md-4" >  <p>No Custom Measures Found.</p></div>
        <div class="col-md-4" ></div>
    </div>
    
    <div class="clearfix">
            <div class="pull-right">
                <div *ngIf="formSubmitSuccess">
                    <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
                </div>
            </div>
    </div>
    
    <div class="add-form" *ngIf="isAddOpen">
        <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
        <div class="form-group">
            <form [formGroup]="measureForm">
                <div><b>Add/Edit Custom Measure </b></div>
                <label for="txt-role">Site</label>
                <div>
                <select formControlName="site" class="form-control" required (change)='onSiteSelected($event.target.value)'>
                    <option value=0> Select Site</option>
                    <option *ngFor="let site of siteList; let rowIndex=index;" [ngValue]="site.id" [selected]="site.name == this"> 
                       {{site.name}} 
                    </option>
                </select>
                </div>  
                   <label for="txt-role">Group</label>
                        <div>
                        <select formControlName="group" class="form-control" required>
                            <option value = 0> Select group</option>
                            <option *ngFor="let group of manageGroups; let rowIndex=index;" [ngValue]="group.id" [selected]="group.name == this">
                               {{group.name}} 
                            </option>
                        </select>
                        </div> 
                        <div *ngIf="isValiGroup">
                            <label class="alert-danger">Please select measure group</label>
                        </div> 
                    <div *ngIf="isValidMeasureNumber">
                        <label class="alert-danger">Measure number already exists</label>
                    </div>
<!-- 
                    <div *ngIf="isAdmin">Age at Time Of 
                        <select *ngIf="isAdmin" class="form-control" required>
                            <option value = "">Select Age</option>
                            <option *ngFor="let opt of ageAtTimeOfOptions" value="{{opt}}">{{opt}}</option>
                        </select> 
                    </div> -->
                    <div>Measure
                    <textarea formControlName="measure" id="txtareaMeasure" placeholder="Measure" name="txtareaMeasure" rows="2" class="form-control"required></textarea>
                    </div>
                    <div>Short Name 
                        <input formControlName="shortMeasure" id="txtshortMeasure" type="text" placeholder="Short Measure Name"class="form-control" required> 
                    </div>
                    <div>Definition
                    <textarea formControlName="definition" id="txtareaDefinition" placeholder="Definition" name="txtareaDefinition" rows="4" class="form-control"></textarea>
                    </div>

                    <div>Comments
                        <textarea formControlName="comments" id="txtcomments" placeholder="Comments" name="txtComments" rows="4" class="form-control"></textarea>
                    </div>
            </form>
        </div>
        
        <div *ngIf="isNumberExists">
            <span class="alert alert-danger">Number already exists.</span>
        </div>
    
        <div class="col-md-4 px-5" *ngIf="isDataLead || isNew">
            <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateMeasure()" [disabled]="measureForm.invalid">{{buttonText}}</button>
        </div>
        <div class="px-5" *ngIf="isAdmin  && !isNew">
            <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateMeasure(true,false)" [disabled]="measureForm.invalid">Approve</button>
            <button type="button" class="btn btn-secondary button-style back-button" (click)="AddUpdateMeasure(false,true)" >Reject</button>
        </div>
    </div>
    
    <div class="backdrop" *ngIf="isAddOpen"></div>
    
    <app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
        <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
            <div class="col-sm-12 content-style">
                <div *ngIf="dataItem">
                    <span>Are you sure you want to delete {{dataItem.deleteMeasure.measure}}?</span>
                </div>
            </div>
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                        <span>Cancel</span>
                    </button>
                </div>
                <div class="savebtn">
                    <button type="submit" class="btn button-style" (click)="confirmMeasuretoRemove(dataItem.deleteMeasure)">
                        <span>Ok</span>
                    </button>
                </div>
            </div>
        </ng-template>
    </app-modal>
</div>