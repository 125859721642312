import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing',
  styleUrls: [],
  template: `
    <div class="prelogin-imgcontainer">
      <div class="overcontainer">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
              <div class="heading">
                  <div class="headingContent">
                    <div class="mainheading">
                      <h1 *ngIf="!isGrants">Casey’s Center for Knowledge and Analytics</h1>
                      <h1 *ngIf="isGrants">{{title}}</h1>
                    </div>
                  <div class="subheading">
                      <h5></h5>
                    </div>
                  </div>
              </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>`
})
export class LandingComponent implements OnInit {

  isGrants:boolean;
   title = '';
  constructor(private titleService: Title,   
              private stateService: StateService) { }

  ngOnInit() {
    this.title= environment.grantsPageTitle;
    this.titleService.setTitle(this.title);

    this.isGrants = this.stateService.isGrantsGroup();
    const bodyElement = document.body;
    if(this.isGrants)
    bodyElement.style.paddingBottom='0px';
  }
}
