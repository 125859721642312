import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { GroupModel } from "../models/group.model";

@Injectable()
export class GroupServices {

  constructor(private repository: RepositoryService) { }

  getGroup() : Observable<GroupModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/Group');
  }

  getSiteMeasuresGroup() : Observable<GroupModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/Group/GetGroups/'+true);
  }

  saveGroup(group: GroupModel[]){
    return this.repository.post(environment.apiBaseURL, 'api/Group', group);
  }
   
  removeGroup(group: GroupModel[]){
    return this.repository.delete(environment.apiBaseURL, 'api/Group', group);
  }

  validateBeforeDelete(groupId:number): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/Group/hasmeasures/'+ groupId);
  }

  exportGroups(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/Group/export/','blob');
  }
}