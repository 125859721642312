import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { NavigationRoute, Features, Groups } from 'src/app/core/navigations/navigation-settings.model';
import { NavigationService } from 'src/app/core/navigations/navigation.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { LocalStorageService } from 'src/app/core/state-manager/local-storage/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reporting-home',
  templateUrl: './reporting-home.component.html',
  styleUrls: ['./reporting-home.component.scss']
})
export class ReportingHomeComponent implements OnInit {

  showReportsLink = false;
  userDetail: any;
  reportsLinks = [];
  hasReports$: Observable<boolean>;
  navigationRoutedata: NavigationRoute;
  Features: any;
  loginType: string;
  constructor(public currentState: StateService, private router: Router,
              private repository: RepositoryService,
              private navigationService: NavigationService,
              private storageService: LocalStorageService,
              private commonService: CommonEntityService,
              public googleAnalyticsService: GoogleAnalyticsService,
              private titleService: Title) { }

  ngOnInit() {
    this.hasReports$ = this.getUserReportAccess();
    this.currentState.getSessionEvent().subscribe(() => this.getSessionData());
    this.commonService.getViewUserEventEmitter().subscribe(data => this.setViewUserInfo(data));
    this.hasReports$ = this.getUserReportAccess();
  }

  getSessionData() {
    const group = this.currentState.getGroupSession();
    this.navigationRoutedata = this.navigationService.getCurrentnavigationRoutes(Groups[group]);
    if (this.showNavigation) {
      this.setViewUserInfo(this.currentState.isViewAsAdmin());
    }
  }

  showDashboard() {
    if (this.userDetail && this.userDetail.reports) {
      this.reportsLinks = this.userDetail.reports;
      this.reportsLinks = this.reportsLinks.filter(x=>x.reportName!=='Portal Analytics');
      this.showReportsLink = this.reportsLinks && this.reportsLinks.length > 0;
    }
  }

  setViewUserInfo(isView) {
    if (this.showNavigation) {
      if (isView) {
        this.userDetail = this.currentState.getViewingUserData();
      } else {
        this.userDetail = this.currentState.getUserData();
      }
      this.hasReports$ = this.getUserReportAccess();
      this.showDashboard();
    }
  }
  get showNavigation() {
    return this.currentState.isTokenValid;
  }
  showReportTiles() {
    if (this.userDetail && this.userDetail.reports) {
      this.reportsLinks = this.userDetail.reports;
      this.showReportsLink = this.reportsLinks && this.reportsLinks.length > 0;
    }
  }
  gotoReports(reportLink) {
    if (reportLink && reportLink.reportType !== 'Dashboard') {
      this.googleAnalyticsService.eventEmitter('Homepage Tile', reportLink.reportName, 'Report');
      this.router.navigate(['/dashboard/' + reportLink.reportName]);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
  getUserReportAccess(): Observable<boolean> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/department/HasReports');
  }
}
