import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorLogService } from '../services/logger/error-log.service';
import { AppInsightService } from 'src/app/common/logging/appinsight.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private appInsights: AppInsightService;
    constructor(private errorLogService: ErrorLogService,
                private injector: Injector
               ) {
                    this.appInsights = this.injector.get(AppInsightService);
                 }

    handleError(error) {
        // const message = error.message ? error.message : error.toString();
       /*  // log on to the server
       //can trigger error log service
        throw error; */
        this.appInsights.logException(error);
        this.errorLogService.logError(error);
    }
}
