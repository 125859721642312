import { Injectable, EventEmitter } from '@angular/core';

import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { Observable, BehaviorSubject } from 'rxjs';
import { UsersList } from 'src/app/account/shared/user.model';
import { environment } from 'src/environments/environment';
import { FoundationProfiles } from 'src/app/account/shared/profile.model';

@Injectable({
  providedIn: 'root'
})
export class CommonEntityService {
  viewUserEventDispatcher = new BehaviorSubject<boolean>(false);

  constructor(private repository: RepositoryService, private stateService: StateService) { }

  emitViewUserEvent(isView: boolean) {
    this.viewUserEventDispatcher.next(isView);
  }

  getViewUserEventEmitter() {
    return this.viewUserEventDispatcher;
  }

  getSearchedUsers(searchString: string): Observable<UsersList> {
    return this.repository.get(environment.apiBaseURL, 'api/User/autocomplete/' + searchString);
  }

  resetToAdminUser() {
    return this.stateService.resetToAdminUser();
  }

  setToViewUser(data) {
    return this.stateService.setViewingUserData(data);
  }

  getFTLAProfileUsers():Observable<FoundationProfiles>{
    return this.repository.get(environment.apiBaseURL, 'api/User/ftlaProfiles');
  }

  getFTLAProfiles():Observable<FoundationProfiles>{
    return this.repository.get(environment.apiBaseURL, 'api/User/profile');
  }
  
}
