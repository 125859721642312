<div class="background" [ngClass]="{ 'background-all': otherGroup }">
  <div class="gradient">      
    <div class="container">
        <div class="mainheading" *ngIf="isUnAuthorized && isUserActive">
          <h1>You do not have permission to view the page.</h1>
          <h2>If you feel this is an error, contact support.</h2>
        </div>
        <div class="mainheading" *ngIf="!isUserActive">
          <h1>Your account is not active.</h1>
          <h2>If you feel this is an error, contact <a href="mailto:helpdesk@aecf.org">support.</a></h2>
        </div>
    </div>     
  </div>
</div>