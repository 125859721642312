import { Component, OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteServices } from '../services/site.services';
import { SiteDataCollection, SiteModel, StateList } from '../models/site.model';
import { Month, Month2LabelMapping } from '../models/datacollections.model';
import { DatePipe } from '@angular/common';
import {formatDate} from '@angular/common';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { SystemPermissions } from '../../permissions';
import { debug } from 'console';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  @ViewChild('warningModal', {static: false}) warningModal: ModalComponent;
  siteList: Array<SiteModel>;
  managedSiteList: Array<SiteModel>;
  removedSiteList: Array<SiteModel>;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageSites: Array<SiteModel>;
  selectedUser: SiteModel = null;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  siteForm: FormGroup;
  searchTerm: string;
  currentUserRole: Roles;
  isSiteExists:boolean;
  selectedSite: SiteModel;
  currentUser: any;
  lastSort:string;
  isAddDataCollectionOpen:boolean;
  dataCollectionForm:FormGroup;
  sitedataCollection:SiteDataCollection;
  siteAsc: boolean = true;
  stateAsc: boolean = true;
  lastUpdatedAsc:boolean = true;
  lastUpdatedDateTimeAsc:boolean = true;
  isActiveAsc:boolean = true;
  buttonText:string = "Add Site"; 
  permissions = SystemPermissions;
  stateList : Array<StateList>;
  isNew :boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  siteImageURL: string;
  monthVals = Object.values(Month).filter(value => typeof value === 'number');
  Month2LabelMapping = Month2LabelMapping;
  constructor(private siteService: SiteServices,
              private stateService: StateService,
              public googleAnalyticsService: GoogleAnalyticsService,
              public datepipe: DatePipe,
              private router: Router
              ) {
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }

  

  ischeckPermissions(permission: string): boolean {
    return this.stateService.permissionCheck(permission);
  }

  ngOnInit() {
    this.managedSiteList = new Array<SiteModel>();
    this.removedSiteList = new Array<SiteModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }

    this.dropdownList.push({item_id: 'Center for Children\'s Law and Policy', item_text:'Center for Children\'s Law and Policy' });
    this.dropdownList.push({item_id: 'Evident Change', item_text:'Evident Change' });
    this.dropdownList.push({item_id: 'Empact Solutions', item_text:'Empact Solutions' });
    this.dropdownList.push({item_id: 'RFK', item_text:'RFK' });
    this.dropdownList.push({item_id: 'Impact Justice', item_text:'Impact Justice' });
    this.dropdownList.push({item_id: 'National Council of Juvenile and Family Court Judges', item_text:'National Council of Juvenile and Family Court Judges' });
    this.dropdownList.push({item_id: 'American Probation & Parole Association', item_text:'American Probation & Parole Association' });
    this.dropdownList.push({item_id: 'Georgetown/CJJR', item_text:'Georgetown/CJJR' });
    this.dropdownList.push({item_id: 'Equity Learning Series', item_text:'Equity Learning Series' });
    this.dropdownList.push({item_id: 'Juvenile Detention Alternatives Initiative', item_text:'Juvenile Detention Alternatives Initiative' });
    this.dropdownList.push({item_id: 'Probation Transformation', item_text:'Probation Transformation' });
    this.dropdownList.push({item_id: 'Reimagining Juvenile Justice', item_text:'Reimagining Juvenile Justice' });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 4,
      allowSearchFilter: false,
      enableCheckAll:true,
      defaultOpen:true
    };

    this.dropdownList.sort((a, b) => a.item_text.toLowerCase().localeCompare(b.item_text.toLowerCase()))

    this.getSites();
    this.siteForm = new FormGroup({
      name:new FormControl('', Validators.required),
      copyFromSite: new FormControl('0'),
      state: new FormControl('', Validators.required),
      isActive:new FormControl(''),
      baseLineYear: new FormControl(''),
      jdaiSince:new FormControl(''),
      probationTransformationSince: new FormControl(''),
      statusOffenses : new FormControl(''),
      is17YearsOld : new FormControl(''),
      youthJusticeInitiatives : new FormControl(''),
      fiscalYearStart : new FormControl(''),
      siteImage:new FormControl()
    });

    this.dataCollectionForm = new FormGroup({
      dataCollection:new FormControl('', Validators.required),
    });

    this.siteForm.controls['name'].valueChanges.subscribe(x=>{
        let siteName = this.siteForm.get('name').value;
        if(siteName !=null && siteName!=''){
        if(this.manageSites.length!==0 || this.siteList.length !== 0){
        let itemIndex = this.siteList.findIndex(x=>x.name.toLocaleLowerCase() == siteName.toLocaleLowerCase());
        let manageGroupIndex = this.manageSites.findIndex(x=>x.name.toLocaleLowerCase() == siteName.toLocaleLowerCase());

        if(this.selectedSite === null || this.selectedSite === undefined) {
          if(itemIndex>=0 || manageGroupIndex>=0) {
            this.isSiteExists = true;
          }else{
            this.isSiteExists = false;
          }
        }
        else{
          if((itemIndex>=0 || manageGroupIndex>=0) && this.selectedSite.name !=siteName) {
            this.isSiteExists = true;
          }else{
            this.isSiteExists = false;
          }
        }
      }
      }
    });

    this.getStateList();
  }

  
  isValidSite() {
    const siteControl = this.siteForm.get('name');
    if (siteControl.value && !siteControl.errors) {
      return true;
    }
  }

  getSites() {
    this.managedSiteList.length=0;
    this.removedSiteList.length=0;
    this.siteService.getSites()
    .subscribe(data => {
      this.siteList =data;
      if(!this.isAdmin || this.viewAsAdmin){
        let currentUserSites =  this.currentUser.sites as SiteModel[];
        let results = currentUserSites.map(s=>s.id);
        this.siteList= this.siteList.filter(s=>results.includes(s.id));
      }
        this.siteList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
        this.manageSites = this.siteList.slice(0, 10);
        this.currentPage=1;
        this.totalRows = this.siteList.length;
    });
    this.siteService.getSiteDataCollection()
    .subscribe(data => {
        this.sitedataCollection =data;
    });
  }
  getStateList(){
    this.siteService.getListOfStates().subscribe(s=> { this.stateList = s;});
  }
  exportSites(){
    this.siteService.exportSites().subscribe(data=>{
      const blob = new Blob([data], { type: 'application/octet-stream' });
      FileSaver.saveAs(data,  +'sites_list.xlsx');
    });
  }
  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    this.manageSites = this.siteList.slice(skipRows, this.currentPage*this.perPage);
  }

  resetAddForm() {
    this.selectedSite = null;
    this.isAddOpen = false;
    this.siteImageURL=null;
    this.siteForm.reset();
  }

   confirmSiteToRemove(site: SiteModel) {
    if (site) {
      this.removedSiteList.push(site);
      this.siteList = this.siteList.filter(x=>x.name !== site.name);
      let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
      this.manageSites = this.siteList.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
      this.closeConfirmModal();
    }
  }

  openConfirmationModal(site: SiteModel) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      this.defaultConfirmationModalEntity(site);
    }
  }

  defaultConfirmationModalEntity(site: SiteModel) {
    this.confirmationModalCompoent.entityData = { entityData: {
        deleteSite: site
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.closeModalWindow();
    }
  }

  openWarningModal() {
    if (this.warningModal) {
      this.warningModal.showModal();
    }
  }

  closeWarningModal(){
    if (this.warningModal) {
      this.warningModal.closeModalWindow();
    }
  }

  addSite() {
    this.isAddOpen = true;
    this.isNew =true;
  }
  openDataCollection()
  {
    this.isAddDataCollectionOpen=true;
    this.dataCollectionForm.get('dataCollection').setValue(this.sitedataCollection.dataCollection);
  }
  cancelAddDC(){
    this.isAddDataCollectionOpen=false;
  }

  manageMeasures(site: SiteModel,rowindex:number):void{
    sessionStorage.setItem('site', JSON.stringify(site));
    this.router.navigate(['/sitemeasure']);
  }
  manageMeasuresData(site: SiteModel,rowindex:number):void{
    sessionStorage.setItem('site', JSON.stringify(site));
    this.router.navigate(['/manageDataCollections']);
  }

  get enableSave():boolean{
    if(this.siteList.length>0 && this.dataCollectionForm.get('dataCollection').value!=''){
      return true;
    }else if(this.managedSiteList.length>0){
      return true;
    }
    else if(this.removedSiteList.length>0){
      return true;
    }
    return false;
  }
 

  AddUpdateSite():void {
    if(this.selectedSite ===null || this.selectedSite === undefined) {
      this.selectedSite= new SiteModel(-(this.managedSiteList.length+1),'',false,this.currentUser.fullName,'',0,0,0,0)
    }
      this.selectedSite.name = this.siteForm.get('name').value;
      this.selectedSite.state = this.siteForm.get('state').value;
      this.selectedSite.copyFromSite = this.siteForm.get('copyFromSite').value;
      this.selectedSite.isActive = (this.siteForm.get('isActive').value == '' || this.siteForm.get('isActive').value == null) ? false:true;
      this.selectedSite.baseLineYear = (this.siteForm.get('baseLineYear').value == '' || this.siteForm.get('baseLineYear').value == null? 0 : this.siteForm.get('baseLineYear').value)  ;
      this.selectedSite.jdaiSince = (this.siteForm.get('jdaiSince').value  == ''|| this.siteForm.get('jdaiSince').value == null ? 0 : this.siteForm.get('jdaiSince').value)  ;
      this.selectedSite.probationTransformationSince = (this.siteForm.get('probationTransformationSince').value  == '' || this.siteForm.get('probationTransformationSince').value == null ? 0 : this.siteForm.get('probationTransformationSince').value) ;
      this.selectedSite.is17YearsOld = (this.siteForm.get('is17YearsOld').value == '' || this.siteForm.get('is17YearsOld').value == '0' || this.siteForm.get('is17YearsOld').value == null ) ? false:true;
      this.selectedSite.fiscalYearStart = this.siteForm.get('fiscalYearStart').value ;
      this.selectedSite.statusOffenses = (this.siteForm.get('statusOffenses').value == '' || this.siteForm.get('statusOffenses').value == '0' || this.siteForm.get('statusOffenses').value == null ) ? false:true;
      let item = this.manageSites.find(item => item.id === this.selectedSite.id);
      let mitem = this.managedSiteList.find(item => item.id === this.selectedSite.id);
      if(item || mitem){
        if(item){
          item.name = this.selectedSite.name;
          item.isActive = this.selectedSite.isActive;
          item.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");;
        }

        if(mitem){
          mitem.name = this.selectedSite.name;
          mitem.isActive = this.selectedSite.isActive;
        }
        else{
          this.managedSiteList.push(this.selectedSite);
        }

      }
      else if(this.selectedSite.id<0){
      this.manageSites.push(this.selectedSite);
      this.managedSiteList.push(this.selectedSite);
    }
    const siteFormData = new FormData();
    siteFormData.set('id', this.selectedSite.id.toString());
    siteFormData.set('name', this.selectedSite.name);
    siteFormData.set('state',this.selectedSite.state);
    siteFormData.set('isActive',this.selectedSite.isActive.toString());
    siteFormData.set('jdaiSince',this.selectedSite.jdaiSince?.toString());
    siteFormData.set('baseLineYear',this.selectedSite.baseLineYear?.toString());
    siteFormData.set('probationTransformationSince',this.selectedSite.probationTransformationSince?.toString());
    siteFormData.set('is17YearsOld',this.selectedSite.is17YearsOld.toString());
    siteFormData.set('fiscalYearStart',this.selectedSite.fiscalYearStart?.toString());
    siteFormData.set('statusOffenses',this.selectedSite.statusOffenses?.toString());
    if(this.selectedItems!=null)
      siteFormData.set('youthJusticeInitiatives',this.selectedItems.map(x=>x.item_id).join(","));
    siteFormData.set('siteImage',this.siteForm.get('siteImage').value);
    this.saveSite(siteFormData);
    this.resetAddForm();
  }
  onItemSelect(item: any) {
  }

  onItemDeSelect(item: any){
  }
  saveSite(sitedata:any) :void {
    if(this.managedSiteList.length>0 || this.removedSiteList.length>0) {
      this.managedSiteList.map(s=>{
        s.lastUpdatedDateTime = new Date().toJSON();
        s.copyFromSite = s.copyFromSite == null ? 0 : s.copyFromSite;
        sitedata.set('lastUpdatedDateTime',new Date().toJSON());
        sitedata.set('copyFromSite',s.copyFromSite);
        return s;
      });
      if(this.managedSiteList.length>0){
      this.siteService.saveSites(sitedata)
        .subscribe((data) => {
          this.formSubmitSuccess = "Successfully saved sites";
          this.getSites();
          this.managedSiteList = new Array<SiteModel>();
        });
      }
          if(this.removedSiteList.length>0){
            this.siteService.removeSite(this.removedSiteList)
             .subscribe((data) => {
              this.formSubmitSuccess = "Successfully removed sites";
              this.getSites();
           });
          }
   }

    this.siteService.saveSiteDataCollection(this.sitedataCollection).subscribe((data)=>{
      this.formSubmitSuccess = "Successfully saved site data collection";
    });
  }

  AddUpdateDataCollection():void {
    this.isAddDataCollectionOpen =false;
    this.sitedataCollection = new SiteDataCollection(this.dataCollectionForm.get('dataCollection').value,'');
}

  removeSite(site: SiteModel):void{
    if(site.id>0){
    this.siteService.validateBeforeDelete(site.id)
    .subscribe((data :boolean) => {
      if(data){
        this.openWarningModal();
      }else{
        this.openConfirmationModal(site);
      }
    });
    }
    else{
      this.openConfirmationModal(site);
    }
  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }


  search():void{
    if(this.searchTerm.length===0){
      this.currentPage=0;
      this.manageSites = this.siteList.slice(this.currentPage, this.perPage);
      this.totalRows = this.siteList.length;
    }
    else{
      this.manageSites = this.siteList.filter(x=> x.name.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(0,10);
      this.totalRows = this.manageSites.length;
    }
  }

  toggleTag(column: string) {
    let value = "asc";
    if (column == 'name') {
      this.siteAsc = !this.siteAsc;
      value = this.siteAsc ? "asc" : "desc"
    }
    else if(column == 'lastUpdated')
    {
      this.lastUpdatedAsc = !this.lastUpdatedAsc;
      value = this.lastUpdatedAsc ? "asc" : "desc"
    }
    else if(column == 'lastUpdatedDateTime')
    {
      this.lastUpdatedDateTimeAsc = !this.lastUpdatedDateTimeAsc;
      value = this.lastUpdatedDateTimeAsc ? "asc" : "desc"
    }
    else if(column == 'state')
    {
      this.stateAsc = !this.stateAsc;
      value = this.stateAsc ? "asc" : "desc"
    }
      this.sortOn(column, value);
  }

  sortOn(column: string, type: string): void {
    if (column !== null) {
      if (type == "desc") {

        this.manageSites = this.siteList.sort((nodeA, nodeB) => compareItems(nodeB[column], nodeA[column]));

      } else {
        this.manageSites = this.siteList.sort((nodeA, nodeB) => compareItems(nodeA[column], nodeB[column]));
      }
      let skipRows = (this.currentPage - 1) * this.perPage;
      this.manageSites = this.siteList.slice(skipRows, this.currentPage * this.perPage);
      this.lastSort = column;
    }
  }

  editSite(site:SiteModel):void {
    this.selectedSite = site;
    this.siteImageURL=this.selectedSite.siteImageURL;
    this.isAddOpen=true;
    this.isNew =false;
    this.siteForm.get('name').setValue(site.name);
    this.siteForm.get('state').setValue(site.state);
    this.siteForm.get('isActive').setValue(site.isActive);
    this.siteForm.get('baseLineYear').setValue(site.baseLineYear==0?"":site.baseLineYear);
    this.siteForm.get('jdaiSince').setValue(site.jdaiSince==0?"":site.jdaiSince);
    this.siteForm.get('probationTransformationSince').setValue(site.probationTransformationSince==0?"":site.probationTransformationSince);
    this.siteForm.get('is17YearsOld').setValue(site.is17YearsOld ? "1":"0");
    this.siteForm.get('fiscalYearStart').setValue(site.fiscalYearStart);
    this.siteForm.get('statusOffenses').setValue(site.statusOffenses ? "1":"0");
    this.selectedItems = [];
    if(site.youthJusticeInitiatives !=null){
      let youthJusticeInitiativesArray =  site.youthJusticeInitiatives.split(',');
      if(youthJusticeInitiativesArray.length>0) {
        youthJusticeInitiativesArray.forEach(site=>{
            this.selectedItems.push({item_id: site, item_text:site });
        });
      }
    }

    this.buttonText="Save Site";
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.siteImageURL = null;
      const file = event.target.files[0];
      const reader = new FileReader();
        reader.readAsDataURL(file); // toBase64
      reader.onload = () => {
        this.siteImageURL=null;
        // this.imageSrc = reader.result as string; // base64 Image src
        this.siteForm.patchValue({siteImage: file});
      };
    }
  }
}