import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StateService } from '../../core/state-manager/appstateservice';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';

import { GoogleAnalyticsService } from '../../core/services/google/google-analytics.service';
import { environment } from 'src/environments/environment';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, BrowserCacheLocation, BrowserUtils, EventMessage, EventType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs/internal/Subject';
import { BrowserCacheManager } from '@azure/msal-browser/dist/internals';
import { HttpContext } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// declare gives Angular app access to ga function
declare let gtag: Function;

@Component({
  selector: 'app-portalconfig-header',
  templateUrl: './portalconfig-header.component.html',
  styleUrls: ['./portalconfig-header.component.scss']
})
export class PortalConfigHeaderComponent implements OnInit {
  googleAnalyticsId = environment.defaultGAId;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
loggedUserName:string;
  constructor( @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
              private currentState: StateService,
              private router: Router,
              public googleAnalyticsService: GoogleAnalyticsService
             ) {
    this.setGroupSession();
    this.configureAuthentication();

    if (!environment.gaEnable) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.urlAfterRedirects.indexOf('#id_token=') < 0 && event.urlAfterRedirects.indexOf('redirecturl=') < 0) {
            const user = this.currentState.getUserData();
            if (user != null && typeof user.id !== 'undefined' && user.id != null) {
              gtag('set', {user_id: user.id});
              gtag('config', this.googleAnalyticsId, {
                custom_map: {
                  dimension1: 'userCustId'
                },
                page_path: event.urlAfterRedirects,
                userCustId: user.id
              });
              window['dataLayer'] = window['dataLayer'] || [];
              window['dataLayer'].push({
                dlUserId : user.id
              });
            } else {
              gtag('config', this.googleAnalyticsId, {page_path: event.urlAfterRedirects});
            }
          }
        }
      });
    }
  }

  setGroupSession() {
    if (!this.currentState.getGroupSession()) {
      this.currentState.setGroupSession();
    }
  }

  configureAuthentication() {
      if (environment.gaEnable) {
        GoogleAnalyticsService.loadGoogleAnalytics(environment.defaultGAId, environment.defaultGTMId);
        this.googleAnalyticsId = environment.defaultGAId;
      }
  }

  get userName() {
    return this.loggedUserName;
  }

  navigateToRoutes(route: string) {
    this.router.navigate([route]);
  }

  ngOnInit() {
    this.setLoginDisplay();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.setLoginDisplay();
      });
   let msalInstance = this.authService.instance;
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active acccount on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

  msalInstance.addEventCallback((event: { eventType: EventType; payload: { account: any; }; }) => {
  // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    let homeAccountId=this.authService.instance.getActiveAccount().homeAccountId;
    let idtoken=homeAccountId+'-login.windows.net-idtoken-'+environment.clientId+'-'+environment.tenantId;
    var idToken = JSON.parse(localStorage.getItem(idtoken+'-')).secret;
   this.currentState.setToken(idToken);
   sessionStorage.setItem('id_token',idToken);
    this.navigateToRoutes['/confighome']

    }
  }), (error: any)=> {
      console.log('error', error);
  };

console.log('get active account', msalInstance.getActiveAccount());
// handle auth redired/do all initial setup for msal
msalInstance.handleRedirectPromise().then(authResult=>{
  // Check if user signed in 
  const account = msalInstance.getActiveAccount();
  if(account){
    // // redirect anonymous user to login page 
    // msalInstance.loginRedirect();
  this.loggedUserName = account.name;
  }
}).catch(err=>{
  // TODO: Handle errors
  console.log(err);
});
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
  logout() {
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.authService.logout();
    window.location.reload();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
  }
