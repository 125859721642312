<div *ngIf="showDashboard && (isLoggedIn$ | async)" class="report-container"> 
  <div class="backToDashboard" *ngIf="tilesClicked">
      <input *ngIf="tilesClicked" id="searchButton" value="BACK TO DASHBOARD" (click)="bindReportObject()" />
  </div>
<div class="row" *ngIf="isJJDataGroup">
    <div class="col-md-3" *ngIf="isCaseyConsultant">
      <select class="form-control" required (change)='onSiteSelected($event.target.value)'>
        <option value=-1> Select Site</option>
        <option value="0">All Sites</option>
        <option *ngFor="let site of siteList; let rowIndex=index;" [ngValue]="site.id" [selected]="site.name == this"> 
           {{site.name}} 
        </option>
      
     </select>
  </div> 
  <div class="col-md-9" *ngIf="false"><h3 style ="color: red;text-align: center;">**To copy the data image, Open the snipping tool from your PC then Press Windows logo key + Shift + S.** </h3></div> 
</div>
  <!-- <div class="backToDashboard">
    <div class="row">
      <div class="col-md-5 col-sm-5 col-xs-5">
        <label for="export">Step 1: Pick an Export Format </label>
      </div>
    </div>
  </div> -->
  <div class="backToDashboard" *ngIf="!(isJJDataGroup || isFTLAGroup || isGrantsPortal)">
    <div class="row">
      <div class="col-md-5 col-sm-5 col-xs-5">
        <label for="export">Step 1: Pick an Export Format</label>
        <button  id="btnExport"  class="btn btn-secondary button-style" (click)="onOptionsSelected('PDF')">Adobe PDF</button>
        <button  id="btnExport"  class="btn btn-secondary button-style margin_10" (click)="onOptionsSelected('PPTX')">PowerPoint</button>
      </div>
      <div class="col-md-3 col-xs-3 col-sm-3">
        <label for="export">Step 2: Go to </label>
        <button  id="btndownloadReports"  class="btn btn-secondary button-style" (click)="gotoDownloads()">Download Reports</button>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4">
        <label for="export">View Fullscreen: </label>
        <button  id="btnResize"  class="btn btn-secondary button-style" (click)="onToggleScreen()">{{resizeButtonName}}</button>
        <button  id="btnReset"  class="btn btn-secondary button-style margin_10" (click)="reportReset()">Reset</button>
      </div>
    </div>
    <input *ngIf="tilesClicked" id="searchButton" value="BACK TO DASHBOARD" (click)="bindReportObject()" />
</div>
<div class="row">
  <div [ngClass]="{ 'col-md-12': !isJJDataGroup }">
    <div id="pbi-container" [ngStyle]="{'padding-bottom': (isGrantsPortal) ? '3%' : '0%', 'height': (isFTLAGroup) ? '70vh' : 'calc(100vh - 57px)' }" >
        <span class="loadingerror" *ngIf="dashboardLoadError">The dashboard may not display  due to network security at your current location, contact your local support team for assistance</span>
    </div>
  </div>

  <!-- <div class="col-md-3" *ngIf="isJJDataGroup">
    <div class="row">
      <div class="col-md-3"><h3><b>Instructions</b></h3></div>
      <div class="col-md-4">
         <div class="delete-icon" [ngClass]="{ 'disable': viewAsAdmin }" *ngIf="isAdmin" title="Click here to add/update report instructions">
            <span  class="role-icon" (click)="editReportInstruction()" >
                <em class="far fa-edit"></em>
            </span>  
       </div>
    </div>
  </div>
    <div id="pbi-instructor" class="toppadding1">
     <textarea id="txtareaReportInstructions" class="form-control" [(ngModel)]="reportInstruction" *ngIf="isEditMode"></textarea>
     <pre *ngIf="!isEditMode" [innerHTML]="reportInstruction"></pre>
    </div>
    <div>
      <button type="button" class="btn btn-success btn-instruction" *ngIf="isEditMode" (click)="saveReportInstructions()">Save</button>
      <button type="button" class="btn btn-warning btn-instruction-cancel" *ngIf="isEditMode" (click)="cancelReportInstructions()">Cancel</button>
    </div>
  </div> -->

</div>
</div>

<div class="model-dialog" *ngIf="showDialog">
  <a class="close" (click)="cancelDialog()"><i class="fa fa-times"></i></a>
  <div>Export report</div>
  <div class="row"></div>
  <div class="row">
    <div class="col-md-12">
      <p>Report export is in progress..</p>
    </div>
  </div>
  <div class="row"> 
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <button id="cancel"class="btn btn-secondary button-style" (click)="cancelDialog()"> Ok</button>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
<div class="backdrop" *ngIf="showDialog"></div>