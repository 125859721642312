
export enum Features {
    Home = 'home',
    Reports = 'reports',
    Search = 'search',
    Upload = 'upload',
    Admin = 'admin',
    FAQ = 'faq',
    Favorites = 'favorites',
    Box = 'box',
    Dropbox = 'dropbox',
    Config = 'config',
    Analytics = 'analytics',
}

export enum Groups {
    Kms = 'kms',
    All = 'all',
    Reporting = 'reporting',
    JDAI = 'jdai',
    Thrive = 'thrive',
    FTLA = 'ftla',
    Jcyoi = 'jcyoi',
    JimCasey = 'Jim Casey Youth Dashboards Okta Users',
    grants = 'grants',
    aecfsearchdev='aecfsearchdev',
    aeckmsapp='aec-kms-app',
    JJdata='jjdata'
}

export enum SubGroups {
    All = 'all',
    Reporting = 'reporting',
    JDAI = 'jdai',
    Thrive = 'thrive',
    FTLA = 'ftla',
    Jcyoi = 'jcyoi',
    JimCasey = 'Jim Casey Youth Dashboards Okta Users',
    grants = 'grants',
    aecfsearchdev='aecfsearchdev',
    aeckmsapp='aec-kms-app',
    JJdata='jjdata'
}

export interface Feature {
    link: string;
    label: string;
    name: Features;
}

export interface NavigationRoute {
    navigateLink: string;
    rootRoute: string;
    supportedFeatures?: Array<Feature>;
}

export const NavigationRoutes = {
    aecfsearchdev: {
        navigateLink: '/confighome',
        supportedFeatures: [{
            name: Features.Home
        }       
    ]},
    grants: {
        navigateLink: '/grantshome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Favorites
        },
        {
            name: Features.Search
        },
        {
            name: Features.Admin
        },
        {
            name: Features.Upload
        },
        {
            name: Features.Box
        },
        {
            name: Features.Dropbox
        },
        {
            name: Features.Config
        },
        {
            name: Features.Analytics
        },
        {
            name: Features.Reports
        }
    ]},
    all: {
        navigateLink: '/allhome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Favorites
        },
        {
            name: Features.Search
        },
        {
            name: Features.Admin
        },
        {
            name: Features.Upload
        },
        {
            name: Features.Box
        },
        {
            name: Features.Dropbox
        },
        {
            name: Features.Config
        },
        {
            name: Features.Analytics
        }
    ]},
    jjdata: {
        navigateLink: '/jjhome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Favorites
        },
        {
            name: Features.Admin
        },
        {
            name: Features.Box
        },
        {
            name: Features.Dropbox
        },
        {
            name: Features.Config
        },
        {
            name: Features.Reports
        },
        {
            name: Features.Analytics
        }
    ]},
    kms: {
        navigateLink: '/home',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Favorites
        },
        {
            name: Features.Search
        },
        {
            name: Features.Admin
        },
        {
            name: Features.Upload
        },
        {
            name: Features.Box
        },
        {
            name: Features.Dropbox
        },
        {
            name: Features.Config
        },
        {
            name: Features.Analytics
        },
        {
            name: Features.Reports
        },
        {
            name: Features.FAQ
        }
    ]},
    reporting: {
        navigateLink: '/reportinghome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Reports
        },
        {
            name: Features.Admin
        },
        {
            name: Features.FAQ
        }]
    },
    jdai: {
        navigateLink: '/jdaihome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Favorites
        },
        {
            name: Features.Search
        },
        {
            name: Features.Admin
        },
        {
            name: Features.Upload
        },
        {
            name: Features.Box
        },
        {
            name: Features.Dropbox
        },
        {
            name: Features.Config
        },
        {
            name: Features.Analytics
        }
    ]},
    thrive: {
        navigateLink: '/thrivehome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Favorites
        },
        {
            name: Features.Search
        },
        {
            name: Features.Admin
        },
        {
            name: Features.Upload
        },
        {
            name: Features.Box
        },
        {
            name: Features.Dropbox
        },
        {
            name: Features.Config
        },
        {
            name: Features.Analytics
        }
    ]},
    ftla: {
        navigateLink: '/ftlahome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Favorites
        },
        {
            name: Features.Search
        },
        {
            name: Features.Admin
        },
        {
            name: Features.Upload
        },
        {
            name: Features.Box
        },
        {
            name: Features.Dropbox
        },
        {
            name: Features.Config
        },
        {
            name: Features.Analytics
        }
    ]},
    jcyoi: {
        navigateLink: '/jcyoihome',
        supportedFeatures: [{
            name: Features.Home
        },
        {
            name: Features.Reports
        },
        {
            name: Features.Admin
        }
    ]},
    default: {
        navigateLink: '/home',
    }
};
