import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { FaqData } from './faq-model';
import { FAQService } from './faq.service';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrls: ['./faq-content.component.scss']
})
export class FaqContentComponent implements OnInit { 
  faqForm: FormGroup;
  currentUserRole: any;
  viewAsAdmin: boolean;
  currentUser: any;
  faqContents:FaqData[];
  formSubmitSuccess: string;
  formSubmitError: string;
  currentEditItemId:number;
  get isAdmin() { return this.currentUserRole === Roles.Admin; }
  get isSuperAdmin() { return this.currentUserRole === Roles.SuperAdmin; }

  constructor(private faqService: FAQService,
              private stateService: StateService,
              private sanitizer: DomSanitizer,
              private googleAnalyticsService: GoogleAnalyticsService) { 

              }
  ngOnInit(): void {
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    
    this.faqForm = new FormGroup({
      question:new FormControl('', Validators.required),
      answer:new FormControl('', Validators.required)
    });

  this.getFAQContents();
}

getFAQContents(): void {
  this.faqService.getFaqData()
    .subscribe(data => {
      this.faqContents=data;
    });
}

SaveFAQContent(): void {
  if(this.faqForm.valid){
    let question = this.faqForm.get('question').value;
    let answer = this.faqForm.get('answer').value;
    let id= this.currentEditItemId>0?this.currentEditItemId:0;
    let faqDAta = new FaqData(id,question,answer);
    this.faqService.saveFAQContnet(faqDAta).subscribe(sucesss=>{
      this.formSubmitSuccess = "FAQ content saved";
      this.getFAQContents();
      this.faqForm.reset();
    },
    err => {
      this.formSubmitError = "FAQ content not saved";
    }
    );
  }
}
CancelFAQ()
{
  this.faqForm.reset();
  this.currentEditItemId=0;
}
parseHtml(html:string)
{
  let res = this.sanitizer.bypassSecurityTrustHtml(html);
  return res;
}

  toggelFAQ(btn:any): void {
    var panel =btn.target.parentElement.parentElement.nextElementSibling.firstChild;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  removeFAQ(faq:FaqData,index:number): void {
    this.faqService.removeFAQ(faq).subscribe(()=>{});
    this.faqContents.splice(index,1);
  }
  editFAQ(faq:FaqData,index:number): void {
    this.faqForm.get('question').setValue(faq.question);
    this.faqForm.get('answer').setValue(faq.answer);
    this.currentEditItemId=faq.id;
  }
}
