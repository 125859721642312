import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SharedModule } from 'src/app/shared/shared.module';
import { TechnologiesServices } from './services/technologies.services';
import { TechnologiesComponent } from './technologies/technologies.component';
import { organizationProfileService } from './services/organizationprofile.services';
import { OrganizationprofileComponent } from './organizationprofile/organizationprofile.component';
//import { MeasureComponent } from './measure/measure.component';
//import { MeasureServices } from './services/measure.services';
//import { DataCollectionsComponent } from './data-collections/data-collections.component';
//import { DataCollectionsServices } from './services/datacollections.services';
//import { GroupComponent } from './group/group.component';
//import { GroupServices } from './services/group.services';
//import { SitemeasuresComponent } from './sitemeasures/sitemeasures.component';
//import { SiteMeasureServices } from './services/siteMeasure.services';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { CustomMeasuresComponent } from './custom-measures/custom-measures.component';
//import { MeasureValidationsComponent } from './measure-validations/measure-validations.component';
//import { ImportDataModelComponent } from './import-data-model/import-data-model.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FileUploadModule } from 'ng2-file-upload';
//import { ApproveSiteDataComponent } from './approve-site-data/approve-site-data.component';

@NgModule({
  declarations: 
  [
    TechnologiesComponent, 
    OrganizationprofileComponent,
    //MeasureComponent, 
    //DataCollectionsComponent,
    //GroupComponent,
    //SitemeasuresComponent,
    //CustomMeasuresComponent,
    //MeasureValidationsComponent,
    //ImportDataModelComponent,
    //ApproveSiteDataComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule,
    TypeaheadModule,
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    AccordionModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ModalModule.forRoot(),
    FileUploadModule,
  ],
  providers: [
    TechnologiesServices,
    organizationProfileService,
    //MeasureServices,
    //DataCollectionsServices,
    //GroupServices,
    DatePipe,
    //SiteMeasureServices
  ]
})
export class FtlaModule { }
