<div class="row p-0" id="dvnewsfeedrowcontainer">
  <div class="col-md-12 p-0">
    <accordion [isAnimated]="true">
      <accordion-group class="newsFeed" [ngClass]="{'viewNewsFeed':isViewNewsFeed}" [(isOpen)]="isOpened">
        <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
          <div class="pull-left title" *ngIf="isOpened">Latest News 
            <i class="fa fa-caret-down" *ngIf="isOpened"></i>
          </div>
          <div class="pull-left title" *ngIf="!isOpened">Latest News 
            <i class="fa fa-caret-up"></i>
          </div>
        </button>
        <div class="row p-0">
          <div class="col-md-12 carousel-item pr p-0" *ngIf="isViewNewsFeed">
              <a class="close" (click)="isViewNewsFeed = false" ><i class="fa fa-times"></i></a>
              <!-- <a class="close" target="_blank" href="https://ftla.slack.com/archives/C04AKKGS6" title="FTLA"> <i class="fa fa-times"></i></a> -->
              <span class="badge badge-success">New</span>
              <span class="text-bold"><b>{{newsFeed.title}}</b></span>
              <p class="p-0 mb-0">{{newsFeed.body}}</p>
              <span class="author">{{newsFeed.createdBy}}</span>
              <span class="time">{{newsFeed.createdDateTime + "Z" | date:'MMM d, y hh:mm a'}}</span>
          </div>
          <div class="col-md-12 p-0" *ngIf="showFeeds && !isViewNewsFeed">
            <carousel [singleSlideOffset]="false" [itemsPerSlide]="itemsPerSlide" [interval]="false" [showIndicators]="false">
              <slide class="pr" *ngFor="let newsFeed of newsFeeds; let rowIndex=index;"> 
                <span class="badge badge-success">New</span>
                <span class="text-bold"><b>{{newsFeed.title}}</b></span>
                <p class="p-0 mb-0">{{newsFeed.body | truncate : 90 }} <a class="more-link" (click)="getNewsFeed(rowIndex)" *ngIf="(newsFeed.body + ' ').length>90">more</a> </p>
                <span class="author">{{newsFeed.createdBy}}</span>
                <span class="time">{{newsFeed.createdDateTime + "Z" | date:'MMM d, y hh:mm a'}}</span>
              </slide>
            </carousel>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
  
</div>

