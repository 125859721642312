import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'timezone'})
export class TimezoneTransformPipe implements PipeTransform {
    transform(value: string, format?: any) {
        if (value === null || value === undefined) {
            return value;
        }

        const time = moment.utc(value).local().format(format);
        return time;
    }
}
