<div class="select-resource" [formGroup]="contentForm">
    <tabset #selectionTabs>
        <tab heading="Upload Data Files" class="content-view" [ngClass]="{ 'disable': linkAdded }" customClass="{{ hasPermission(permissions.UploadFile) ? 'Show' : 'hide' }}" (selectTab)="onSelect($event)">
            <div class="upload-tab">
                <div *ngIf="contentAdded" formArrayName="formFilesField">
                    <div class="file-row" *ngFor="let formGroupItem of formFields.controls; let i=index">
                        <div [formGroup]="formGroupItem">
                            <div  class="col-sm-8 file-item">
                               <span class="file-type-icon"><em *ngIf="formFieldGroup(formGroupItem).fileAttributes" class="{{ formFieldGroup(formGroupItem).fileAttributes.cssClass }} fa-sm"></em></span>
                               <span class="file-item-span">{{formFieldGroup(formGroupItem).file.name}}</span>
                            </div>
                            <div class="text-center" class="col-sm-3">                     
                            </div>         
                            <div class="col-sm-1 delete-icon-col">                     
                                <button class="btn delete-icon" (click)="removeFileItem(formFieldGroup(formGroupItem))" *ngIf="!formFieldGroup(formGroupItem).isSuccess && !formFieldGroup(formGroupItem).isUploading">
                                    <span class="glyphicon glyphicon-trash"></span>
                                </button>                     
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="line"></div>
                    </div>
                    <div class="col-sm-12 more-files-caption">
                        Add more files
                    </div>
                </div>
                <div class="browse-files">
                    <div>All shareable office documents should be uploaded here.</div>
                    <div><b>Choose your source and select files (up to {{pendingUploadCount}} files can be uploaded)</b></div>
                    <div>
                        <input #fileInput type="file" ng2FileSelect [uploader]="uploader" multiple  class="fileupload"/>
                        <button id="button-browse" type="button" class="btn button-style upload" (click)="fileInput.click(); setInitials();">
                            <span>Browse Files</span>
                        </button>
                        <button id="button-box" type="button" class="btn button-style upload" (click)="boxUpload()">
                            <span>Box</span>
                        </button>
                        <button id="button-dropBox" type="button" class="btn button-style upload" (click)="dropBoxUpload()">
                            <span>Dropbox</span>
                        </button>
                    </div>
                    <div class="drop-files">
                        <div ng2FileDrop
                                [ngClass]="{'nv-file-over': hasDropZoneOver}"
                                (fileOver)="fileOver($event)"
                                [uploader]="uploader"
                                class="drop-zone">
                            <span class="drop-files-zone">
                                Drag & Drop file here
                            </span>
                        </div>
                    </div> 
                </div>
            </div>
        </tab>
        <tab heading="Upload Links" class="content-view" [ngClass]="{ 'disable': contentAdded }" customClass="{{ hasPermission(permissions.UploadLink) ? 'Show' : 'hide' }}" (selectTab)="onSelect($event)">
            <div class="upload-tab">
                <div *ngIf="linkAdded" formArrayName="linkField">
                    <div class="file-row" *ngFor="let linkGroupItem of linkField.controls; let i=index">
                        <div  class="col-sm-8 file-item">
                            <span class="file-type-icon"><em *ngIf="linkFieldGroup(linkGroupItem).fileAttributes" class="{{ linkFieldGroup(linkGroupItem).fileAttributes.cssClass }} fa-sm"></em></span>
                            <span class="file-item-span">{{linkFieldGroup(linkGroupItem).linkValue}}</span> 
                        </div>   
                        <div class="text-center" class="col-sm-3">                     
                        </div>
                        <div class="col-sm-1 delete-icon-col">                     
                            <button class="btn delete-icon" *ngIf="!linkFieldGroup(linkGroupItem).isUploaded" (click)="removeLink(linkFieldGroup(linkGroupItem))">
                                <span class="glyphicon glyphicon-trash"></span>
                            </button>                     
                        </div> 
                    </div>
                    <div class="col-sm-12">
                        <div class="line"></div>
                    </div>
                    <div class="col-sm-12 more-files-caption">
                        Add more links
                    </div>
                </div>
                <div class="add-links" [ngClass]="{ 'disable': disableLinkItems }">
                    <div *ngIf="linkAdded"><b>Up to {{pendingUploadCount}} more links can be added</b></div>
                    <div *ngIf="!linkAdded"><b>Add links (up to {{pendingUploadCount}} links can be added)</b></div>
                    <div>
                        <span class="linkCaption">Link</span>
                        <input id="linkText" type="url" placeholder="URL" class="form-control" [(ngModel)]="linkText" [ngModelOptions]="{standalone: true}">
                        <span (click)="addLink()" ><em class="fa fa-plus"></em></span>
                    </div>
                    <div class="link-add">                        
                        <button id="button-add" type="button" class="btn addFile button-style" (click)="addLink()">
                            <a class="btnaddfile" id="btnaddfile">Add</a>
                        </button>                
                    </div>
                </div>
            </div>
        </tab>
        <tab heading="Upload Dataset" class="content-view" customClass="{{ hasPermission(permissions.UploadDataset) ? 'Show' : 'hide' }}" [ngClass]="{ 'disable': linkAdded }" (selectTab)="onSelect($event)">
            <div class="upload-tab">
                <div  class="browse-files clearfix">
                    <div  class="col-sm-4">
                        <span class="caption">Type (Required)</span>
                        <select class="form-control select" id="selectType" formControlName="datasetType" [ngClass]="{ 'invalid': hasFieldError('datasetType') }">
                            <option value=''>Select Type</option>
                            <option value="travel">Travel</option>
                            <!--<option value="help">Helpdesk & security</option>-->
                        </select>
                    </div>
                    <div  class="col-sm-4 pt-30" *ngIf="isDisplayDownload()">
                        <a (click)="downloadTemplate()" class="cur-pointer">Download Template</a>
                    </div>
                </div>
                <div *ngIf="contentAdded" formArrayName="formFilesField">
                    <div class="file-row" *ngFor="let formGroupItem of formFields.controls; let i=index">
                        <div [formGroup]="formGroupItem">
                            <div  class="col-sm-8 file-item">
                               <span class="file-type-icon"><em *ngIf="formFieldGroup(formGroupItem).fileAttributes" class="{{ formFieldGroup(formGroupItem).fileAttributes.cssClass }} fa-sm"></em></span>
                               <span class="file-item-span">{{formFieldGroup(formGroupItem).file.name}}</span>
                            </div>
                            <div class="text-center" class="col-sm-3">                     
                            </div>         
                            <div class="col-sm-1 delete-icon-col">                     
                                <button class="btn delete-icon" (click)="removeFileItem(formFieldGroup(formGroupItem))" *ngIf="!formFieldGroup(formGroupItem).isSuccess && !formFieldGroup(formGroupItem).isUploading">
                                    <span class="glyphicon glyphicon-trash"></span>
                                </button>                     
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="line"></div>
                    </div>
                    <div class="col-sm-12 more-files-caption">
                        Add more files
                    </div>
                </div>
                <div class="browse-files">
                    <div>All shareable office documents should be uploaded here.</div>
                    <div><b>Choose your source and select files (up to {{pendingUploadCount}} files can be uploaded)</b></div>
                    <div>
                        <input #fileInput type="file" ng2FileSelect [uploader]="uploader" multiple  class="fileupload"/>
                        <button id="button-browse" type="button" class="btn button-style upload" (click)="fileInput.click()">
                            <span>Browse Files</span>
                        </button>
                    </div>
                    <div class="drop-files">
                        <div ng2FileDrop
                                [ngClass]="{'nv-file-over': hasDropZoneOver}"
                                (fileOver)="fileOver($event)"
                                [uploader]="uploader"
                                class="drop-zone">
                            <span class="drop-files-zone">
                                Drag & Drop file here
                            </span>
                        </div>
                    </div> 
                </div>
            </div>
        </tab>
        <tab heading="Upload Template" class="content-view" customClass="{{ hasPermission(permissions.UploadTemplate) ? 'Show' : 'hide' }}" [ngClass]="{ 'disable': linkAdded }" (selectTab)="onSelect($event)">
            <form [formGroup]="templateForm" (submit)="uploadTemplate()">
            <div class="upload-tab">
                <div  class="browse-files clearfix">
                    <div  class="col-sm-4">
                        <span class="caption">Type (Required)</span>
                        <select class="form-control select" id="selectType" formControlName="datasetType" [ngClass]="{ 'invalid': hasFieldError('datasetType') }">
                            <option value=''>Select Type</option>
                            <option value="travel">Travel</option>
                            <!--<option value="help">Helpdesk & security</option>-->
                        </select>
                    </div>
                </div>
                <div class="browse-files">
                    <div>All report templates should be uploaded here.</div>
                    <div>
                        <input type="file" formControlName="template" (change)="initTemplateUpload($event)"/>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <div class="col-md-4 pull-right">
                    <button [disabled]="!templateForm.valid || sending" class="btn btn-secondary button-style next-button"><ng-container *ngIf="!sending">Upload</ng-container><ng-container *ngIf="sending">Uploading</ng-container></button>
                </div>
            </div>
          </form>
        </tab>

        <tab heading="Upload Reports" class="content-view" customClass="{{ hasPermission(permissions.UploadReport) ? 'Show' : 'hide' }}" [ngClass]="{ 'disable': linkAdded }" (selectTab)="onSelect($event)">
            <form [formGroup]="reportForm" (submit)="uploadReport()">
            <div class="upload-tab">
                <div class="browse-files">
                    <div>Select power bi reports to upload</div>
                    <div>
                        <input type="file" formControlName="report" (change)="initReportUpload($event)"/>
                    </div>
                </div>
            </div>
            <div class="clearfix">
                <div class="col-md-4 pull-right">
                    <button [disabled]="!reportForm.valid || sending" class="btn btn-secondary button-style next-button"><ng-container *ngIf="!sending">Upload</ng-container><ng-container *ngIf="sending">Uploading</ng-container></button>
                </div>
            </div>
           
                <div *ngIf="successmsg!==''" class="alert alert-success">
                    {{successmsg}}
                </div>
                <div *ngIf="errormsg!==''" class="alert alert-danger">
                    {{errormsg}}
                </div>
          </form>
        </tab>
    </tabset>
    <div class="moderator-section" [ngClass]="{ 'disable': uploadCompleted }" *ngIf="!isTemplate">
      <div class="col-sm-12">
        <div  class="col-sm-6" [ngClass]="{ 'disable': isAutoApproved }">
            <span class="caption" *ngIf="!isAutoApproved">Moderator (Required)</span>
            <span class="caption" *ngIf="isAutoApproved">Moderator</span>
            <select class="form-control select" id="selectModerator" formControlName="moderator" [ngClass]="{ 'invalid': hasFieldError('moderator'), 'disable': isAutoApproved }" [readonly]="isAutoApproved">
                <option value='' *ngIf="isAutoApproved">Select Moderator</option>
                <option  *ngFor="let moderator of moderatorsList" [ngValue]="moderator" >{{moderator.fullName}}</option>
            </select>
            <div class="clearfix">
                <span *ngIf="isAutoApproved" class="input-info">Your content will be auto approved, doesn't required moderator approval.</span>
            </div>
        </div>
        <div class="col-sm-5">
            <span class="caption">Grant Number (Optional)</span>
            <input id="grantNumber" class="form-control" formControlName="grantNumber" maxlength="50" placeholder="Enter Grant Number">                    
        </div>        
      </div>
    </div>
</div>
<div *ngIf="showQueueAlert">
    <alert type="info" class="toast" [dismissible]=true [dismissOnTimeout]=5000 (onClosed)="onClosedAlert()">{{message}}</alert>
</div>
