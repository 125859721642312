import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { CustomMeasureModel } from "../models/custommeasure.model";
import { MeasureModel } from "../models/measure.model";
import { MeasurePrediciton, MeasureValidation } from "../models/measureValidation.model";

@Injectable()
export class MeasureServices {

  constructor(private repository: RepositoryService) { }

  getMeasures() : Observable<MeasureModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/measure').pipe(map(s=>{
      s.lastUpdatedDateTime = new Date(s.lastUpdatedDateTime)
      return s;
  }));
}
  saveMeasures(measures: MeasureModel[]): Observable<any>{
    return this.repository.post(environment.apiBaseURL, 'api/measure', measures);
  }
   
  removeMeasure(measures: MeasureModel[]): Observable<any>{
    return this.repository.delete(environment.apiBaseURL, 'api/measure', measures);
  }
  
  validateBeforeDelete(measureId:number): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/Measure/ismapped/'+ measureId);
  }

  getCustomeMeasures() : Observable<CustomMeasureModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/measure/custom').pipe(map(s=>{
      s.lastUpdatedDateTime = new Date(s.lastUpdatedDateTime)
      return s;
  }));
}
  saveCustomeMeasures(measures: CustomMeasureModel[]): Observable<any>{
    return this.repository.post(environment.apiBaseURL, 'api/measure/custom', measures);
  }

  ApproveRejectCustomMeasures(measures: CustomMeasureModel[]): Observable<any>{
    return this.repository.post(environment.apiBaseURL, 'api/measure/custom/approve', measures);
  }
   
  removeCustomeMeasure(measures: CustomMeasureModel[]): Observable<any>{
    return this.repository.delete(environment.apiBaseURL, 'api/measure/custom', measures);
  }

  getListSitesUsedByMeasure(measureId: number):Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Measure/viewSites/'+ measureId);
  }

  validateMeasureNumber(number: string):Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Measure/custom/validateNumber/'+ number);
  }

  getMeasureValidations():Observable<Array<MeasureValidation>> {
    return this.repository.get(environment.apiBaseURL, 'api/Measure/measurevalidations/');
  }

  GetMeasureValidationsBySiteId(measureId: number,siteId:number, calendarPeriod:number):Observable<Array<MeasureValidation>> {
    return this.repository.get(environment.apiBaseURL, 'api/Measure/measurevalidations/'+measureId+'/'+siteId+'/'+ calendarPeriod);
  }
  
  exportCustomMeasures(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/Measure/export/custom','blob');
  }

  exportMeasures(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/Measure/export','blob');
  }  

  saveMeasureValidations(measuresValidations: MeasureValidation[]): Observable<any>{
    return this.repository.post(environment.apiBaseURL, 'api/measure/measurevalidations', measuresValidations);
  }

  removeMeasureValidations(validationId: number): Observable<any>{
    return this.repository.delete(environment.apiBaseURL, 'api/measure/measurevalidations/'+validationId, '{}');
  }

  SuggestMeasureNumber(groupId: number): Observable<any>{
      return this.repository.get(environment.apiBaseURL, 'api/Measure/maxnewnumber/'+groupId,  'text');
  }

  getMeasureValidationPrediction(measureId: number,siteId:number, year:number):Observable<MeasurePrediciton> {
    return this.repository.get(environment.apiBaseURL, 'api/Measure/measureValidationsTrendLine/'+measureId+'/'+siteId+'/'+year);
  }

}