<div class="search-document-container"> 
  <div class="dvSearchResults">
    <ng-container *ngIf="showResults; else emptyContainer">
      <div class="search-document-row">
        <div *ngIf="fileDocument.externalUrl && fileDocument.externalUrl != 'Not Specified'"> 
          <a href="javascript:void(0)" (click)="goToUrl(fileDocument.externalUrl);" >
            <label *ngIf="fileDocument.fileTitle == 'Not Specified'">{{ fileDocument.fileName | titlecase }} </label>
            <label *ngIf="fileDocument.fileTitle != 'Not Specified'">{{ fileDocument.fileTitle | titlecase }} </label>
          </a>
        </div>
        <div *ngIf="fileDocument.externalUrl == 'Not Specified' || fileDocument.externalUrl === null"> 
          <label *ngIf="fileDocument.fileTitle== 'Not Specified'">{{ fileDocument.fileName | titlecase }} </label>
          <label *ngIf="fileDocument.fileTitle!= 'Not Specified'">{{ fileDocument.fileTitle | titlecase }} </label>
        </div>
        <div class="tags_container">
          <ng-container *ngFor="let item of fileDocument.tags">   
            <label *ngFor="let itemval of item.value" [hidden]="itemval == 'Not Specified' || itemval== 'false'" class="selectedFacet Rectangle">{{ itemval }}</label>
          </ng-container>
        </div>
        <p class="author_details"> Uploaded {{ fileDocument.publicationDate | date: 'MMM d, y hh:mm a' }} by {{ fileDocument.uploadedBy }} </p>
        <div class="col-md-12 result-description" *ngIf="fileDocument.description && fileDocument.description != 'Not Specified'">
            {{fileDocument.description}}
        </div>
        <div class="row">
            <div class="col-md-10 col-xs-10 search-row-buttons">
              <ng-container *ngIf="previewAvailable">
                <a *ngIf="fileDocument.toggle == 0" href="javascript:void(0)" (click)="showPreview(fileDocument)"  class="form-control preview_button">
                  <span>Show Preview</span><em class="button-icon fas far fa-eye"></em>
                </a>
                <a *ngIf="fileDocument.toggle == 1" href="javascript:void(0)" (click)="showPreview(fileDocument)"  class="form-control preview_button">
                    <span>Hide Preview</span><em class="button-icon fas far fa-eye"></em>
                </a>
              </ng-container>
              <span *ngIf="!previewAvailable"
                class="preview_text_button">
                Preview Not Available
              </span>
              <ng-container *ngIf="showDownload">
                <a (click)="downloadFile(fileDocument)" class="form-control searchButton download_button"><span>Download </span><em class='button-icon fas fa-download'></em></a>
              </ng-container>                  
            </div>
            <div class="col-md-2 col-xs-2 search-row-file-type">
              <span class="file_type_label {{ fileDocument.fileAttributes.cssIcon }}"><em class="{{ fileDocument.fileAttributes.cssClass }}"></em></span>
            </div>
        </div>  
        <div id="divpreview" class="preview_container_wrapper" *ngIf="fileDocument.toggle && !previewError">
          <div class="col-md preview_container" id="dvpreviewcol">
            <iframe *ngIf="fileDocument.fileAttributes.fileType != 'PDF' && fileDocument.fileType[0] != 'Image'"
              [src]='fileDocument.fileURL'
              class="preview_iframe"></iframe>
            <object *ngIf="fileDocument.fileAttributes.fileType == 'PDF';" [data]="fileDocument.fileURL | safeUrl" type="application/pdf">Preview not available</object>
            <img [src]="fileDocument.fileURL | safeUrl"  width="100%" height="100%"
              *ngIf="fileDocument.fileType[0] == 'Image'"
            />
          </div>
        </div>
        <div id="divpreview" class="preview_container_wrapper" *ngIf="previewError">
          <div class="col-md preview_container" id="dvpreviewcol">
            <p class="filePreviewWarning">Unable to show file preview as container name value is NULL.</p>
          </div>
        </div>           
      </div>
    </ng-container>
    <ng-template #emptyContainer>
      <p class="noResults">No document available</p>
    </ng-template>        
  </div>
</div>
