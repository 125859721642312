import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { BlobMetadata } from 'src/app/common/uploadform/models/blob-metadata-model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchEventDispatcher: EventEmitter<any> = new EventEmitter();
  searchRoutesEventDispatcher = new BehaviorSubject<any>(null);

  constructor(private repository: RepositoryService) {}

  emitSearchEvent(search: string, renderSearchResults: boolean) {
    const message = {search, renderSearchResults};
    this.searchEventDispatcher.emit(message);
  }

  emitSearchRouteEvent(paramData: any) {
    this.searchRoutesEventDispatcher.next(paramData);
  }

  getSearchEventEmitter() {
    return this.searchEventDispatcher;
  }

  getSearchRouteState(): any {
    return this.searchRoutesEventDispatcher.getValue();
  }

  public getSearchResults(input: string,searchOption:string =''): Observable<any> {
    const newValue = encodeURIComponent(input);
    return this.repository.get(environment.apiBaseURL, 'api/Search/' + newValue+'/'+searchOption);
  }

  public getBoxData(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Search/boxdata');
  }

  public getTopSearch(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Search/topSearch');
  }

  public getQueryResults(input: string): Observable<any> {
    const newValue = encodeURIComponent(input);
    return this.repository.get(environment.apiBaseURL, 'api/Search/openquery/' + newValue);
  }

  public getQueryString(input: string): Observable<any> {
    const newValue = encodeURIComponent(input);
    return this.repository.get(environment.apiBaseURL, 'api/Search/query/' + newValue);
  }

  public getFileContent(fileName: string, containerName: string) {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/download/' + fileName + '/' + containerName, 'arraybuffer');
  }

  public SendToSlack(fileName: string, containerName: string) {
    return this.repository.get(environment.apiBaseURL, 'api/Search/slacksharedocument/' + fileName + '/' + containerName);
  }
  public isSlakcEnabled() {
    return this.repository.get(environment.apiBaseURL, 'api/common/SlackEnabled');
  }

  public getBlobSASUrl(fileName: string, containerName: string): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/sastoken/' + fileName + '/' + containerName)
    .pipe(map((res: any) => {
         return res.tokenUrl;
        }));
  }

  public getPagedSearchResults(input: {}): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/Search', input);
  }

  public shareResults(input: {}, isCopyLink): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/Search/SaveSearchQuery/' + isCopyLink, input, null, "text");
  }

  public shareDocument(documentName: string, folderName: string, sharedWith: string, sharedBy: string): Observable<any> {
    const input = {documentName, folderName, sharedWith, sharedBy};
    return this.repository.post(environment.apiBaseURL, 'api/Search/sharedocument', input);
  }

  public getRawFileContent(fileId: string) {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/downloadSharedDocument/' + fileId);
  }

  public getSuggestions(input: string): Observable<any> {
    const newValue = encodeURIComponent(input);
    return this.repository.get(environment.apiBaseURL, 'api/Search/autocomplete/' + newValue);
  }

  public removeFile(files: any): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/Admin/delete', files);
  }

  getlocationsMetadata(): Observable<BlobMetadata> {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/geolocations');
  }
}
