import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jjdata-landing',
  styleUrls: [],
  template: `
    <div class="prelogin-jjdata-imgcontainer">
    </div>`
})
export class JJLandingComponent implements OnInit {

  constructor(private titleService: Title,
    private router: Router) { }

  ngOnInit() {
    this.titleService.setTitle(environment.jjdataPageTitle);
    this.router.navigate(['/jjhome']);
  }

}
