export class ReportProfile{
    id:number;
    reports: any;
    profileName: string;
    departments: Array<string>;
    jobTitles:Array<string>;
    isRemoved: boolean;
    constructor(id:number,profileName:string,reports:any,jobTitles:Array<string>,departments:Array<string>, isRemoved:boolean)
    {
        this.id = id;
        this.reports = reports;
        this.jobTitles = jobTitles;
        this.profileName = profileName;
        this.departments = departments;
        this.isRemoved = isRemoved;
    }
}

export interface reportmodel {
    reportId: string;
    reportName: string;
}