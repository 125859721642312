import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { TagItem, EditDetail, LinkItem } from '../shared/content-model';
import { ContentService } from '../shared/content.service';
import { uploadItemReqValidator } from '../shared/validator';
import { BlobMetadataComponent } from '../blob-metadata/blob-metadata.component';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { FileType, organizeFileTypeTags } from '../shared/blob-upload.model';
import { FileUploaderService } from '../shared/file-uploader.service';

@Component({
  selector: 'app-content-edit',
  templateUrl: './content-edit.component.html',
  styleUrls: ['./content-edit.component.scss']
})

export class ContentEditComponent implements OnInit {
  @ViewChild('blobMetadataView', {static: false}) blobMetadataView: BlobMetadataComponent;
  contentSubmitted = false;
  fileName: string;
  containerName: string;
  NotSpecified = 'Not Specified';
  metaDataForm: FormGroup;
  extentionRegEx = /(?:\.([^.]+))?$/;
  viewAsAdmin = this.commonService.getViewUserEventEmitter();
  blobFileTypes: Array<FileType>;

  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private uploadService: FileUploaderService,
              private contentService: ContentService,
              private router: Router,
              private commonService: CommonEntityService,
              public googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.fileName = params['fileName'];
      this.containerName = params['folderName'];
    });
    this.getFileMetadata();
    this.uploadService.getFiletypesMetadata().subscribe((fileTypes: FileType[])  => {
      this.blobFileTypes = organizeFileTypeTags(fileTypes);
    });
  }

  getFileMetadata() {
    this.contentService.getFileMetadata(this.fileName, this.containerName).subscribe(data => {
      const editDetail = new EditDetail().uiMapper(data);
      this.initMetaDataForm(editDetail);
    });
  }

  initMetaDataForm(fileMetadata: EditDetail) {
    this.metaDataForm = this.formBuilder.group({
      formFilesField: this.formBuilder.array(
                      this.getFileGroupArrayValue(fileMetadata.fileTitle, this.getFormArrayValue(fileMetadata.fileType))),
      programArea: this.formBuilder.array(this.getFormArrayValue(fileMetadata.programArea), Validators.required),
      topic: this.formBuilder.array(this.getFormArrayValue(fileMetadata.topic), Validators.required),
      community: this.formBuilder.array(this.getFormArrayValue(fileMetadata.community), Validators.required),
      continent: this.formBuilder.array(this.getLocationFormArrayValue(fileMetadata.continent)),
      country: this.formBuilder.array(this.getLocationFormArrayValue(fileMetadata.country)),
      region: this.formBuilder.array(this.getLocationFormArrayValue(fileMetadata.region)),
      division: this.formBuilder.array(this.getLocationFormArrayValue(fileMetadata.division)),
      state: this.formBuilder.array(this.getLocationFormArrayValue(fileMetadata.state)),
      jurisdiction: this.formBuilder.array(this.getLocationFormArrayValue(fileMetadata.jurisdiction)),
      publisher: this.formBuilder.array(this.getFormArrayValue(fileMetadata.publisher)),
      contentType: this.formBuilder.array(this.getFormArrayValue(fileMetadata.contentType), Validators.required),
      privacyType: this.formBuilder.array(this.getFormArrayValue(fileMetadata.privacyType), Validators.required),
      accessFor: [''],
      publicationDate: [fileMetadata.publicationDate !== '' ? fileMetadata.publicationDate + 'Z' : null],
      description: [fileMetadata.description !== this.NotSpecified ? fileMetadata.description : ''],
      linkField: this.formBuilder.array(this.getLinkGroupArrayValue(fileMetadata.fileTitle, fileMetadata.externalUrl,
                 this.getFormArrayValue(fileMetadata.fileType))),
      grantNumber: [''],
      moderator: ['']
    });
  }

  getFileGroupArrayValue(fileTile: string, fileType: any[]): any[] {
    const fileFormGroup = [];
    fileFormGroup.push(this.formBuilder.group({
        FileField: {file: {name: this.fileName}},
        kcTitleField: [fileTile, [uploadItemReqValidator(null)]],
        fileTypeField: this.formBuilder.array(this.getFormArrayValue(fileType), Validators.required)
      })
    );
    return fileFormGroup;
  }

  getLinkGroupArrayValue(fileTitle: string, externalUrl: string, fileType: any[]): any[] {
    const linkGroup = [];
    if (externalUrl && externalUrl !== 'Not Specified') {
      linkGroup.push(this.formBuilder.group({
        externalUrlField: [new LinkItem(externalUrl, null)],
        fileName: [this.fileName],
        kcTitleField: [fileTitle, [uploadItemReqValidator(null)]],
        fileTypeField: this.formBuilder.array(this.getFormArrayValue(fileType), Validators.required)
      })
      );
    }
    return linkGroup;
  }

  getFormArrayValue(formValue: any[]): any[] {
    if (!formValue) {
      return [this.NotSpecified];
    }
    return formValue;
  }

  getLocationFormArrayValue(formValue: any[]): any[] {
    const result = [];
    if (!formValue) {
      return result;
    }

    formValue.forEach(item => {
      if (item !== this.NotSpecified) {
        result.push([new TagItem(item, true, null, null, null, null, null)]);
      }
    });

    return result;
  }

  updateMetadata() {
    this.contentSubmitted = true;
    if (!this.metaDataForm.valid || this.blobMetadataView.isLocationInvalid()) {
      return;
    }

    this.googleAnalyticsService.eventEmitter('File Updated', this.containerName + '/' + this.fileName, 'Submit Resource');

    this.contentService.saveFileMetadata(this.metaDataForm.value, this.fileName, this.containerName)
    .subscribe((data) => {
      this.router.navigate(['/content/remove']);
    });
  }
}

