export class AuditLoggingFilter {
  PageIndex: number;
  PageSize: number;
  UserName: string;
  StartDate: string;
  EndDate: string;
  Action: EventAction;
  Type: EventType;


    // tslint:disable-next-line: max-line-length
    constructor(PageIndex: number,
                PageSize: number,
                UserName: string,
                Action: EventAction,
                Type: EventType,
                StartDate: string,
                EndDate: string ) {
                this.PageIndex = PageIndex;
                this.PageSize = PageSize;
                this.UserName = UserName;
                this.StartDate = StartDate;
                this.EndDate = EndDate;
                this.Action = Action;
                this.Type = Type;
    }
}

export enum EventType {
    None = 0,
    'File' = 1,
    'Resource' = 2,
    'Role' = 5,
    'User' = 6,
    'Moderator' = 7,
    'Permissions' = 8,
    'Dataset' = 9,
    'App Insights' = 10,
    'Protal Config' = 11
}
// tslint:disable-next-line: no-namespace
export namespace EventType {
  export function values() {
    return Object.keys(EventType).filter(
      (type) => isNaN( type as any) && type !== 'values' && type !== 'None'
    );
  }
}

export enum EventAction {
    None = 0,
    Inserted = 1,
    Updated = 2,
    Deleted = 3,
    Exception = 4,
    Assigned = 5,
    'Re-assigned' = 6,
    Indexed = 7
}
// tslint:disable-next-line: no-namespace
export namespace EventAction {
  export function values() {
    return Object.keys(EventAction).filter(
      (type) => isNaN( type as any) && type !== 'values' && type !== 'None'
    );
  }
}
