import { Injectable } from '@angular/core';

import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UserSet, updateSet } from './staging-file.model';
import { Observable } from 'rxjs';
import { UserDetail } from 'src/app/account/shared/user.model';
import { EditDetail } from './content-model';

@Injectable()
export class ContentService {

  constructor(private repository: RepositoryService) { }

  getStagingMetaData(setId: string) {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/stagingmetadata/' + setId);
  }

  getValidationErrors(setId: string) {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/datasetErrors/' + setId);
  }

  getPendingApprovalAssignedtoMe() {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/stagingFilesAssigned')
    .pipe(map(data => UserSet.getUserSet(data)));
  }

  getPendingApprovalNotAssignedtoMe() {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/stagingFilesNotAssigned')
    .pipe(map(data => UserSet.getUserSet(data)));
  }

  getReportTemplate(type: string) {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/reportTemplate/download/' + type, 'blob');
  }

  setTaskAssignedtoMe(setId: string) {
    return this.repository.post(environment.apiBaseURL, 'api/Moderator/stagingFilesAssignToMe', [setId]);
  }

  approveFile(fileName: string): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/approve/' + fileName);
  }

  rejectFile(fileName: string): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/reject/' + fileName);
  }

  approveSet(setId: string): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/Moderator/approve', [setId]);
  }

  rejectSet(setId: string): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/Moderator/reject', [setId]);
  }

  modifySet(metadataItems: any, setId: string): Observable<any> {
    const formData = updateSet(metadataItems);
    return this.repository.put(environment.apiBaseURL, 'api/BlobStorage/metadata/' + setId, formData);
  }

  getModerators(): Observable<UserDetail[]> {
    return this.repository.get(environment.apiBaseURL, 'api/Moderator/all');
  }

  getFileMetadata(fileName: string, containerName: string): Observable<any> {
    return this.repository.get(environment.apiBaseURL,  'api/Admin/filemetadata/' + containerName + '/' + fileName);
  }

  saveFileMetadata(fileDetail: any, fileName: string, containerName: string) {
    const model = EditDetail.fileMetadataModelMapper(fileDetail, fileName, containerName);
    return this.repository.post(environment.apiBaseURL,  'api/Admin/updatemetadata', model);
  }
}
