import { Component,OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MeasureServices } from '../services/measure.services';
import { MeasureValidation } from '../models/measureValidation.model';
import { MeasureModel } from '../models/measure.model';

@Component({
  selector: 'app-measure-validations',
  templateUrl: './measure-validations.component.html',
  styleUrls: ['./measure-validations.component.scss']
})
export class MeasureValidationsComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalComponent: ModalComponent;
  @ViewChild('warningModal', {static: false}) warningModal: ModalComponent;
  @ViewChild('errorModal', {static:false}) errorModal: ModalComponent;
  managedMeasureValidationList: Array<MeasureValidation>;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageMeasureValidations: Array<MeasureValidation>;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  searchTerm: string;
  currentUserRole: Roles;
  isGroupExists:boolean;
  isNumberExists:boolean;
  isMeasureExists:boolean;
  selectedMeasureValidation: MeasureValidation;
  currentUser: any;
  lastSort:string;
  measureValidationForm: FormGroup;
  measureValidationList: Array<MeasureValidation>;
  removedMeasureList: Array<MeasureValidation>;
  groupForm: FormGroup;
  selectedMeasureA: MeasureModel;
  selectedMeasureB:MeasureModel;
  measuresList:Array<MeasureModel>;
  validationTypeAsc: boolean = true;
  validationRuleAsc: boolean = true;
  validationMessageAsc: boolean = true;
  lastUpdatedAsc:boolean = true;
  lastUpdatedDateTimeAsc:boolean = true;
  buttonText:string = "Add Validation"; 
  measureList: Array<MeasureValidation>;
  isValidMeasureNumber:boolean;
  measureValidationSearchList: Array<MeasureValidation>;
  errorModalMsg: string = null;
  constructor(private measureService: MeasureServices,
    private stateService: StateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public datepipe: DatePipe) { 
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }

  ngOnInit(): void {
    this.managedMeasureValidationList = new Array<MeasureValidation>();
    this.removedMeasureList = new Array<MeasureValidation>();
    this.measuresList = new Array<MeasureModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }

    this.measureValidationForm = new FormGroup({
      validationRule:new FormControl(),
      validationType:new FormControl('0',Validators.required),
      validationMessage:new FormControl()
    },{ validators: identityRevealedValidator });
    
    this.getMeasures();
    this.getMeasureValidations();
  }
  
  get enableSave():boolean{
    if(this.measureValidationList.length>0){
      return true;
    }
    else if(this.managedMeasureValidationList.length>0){
      return true;
    }
    else if(this.removedMeasureList.length>0){
      return true;
    }
    return false;
  }
  
  isValidMeasure() {
    const measureControl = this.measureValidationForm.get('measure');
    if (measureControl.value && !measureControl.errors) {
      return true;
    }
  }

  getMeasures() {
    this.measureService.getMeasures()
      .subscribe(data => {
        this.measuresList = data.filter(x=>{ return x.groupOrder!=15;});
        this.measuresList.sort(this.fieldSorter(['groupOrder', 'number']));
      });
  }

  getMeasureValidations() {
    this.managedMeasureValidationList.length=0;
    this.measureService.getMeasureValidations()
    .subscribe(data => {
        this.measureValidationList = data.filter(x=> !this.removedMeasureList.some(y=> y.id == x.id));
        this.manageMeasureValidations = this.measureValidationList.sort(this.fieldSorter(['id'],1)).slice(0, 10);
        this.currentPage =1;
        this.totalRows = this.measureValidationList.length;
        this.removedMeasureList.length=0;
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    if(this.searchTerm==undefined ||  this.searchTerm?.length==0)
    {
      this.manageMeasureValidations = this.measureValidationList.slice(skipRows, this.currentPage*this.perPage);
    }
    else
    {
      this.manageMeasureValidations = this.measureValidationSearchList.slice(skipRows, this.currentPage*this.perPage);
    }
  }

  resetAddForm() {
    this.selectedMeasureValidation = null;
    this.isAddOpen = false;
    this.measureValidationForm.reset();
  }

  confirmMeasuretoRemove(measure: MeasureValidation) {
    if (measure) {
      this.removedMeasureList.push(measure);
      this.measureService.removeMeasureValidations(measure.id)
      .subscribe((data) => {
        this.formSubmitSuccess = "Successfully removed measure validations";
        this.getMeasureValidations();

    });
      this.closeConfirmModal();
    }
  }

  openConfirmationModal(measure: MeasureValidation) {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.entityData = { entityData: null };
      this.confirmationModalComponent.showModal();
      this.defaultConfirmationModalEntity(measure);
    }
  }

  defaultConfirmationModalEntity(measure: MeasureValidation) {
    this.confirmationModalComponent.entityData = { entityData: {
        deleteMeasure: measure,
      }
    };
  }

  updateConfirmationModalEntity(measureToDelete: MeasureValidation, moderators: MeasureValidation[]) {
    const users = moderators.filter(x => x.id !== measureToDelete.id);
    this.confirmationModalComponent.entityData = {
      entityData: {
        deleteMeasure: measureToDelete
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }

  addMeasure() {
    this.isAddOpen = true;
    this.measureValidationForm.reset();
    this.measureValidationForm.get('validationType').setValue(0);
    }

  checkFormula(f:string): string {
    const ops = ['+', '-', '*', '/'];
    const opsMap = '\\s*(' + ops.map(op => '\\' + op).join('|') + ')'
    const comps = ['=', '<', '>', '<=', '>=', '<>'];
    const compsMap = '\\s*(' + comps.map(comp => '\\' + comp).join('|') + ')'
    const symb = '(!\\w)*\\s*(\\w+)';
    const formulaRe = new RegExp('^(' + symb + opsMap + ')*' + symb + compsMap + symb +
                                '(' + opsMap + symb + ')*$', 'g');

    // check formula is valid syntax
    if(!formulaRe.test(f)){
      const msg = "Formula syntax is invalid. Please check the logic represented."
      this.openErrorModal(msg);
      return null;
    }

    // check measures included are valid
    const symbolsRe = new RegExp(symb, 'g');
    const matchedSymbols = [...f.matchAll(symbolsRe)].map(m=>m[2]);

    var measuresInFormula:string = '';
    var measuresValid:boolean = false;
    var curMeasNum:string = 'none';

    for (var m of matchedSymbols){
      curMeasNum = m;
      if(this.measuresList.some((meas) => meas.number == curMeasNum))
        measuresValid = true;
      else{
        measuresValid = false;
        break;
      }
    }
    if(!measuresValid){
      const msg = "The symbol " + curMeasNum + " is not a valid measure number.";
      this.openErrorModal(msg);
      return null;
    }

    return matchedSymbols.toString();

  }

  AddUpdateMeasureValidation():void {
    const measuresInRule = this.checkFormula(this.measureValidationForm.get('validationRule').value);
    if(!measuresInRule)
      return;
  if(this.selectedMeasureValidation === null || this.selectedMeasureValidation === undefined) {
      this.selectedMeasureValidation = new MeasureValidation(-(this.managedMeasureValidationList.length+1),
                                                                    this.measureValidationForm.get('validationRule').value,
                                                                    this.measureValidationForm.get('validationType').value,
                                                                    this.measureValidationForm.get('validationMessage').value,
                                                                    measuresInRule,
                                                                    {},
                                                                    this.currentUser.userPrincipalName
                                                );
    }
    else {
      this.selectedMeasureValidation.validationRule = this.measureValidationForm.get("validationRule").value;
      this.selectedMeasureValidation.validationType = this.measureValidationForm.get('validationType').value;
      this.selectedMeasureValidation.validationMessage = this.measureValidationForm.get('validationMessage').value;
      this.selectedMeasureValidation.measuresInRule = measuresInRule;
      this.selectedMeasureValidation.measureValues = {};
    }

    this.managedMeasureValidationList.push(this.selectedMeasureValidation);

    if(this.managedMeasureValidationList.length>0){
      this.measureService.saveMeasureValidations(this.managedMeasureValidationList)
        .subscribe(() => {
          this.formSubmitSuccess = "Successfully saved measure validation";
          this.getMeasureValidations();
      });
    }
    
    this.resetAddForm();
  }

getValidationType(validationType:string){
  if(validationType == 'error')
  return 'Hard stop';
  else if(validationType =='warning')
  return 'Are you sure?.'
}
getOperatorText(operator:string)
{
  if(operator =='=')
    return 'Equal';
  else if (operator == '!')
    return 'Not Equal';
  else if (operator =='<')
    return 'Less than';
  else if (operator =='>')
    return 'Greater than';
  else if (operator =='>=')
    return 'Greater than or Equal';
  else if (operator =='<=')
    return 'Less than or Equal';
}

  padLeadingZeros(num, size):string {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

removeMeasure(measure: MeasureValidation):void{
  this.openConfirmationModal(measure);
  }

openWarningModal() {
  if (this.warningModal) {
    this.warningModal.showModal();
  }
}

openErrorModal(msg:string) {
  if (this.errorModal) {
    this.errorModalMsg = msg;
    this.errorModal.showModal();
  }
}

closeWarningModal(){
  if (this.warningModal) {
    this.warningModal.closeModalWindow();
  }
}

closeErrorModal(){
  if (this.errorModal) {
    this.errorModalMsg = null;
    this.errorModal.closeModalWindow();
  }
}

cancelAdd() {
  this.resetAddForm();
}

ngOnDestroy() {
  this.closeConfirmModal();
}
 
  search():void{
    if(this.searchTerm.length===0){
      this.currentPage=0;
      this.manageMeasureValidations = this.measureValidationList.slice(this.currentPage, this.perPage);
      this.totalRows = this.measureValidationList.length;
    }
    else{
      this.measureValidationSearchList = this.measureValidationList.filter(x=> x.measuresInRule.toLowerCase().includes(this.searchTerm.toLowerCase()));
      this.manageMeasureValidations = this.measureValidationSearchList.sort(this.fieldSorter(['id'],1)).slice(0,10);
      this.totalRows = this.measureValidationSearchList.length;
    }
  }

  fieldSorter = (fields,dir=1) => (a, b) => fields.map(o => {
      if (o[0] === '-') { dir = -1; o=o.substring(1); }
      return this.getProperty(a, o) >this.getProperty(b, o) ? dir : this.getProperty(a, o) < this.getProperty(b, o) ? -(dir) : 0;
  }).reduce((p, n) => p ? p : n, 0);

  private getProperty (value: { [key: string]: any}, key: string): number|string {
    if (value == null || typeof value !== 'object') {
      return undefined;
    }

    const keys: string[] = key.split('.');
    let result: any = value[keys.shift()];

    for (const key of keys) {
      if (result == null) { // check null or undefined
        return undefined;
      }

      result = result[key];
    }

    return result;
  }
  toggleTag(column: string) {
    let value = "asc";

    if(column == 'validationRule')
      {
        this.validationRuleAsc = !this.validationRuleAsc;
        value = this.validationRuleAsc ? "asc" : "desc"
        if(value=="asc")
        this.measureValidationList.sort((a,b) => a.validationRule > b.validationRule?1:-1);
        else
        this.measureValidationList.sort((a,b) => a.validationRule < b.validationRule?1:-1);
      }
      else if(column == 'validationType')
    {
      this.validationTypeAsc = !this.validationTypeAsc;
      value = this.validationTypeAsc ? "asc" : "desc"
      if(value=="asc")
      this.measureValidationList.sort((a,b) => a.validationType > b.validationType?1:-1);
      else
      this.measureValidationList.sort((a,b) => a.validationType < b.validationType?1:-1);
    }
    else if(column == 'validationMessageAsc')
    {
      this.validationMessageAsc = !this.validationMessageAsc;
      value = this.validationMessageAsc ? "asc" : "desc"
      if(value=="asc")
      this.measureValidationList.sort((a,b) => a.validationMessage > b.validationMessage?1:-1);
      else
      this.measureValidationList.sort((a,b) => a.validationMessage < b.validationMessage?1:-1);
    }
    else if(column == 'lastUpdatedDateTime')
      {
        this.lastUpdatedAsc = !this.lastUpdatedAsc;
        value = this.lastUpdatedAsc ? "asc" : "desc"
        if(value=="asc")
        this.measureValidationList.sort((a,b) => a.lastUpdated > b.lastUpdated?1:-1);
        else
        this.measureValidationList.sort((a,b) => a.lastUpdated < b.lastUpdated?1:-1);
      }
    else if(column == 'lastUpdatedDateTime')
    {
      this.lastUpdatedDateTimeAsc = !this.lastUpdatedDateTimeAsc;
      value = this.lastUpdatedDateTimeAsc ? "asc" : "desc"
      if(value=="asc")
      this.measureValidationList.sort((a,b) => a.lastUpdatedDateTime > b.lastUpdatedDateTime?1:-1);
      else
      this.measureValidationList.sort((a,b) => a.lastUpdatedDateTime < b.lastUpdatedDateTime?1:-1);
    }
   
      this.sortCol();
  }
  sortCol(): void {
      let skipRows = (this.currentPage - 1) * this.perPage;
      this.manageMeasureValidations = this.measureValidationList.slice(skipRows, this.currentPage * this.perPage);
  }
  sortOn(column: string, type: string): void {
    if (column !== null) {
      if (type == "desc") {
        this.manageMeasureValidations = this.measureValidationList.sort((nodeA, nodeB) => compareItems(nodeB[column], nodeA[column]));
      } else {
        this.manageMeasureValidations = this.measureValidationList.sort((nodeA, nodeB) => compareItems(nodeA[column], nodeB[column]));
      }
      let skipRows = (this.currentPage - 1) * this.perPage;
      this.manageMeasureValidations = this.measureValidationList.slice(skipRows, this.currentPage * this.perPage);
      this.lastSort = column;
    }
  }

  editMeasure(measure:MeasureValidation):void {
    this.selectedMeasureValidation = measure;
    this.isAddOpen=true;
    this.measureValidationForm.get('validationRule').setValue(measure.validationRule);
    this.measureValidationForm.get('validationType').setValue(measure.validationType);
    this.measureValidationForm.get('validationMessage').setValue(measure.validationMessage);
    this.buttonText="Save Validation";
 }
}


export function selectValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return  control.value !== '0' ? { identityRevealed: true } : null;
  };
}

export const identityRevealedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const validationType = control.get('validationType');
  return validationType.value !== 0 ? null :{ identityRevealed: true };
};