import { Component, OnInit } from '@angular/core';

import { AccountService } from '../shared/account.service';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';

@Component({
  selector: 'app-permission-management',
  templateUrl: './permission-management.component.html',
  styleUrls: ['./permission-management.component.scss']
})
export class PermissionManagementComponent implements OnInit {

  formSubmitSuccess: string;
  formSubmitError: string;
  userRoles: any;
  currentUserRole: Roles;
  selectedUserRole: any = null;
  currentUser: any;
  viewAsAdmin = false;
  isAllGroup = false;
  isAddOpen = false;
  isKMSGroup =  false;
  isFTLAGroup = false;
  isEmailNotification = false;
  roleColumnSize = 2;
  nameColumnSize = 4;
  jjroleColumnSize=0;
  isJJGroup=false;
  permissionsAssignment: any[];
  changedPermissions = [];

  community = this.stateService.getGroupSession();

  constructor(private accountService: AccountService,
              private stateService: StateService,
              public googleAnalyticsService: GoogleAnalyticsService) {
    this.userRoles = Roles;
  }

  get isAdmin() { return this.currentUserRole === Roles.Admin; }

  get isModerator() { return this.currentUserRole === Roles.Moderator; }

  get isSuperAdmin() { return this.currentUserRole === Roles.SuperAdmin; }

  ngOnInit() {
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    if (this.isModerator) {
      this.selectedUserRole = this.userRoles.Moderator;
    }
    if (this.stateService.isAllGroup()) {
      this.isAllGroup = true;
    }
    if (this.stateService.isKmsGroup()) {
      this.isKMSGroup = true;
    }
    this.isFTLAGroup = this.stateService.isFTLAGroup();
    this.isJJGroup = this.stateService.isJJGroup();
    if ((this.isAdmin === true || this.isSuperAdmin === true) && this.isAllGroup === true) {
      this.roleColumnSize = 3;
      this.nameColumnSize = 3;
    }
    else if(this.isJJGroup)
    {
      this.roleColumnSize = 1;
      this.nameColumnSize = 2;
      this.jjroleColumnSize=2
    }
    this.getPermissions();
  }

  // Main method to get assigned permissions
  getPermissions() {
    this.accountService.getAllPermissions()
    .subscribe(permissionsData => {
      this.accountService.getAllRolesPermissions()
      .subscribe(rolesPermissionsData => {
        // Filter resulted data for current community and reduce to data to our needed format
        this.permissionsAssignment = rolesPermissionsData.filter( x =>
          x.community.toLowerCase() === this.community.toLowerCase()
        ).reduce((r, a) => {
          r[a.permission] = [...r[a.permission] || [], a];
          return r;
        }, {});

        permissionsData.forEach(element => {
          if (this.permissionsAssignment[element.permission] === undefined) {
            this.permissionsAssignment[element.permission] = [{permissionId: element['id']}];
          }
        });
      });
    });
  }

  // Method which is used to check the existance ofr permission assignment
  checkPermission(permissionObj, roleName) {
    let permissionCheck = [];
    permissionCheck = permissionObj.filter(x =>
      roleName === 'User' ? 
        x.role !== undefined && (x.role === 'Staff' || x.role === 'Partner')
      : x.role !== undefined && (x.role === roleName)
    );
    return permissionCheck.length > 0 ? permissionCheck[0].role : '';
  }

  // Method used to populate the changed permissions
  // In this we have two cases, for new permissions, if user checked we need to add that entry.
  // For already checked entry, if user uncheck we need to add that entry.
  checkboxSelect(event, permissionObj, permissionName, roleName) {
    // This is base flag to determine the operation.
    let addRemoveFlag = event.target.checked;
    if (this.checkPermission(permissionObj, roleName) !== '') {
      addRemoveFlag = !addRemoveFlag;
    }
    // Decision maker for the new permission / old permission
    let getPermission = [];
    getPermission = permissionObj.filter(x =>
      roleName === 'User' ? 
        x.role !== undefined && (x.role === 'Staff' || x.role === 'Partner')
      : x.role !== undefined && (x.role === roleName)
    );
    // Final modified permissions object creaion.
    const object = {};
    if (addRemoveFlag) {
      if (getPermission.length > 0) {
        object['community'] = this.community;
        object['permission'] = permissionName;
        object['role'] = roleName;
        object['permissionId'] = getPermission[0].permissionId;
        object['rolePermissionId'] = getPermission[0].rolePermissionId;
      } else {
        object['community'] = this.community;
        object['permission'] = permissionName;
        object['role'] = roleName;
        object['permissionId'] = permissionObj[0].permissionId;
        object['rolePermissionId'] = 0;
      }
      this.changedPermissions.push(object);
    } else {
      const removeIndex = this.changedPermissions.map(item => item.permission + ':' + item.role).indexOf(permissionName + ':' + roleName);
      ~removeIndex && this.changedPermissions.splice(removeIndex, 1);
    }
  }

  // Main method to save permissions
  updatePermission() {
    this.accountService.savePermissions(this.changedPermissions)
      .subscribe((data) => {
        this.formSubmitSuccess = 'Successfully Updated Permissions';
        this.getPermissions();
      });
  }
}

