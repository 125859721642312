import { Injectable, QueryList } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { AuditLoggingFilter, EventAction, EventType } from '../model/audit-logging-filter.model';
import { Action } from 'rxjs/internal/scheduler/Action';

@Injectable()
export class AuditLoggingService {

  constructor(private repository: RepositoryService) { }

  exportAuditLoggings(pageIndex: number, perPage: number, userName?:string, eventAction?: EventAction, eventType?: EventType, fromDate?: string, toDate?: string): Observable<any> {
    const auditFilter = new AuditLoggingFilter(pageIndex, perPage, userName, eventAction, eventType, fromDate, toDate);
    return this.repository.post(environment.apiBaseURL, 'api/Audit/export', auditFilter, null, 'blob');
  }

  getAudits(pageIndex: number, perPage: number, userName?:string, eventAction?: EventAction, eventType?: EventType, fromDate?: string, toDate?: string, isExport?: boolean): Observable<any> {
    const auditFilter = new AuditLoggingFilter(pageIndex, perPage, userName, eventAction, eventType, fromDate, toDate);
    let exportParam = isExport ? '?isexport=' + isExport : '';
    return this.repository.post(environment.apiBaseURL, 'api/Audit' + exportParam, auditFilter);
  }
}
