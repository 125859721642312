<div class="container user-bulk-upload-container">
    <div class="heading d-flex">
        <div class="page-title">
          <div>
              <span calss="page-title"><h1>Import Users</h1></span>
          </div>
      </div>
</div>
<hr>
<div class="pull-right padding-2p">
    <a  (click)="downloadUserBulkUploadTemplate()" > <span>Download Import Template</span> &nbsp; <i class="fa fa-download"></i></a>
</div>

<div class="pull-right padding-2p">
    <a (click)="downloadUserBulkUploadInstructions()"> <span>Instructions for Adding Site Names</span> &nbsp; <i class="fa fa-download"></i></a>
</div>

<div class="browser-file" >
    <form [formGroup]="bulkuploadForm">
        <div class="content-style">
            <label for="myfile">Select a file:</label>
            <input #fileInput type="file" ng2FileSelect  [uploader]="uploader" class="fileupload" (change)="initUpload($event)"/>
            <button id="button-browse" type="button" class="btn button-style upload" (click)="fileInput.click();">
                <span>Browse Files</span>
            </button>
            <span class="file-item-span">{{filename}}</span>
            <button (click)="uploadFile()" class="btn button-style" style="width: 100px;" [disabled]="bulkuploadForm.invalid">
                <span>Upload</span>
            </button>
        </div>
    </form>
    <div class="row content-style" *ngIf="importUserStatus" >
       <table class="table table-striped sortable" id="printableArea">
            <tbody>
                <tr>
                    <td>Total Number Of Rows: {{importUserStatus.totalRows}}</td>
                    <td>Invalid Email Rows: <b>({{importUserStatus.invalidEmailAddressRows.length}}) </b>:   {{importUserStatus.invalidEmailAddressRows.join(", ")}}</td>
                </tr>
                <tr>
                    <td>Users already existss: <b>({{importUserStatus.usersAlreadyExists.length}}) </b>:   {{importUserStatus.usersAlreadyExists.join(", ")}}</td>
                    <td>Invalid FirstName Rows: <b>({{importUserStatus.invalidFirstNameRows.length}})</b>:   {{importUserStatus.invalidFirstNameRows.join(", ")}}</td>
                </tr>
                <tr>
                    <td>Invalid LastName Rows: <b>({{importUserStatus.invalidLastNameRows.length}})</b>:   {{importUserStatus.invalidLastNameRows.join(", ")}}</td>
                    <td>Invalid Roles Rows: <b>({{importUserStatus.invalidRoleRows.length}})</b>:   {{importUserStatus.invalidRoleRows.join(", ")}}</td>
                </tr>
                <tr *ngIf="importUserStatus!=null">
                    <td><button type="button"  [ngClass]="{ 'disabled':  viewAsAdmin }"   id="btnApprove" class="btn btn-success" (click)="ApproveRejectUsers(true)">Approve</button></td>
                    <td style="text-align:right;"><button type="button"  [ngClass]="{ 'disabled':  viewAsAdmin }"   id="btnReject" class="btn btn-warning " (click)="ApproveRejectUsers1(false)">Reject</button></td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>  
    <div *ngIf="showQueueAlert">
        <alert type="info" class="toast" [dismissible]=true [dismissOnTimeout]=5000 (onClosed)="onClosedAlert()">{{message}}</alert>
    </div>
    <div class="row" *ngIf="importUserStatus!=null">
        <div>
            
        </div>
        <div class="col-12 table-responsive">
          <table class="table table-striped sortable" id="printableArea">
            <thead>
              <tr>
                <th><div style="display:inline-flex"> Full Name</div></th>
                <th><div style="display:inline-flex"> User Principal Name</div></th>
                <th><div style="display:inline-flex"> Title </div></th>
                <th><div style="display:inline-flex"> Organization </div></th>
                <th><div style="display:inline-flex"> Role </div></th>
                <th><div style="display:inline-flex"> Sites </div></th>
                <th><div style="display:inline-flex"> Errors</div></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of importUserStatus.importedUsers; let rowIndex=index;">
                <td>{{user.firstName +" " + user.lastName }}</td>
                <td>{{user.userPrincipalName}}</td>
                <td>{{user.jobTitle}}</td>
                <td>{{user.organization}}</td>
                <td>{{user.role}}</td>
                <td>{{user.siteLists}}</td>
                <td>{{user.errors}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>

    <app-modal #warningModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
        <ng-template #modalContentTemplate>
            <div class="col-sm-12 content-style">
                <div>
                    <span>Bulk Upload Approved</span>
                </div>
            </div>
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" (click)="closeWarningModal()">
                        <span>Ok</span>
                    </button>
                </div>
            </div>
        </ng-template>
    </app-modal>

    <app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
        <ng-template #modalContentTemplate>
            <div class="col-sm-12 content-style">
                <div>
                    <span>Bulk Upload Rejected</span>
                </div>
            </div>
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmationModal()">
                        <span>Ok</span>
                    </button>
                </div>
            </div>
        </ng-template>
    </app-modal>

</div>