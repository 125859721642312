import { formatDate } from '@angular/common';

export class TechnologiesModel {
  id: number;
  itFunctionId: number;
  itSolutionId: number;
  foundationId: number;
  vendorId: number;
  partnerId: number;
  product: string;
  partnerExperience: string;
  partnerContactName: string;
  partnerContactEmail: string;
  comments: string;
  createdDateTime: string;
  updatedDateTime: string;
  createdBy: string;
  updatedBy: string;
  functionName: string;
  solutionName: string;
  foundationName: string;
  vendorName: string;
  partnerName: string;
  currentlyUsed: boolean;

  public addTechnologies(id: number,  itSolutionId: number, itFunctionId: number, vendorId: number, partnerId: number, partnerContactName: string, partnerExperience: string, comments: string, functionName: string, currentlyUsed: boolean) {
    Object.assign(this, {
      id: id,
      itSolutionId: itSolutionId,
      itFunctionId: itFunctionId,
      vendorId: vendorId,
      partnerId: partnerId,
      // product: product,
      partnerContactName: partnerContactName,
      partnerExperience: partnerExperience,
      comments: comments,
      foundationName: functionName,
      currentlyUsed: currentlyUsed,
    });
    return this;
    //this.functionName = functionName;
    //this.solutionName = solutionName;
    //this.foundationName = foundationName;
    //this.vendorName = vendorName;
    //this.partnerName = partnerName;

  }
}

export class FunctionList {
  ITFunction: string;
  Id: number;
}

export class SolutionList {
  ITSolution: string;
  Id: number;
}

export class VendorList {
  Vendor: string;
  Id: number;
}

export class PartnerList {
  Vendor: string;
  Id: number;
}
