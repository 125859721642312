import { Injectable } from '@angular/core';

const APP_PREFIX = '';

@Injectable()
export class SessionStorageService {

  constructor() {}

  setItem(key: string, value: string): void {
    if (value) {
      window.sessionStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    }
  }

  getItem(key: string): any {
    const returnValue = window.sessionStorage.getItem(`${APP_PREFIX}${key}`);

    if (returnValue === null) {
      return null;
    } else {
      return JSON.parse(returnValue);
    }
  }

  removeItem(key: string): void {
    window.sessionStorage.removeItem(`${APP_PREFIX}${key}`);
  }

  clear(): void {
    window.sessionStorage.clear();
  }
}
