import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { TechnologiesModel } from "../models/technologies.model";

@Injectable()
export class TechnologiesServices {

  constructor(private repository: RepositoryService) { }

  getTechnologies(foundationName: string) : Observable<TechnologiesModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/ITTechnologies/TechnologiesList/'+foundationName);
  }

  saveTechnologies(technologies: TechnologiesModel){
    return this.repository.post(environment.apiBaseURL, 'api/ITTechnologies', technologies);
  }
   
  removeTechnologies(technologies: TechnologiesModel){
    return this.repository.delete(environment.apiBaseURL, 'api/ITTechnologies', technologies);
  }

  getFunctionList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL,'api/ITTechnologies/functionList');
  }

  getSolutionList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL,'api/ITTechnologies/solutionList');
  }

  getVendorList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL,'api/ITTechnologies/vendorList');
  }

  getPartnerList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL,'api/ITTechnologies/partnerList');
  }

  saveFunction(itFunction: string){
    return this.repository.get(environment.apiBaseURL, 'api/ITTechnologies/addFunction/'+ itFunction);
  }

  saveSolution(solution: string){
    return this.repository.get(environment.apiBaseURL, 'api/ITTechnologies/addSolution/'+solution);
  }

  savePartner(partner: string){
    return this.repository.get(environment.apiBaseURL, 'api/ITTechnologies/addPartner/'+ partner);
  }

  saveVendor(vendor: string){
    return this.repository.get(environment.apiBaseURL, 'api/ITTechnologies/addVendor/'+ vendor);
  }

  getSlackMessage(){
    return this.repository.get(environment.apiBaseURL, 'api/ITTechnologies/slacksendmessage');
  }

}
