import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { ContentService } from '../shared/content.service';
import { UserSet, FileDetail, LinkDetail } from '../shared/staging-file.model';
import { metadataTags, locationTags, FileType, organizeFileTypeTags } from '../shared/blob-upload.model';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { uploadItemReqValidator } from '../shared/validator';
import { LinkItem, TagItem } from '../shared/content-model';
import { getSupportedFileAttributes } from 'src/app/common/models/file-definitions';
import { BlobMetadataComponent } from '../blob-metadata/blob-metadata.component';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { FileUploaderService } from '../shared/file-uploader.service';

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent implements OnInit, OnDestroy {
  @ViewChild('metatagsModal', {static: false}) metatagsModalCompoent: ModalComponent;
  @ViewChild('blobMetadataView', {static: false}) blobMetadataView: BlobMetadataComponent;
  userSets: UserSet[];
  NotSpecified = 'Not Specified';
  metaDataForm: FormGroup;
  contentSubmitted = false;
  extentionRegEx = /(?:\.([^.]+))?$/;
  moderatorType = 'assignedToMe';
  viewAsAdmin = this.commonService.getViewUserEventEmitter();
  isModalOpen = false;
  isDataset = false;
  validationErrors: Array<any>;
  blobFileTypes: Array<FileType>;
  tabSelected = '';

  constructor(private uploadService: FileUploaderService, private contentService: ContentService, private formBuilder: FormBuilder, private commonService: CommonEntityService,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  get tags(): any {
    const metaTags = Object.keys(metadataTags);
    const index = metaTags.findIndex(x => x === 'location');
    metaTags.splice(index, 1, ...Object.keys(locationTags));
    return metaTags;
  }

  ngOnInit() {
    this.validationErrors = [];
    this.showPendingApproval();
    this.uploadService.getFiletypesMetadata().subscribe((fileTypes: FileType[])  => {
      this.blobFileTypes = organizeFileTypeTags(fileTypes);
    });
  }

  showPendingApproval() {
    let resultSet: Observable<UserSet[]>;
    if (this.moderatorType === 'assignedToMe') {
      resultSet = this.contentService.getPendingApprovalAssignedtoMe();
    } else {
      resultSet = this.contentService.getPendingApprovalNotAssignedtoMe();
    }
    resultSet
    .subscribe(data => {
        this.userSets = data;
    });
  }

  selectCheckChanged(event, setItem) {
    this.userSets.forEach(element => {
      element.viewItems = false;
    });
    setItem.viewItems = event.target.checked;
  }

  approveSet(setId) {
    this.googleAnalyticsService.eventEmitter('Approve File Set',  setId, 'Moderate Content');
    this.contentService.approveSet(setId)
    .subscribe((data) => {
        this.showPendingApproval();
    });
  }

  rejectSet(setId) {
    this.googleAnalyticsService.eventEmitter('Reject File Set',  setId, 'Moderate Content');
    this.contentService.rejectSet(setId)
    .subscribe(() => {
      this.showPendingApproval();
    });
  }

  assignToMe(setId) {
    this.contentService.setTaskAssignedtoMe(setId)
    .subscribe(() => {
      this.showPendingApproval();
    });
  }

  approve(file) {
    this.googleAnalyticsService.eventEmitter('Approve File',  file, 'Moderate Content');
    this.contentService.approveFile(file)
    .subscribe((data) => {
        this.showPendingApproval();
    });
  }

  reject(file) {
    this.googleAnalyticsService.eventEmitter('Reject File',  file, 'Moderate Content');
    this.contentService.rejectFile(file)
    .subscribe(() => {
      this.showPendingApproval();
    });
  }

  modifySet(userSet: UserSet, openModal: boolean, isOpen: boolean) {
    if (openModal) {
      if (this.isModalOpen) {
        return;
      } else {
        this.isModalOpen = true;
      }
    }
    if (!openModal && isOpen) {
      return;
    }
    this.validationErrors = [];
    this.contentService.getStagingMetaData(userSet.uniqueKey)
    .subscribe(data => {
      this.isDataset = userSet.isDataset;
      this.tabSelected = "";
      userSet.getUserMetaData(data);
      if(userSet.isDataset) {
        this.tabSelected = "Upload Dataset";
        this.contentService.getValidationErrors(userSet.uniqueKey)
          .subscribe(data => {
            this.validationErrors = data;
        });
      }
      if (userSet.usersSet && openModal) {
        this.openMedatagsModal(userSet);
      } else if (openModal) {
        this.isModalOpen = false;
      }
    });
  }

  openMedatagsModal(userSet) {
    if (this.metaDataForm) {
      this.metaDataForm.reset({}, {emitEvent: false});
    }
    this.initMetaDataForm(userSet);
    if (this.metatagsModalCompoent) {
        this.metatagsModalCompoent.entityData = {
          headerText: 'Modify Metadata',
          entityData: {
            metaDataForm: this.metaDataForm,
            setId: userSet.uniqueKey
          }
        };
        this.metatagsModalCompoent.showModal();
    }
  }

  closeUserDisplayModalEvent(message) {
    this.isModalOpen = false;
  }

  closeConfirmModal(event) {
    if (this.metatagsModalCompoent) {
      this.metatagsModalCompoent.closeModalWindow();
    }
  }

  ngOnDestroy() {
    this.closeConfirmModal('close');
  }

  initMetaDataForm(userSet: UserSet) {
    this.metaDataForm = this.formBuilder.group({
      formFilesField: this.formBuilder.array(this.getFileGroupArrayValue(userSet.fileSet)),
      programArea: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.programArea), Validators.required),
      topic: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.topic), Validators.required),
      //community: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.community), Validators.required),
      continent: this.formBuilder.array(this.getLocationFormArrayValue(userSet.usersSet.continent)),
      country: this.formBuilder.array(this.getLocationFormArrayValue(userSet.usersSet.country)),
      region: this.formBuilder.array(this.getLocationFormArrayValue(userSet.usersSet.region)),
      division: this.formBuilder.array(this.getLocationFormArrayValue(userSet.usersSet.division)),
      state: this.formBuilder.array(this.getLocationFormArrayValue(userSet.usersSet.state)),
      focus: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.focus)),
      jurisdiction: this.formBuilder.array(this.getLocationFormArrayValue(userSet.usersSet.jurisdiction)),
      contentType: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.contentType), Validators.required),
      privacyType: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.privacyType), Validators.required),
      fileType: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.fileType), Validators.required),
      authors: this.formBuilder.array(this.getFormArrayValue(userSet.usersSet.author)),
     // accessFor: [userSet.usersSet.AccessFor, Validators.required],
      description: [userSet.usersSet.Description !== this.NotSpecified ? userSet.usersSet.Description : ''],
      linkField: this.formBuilder.array(this.getLinkGroupArrayValue(userSet.linkSet)),
      grantNumber: [userSet.usersSet.GrantNumber],
      moderator: [userSet.usersSet.moderator]
    });
  }

  getLocationFormArrayValue(formValue: string): any[] {
    if (!formValue || formValue === 'Not Specified') {
      return [];
    }
    return formValue.split(',').map(item => [new TagItem(item, true, null, null, null, null, null)]);
  }

  getFormArrayValue(formValue: string): any[] {
      if (!formValue || formValue === 'Not Specified') {
        return [];
      }
      return formValue.split(',').map(item => [item]);
  }

  getFileGroupArrayValue(fileItems: Array<FileDetail>): any[] {
    const fileFormGroup = [];
    fileItems.forEach(fileItem => {
      const fileName = fileItem.FileName;
      const extention = this.extentionRegEx.exec(fileName)[1].toLowerCase();
      fileItem['fileAttributes'] = getSupportedFileAttributes(extention);
      fileFormGroup.push(this.formBuilder.group({
          FileField: {file: {name: fileItem.FileName}},
          kcTitleField: [fileItem.KCTitle, [uploadItemReqValidator(null)]],
          fileTypeField: this.formBuilder.array(this.getFormArrayValue(fileItem.FileType), Validators.required)
        })
      );
    });
    return fileFormGroup;
  }

  getLinkGroupArrayValue(linkItems: Array<LinkDetail>): any[] {
    const linkGroup = [];
    linkItems.forEach(fileItem => {
      const fileName = fileItem.FileName;
      const extention = this.extentionRegEx.exec(fileName)[1].toLowerCase();
      fileItem['fileAttributes'] = getSupportedFileAttributes(extention);
      linkGroup.push(this.formBuilder.group({
          externalUrlField: [new LinkItem(fileItem.ExternalUrl, null)],
          fileName: [fileItem.FileName],
          kcTitleField: [fileItem.KCTitle, [uploadItemReqValidator(null)]],
          fileTypeField: this.formBuilder.array(this.getFormArrayValue(fileItem.FileType), Validators.required)
        })
      );
    });
    return linkGroup;
  }

  onSubmit(setId) {
    this.contentSubmitted = true;
    if (!this.metaDataForm.valid || this.blobMetadataView.isLocationInvalid()) {
      return;
    }
    this.googleAnalyticsService.eventEmitter('Modify File Set',  setId, 'Moderate Content');
    this.contentService.modifySet(this.metaDataForm.value, setId)
    .subscribe(() => {
      this.closeConfirmModal('close');
      this.showPendingApproval();
    });
  }
}
