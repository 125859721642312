import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';

import { FileUploaderService } from '../shared/file-uploader.service';
import { fileExtensionValidator } from '../../shared/directives/file-extension-validator.directive';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { uploadItemReqValidator } from '../shared/validator';
import { getSupportedFileAttributes } from 'src/app/common/models/file-definitions';
import { LinkItem } from '../shared/content-model';
import { ContentService } from '../shared/content.service';
import { UsersList } from 'src/app/account/shared/user.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { SystemPermissions } from 'src/app/permissions';

@Component({
  selector: 'app-content-file-upload',
  templateUrl: './content-file-upload.component.html',
  styleUrls: ['./content-file-upload.component.scss']
})
export class ContentFileUploadComponent implements OnInit {
  @ViewChild('selectionTabs') selectionTabs: TabsetComponent;
  hasDropZoneOver = false;
  droppedItems = [];
  filesToUpload = [];
  uploader: FileUploader;
  @Input() contentForm: FormGroup;
  @Input() contentSubmitted: boolean;
  @Input() uploadCompleted: boolean;
  @Output() contentUpdated = new EventEmitter<boolean>();
  @Output() tabSelected = new EventEmitter<string>();
  linkText: string;
  contentAdded = false;
  linkAdded = false;
  showQueueAlert = false;
  disableLinkItems = false;
  uploadContentType = 'files';
  extentionRegEx = /(?:\.([^.]+))?$/;
  pendingUploadCount = this.fileUploader.queueLimit;
  moderatorsList: any;
  message: string;
  isDataset = false;
  isTemplate = false;
  isReport = false;
  isKmsGroup = false;
  isAllGroup = false;
  isFtlaGroup = false;
  templateForm: FormGroup;
  reportForm: FormGroup;
  sending = false;
  userRole: Roles;
  permissions = SystemPermissions;
  isDropBox = false;
  isBox = false;
  successmsg='';
  errormsg='';
  isAutoApproved=false;

  constructor(private fileUploader: FileUploaderService,
              private formBuilder: FormBuilder,
              private currentState: StateService,
              private contentService: ContentService,
              public googleAnalyticsService: GoogleAnalyticsService) { }

  get formFields(): FormArray {
    return this.contentForm.get('formFilesField') as FormArray;
  }

  formFieldGroup(groupItem): FileItem {
    return groupItem.controls.FileField.value;
  }

  get linkField(): FormArray {
    return this.contentForm.get('linkField') as FormArray;
  }

  get isAdmin() { return this.userRole === Roles.Admin; }

  get isSuperAdmin() { return this.userRole === Roles.SuperAdmin; }

  linkFieldGroup(groupItem): LinkItem {
    return groupItem.controls.externalUrlField.value;
  }

  hasFieldError(fieldName) {
    return this.contentSubmitted && this.contentForm.controls[fieldName].errors;
  }
  
  hasPermission(permission: string): boolean {
    return this.currentState.permissionCheck(permission);
  }

  ngOnInit() {
    const viewAsAdmin = this.currentState.isViewAsAdmin();
    if (viewAsAdmin) {
      this.userRole = this.currentState.getViewUserRole();
    } else {
      this.userRole = this.currentState.getRole();
    }
    this.isKmsGroup = this.currentState.isKmsGroup();
    this.isAllGroup = this.currentState.isAllGroup();
    this.isFtlaGroup = this.currentState.isFTLAGroup();
    this.initFileUpload();
    this.fileUploader.initBoxUpload();
    this.getModerators();
    this.templateForm = new FormGroup({
      FormFile: new FormControl('', Validators.required),
      template: new FormControl('', [Validators.required, fileExtensionValidator('xls, xlsx')]),
      datasetType: new FormControl('', Validators.required)
    });
    this.reportForm = new FormGroup({
      FormFile: new FormControl('', Validators.required),
      report: new FormControl('', [Validators.required, fileExtensionValidator('pbix')]),
    });

    if((this.isSuperAdmin && this.isAllGroup) || ((this.isAdmin || this.isSuperAdmin) && this.isFtlaGroup))
    {
        this.isAutoApproved=true;
    }
  }

  getModerators() {
    this.contentService.getModerators()
    .subscribe(data => {
      this.moderatorsList = new UsersList().uiMapper(data).kmsUsers;
      if(this.isAutoApproved) {
        this.contentForm.get('moderator').setValue(this.moderatorsList[0]);
      }
    });
  }

  fileOver(event: any) {
    this.hasDropZoneOver = event;
  }

  boxUpload() {
    this.isDropBox = false;
    this.isBox = true;
    this.fileUploader.showBoxPopup();
    this.googleAnalyticsService
                .eventEmitter('Box Upload', 'Box Upload', 'Submit Resource');
  }

  dropBoxUpload() {
    this.isBox = false;
    this.isDropBox = true;
    this.fileUploader.showDropBoxPopup();
    this.googleAnalyticsService.eventEmitter('Dropbox Upload', 'Dropbox Upload', 'Submit Resource');
  }

  setInitials(){
    this.isBox = false;
    this.isDropBox = false;
  }

  initFileUpload() {
    this.fileUploader.initBulkUploader(this.isDataset);
    this.uploader = this.fileUploader.bulkUploader;
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      const fileName = fileItem.file.name;
      const extention = this.extentionRegEx.exec(fileName)[1].toLowerCase();
      fileItem['fileAttributes'] = getSupportedFileAttributes(extention);
      const fileFormGroup = this.formBuilder.group({
        FileField: fileItem,
        kcTitleField: [fileName.substr(0, fileName.lastIndexOf('.')), [uploadItemReqValidator(null)]],
        fileTypeField: this.formBuilder.array([], Validators.required)
      });
      this.formFields.push(fileFormGroup);
      this.formFields.updateValueAndValidity();
      this.onContentUpdated();
      this.googleAnalyticsService
                  .eventEmitter((this.isBox) ? 'File Upload - Box' : ((this.isDropBox) ? 'File Upload - Drop Box' : 'File Upload'), fileName, 'Submit Resource');
      this.googleAnalyticsService
                  .eventEmitter((this.isBox) ? 'File Upload Type - Box' : ((this.isDropBox) ? 'File Upload Type - Drop Box' : 'File Upload Type'), extention, 'Submit Resource');
    };

    this.uploader.onAfterAddingAll = (fileItems: any) => {
      if (this.isBox === false && this.isDropBox === false) {
        this.googleAnalyticsService.eventEmitter('File Upload Count',
        String(this.uploader.queue.length - (this.fileUploader.isBoxUploadComplete + this.fileUploader.isDropBoxUploadComplete)), 'Submit Resource');
      }
    };

    this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any, options: any) => {
      switch (filter.name) {
        case 'queueLimit':
          this.message = 'You can upload up to ' + this.fileUploader.queueLimit + ' ' +  this.uploadContentType;
          break;
        case 'fileSize':
          this.message = 'You can upload up to ' + this.formatBytes(this.fileUploader.maxFileSize);
          break;
        case 'fileType':
          this.message = 'You can upload only CSV/excel ';
          break;
        default:
          this.message = 'Failed to Upload ';
          break;
      }
      this.googleAnalyticsService.eventEmitter((this.isBox) ? 'File Upload Failed - Box' : ((this.isDropBox) ? 'File Upload Failed - Drop Box' : 'File Upload Failed'), fileItem.file.name + ' / ' + this.message, 'Submit Resource');
      this.showQueueAlert = true;
    };
  }

  private formatBytes(bytes, decimals?) {
    if (bytes === 0) { return '0 Bytes'; }
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  removeFileItem(fileItem: FileItem) {
    let removeIndex = -1;
    const groupItems =  this.formFields.controls as FormGroup[];
    groupItems.forEach((groupItem, index) => {
      if (groupItem.controls.FileField.value === fileItem) {
        fileItem.remove();
        removeIndex = index;
      }
    });
    groupItems.splice(removeIndex, 1);
    this.googleAnalyticsService
                .eventEmitter('File Removed', fileItem.file.name, 'Submit Resource');
    this.formFields.updateValueAndValidity();
    this.onContentUpdated();
  }

  initTemplateUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.templateForm.get('FormFile').setValue(file);
      this.templateForm.get('FormFile').updateValueAndValidity();
    }
  }

  initReportUpload(event: { target: { files: string | any[]; }; }) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.reportForm.get('FormFile').setValue(file);
      this.reportForm.get('FormFile').updateValueAndValidity();
    }
  }

  uploadTemplate() {
    this.sending = true;
    this.fileUploader.uploadFile(this.templateForm.value)
      .subscribe(() => {
        this.sending = false;
      });
  }

  uploadReport() {
    this.sending = true;
    this.fileUploader.uploadReport(this.reportForm.value)
      .subscribe(() => {
        this.sending = false;
        this.reportForm.reset();
        this.successmsg='Report published successfully';
      },
      ()=>{
        this.sending = false;
        this.errormsg='Report is failed to published';
      }
      );
  }

  addLink() {
    if (this.linkText && this.linkText.length > 0) {
      const  fileAttributes = getSupportedFileAttributes('url');
      const linkGroup = this.formBuilder.group({
        externalUrlField:  new LinkItem(this.linkText, fileAttributes),
        kcTitleField: [this.linkText, [uploadItemReqValidator(null)]],
        fileTypeField: this.formBuilder.array([], Validators.required)
      });
      this.linkField.push(linkGroup);
      this.linkField.updateValueAndValidity();
      this.googleAnalyticsService
                  .eventEmitter('Link Added', this.linkText, 'Submit Resource');
      this.linkText = '';
      this.onContentUpdated();
    }
  }

  removeLink(externalUrlControl: LinkItem) {
    let removeIndex = -1;
    const groupItems =  this.linkField.controls as FormGroup[];
    groupItems.forEach((groupItem, index) => {
      if (groupItem.controls.externalUrlField.value === externalUrlControl) {
        removeIndex = index;
      }
    });
    groupItems.splice(removeIndex, 1);
    this.linkField.updateValueAndValidity();
    this.googleAnalyticsService
                .eventEmitter('Link Removed', externalUrlControl.linkValue, 'Submit Resource');
    this.onContentUpdated();
  }

  onContentUpdated() {
    this.contentAdded = this.formFields.controls.length > 0;
    this.linkAdded = this.linkField.controls.length > 0;
    this.pendingUploadCount = this.fileUploader.queueLimit -
                              (this.contentAdded ? this.formFields.controls.length : this.linkField.controls.length);
    this.contentUpdated.emit(this.contentAdded || this.linkAdded);
    this.selectionTabs.tabs[0].disabled = this.linkAdded;
    this.selectionTabs.tabs[1].disabled = this.contentAdded;
    this.disableLinkItems = this.linkField.controls.length >= this.fileUploader.queueLimit;
    if (this.disableLinkItems) {
      this.uploadContentType = 'links';
      this.showQueueAlert = true;
    }
  }

  downloadTemplate() {
    const type = this.contentForm.get('datasetType').value;
    let fileName = type + 'Template.xlsx';
    this.contentService.getReportTemplate(type).subscribe(data => {
      const file = new Blob([data], {type: 'application/octet-stream'});
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.click();      
    });
  }

  isDisplayDownload() {
    const type = this.contentForm.get('datasetType').value;
    if (type !== '') {
      return true;
    } else {
      return false;
    }
  }

  onSelect(data: TabDirective): void {
    this.tabSelected.emit(data.heading);
    if (data.heading === 'Upload Dataset') {
      this.isDataset = true;
      this.isTemplate = false;
      this.isReport=false;
      this.initFileUpload();
      this.contentForm.get('datasetType').setValidators([Validators.required]);
      this.contentForm.get('programArea').clearValidators();
      this.contentForm.get('topic').clearValidators();
      this.contentForm.get('contentType').clearValidators();
      this.contentForm.get('privacyType').clearValidators();
      this.contentForm.get('isDataset').setValue(true);
    } else if (data.heading === 'Upload Template' || data.heading === 'Upload Reports') {
      this.isTemplate = true;
      this.isDataset = false;
      this.isReport=false;
    }
    else {
      this.isDataset = false;
      this.isTemplate = false;
      this.isReport=false;
      this.initFileUpload();
      this.contentForm.get('datasetType').clearValidators();
      this.contentForm.get('programArea').setValidators([Validators.required]);
      this.contentForm.get('topic').setValidators([Validators.required]);
      this.contentForm.get('contentType').setValidators([Validators.required]);
      this.contentForm.get('privacyType').setValidators([Validators.required]);
      this.contentForm.get('isDataset').setValue(false);
    }
  }

  onClosedAlert() {
    this.showQueueAlert = false;
  }
}
