import { Component, OnInit, Renderer2, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PortalConfigService } from '../shared/portal-config.service';
import { FormGroup,  FormBuilder,  Validators, ReactiveFormsModule  } from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { PortalConfig, ResourcesType } from '../shared/portal-config.model';
import { fileExtensionValidator } from '../../shared/directives/file-extension-validator.directive';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { Roles } from 'src/app/core/roles/role-settings.model';

@Component({
  selector: 'app-title-manage-component',
  templateUrl: './title-manage.component.html',
  styleUrls: ['./title-manage.component.scss'],
  providers: []
})
export class TitleManageComponent implements OnInit {
  formSubmitSuccess: string;
  formSubmitError: string;
  configForm: FormGroup;
  portalConfig: PortalConfig;
  resorceList: Array<PortalConfig>;
  resourcesType = ResourcesType;
  userRole: Roles;
  isAddOpen = false;

  constructor( private portalService: PortalConfigService, private router: Router, private currentState: StateService, 
    private formBuilder: FormBuilder, public googleAnalyticsService: GoogleAnalyticsService) {}

  get isAdmin() { return this.userRole === Roles.Admin; }

  get isModerator() { return this.userRole === Roles.Moderator; }

  get isSuperAdmin() { return this.userRole === Roles.SuperAdmin; }

  ngOnInit() {
    this.userRole = this.currentState.getRole();
    this.getConfig();
    this.resetForm();
    this.getResourceList();
  }

  resetForm() {
    const user = this.currentState.getUserData();
    this.configForm = this.formBuilder.group({
      title: ['', Validators.required ],
      formFile: ['', Validators.required ],
      banner: ['', [Validators.required, fileExtensionValidator('jpg, jpeg, png') ]],
      community: [this.currentState.getGroupSession(), Validators.required],
      userId: [user.id, Validators.required],
      userServicePrinicipalName: [user.userPrincipalName, Validators.required],
      userFullName: [user.fullName, Validators.required],
      resourcesType: [this.resourcesType.Banner, Validators.required],
      isActive: [this.isAdmin, Validators.required],
      isApproved: [this.isAdmin, Validators.required]
   });
  }

  getConfig() {
    this.portalService.getConfig(this.resourcesType.Banner).subscribe(data => {
        this.portalConfig = new PortalConfig().uiMapper(data);
        this.configForm.patchValue({
          title: this.portalConfig.title
        });
    });
  }

  getResourceList() {
    this.portalService.getResourceList().subscribe(data => {
      data.forEach(resource => {
        resource.fileURL = decodeURIComponent(resource.resourcePath);
      });
        this.resorceList = data;
    });
  }

  initUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.configForm.get('formFile').setValue(file);
      this.configForm.get('formFile').updateValueAndValidity();
    };
  }

  saveConfig() {
    this.portalService.saveConfig(this.configForm.value).subscribe(data => {
      this.getResourceList();
      this.formSubmitSuccess = 'Saved Successfully.';
      this.resetForm();
      this.isAddOpen = false;
      this.googleAnalyticsService
                    .eventEmitter('Edit', this.portalConfig.title, 'Title / Banner Settings');
    });
  }

  approveConfig(portalConfig: PortalConfig) {
    this.portalService.approveConfig(portalConfig.setId).subscribe(data => {
      this.getResourceList();
      this.googleAnalyticsService
                    .eventEmitter('Approve', portalConfig.title, 'Title / Banner Settings');
    });
  }

  rejectConfig(portalConfig: PortalConfig) {
    this.portalService.rejectConfig(portalConfig.setId).subscribe(data => {
      this.getResourceList();
      this.googleAnalyticsService
                    .eventEmitter('Reject', portalConfig.title, 'Title / Banner Settings');
    });
  }

  cancelAdd() {
    this.formSubmitSuccess = '';
    this.resetForm();
    this.isAddOpen = false;
  }
}
