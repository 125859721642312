import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { FaqData } from "./faq-model";

@Injectable()
export class FAQService {

  constructor(private repository: RepositoryService) { }

  saveFAQContnet(faqData: FaqData){
    return this.repository.post(environment.apiBaseURL, 'api/faq', faqData);
  }

  getFaqData(): Observable<FaqData[]> {
    return this.repository.get(environment.apiBaseURL, 'api/faq');
  }
  
  removeFAQ(faqData: FaqData){
    return this.repository.delete(environment.apiBaseURL, 'api/faq', faqData);
  }

}