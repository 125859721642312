import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SavedFavoritesComponent } from './saved-favorites/saved-favorites.component';
import { SharedWithOthersComponent } from './shared-with-others/shared-with-others.component';
import { SharedWithMeComponent } from './shared-with-me/shared-with-me.component';
import { FormsModule } from '@angular/forms';
import { FavoritesService } from 'src/app/favorites/shared/favorites.service';
import { SharedModule } from '../shared/shared.module';
import { ReportExportComponent } from './report-export/report-export.component';
import { ReportExportService } from './shared/reportexport.service';

@NgModule({
  declarations: [SavedFavoritesComponent, SharedWithOthersComponent, SharedWithMeComponent, ReportExportComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  providers: [
    FavoritesService,
    ReportExportService
  ]
})
export class FavoritesModule { }
