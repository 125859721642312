<div class="container role-manage">
    <div class="heading d-flex">
      <div class="d-flex left-heading">
        <div class="col-md-12">
          <label class="title">Report Profiles Configuration</label>
        </div>
      </div>
    </div>
    <div  class="row alertContainer" role="alert">
        <div class="col-md-6" >
            <div class="alert alert-danger" *ngIf="formSubmitError" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
        <div class="col-md-6">
            <button class="btn btn-secondary button-style pull-right" (click)="addReportProfile()" >Add Profile</button>  
        </div>
    </div>
    <div class="adminRoleContainer col-md-12">
        <div class="row header m-0">
            <div class="col-md-2 align-left">
                <span><span class="header-icon"><em class="fas fa-ruler-vertical"></em></span>Profile Name</span>
            </div>
            <div class="col-md-3 align-left">                
                <span class="header-icon"><em class="fas fa-users"></em></span>Departments
            </div>
            <div class="col-md-3 align-left">
                <span><span class="header-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></span>Job Titles</span>
            </div>  
            <div class="col-md-3 align-left">
                <span><span class="header-icon"><em class="fa fa-chart-line"></em></span>Report</span>
            </div> 
            <div class="col-md-1 text-center" *ngIf="(isSuperAdmin|| isAdmin)">
               
            </div>        
        </div>
        <div class="row m-0" *ngIf="isEmpty">
            <span> No report profiles are created.</span>
        </div>
        <div class="row m-0">
            <div class="row" *ngFor="let profile of reportProfileList; let odd = odd" [ngClass]="odd ? 'odd_col' : 'even_col'" >
                <div class="col-md-2 align-left">
                   {{profile.profileName}}
                 </div>
                 <div class="col-md-3 align-left">
                     <ul>
                         <li *ngFor="let dept of profile.departments">
                             {{dept}}
                         </li>
                     </ul>            
                 </div>
                 <div class="col-md-3 align-left">
                    <ul>
                        <li *ngFor="let jobTitle of profile.jobTitles">
                            {{jobTitle}}
                        </li>
                    </ul>                  
                 </div>
                 <div class="col-sm-3 align-left">
                    <ul>
                        <li *ngFor="let report of profile.reports">
                            {{report.reportName}}
                        </li>
                    </ul>                      
                 </div>
                 <div class="col-md-1 pull-right" *ngIf="(isSuperAdmin|| isAdmin) && reportProfileList.length>0">
                     <a class="paddingright45" (click)="EditReportProfile(profile.id)"><i class="fa fa-edit"></i> </a>
                     <a (click)="DeleteReportProfile(profile.id)"><em class="far fa-trash-alt"></em> </a>
                 </div>        
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
        <div class="col-md-6 paddingleft27">
            <button type="button" id="btnsave" class="btn btn-secondary button-style" (click)="saveUpdateReports()" [ngClass]="{ 'disable-color': viewAsAdmin }">Save</button>
            <button type="button" id="btncancel" class="btn btn-secondary button-style back-button buttonpadding" [routerLink]="['/']">Cancel</button>
        </div>
    </div>
<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="choose-tags">
    <div class="form-group">
        <label for="profileName">Profile Name</label>
        <input formControlName="profileName"
            placeholder="Profile Name"
            class="form-control">
        <div *ngIf="profileForm.controls.profileName.errors?.required" class="text-danger small">
            Profile name is required.
        </div>
    </div>
        <div class="row">
            <div class="tag-content profileForm">
        <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('departments') }">
              <span class="required" *ngIf="profileForm.controls.departments.errors?.required">*</span> Select Departments <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu location-sub-tag" role="menu" aria-labelledby="button-basic">
                <li role="menuitem">
                    <input type="checkbox" (change)="toggleSelection('departments', departmentlist, $event.target.checked)" formArrayName="departments"> Select All
                  </li>
              <li *ngFor="let item of departmentlist" role="menuitem">
                <input type="checkbox" (change)="onTagValueChange(item, 'departments', $event.target.checked)" [checked]="tagSelected(item, 'departments')" formArrayName="departments"> {{ item }}
              </li>
            </ul>
          </div>
        </div>
    </div>
        
        <div class="row">
            <div class="tag-content profileForm">
            <div class="btn-group" dropdown [insideClick]="true">
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('jobTitles') }">
                  <span class="required" *ngIf="profileForm.controls.jobTitles.errors?.required">*</span> Select jobTitles <i class='dropDownIcon fas fa-angle-down'></i>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" >
                    <li role="menuitem">
                        <input type="checkbox" (change)="toggleSelection('jobTitles', jobTitleList, $event.target.checked)" formArrayName="jobTitles"> Select All
                      </li>
                  <li *ngFor="let item of jobTitleList" role="menuitem">
                    <input type="checkbox" (change)="onTagValueChange(item, 'jobTitles', $event.target.checked)" [checked]="tagSelected(item, 'jobTitles')" formArrayName="jobTitles"> {{ item }}
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="tag-content profileForm">
            <div class="btn-group" dropdown [insideClick]="true">
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('reports') }">
                    <span class="required" *ngIf="profileForm.controls.reports.errors?.required">*</span> Select Reports <i class='dropDownIcon fas fa-angle-down'></i>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" >
                    <li role="menuitem">
                        <input type="checkbox" (change)="toggleSelection('reports', reportList, $event.target.checked)" formArrayName="reports"> Select All
                      </li>
                    <li *ngFor="let report of reportList" role="menuitem">
                    <input type="checkbox" (change)="onTagValueChange(report.reportId, 'reports', $event.target.checked)" [checked]="tagSelected(report.reportId, 'reports')" formArrayName="reports"> {{ report.reportName }}
                    </li>
                </ul>
                </div>
            </div>
        </div>
    <div class="col-md-4 px-5">
        <button type="submit" class="btn btn-secondary button-style" [disabled]="!profileForm.valid && !(isAdmin || isSuperAdmin)">Submit</button>
    </div>
</form> 
  </div>
<div class="backdrop" *ngIf="isAddOpen"></div>
