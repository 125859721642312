import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { SiteMeasureModel, SiteYears } from "../models/siteMeasure.model";

@Injectable()
export class SiteMeasureServices {

  constructor(private repository: RepositoryService) { }

  getSiteMeasures(siteId:number) : Observable<SiteMeasureModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/sitemeasure/'+siteId).pipe(map(s=>{
      s.lastUpdatedDateTime = new Date(s.lastUpdatedDateTime)
      return s;
    }));
  }

  removeSiteMeasure(measures: SiteMeasureModel[]): Observable<any>{
    return this.repository.delete(environment.apiBaseURL, 'api/sitemeasure', measures);
  }

  saveSiteMeasures(measures: SiteMeasureModel[]): Observable<any>{
    return this.repository.post(environment.apiBaseURL, 'api/sitemeasure', measures);
  }

  updateSiteMeasuresStatus(siteId:number,groupId:number,isActive:boolean) : Observable<SiteYears[]> {
    return this.repository.get(environment.apiBaseURL, 'api/sitemeasure/updateSiteMeasures/'+siteId+'/'+groupId+'/'+isActive);
  }

  exportMeasures(siteId:number): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/sitemeasure/export/'+siteId,'blob');
  }
    getImportJobStatusValidate(Id:Guid): Observable<any>{
      return this.repository.get(environment.apiBaseURL,'api/sitemeasure/importjobsvalidate/'+Id);
    }
    getImportJobStatus(): Observable<any>{
      return this.repository.get(environment.apiBaseURL,'api/sitemeasure/importjobs');
    }

    approveImportJobs(jobId:Guid, isApproved: boolean) :Observable<any>{
      return this.repository.get(environment.apiBaseURL,'api/sitemeasure/approveimportedsitedata/'+jobId+'/'+isApproved);
    }

    getImportJobSiteData(jobId:Guid):Observable<any>{
      return this.repository.get(environment.apiBaseURL,'api/sitemeasure/importjobs/'+jobId);
    }

    importMeasureData(jobId:Guid):Observable<any>{
      return this.repository.get(environment.apiBaseURL,'api/sitemeasure/importmeasurejobs/'+jobId);
    }

    rejectImportMeasureData(jobId:Guid):Observable<any>{
      return this.repository.get(environment.apiBaseURL,'api/sitemeasure/rejectmeasurejobs/'+jobId);
    }
}