<div class="allImgcontainer" [ngStyle]="portalbannerConfig?{'background': 'url(' + bgImage + ') no-repeat center center fixed', 'background-size': 'cover'  }:''">
    <div class="overcontainer">
        <div class="row" *ngIf="portalbannerConfig" [ngClass]="{'titleClass':portalbannerConfig}">
            <div class="col-md-3"></div>
            <div class="col-md-9">
                <div>
                    <div>
                      <h1>{{title}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="headingjive">
                    <div class="headingContent">
                            <p>Join the conversation and explore competencies
                            <br> on the Advocacy Learning Lab</p>                    
                    </div>
                    <div class="cancelbutton">
                            <a class="btn button-style" href="https://community.aecf.org/community/advocacy-learning-lab" target="_blank" rel="noopener noreferrer">Visit ALL</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="heading">
                    <div class="headingContent"></div>
                    <div class="searchbar">
                        <app-search-component></app-search-component>
                    </div>
                </div>
                <div>
                    <app-search-widget></app-search-widget>
                </div>
            </div>
        </div>       
    </div>
</div>
