import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AccountService } from '../shared/account.service';
import { RoleDetails, UserDetail, UsersList } from '../shared/user.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import * as FileSaver from 'file-saver';
import { SiteServices } from 'src/app/jj/services/site.services';
import { SiteModel } from 'src/app/jj/models/site.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent  implements OnInit, OnDestroy  {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  @ViewChild('saveModal', {static: false}) saveModalCompoent: ModalComponent;
  @ViewChild('userbulkuploadconfirmationModal', {static: false}) userbulkuploadconfirmationModal: ModalComponent;
  userList: Array<UserDetail>;
  manageduserList: Array<UserDetail>;
  userRoles: any;
  currentUserRole: Roles;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageUsers: Array<UserDetail>;
  originalManageUsers: Array<any>;
  currentManageUsers: Array<any>;
  differentManageUsers: Array<any>;
  pendingUsers: Array<UserDetail>;
  selectedUser: UserDetail = null;
  currentUser: any;
  addRowInvalid = false;
  selectedModerator: any;
  selectedUserName: string;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAllGroup = false;
  isAddOpen = false;
  isKMSGroup =  false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  userForm: FormGroup;
  searchUserResult: Observable<any>;
  @Input('userPrincipalName')
  typedUser: string;
  isUserExists=false;
  searchTerm: string;
  siteList: SiteModel[];
  isJJDataPortal:boolean;
  isFTLAPortal:boolean;
  isSiteNotSelected:boolean=false;
  selectedSite:SiteModel;
  lastSort:string;
  fullnameAsc:boolean =true;
  emailAsc:boolean=true;
  titleAsc:boolean=true;
  organizationAsc:boolean=true;
  roleAsc:boolean=true;
  buttonText:string = "Add User"; 
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  saveMessage:string ='';
  manageUserList =new Array<UserDetail>;
  constructor(private accountService: AccountService,
              private stateService: StateService,
              public googleAnalyticsService: GoogleAnalyticsService,
              private siteService: SiteServices) {
              this.userRoles = Roles;
  }

  get isAdmin() { return this.currentUserRole === Roles.Admin; }

  ngOnInit() {
    this.manageduserList = new Array<UserDetail>();
    this.isJJDataPortal = this.stateService.isJJGroup();
    this.isFTLAPortal = this.stateService.isFTLAGroup();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    this.getUsers();
    this.getSites();
    if(!this.isFTLAPortal) {
    this.userForm = new FormGroup({
      firstName:new FormControl('', Validators.pattern('^(?=.*?[A-Za-z])[A-Za-z0-9]+$')),
      lastName:new FormControl('', Validators.pattern('^(?=.*?[A-Za-z])[A-Za-z0-9]+$')),
      title:new FormControl(''),
      organization:new FormControl(''),
      userPrincipalName:new FormControl('', [Validators.required, Validators.email]),
      role:new FormControl('', Validators.required),
      site:new FormControl('')
    });
  } else {
    this.userForm = new FormGroup({
      firstName:new FormControl('', Validators.pattern('[a-zA-Z ]*')),
      lastName:new FormControl('', Validators.pattern('[a-zA-Z ]*')),
      title:new FormControl(''),
      organization:new FormControl(''),
      userPrincipalName:new FormControl('', [Validators.required, Validators.email]),
      site:new FormControl('')
    });
  }
  }


  getSites() {
    this.siteService.getSites()
    .subscribe(data => {
      this.siteList =data;
        this.siteList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
        data.forEach(element => {
          this.dropdownList.push({item_id: element.id, item_text:element.name });
      });
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 2,
      allowSearchFilter: false,
      enableCheckAll:true,
      defaultOpen:true
    };
  }

  importUsers():void{

  }

  onOptionsSelected(value:number){
    this.selectedSite = this.siteList.filter(x => x.id === this.userForm.get('site').value)[0]; 
    this.isSiteNotSelected=false;
 }

  onUserSelect(event: TypeaheadMatch): void {
    this.typedUser = event.item.userPrincipalName;
    this.userForm.get('userPrincipalName').setValue(event.item.userPrincipalName.toLowerCase());
    this.userForm.get('firstName').setValue(event.item.givenName);
    this.userForm.get('lastName').setValue(event.item.surName);
    this.userForm.get('title').setValue(event.item.surName);
    this.userForm.get('organization').setValue(event.item.surName);
    event.item.sites.forEach(site=>{
      this.selectedItems.push({item_id: site.id, item_text:site.name});
    });
  }

  isValidUser() {
    const userControl = this.userForm.get('userPrincipalName');
    if (userControl.value && !userControl.errors) {
      return true;
    }
  }

  getUsers() {
      this.accountService.getCommunitySpecificUsers()
    .subscribe(data => {
        this.userList = new UsersList().uiMapper(data).kmsUsers;
        this.userList.sort((nodeA, nodeB) => compareItems(nodeA['fullName']?.toLowerCase(), nodeB['fullName']?.toLowerCase()));
        let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
        this.manageUsers = this.userList.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
        this.totalRows = this.userList.length;
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    if (this.searchTerm ==undefined || this.searchTerm?.length === 0)
    {
      this.manageUsers = this.userList?.slice(skipRows, this.currentPage*this.perPage);
    }
    else
    {
      this.manageUsers = this.manageUserList?.slice(skipRows, this.currentPage*this.perPage);
    }
  }

  resetAddForm() {
    this.selectedUser = null;
    this.isAddOpen = false;
    this.isUserExists=false;
    this.userForm.reset();
  }

   confirmUserToRemove(user: UserDetail) {
    if (user) {
      user.isDeleted=true;
      user.role=null;
       this.manageduserList.push(user);
       let itemIndex= this.userList.findIndex(x=>x.userPrincipalName==user.userPrincipalName)
       this.userList.splice(itemIndex,1);
      let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
      this.manageUsers = this.userList.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
      this.saveMessage = 'User deleted successfully';
      this.saveUsers();
      this.accountService.RemoveUserFromFTLA(user.id).subscribe(x=> {
        this.getUsers();
      });
      this.closeConfirmModal();
    }
  }

  openConfirmationModal(user: UserDetail) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      this.defaultConfirmationModalEntity(user);
    }
  }

  defaultConfirmationModalEntity(user: UserDetail) {
    this.confirmationModalCompoent.entityData = { entityData: {
        deleteUser: user,
        tasksExists: false
      }
    };
  }

  updateConfirmationModalEntity(userToDelete: UserDetail, moderators: UserDetail[], rowIndex: number) {
    const users = moderators.filter(x => x.id !== userToDelete.id);
    this.confirmationModalCompoent.entityData = {
      entityData: {
        deleteUser: userToDelete,
        manageUsers: users,
        manager: users.length === 1 ? users[0] : null,
        tasksExists: true,
        managersExists: users.length > 0,
        rowIndex: rowIndex
      }
    };
    this.selectedModerator = users.length > 0 ? users[0] : null;
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.selectedModerator = null;
      this.confirmationModalCompoent.closeModalWindow();
    }
  }

  closeSaveModel(){
    if(this.saveModalCompoent){
      this.saveModalCompoent.closeModalWindow();
    }
  }

  addUser() {
    this.isSiteNotSelected=false;
    this.isAddOpen = true;
  }

  AddUsers():void {
    var role = "";
    if (this.isFTLAPortal){
      role = "Admin"
   } else {
      role = this.userForm.get('role').value;
       
    if(this.userForm.get('role').value ==="Admin"){
      this.isSiteNotSelected=false;
    }
   else if(this.isJJDataPortal && (this.selectedItems==null || this.selectedItems.length===0)){
        this.isSiteNotSelected=true;
        return;
      }
    }
    let email = this.userForm.get('userPrincipalName').value;
    let firstName = this.userForm.get('firstName').value;
    let lastName = this.userForm.get('lastName').value;
    let title = this.userForm.get('title').value;
    let organization = this.userForm.get('organization').value;
    let sites = role=='Admin' ? null: this.siteList.filter(x=> this.selectedItems.some(s=>s.item_id === x.id));
    let roleDetails = new RoleDetails();
    roleDetails.name = role;
    let user = new UserDetail().addUser(email, role,firstName.trim(),lastName.trim(),title,organization,true,sites,roleDetails);
    user.isNew =this.selectedUser==null? true:false;
    if(!user.isNew)
      user.id = this.selectedUser.id;
    this.manageduserList.push(user);
    this.saveMessage = 'User saved successfully';
    this.saveUsers();
    if (this.isFTLAPortal){
      const formData = new FormData();
      formData.set('userPrincipalName',email);
      formData.set('contact','');
      formData.set('givenName',firstName);
      formData.set('surName',lastName);
      formData.set('title',title);
      formData.set('name',organization);
      formData.set('website','');
      formData.set('id','0');
      formData.set('profilePhoto',null);
      this.accountService.AddOrUpdateFTLAProfile(formData).subscribe(x=>{
        this.getUsers();
   });
    }

    this.resetAddForm();
  }

  checkUserExists():void{
    if(this.selectedUser!=null)
    return;
    let email = this.userForm.get('userPrincipalName').value;
    let itemIndex = this.userList.findIndex(x=>x.userPrincipalName.toLocaleLowerCase()==email.toLocaleLowerCase());
    if(itemIndex>0){
      this.isUserExists=true;
    }else{
      this.isUserExists=false;
    }
  }

  saveUsers() :void {
    this.accountService.ManageUsers(this.manageduserList)
      .subscribe((data) => {
        this.formSubmitSuccess = "Successfully saved users";
        this.stateService.setNewUserSession();
        this.getUsers();
        this.saveModalCompoent.showModal();
    });
    this.manageduserList=[];
  }

  removeUser(user: UserDetail):void{
    this.openConfirmationModal(user);
  }

  onItemSelect(item: any) {
    this.isSiteNotSelected = false;
    }
  
    onItemDeSelect(item: any){
      if(this.selectedItems==null || this.selectedItems.length===0)
          this.isSiteNotSelected = false;
    }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }

  search(): void {
    var searchNameList, searchJobList, searchOrganizationList, searchRoleList, searchEmailList;
    if (this.searchTerm.length === 0) {
      this.currentPage = 0;
      this.manageUsers = this.userList?.sort((nodeA, nodeB) => compareItems(nodeA["fullName"]?.toLowerCase(), nodeB["fullName"]?.toLowerCase()))
      .filter(x => x.fullName?.toLowerCase()).slice(this.currentPage, this.perPage);
      this.totalRows = this.userList.length;
    }
    else {
      this.manageUserList =new Array<UserDetail>;
      searchNameList = this.userList.filter(x => x.fullName?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      searchJobList = this.userList.filter(x =>  x.jobTitle?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      searchOrganizationList = this.userList.filter(x =>  x.organization?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      searchRoleList = this.userList.filter(x =>  x.roleName?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      searchEmailList = this.userList.filter(x =>  x.userPrincipalName?.toLowerCase().includes(this.searchTerm.toLowerCase()));

      if(searchNameList!=null || searchNameList!=undefined)
      {
        this.manageUserList = this.searchConcat(this.manageUserList, searchNameList);
      }
      if(searchEmailList!=null || searchEmailList!=undefined)
      {
        this.manageUserList = this.searchConcat(this.manageUserList, searchEmailList);
      }
      if(searchJobList!=null || searchJobList!=undefined)
      {
        this.manageUserList = this.searchConcat(this.manageUserList, searchJobList);
      }

      if(searchOrganizationList!=null || searchOrganizationList!=undefined)
      {
        this.manageUserList = this.searchConcat(this.manageUserList, searchOrganizationList);
      }

      if(searchRoleList!=null || searchRoleList!=undefined)
      {
        this.manageUserList = this.searchConcat(this.manageUserList, searchRoleList);
      }
      const unique = [...new Set(this.manageUserList.map((item) => item?.userPrincipalName))];

      var result = this.manageUserList.reduce((unique, o) => {
        if(!unique.some(obj => obj.userPrincipalName === o.userPrincipalName && obj.fullName === o.fullName)) {
          unique.push(o);
        }
        return unique;
    },[]);
      console.log(result);
      this.manageUserList = result;
      let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
      this.manageUsers = this.manageUserList.slice(0, 10);
      this.totalRows = this.manageUserList.length;
    }
  }

  searchConcat(userTotalList: UserDetail[], userList: UserDetail[]) : UserDetail[]
  {
    var result = userTotalList;
    if(userList?.length>0)
    {
      userList.forEach(a => {
        result = result.concat(a);
      });
    }
    return result;
  }

  exportUsers():void{
    this.accountService.getUsersExport().subscribe(data => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    FileSaver.saveAs(data, 'users_list.xlsx');
  });
}

GetOktaStatus():void{
  this.accountService.getOktaStatus().subscribe(data => {
    this.ngOnInit();
});
}

toggleTag(column: string) {

  let value = "asc";
  if (column == 'fullName') {
    this.fullnameAsc = !this.fullnameAsc;
    value = this.fullnameAsc ? "asc" : "desc"
  }
  else if(column == 'userPrincipalName')
  {
    this.emailAsc = !this.emailAsc;
    value = this.emailAsc ? "asc" : "desc"
  }
  else if(column == 'jobTitle')
  {
    this.titleAsc = !this.titleAsc;
    value = this.titleAsc ? "asc" : "desc"
  }
  else if(column == 'organization')
  {
    this.organizationAsc = !this.organizationAsc;
    value = this.organizationAsc ? "asc" : "desc"
  }
  else if(column == 'roleName')
  {
    this.roleAsc = !this.roleAsc;
    value = this.roleAsc ? "asc" : "desc"
  }
  this.sortOn(column, value);
}

sortOn(column: string, type: string): void {
  if (column !== null) {
    var totalSortUser = new Array<UserDetail>();
    if (this.searchTerm ==undefined || this.searchTerm?.length === 0)
    {
      totalSortUser = this.userList;
    }
    else
    {
      totalSortUser = this.manageUserList;
    }
    let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
    if (type == "desc") {
      totalSortUser = totalSortUser?.sort((nodeA, nodeB) => compareItems(nodeB[column]?.toLowerCase(), nodeA[column]?.toLowerCase()));
      this.lastSort = column;
      totalSortUser = this.SortNull(totalSortUser,column);
    } 
    else 
    {
      totalSortUser = totalSortUser?.sort((nodeA, nodeB) => compareItems(nodeA[column]?.toLowerCase(), nodeB[column]?.toLowerCase()));
      totalSortUser = this.SortNull(totalSortUser,column);
    }
    if (this.searchTerm ==undefined || this.searchTerm?.length === 0)
    {
     this.userList  = totalSortUser;
    }
    else
    {
      this.manageUserList = totalSortUser;
    }
    this.manageUsers = totalSortUser?.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);//this.sortUsersColumn(totalSortUser,column,skipRows)?.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
    
    this.totalRows = totalSortUser.length;
  }
}

SortNull(userTotalList: UserDetail[], column:string): UserDetail[]
{
  var result = new Array<UserDetail>();
  var notNullData = new Array<UserDetail>();
  var nullData = new Array<UserDetail>();

  switch(column.toLowerCase())
  {
    case "organization":
      notNullData = userTotalList.filter(a=>a.organization!=null && a.organization!="");
      nullData = userTotalList.filter(a=>a.organization==null || a.organization=="");
      result = notNullData.concat(nullData);
      break;
    case "jobtitle":
      notNullData = userTotalList.filter(a=>a.jobTitle!=null && a.jobTitle!="");
      nullData = userTotalList.filter(a=>a.jobTitle==null || a.jobTitle=="");
      result = notNullData.concat(nullData);
      break;
   default:
    result = userTotalList;
      break;    
  }
  return result;
}

  editUser(user:UserDetail):void {
    this.isSiteNotSelected=false;
    this.buttonText="Save User";
    this.selectedUser = user;
    console.log(this.selectedUser);
    this.isAddOpen=true;
    this.userForm.get('userPrincipalName').setValue(user.userPrincipalName.toLowerCase().trim());
    this.userForm.get('firstName').setValue(user.givenName.trimEnd());
    this.userForm.get('lastName').setValue(user.surName.trimEnd());
    this.userForm.get('title').setValue(user.jobTitle);
    this.userForm.get('organization').setValue(user.organization);
    this.userForm.get('role').setValue(user.roleDetails.name);
    this.selectedItems =[];
    if(user.sites!=null){
      user.sites.forEach(site=>{
        this.selectedItems.push({item_id: site.id, item_text:site.name});
      });
    }
  }

  openBulkUploadConfirmationModal() {
    if (this.userbulkuploadconfirmationModal) {
        this.userbulkuploadconfirmationModal.entityData = {
          headerText: 'Users Bulk Upload',
          entityData: null
        };
        this.userbulkuploadconfirmationModal.showModal();
    }
  }

  closeBulkUploadConfirmModal(event) {
    if (this.userbulkuploadconfirmationModal) {
      this.userbulkuploadconfirmationModal.closeModalWindow();
    }
  }

  sendOktaReactivationEmail(user:string){
    this.accountService.sendReactivationEmail(user).subscribe(x=>{

    });
  }

  sortUsersColumn(totalSortUser: UserDetail[], column:string, skipRows: number) : UserDetail[]
  {
    var result = new Array<UserDetail>();
    switch (column) {
      case 'fullName':
        result = totalSortUser?.sort((a,b)=>(a.fullName?.toLowerCase() > b.fullName?.toLowerCase() ? 1 : -1));
        break;
      case 'userPrincipalName':
        result = totalSortUser?.sort((a,b)=>(a.userPrincipalName?.toLowerCase() > b.userPrincipalName?.toLowerCase() ? 1 : -1));
        break;
      case 'jobTitle':
        var notNUllUser = totalSortUser?.filter(x => x.jobTitle?.toLowerCase()).sort((a,b)=>(a.jobTitle?.toLowerCase() > b.jobTitle?.toLowerCase() ? 1 : -1));
        var nullUser = totalSortUser?.filter(x => x.jobTitle==null);
        result = this.searchConcat(notNUllUser,nullUser);
        break;
      case 'organization':
        var notNUllUser = totalSortUser?.filter(x => x.organization?.toLowerCase()).sort((a,b)=>(a.organization?.toLowerCase() > b.organization?.toLowerCase() ? 1 : -1));
        var nullUser = totalSortUser?.filter(x => x.organization==null);
        result = this.searchConcat(notNUllUser,nullUser);
        break;
      case 'roleName':
        var notNUllUser = totalSortUser?.filter(x => x.roleName?.toLowerCase()).sort((a,b)=>(a.roleName?.toLowerCase() > b.roleName?.toLowerCase() ? 1 : -1));
        var nullUser = totalSortUser?.filter(x => x.roleName==null);
        result = this.searchConcat(notNUllUser,nullUser);
        break;
      default:
        result = totalSortUser?.filter(x => x.fullName?.toLowerCase());
        break;
    }
    return result;
  }

}