import { Component, OnInit } from '@angular/core';
import { PortalConfig, ResourcesType } from '../portal-config/shared/portal-config.model';
import { SystemPermissions } from '../permissions';
import { PortalConfigService } from '../portal-config/shared/portal-config.service';
import { StateService } from '../core/state-manager/appstateservice';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jj-data-pre-login',
  templateUrl: './jj-data-pre-login.component.html',
  styleUrls: ['./jj-data-pre-login.component.scss']
})
export class JjDataPreLoginComponent implements OnInit {

  bannerConfig: PortalConfig;
  resourcesType = ResourcesType;
  title = '';
  bgImage = '/assets/images/JJdataHomePage.png';
  permissions = SystemPermissions;
  portalbannerConfig:boolean=false;
  displayOption='FitToPage';
  hideVisuals=new Array<string>('JDAI Since ','Probation Transformation since ',' County, ');
  constructor(private portalService: PortalConfigService,
               private currentState: StateService,
               private titleService: Title,
               private router: Router ) { }
  ngOnInit() {
    this.titleService.setTitle(environment.jjdataPageTitle);
    this.router.navigate(['/jjhome']);
  }
}
