export class Query {
    searchTitle: string;
    isActive: boolean;
    queryid: string;
    isFavorites: boolean;

    public static modelMapper(uiModel: Query) {
        return {
            title: uiModel.searchTitle,
            isActive: uiModel.isActive
        };
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            searchTitle: modelData['title'],
            queryid: modelData['queryId'],
            isFavorites: modelData['isFavorites']
        });
        return this;
    }
}

export class QueryList {
    savedQueries: Array<Query> = [];

    public dataMapper(modelData: Query[]) {
        return Object.assign(modelData ? modelData.map(data => {
                                    return  Object.assign(Query.modelMapper(data));
                                }) : []);
    }

    public uiMapper(modelData: any) {
        Object.assign(this, {
            savedQueries: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new Query().uiMapper(data));
                        }) : []
        });

        return this;
    }
}
