<div class="search-results-container">
  <div class="row refine-row">
      <div class="filter-tag-header refine-tag col-md-2" (click)="toggleRefineTag()"><em class='refine_icon fas fa-sliders-h'></em>Refine</div>
      <div class="searchComponentContainer col-md-10" >
        <div class="searchBoxForm">
            <input type="text" id="searchTerm" name="searchTerm" class="searchBox" [(ngModel)]="searchTerm" (keyup)="search()" placeholder="Begin Search..." autocomplete="off" />
        </div>            
        <div class="search_button">
            <button id="searchButton" value="Search" class="searchButton" (click)="searchInBox()">Refresh Box Data</button>
        </div>
      </div>
  </div>
  <div class="row dv-search-results-container" *ngIf="showResults">
      <div class="filter-tags" [ngClass]="{'hide': !showFilters, 'col-md-3': isAllGroup, 'col-md-2': !isAllGroup}">
      <accordion class="search_facets_accordion">
          <accordion-group [panelClass]="accordionPanelClass">
          <div class="filter-tag-header" accordion-heading>
              <span>Upload Date</span><i class='arrow_icon fas fa-angle-down'></i>
          </div>
          <div class="refine-tags-option" id="publicationDate">
              <label class="clear-text"><a (click)="onDateClear()">CLEAR <i class='close_icon fas fa-times'></i></a></label>
              <div class="container-fluid searchDatePicker">
              <div class="datecol">
                  <input class="form-control datapicker" #drp="bsDatepicker" placement="right" [ngClass]="{'invalid': hasInvalidDate(bsStartValue) || hasDateRangeError() }" [(bsValue)]="bsStartValue" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateValueChange($event)">
              </div>
              <div class="datecolinter">
                  <span>-</span>
              </div>
              <div class="datecol">
                  <input class="form-control datapicker" #drp="bsDatepicker" placement="right" [ngClass]="{'invalid': hasInvalidDate(bsEndValue) || hasDateRangeError() }" [(bsValue)]="bsEndValue" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateValueChange($event)">
              </div>
              <span *ngIf="hasDateRangeError()" class="invalid-error">Please enter valid date range</span>
              </div>
          </div>
          </accordion-group>
      </accordion>
      </div>
      <div class="dvSearchResults" [ngClass]="{'col-md-10': showFilters && !isAllGroup, 'col-md-9': showFilters && isAllGroup, 'col-md-12': !showFilters}">
      <ng-container *ngIf="totalItems; else emptyContainer">
          <div>
          <div class="row result_count_container">
              <div class="col-md-9 result_count">
              <p>
                  {{itemPerPage}} Results shown for ‘{{ searchQuery }}’ / <b>{{ totalItems }} Results Total</b>
              </p>
              </div>
          </div>
          <ng-container *ngFor="let result of pagedData;">
              <div class="row row_padding">
              </div>
              <div class="search-results-row">
              <div> 
                  <label>{{ result.fileTitle | titlecase }} </label>
              </div>
              <p class="author_details"> Uploaded {{ result.uploadedDate | date: 'MMM d, y hh:mm a' }} by {{ result.uploadedBy }} </p>
              <div class="col-md-12 result-description" *ngIf="result.description && result.description != 'Not Specified'">
                  {{result.description}}
              </div>
              <div class="row">
                  <div class="col-md-10 search-row-buttons">
                      <ng-container>
                      <a *ngIf="result.toggle == 0" href="javascript:void(0)" (click)="showBoxFile(result)"  class="form-control preview_button">
                          <span>Go to file</span><em class="button-icon fas far fa-eye"></em>
                      </a>
                   
                      </ng-container>
                  </div>
                  <div class="col-md-2 search-row-file-type">
                      <span class="file_type_label {{ result.fileAttributes.CssIcon }}"><em class="{{ result.fileAttributes.CssClass }}"></em></span>
                  </div>
              </div>  
              </div>
          </ng-container>
          <div>
              <div class="text-right paginationContainer">
              <pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [maxSize]="10" class="pagination"
                  [boundaryLinks]="true" [rotate]="false"  (click)="pageChanged($event)"></pagination>
              </div>
          </div>
          </div>
      </ng-container>
      <ng-template #emptyContainer>
          <p class="noResults">No results for <b>"{{ searchQuery }}"</b></p>
      </ng-template>        
      </div>
  </div>  
</div>

