<div class="container newsfeed-manage">
  <div>
      <div class="page-title">
        <div>
            <span calss="page-title"><h1>Manage Newsfeed</h1></span>
        </div>
      </div>
      <div class='row'>
        <div class="col-md-9">
          <span>Add, Edit or Delete Updates</span>
        </div>
        <div class="col-md-3">
          <button type="button" id="btnAddProfile" class="btn btn-success" (click)="isAddOpen = true" 
              *ngIf="userDetails !== undefined && userDetails.permissions.indexOf(permissions.CreateNewsfeed) > 0" >Add</button>
        </div>
      </div>
  </div>
  <div *ngIf="formSubmitSuccess" class="row alertContainer" role="alert">
      <div class="col-md-12">
          <div class="alert alert-success" id="formSubmitSuccess">
          {{ formSubmitSuccess }}
          </div>
      </div>
  </div>
  <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
      <div class="col-md-12">
          <div class="alert alert-danger" id="formSubmitError">
          {{ formSubmitError }}
          </div>
      </div>
  </div>
  <div class="clearfix">
    <div class="col-md-12 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th><i class="fa fa-cog"></i> Action</th>
            <th><i class="fa fa-file"></i> Title</th>
            <th class="description"><i class="fa fa-file"></i> Description</th>
            <th><i class="fa fa-user"></i> Created By</th>
            <th><i class="fa fa-users"></i> Community</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let newsFeed of newsFeeds; let rowIndex=index;">
            <td class="action">
              <div dropdown container="body">
                <a dropdownToggle class="dropdown-toggle" aria-controls="dropdown-container">
                  <i class="fa fa-list"></i></a>
                <ul id="dropdown-container" *dropdownMenu class="dropdown-menu p-0"
                    role="menu" aria-labelledby="button-container">
                  <li role="menuitem"><a class="dropdown-item" (click)="editNewsFeed(rowIndex)">Edit</a></li>
                  <li role="menuitem"><a class="dropdown-item" (click)="openConfirmationModal(newsFeed.id, rowIndex)">Delete</a></li>
                </ul>
              </div>
            </td>
            <td>{{newsFeed.title}}</td>
            <td>
              <span *ngIf="viewNewsFeed != rowIndex">{{newsFeed.body | truncate : 80}} <a class="more-link" (click)="viewNewsFeed=rowIndex" *ngIf="(newsFeed.body + ' ').length>90">more</a> </span> 
              <span *ngIf="viewNewsFeed == rowIndex">{{newsFeed.body}} <a class="more-link" (click)="viewNewsFeed=-1">less</a></span> 
            </td>
            <td>{{newsFeed.createdBy}}</td>
            <td>{{newsFeed.community}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="newsfeed-form" *ngIf="isAddOpen">
  <a class="close" (click)="cancelAddUpdate()"><i class="fa fa-times"></i></a>
  <form [formGroup]="newsFeedForm" novalidate>
    <div class="form-group">
      <label for="txt-title">Title</label>
      <input formControlName="title" class="form-control" name="title" id="txt-title" type="text">
      <div *ngIf="newsFeedForm.hasError('required', 'title')" class="text-danger small">
        Title is required.
      </div>
    </div>
    <div class="form-group">
      <label for="txt-title">Body</label>
      <textarea formControlName="body" class="form-control" name="body" id="txt-body" rows="5"></textarea>
      <div *ngIf="newsFeedForm.hasError('required', 'body')" class="text-danger small">
        Description is required.
      </div>
    </div>
    <div class="col-md-4 px-5">
      <button type="button" class="btn btn-block btn-default" [disabled]="selectedId==0" (click)="openConfirmationModal(selectedId, selectedRowIndex)">Delete</button>
    </div>
    <div class="col-md-4 px-5">
      <button type="button" (click) = "cancelAddUpdate()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
      <button type="button" [disabled]="newsFeedForm.pristine || newsFeedForm.invalid" class="btn btn-block btn-warning" (click)="saveNewsFeed()">Save</button>
    </div>
  </form>
</div>
<div class="backdrop" *ngIf="isAddOpen"></div>
<app-modal #confirmationModal contentStyle="delete-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
      <div class="col-sm-12 content-style">
        <p>Are you sure you want to delete?</p>
      </div>
      <div class="col-sm-12 footer-style">
        <div>
            <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                <span>Cancel</span>
            </button>
        </div>
        <div class="savebtn">
            <button type="submit" class="btn button-style" (click)="confirmDelete(selectedId, selectedRowIndex)">
                <span>Ok</span>
            </button>
        </div>
    </div>
  </ng-template>
</app-modal>