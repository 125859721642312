import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Query, QueryList } from '../shared/query.model';
import { FavoritesService } from '../shared/favorites.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';

@Component({
  selector: 'app-saved-favorites',
  templateUrl: './saved-favorites.component.html',
  styleUrls: ['./saved-favorites.component.scss']
})
export class SavedFavoritesComponent implements OnInit {
  searchQuery: Array<Query>;
  viewAsAdmin = this.commonService.getViewUserEventEmitter();

  constructor(private favoritesService: FavoritesService,  private router: Router, private commonService: CommonEntityService, 
              public googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    this.getSavedFavourites();
  }

  getSavedFavourites() {
    this.favoritesService.getSavedFavorites()
    .subscribe(data => {
        const savedFavorites = data.filter(x => x.isFavorites);
        this.searchQuery = new QueryList().uiMapper(savedFavorites).savedQueries;
    });
  }

  removeFavoritesQuery(query: Query, rowIndex: number) {
    this.favoritesService.deleteSavedFavorites(query.queryid)
    .subscribe(() => {
        this.searchQuery.splice(rowIndex, 1);
    });
    this.googleAnalyticsService.eventEmitter('Remove Query', query.queryid, 'Saved Favorites');
    this.googleAnalyticsService.eventEmitter('Remove Favorites', query.searchTitle, 'Saved Favorites');
  }

  searchFavoriteQuery(query: Query) {
    this.googleAnalyticsService.eventEmitter('Search Query', query.queryid, 'Saved Favorites');
    this.googleAnalyticsService.eventEmitter('Search From', query.searchTitle, 'Saved Favorites');
    this.router.navigate(['/search/' + query.queryid]);
  }
}
