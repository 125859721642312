import { formatDate } from "@angular/common";

export class MeasurePrediciton {
    prediction: number;
    noData: boolean;
    constructor(Prediction:number, NoData:boolean){
        this.prediction = Prediction;
        this.noData = NoData;
    }
}

export class MeasureValidation {
    id: number;
    validationRule:string;
    validationType:string;
    validationMessage:string;
    measuresInRule:string;
    measureValues:object;
    lastUpdated: string;
    lastUpdatedDateTime: string;
    constructor(    
        id: number,
        validationRule:string,
        validationType:string,
        validationMessage:string,
        measuresInRule:string,
        measureValues:object,
        lastUpdated: string
     ) {
            this.id = id;
            this.validationMessage = validationMessage;
            this.validationType = validationType;   
            this.validationRule = validationRule;
            this.measuresInRule = measuresInRule;
            this.measureValues = measureValues;
            this.lastUpdated = lastUpdated;
            this.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");
        }
}