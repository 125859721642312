export class AuditLogging {
    eventType: string;
    action: string;
    primaryKey: string;
    dateTime: string;
    userPrincipalName: string;
    community: string;
    oldValue: any;
    newValue: any;

    public static modelMapper(uiModel: AuditLogging) {
        return {
            eventType: uiModel.eventType,
            action: uiModel.action,
            primaryKey: uiModel.primaryKey,
            dateTime: uiModel.dateTime,
            userPrincipalName: uiModel.userPrincipalName,
            community: uiModel.community,
            oldValue: uiModel.oldValue,
            newValue: uiModel.newValue
        };
    }

    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
            eventType: modelData['eventType'],
            action: modelData['action'],
            primaryKey: modelData['primaryKey'],
            dateTime: modelData['dateTime'],
            community: modelData['community'],
            userPrincipalName: modelData['userPrincipalName'],
            oldValue: modelData['oldValue'],
            newValue: modelData['newValue']
        });
        return this;
    }
}

export class AuditLoggingList {
    auditLoggings: Array<AuditLogging> = [];

    public dataMapper(modelData: AuditLogging[]) {
        return Object.assign(modelData ? modelData.map(data => {
            return  Object.assign(AuditLogging.modelMapper(data));
        }) : []);
    }

    public uiMapper(modelData: any) {
        Object.assign(this, {
            auditLoggings: modelData ?
                modelData.map(data => {
                    return  Object.assign(new AuditLogging().uiMapper(data));
                }) : []
        });

        return this;
    }
}
