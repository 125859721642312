export class SharedDocument {
    documentName: string;
    folderName: string;
    sharedWith: boolean;
    sharedBy: string;
    dateShared: boolean;
    sharedFileId: string;

    public uiMapper(modelData: any) {
        Object.assign(this, modelData);
        return this;
    }
}

export class SharedDocumentList {
    sharedDocuments: Array<SharedDocument> = [];

    public uiMapper(modelData: any) {
        Object.assign(this, {
            sharedDocuments: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new SharedDocument().uiMapper(data));
                        }) : []
        });

        return this;
    }
}
