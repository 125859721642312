import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SearchResultsComponent } from './search-results/search-results.component';
import { HelpComponent } from './help/help.component';
import { ReportComponent } from './reports/report/reportobj.component';
import { OktaAuthGuard } from './core/guards/auth.guard';
import { RoleManagementComponent } from './account/role-management/role-management.component';
import { DashboardManagementComponent } from './account/dashboard-management/dashboard-management.component';
import { ContentRoutingModule } from './content/content-routing.module';
import { HomeComponent } from './home/home/home.component';
import { SearchComponent } from './search/search.component';
import { SharedDocumentComponent } from './search-results/shared-document/shared-document.component';
import { AllHomeComponent } from './home/all-home/all-home.component';
import { ReportingHomeComponent } from './home/reporting-home/reporting-home.component';
import { Features } from './core/navigations/navigation-settings.model';
import { FavoritesRoutingModule } from './favorites/favorites-routing.module';
import { JdaiHomeComponent } from './home/jdai-home/jdai-home.component';
import { VisionHomeComponent } from './home/vision-home/vision-home.component';
import { GrantsHomeComponent } from './home/grants-home/grants-home.component'
import { NotAuthorizedPageComponent } from './common/not-authorized-page/not-authorized-page.component';
import { BoxContainerComponent } from './box/box-container/box-container.component';
import { NewsFeedManageComponent } from './news-feed/news-feed-manage/news-feed-manage.component';
import { FtlaHomeComponent } from './home/ftla-home/ftla-home.component';
import { AuditLoggingComponent } from './audit-logging/audit-logging/audit-logging.component';
import { DropboxContainerComponent } from './dropbox/dropbox-container/dropbox-container.component';
import { PortalConfigRoutingModule } from './portal-config/portal-config-routing.module';
import { PermissionManagementComponent } from './account/permission-management/permission-management.component';
import { JcyoiHomeComponent } from './home/jcyoi-home/jcyoi-home.component';
import { ReportConfigComponent } from './report-config/report-config.component';
import { ReportProfileComponent } from './report-config/report-profile/report-profile.component';
import { UserProfilesComponent } from './account/user-profiles/user-profiles.component';
import { ManageUsersComponent } from './account/manage-users/manage-users.component';
import { FaqContentComponent } from './faq/faq-content/faq-content.component';
import { SurveryPeriodSelectionComponent } from './report-config/survery-period-selection/survery-period-selection.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { OktaConfigComponent } from './portal-config/okta-config/okta-config.component';
import { OktaconfigHomeComponent } from './home/oktaconfig-home/oktaconfig-home.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { AuthNewServiceComponent } from './auth/auth-new-service/auth-new-service.component';
import { SiteComponent } from './jj/site/site.component';
import { JJHomeComponent } from './home/jj-home/jj-home.component';
import { MeasureComponent } from './jj/measure/measure.component';
import { DataCollectionsComponent } from './jj/data-collections/data-collections.component';
import { GroupComponent } from './jj/group/group.component';
import { SitemeasuresComponent } from './jj/sitemeasures/sitemeasures.component';
import { CustomMeasuresComponent } from './jj/custom-measures/custom-measures.component';
import { MeasureValidationsComponent } from './jj/measure-validations/measure-validations.component';
import { VisionSearchResultsComponent } from './search-results/vision-search/vision-search-result.component';
import { ApproveSiteDataComponent } from './jj/approve-site-data/approve-site-data.component';
import { UserBlukUploadComponent } from './account/user-bluk-upload/user-bluk-upload.component';
import { OrganizationprofileComponent } from './ftla/organizationprofile/organizationprofile.component';
import { TechnologiesComponent } from './ftla/technologies/technologies.component';

const routes: Routes = [
{path: '', component: AuthNewServiceComponent},
{
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
{path: 'confighome', component: OktaconfigHomeComponent, canActivate: [MsalGuard], data: { groups: ['aecfsearchdev'] }},
{path: 'home', component: HomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['Kms'] }},
{path: 'allhome', component: AllHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['All'] }},
{path: 'jjhome', component: JJHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['JJdata'] }},
{path: 'reportinghome', component: ReportingHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['Reporting'] }},
{path: 'jdaihome', component: JdaiHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['JDAI'] }},
{path: 'thrivehome', component: VisionHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['Thrive'] }},
{path: 'ftlahome', component: FtlaHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['FTLA'] }},
{path: 'jcyoihome', component: JcyoiHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['Jcyoi'] }},
{path: 'grantshome', component: GrantsHomeComponent, canActivate: [OktaAuthGuard], data: { groups: ['grants'] }},
{path: 'portalconfig', component: OktaConfigComponent, canActivate: [MsalGuard]},
{path: 'portalroles', component: RoleManagementComponent, canActivate: [MsalGuard]},
{path: 'dashboard', redirectTo: 'dashboard/', canActivate: [OktaAuthGuard],
data: { permissions: ['ViewReports'], feature: [Features.Reports] }},
{path: 'dashboard/:reportName', component: ReportComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ViewReports'], feature: [Features.Reports]}},
{path: 'admin/:reportName', component: ReportComponent, canActivate: [OktaAuthGuard],
data: {permissions: ['ViewGoogleAnalytics'] }},
{path: 'search' , component: SearchResultsComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['Search'], feature: [Features.Search] }},
{path: 'thrive-search' , component: VisionSearchResultsComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['Search'], feature: [Features.Search] }},
{path: 'search/:queryId', component: SearchComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['Search'], feature: [Features.Search] }},
{path: 'sharedocument/:id' , component: SharedDocumentComponent,
canActivate: [OktaAuthGuard], data: { permissions: ['ShareDocument'], feature: [Features.Favorites] }},
{path: 'boxview' , component: BoxContainerComponent, canActivate: [OktaAuthGuard],
data: {permissions: ['ViewBox'], feature: [Features.Box] } },
{path: 'dropboxview' , component: DropboxContainerComponent, canActivate: [OktaAuthGuard],
data: {permissions: ['ViewDropbox'], feature: [Features.Dropbox] } },
{path: 'faq' , component: HelpComponent , canActivate: [OktaAuthGuard], data: { feature: [Features.FAQ] }},
{path: 'roles', component: RoleManagementComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageSuperAdmins', 'ManageModerators', 'ManageAdmins'] }},
{path: 'users', component: ManageUsersComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageAdmins'] }},
{path: 'permissions', component: PermissionManagementComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageSuperAdmins', 'ManageModerators', 'ManageAdmins'] }},
{path: 'reportassignment', component: DashboardManagementComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ShareReports'] }},
{ path: 'not-authorized-page', component: NotAuthorizedPageComponent },
{path: 'newsfeed', component: NewsFeedManageComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ViewNewsfeed', 'CreateNewfeed'] }},
{path: 'audit', component: AuditLoggingComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ViewAuditLogs', 'ExportAuditLogs', 'PrintAuditLogs'] }},
{ path: 'reportconfig', component: ReportConfigComponent,canActivate: [OktaAuthGuard],
    data: { permissions: ['ReportsConfiguration'] } },
{ path: 'reportprofile', component: ReportProfileComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ReportProfileConfiguration'] } },
{ path: 'surveyperiod', component: SurveryPeriodSelectionComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageSurveyPeriods'] } },
{ path: 'newfaq', component: FaqContentComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ViewFAQPage,ManageFAQPage'] } },
{ path: 'profiles', component: UserProfilesComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ViewFAQPage'] } },
{ path: 'sites', component: SiteComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ViewSite,ManageSite'] } },
{path: 'groups', component: GroupComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageGroup']}},
{path: 'measure', component: MeasureComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageMeasure']}},
{path: 'sitemeasure', component: SitemeasuresComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageMeasure']}},
{path: 'manageDataCollections', component: DataCollectionsComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageMeasure']}},
{path: 'custommeasure', component: CustomMeasuresComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageCustomMeasure']}},
{path: 'measureValidations', component: MeasureValidationsComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageMeasureValidations']}},
{path: 'measureApprove', component: ApproveSiteDataComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['ManageMeasureValidations']}},
{path: 'importUsers', component:UserBlukUploadComponent, canActivate:[OktaAuthGuard],
data: { permissions: ['ManageAdmins'] }},
{path: 'organizationprofile', component: OrganizationprofileComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['OrganizationProfile']}},
{path: 'technologies', component: TechnologiesComponent, canActivate: [OktaAuthGuard],
data: { permissions: ['Technologies']}}
];

@NgModule({
imports: [
RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', // Don't perform initial navigation in iframes or popups
initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'}),
ContentRoutingModule,
FavoritesRoutingModule,
PortalConfigRoutingModule
],
exports: [RouterModule]
})
export class AppRoutingModule { }