import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @ViewChild('confirmationDialog', {static: false}) contentTemplate;
  @Input() dataItem: any;
  modalRef: BsModalRef;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<any>();

  constructor(private modalService: BsModalService) { }

  get fileName(): string {
    if (this.dataItem && this.dataItem.document) {
      if (this.dataItem.document.fileTitle !== 'Not Specified') {
        return this.dataItem.document.fileTitle;
      }
      return this.dataItem.document.fileName;
    }
    return '';
  }


  ngOnInit() {
  }

  showModal() {
    this.modalRef = this.modalService.show(
      this.contentTemplate,
      {
        ignoreBackdropClick: true,
        class: 'modal-sg delete-modal'
      }
    );
  }

  onDelete(): void {
    this.confirm.next(this.dataItem);
  }

  onCancel(): void {
    this.closeModal.next(false);
  }
}
