import { Component, OnInit } from '@angular/core';
import { ReportConfigurationService } from './shared/report-config.service';
import { ReportConfig } from './shared/report-config-model';
import { StateService } from '../core/state-manager/appstateservice';
import { Roles } from '../core/roles/role-settings.model';

@Component({
  selector: 'app-report-config',
  templateUrl: './report-config.component.html',
  styleUrls: ['./report-config.component.scss']
})
export class ReportConfigComponent implements OnInit {
  reportconfigurations:any =[];
  formSubmitSuccess: string;
  formSubmitError: string;
  viewAsAdmin = false;
  isAddOpen = false;
  selectedReport : ReportConfig;
  selectedReportName: string;
  selectedDepartment:string;
  isNavigation:boolean;
  departmentlist:any=[];
  updatedReportsList:any=[];
  constructor(private reportConfigSrv: ReportConfigurationService,
              private stateService: StateService) { 
                
              }
              get isAdmin() { return this.stateService.getRole() === Roles.Admin; }

              get isModerator() { return this.stateService.getRole() === Roles.Moderator; }
            
              get isSuperAdmin() { return this.stateService.getRole() === Roles.SuperAdmin; }

  ngOnInit(): void {
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    this.reportConfigSrv.getReports().subscribe(res=>{
        this.reportconfigurations = res;
    });
    this.reportConfigSrv.getAllDepartments().subscribe(res=>{
        this.departmentlist = res;
    });
  }

  addUser() {
    this.isAddOpen = true;
  }

  cancelAdd() {
    this.selectedReport=null;
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.selectedReport=null;
  }
  resetAddForm() {
    this.selectedReport = null;
    this.isAddOpen = false;
  }

  EditReport(reportId:any, reportName:any,department:any,navigation:any): void {
    this.selectedReport = new ReportConfig(reportId,reportName,navigation,department);
     this.isAddOpen = true;
  }
  saveUpdateReports():void{
    this.reportConfigSrv.updateReports(this.updatedReportsList).subscribe(r=>{
        this.formSubmitSuccess ='Reports are saved successfully';
    });
  }

  updateReport():void {
      this.updatedReportsList.push(this.selectedReport);
      let updatereport = this.reportconfigurations.find(this.findIndexToUpdate, this.selectedReport.reportId);
      let index = this.reportconfigurations.indexOf(updatereport);
      this.reportconfigurations[index] = this.selectedReport;
      this.isAddOpen = false;
  }
  findIndexToUpdate(newItem) { 
    return newItem.reportId === this;
}
}
