import { FormGroupDirective } from "@angular/forms";

export class ReportConfig{
    reportId: string;
    reportName: string;
    accessEnabled: boolean;
    departmentAccess: string;
    constructor(reportId: string, reportName:string,accessEnabled:boolean,departmentAccess:string)
    {
        this.reportId = reportId;
        this.reportName = reportName;
        this.accessEnabled = accessEnabled;
        this.departmentAccess = departmentAccess;
    }
}