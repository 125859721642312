<div class="navbar-top header-row no-print">  
    <div class="navbar-top-header" [ngClass]="{'ftla-header' : isFtlaGroup}">   
      <div class="header-logo">   
        <a *ngIf="!isFtlaGroup" href="/">
            <img src="../../../assets/images/CaseyPrimaryLogoColor.png" class="logo" alt="logo"/>
        </a>

        <a *ngIf="isFtlaGroup" href="/">
          <img src="../../../assets/images/CaseyPrimaryLogoColor.png" class="logo" alt="logo"/>
      </a>
      </div>
      <div class="header-all" *ngIf="isJdaiGroup">
        <p class="jdai-title">JUVENILE DETENTION ALTERNATIVES INITIATIVE</p>
      </div>
      <div class="header-all" *ngIf="isThriveGroup">
        <p class="vision-title">Thrive by 25</p>
      </div>
      <div class="ftla-logo" *ngIf="isFtlaGroup">
        <img src="../../../assets/images/ftlalogo.png" class="logo" alt="logo"/>
        <!-- <p class="ftla-title">FTLA: FOUNDATION TECHNOLOGY LEADERS ALLIANCE</p> -->
      </div>
      <div class="header-all" *ngIf="isJJDataGroup">
        <p class="jjdata-title">PRO-DATA: PROBATION TRANSFORMATION DATA PORTAL</p>
      </div>
    </div>
    <div class="navbar-right-link">
        <ul class="header-link">
          <li *ngIf="userName" class="login_links logout_links">
            <ng-container *ngIf="userName">
              <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                  <ng-container *ngIf="!isViewasAdmin"><span class="user-icon"><em class="far fa-user-circle"></em></span> {{ userName | firstWord : ' '}}</ng-container>
                  <ng-container *ngIf="isViewasAdmin"><span class="user-icon"><em class="far fa-user-circle"></em></span>  Logged in as {{ viewUserName | firstWord : ' '}}</ng-container>
                  <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">   
                  <li role="menuitem" *ngIf="(!isViewasAdmin && (isAdmin || isSuperAdmin))"> <a (click)="showSearchUserModal()" class="dropdown-item">View as User</a></li>
                  <li role="menuitem" *ngIf="isViewasAdmin"><a (click)="viewasAdmin()" class="dropdown-item">View as Admin</a></li>                     
                  <li role="menuitem"><a (click)="logout()" class="dropdown-item">LOG OUT</a></li>
                </ul>
              </div>
            </ng-container>
          </li>
          <li *ngIf="!userName" class="login_links">
            <ng-container *ngIf="!userName">
              <button *ngIf="singleLogin" (click)="login()" id="button-basic" type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                LOGIN
              </button>
              
            </ng-container>
          </li>
        </ul>
    </div>
  </div>
  
  <app-modal #searchUserDialog contentStyle="search-user-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate>
      <form (ngSubmit)="selectUser()">
          <div class="search-user-content">
            <div class="row">
              <div  class="col-sm-3">
                  <span>Select for user:</span>
              </div>   
              <div class="col-sm-9">  
                <div class="display-text">     
                  <div class="searchBoxForm" *ngIf="!viewUserSelected">
                    <input [(ngModel)]="typedUser"
                      [ngClass]="{ 'invalid': !isValidUser() }"
                      class="form-control"
                      autocomplete="off"
                      id="userText"
                      name="userText"
                      placeholder="Begin Search..."
                      type="text"
                      [typeahead]="searchUserResult"  
                      [typeaheadItemTemplate]="typeaheadItemTemplate"
                      (typeaheadOnSelect)="typeaheadOnSelect($event)"                  
                      [typeaheadOptionsLimit]="10"
                      typeaheadMinLength="3" 
                    />
                  </div>  
                  <div class="display-selected-user" *ngIf="viewUserSelected">
                    <label (click)="removeUserSelect()">{{ typedUser.fullName }}  <i class='close_icon fas fa-times'></i></label>
                  </div>         
                </div>
              </div>         
            </div>
          </div>        
          <div class="col-sm-12 footer-style">
              <div>
                  <button type="button" class="btn button-style cancelbtn" style="width: 100px;" (click)="closeSelectUserModal()">
                      <span>Cancel</span>
                  </button>
              </div>           
              <div class="savebtn">
                  <button type="submit" class="btn button-style">
                      <span>Confirm</span>
                  </button>
              </div>
          </div>
      </form>
   </ng-template>  
  </app-modal>
  <ng-template #typeaheadItemTemplate let-model="item" let-index="index">
    <h4><span class="user-icon"><em class="far fa-user-circle"></em></span> {{model.fullName}}</h4>
  </ng-template>
  