import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';

import { FileUploaderService } from '../shared/file-uploader.service';
import { organizeMetadataTags, 
  organizeLocationMetadataTags, 
  locationTags, 
  FileType, 
  sortLocationMetadataTags, 
  allMetadataTags,
  ftlaMetadataTags } from '../shared/blob-upload.model';
import { FileItem } from 'ng2-file-upload';
import { LinkItem, TagItem } from '../shared/content-model';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-blob-metadata',
  templateUrl: './blob-metadata.component.html',
  styleUrls: ['./blob-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlobMetadataComponent implements OnInit {
  @Input() contentForm: FormGroup;
  @Input() contentSubmitted: boolean;
  blobMetaData: any;
  @Input() blobFileTypes: Array<FileType>;
  blobMetaDataItems: Array<string>;
  metadataTags: any;
  locationMetaData: any;
  locationsTags: any;
  locationsTagsCount = [];
  isAllGroup = false;
  isFtlaGroup = false;
  isThriveGroup = false;
  @Input() uploadCompleted: boolean;
  @Input() tabSelected: string;
  extentionRegEx = /(?:\.([^.]+))?$/;
  @Input() showAccess = true;
  isDataset = false;
  allMetadataTags = allMetadataTags;
  ftlaMetadataTags =ftlaMetadataTags;
  topicsList: Array<any>;
  programsList: Array<any>;
  communitiesList: Array<any>;
  publishersList: Array<any>;
  contentTypeList: Array<any>;
  privacyTypeList: Array<any>;
    /* DatePicker */
  bsPublicationValue = new Date();
  maxDate = new Date();
  colorTheme = 'default';
  bsConfig: Partial<BsDatepickerConfig>;
  locationdata: any=[];
  @Input() searchValue: string; 
  values: number[];
  @ViewChild('txtsearch') txtsearch: ElementRef;
  isExpand: boolean =true;
  
  constructor(private uploadService: FileUploaderService, 
    private formBuilder: FormBuilder, 
    private currentState: StateService,
              private googleAnalyticsService: GoogleAnalyticsService, 
              public datepipe: DatePipe,
              private cd: ChangeDetectorRef) {
      this.maxDate.setDate(this.maxDate.getDate() + 7);
      this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, maxDate: this.maxDate, showWeekNumbers: false });
    }

  get formFileFields(): FormArray {
    return this.contentForm.get('formFilesField') as FormArray;
  }

  formFieldGroup(groupItem): FileItem {
    return groupItem.controls.FileField.value;
  }

  get linkFields(): FormArray {
    return this.contentForm.get('linkField') as FormArray;
  }

  linkFieldGroup(groupItem): LinkItem {
    return groupItem.controls.externalUrlField.value;
  }
  get formFields() { return this.contentForm.controls; }

  hasFieldError(fieldName) {
    const hasError = this.contentSubmitted && this.contentForm.controls[fieldName].errors;
    return hasError;
  }

  hasLocationFieldError() {
    if (!this.isDataset) {
      return this.contentSubmitted && this.isLocationInvalid();
    } else {
      return false;
    }
  }

  isLocationInvalid() {
    if (this.isThriveGroup) {
      return (this.contentForm.controls['country'].value.length === 0
      && this.contentForm.controls['region'].value.length === 0
      && this.contentForm.controls['division'].value.length === 0
      && this.contentForm.controls['state'].value.length === 0
      && this.contentForm.controls['jurisdiction'].value.length === 0);
    }
   else if (this.isFtlaGroup) {
      return false;
    } else {
      return (this.contentForm.controls['country'].value.length === 0
      && this.contentForm.controls['region'].value.length === 0
      && this.contentForm.controls['division'].value.length === 0
      && this.contentForm.controls['state'].value.length === 0
      && this.contentForm.controls['jurisdiction'].value.length === 0);
    }

  }

  hasGroupFieldError(groupItem, fieldName) {
    const hasError = this.contentSubmitted && groupItem.controls[fieldName].errors;
    return hasError;
  }

  ngOnInit() {
    this.isAllGroup = this.currentState.isAllGroup();
    this.isFtlaGroup = this.currentState.isFTLAGroup();
    this.isThriveGroup = this.currentState.isThriveGroup();
    this.metadataTags = {};
    this.uploadService.getBlobMetadata().subscribe(results => {
      this.blobMetaData = results;
      this.blobMetaData['programArea'].sort();
      this.metadataTags = organizeMetadataTags(Object.keys(results), this.isAllGroup);
      this.blobMetaDataItems = Object.keys(this.metadataTags);
    });
    this.locationsTags = Object.keys(locationTags);
    this.gettopicsList();
    if (!this.isAllGroup) {
      this.getprogramsList();0
      //this.getcommunitiesList();
    }
    this.getpublishersList();
    this.getcontentTypeList();
    this.getprivacyTypeList();
    this.uploadService.getlocationsMetadata().subscribe(results => {
        this.locationdata=results;
        this.locationMetaData = sortLocationMetadataTags(organizeLocationMetadataTags(results, this.isFtlaGroup), this.isFtlaGroup);
        if (this.isAllGroup) {
          this.onLocationTagValueChange('USA', 'country', true);
          this.onTagValueChange('Advocacy Learning Lab', 'community', true);
        }
    });

    if (this.tabSelected == 'Upload Dataset') {
      this.isDataset = true;
    }
  }
  resetsearch():void{
    this.searchValue='';
    this.locationMetaData = sortLocationMetadataTags(organizeLocationMetadataTags(this.locationdata, this.isFtlaGroup), this.isFtlaGroup);
  }
  
  onLocationSearch(): void {  
    if(this.searchValue!==''){
        let searchterm = this.searchValue.toLocaleLowerCase();
        console.log(this.locationdata);
        let results = this.locationdata.filter((d: { state: string; county: string; division: string; region: string; }) =>  
                                                    d.state.toLowerCase().startsWith(searchterm) 
                                                  || d.county.toLowerCase().startsWith(searchterm)
                                                  || d.division.toLowerCase().startsWith(searchterm)
                                                  || d.region.toLowerCase().startsWith(searchterm)
                                                  || d.county.toLowerCase().startsWith(searchterm));
                                                  console.log(results);
        if(results.length===0 && this.searchValue.length>0){
          this.isExpand =false;
          this.locationMetaData = JSON.parse('[{"country": "USA"}]');
        }
        else {   
          //Todo : refactor this condition later
          for(let i=0;i<=7;i++){
            results.push(results[i]);
          }
          let orgdata= organizeLocationMetadataTags(results, this.isFtlaGroup);
          this.locationMetaData = results.length ===1 ?orgdata: sortLocationMetadataTags(orgdata, this.isFtlaGroup);
          console.log(this.locationMetaData);
          this.isExpand =true;
        }
    }
  }
  
  ngDoCheck() {
    if(this.searchValue!==undefined || this.txtsearch!==undefined) {
      if(this.searchValue!=''){
       this.cd.markForCheck();
       this.txtsearch.nativeElement.focus();
      }
    }
  }

  gettopicsList() {
    this.uploadService.gettopicsList().subscribe(results => {
      this.topicsList = results;
      this.topicsList.sort((a, b) => (a.topic > b.topic) ? 1 : ((b.topic > a.topic) ? -1 : 0));
      if (this.isAllGroup) {
        this.topicsList.forEach((topic) => {
          topic.programs.sort((a, b) => (a.program > b.program) ? 1 : ((b.program > a.program) ? -1 : 0));
        });
      }
    });
  }

  getprogramsList() {
    this.uploadService.getprogramsList().subscribe(results => {
      this.programsList = results;
      this.programsList.sort((a, b) => (a.program > b.program) ? 1 : ((b.program > a.program) ? -1 : 0));
    });
  }

  getcontentTypeList() {
    this.uploadService.getcontentTypeList().subscribe(results => {
      this.contentTypeList = results;
      this.contentTypeList.sort((a, b) => (a.contentType > b.contentType) ? 1 : ((b.contentType > a.contentType) ? -1 : 0));
    });
  }

  getprivacyTypeList() {
    this.uploadService.getprivacyTypeList().subscribe(results => {
      this.privacyTypeList = results;
      this.privacyTypeList.sort((a, b) => (a.privacyType > b.privacyType) ? 1 : ((b.privacyType > a.privacyType) ? -1 : 0));
    });
  }


  getpublishersList() {
    this.uploadService.getpublishersList().subscribe(results => {
      this.publishersList = results;
      this.publishersList.sort((a, b) => (a.publisher > b.publisher) ? 1 : ((b.publisher > a.publisher) ? -1 : 0));
    });
  }

  onDateValueChange(value: Date): void {
    const publicationDate = this.datepipe.transform(this.bsPublicationValue, 'yyyy-MM-dd HH:mm:ss');
    this.contentForm.controls['publicationDate'].setValue(publicationDate);
  }

  isNumber(value) {
    return !isNaN(Number(value));
  }

  addFileTypeTagItems() {
    this.formFileFields.controls.forEach(element => {
      const fileTypeField = (element as FormGroup).controls.fileTypeField as FormArray;
      const fileName = this.formFieldGroup(element).file.name;
      const extention = this.extentionRegEx.exec(fileName)[1].toLowerCase();
      const fileType = this.blobFileTypes.find(x => x.extensionList.indexOf(extention) > -1);
      if (fileType) {
        const index = fileTypeField.controls.findIndex(x => x.value === fileType.fileType);
        if (index < 0) {
          fileTypeField.push(new FormControl(fileType.fileType));
        }
      }
    });
    const linkFieldGroupItems =  this.linkFields.controls as FormGroup[];
    linkFieldGroupItems.forEach(element  => {
      const fileType = 'Link';
      const fileTypeField = element.controls.fileTypeField as FormArray;
      const index = fileTypeField.controls.findIndex(x => x.value === fileType);
      if (index < 0) {
        fileTypeField.push(new FormControl(fileType));
      }
    });
  }

  onFileTypeTagValueChange(value, groupItem, formName, isChecked: boolean) {
    const formNameArray = groupItem.controls[formName] as FormArray;
    const formNameGA = formName.charAt(0).toUpperCase() + formName.slice(1).split(/(?=[A-Z])/).join(' ');
    if (isChecked) {
      formNameArray.push(new FormControl(value));
      this.googleAnalyticsService.eventEmitter(formNameGA, value, 'Submit Resource');
    } else {
      const index = formNameArray.controls.findIndex(x => x.value === value);
      formNameArray.removeAt(index);
      this.googleAnalyticsService.eventEmitter(formNameGA, value, 'Submit Resource');
    }
  }

   onTagValueChange(value, formName, isChecked: boolean) {
     if(this.isThriveGroup && formName ==='jurisdiction') {
      let selectedLocation = this.locationdata.filter(x=>x.county === value);
      if(selectedLocation.length>0){
      if(isChecked){
         this.onLocationTagValueChange(value,formName,isChecked,
                            selectedLocation[0].continent,
                            selectedLocation[0].country,
                            selectedLocation[0].region,
                            selectedLocation[0].division,
                            selectedLocation[0].state);
         }
         else{
            this.removeTagItem(new TagItem(value, true, selectedLocation[0].continent,
              selectedLocation[0].country,
              selectedLocation[0].region,
              selectedLocation[0].division,
              selectedLocation[0].state), formName);
          }
         }
        }
     else{
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const formNameGA = formName.charAt(0).toUpperCase() + formName.slice(1).split(/(?=[A-Z])/).join(' ');
      if (isChecked) {
        const index = formNameArray.controls.findIndex(x => x.value && x.value === value);
        if (index === -1) {
          formNameArray.push(new FormControl(value));
        }
      } else {
        const index = formNameArray.controls.findIndex(x => x.value === value);
        formNameArray.removeAt(index);
      }
      this.googleAnalyticsService.eventEmitter(formNameGA, value, 'Submit Resource');
    }
  }

  onTopicTagValueChange(value, formName, isChecked: boolean, topic?: any) {
    if (isChecked) {
      this.onTagValueChange(value, formName, true);
      if (formName == 'programArea') {
        const formNameArray = this.contentForm.controls['topic'] as FormArray;
        const index = formNameArray.controls.findIndex(x => x.value === topic.topic);
        if (index === -1) {
          this.onTagValueChange(topic.topic, 'topic', true);
        }
      } else {
        const formNameArray = this.contentForm.controls['programArea'] as FormArray;
        if (topic != undefined) {
          topic.programs.forEach((program) => {
            const index = formNameArray.controls.findIndex(x => x.value === program.program);
            if (index === -1) {
              this.onTagValueChange(program.program, 'programArea', true);
            }
          });
        }
      }
    } else {
      this.onTagValueChange(value, formName, false);
      if (formName == 'topic') {
        const formNameArray = this.contentForm.controls['programArea'] as FormArray;
        if (topic != undefined) {
          topic.programs.forEach((program) => {
            const index = formNameArray.controls.findIndex(x => x.value === program.program);
            if (index >= 0) {
              this.onTagValueChange(program.program, 'programArea', false);
            }
          });
        } else {
          const topicIndex = this.topicsList.findIndex(x => x.topic === value);
          const formNameArray = this.contentForm.controls['programArea'] as FormArray;
          this.topicsList[topicIndex].programs.forEach((program) => {
            const index = formNameArray.controls.findIndex(x => x.value === program.program);
            if (index >= 0) {
              this.onTagValueChange(program.program, 'programArea', false);
            }
          });
        }
      } else {
        const formNameArray = this.contentForm.controls['programArea'] as FormArray;
        let isSubAreaSelected = false;
        if (topic != undefined) {
          for (const program of topic.programs) {
            const index = formNameArray.controls.findIndex(x => x.value === program.program);
            if (index >= 0) {
              isSubAreaSelected = true;
              break;
            }
          }
          if (!isSubAreaSelected) {
            this.onTagValueChange(topic.topic, 'topic', false);
          }
        } else {
          for (const topic of this.topicsList) {
            const index = topic.programs.findIndex(x => x.program === value);
            if (index >= 0) {
              for (const program of topic.programs) {
                const index = formNameArray.controls.findIndex(x => x.value === program.program);
                if (index >= 0) {
                  isSubAreaSelected = true;
                  break;
                }
              }
              if (!isSubAreaSelected) {
                this.onTagValueChange(topic.topic, 'topic', false);
                break;
              }
            }
          }
        }
      }
    }
  }

  onLocationTagValueChange(value, formName, isChecked: boolean,
                           continent?: string, country?: string, region?: string, division?: string, state?: string) {
    const formNameArray = this.contentForm.controls[formName] as FormArray;
    const formNameGA = formName.charAt(0).toUpperCase() + formName.slice(1).split(/(?=[A-Z])/).join(' ');
    if (isChecked) {
      this.googleAnalyticsService.eventEmitter(formNameGA, value, 'Submit Resource');
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === value);
      if (index === -1) {
        formNameArray.push(new FormControl(new TagItem(value, true, continent, country, region, division, state)));
      }
      this.addTagItemtoCount(value, formName, true, continent, country, region, division, state);
      this.addTagItem(continent, locationTags.continent, false, continent, country, region, division, state);
      this.addTagItem(country, locationTags.country, false, continent, country, region, division, state);
      this.addTagItem(region, locationTags.region, false, continent, country, region, division, state);
      this.addTagItem(division, locationTags.division, false, continent, country, region, division, state);
      this.addTagItem(state, locationTags.state, false, continent, country, region, division, state);
    } else {
      this.removeTagItem(new TagItem(value, true, continent, country, region, division, state), formName);
    }
  }

  addTagItem(value, formName, tagItem: boolean, continent: string, country: string, region: string, division: string, state: string) {
    if (value) {
      const formNameGA = formName.charAt(0).toUpperCase() + formName.slice(1).split(/(?=[A-Z])/).join(' ');
      this.googleAnalyticsService.eventEmitter(formNameGA, value, 'Submit Resource');
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === value);
      if (index === -1) {
        formNameArray.push(new FormControl(new TagItem(value, tagItem, continent, country, region, division, state)));
      }
    }
  }

  addTagItemtoCount(formValue: string, formName: string, tagged: boolean,
                    continent: string, country: string, region: string, division: string, state: string) {
    this.locationsTagsCount.push({
    [locationTags.continent]: formName === locationTags.continent ? formValue : continent,
    [locationTags.country]: formName === locationTags.country ? formValue : country,
    [locationTags.region]: formName === locationTags.region ? formValue : region,
    [locationTags.division]: formName === locationTags.division ? formValue : division,
    [locationTags.state]: formName === locationTags.state ? formValue : state,
    [locationTags.jurisdiction]: formName === locationTags.jurisdiction ? formValue : null,
    tagged
    });
  }

  removeTagItemtoCount(tagValue: TagItem, formName: string) {
    let index_array = [];
    const continent = formName === locationTags.continent ? tagValue.item : tagValue.continent;
    const country = formName === locationTags.country ? tagValue.item : tagValue.country;
    const region = formName === locationTags.region ? tagValue.item : tagValue.region;
    const division = formName === locationTags.division ? tagValue.item : tagValue.division;
    const state = formName === locationTags.state ? tagValue.item : tagValue.state;
    const jurisdiction = formName === locationTags.jurisdiction ? tagValue.item : null;
    this.locationsTagsCount.forEach((x, index) => {
      if (formName === locationTags.state) {
        if (x.continent === continent && x.country === country && x.region === region &&
          x.division === division && x.state === state) {
            index_array.push(index);
        }
      } else if (formName === locationTags.division) {
        if (x.continent === continent && x.country === country && x.region === region &&
          x.division === division) {
            index_array.push(index);
        }
      } else if (formName === locationTags.region) {
        if (x.continent === continent && x.country === country && x.region === region) {
          index_array.push(index);
        }
      } else if (formName === locationTags.country) {
        if (x.continent === continent && x.country === country) {
          index_array.push(index);
        }
      } else if (formName === locationTags.continent) {
        if (x.continent === continent) {
          index_array.push(index);
        }
      } else {
        if (x.continent === continent && x.country === country && x.region === region &&
          x.division === division && x.state === state && x.jurisdiction === jurisdiction) {
            index_array.push(index);
        }
      }
    });
    if (index_array.length > 0) {
      for (let i = index_array.length - 1; i >= 0; i--) {
        this.locationsTagsCount.splice(index_array[i], 1);
      }
    }
  }

  removeTagItem(tagValue: TagItem, formName: string) {
    const formNameGA = formName.charAt(0).toUpperCase() + formName.slice(1).split(/(?=[A-Z])/).join(' ');
    if (tagValue.continent == undefined) {
      tagValue.continent = null;
    }
    if (formName === locationTags.jurisdiction) {
      this.googleAnalyticsService.eventEmitter(formNameGA, tagValue.item, 'Submit Resource');
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === tagValue.item);
      formNameArray.removeAt(index);
      this.removeTagItemtoCount(tagValue, formName);
    } else {
      this.googleAnalyticsService.eventEmitter(formNameGA, tagValue.item + ' and its child', 'Submit Resource');
      this.removeTagItemtoCount(tagValue, formName);
      this.removeTagItemFormArray(tagValue, formName);
    }
  }

  removeTagItemFormArray(tagValue: TagItem, formName: string) {
    const continent = formName === locationTags.continent ? tagValue.item : tagValue.continent;
    const country = formName === locationTags.country ? tagValue.item : tagValue.country;
    const region = formName === locationTags.region ? tagValue.item : tagValue.region;
    const division = formName === locationTags.division ? tagValue.item : tagValue.division;
    const state = formName === locationTags.state ? tagValue.item : tagValue.state;
    if (formName === locationTags.state) {
      const countyIndex = this.locationsTagsCount.findIndex(x => x.country === tagValue.country && x.region === tagValue.region &&
        x.division === tagValue.division && x.state === tagValue.state);
      if (countyIndex < 0) {
        const formNameArray = this.contentForm.controls[locationTags.jurisdiction] as FormArray;
        let index_array = [];
        formNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.state === state && x.value.country === tagValue.country && x.value.region === tagValue.region &&
            x.value.division === tagValue.division && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            formNameArray.removeAt(index_array[i]);
          }
        }
      }
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === state && x.value.tagged === false && x.value.country === tagValue.country &&
        x.value.region === tagValue.region && x.value.division === tagValue.division && x.value.continent === tagValue.continent);
      formNameArray.removeAt(index);
    }
    if (formName === locationTags.division) {
      const stateIndex = this.locationsTagsCount.findIndex(x => x.country === tagValue.country && x.region === tagValue.region &&
        x.division === tagValue.division && x.continent === tagValue.continent);
      if (stateIndex < 0) {
        const formNameArray = this.contentForm.controls[locationTags.jurisdiction] as FormArray;
        let index_array = [];
        formNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.division === division && x.value.country === tagValue.country && x.value.region === tagValue.region &&
            x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            formNameArray.removeAt(index_array[i]);
          }
        }
        const stateformNameArray = this.contentForm.controls[locationTags.state] as FormArray;
        index_array = [];
        stateformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.division === division && x.value.country === tagValue.country && x.value.region === tagValue.region &&
            x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            stateformNameArray.removeAt(index_array[i]);
          }
        }
      }
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === division && x.value.tagged === false &&
        x.value.country === tagValue.country && x.value.region === tagValue.region && x.value.continent === tagValue.continent);
      formNameArray.removeAt(index);
    }
    if (formName === locationTags.region) {
      const divisionIndex = this.locationsTagsCount.findIndex(x => x.country === tagValue.country && x.region === tagValue.region && x.continent === tagValue.continent);
      if (divisionIndex < 0) {
        const formNameArray = this.contentForm.controls[locationTags.jurisdiction] as FormArray;
        let index_array = [];
        formNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.region === region && x.value.country === tagValue.country && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            formNameArray.removeAt(index_array[i]);
          }
        }
        const stateformNameArray = this.contentForm.controls[locationTags.state] as FormArray;
        index_array = [];
        stateformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.region === region && x.value.country === tagValue.country && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            stateformNameArray.removeAt(index_array[i]);
          }
        }
        const divisionformNameArray = this.contentForm.controls[locationTags.division] as FormArray;
        index_array = [];
        divisionformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.region === region && x.value.country === tagValue.country && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            divisionformNameArray.removeAt(index_array[i]);
          }
        }
      }
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === region && x.value.tagged === false &&
        x.value.country === tagValue.country && x.value.continent === tagValue.continent);
      formNameArray.removeAt(index);
    }
    if (formName === locationTags.country) {
      const stateIndex = this.locationsTagsCount.findIndex(x => x.country === country && x.continent === tagValue.continent);
      if (stateIndex < 0) {
        const formNameArray = this.contentForm.controls[locationTags.jurisdiction] as FormArray;
        let index_array = [];
        formNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.country === country && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            formNameArray.removeAt(index_array[i]);
          }
        }
        const stateformNameArray = this.contentForm.controls[locationTags.state] as FormArray;
        index_array = [];
        stateformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.country === country && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            stateformNameArray.removeAt(index_array[i]);
          }
        }
        const divisionformNameArray = this.contentForm.controls[locationTags.division] as FormArray;
        index_array = [];
        divisionformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.country === country && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            divisionformNameArray.removeAt(index_array[i]);
          }
        }
        const regionformNameArray = this.contentForm.controls[locationTags.region] as FormArray;
        index_array = [];
        regionformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.country === country && x.value.continent === tagValue.continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            regionformNameArray.removeAt(index_array[i]);
          }
        }
      }
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === country && x.value.tagged === false &&
        x.value.continent === tagValue.continent);
      formNameArray.removeAt(index);
    }
    if (formName === locationTags.continent) {
      const regionIndex = this.locationsTagsCount.findIndex(x => x.continent === continent);
      if (regionIndex < 0) {
        let index_array = [];
        const formNameArray = this.contentForm.controls[locationTags.jurisdiction] as FormArray;
        formNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.continent === continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            formNameArray.removeAt(index_array[i]);
          }
        }
        const stateformNameArray = this.contentForm.controls[locationTags.state] as FormArray;
        index_array = [];
        stateformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.continent === continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            stateformNameArray.removeAt(index_array[i]);
          }
        }
        const divisionformNameArray = this.contentForm.controls[locationTags.division] as FormArray;
        index_array = [];
        divisionformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.continent === continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            divisionformNameArray.removeAt(index_array[i]);
          }
        }
        const regionformNameArray = this.contentForm.controls[locationTags.region] as FormArray;
        index_array = [];
        regionformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.continent === continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            regionformNameArray.removeAt(index_array[i]);
          }
        }
        const countryformNameArray = this.contentForm.controls[locationTags.country] as FormArray;
        index_array = [];
        countryformNameArray.controls.forEach((x, index) => {
          if (x.value && x.value.continent === continent) {
              index_array.push(index);
          }
        });
        if (index_array.length > 0) {
          for (let i = index_array.length - 1; i >= 0; i--) {
            countryformNameArray.removeAt(index_array[i]);
          }
        }
      }
      const formNameArray = this.contentForm.controls[formName] as FormArray;
      const index = formNameArray.controls.findIndex(x => x.value && x.value.item === continent && x.value.tagged === false);
      formNameArray.removeAt(index);
    }
  }

  fileTypeSelected(value, groupItem, formName) {
    const formNameArray = groupItem.controls[formName] as FormArray;
    return formNameArray.controls.findIndex(x => x.value === value) > -1;
  }

  tagSelected(value, formName) {
    const formNameArray = this.contentForm.controls[formName] as FormArray;
    return formNameArray.controls.findIndex(x => x.value === value) > -1;
  }

  locationTagSelected(value, formName) {
    const formNameArray = this.contentForm.controls[formName] as FormArray;
    return formNameArray.controls.findIndex(x => x.value && x.value.item === value) > -1;
  }

}