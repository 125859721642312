import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { DatePipe } from '@angular/common';

import { SearchService } from './shared/search.service';
import { filterTags, ReprioritizeFacets, filterLocations, getShareResultsModel, allFilterTags, filterTopics, ftlaFilterTags } from './shared/search-model';
import { organizeLocationMetadataTags } from '../content/shared/blob-upload.model';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { Roles } from '../core/roles/role-settings.model';
import { StateService } from '../core/state-manager/appstateservice';
import { CommonEntityService } from '../common/services/common-entity.service';
import { GoogleAnalyticsService } from '../core/services/google/google-analytics.service';
import { FileUploaderService } from '../content/shared/file-uploader.service';
import { SystemPermissions } from '../permissions';
@Component({
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  providers: [ DatePipe ]
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @ViewChild('shareResultsDialog', {static: false}) shareResultsModalComponent: ModalComponent;
  @ViewChild('shareDocumentDialog', {static: false}) shareDocumentModalComponent: ModalComponent;
  accordionPanelClass = 'refine-tags';
  results = [];
  facets = [];
  facetsGroup = {};
  searchQuery: string;
  sortParam = 0;
  facetsList: string[] = [];
  fileAttributes: string[] = [];
  tags: any[] = [];
  newFacets: any[] = [];
  /* Pagination */
  maxSize = 5;
  currentPage = 1;
  countFrom;
  countTo;
  totalItems;
  perPage = 10;
  itemPerPage;

  /* DatePicker */
  bsStartValue: Date = null;
  bsEndValue: Date = null;
  maxDate = new Date();
  colorTheme = 'default';
  bsConfig: Partial<BsDatepickerConfig>;

  searchEventSubscription: any;
  showFilters = true;
  geoLocationsData: any;
  locationMetaData: any;
  shareResultsForm: FormGroup;
  emailAddressAdded: boolean;
  shareResultEmailAddress: string;
  shareResultsSubmitted: boolean;
  emailAddressValidationRequired: boolean;
  shareDocumentForm: FormGroup;
  shareDocumentSubmitted: boolean;
  emailPattern = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';
  userRole: Roles;
  viewAsAdmin = this.commonService.getViewUserEventEmitter();
  facetItemKeys: any;
  topicsList: Array<any>;
  message = '';
  showAlert = false;
  showShareAlert = false;
  isAllGroup = false;
  isFTLAGroup = false;
  isFTLA = false;
  isFacetDisabled = false;
  selectedAccordion = '';
  selectedTopic = '';
  isCopyLink: any;
  permissions = SystemPermissions;
  isThriveGroup =false;
  locationdata:any;
  resultLoading:Boolean=false;
  isKmsGroup=false;
  searchOption: string =null;
  constructor(private uploadService: FileUploaderService, private searchService: SearchService, public sanitizer: DomSanitizer,
              public datepipe: DatePipe, private currentState: StateService,
              private route: ActivatedRoute, private formBuilder: FormBuilder,
              private commonService: CommonEntityService, public googleAnalyticsService: GoogleAnalyticsService) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, maxDate: this.maxDate, showWeekNumbers: false });
  }

  ngOnInit() {
    this.isThriveGroup = this.currentState.isThriveGroup();
    this.isKmsGroup = this.currentState.isKmsGroup();
    this.isFTLA = this.currentState.isFTLAGroup();
    this.isAllGroup =this.currentState.isAllGroup();
    if (this.isAllGroup || this.isFTLA) {
      this.gettopicsList();
    } else {
      this.searchService.getlocationsMetadata().subscribe(results => {
        this.locationdata = results;
        this.geoLocationsData = organizeLocationMetadataTags(results, this.isFTLA);
        this.loadBasicSearchResults();
      });
    }
    this.searchEventSubscribe();
    this.initSearchResultsForm();
    this.initSearchDocumentForm();
    if(this.currentState.isFTLAGroup()){
      this.facetItemKeys = ftlaFilterTags;
    }
    else if(this.currentState.isAllGroup()){
      this.facetItemKeys = allFilterTags;
    }
    else
    this.facetItemKeys = filterTags;

    if (this.isFTLA) {
      this.searchService.isSlakcEnabled().subscribe((res: boolean) => {
        this.isFTLAGroup = res;
      });
    }
  }

  ischeckPermissions(permission: string): boolean { return this.currentState.permissionCheck(permission); }

  gettopicsList() {
    this.uploadService.gettopicsList().subscribe(results => {
      this.searchService.getlocationsMetadata().subscribe(results => {
        this.geoLocationsData = organizeLocationMetadataTags(results, this.isFTLA);
        this.loadBasicSearchResults();
      });
      this.topicsList = results;
      this.topicsList.sort((a, b) => (a.topic > b.topic) ? 1 : ((b.topic > a.topic) ? -1 : 0));
      this.topicsList.forEach((topic) => {
        topic.programs.sort((a, b) => (a.program > b.program) ? 1 : ((b.program > a.program) ? -1 : 0));
      });
    });
  }

  ngOnDestroy() {
    if (this.searchEventSubscription) {
      this.searchEventSubscription.unsubscribe();
    }
  }

  isOpenChange(key: string, isOpen: boolean) {
    if (isOpen) {
      this.selectedAccordion = key;
    } else {
      this.selectedAccordion = '';
    }
  }

  initSearchResultsForm() {
    this.shareResultsForm = this.formBuilder.group({
      title:  ['', Validators.required],
      shareWith: this.formBuilder.array([], Validators.required)
    });
  }

  toggleRefineTag() {
    this.showFilters = !this.showFilters;
  }

  get shareResultsFormFields() { return this.shareResultsForm.controls; }

  get shareDocumentFormFields() { return this.shareDocumentForm.controls; }

  get emailAddressToShare(): FormArray {
    return this.shareResultsForm.get('shareWith') as FormArray;
  }

  validateEmailAddress(emailAddress) {
    if (this.emailAddressValidationRequired && (!emailAddress.value || emailAddress.errors)) {
      return false;
    }

    return true;
  }

  validSearchQueryTitle() {
    if (this.shareResultsSubmitted && (this.shareResultsFormFields['title'].errors
                                      || this.shareResultsFormFields['title'].value.trim().length === 0)) {
      return false;
    }

    return true;
  }

  addEmailAddress(validEmailAddress) {
    this.emailAddressValidationRequired = true;
    if (!this.validateEmailAddress(validEmailAddress)) {
      return;
    }
    this.emailAddressValidationRequired = false;
    const addressList = this.emailAddressToShare;
    const index = addressList.controls.findIndex(x => x.value === this.shareResultEmailAddress);
    if (index < 0) {
      addressList.push(new FormControl(this.shareResultEmailAddress));
      addressList.updateValueAndValidity();
    }
    this.shareResultEmailAddress = '';
    this.emailAddressAdded = addressList.controls.length > 0;
  }

  removeEmailAddress(email) {
    const addressList = this.emailAddressToShare;
    const index = addressList.controls.findIndex(x => x.value === email);
    if (index > -1) {
      addressList.removeAt(index);
      addressList.updateValueAndValidity();
    }
    this.emailAddressAdded = addressList.controls.length > 0;
  }

  showShareResultsModal(isFavourite) {
    this.shareResultsSubmitted = false;
    this.emailAddressAdded = false;
    this.emailAddressToShare.clear();
    this.shareResultsForm.reset({title: '', shareWith: []});
    if (this.shareResultsModalComponent) {
      this.shareResultsModalComponent.entityData = {
        headerText: isFavourite ?  'Save Search Results' : 'Share Search Results',
        entityData: { shareResultsForm: this.shareResultsForm, isFavouriteForm: isFavourite }
      };
      this.shareResultsModalComponent.showModal();
    }
  }

  closeShareResultsModal(reason: boolean) {
    this.emailAddressValidationRequired = false;
    if (this.shareResultsModalComponent) {
      this.shareResultsModalComponent.closeModalWindow(reason);
    }
  }

  initSearchDocumentForm() {
    this.shareDocumentForm = this.formBuilder.group({
      emailAddress:  ['', Validators.pattern(this.emailPattern)]
    });
  }

  showShareDocumentModal(document) {
    this.shareDocumentSubmitted = false;
    this.emailAddressValidationRequired = false;
    this.shareDocumentForm.reset({emailAddress: ''});
    if (this.shareDocumentModalComponent) {
      this.shareDocumentModalComponent.entityData = {
        headerText: 'Share Document',
        entityData: { document }
      };
      this.shareDocumentModalComponent.showModal();
    }
  }

  closeShareDocumentModal(reason: boolean) {
    this.shareDocumentSubmitted = false;
    if (this.shareDocumentModalComponent) {
      this.shareDocumentModalComponent.closeModalWindow(reason);
    }
  }


  loadBasicSearchResults() {
    this.facetsGroup = {};
    this.currentPage = 1;
    this.countTo = this.currentPage * this.perPage;
    this.countFrom = this.countTo - 9;

    const routeParams = this.searchService.getSearchRouteState();
    this.searchService.emitSearchRouteEvent(null);
    if (routeParams && routeParams.sharedResults) {
      this.searchService.getQueryString(routeParams.sharedResultId).subscribe(results => {
        this.searchQuery = results.searchQuery;
        Object.entries(results).forEach(([key, value]) => {
          if (key != 'searchQuery' && key != 'pageNumber' && key != 'sortOrder'  && value != null) {
            this.facetsGroup[key] = value;
          }
        });
      });
      this.searchService.getQueryResults(routeParams.sharedResultId).subscribe(results => {
        this.resultFormatter(results);
      }, error => {}, () => {  });
    } else {
      if (routeParams && routeParams.externalLink) {
        this.searchQuery = '';
        this.filterTag('fileType', 'Link');
      } else {
        this.searchQuery = localStorage.getItem('searchQuery');
      }
      // if ((routeParams && routeParams.externalLink) || this.searchQuery) {
      this.searchService.getSearchResults(this.searchQuery,this.searchOption).subscribe(results => {
          this.resultFormatter(results);
        }, error => {}, () => {  });
      // }
    }
  }

  searchEventSubscribe() {
    this.searchEventSubscription = this.searchService.getSearchEventEmitter()
      .subscribe(item => {
         //// after search results loaded
         if (item.renderSearchResults) {
            this.loadBasicSearchResults();
         }
      });
  }

  isHidden(key: string) {
    return (this.isAllGroup && (key == 'community' || key == 'continent' || key == 'country' || key == 'division' || key == 'jurisdiction' || key == 'region' || key == 'state' || key == 'location'));
  }

  clearFacetSelection(facetName, facetValue) {
    if (facetName === 'locationFacet') {
      this.clearFacetSelection('countryFacet', facetValue);
      this.clearFacetSelection('regionFacet', facetValue);
      this.clearFacetSelection('divisionFacet', facetValue);
      this.clearFacetSelection('stateFacet', facetValue);
      this.clearFacetSelection('jurisdictionFacet', facetValue);
    }
    if (facetName === 'topic' && (this.isAllGroup || this.isFTLA)) {
      if (facetValue == 'clearAllChecked') {
        delete this.facetsGroup['topicFacet'];
        delete this.facetsGroup['programAreaFacet'];
      } else {
        this.clearFacetSelection('topicFacet', facetValue);
        this.clearFacetSelection('programAreaFacet', facetValue);
      }
    } else {
      if (facetValue !== 'clearAllChecked') {
        let finalString = '';
        if (typeof this.facetsGroup[facetName] !== 'undefined') {
          this.facetsGroup[facetName].split('|').forEach(key => {
            if (key !== facetValue) {
              finalString += key + '|';
            }
          });
          finalString = finalString.slice(0, -1);
          this.facetsGroup[facetName] = finalString;
          finalString = '';
        }
      } else {
        delete this.facetsGroup[facetName];
      }
    }
    if (this.facetsGroup[facetName] === '') {
      delete this.facetsGroup[facetName];
    }

    let input = this.getBasicSearchParameters();
    input = {...input, ...(this.facetsGroup)};

    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    }, error => {}, () => {  });
  }

  getBasicSearchParameters() {
    this.countTo = this.currentPage * this.perPage;
    this.countFrom = this.countTo - 9;
    const input = {};
    input['searchQuery'] = this.searchQuery;
    input['pageNumber'] = this.currentPage;
    input['sortOrder'] = this.sortParam;
    return input;
  }

  isChecked(facetsGroup, facetValue): boolean {
    if (typeof facetsGroup !== 'undefined') {
      return facetsGroup.split('|').map(key => key).includes(facetValue);
    }
    return false;
  }

  filterTag(facetName, facetValue) {
    if (typeof facetName !== 'undefined') {
      const event = {
        target: {
          checked: true
        }
      };
      this.getFacetedSearchResults(facetName, facetValue, event);
    }
  }

  getFacetedSearchResults(facetName, facetValue, event) {
    this.isFacetDisabled = true;
    let finalString = '';
    const facet = facetName + 'Facet';
    const facetNameGA = facetName.charAt(0).toUpperCase() + facetName.slice(1).split(/(?=[A-Z])/).join(' ');
    this.googleAnalyticsService.eventEmitter(facetNameGA, facetValue, 'Search Results');
    if (typeof this.facetsGroup[facet] !== 'undefined') {
      this.facetsGroup[facet].split('|').forEach((key: string) => {
        if (key !== facetValue) {
          finalString += key + '|';
        }
      });
      if (event.target.checked) {
        finalString += facetValue + '|';
      }
      finalString = finalString.slice(0, -1);
      this.facetsGroup[facet] = finalString;
      finalString = '';
    }

    if (this.facetsGroup[facet] === '') {
      delete this.facetsGroup[facet];
    }

    this.currentPage = 1;
    let input = this.getBasicSearchParameters();
    if (typeof this.facetsGroup[facet] === 'undefined' && event.target.checked) {
      this.facetsGroup[facet] = facetValue;
    }

    input = {...input, ...(this.facetsGroup)};

    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    }, error => {}, () => {  });
  }

  sortSearch(sortParam) {
    this.sortParam = sortParam;
    if (sortParam === 0) {
      this.googleAnalyticsService.eventEmitter('Sort Relevance', this.searchQuery, 'Search Results');
    } else {
      this.googleAnalyticsService.eventEmitter('Sort Recent', this.searchQuery, 'Search Results');
    }
    let input = this.getBasicSearchParameters();
    input = {...input, ...(this.facetsGroup)};
    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    }, error => {}, () => { });
  }

  getPagedSearchResults() {
    let input = this.getBasicSearchParameters();
    input = {...input, ...(this.facetsGroup)};
    this.searchService.getPagedSearchResults(input).subscribe(results => {
      this.resultFormatter(results);
    }, error => {}, () => {  });
  }

  shareResults(isFavourite, isCopyLink) {
    this.shareResultsSubmitted = true;

    if (isFavourite) {
      if (!this.validSearchQueryTitle() && !isCopyLink) {
        return;
      }
    } else if (!this.shareResultsForm.valid) {
      return;
    }
    const input = this.getBasicSearchParameters();
    const shareResultsModel = getShareResultsModel({...input, ...(this.facetsGroup)}, this.shareResultsForm.value, isFavourite);
    this.searchService.shareResults(shareResultsModel, isCopyLink).subscribe(data => {
      if (isCopyLink) {
        this.message = 'Link copied to clipboard.';
        const url = location.origin + '/search/' + data;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.googleAnalyticsService.eventEmitter('Copy Search Link', url, 'Search Results');
      } else {
        if (isFavourite) {
          this.message = 'Search results saved to your favorite.';
        } else {
          this.message = 'Search results succesfully shared.';
        }
      }
      this.showAlert = true;
    });
    this.closeShareResultsModal(true);
  }

  onClosedAlert() {
    this.showAlert = false;
    this.showShareAlert = false;
  }

  shareDocument(document) {
    this.shareDocumentSubmitted = true;
    this.emailAddressValidationRequired = true;
    if (!this.validateEmailAddress(this.shareDocumentForm.get('emailAddress'))) {
      return;
    }
    const currentUser = this.currentState.getUserData();
    this.searchService.shareDocument(document.fileName, document.containerName,
      this.shareDocumentForm.value.emailAddress, currentUser.userPrincipalName).subscribe();
    this.googleAnalyticsService.eventEmitter('Share Document Email', this.shareDocumentForm.value.emailAddress, 'Search Results');
    this.googleAnalyticsService.eventEmitter('Share Document Content', document.fileName, 'Search Results');
    this.closeShareDocumentModal(true);
  }

  getFileContent(fileName: string, containerName: string, fileContentType: string) {
    this.googleAnalyticsService
                .eventEmitter('Get File Content', fileName, 'Search Results');
    this.searchService.getFileContent(fileName, containerName).subscribe(results => {
      const file = new Blob([results], {type: fileContentType});
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    });
  }

  goToUrl(url: string): void {
    if (url.indexOf('http') === -1) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }

  resultFormatter(results) {
    this.facetsList = [];
    const re = /(?:\.([^.]+))?$/;
    this.totalItems = results.totalCount;
    this.itemPerPage = results.documents.length;
    this.facetsList = Object.keys(filterTags);
    results.documents.forEach(result => {
      this.fileAttributes = [];
      this.tags = [];
      this.facetsList.forEach((facet) => {
        if (facet !== null) {
          if (result.document[facet] !== null && result.document[facet] !== 'null') {
            this.tags.push({ key: facet, value: result.document[facet] });
          }
        }
      });
      const extentions = re.exec(result.document.fileName);
      const ext = result.document.fileName && extentions[1] && extentions[1].toLowerCase();
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'bmp' || ext === 'tif') {
        this.fileAttributes['CssClass'] = 'far fa-file-image';
        this.fileAttributes['CssIcon'] = 'file-pdf';
        this.fileAttributes['FileContentType'] = 'image/' + ext;
        this.fileAttributes['FileType'] = 'image';
        result.document.fileAttributes = this.fileAttributes;
      }
      if (ext === 'url' || ext === 'lnk') {
        this.fileAttributes['CssClass'] = 'fa fa-link';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/internet-shortcut';
        this.fileAttributes['FileType'] = 'WebSite';
        result.document.fileAttributes = this.fileAttributes;
      }
      if (ext === 'pdf') {
        this.fileAttributes['CssClass'] = 'far fa-file-pdf';
        this.fileAttributes['CssIcon'] = 'file-pdf';
        this.fileAttributes['FileContentType'] = 'application/pdf';
        this.fileAttributes['FileType'] = 'PDF';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'eml' || ext === 'mht') {
        this.fileAttributes['CssClass'] = 'far fa-envelope';
        this.fileAttributes['CssIcon'] = 'file-email';
        this.fileAttributes['FileContentType'] = 'message/rfc822';
        this.fileAttributes['FileType'] = 'Email';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'docx') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        this.fileAttributes['FileType'] = 'WORD';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'ppt') {
        this.fileAttributes['CssClass'] = 'far fa-file-powerpoint';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/vnd.ms-powerpoint';
        this.fileAttributes['FileType'] = 'POWER POINT';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'pptx') {
        this.fileAttributes['CssClass'] = 'far fa-file-powerpoint';
        this.fileAttributes['CssIcon'] = 'file-powerpoint';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        this.fileAttributes['FileType'] = 'POWER POINT';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'doc') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/msword';
        this.fileAttributes['FileType'] = 'WORD';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'rtf') {
        this.fileAttributes['CssClass'] = 'far fa-file-word';
        this.fileAttributes['CssIcon'] = 'file-word';
        this.fileAttributes['FileContentType'] = 'application/rtf';
        this.fileAttributes['FileType'] = 'WORD';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'xls' || ext === 'csv') {
        this.fileAttributes['CssClass'] = 'far fa-file-excel';
        this.fileAttributes['CssIcon'] = 'file-excel';
        this.fileAttributes['FileContentType'] = 'application/vnd.ms-excel';
        this.fileAttributes['FileType'] = 'EXCEL';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'xlsx') {
        this.fileAttributes['CssClass'] = 'far fa-file-excel';
        this.fileAttributes['CssIcon'] = 'file-excel';
        this.fileAttributes['FileContentType'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        this.fileAttributes['FileType'] = 'EXCEL';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'htm' || ext === 'html') {
        this.fileAttributes['CssClass'] = 'far fa-file-code';
        this.fileAttributes['CssIcon'] = 'file-code';
        this.fileAttributes['FileContentType'] = 'text/html';
        this.fileAttributes['FileType'] = 'HTML';
        result.document.fileAttributes = this.fileAttributes;
      }
      if (ext === 'wmv') {
        this.fileAttributes['CssClass'] = 'far fa fa-film';
        this.fileAttributes['CssIcon'] = 'file-code';
        this.fileAttributes['FileContentType'] = 'video/x-ms-wmv';
        this.fileAttributes['FileType'] = 'Wmv';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (ext === 'zip' || ext === 'rar' || ext === '7z') {
        this.fileAttributes['CssClass'] = 'far fa-file-archive';
        this.fileAttributes['CssIcon'] = 'file-archive';
        this.fileAttributes['FileContentType'] = 'application/octet-stream';
        this.fileAttributes['FileType'] = 'Archive';
        result.document.fileAttributes = this.fileAttributes;
      }

      if (!result.document.fileAttributes) {
        this.fileAttributes['CssClass'] = 'far fa fa-file';
        this.fileAttributes['CssIcon'] = 'file-archive';
        this.fileAttributes['FileType'] = 'Not Specified';
        result.document.fileAttributes = this.fileAttributes;
      }

      result.document.tag = this.tags;
      result.document.toggle = 0;
    });
    this.results = results.documents;
    const searchFacets = results.facets;
    if(!this.isThriveGroup){
      searchFacets.location = filterLocations(searchFacets, this.geoLocationsData, this.isFTLA);
    }
    if (this.isAllGroup || this.isFTLA) {
      searchFacets.topic = filterTopics(searchFacets, this.topicsList);
    }
    this.facets = ReprioritizeFacets(searchFacets, this.isFTLA, this.isThriveGroup);
    this.isFacetDisabled = false;
  }

  showPreview(result) {
    if (result.document.containerName !== null) {
      if (result.document.fileAttributes['FileType'] === 'PDF' ||
      (result.document.fileAttributes['FileType'] === 'Not Specified' &&
      (result.document.fileType[0] === 'Text' || result.document.fileType[0] === 'Data' || result.document.fileType[0] === 'Form'))) {
        this.searchService.getFileContent(result.document.fileName, result.document.containerName).subscribe(results => {
          const file = new Blob([results], {type: result.document.fileAttributes.FileContentType});
          const fileURL = URL.createObjectURL(file);
          if (result.document.fileAttributes['FileType'] === 'Not Specified' &&
          (result.document.fileType[0] === 'Text' || result.document.fileType[0] === 'Data' || result.document.fileType[0] === 'Form')) {
            result.document.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
          } else {
            result.document.fileURL = fileURL;
          }
          result.document.toggle = !result.document.toggle;
        });
      } else if (result.document.fileAttributes['FileType'] === 'image') {
        this.searchService.getBlobSASUrl(result.document.fileName, result.document.containerName).subscribe(results => {
          result.document.fileURL =  decodeURIComponent(results);
          result.document.toggle = !result.document.toggle;
        });
     } else {
        this.searchService.getBlobSASUrl(result.document.fileName, result.document.containerName).subscribe(results => {
          result.document.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://view.officeapps.live.com/op/embed.aspx?src='
                                    + results + '&embedded=true');
          result.document.toggle = !result.document.toggle;
        });
      }
      if (!result.document.toggle) {
        this.googleAnalyticsService.eventEmitter('Show Preview', result.document.fileName, 'Search Results');
      } else {
        this.googleAnalyticsService.eventEmitter('Hide Preview', result.document.fileName, 'Search Results');
      }
    }
  }

  downloadFile(result) {
    if (result.document.containerName !== null) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      this.searchService.getFileContent(result.document.fileName, result.document.containerName).subscribe(results => {
        const file = new Blob([results], {type: result.document.fileAttributes.FileContentType});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = result.document.fileName;
        link.click();
      });
      this.googleAnalyticsService
                  .eventEmitter('Download File', result.document.fileName, 'Search Results');
    }
  }

  sendToSlack(result) {
    if (result.document.containerName !== null) {
      this.searchService.SendToSlack(result.document.fileName, result.document.containerName).subscribe(results => {
        this.message = 'File shared to the slack channel';
        this.showShareAlert = true;
      });
      this.googleAnalyticsService.eventEmitter('Sent To Slack', result.document.containerName + '/' + result.document.fileName, 'Search Results');
    }
  }
  returnZero(): number {
    return 0;
  }
}
