import { Injectable } from '@angular/core';
import { FileUploader, FileItem  } from 'ng2-file-upload';
import { Observable, of } from 'rxjs';
declare const BoxSelect: any;
declare const Dropbox: any;

import { StateService } from 'src/app/core/state-manager/appstateservice';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { BlobMetadata } from 'src/app/common/uploadform/models/blob-metadata-model';
import { environment } from 'src/environments/environment';
import { updateMetadataTags, FileType } from './blob-upload.model';
import { catchError, map } from 'rxjs/operators';
import { maxUploadFileSize } from 'src/app/common/models/file-definitions';
import { HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';

@Injectable()
export class FileUploaderService {
  private uploader: FileUploader;
  private boxSelect: any;
  private dropBoxSelect: any;
  public guid: string;
  private httpClient: HttpClient;
  isDropBoxUploadComplete = 0;
  isBoxUploadComplete = 0;

  constructor(private stateService: StateService, private repository: RepositoryService,  handler: HttpBackend,
              public googleAnalyticsService: GoogleAnalyticsService) {
    this.httpClient = new HttpClient(handler);
  }

  initBulkUploader(isDataset) {
    this.guid = this.createGuid();
    this.getGuid().subscribe(data => {
      if (data) {
        this.guid = data;
      }
    });
    if (isDataset) {
      this.uploader = new FileUploader({
        url: environment.apiBaseURL + 'api/BlobStorage/uploadFile/',
        authToken: this.stateService.getToken(),
        queueLimit: this.queueLimit,
        maxFileSize: this.maxFileSize,
        allowedFileType: ['xls']
      });
    } else {
      this.uploader = new FileUploader({
        url: environment.apiBaseURL + 'api/BlobStorage/uploadFile/',
        authToken: this.stateService.getToken(),
        queueLimit: this.queueLimit,
        maxFileSize: this.maxFileSize
      });
    }
  }

  get bulkUploader(): FileUploader {
    return this.uploader;
  }

  get getUploader(): FileUploader {
    return this.uploader;
  }

  get queueLimit(): number {
    return 10;
  }

  get maxFileSize(): number {
    return maxUploadFileSize.maxFileSize;
  }

  uploadLink(uploladValues: any, linkItem: any): Observable<any> {
    //// link external update
    const formData = new FormData();
    this.buildUploadItemForm(uploladValues, null, formData, linkItem);
    const retryOption =  linkItem.externalUrlField.retry ? '?retry=' + true : '';
    return this.repository.upload(environment.apiBaseURL, 'api/BlobStorage/uploadFile' + retryOption, formData, false)
    .pipe(map(response => {
            linkItem.externalUrlField.isUploaded = true;
            linkItem.externalUrlField.isError = false;
            linkItem.externalUrlField.retry = false;
            linkItem.externalUrlField.status = response.status;
            response.success = true;
            return response;
          }),
          catchError(error => {
            linkItem.externalUrlField.isError = true;
            linkItem.externalUrlField.retry = false;
            linkItem.externalUrlField.status = error.status;
            return of(error);
    }));
  }

  initBoxUpload() {
    const options = {
      clientId: environment.boxClientId,
      linkType: 'direct',
      multiselect: true
    };

    this.boxSelect = new BoxSelect(options);

    // Register a success callback handler
    let itemsProcessed = 0;
    this.boxSelect.success((files) => {
      if (files) {
        files.forEach((file, index, array) => {
          if (file && file['url']) {
            this.repository.get(file['url'], '', 'blob')
            .subscribe((response) => {
              const filedata = new File([response], file['name'], {
                type: response['type'],
                lastModified: Date.now()
              });
              this.uploader.addToQueue([filedata]);
            });
          }
          itemsProcessed++;
          if (itemsProcessed === array.length) {
            this.isBoxUploadComplete = this.isBoxUploadComplete + array.length;
            this.googleAnalyticsService.eventEmitter('File Upload Count - Box', String(array.length), 'Submit Resource');
          }
        });
      }
    });

    // Register a cancel callback handler
    this.boxSelect.cancel(() => {
        console.log('The user clicked cancel or closed the popup');
    });
  }

  initDropBoxUpload() {
    this.dropBoxSelect = Dropbox;
  }

  showBoxPopup() {
    this.boxSelect.closePopup();
    this.boxSelect.launchPopup();
  }

  showDropBoxPopup() {
    let itemsProcessed = 0;
    const options = {
      linkType: 'direct',
      multiselect: true,
      // Required. Called when a user selects an item in the Chooser.
        success: (files) => {
          if (files) {
            files.forEach((file, index, array) => {
              if (file && file['link']) {
                this.downloadDropboxFile(file['link'], 'blob')
                  .subscribe((response) => {
                    const filedata = new File([response], file['name'], {
                      type: response['type'],
                      lastModified: Date.now()
                    });
                    this.uploader.addToQueue([filedata]);
                  });
              }
              itemsProcessed++;
              if (itemsProcessed === array.length) {
                this.isDropBoxUploadComplete = this.isDropBoxUploadComplete + array.length;
                this.googleAnalyticsService.eventEmitter('File Upload Count - Drop Box', String(array.length), 'Submit Resource');
              }
            });
          }
      },

      // Optional. Called when the user closes the dialog without selecting a file
      cancel() {
        console.log('The user clicked cancel or closed the popup');
      }
    };

    this.dropBoxSelect = Dropbox.choose(options);
  }

  downloadDropboxFile(url: any, responseType: any): Observable<any> {
    const httpObserve: any = 'response';
    const httpResponseType = responseType;
    let reqHeaders =  new HttpHeaders();
    reqHeaders = reqHeaders.set('Content-Type', 'application/json');

    const httpOptions  = {
      body : null,
      headers: reqHeaders,
      params: null,
      responseType: httpResponseType,
      observe : httpObserve
    };
    return new Observable((observer) => {
      this.httpClient.request('GET', url, httpOptions)
        .subscribe(
        (response) => {
          const resBody = response['body'] !== null ? response['body'] : {};
          observer.next(resBody);
          observer.complete();
        }, (error) => { observer.error(error); });
      });
  }

  buildUploadItemForm(uploladValues: any, fileItem: any, formData: any, linkItem?: any) {
    if (fileItem) {
      fileItem.alias = 'FormFile';
      //// add headers
      fileItem.withCredentials = false;
      fileItem.headers = [{name: 'X-View-As-User', value: this.stateService.getViewingUserEmail()}];

      const fieldItem = uploladValues.formFilesField.find(x => x.FileField === fileItem);
      if (fieldItem) {
        formData.set('FileTitle', fieldItem.kcTitleField);
        fieldItem.fileTypeField.forEach(formValue => {
          formData.append('FileType[]', formValue);
        });
      }
    } else if (linkItem && linkItem.externalUrlField.linkValue) {
      formData.set('ExternalUrl', linkItem.externalUrlField.linkValue);
      formData.set('FileTitle', linkItem.kcTitleField);
      linkItem.fileTypeField.forEach(formValue => {
        formData.append('FileType[]', formValue);
      });
    }

    updateMetadataTags(uploladValues, formData);
    formData.set('UserfullName', this.stateService.getUserfullName());
    formData.set('UniqueKey', this.guid);
    formData.set('GrantNumber', uploladValues.grantNumber);
    if (uploladValues.moderator) {
        formData.set('ModeratorId', uploladValues.moderator.id);
    }
    if (uploladValues.datasetType) {
      formData.set('datasetType', uploladValues.datasetType);
      formData.set('IsDataset', uploladValues.isDataset);
    }
  }

  sendUploadNotification(uploadedFiles: Array<string>, setId: string): any {
    this.repository.get(environment.apiBaseURL, 'api/Notification/uploadnotification/' + setId).subscribe();
    this.repository.post(environment.apiBaseURL, 'api/Notification/awaitingapproval', uploadedFiles).subscribe();
  }
  getBlobMetadata(): Observable<BlobMetadata> {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/blobmetadata/');
  }
  getlocationsMetadata(): Observable<BlobMetadata> {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/geolocations');
  }

  getlocationMetadata(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/geolocations');
  }

  getFiletypesMetadata(): Observable<FileType[]> {
    return this.repository.get(environment.apiBaseURL, 'api/BlobStorage/fileTypes');
  }

  getGuid(): Observable<string> {
    return this.repository.get(environment.apiBaseURL, 'api/Common/guid');
  }

  gettopicsList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/topics');
  }

  getprogramsList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/programs');
  }

  getcommunitiesList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/communities');
  }

  getpublishersList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/publishers');
  }

  getcontentTypeList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/contentTypes');
  }

  getprivacyTypeList(): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/ResourcesConfiguration/metadata/privacyTypes');
  }

  createGuid() {
    const u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    const guid = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join('-');
    return guid;
  }

  uploadFile(data: any) {
    const formData = new FormData();
    formData.append('Template', data.FormFile, data.FormFile.name);
    formData.append('ReportType', data.datasetType);
    formData.append('FileName', data.FormFile.name);
    return this.repository.upload(environment.apiBaseURL, 'api/BlobStorage/reportTemplate/upload', formData);
  }
  uploadReport(data:any)
  {
    const formData = new FormData();
    formData.append('report', data.FormFile, data.FormFile.name);
    return this.repository.upload(environment.apiBaseURL, 'api/Reports/publish', formData);
  }

  resourceUploadNewsFeed(document:any,uploadedBy:any,org:any):Observable<any> {
    return this.repository.get(environment.apiBaseURL,'api/User/resourceUploadNewsFeed/'+document+'/'+uploadedBy+'/'+org);
  }
}
