import { Injectable } from '@angular/core';
import { IServerConfiguration } from './../models/IServerConfiguration';
import { RepositoryService } from '../../core/services/http/repository.service';
import { StateService } from '../../core/state-manager/appstateservice';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigurationService {

  private configuration: IServerConfiguration;

  constructor(private repository: RepositoryService, private currentState: StateService) { }

  loadConfig() {
    if(localStorage.getItem('OktaSettings') !== 'true') {
    return this.repository.get(environment.apiBaseURL, 'api/OktaConfig')
    .toPromise()
      .then(result => {
        this.configuration = ( (result) as IServerConfiguration);
        localStorage.setItem('clientId', this.configuration.oktaClientId);
        localStorage.setItem('issuer', this.configuration.oktaIssuer);
        localStorage.setItem('OktaSettings','true');
        console.log('Okta API called............');
        window.location.href='/';
      }, error => console.log(error));
    }
  }
}
