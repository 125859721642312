import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FavoritesService } from '../shared/favorites.service';
import { SharedDocument, SharedDocumentList } from '../shared/shared-document';

@Component({
  selector: 'app-shared-with-others',
  templateUrl: './shared-with-others.component.html',
  styleUrls: ['./shared-with-others.component.scss']
})
export class SharedWithOthersComponent implements OnInit {
  sharedDocuments: SharedDocument[];

  constructor(private router: Router, private favoritesService: FavoritesService) { }

  ngOnInit() {
    this.getSharedDocuments();
  }

  getSharedDocuments() {
    this.favoritesService.getSharedWithOthersDocuments()
    .subscribe(data => {
        this.sharedDocuments = new SharedDocumentList().uiMapper(data).sharedDocuments;
    });
  }

  openDocument(document: SharedDocument) {
    this.router.navigate(['/sharedocument/' + document.sharedFileId]);
  }
}
