<div class="navbar-bottom  no-print" [ngClass]="{ 'footer-row': !isJJDataGroup && !isGrantsGroup, 'footer-row-jjdata':isJJDataGroup, 'footer-row-grants': isGrantsGroup}" *ngIf="isFooterShown">
    <div class="container footer_container">
        <div [ngClass]="{ 'col-lg-9 col-md-9 col-sm-9 col-xs-9': !isJJDataGroup && !isGrantsGroup, 'col-lg-8 col-md-8 col-sm-8 col-xs-8':isJJDataGroup || isGrantsGroup }">
            <div class="row">
                <div class="footer-row-spacing">
                    <a href="https://www.aecf.org/">THE ANNIE E. CASEY FOUNDATION</a>
                </div>
                <div class="footer-row-spacing">
                    <a href="https://www.aecf.org/privacy/">PRIVACY</a>
                </div>
                <div class="footer-row-spacing" *ngIf="isShow">
                    <a href="https://partners.aecf.org/">GRANTEE PORTAL</a>
                </div>
                <div class="footer-row-spacing">
                    <a href="https://www.aecf.org/terms-of-use/">TERMS OF USE</a>
                </div>
            </div>
            <div class="row copyrightContainer" *ngIf="!isJJDataGroup && !isGrantsGroup">
              <span>&copy; {{ today | date:'yyyy'}} THE ANNIE E. CASEY FOUNDATION. ALL RIGHTS RESERVED.</span>
              <span class="footer-version-bg"> Version {{version}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"  *ngIf="isJJDataGroup || isGrantsGroup">
            <div class="footer-row-spacing-jjdata">
                <span style="font-size:13px">&copy; {{ today | date:'yyyy'}} </span><span> THE ANNIE E. CASEY FOUNDATION. ALL RIGHTS RESERVED.</span>
                <span class="footer-version-bg" *ngIf="!isGrantsGroup"> Version {{version}}</span>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="!isJJDataGroup && !isGrantsGroup">
            <img src="../../../assets/images/CaseyStackedLogoBW.png" class="footer_logo"/>
        </div>
    </div>
</div>
