<div class="container role-manage">
    <div class="heading">
        <div class="page-title">
            <div>
                <span calss="page-title"><h1>Role Assignment</h1></span>
            </div>
        </div>
        <div class="col-md-12" *ngIf="isAdmin">
          <span>Select the role assignment for the corresponding user or add a new user</span>        
        </div>
        <div class="col-md-12" *ngIf="isModerator">
          <span>Add or remove moderators within your business unit</span>
        </div>
    </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>
    <div class="adminRoleContainer col-md-12">
        <div class="row header m-0">
            <div class="col-sm-{{nameColumnSize}} align-left">
               <span class="user-icon"><em class="fas fa-user"></em></span>User Name
            </div>
            <div class="col-sm-{{roleColumnSize}} align-left" *ngIf="isKMSGroup">                
                <span class="header-icon"><em class="fas fa-users"></em></span>Unit
            </div>
            <div class="col-sm-{{roleColumnSize}} text-center" *ngIf="isModerator">
                <span><span class="header-icon"><em class="fas fa-unlock"></em></span>Send Email</span>
            </div>   
            <div class="col-sm-{{roleColumnSize}} text-center" *ngIf="(isSuperAdmin|| isAdmin) && (isAllGroup ||isFTLAGroup)">
                <span><span class="header-icon"><em class="fas fa-unlock"></em></span>Super Admin</span>
            </div>        
            <div class="col-sm-{{roleColumnSize}} text-center" *ngIf="isSuperAdmin || isAdmin || isPortalConfig">
                <span><span class="header-icon"><em class="fas fa-unlock"></em></span>Admin</span>
            </div>
            <div class="col-sm-{{roleColumnSize}} text-center">
                <span *ngIf="(isSuperAdmin || isAdmin) && !(isAllGroup || isFTLAGroup )"><span class="header-icon"><em class="fas fa-unlock"></em></span>Moderator</span>
            </div>
        </div>
        <div class="row m-0">
            <ng-container *ngFor="let user of manageUsers; let rowIndex=index;">
                <div *ngIf="showAdminRow(user)"  class="clearfix userContainer">
                    <div class="col-sm-{{nameColumnSize}} align-left">
                        <div class="delete-icon" [ngClass]="{ 'disable': !user.isPending && viewAsAdmin }">
                            <span *ngIf="currentUser.id !== user.id" class="role-icon" (click)="removePendingRole(user, rowIndex)">
                                <em class="far fa-trash-alt"></em>
                            </span>  
                        </div>
                        <span [tooltip]="user.userPrincipalName"> {{user.fullName}}</span>
                    </div>
                    <div class="col-sm-{{roleColumnSize}} align-left" *ngIf="isKMSGroup">
                        {{user.unit}}
                    </div>
                    <div class="col-sm-{{roleColumnSize}} text-center" *ngIf="(isSuperAdmin || isAdmin) && (isAllGroup || isFTLAGroup)">
                        <input  [(ngModel)]="user['role']" class="selectCheck" [value]="userRoles.SuperAdmin" name="userRole{{rowIndex}}" id="btn-{{rowIndex}}-superadmin"
                        type="radio">
                    </div>
                    <div class="col-sm-{{roleColumnSize}} text-center">
                        <input *ngIf="isSuperAdmin || isAdmin || isPortalConfig" [(ngModel)]="user['role']" class="selectCheck" [value]="userRoles.Admin" name="userRole{{rowIndex}}" id="btn-{{rowIndex}}-admin"
                        type="radio">
                        <input *ngIf="isModerator" [(ngModel)]="user['isEmailNotification']" class="selectCheck" [value]="user['isEmailNotification']" name="userEmail{{rowIndex}}" id="btn-{{rowIndex}}-sendEmail"
                        type="checkbox">
                    </div>
                    <div class="col-sm-{{roleColumnSize}} text-center">
                        <input *ngIf="(isAdmin || isSuperAdmin) && !(isAllGroup || isFTLAGroup)" [(ngModel)]="user['role']" class="selectCheck" [value]="userRoles.Moderator" name="userRole{{rowIndex}}" id="btn-{{rowIndex}}-moderator"
                        type="radio">
                    </div>                     
                </div>                
            </ng-container>
        </div>
    </div>
    <div class="clearfix">
        <div class="px-15 addRowBtn">
            <button type="button" id="btnadd" class="btn btn-success button-style mr-1rem" (click)="addUser()">Add</button>
            <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
            <button type="button" id="btnsave" class="btn btn-secondary button-style" (click)="updateRole()" [ngClass]="{ 'disable-color': viewAsAdmin }">Save</button>
        </div>
        <div class="pull-right">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
    </div>
</div>

<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <label for="txt-user">User</label>
        <input [(ngModel)]="selectedUserName"
            (typeaheadOnSelect)="onUserSelect($event)"
            [typeahead]="pendingUsers"  
            typeaheadOptionField="fullName"
            [typeaheadMinLength]="0"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]=10
            [typeaheadOptionsLimit]=itemsTosearch
            placeholder="Select User to Add"
            class="form-control select">
        <div *ngIf="!selectedUserName" class="text-danger small">
            User is required.
        </div>
    </div>
    <div class="form-group" *ngIf="isAdmin || isSuperAdmin">
        <label for="txt-role">Role</label>
        <div class="clearfix px-15" *ngIf="(isAllGroup || isFTLAGroup)">
            <input [(ngModel)]="selectedUserRole" class="selectCheck" [value]="userRoles.SuperAdmin" name="userRole" id="btn-admin"
            type="radio"> <span class="pl-5">Super Admin</span>
        </div>  
        <div class="clearfix px-15">
            <input [(ngModel)]="selectedUserRole" class="selectCheck" [value]="userRoles.Admin" name="userRole" id="btn-admin"
            type="radio"> <span class="pl-5">Admin</span>
        </div>  
        <div class="clearfix px-15" *ngIf="!(isAllGroup || isFTLAGroup || isPortalConfig)">
            <input [(ngModel)]="selectedUserRole" class="selectCheck" [value]="userRoles.Moderator" name="userRole" id="btn-moderator"
            type="radio"> <span class="pl-5">Moderator</span>
        </div>
    </div>
    <div class="form-group" *ngIf="isModerator">
        <div class="clearfix">
            <input [(ngModel)]="isEmailNotification" class="selectCheck" [value]="isEmailNotification" name="isEmailNotification" id="chkEmail"
                        type="checkbox"><span class="pl-5">Send Email</span>
        </div>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" (click) = "cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" (click)="AddUserRole()" [disabled]="(isAdmin && (!selectedUser || !selectedUserRole)) || (isModerator && !selectedUser)" class="btn btn-block btn-warning">Add</button>
    </div>
  </div>
  <div class="backdrop" *ngIf="isAddOpen"></div>

<app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <div class="col-sm-12 content-style">
            <div *ngIf="dataItem && dataItem.managersExists && dataItem.tasksExists">
                <span>{{dataItem.deleteUser.fullName}} will be deleted and tasks will be reassigned to </span><span *ngIf="dataItem.manager">{{dataItem.manager.fullName}}</span>
                <select class="form-control select" id="selectModerator" [(ngModel)]="selectedModerator" *ngIf="!dataItem.manager">
                    <option *ngFor="let manager of dataItem.manageUsers" [ngValue]="manager">{{manager.fullName}}</option>
                </select>
            </div>
            <div *ngIf="dataItem && dataItem.tasksExists && !dataItem.managersExists">
                <span>The tasks are pending for the user {{dataItem.deleteUser.fullName}} and cannot be deleted?</span>
            </div>
            <div *ngIf="dataItem && !dataItem.tasksExists">
                <span>Are you sure you want to delete {{dataItem.deleteUser.fullName}}?</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style" *ngIf="dataItem && (dataItem.managersExists || !dataItem.tasksExists)">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="removeRole(dataItem.deleteUser, dataItem.rowIndex)">
                    <span>Ok</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>