import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabelTransformPipe } from './pipes/label-transform.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { BytesTransformPipe } from './pipes/bytes-transform.pipe';
import { FormatArrayPipe } from './pipes/format-array.pipe';
import { ModalComponent } from './components/modal/modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FirstWordPipe } from './pipes/first-word.pipe';
import { TimezoneTransformPipe } from './pipes/timezone-transform.pipe';

@NgModule({
  declarations: [LabelTransformPipe, SafeUrlPipe, BytesTransformPipe, FormatArrayPipe, FirstWordPipe,
    ModalComponent, TimezoneTransformPipe],
  imports: [
    CommonModule,
    BsDropdownModule
  ],
  exports: [
    LabelTransformPipe,
    SafeUrlPipe,
    BytesTransformPipe,
    FormatArrayPipe,
    ModalComponent,
    FirstWordPipe,
    TimezoneTransformPipe
  ]
})
export class SharedModule { }
