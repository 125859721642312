import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { NewsFeedService } from '../shared/news-feed.service';
import { NewsFeed, NewsFeedList } from '../shared/news-feed.model';

@Component({
  selector: 'app-news-feed-component',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 1500, noPause: true, showIndicators: true } }
  ]
})
export class NewsFeedComponent implements OnInit {
  customClass = 'newsFeed';
  isOpened = true;
  itemsPerSlide = 4;
  innerWidth: number;
  mobileBreakpoint = 480;
  tabBreakpoint = 980;
  isViewNewsFeed = false;
  newsFeeds: Array<NewsFeed> = [];
  newsFeed: NewsFeed;
  showFeeds = false;

  constructor( private newsFeedService: NewsFeedService, private router: Router,
               private renderer: Renderer2, private route: ActivatedRoute) {}

  ngOnInit() {
    this.adjustsItemsPerSlide();
    this.getNewsFeeds();
  }

  adjustsItemsPerSlide() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < this.mobileBreakpoint) {
      this.itemsPerSlide = 1;
    } else if (this.innerWidth < this.tabBreakpoint) {
      this.itemsPerSlide = 2;
    } else {
      this.itemsPerSlide = 4;
    }
  }

  getNewsFeeds() {
    this.newsFeedService.getNewsFeeds()
    .subscribe(data => {
        this.newsFeeds = new NewsFeedList().uiMapper(data).newsFeeds;
        this.showFeeds = this.newsFeeds.length > 0;
    });
  }

  getNewsFeed(index: number) {
    this.isViewNewsFeed = true;
    this.newsFeed = this.newsFeeds[index];
  }
}
