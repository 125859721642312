import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqContentComponent } from './faq-content/faq-content.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { QuillModule } from 'ngx-quill';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FAQService } from './faq-content/faq.service';

@NgModule({
  declarations: [FaqContentComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    AlertModule.forRoot()
  ],
  providers:[
    FAQService
  ]
})
export class FaqModule { }