import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropboxContainerComponent } from './dropbox-container/dropbox-container.component';
import { DropboxExplorerComponent } from './dropbox-explorer/dropbox-explorer.component';
import { DropboxService } from './shared/dropbox.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DropboxContainerComponent, DropboxExplorerComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers: [
    DropboxService
  ]
})
export class DropboxModule { }
