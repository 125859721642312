import { Component, OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MeasureServices } from '../services/measure.services';
import { MeasureModel } from '../models/measure.model';
import { formatDate } from '@angular/common';
import { GroupModel } from '../models/group.model';
import { GroupServices } from '../services/group.services';
import { SiteModel } from '../models/site.model';
import { SiteServices } from '../services/site.services';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss']
})
export class MeasureComponent implements OnInit {
  @ViewChild('confirmationModal', { static: false }) confirmationModalComponent: ModalComponent;
  @ViewChild('warningModal', { static: false }) warningModal: ModalComponent;
  managedMeasureList: Array<MeasureModel>;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageMeasure: Array<MeasureModel>;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  searchTerm: string;
  currentUserRole: Roles;
  isGroupExists: boolean;
  isNumberExists: boolean;
  isMeasureExists: boolean;
  selectedMeasure: MeasureModel;
  currentUser: any;
  lastSort: string;
  measureForm: FormGroup;
  measureList: Array<MeasureModel>;
  removedMeasureList: Array<MeasureModel>;
  managedGroupList: GroupModel[];
  groupList: Array<GroupModel>;
  manageGroups: Array<GroupModel>;
  selectedGroup: GroupModel;
  NumberAsc: boolean = true;
  measureAsc : boolean = true;
  groupAsc: boolean = true;
  lastUpdatedAsc:boolean = true;
  lastUpdatedDateTimeAsc:boolean = true;
  isViewOpened: boolean=false;
  buttonText:string = "Add Measure"; 
  MeasuresUsedSites : Array<SiteModel>;
  siteList: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  measureShortNameAsc : boolean = true;
  constructor(private measureService: MeasureServices,
    private stateService: StateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private groupService: GroupServices,
    private siteService:SiteServices,
    public datepipe: DatePipe) {
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }
  get isDataLead() { return this.stateService.getRole() === Roles.DataLead; }

  ngOnInit(): void {
    this.managedMeasureList = new Array<MeasureModel>();
    this.removedMeasureList = new Array<MeasureModel>();
    this.managedGroupList = new Array<GroupModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }

    this.getMeasures();
    this.measureForm = new FormGroup({
      group: new FormControl('', Validators.required),
      number: new FormControl(''),
      measure: new FormControl('', Validators.required),
      definition: new FormControl(''),
      measureShortName:new FormControl(''),
      site:new FormControl()
    });

    this.fetchGroups();
    this.getSites();
    this.measureForm.controls['measure'].valueChanges.subscribe(x => {
      let measure = this.measureForm.get('measure').value;
      if (measure != null && measure != '') {
        if (this.manageMeasure.length !== 0 || this.measureList.length !== 0) {
          let itemIndex = this.measureList.findIndex(x => x.measure.toLocaleLowerCase() == measure.toLocaleLowerCase());
          let manageGroupIndex = this.manageMeasure.findIndex(x => x.measure.toLocaleLowerCase() == measure.toLocaleLowerCase());
          if ((itemIndex >= 0 || manageGroupIndex >= 0) && this.selectedMeasure === null) {
            this.isMeasureExists = true;
          } else {
            this.isMeasureExists = false;
          }
        }
      }
    });
  }
  getSites() {
    this.siteService.getSites()
    .subscribe(data => {
      this.siteList =data;
        this.siteList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
        data.forEach(element => {
          this.dropdownList.push({item_id: element.id, item_text:element.name });
      });
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 2,
      allowSearchFilter: false,
      enableCheckAll:true,
      defaultOpen:true
    };
  }

  get enableSave(): boolean {
    if (this.measureList.length > 0) {
      return true;
    } else if (this.managedMeasureList.length > 0) {
      return true;
    }
    else if (this.removedMeasureList.length > 0) {
      return true;
    }
    return false;
  }


  toggleTag(column: string) {
    let value = "asc";
    if (column == 'number') {
      this.NumberAsc = !this.NumberAsc;
      value = this.NumberAsc ? "asc" : "desc"
    }
    else if(column == 'measure')
    {
      this.measureAsc = !this.measureAsc;
      value = this.measureAsc ? "asc" : "desc"
    }
    else if(column == 'group.name')
    {
      this.groupAsc = !this.groupAsc;
      value = this.groupAsc ? "asc" : "desc"
    }

    else if(column == 'lastUpdated')
    {
      this.lastUpdatedAsc = !this.lastUpdatedAsc;
      value = this.lastUpdatedAsc ? "asc" : "desc"
    }
    else if(column == 'lastUpdatedDateTime')
    {
      this.lastUpdatedDateTimeAsc = !this.lastUpdatedDateTimeAsc;
      value = this.lastUpdatedDateTimeAsc ? "asc" : "desc"
    }
    else if(column == 'measureShortName')
    {
      this.measureShortNameAsc = !this.measureShortNameAsc;
      value = this.measureShortNameAsc ? "asc" : "desc"
    }
   
      this.sortOn(column, value);
  }

  isValidMeasure() {
    const measureControl = this.measureForm.get('measure');
    if (measureControl.value && !measureControl.errors) {
      return true;
    }
  }

  getMeasures() {
    this.managedMeasureList.length = 0;
    this.removedMeasureList.length = 0;
    this.measureService.getMeasures()
      .subscribe(data => {
        this.measureList = data.filter(x=>{ return x.groupOrder!=15;});
        this.measureList.sort(this.fieldSorter(['groupOrder', 'number']));
        this.manageMeasure = this.measureList.slice(0, 10);
        this.currentPage = 1;
        this.totalRows = this.measureList.length;
      });
  }

  fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') { dir = -1; o = o.substring(1); }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
  }).reduce((p, n) => p ? p : n, 0);

  fetchGroups() {
    this.groupService.getGroup()
      .subscribe(data => {
        this.groupList = data;
        this.groupList.sort((nodeA, nodeB) => compareItems(nodeA['order'], nodeB['order']));
      });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    this.manageMeasure = this.measureList.slice(skipRows, this.currentPage * this.perPage);
  }

  resetAddForm() {
    this.selectedMeasure = null;
    this.isAddOpen = false;
    this.measureForm.reset();
  }

  confirmMeasuretoRemove(measure: MeasureModel) {
    if (measure) {
      this.removedMeasureList.push(measure);
      this.measureList = this.measureList.filter(x => x.measure !== measure.measure)
      let skipRows = this.currentPage == 0 ? this.currentPage : (this.currentPage - 1) * this.perPage;
      this.manageMeasure = this.measureList.slice(skipRows, this.currentPage == 0 ? 10 : this.currentPage * this.perPage);
      this.saveMeasure();
      this.closeConfirmModal();
    }
  }

  openConfirmationModal(measure: MeasureModel) {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.entityData = { entityData: null };
      this.confirmationModalComponent.showModal();
      this.defaultConfirmationModalEntity(measure);
    }
  }

  defaultConfirmationModalEntity(measure: MeasureModel) {
    this.confirmationModalComponent.entityData = {
      entityData: {
        deleteMeasure: measure,
      }
    };
  }

  updateConfirmationModalEntity(measureToDelete: MeasureModel, moderators: MeasureModel[]) {
    const users = moderators.filter(x => x.id !== measureToDelete.id);
    this.confirmationModalComponent.entityData = {
      entityData: {
        deleteMeasure: measureToDelete
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }

  addMeasure() {
    this.isAddOpen = true;
  }

  onOptionsSelected(value: number) {
    this.selectedGroup = this.groupList.filter(x => x.id === this.measureForm.get('group').value)[0];
  }

  AddUpdateMeasure(): void {
    if (this.selectedMeasure === null || this.selectedMeasure === undefined) {
      this.selectedMeasure = new MeasureModel(-(this.managedMeasureList.length + 1), 
                                                this.measureForm.get('number').value, 
                                                this.measureForm.get('measure').value, 
                                                this.selectedGroup, 
                                                this.currentUser.fullName, 
                                                this.measureForm.get('definition').value,
                                                this.measureForm.get('measureShortName').value);
    }
    this.selectedMeasure.measure = this.measureForm.get('measure').value;
    this.selectedMeasure.number = this.measureForm.get('number').value;
    this.selectedMeasure.definition = this.measureForm.get('definition').value;
    this.selectedMeasure.group = this.selectedGroup === undefined ? this.groupList.filter(g=> {return g.id == this.measureForm.get('group').value})[0] : this.selectedGroup;
    this.selectedMeasure.measureShortName =this.measureForm.get('measureShortName').value;
    this.selectedMeasure.sites=[];
    this.selectedItems.forEach(site=>{
      return this.selectedMeasure.sites.push(new SiteModel(site.item_id,site.item_text,true,formatDate(Date.now().toString(), "yyyy-MM-dd HH:mm", "en-US"),'',0,0,0,0));
    })
   
    let item = this.manageMeasure.find(item => item.id === this.selectedMeasure.id);
    let mitem = this.managedMeasureList.find(item => item.id === this.selectedMeasure.id);
    if (item || mitem) {
      if (item) {
        item.measure = this.selectedMeasure.measure;
        item.number = this.selectedMeasure.number;
        item.definition = this.selectedMeasure.definition;
        item.lastUpdatedDateTime = formatDate(Date.now().toString(), "yyyy-MM-dd HH:mm", "en-US");
      }

      if (mitem) {
        mitem.measure = this.selectedMeasure.measure;
        item.number = this.selectedMeasure.number;
        item.definition = this.selectedMeasure.definition;
      }
      else {
        this.managedMeasureList.push(this.selectedMeasure);
      }
    }
    else if (this.selectedMeasure.id < 0) {
      this.manageMeasure.push(this.selectedMeasure);
      this.managedMeasureList.push(this.selectedMeasure);
    }
    this.saveMeasure();
    this.resetAddForm();
  }

  padLeadingZeros(num, size): string {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  onItemSelect(item: any) {
  }

  onItemDeSelect(item: any){
  }
  saveMeasure(): void {
    if (this.managedMeasureList.length > 0 || this.removedMeasureList.length > 0) {
      this.managedMeasureList.map(s => {
        s.lastUpdatedDateTime = new Date().toJSON();
        s.group.lastUpdatedDateTime = new Date().toJSON();
        s.sites.map(site=>{ site.lastUpdatedDateTime = new Date().toJSON();})
        s.isActive = true;
        return s;
      });
      if (this.managedMeasureList.length > 0) {
        this.measureService.saveMeasures(this.managedMeasureList)
          .subscribe((data) => {
            this.formSubmitSuccess = "Successfully saved Measures";
            this.getMeasures();
          });

      }
      if (this.removedMeasureList.length > 0) {
        this.measureService.removeMeasure(this.removedMeasureList)
          .subscribe((data) => {
            this.formSubmitSuccess = "Successfully removed measures";
            this.getMeasures();
          });
      }
    }
  }

  removeMeasure(measure: MeasureModel): void {
    if (measure.id > 0) {
      this.measureService.validateBeforeDelete(measure.id)
        .subscribe((data: boolean) => {
          if (data) {
            this.openWarningModal();
          } else {
            this.openConfirmationModal(measure);
          }
        });
    }
    else {
      this.openConfirmationModal(measure);
    }
  }

  openWarningModal() {
    if (this.warningModal) {
      this.warningModal.showModal();
    }
  }

  closeWarningModal() {
    if (this.warningModal) {
      this.warningModal.closeModalWindow();
    }
  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }

  search(): void {
    if (this.searchTerm.length === 0) {
      this.currentPage = 0;
      this.manageMeasure = this.measureList.slice(this.currentPage, this.perPage);
      this.totalRows = this.measureList.length;
    }
    else {
      this.manageMeasure = this.measureList.filter(x => x.measure.toLowerCase().includes(this.searchTerm.toLowerCase())).slice();
      this.totalRows = this.manageMeasure.length;
    }
  }

  sortOn(column: string, type: string): void {
    if (column !== null) {
      if (type == "desc") {

        this.manageMeasure = this.measureList.sort((nodeA, nodeB) => compareItems(nodeB[column], nodeA[column]));

      } else {
        this.manageMeasure = this.measureList.sort((nodeA, nodeB) => compareItems(nodeA[column], nodeB[column]));
      }
      let skipRows = (this.currentPage - 1) * this.perPage;
      this.manageMeasure = this.measureList.slice(skipRows, this.currentPage * this.perPage);
      this.lastSort = column;
    }
  }

  editMeasure(measure: MeasureModel): void {
    this.selectedMeasure = measure;
    this.isAddOpen = true;
    this.measureForm.get('measure').setValue(measure.measure);
    this.measureForm.get('number').setValue(measure.number);
    this.measureForm.get('group').setValue(measure.group.id);
    this.measureForm.get('definition').setValue(measure.definition);
    this.measureForm.get('measureShortName').setValue(measure.measureShortName);
    this.buttonText="Save Measure";
    this.measureService.getListSitesUsedByMeasure(measure.id).subscribe(sites=>{
      this.selectedItems =[];
      sites.forEach(site=>{
        this.selectedItems.push({item_id: site.id, item_text:site.name});
      });
    });
  }
  viewSites(measure: MeasureModel): void {
    this.selectedMeasure = measure;
    this.measureService.getListSitesUsedByMeasure(measure.id).subscribe(x=>{
    this.MeasuresUsedSites =x;
    this.isViewOpened=true;
    });
  }
  cancelViewSites(){
    this.isViewOpened=false;
  }
  exportMeasure():void{
    this.measureService.exportMeasures().subscribe(data => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    FileSaver.saveAs(data, 'measures_list.xlsx');
  });
}
}


