import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { compareItems } from 'src/app/common/models/utility';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { ImportSiteDataResults } from '../models/measure.model';
import { SiteModel } from '../models/site.model';
import { DataCollectionsServices } from '../services/datacollections.services';
import { SiteServices } from '../services/site.services';
import { Router } from "@angular/router";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-import-data-model',
  templateUrl: './import-data-model.component.html',
  styleUrls: ['./import-data-model.component.scss']
})
export class ImportDataModelComponent implements OnInit {

  uploader: FileUploader;
  sitedataImportForm: FormGroup;
  extentionRegEx = /(?:\.([^.]+))?$/;
  message: string;
  uploadContentType = 'files';
  showQueueAlert = false;
  modalRef: BsModalRef;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<any>();
  @ViewChild('confirmationDialog', {static: false}) contentTemplate;
  hasDropZoneOver = false;
  siteList:Array<SiteModel>;
  SelectedSite:string;
  filename:string;
  isUploadSuccess:boolean=false;
  siteId:number;
  apiResponseData:any;
  importSucceeded:boolean=false;
  constructor(private router: Router,
    private modalService: BsModalService,
    private siteService: SiteServices,
    public googleAnalyticsService: GoogleAnalyticsService,
    private dataCollectionsServices:DataCollectionsServices,private repository: RepositoryService)
  {
  }

  ngOnInit(): void {
      this.sitedataImportForm = new FormGroup({
        file:new FormControl('', Validators.required),
      });
      this.initFileUpload();
      this.getSites();
      this.siteId = (JSON.parse(window.sessionStorage.getItem('site')).id);
  }

  showModal() {
    this.importSucceeded=false;
    this.modalRef = this.modalService.show(
      this.contentTemplate,
      {
        ignoreBackdropClick: true,
        class: 'modal-sg delete-modal'
      }
    );
  }

  getSites() {
    this.siteService.getSites()
    .subscribe(data => {
      this.siteList =data;
      this.siteList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
      let results = data.filter(a=>a.id==this.siteId)[0];
      this.SelectedSite = results.name;
    });
  }


  uploadFile(){
    this.dataCollectionsServices.uploadSiteDataFile(this.sitedataImportForm.get('file').value,this.siteId)
    .subscribe((data) => {
      this.apiResponseData = data;
      this.importSucceeded = true;
      this.navigateToMeasureApprove();
      console.log('File upload success');
    });
  }

  navigateToMeasureApprove(){
    this.router.navigate(['/measureApprove']
      // , {queryParams: {Id: this.apiResponseData.body}}
    );
  }

  downloadImportSiteDataTemplate(){
      window.open('https://aecfdevstorage.blob.core.windows.net/jjdata-resources/Site_Data_Bulk_Upload_Updated.xlsx');
  }

  initUpload(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.sitedataImportForm.get('file').setValue(file);
      this.sitedataImportForm.get('file').updateValueAndValidity();
      console.log(file);
      this.filename = file.name;
    }
  }

  initFileUpload() {
    this.dataCollectionsServices.initBulkUploader(this.siteId);
    this.uploader = this.dataCollectionsServices.bulkUploader;
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      const fileName = fileItem.file.name;
      const extention = this.extentionRegEx.exec(fileName)[1].toLowerCase();
      this.googleAnalyticsService
                  .eventEmitter( 'File Upload - Import site measure data', fileName, 'Import Site Data');
        };

    this.uploader.onAfterAddingAll = (fileItems: any) => {
        this.googleAnalyticsService.eventEmitter('File Upload Count', "1", 'Import Site Data');
    };

    this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any, options: any) => {
      switch (filter.name) {
        case 'queueLimit':
          this.message = 'You can upload up to ' + this.dataCollectionsServices.queueLimit + ' ' +  this.uploadContentType;
          break;
        case 'fileSize':
          this.message = 'You can upload up to ' + this.formatBytes(this.dataCollectionsServices.maxFileSize);
          break;
        case 'fileType':
          this.message = 'You can upload only excel ';
          break;
        default:
          this.message = 'Failed to Upload ';
          break;
      }
      this.googleAnalyticsService.eventEmitter('File Upload Failed - Bulk User Upload', fileItem.name + ' / ' + this.message, 'Manage Users');
      this.showQueueAlert = true;
    };
  }

  fileOver(event: any) {
    this.hasDropZoneOver = event;
    console.log(event);
  }


  private formatBytes(bytes, decimals?) {
    if (bytes === 0) { return '0 Bytes'; }
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onCancel(): void {
    this.closeModal.next(false);
  }

  Cancel(): void {
    this.modalService.hide(1);
    document.body.classList.remove('modal-open');
  }

  onClosedAlert() {
    this.showQueueAlert = false;
  }
}
