<div class="submit-resource container-fluid">
  <div>
    <span calss="submit-resource-title"><h1>Submit Resource</h1></span>
    <span class="submit-resource-subtitle">Submit a resource (Data Files or Links) by following the steps below.</span>
  </div>
  <aw-wizard #contentWizard [awNavigationMode] navigateForward="visited">
      <aw-wizard-step #selectSource stepTitle="Select Resource" awSelectedStep stepId="start">
        <div class="centered-content">
          <div>
              <app-content-file-upload [contentForm]="contentUploadForm" (contentUpdated)="isContentUpdated($event)" [contentSubmitted]=navigateToTagsSubmitted [uploadCompleted]=uploadCompleted (tabSelected)="selectTab($event)"></app-content-file-upload>
          </div>  
          <div class="btn-group" *ngIf="contentAdded">
            <button type="button" class="btn btn-secondary button-style next-button" awNextStep (click)="navigateToTags()">Next</button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step #chooseTags [stepTitle]="'Choose Tags'" stepId="chooseTags"> 
        <div class="centered-content">
          <div *ngIf="contentAdded">
              <app-blob-metadata #blobMetadataView [contentForm]="contentUploadForm" [contentSubmitted]=contentSubmitted [uploadCompleted]=uploadCompleted [tabSelected]=tabSelected [blobFileTypes]=blobFileTypes></app-blob-metadata>
          </div>  
          <div class="btn-group">
            <button type="button" class="btn btn-secondary button-style back-button" awPreviousStep (click)="stepBack()">Back</button>
            <button type="button" class="btn btn-secondary button-style next-button" awNextStep (click)="navigateToContentView()">Next</button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step [stepTitle]="'Review'" stepId="review" (stepEnter)="reviewStep($event)">
        <div class="centered-content">
          <div>
              <ng-template [ngTemplateOutlet]="contentViewTemplate"
                [ngTemplateOutletContext]="{contentData: contentData, showProgress:false}">
              </ng-template>
          </div>
    
          <div class="btn-group">
            <button type="button" class="btn btn-secondary button-style back-button" awPreviousStep>Back</button>
            <button type="button" class="btn btn-secondary button-style next-button" [ngClass]="{ 'disable-color': viewAsAdmin | async }" awNextStep>Next</button>
          </div>
        </div>
      </aw-wizard-step>
      <aw-wizard-step #completion [stepTitle]="'Upload'" stepId="finish" (stepEnter)="progressStep($event)" (stepExit)="exitCompletion($event)">
        <div class="centered-content">
          <div *ngIf="completionStep">
            <ng-template [ngTemplateOutlet]="contentViewTemplate"
              [ngTemplateOutletContext]="{contentData: contentData, showProgress:true}">
            </ng-template>
          </div>
          <div class="btn-group">
            <button *ngIf="backToContentView" type="button" class="btn btn-secondary button-style back-button" awPreviousStep>Back</button>
            <button *ngIf="uploadCompleted" type="button" class="btn btn-secondary button-style finish-button" (click)="closeWizard()">Submit</button>
          </div>
        </div>
      </aw-wizard-step>
  </aw-wizard>
</div>
<ng-template #contentViewTemplate let-data="contentData" let-showProgress="showProgress">
    <app-content-view #contentView [contentData]="data" [showProgress]="showProgress" (uploadCompletedEvent)="isUploadCompleted($event)" (backToReviewStepEvent)="goBackToReviewStep($event)" [tabSelected]=tabSelected></app-content-view>
</ng-template>