<div class="delete-confirmation-container">
    <div class="content-style">
        <p>Are you sure you want to remove {{fileName}}?
        </p>
    </div>
    <div class="col-sm-12 footer-style">
        <div>
            <button type="button" class="btn button-style" style="width: 100px;" (click)="onCancel()">
            <span>Cancel</span>
            </button>
        </div>
        <div class="savebtn">
            <button (click)="onDelete()" class="btn button-style" style="width: 100px;">
            <span>Delete</span>
            </button>
        </div>
    </div>
</div>