import { Component,OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MeasureServices } from '../services/measure.services';
import { GroupModel } from '../models/group.model';
import { GroupServices } from '../services/group.services';
import { SiteModel } from '../models/site.model';
import { SiteServices } from '../services/site.services';
import { CustomMeasureModel } from '../models/custommeasure.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-custom-measures',
  templateUrl: './custom-measures.component.html',
  styleUrls: ['./custom-measures.component.scss']
})
export class CustomMeasuresComponent implements OnInit {
  @ViewChild('confirmationModal', {static: false}) confirmationModalComponent: ModalComponent;
  @ViewChild('warningModal', {static: false}) warningModal: ModalComponent;
  managedMeasureList: Array<CustomMeasureModel>;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageMeasure: Array<CustomMeasureModel>;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  searchTerm: string;
  currentUserRole: Roles;
  isNumberExists:boolean;
  selectedMeasure: CustomMeasureModel;
  currentUser: any;
  lastSort:string;
  measureForm: FormGroup;
  measureList: Array<CustomMeasureModel>;
  removedMeasureList: Array<CustomMeasureModel>;
  managedGroupList: GroupModel[];
  groupList:  Array<GroupModel>;
  manageGroups:  Array<GroupModel>;
  groupForm: FormGroup;
  selectedGroup: GroupModel;
  selectedSite:SiteModel;
  NumberAsc: boolean = true;
  measureAsc : boolean = true;
  groupAsc: boolean = true;
  siteAsc: boolean = true;
  lastUpdatedAsc:boolean = true;
  lastUpdatedDateTimeAsc:boolean = true;
  siteList:Array<SiteModel>;
  isNew:boolean;
  buttonText:string = "Add Measure"; 
  isValidMeasureNumber:boolean;
  isValiGroup:boolean;
  // ageAtTimeOfOptions:Array<Object> = ["Alleged Offense","Start of Intervention"];

  constructor(private measureService: MeasureServices,
    private siteService: SiteServices,
    private stateService: StateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private groupService: GroupServices,
    public datepipe: DatePipe) { 
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }

  get isDataLead() { return this.stateService.getRole() === Roles.DataLead; }

  ngOnInit(): void {
    this.managedMeasureList = new Array<CustomMeasureModel>();
    this.removedMeasureList = new Array<CustomMeasureModel>();
    this.managedGroupList = new Array<GroupModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }

    this.measureForm = new FormGroup({
      group:new FormControl('', [Validators.required]),
      site:new FormControl('', Validators.required),
      measure:new FormControl('', Validators.required),
      definition:new FormControl(''),
      comments:new FormControl(),
      shortMeasure:new FormControl('', Validators.required)
      // ,ageAtTimeOf: new FormControl('0',[Validators.required])
    });
    
    this.getCustomMeasures();
  }
  
  get enableSave():boolean{
    if(this.measureList.length>0){
      return true;
    }
    else if(this.managedMeasureList.length>0){
      return true;
    }
    else if(this.removedMeasureList.length>0){
      return true;
    }
    return false;
  }
  
  isValidMeasure() {
    const measureControl = this.measureForm.get('measure');
    if (measureControl.value && !measureControl.errors) {
      return true;
    }
  }

  getSites() {
    this.siteService.getSites()
    .subscribe(data => {
      this.siteList =data;
      if(!this.isAdmin){
        let currentUserSites =  this.currentUser.sites as SiteModel[];
        let results = currentUserSites.map(s=>s.id);
        this.siteList= this.siteList.filter(s=>results.includes(s.id));
      }
        this.siteList.sort((nodeA, nodeB) => compareItems(nodeA['name'], nodeB['name']));
    });
    this.fetchGroups();
  }
  
  exportCustomMeasures(){
    this.measureService.exportCustomMeasures().subscribe(data=>{
      const blob = new Blob([data], { type: 'application/octet-stream' });
      FileSaver.saveAs(data,  +'custom_measure_list.xlsx');
    });
  }

  getCustomMeasures() {
    this.managedMeasureList.length=0;
    this.removedMeasureList.length=0;
    this.measureService.getCustomeMeasures()
    .subscribe(data => {
      this.measureList =data;
        this.measureList.sort(this.fieldSorter(['groupOrder', 'measure']));
        this.manageMeasure = this.measureList.slice(0, 10);
        this.currentPage=1;
        this.totalRows = this.measureList.length;
        this.getSites();
    });
  }

  fieldSorter = (fields) => (a, b) => fields.map(o => {
      let dir = 1;
      if (o[0] === '-') { dir = -1; o=o.substring(1); }
      return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
  }).reduce((p, n) => p ? p : n, 0);

  fetchGroups() {
    this.groupService.getGroup()
    .subscribe(data => {
      this.groupList =data;
        this.groupList.sort((nodeA, nodeB) => compareItems(nodeA['order'], nodeB['order']));
    }); 
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    this.manageMeasure = this.measureList.slice(skipRows, this.currentPage*this.perPage);
  }

  resetAddForm() {
    this.selectedMeasure = null;
    this.isAddOpen = false;
    this.managedMeasureList.length=0;
    this.measureForm.reset();
  }

  confirmMeasuretoRemove(measure: CustomMeasureModel) {
    this.closeConfirmModal();
    if (measure) {
        this.removedMeasureList.push(measure);
          if(this.removedMeasureList.length>0){
            this.saveMeasure();
        }
    }
  }

  openConfirmationModal(measure: CustomMeasureModel) {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.entityData = { entityData: null };
      this.confirmationModalComponent.showModal();
      this.defaultConfirmationModalEntity(measure);
    }
  }

  defaultConfirmationModalEntity(measure: CustomMeasureModel) {
    this.confirmationModalComponent.entityData = { entityData: {
        deleteMeasure: measure,
      }
    };
  }

  closeConfirmModal() {
    if (this.confirmationModalComponent) {
      this.confirmationModalComponent.closeModalWindow();
    }
  }

  addMeasure() {
    this.isAddOpen = true;
    this.isNew =true;
    this.manageGroups = this.isNew ?  this.groupList.filter(x=>x.order==15) :this.groupList;
    this.measureForm.get('site').setValue(0);
    this.measureForm.get('group').setValue(this.manageGroups[0].id);
    // this.measureForm.get('ageAtTimeOf').setValue(0);
  }

  onSiteSelected(value:number){
    this.selectedSite = this.siteList.filter(x => x.id === this.measureForm.get('site').value)[0]; 
  }

  AddUpdateMeasure(isApproved:boolean, isRejected:boolean):void {
    this.selectedGroup = this.groupList.filter(x => x.id === this.measureForm.get('group').value)[0]; 
    if(this.measureForm.get('group').value===0 && !isRejected){
      this.measureForm.get('group').setErrors({'incorrect': true});
      this.isValiGroup =true;
      return;
    }
    this.isValiGroup=false;
    if(this.selectedMeasure === null || this.selectedMeasure === undefined) {
        this.selectedMeasure = new CustomMeasureModel(-(this.managedMeasureList.length+1),
        this.measureForm.get('measure').value,this.selectedGroup,
        this.currentUser.fullName,
        this.measureForm.get('definition').value,
        isApproved,
        isRejected,
        this.measureForm.get('comments').value,
        this.measureForm.get('shortMeasure').value,
        this.selectedSite
        // ,this.measureForm.get('ageAtTimeOf').value             
        );
    }
    else {
        this.selectedMeasure.measure = this.measureForm.get('measure').value;
        this.selectedMeasure.definition = this.measureForm.get('definition').value;
        this.selectedMeasure.comments = this.measureForm.get('comments').value;
        this.selectedMeasure.shortMeasure = this.measureForm.get('shortMeasure').value;
        this.selectedMeasure.group =this.selectedGroup == undefined ?this.selectedMeasure.group : this.selectedGroup;
        // this.selectedMeasure.ageAtTimeOf = this.measureForm.get('ageAtTimeOf').value;
    }
    this.selectedMeasure.isApproved =isApproved;
    this.selectedMeasure.isRejected =isRejected;
    this.managedMeasureList.push(this.selectedMeasure);
    this.saveMeasure();
    this.resetAddForm();
  }

  padLeadingZeros(num, size):string {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

  saveMeasure() :void {
    if(this.managedMeasureList.length>0 || this.removedMeasureList.length>0) {
      this.managedMeasureList.map(s=>{
        s.lastUpdatedDateTime = new Date().toJSON();
        s.group.lastUpdatedDateTime = new Date().toJSON();
        return s;
      });
      if(this.managedMeasureList.length>0) {
        if(this.isDataLead || this.isNew){
            this.measureService.saveCustomeMeasures(this.managedMeasureList)
            .subscribe(() => {
            this.formSubmitSuccess = "Successfully saved custom measures";
            this.getCustomMeasures();
         });
          return;
      }
      else if (this.isAdmin || !this.isNew)
      {
          this.measureService.ApproveRejectCustomMeasures(this.managedMeasureList)
            .subscribe((data) => {
              this.formSubmitSuccess = "Successfully saved custom measures";
              this.getCustomMeasures();
          },
          (error)=>{
            console.log(error);
          });
      }
  }
    if(this.removedMeasureList.length>0){
      this.measureService.removeCustomeMeasure(this.removedMeasureList)
       .subscribe((data) => {
        this.formSubmitSuccess = "Successfully removed custom measures";
        this.getCustomMeasures();
     });
    }
  }
}


  removeMeasure(measure: CustomMeasureModel):void{
    this.openConfirmationModal(measure);
  
  }

  openWarningModal() {
    if (this.warningModal) {
      this.warningModal.showModal();
    }
  }

  closeWarningModal(){
    if (this.warningModal) {
      this.warningModal.closeModalWindow();
    }
  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }
 
  search():void{
    if(this.searchTerm.length===0){
      this.currentPage=0;
      this.manageMeasure = this.measureList.slice(this.currentPage, this.perPage);
      this.totalRows = this.measureList.length;
    }
    else{
      this.manageMeasure = this.measureList.filter(x=> x.measure.toLowerCase().includes(this.searchTerm.toLowerCase())).slice(this.currentPage, this.perPage);
      this.totalRows = this.manageMeasure.length;
    }
  }


  toggleTag(column: string) {
    let value = "asc";
    if(column == 'measure')
    {
      this.measureAsc = !this.measureAsc;
      value = this.measureAsc ? "asc" : "desc"
    }
    else if(column == 'group.name')
    {
      this.groupAsc = !this.groupAsc;
      value = this.groupAsc ? "asc" : "desc"
    }
    else if(column == 'site.name')
    {
      this.siteAsc = !this.siteAsc;
      value = this.siteAsc ? "asc" : "desc"
    }
    else if(column == 'lastUpdated')
    {
      this.lastUpdatedAsc = !this.lastUpdatedAsc;
      value = this.lastUpdatedAsc ? "asc" : "desc"
    }
    else if(column == 'lastUpdatedDateTime')
    {
      this.lastUpdatedDateTimeAsc = !this.lastUpdatedDateTimeAsc;
      value = this.lastUpdatedDateTimeAsc ? "asc" : "desc"
    }
      this.sortOn(column, value);
  }

  sortOn(column: string, type: string): void {
    if (column !== null) {
      if (type == "desc") {
        this.manageMeasure = this.measureList.sort((nodeA, nodeB) => compareItems(nodeB[column], nodeA[column]));
      } else {
        this.manageMeasure = this.measureList.sort((nodeA, nodeB) => compareItems(nodeA[column], nodeB[column]));
      }

      let skipRows = (this.currentPage - 1) * this.perPage;
      this.manageMeasure = this.measureList.slice(skipRows, this.currentPage * this.perPage);
      this.lastSort = column;
    }
  }

  async editMeasure(measure:CustomMeasureModel):Promise<void> {
    this.selectedMeasure = measure;
    this.isAddOpen=true;
    this.isNew=false;
    //this.manageGroups = !this.isNew && this.isAdmin ? this.groupList.filter(x=>x.order!=15) : this.groupList.filter(x=>x.order==15);
    this.manageGroups = this.groupList;
   await delay(1000);
    this.measureForm.get('measure').setValue(measure.measure);
    // if(this.isAdmin){
    //   this.measureForm.get('group').setValue(0);
    // }else{
    //   this.measureForm.get('group').setValue(measure.group.id);
    // }
    this.measureForm.get('group').setValue(measure.group.id);
    // this.measureForm.get('ageAtTimeOf').setValue(measure.ageAtTimeOf==null?0:measure.ageAtTimeOf);
    this.measureForm.get('site').setValue(measure.site.id);
    this.measureForm.get('definition').setValue(measure.definition);
    this.measureForm.get('comments').setValue(measure.comments);
    this.measureForm.get('shortMeasure').setValue(measure.shortMeasure);
    this.buttonText="Save Measure";
 }
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}