import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reporting-landing',
  styleUrls: [],
  template: `
    <div class="prelogin-reporting-imgcontainer">
        <div class="heading">
          <h1>Casey’s Analytics and Reporting Center</h1>
        </div>
    </div>`
})
export class ReportingLandingComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(environment.reportingPageTitle);
  }

}
