import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './common/footer/footer.component';
import { SearchResultsModule } from './search-results/search-results.module';
import { SearchModule} from './search/search.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { HelpModule } from './help/help.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { ReportModule } from './reports/report.module';
import { CoreModule } from './core/core.module';
import { ContentModule } from './content/content.module';
import { AccountModule } from './account/account.module';
import { GlobalErrorHandler } from './core/error-handler/global-error-handler';
import { AppInsightService } from './common/logging/appinsight.service';
import { HomeModule } from './home/home.module';
import { TopNavComponent } from './common/top-nav/top-nav.component';
import { SharedModule } from './shared/shared.module';
import { FavoritesModule } from './favorites/favorites.module';
import { LandingComponent } from './auth/landing/landing.component';
import { AllLandingComponent } from './auth/landing/all-landing.component';
import { ReportingLandingComponent } from './auth/landing/reporting-landing.component';
import { JdaiLandingComponent } from './auth/landing/jdai-landing.component';
import { VisionLandingComponent } from './auth/landing/vision-landing.component';
import { NotAuthorizedPageComponent } from './common/not-authorized-page/not-authorized-page.component';
import { NewsFeedModule } from './news-feed/news-feed.module';
import { GoogleAnalyticsService } from './core/services/google/google-analytics.service';
import { BoxModule } from './box/box.module';
import { FtlaLandingComponent } from './auth/landing/ftla-landing.component';
import { AuditLoggingModule } from './audit-logging/audit-logging.module';
import { DropboxModule } from './dropbox/dropbox.module';
import { PortalConfigModule } from './portal-config/portal-config.module';
import { JcyoiLandingComponent } from './auth/landing/jcyoi-landing.component';
import { ReportConfigurationModule } from './report-config/report-config.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { FaqModule } from './faq/faq.module';
import { GrantsLandingComponent } from './auth/landing/grants-landing.component';
const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadConfig();
  };
};

let oktaAuth = new OktaAuth(config.oidc);

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { PortalConfigHeaderComponent } from './common/header/portalconfig-header.component';
import { PortalConfigLandingComponent } from './auth/landing/portalconfig-landing.component';
import config from './app.config';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  OKTA_CONFIG,
  OktaAuthModule
} from '@okta/okta-angular';
import { HeaderNewComponent } from './common/header/header-new/header-new.component';
import { AuthNewServiceComponent } from './auth/auth-new-service/auth-new-service.component';
import { ConfigurationService } from './common/services/configuration.service';
import { JJModule } from './jj/jj.module';
import { FtlaModule } from './ftla/ftla.module';
import { JJLandingComponent } from './auth/landing/jj-landing.component';
import { JjDataPreLoginComponent } from './jj-data-pre-login/jj-data-pre-login.component';
// import { JobdescriptionsComponent } from './ftla/jobdescriptions/jobdescriptions.component';
// import { OrganizationprofileComponent } from './ftla/organizationprofile/organizationprofile.component';
// import { TechnologiesComponent } from './ftla/technologies/technologies.component';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId, 
      authority: 'https://login.microsoftonline.com/'+environment.tenantId, 
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent,
    PortalConfigHeaderComponent,
    FooterComponent,
    TopNavComponent,
    LandingComponent,
    AllLandingComponent,
    JJLandingComponent,
    ReportingLandingComponent,
    JdaiLandingComponent,
    VisionLandingComponent,
    FtlaLandingComponent,
    JcyoiLandingComponent,
    NotAuthorizedPageComponent,
    GrantsLandingComponent,
    PortalConfigLandingComponent,
    HeaderNewComponent,
    AuthNewServiceComponent,
    JjDataPreLoginComponent
    // JobdescriptionsComponent
    // OrganizationprofileComponent
        
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OktaAuthModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    SearchModule,
    FavoritesModule,
    SearchResultsModule,
    CoreModule,
    SharedModule,
    HomeModule,
    NewsFeedModule,
    ReportModule,
    HelpModule,
    ContentModule,
    AccountModule,
    BoxModule,
    DropboxModule,
    AuditLoggingModule,
    PortalConfigModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ReportConfigurationModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FaqModule,
    MsalModule,
    JJModule,
    FtlaModule
    ],
  providers: [
    BnNgIdleService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigurationService]
    },
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    AppInsightService,
    GoogleAnalyticsService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
