import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portalconfig-landing',
  styleUrls: [],
  template: `
    <div class="prelogin-imgcontainer">
    </div>`
})
export class PortalConfigLandingComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(environment.portalConfigPageTitle);
  }
}
