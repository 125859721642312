import { resourceUsage } from "process";
import { GroupModel } from "./group.model";
import { MeasureModel } from "./measure.model";
import { SiteModel } from "./site.model";
import { SiteMeasureModel } from "./siteMeasure.model";

export class DataCollectionsModel {
    id: number;
    number: string;
    measure:MeasureModel;
    SiteMeasure:SiteMeasureModel;
    group:GroupModel;
    year: number;
    timePeriodType: string;
    timePeriod: number;
    calendarPeriod: number;
    calendarDisplay: string;
    latino: string;
    genderFemale:number | null;
    genderMale:number | null;
    genderNA:number | null;
    genderNonBinary:number | null;
    ethnicityHispanic:number | null;
    ethnicityNonHispanic:number | null;
    ethnicityNA:number | null;
    raceAIAKNative:number | null;
    raceAsian:number | null;
    raceBAA:number | null;
    raceHispanic:number | null;
    raceWhite:number | null;
    raceOther:number | null;
    racesTwoOrMore:number | null;
    racesNA:number | null;
    isActive: boolean;
    ethnicityValue:number | null;
    genderValue:number | null;
    raceValue:number | null;
    total:number;
    // ageGroup:string;
    comments: string;
    constructor(id:number,
                number?:string,
                measure?:MeasureModel,
                SiteMeasure?:SiteMeasureModel,
                group?:GroupModel,
                year?:number,
                timePeriodType?: string,
                timePeriod?: number,
                calendarPeriod?: number,
                calendarDisplay?: string,
                latino?:string,
                genderFemale?:number,
                genderMale?:number,
                genderNA?:number,
                genderNonBinary?:number,
                ethnicityHispanic?:number,
                ethnicityNonHispanic?:number,
                ethnicityNA?:number,
                raceAIAKNative?:number,
                raceAsian?:number,
                raceBAA?:number,
                raceHispanic?:number,
                raceWhite?:number,
                raceOther?:number,
                racesTwoOrMore?:number,
                racesNA?:number,
                isActive?:boolean,
                genderValue?:number,
                ethnicityValue?:number,
                raceValue?:number,
                total?:number,
                // ageGroup?:string,
                comments?: string){
        this.id=id;
        this.number=number;
        this.measure = measure;
        this.group = group;
        this.year = year;
        this.timePeriodType = timePeriodType,
        this.timePeriod = timePeriod,
        this.calendarPeriod = calendarPeriod,
        this.calendarDisplay = calendarDisplay,
        this.latino = latino;
        this.genderFemale = genderFemale;
        this.genderMale = genderMale;
        this.genderNA = genderNA;
        this.genderNonBinary =genderNonBinary;
        this.ethnicityHispanic = ethnicityHispanic;
        this.ethnicityNonHispanic = ethnicityNonHispanic;
        this.ethnicityNA = ethnicityNA;
        this.raceAIAKNative = raceAIAKNative;
        this.raceAsian = raceAsian;
        this.raceBAA = raceBAA;
        this.raceHispanic = raceHispanic;
        this. raceWhite = raceWhite;
        this.raceOther = raceOther;
        this.racesTwoOrMore = racesTwoOrMore;
        this.racesNA = racesNA;
        this.isActive = isActive;
        this.SiteMeasure = SiteMeasure;
        this.genderValue = genderValue;
        this.ethnicityValue= ethnicityValue;
        this.raceValue = raceValue;
        this.total = total;
        // this.ageGroup = ageGroup;
        this.comments = comments;
    }
}

export class DataCollectionsSiteResponse {
  dataCollection:string;
  response: string;
  site:SiteModel;
  constructor(dataCollection:string,siteResponse: string,site:SiteModel)
      {
          this.dataCollection= dataCollection;
          this.response= siteResponse;
          this.site =site;
      }
}

export enum TimePeriodType {
    Annual = "Annual",
    Quarterly = "Quarterly",
    Monthly = "Monthly"
}

export enum Month {
    Jan = 1,
    Feb = 2,
    Mar = 3,
    Apr = 4,
    May = 5,
    Jun = 6,
    Jul = 7,
    Aug = 8,
    Sep = 9,
    Oct = 10,
    Nov = 11,
    Dec = 12
}

export const Month2LabelMapping: Record<Month, string> = {
    [Month.Jan]: "Jan",
    [Month.Feb]: "Feb",
    [Month.Mar]: "Mar",
    [Month.Apr]: "Apr",
    [Month.May]: "May",
    [Month.Jun]: "Jun",
    [Month.Jul]: "Jul",
    [Month.Aug]: "Aug",
    [Month.Sep]: "Sep",
    [Month.Oct]: "Oct",
    [Month.Nov]: "Nov",
    [Month.Dec]: "Dec"
};

export enum Quarter {
    JanToMar = 1,
    AprToJun = 2,
    JulToSep = 3,
    OctToDec = 4
}

export const Quarter2LabelMapping: Record<Quarter, string> = {
    [Quarter.JanToMar]: "Jan-Mar",
    [Quarter.AprToJun]: "Apr-Jun",
    [Quarter.JulToSep]: "Jul-Sep",
    [Quarter.OctToDec]: "Oct-Dec",
};
