import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { SearchService } from '../search-results/shared/search.service';
import { mergeMap} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { GoogleAnalyticsService  } from './../core/services/google/google-analytics.service';
import { StateService } from '../core/state-manager/appstateservice';

@Component({
  selector: 'app-search-component',
  templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() resultSubmitted: boolean;
  @Input() hideSearchByFilter: boolean;
  searchForm: FormGroup;
  submitted = false;
  isHomePage = true;
  searchQuery: string;
  isAllGroup = false;

  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSource: Observable<any>;
  isVisionGroup = false;
  constructor(private searchService: SearchService,
              private formBuilder: FormBuilder,
              private router: Router,
              private currentState: StateService,
              private renderer: Renderer2,
              private route: ActivatedRoute,
              private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit() {
    console.log("test1");
    this.isAllGroup = this.currentState.isAllGroup();
    this.isVisionGroup = this.currentState.isThriveGroup();
    const shareResultQueryid = this.route.snapshot.params['queryId'];
    if (shareResultQueryid) {
      localStorage.removeItem('searchQuery');
      this.searchService.emitSearchRouteEvent({sharedResults: true, sharedResultId: shareResultQueryid});
      this.router.navigate(['/search']);
    }

    const routeParams = this.searchService.getSearchRouteState();
    if (routeParams && routeParams.externalLink) {
      localStorage.removeItem('searchQuery');
    }
    let searchQuery = ''; // localStorage.getItem('searchQuery');
    // tslint:disable-next-line: triple-equals
    if (searchQuery == '*') {
      searchQuery = '';
    }

    this.searchForm = this.formBuilder.group({
      searchQuery: [(this.router.url.split('#')[0] === '/search') ? searchQuery : '']
    });
    if (this.router.url.split('#')[0] === '/search' || this.router.url.split('#')[0] === '/content/remove') {
      this.isHomePage = false;
      this.renderer.addClass(document.body, 'innerPages');
    }

    this.searchForm.controls.searchQuery.patchValue(searchQuery);
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next();
    }).pipe(mergeMap((token: string) => this.filterResults(token)));
  }

  // fired every time search string is changed
  filterResults(token: string) {
    return this.searchService.getSuggestions(this.searchForm.controls.searchQuery.value);
  }

  // Show loading indicator
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  // Selected value event
  typeaheadOnSelect(e: TypeaheadMatch): void {}

  // convenience getter for easy access to form fields
  get formFields() { return this.searchForm.controls; }

  get checkLogin() {
    //if (this.oauthService.hasValidIdToken()) {
      return true;
    //}
  }

  onSubmit(byFilters = false) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.searchForm.invalid || !this.checkLogin) {
        return;
    }
    let searchQuery = this.searchForm.controls.searchQuery.value;
    if (!searchQuery) {
      searchQuery = '*';
    }
    localStorage.setItem('searchQuery', searchQuery);

    if (this.isHomePage) {
      if (byFilters) {
        this.googleAnalyticsService.eventEmitter('SearchByFilters', searchQuery, 'Search Criteria');
      } else {
        this.googleAnalyticsService.eventEmitter('SearchByQuery', searchQuery, 'Search Criteria');
      }
    } else {
      this.googleAnalyticsService.eventEmitter('Search Query', searchQuery, 'Search Criteria');
    }

    this.googleAnalyticsService.pageViewTrack('/search?query=' + searchQuery, 'Search Page');

    // const rootRoute = localStorage.getItem('rootRoute');
    // console.log(window.location.href);
    this.searchService.emitSearchEvent(searchQuery, true);
    if (this.router.url=="/ftlahome") {
      if(this.isVisionGroup)
        {this.router.navigate(['/thrive-search']); return;}
      this.router.navigate(['/search']);
      this.searchService.emitSearchEvent(searchQuery, true);
    } else {
      this.searchService.emitSearchEvent(searchQuery, true);
    }
  }
}
