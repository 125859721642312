<div class="imgcontainer" [ngStyle]="portalbannerConfig?{'background': 'url(' + bgImage + ') no-repeat center center fixed', 'background-size': 'cover'  }:''">
  <div class="overcontainer">
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-8">
        <div class="heading">
          <div class="headingContent">
              <div class="mainheading">
                <h1>{{title}}</h1>
              </div>
          </div>
          <div class="headingContent">
            <p>Begin your search below to access our online resources</p>
          </div>
          <div class="searchbar" *ngIf="ischeckPermissions(permissions.Search)">
            <app-search-component></app-search-component>
          </div>        
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
  
  <app-news-feed-component *ngIf="ischeckPermissions(permissions.ViewNewsfeed)" ></app-news-feed-component>
</div>
