import { Injectable, QueryList } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RepositoryService } from 'src/app/core/services/http/repository.service';
import { NewsFeed } from './news-feed.model';

@Injectable()
export class NewsFeedService {

  constructor(private repository: RepositoryService) { }

  getNewsFeeds() {
    return this.repository.get(environment.apiBaseURL, 'api/Newsfeed');
  }

  getNewsFeed(id: number): Observable<any> {
    return this.repository.get(environment.apiBaseURL, 'api/Newsfeed/' + id);
  }

  deleteNewsFeed(id: number): Observable<boolean> {
    return this.repository.delete(environment.apiBaseURL, 'api/Newsfeed/' + id, {});
  }

  saveNewsFeed(newsfeed: NewsFeed) {
    const model = new NewsFeed().uiMapper(newsfeed);
    if (newsfeed.id) {
      return this.repository.put(environment.apiBaseURL, 'api/Newsfeed', model);
    } else {
      return this.repository.post(environment.apiBaseURL, 'api/Newsfeed', model);
    }
  }
}
