import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { environment } from "src/environments/environment";
import { SiteDataCollection, SiteModel } from "../models/site.model";

@Injectable()
export class SiteServices {

  constructor(private repository: RepositoryService) { }

  getSites() : Observable<SiteModel[]> {
    return this.repository.get(environment.apiBaseURL, 'api/site').pipe(map(s=>{
      s.lastUpdatedDateTime = new Date(s.lastUpdatedDateTime)
      return s;
    }));
  }

  saveSites(data: FormData): Observable<any> {
    return this.repository.upload(environment.apiBaseURL, 'api/site', data, false);
  }
   
  removeSite(site: SiteModel[]): Observable<any> {
    return this.repository.delete(environment.apiBaseURL, 'api/site', site);
  }

  saveSiteDataCollection(sitedc: SiteDataCollection): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/site/datacollection', sitedc);
  }

  getSiteDataCollection() : Observable<SiteDataCollection> {
    return this.repository.get(environment.apiBaseURL, 'api/site/datacollection');
  }

  validateBeforeDelete(siteId:number): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/site/hasmeasures/'+ siteId);
  }

  exportSites(): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/site/export/','blob');
  }

  getListOfStates(): Observable<any> {
    return this.repository.get(environment.apiBaseURL,'api/site/stateList');
  }
}