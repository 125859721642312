<div class="search-widget">
  <h4>Resource of the Day</h4>
  <hr/>

<div *ngIf="isDocumentReady" class="search-results-row">
  <div class="row row_padding">
      <label *ngIf="document.fileTitle== 'Not Specified'">{{ document.fileName | titlecase }} </label>
      <label *ngIf="document.fileTitle!= 'Not Specified'">{{ document.fileTitle  }} </label>
    <div class="tags_container">
       <ng-container>
        <label class="selectedFacet Rectangle" *ngIf="document.programArea[0]!= 'Not Specified'">{{ document.programArea[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.topic[0]!= 'Not Specified'">{{ document.topic[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.contentType[0]!= 'Not Specified'">{{ document.contentType[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.fileType[0]!= 'Not Specified'">{{ document.fileType[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.continent[0]!= 'Not Specified'">{{ document.continent[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.country[0]!= 'Not Specified'">{{ document.country[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.division[0]!= 'Not Specified'">{{ document.division[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.region[0]!= 'Not Specified'">{{ document.region[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.state[0]!= 'Not Specified'">{{ document.state[0] }}</label>
        <label class="selectedFacet Rectangle" *ngIf="document.jurisdiction[0]!= 'Not Specified'">{{ document.jurisdiction[0] }}</label>
      </ng-container> 
    </div>
    <p class="author_details"> Uploaded {{ document.uploadedDate | date: 'MMM d, y hh:mm a' }} by {{ document.uploadedBy }} </p>
    <div class="col-md-12 result-description" *ngIf="document.description && document.description != 'Not Specified'">
        {{document.description}}
    </div>
  </div>
  </div>  
</div>
