<div [formGroup]="contentForm" class="choose-tags">
  <div>
    <div class="row header">
        <div  class="col-sm-4">
            <span *ngIf="formFileFields.controls.length > 0 && linkFields.controls.length == 0">File Name</span>
            <span *ngIf="linkFields.controls.length > 0">Link</span>
        </div> 
        <div  class="col-sm-4">
            Display Title
        </div>
        <div  class="col-sm-4"></div>
    </div>
    <div formArrayName="formFilesField" *ngIf="linkFields.controls.length == 0">
        <div class="row" *ngFor="let formGroupItem of formFileFields.controls; let i=index">
            <div [formGroup]="formGroupItem" [ngClass]="{ 'disable': formFieldGroup(formGroupItem).isUploaded }">
                <div  class="col-sm-4 file-item">
                    <span class="file-type-icon"><em *ngIf="formFieldGroup(formGroupItem).fileAttributes" class="{{ formFieldGroup(formGroupItem).fileAttributes.cssClass }} fa-sm"></em></span>
                    <span class="file-item-span">{{formFieldGroup(formGroupItem).file.name}}</span>
                </div>   
                <div class="col-sm-4 display-container">  
                  <div class="display-text">                
                    <input id="titleText{{i}}" type="text" placeholder="KC Title" class="form-control" formControlName="kcTitleField" [ngClass]="{ 'invalid': hasGroupFieldError(formGroupItem, 'kcTitleField') }" maxlength="250">
                  </div>
                </div>
                <div class="col-sm-4 uploadModalContainer tag-container">
                  <div class="col-md-4">
                    <div class="btn-group" dropdown [insideClick]="true" [isDisabled]="isDataset">
                      <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle file-type-option" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasGroupFieldError(formGroupItem, 'fileTypeField') }">
                        <span class="required">*</span>File Type<i class='dropDownIcon fas fa-angle-down'></i>
                      </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li *ngFor="let item of blobFileTypes" role="menuitem">
                          <input type="checkbox" (change)="onFileTypeTagValueChange(item.fileType, formGroupItem, 'fileTypeField', $event.target.checked)" [checked]="fileTypeSelected(item.fileType, formGroupItem, 'fileTypeField')"> {{ item.fileType }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div id="itemAreaList">
                      <label *ngFor="let item of formGroupItem.controls.fileTypeField?.value" class="Rectangle" (click)="onFileTypeTagValueChange(item, formGroupItem, 'fileTypeField', false)">{{ item }}  <i class='close_icon fas fa-times'></i></label>
                    </div>
                  </div>
                </div>         
            </div>
        </div>
    </div>
    <div formArrayName="linkField">
      <div class="row" *ngFor="let linkGroupItem of linkFields.controls; let i=index">
        <div [formGroup]="linkGroupItem" [ngClass]="{ 'disable': linkFieldGroup(linkGroupItem).isUploaded }">
          <div class="col-sm-4 file-item">
              <span class="file-type-icon"><em *ngIf="linkFieldGroup(linkGroupItem).fileAttributes" class="{{ linkFieldGroup(linkGroupItem).fileAttributes.cssClass }} fa-sm"></em></span>
              <span class="file-item-span">{{linkFieldGroup(linkGroupItem).linkValue}}</span>
          </div>  
          <div class="col-sm-4 display-container">   
              <div class="display-text">                    
                <input id="displayText{{i}}" type="url" placeholder="URL" class="form-control" formControlName="kcTitleField"  [ngClass]="{ 'invalid': hasGroupFieldError(linkGroupItem, 'kcTitleField') }" maxlength="250">                    
              </div>
          </div> 
          <div class="col-sm-4 uploadModalContainer tag-container">  
            <div class="col-md-4">
              <div class="btn-group" dropdown [insideClick]="true">
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle file-type-option" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasGroupFieldError(linkGroupItem, 'fileTypeField') }">
                  <span class="required" *ngIf="!isDataset">*</span>File Type<i class='dropDownIcon fas fa-angle-down'></i>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li *ngFor="let item of blobFileTypes" role="menuitem">
                    <input type="checkbox" (change)="onFileTypeTagValueChange(item.fileType, linkGroupItem, 'fileTypeField', $event.target.checked)" [checked]="fileTypeSelected(item.fileType, linkGroupItem, 'fileTypeField')"> {{ item.fileType }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-8">
              <div id="itemAreaList">
                <label *ngFor="let item of linkGroupItem.controls.fileTypeField?.value" class="Rectangle" (click)="onFileTypeTagValueChange(item, linkGroupItem, 'fileTypeField', false)">{{ item }}  <i class='close_icon fas fa-times'></i></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
        <div class="line"></div>
    </div>
  </div>
  <div  class="moderator-content" *ngIf="showAccess != false">
    <div class="moderator-content-div">
      <span class="caption">Moderator: </span><span>{{formFields.moderator.value && formFields.moderator.value.fullName}}</span>
    </div>
    <div>
        <div class="line"></div>
    </div> 
  </div>  
  <div class="row" *ngIf="!isDataset">
    <div class="col-md-12">
      <span>Add Tags  <i class="fa fa-tag" aria-hidden="true"></i></span>
    </div>
  </div>
  <div [ngClass]="{ 'disable': uploadCompleted }" *ngIf="!isDataset">
    <div class="uploadModalContainer tag-container">
      <div class="row" *ngIf="isAllGroup || isFtlaGroup">
        <div class="tag-content location-tag" [ngClass]="[isAllGroup || isFtlaGroup ? 'col-md-3' : 'col-md-2']">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic"  *ngIf="isAllGroup" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic"  [ngClass]="{ 'invalid': hasFieldError('topic') || hasFieldError('programArea') }">
              <span class="required" *ngIf="isAllGroup">*</span>{{ allMetadataTags.topic }} <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <button id="button-basic" *ngIf="isFtlaGroup" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic"  [ngClass]="{ 'invalid': hasFieldError('topic') || hasFieldError('programArea') }">
              <span class="required" >*</span>{{ ftlaMetadataTags.topic }} <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu topic-meta location-sub-tag" role="menu" aria-labelledby="button-basic">
              <li dropdown role="menuitem" *ngFor="let item of topicsList" role="menuitem" [insideClick]="true">
                <input type="checkbox" (change)="onTopicTagValueChange(item.topic, 'topic', $event.target.checked, item)" [checked]="tagSelected(item.topic, 'topic')">
                <span *ngIf="item.programs.length > 0" dropdownToggle class="dropdown-toggle"> {{item.topic}}<i class='dropDownIcon fas fa-angle-down'></i></span>
                <span *ngIf="item.programs.length == 0" class="dropdown-toggle"> {{item.topic}}</span>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem" *ngFor="let program of item.programs">
                    <input type="checkbox" (change)="onTopicTagValueChange(program.program, 'programArea', $event.target.checked, item)" [checked]="tagSelected(program.program, 'programArea')"> {{program.program}}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="text-small text-danger" *ngIf="isAllGroup && (hasFieldError('topic') || hasFieldError('programArea'))">Select Competency area and Sub-Competency area</div>
          <div class="text-small text-danger" *ngIf="isFtlaGroup && (hasFieldError('topic') || hasFieldError('programArea'))">Select topic and topic sub area</div>
        </div>
        <div [ngClass]="[isAllGroup || isFtlaGroup ? 'col-md-9' : 'col-md-10']">
          <div id="itemAreaList">
            <label *ngFor="let item of formFields['topic']?.value" class="Rectangle" (click)="onTopicTagValueChange(item, 'topic', false)">{{ item }}  <i class='close_icon fas fa-times'></i></label>
            <label *ngFor="let item of formFields['programArea']?.value" class="Rectangle" (click)="onTopicTagValueChange(item, 'programArea', false)">{{ item }}  <i class='close_icon fas fa-times'></i></label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!(isAllGroup || isFtlaGroup)">
        <div class="tag-content col-md-2">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('topic') }">
              <span class="required" *ngIf="!isDataset">*</span> Topic <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li *ngFor="let item of topicsList" role="menuitem">
                <input type="checkbox" (change)="onTagValueChange(item.topic, 'topic', $event.target.checked)" [checked]="tagSelected(item.topic, 'topic')"> {{ item.topic }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10">
          <div id="itemAreaList">
              <label *ngFor="let item of formFields['topic']?.value" class="Rectangle" (click)="onTagValueChange(item, 'topic', false)">{{ item }} <i class='close_icon fas fa-times'></i></label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!(isAllGroup || isFtlaGroup)">
        <div class="tag-content col-md-2">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('programArea') }">
              <span class="required" *ngIf="!isDataset">*</span> Program <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li *ngFor="let item of programsList" role="menuitem">
                <input type="checkbox" (change)="onTagValueChange(item.program, 'programArea', $event.target.checked)" [checked]="tagSelected(item.program, 'programArea')"> {{ item.program }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10">
          <div id="itemAreaList">
              <label *ngFor="let item of formFields['programArea']?.value" class="Rectangle" (click)="onTagValueChange(item, 'programArea', false)">{{ item }} <i class='close_icon fas fa-times'></i></label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="tag-content location-tag" [ngClass]="[isAllGroup || isFtlaGroup ? 'col-md-3' : 'col-md-2']">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('contentType') }">
              <span class="required" *ngIf="!isDataset">*</span> Content Type <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li *ngFor="let item of contentTypeList" role="menuitem">
                <input type="checkbox" (change)="onTagValueChange(item.contentType, 'contentType', $event.target.checked)" [checked]="tagSelected(item.contentType, 'contentType')"> {{ item.contentType }}
              </li>
            </ul>
          </div>
        </div>
        <div  [ngClass]="[isAllGroup || isFtlaGroup ? 'col-md-9' : 'col-md-10']">
          <div id="itemAreaList">
              <label *ngFor="let item of formFields['contentType']?.value" class="Rectangle" (click)="onTagValueChange(item, 'contentType', false)">{{ item }} <i class='close_icon fas fa-times'></i></label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="tag-content location-tag" *ngIf="!isThriveGroup" [ngClass]="[isAllGroup || isFtlaGroup ? 'col-md-3' : 'col-md-2']">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('privacyType') }">
              <span class="required" *ngIf="!isDataset">*</span> Privacy Level<i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li *ngFor="let item of privacyTypeList" role="menuitem">
                <input type="checkbox" (change)="onTagValueChange(item.privacyType, 'privacyType', $event.target.checked)" [checked]="tagSelected(item.privacyType, 'privacyType')"> {{ item.privacyType }}
              </li>
            </ul>
          </div>
        </div>
        <div  [ngClass]="[isAllGroup || isFtlaGroup ? 'col-md-9' : 'col-md-10']">
          <div id="itemAreaList">
              <label *ngFor="let item of formFields['privacyType']?.value" class="Rectangle" (click)="onTagValueChange(item, 'privacyType', false)">{{ item }} <i class='close_icon fas fa-times'></i></label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isThriveGroup">
        <div class="tag-content location-tag" [ngClass]="'col-md-2'">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasLocationFieldError() }">
              <span class="required" *ngIf="!isDataset">*</span> Location Type <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li *ngFor="let item of locationdata" role="menuitem">
                <input type="checkbox" (change)="onTagValueChange(item.county, 'jurisdiction', $event.target.checked)" [checked]="locationTagSelected(item.county, 'jurisdiction')"> {{ item.county }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10">
          <div id="itemAreaList">
              <label *ngFor="let item of formFields['jurisdiction']?.value" class="Rectangle" (click)="onTagValueChange(item.item, 'jurisdiction', false)">{{ item.item }} <i class='close_icon fas fa-times'></i></label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!isThriveGroup">
      <!-- <ng-container *ngFor="let metaDataItem of blobMetaDataItems">
        <div class="row" *ngIf="(!isAllGroup || (isAllGroup && metaDataItem != 'community' && metaDataItem != 'location')) && metaDataItem != 'topic' && metaDataItem != 'programArea' && metaDataItem != 'community' && metaDataItem != 'publisher'"> -->
          <div class="tag-content" *ngIf="false; else locationTag" [ngClass]="[isAllGroup ? 'col-md-3' : 'col-md-2']">
            <div class="btn-group" dropdown [insideClick]="true" [isDisabled]="isAllGroup && metaDataItem == 'community'">
              <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError(metaDataItem) }">
                <span class="required" *ngIf="!isDataset && metaDataItem != 'publisher'">*</span>{{ metadataTags[metaDataItem] }} <i class='dropDownIcon fas fa-angle-down'></i>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li *ngFor="let item of blobMetaData[metaDataItem]" role="menuitem">
                  <input type="checkbox" (change)="onTagValueChange(item, metaDataItem, $event.target.checked)" [checked]="tagSelected(item, metaDataItem)"> {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div [ngClass]="[isAllGroup || isFtlaGroup ? 'col-md-9' : 'col-md-10']">
            <div id="itemAreaList">
                <!-- <label *ngFor="let item of formFields[metaDataItem]?.value" class="Rectangle" (click)="onTagValueChange(item, metaDataItem, false)">{{ item }} <i class='close_icon fas fa-times'></i></label>
                <ng-container *ngIf="metaDataItem === 'location'"> -->
                  <ng-container *ngFor="let locationDataItem of locationsTags">
                      <label *ngFor="let locationItem of formFields[locationDataItem]?.value" class="Rectangle" (click)="removeTagItem(locationItem, locationDataItem)">{{ locationItem.item }}  <i class='close_icon fas fa-times'></i></label>
                  </ng-container>  
                <!-- </ng-container>             -->
            </div>
          </div>
        <!-- </div>
      </ng-container> -->
      </div>
      <div class="row" *ngIf="isAllGroup">
        <div class="tag-content" [ngClass]="[isAllGroup ? 'col-md-3' : 'col-md-2']">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasFieldError('publisher') }">
              Publisher <i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li *ngFor="let item of publishersList" role="menuitem">
                <input type="checkbox" (change)="onTagValueChange(item.publisher, 'publisher', $event.target.checked)" [checked]="tagSelected(item.publisher, 'publisher')"> {{ item.publisher }}
              </li>
            </ul>
          </div>
        </div>
        <div [ngClass]="[isAllGroup ? 'col-md-9' : 'col-md-10']">
          <div id="itemAreaList">
              <label *ngFor="let item of formFields['publisher']?.value" class="Rectangle" (click)="onTagValueChange(item, 'publisher', false)">{{ item }} <i class='close_icon fas fa-times'></i></label>
          </div>
        </div>
      </div>
      <div class="row " *ngIf="isAllGroup">
        <div class="tag-content" [ngClass]="[isAllGroup ? 'col-md-3' : 'col-md-2']">
          <div class="btn-group" dropdown [insideClick]="true">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic"  [ngClass]="{ 'invalid': hasFieldError('publicationDate') }">
              Publication Date<i class='dropDownIcon fas fa-angle-down'></i>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu accessFor-dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li role="menuitem">
                <div class="datecol">
                  <input class="form-control datapicker" [(bsValue)]="bsPublicationValue" #drp="bsDatepicker" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateValueChange($event)">
              </div>
              </li>
            </ul>
          </div>
        </div>
        <div [ngClass]="[isAllGroup ? 'col-md-9' : 'col-md-10']">
          <div id="itemAreaList" *ngIf="formFields.publicationDate && formFields.publicationDate.value">
              <label class="Rectangle">{{ formFields.publicationDate.value | date:'MM/dd/yyyy hh:mm a' }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <span class="required-text"><span class="required">*</span> required</span>
    </div>
    <div>
      <div>
          <div class="line"></div>
      </div>
      <span>
        <textarea id="content-comments" columns="12" rows="4" class="form-control comments" formControlName="description" placeholder="Other Information"></textarea>
      </span>
    </div>
  </div>
</div>

<ng-template #locationTag>
  <ng-container *ngIf="isFtlaGroup; else locationTagOther" >
    <div class="tag-content location-tag" [ngClass]="[isFtlaGroup ? 'col-md-3' : 'col-md-2']">
      <div dropdown  class="btn-group" [insideClick]=true #dropdown="bs-dropdown" >
        <button id="button-basic" *ngIf="isFtlaGroup && !isDataset" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasLocationFieldError() }">
          <span></span>Location <i class='dropDownIcon fas fa-angle-down'></i>
        </button>
        <button id="button-basic" *ngIf="!isFtlaGroup && !isDataset" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasLocationFieldError() }">
          <span class="required" *ngIf="!isFtlaGroup && !isDataset">*</span>Location <i class='dropDownIcon fas fa-angle-down'></i>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu location-sub-tag" role="menu" aria-labelledby="button-basic">
          <li dropdown *ngFor="let continentItem of locationMetaData" role="menuitem" [insideClick]="true">
            <input type="checkbox" (change)="onLocationTagValueChange(continentItem.continent, 'continent', $event.target.checked)" [checked]="locationTagSelected(continentItem.continent, 'continent')">
            <span *ngIf="continentItem.countryList.length > 0" dropdownToggle class="dropdown-toggle"> {{ continentItem.continent }}<i class='dropDownIcon fas fa-angle-down'></i></span>
            <span *ngIf="continentItem.countryList.length == 0" class="dropdown-toggle"> {{ continentItem.continent }}</span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li dropdown *ngFor="let countryItem of continentItem.countryList" role="menuitem" [insideClick]="true">
                <input type="checkbox" (change)="onLocationTagValueChange(countryItem.country, 'country', $event.target.checked, continentItem.continent)" [checked]="locationTagSelected(countryItem.country, 'country')">
                <span *ngIf="countryItem.regionList.length == 0" class="dropdown-toggle"> {{ countryItem.country }}</span>
                <span *ngIf="countryItem.regionList.length > 0" dropdownToggle class="dropdown-toggle"> {{ countryItem.country }}<i class='dropDownIcon fas fa-angle-down'></i></span>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li dropdown *ngFor="let regionItem of countryItem.regionList" role="menuitem" [insideClick]="true">
                    <input type="checkbox" (change)="onLocationTagValueChange(regionItem.region, 'region', $event.target.checked, continentItem.continent, countryItem.country)" [checked]="locationTagSelected(regionItem.region, 'region')">
                    <span dropdownToggle class="dropdown-toggle">{{ regionItem.region }}<i class='dropDownIcon fas fa-angle-down'></i></span>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li dropdown *ngFor="let divisionItem of regionItem.divisionList" role="menuitem" [insideClick]="true">
                        <input type="checkbox" (change)="onLocationTagValueChange(divisionItem.division, 'division', $event.target.checked, continentItem.continent, countryItem.country, regionItem.region)" [checked]="locationTagSelected(divisionItem.division, 'division')">
                        <span dropdownToggle class="dropdown-toggle">{{ divisionItem.division }}<i class='dropDownIcon fas fa-angle-down'></i></span>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li dropdown *ngFor="let stateItem of divisionItem.stateList" role="menuitem" [insideClick]="true">
                            <input type="checkbox" (change)="onLocationTagValueChange(stateItem.state, 'state', $event.target.checked, continentItem.continent, countryItem.country, regionItem.region, divisionItem.division)" [checked]="locationTagSelected(stateItem.state, 'state')">
                            <span dropdownToggle class="dropdown-toggle">{{ stateItem.state }}<i class='dropDownIcon fas fa-angle-down'></i></span>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                              <li *ngFor="let countyItem of stateItem.countyList" role="menuitem">
                                <input type="checkbox" (change)="onLocationTagValueChange(countyItem.county, 'jurisdiction', $event.target.checked, continentItem.continent, countryItem.country, regionItem.region, divisionItem.division, stateItem.state)" [checked]="locationTagSelected(countyItem.county, 'jurisdiction')">{{ countyItem.county }}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #locationTagOther>
  <div class="tag-content location-tag" [ngClass]="[isAllGroup ? 'col-md-3' : 'col-md-2']">
    <div dropdown  class="btn-group" [insideClick]="true" #dropdown="bs-dropdown" [isDisabled]="isAllGroup">
      <button id="button-basic" dropdownToggle type="button" (click)="resetsearch();" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic" [ngClass]="{ 'invalid': hasLocationFieldError() }">
        <span class="required" *ngIf="!isDataset" >*</span>Location <i class='dropDownIcon fas fa-angle-down'></i>
      </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu location-sub-tag mr-auto" role="menu" aria-labelledby="button-basic" [isOpen]="isExpand" >
        <li dropdown *ngFor="let countryItem of locationMetaData" role="menuitem" [insideClick]="true" [isOpen]="isExpand">
          <input type="text" autocomplete="off" #txtsearch id="txtlocationsearch" placeholder="Search Location"  class="form-control" [(ngModel)]="searchValue" (input)="onLocationSearch();" />
          <input type="checkbox" (change)="onLocationTagValueChange(countryItem.country, 'country', $event.target.checked)" [checked]="locationTagSelected(countryItem.country, 'country')">
          <span dropdownToggle class="dropdown-toggle" aria-expanded="false"> {{ countryItem.country }}<i class='dropDownIcon fas fa-angle-down'></i></span>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" [isOpen]="isExpand">
            <li dropdown *ngFor="let regionItem of countryItem.regionList" role="menuitem" [insideClick]="true" [isOpen]="isExpand" >
              <input type="checkbox" (change)="onLocationTagValueChange(regionItem.region, 'region', $event.target.checked, null, countryItem.country)" [checked]="locationTagSelected(regionItem.region, 'region')">
              <span dropdownToggle class="dropdown-toggle" aria-expanded="false">{{ regionItem.region }}<i class='dropDownIcon fas fa-angle-down'></i></span>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" [isOpen]="isExpand">
                <li dropdown *ngFor="let divisionItem of regionItem.divisionList" role="menuitem" [insideClick]="true" [isOpen]="isExpand">
                  <input type="checkbox" (change)="onLocationTagValueChange(divisionItem.division, 'division', $event.target.checked, null, countryItem.country, regionItem.region)" [checked]="locationTagSelected(divisionItem.division, 'division')">
                  <span dropdownToggle class="dropdown-toggle" aria-expanded="false">{{ divisionItem.division }}<i class='dropDownIcon fas fa-angle-down' [isOpen]="isExpand"></i></span>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li dropdown *ngFor="let stateItem of divisionItem.stateList" role="menuitem" [insideClick]="true" [isOpen]="isExpand" >
                      <input type="checkbox" (change)="onLocationTagValueChange(stateItem.state, 'state', $event.target.checked, null, countryItem.country, regionItem.region, divisionItem.division)" [checked]="locationTagSelected(stateItem.state, 'state')">
                      <span dropdownToggle class="dropdown-toggle" aria-expanded="false">{{ stateItem.state }}<i class='dropDownIcon fas fa-angle-down'></i></span>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" >
                        <li *ngFor="let countyItem of stateItem.countyList" role="menuitem" >
                          <input type="checkbox" (change)="onLocationTagValueChange(countyItem.county, 'jurisdiction', $event.target.checked, null, countryItem.country, regionItem.region, divisionItem.division, stateItem.state)" [checked]="locationTagSelected(countyItem.county, 'jurisdiction')">{{ countyItem.county }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    
    </div>
  </div>
</ng-template>
<ng-template #VisionlocationTag>

</ng-template>