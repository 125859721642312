<div class="container manage-users">
  <div class="heading d-flex">
      <div class="d-flex left-heading row no-print">
          <div class="col-md-12">
              <label class="title">Manage Survey Periods</label>
          </div>
        </div>
  <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
      <div class="col-md-12">
          <div class="alert alert-danger" id="formSubmitError">
          {{ formSubmitError }}
          </div>
      </div>
  </div>
</div>
  <div class="row no-print">
      <div class="col-12 table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Survey</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let survey of pagedSurveyPeriods; let rowIndex=index;">
              <td>{{survey.periodName}}</td>
              <td><input type="checkbox" id="surveyCheck" [checked]="survey.isActive" (change)="checkSurveyPeriod(survey,$event)"></td>
          </tbody>
        </table>
      </div>
      <div class="col-md-8 no-print">
        <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)"  [boundaryLinks]="true" [maxSize]="10"></pagination>
      </div>    
      <div class="col-md-4 px-5 py-20 no-print">
        <div class="col-xs-4 px-5">
          <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
        </div>
        <div class="col-xs-4 px-5">
          <button type="button" id="btnsave" class="btn btn-secondary button-style" [disabled]="surveyPeriods.length===0" (click)="saveSurveyPeriods()" [ngClass]="{ 'disable-color': viewAsAdmin }">Save</button>
        </div>
      </div>
    </div>
  <div class="clearfix">
      <div class="pull-right">
          <div *ngIf="formSubmitSuccess">
              <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
          </div>
      </div>
  </div>
</div>