<div class="container manage-users">

    <div class="heading d-flex">
            <div class="page-title">
                <div>
                    <span calss="page-title"><h1>Manage Measure</h1></span>
                </div>
            </div>
        <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
            <div class="col-md-12">
                <div class="alert alert-danger" id="formSubmitError">
                {{ formSubmitError }}
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="search_icon_profile">
            <i class="fas fa-search"></i>
        </div>
        <div class="searchBoxForm_profile">
                <input type="text" id="searchTerm" name="searchTerm" placeholder="Search measures..." autocomplete="off"
                             [(ngModel)]="searchTerm" (keyup)="search()"  class="searchBox_Profile" />
        </div>
        <button type="button"  id="btnAddMeasure" class="btn btn-success button-style" hidden="hidden" (click)="addMeasure()">Add Measure</button>
        <button type="button"  id="btnExportMeasure" class="btn btn-success button-style" (click)="exportMeasure()">Export Measures</button>
    </div>
    
    <div class="row">
        <div class="col-12 table-responsive">
            <table class="table table-striped sortable" id="printableArea">
                <thead>
                    <tr>
                      <th *ngIf="!isDataLead" style="width:10px;"></th>
                      <th *ngIf="!isDataLead" style="width:10px;"></th>
                      <th style="width:10px;"><div style="display:inline-flex">Number <a (click)="toggleTag('number')" [hidden]="NumberAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!NumberAsc" (click)="toggleTag('number')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                      <th style="width:150px;"><div style="display:inline-flex">Measure <a (click)="toggleTag('measure')" [hidden]="measureAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!measureAsc" (click)="toggleTag('measure')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                      <th style="width:150px;"><div style="display:inline-flex">Short Name<a (click)="toggleTag('measureShortName')" [hidden]="measureShortNameAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!measureShortNameAsc" (click)="toggleTag('measureShortName')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                      <th style="width:150px;"><div style="display:inline-flex">Group<a (click)="toggleTag('group.name')" [hidden]="groupAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!groupAsc" (click)="toggleTag('group.name')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                      <th style="width:100px;"><div style="display:inline-flex">Last Updated<a (click)="toggleTag('lastUpdated')" [hidden]="lastUpdatedAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedAsc" (click)="toggleTag('lastUpdated')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                      <th style="width:150px;"><div style="display:inline-flex">Last Updated Date Time<a (click)="toggleTag('lastUpdatedDateTime')" [hidden]="lastUpdatedDateTimeAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!lastUpdatedDateTimeAsc" (click)="toggleTag('lastUpdatedDateTime')" ><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
                    </tr>
                  </thead>
                
                <tbody>
                <tr *ngFor="let measure of manageMeasure; let rowIndex=index;">
                <td title="delete measure" *ngIf="!isDataLead">
                        <div class="delete-icon" [ngClass]="{ 'disabled':  viewAsAdmin }">
                        <span *ngIf="isAdmin" class="role-icon" (click)="removeMeasure(measure)">
                            <em class="far fa-trash-alt"></em>
                        </span>  
                    </div>
                </td>
                <td title="edit measures" *ngIf="!isDataLead">
                    <div class="delete-icon" [ngClass]="{ 'disabled': viewAsAdmin }">
                    <span *ngIf="isAdmin" class="role-icon"  (click)="editMeasure(measure, rowIndex)">
                        <i class="far fa-edit"></i>
                    </span>  
                    </div>
                </td>
                    <td>{{padLeadingZeros(measure.number,2)}}</td>
                    <td><a title="{{measure.definition}}" class="viewmeasure"  (click)="viewSites(measure)">{{measure.measure}}</a></td>
                    <td *ngIf="isAdmin">{{measure.measureShortName}}</td>
                    <td *ngIf="isAdmin">{{measure.group.name}}</td>
                    <td *ngIf="isAdmin">{{measure.lastUpdated}}</td>
                    <td *ngIf="isAdmin">{{measure.lastUpdatedDateTime | timezone:'MM/DD/YYYY hh:mm A' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="row" *ngIf="manageMeasure?.length>0">
        <div class="col-md-7" >
            <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)" [(ngModel)]="currentPage"  [rotate]="false"  [boundaryLinks]="true" [maxSize]="10"></pagination>
        </div>     
        <div class="col-md-3 pull-right padding-2p">
                <div class="col-xs-12 px-5">
                    <button type="button" id="btncancel" class="btn btn-secondary button-style back-button" [routerLink]="['/']">Cancel</button>
                </div>
        </div>
    </div>
    <div class="clearfix">
        <div class="pull-right">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="manageMeasure?.length===0">
        <div class="col-md-4" ></div>
        <div class="col-md-4" >  <p>No Measure Found.</p></div>
        <div class="col-md-4" ></div>
    </div>
    <div class="add-form" *ngIf="isViewOpened">
        <a class="close" (click)="cancelViewSites()"><i class="fa fa-times"></i></a>
        <div><b>{{selectedMeasure.measure}} is used by below site(s) </b> </div>
        <div class="responsive-table">
            <table class="table table-striped sortable" id="printableArea">
                    <tbody>
                        <tr *ngFor="let site of MeasuresUsedSites; let rowIndex=index;">
                            <td>{{site.name}}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    
    <div class="add-form" *ngIf="isAddOpen">
        <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
        <div class="form-group">
            <form [formGroup]="measureForm">
                <div>Add/Edit Measure </div>
                   <label for="txt-role">Group</label>
                        <div>
                        <select formControlName="group" class="form-control" required (change)='onOptionsSelected($event.target.value)'>
                            <option value = 0> Select group</option>
                            <option *ngFor="let group of groupList; let rowIndex=index;" [ngValue]="group.id" [selected]="group.name == this"> 
                               {{group.name}} 
                            </option>
                        </select>
                        </div>  
                    <div *ngIf="false">Number 
                    <input formControlName="number" id="txtNumber" type="text" placeholder="Number"class="form-control"> 
                    </div>
                    <div>Measure
                    <textarea formControlName="measure" id="txtareaMeasure" placeholder="Measure" name="txtareaMeasure" rows="2" class="form-control"required></textarea>
                    </div>
                    <div>Measure Short Name
                        <input formControlName="measureShortName" id="txtshortMeasure" type="text" placeholder="Measure short name"class="form-control"> 
                    </div>
                    <div>Definition
                    <textarea formControlName="definition" id="txtareaDefinition" placeholder="Definition" name="txtareaDefinition" rows="4" class="form-control"></textarea>
                    </div>
                    <div>  Active/Inactive Measures - Select Sites
                        <ng-multiselect-dropdown formControlName="site"
                                    [placeholder]="'Select sites'"
                                    [settings]="dropdownSettings"
                                    [data]="dropdownList"
                                    [(ngModel)]="selectedItems"
                                    (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="onItemDeSelect($event)"
                            >
                        </ng-multiselect-dropdown>
                    </div>
            </form>
        </div>
        
        <div *ngIf="isGroupExists">
            <span class="alert alert-danger">Group already exists.</span>
        </div>
    
        <div *ngIf="isNumberExists">
            <span class="alert alert-danger">Number already exists.</span>
        </div>
    
        <div *ngIf="isMeasureExists">
            <span class="alert alert-danger">Measure already exists.</span>
        </div>
    
        <div class="col-md-4 px-5">
            <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateMeasure()"[disabled]="measureForm.invalid" >{{buttonText}}</button>
        </div>
    </div>
    
    <div class="backdrop" *ngIf="isAddOpen"></div>
    
    <app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
        <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
            <div class="col-sm-12 content-style">
                <div *ngIf="dataItem">
                    <span>Are you sure you want to delete {{dataItem.deleteMeasure.measure}}?</span>
                </div>
            </div>
    
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                        <span>Cancel</span>
                    </button>
                </div>
    
                <div class="savebtn">
                    <button type="submit" class="btn button-style" (click)="confirmMeasuretoRemove(dataItem.deleteMeasure)">
                        <span>Ok</span>
                    </button>
                </div>
    
            </div>
        </ng-template>
    </app-modal>
    
    <app-modal #warningModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
        <ng-template #modalContentTemplate>
            <div class="col-sm-12 content-style">
                <div>
                    <span>You cannot delete this measure because measures are associated</span>
                </div>
            </div>
            <div class="col-sm-12 footer-style">
                <div>
                    <button type="button" class="btn button-style cancelbtn" (click)="closeWarningModal()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </ng-template>
    </app-modal>
    
    </div>