<div class="searchContainer">
  <div class="row" id="dvrowcontainer">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()"  method="post">
      <div class="search_icon">
        <i class="fas fa-search"></i>
      </div>
      <ng-container *ngIf="checkLogin; else anonymousForm">
        <div class="searchBoxForm">
          <input formControlName="searchQuery"
            [ngClass]="{ 'is-invalid': submitted && formFields.searchQuery.errors }"
            class="searchBox"
            autocomplete="off"
            id="SearchQuery"
            name="SearchQuery"
            [placeholder]="(isAllGroup || isVisionGroup) ?'Enter Search Keyword':'Begin Search...'"
            type="text"
            [typeahead]="dataSource"
            (typeaheadLoading)="changeTypeaheadLoading($event)"
            (typeaheadOnSelect)="typeaheadOnSelect($event)"
            [typeaheadOptionsLimit]="10"
          />
          <div *ngIf="submitted && formFields.searchQuery.errors" class="searchQuery-error">
              <div *ngIf="formFields.searchQuery.errors.required">Please enter keywords to search.</div>
          </div>
        </div>
        <div class="search_button">
          <input type="submit" id="searchButton" value="Search" class="searchButton" />
        </div>
      </ng-container>
      <ng-template #anonymousForm>
        <div class="searchBoxForm">
          <input formControlName="searchQuery"
            class="searchBox"
            autocomplete="off"
            id="SearchQuery"
            name="SearchQuery"
            placeholder="Begin Search..."
            type="text"
            value=""
          />
        </div>
      </ng-template>
    </form>
  </div>
</div>
<div class="filter-search" *ngIf="hideSearchByFilter">
  <a (click)="onSubmit(true)" class="btn button-style search-link">Search by Filters</a>
</div>