import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { OAuthService } from 'angular-oauth2-oidc';
import { SubGroups } from 'src/app/core/navigations/navigation-settings.model';
import { NavigationService } from 'src/app/core/navigations/navigation.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { LocalStorageService } from 'src/app/core/state-manager/local-storage/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-new-service',
  templateUrl: './auth-new-service.component.html',
  styleUrls: ['./auth-new-service.component.scss']
})
export class AuthNewServiceComponent implements OnInit {

  loginType: string;
  subDomain: string;
  allGroup = false;
  defaultGroup = false;
  reportingGroup = false;
  jdaiGroup = false;
  thriveGroup = false;
  ftlaGroup = false;
  jcyoiGroup = false;
  portalConfig=false;
  jjdataGroup=false
  grantsGroup=false
  constructor(
  public authStateService: OktaAuthStateService, 
  private oktaAuth:OktaAuth,
  private authService: MsalService,
              private route: ActivatedRoute,
              private currentState: StateService,
              private storageService: LocalStorageService,
              private router: Router,
              private navigationService: NavigationService) {
    this.loginType = this.storageService.getItem('LoginType');
    this.currentState.setGroupSession();
   }

 
validateNewLogin(){
this.authStateService.authState$.subscribe(x=>{
  this.currentState.islogged.next(x.isAuthenticated);
  if(x.isAuthenticated){
      this.setloginSession();
  }
});
}
 

  setloginSession() {
    //// set token
    this.currentState.setToken(this.oktaAuth.getIdToken());
    this.currentState.getUserDetail().subscribe(() => {
      this.currentState.setUserActive();
      this.currentState.triggerSessionInitiatedEvent(true);
      let redirectUrl = this.route.snapshot.queryParams['redirecturl'];
      if (!redirectUrl || redirectUrl.length === 0) {
        redirectUrl = this.storageService.getItem('redirecturl');
        this.storageService.removeItem('redirecturl');
      }
      if (redirectUrl && redirectUrl.length > 0) {
        this.router.navigate([decodeURIComponent(redirectUrl)]);
      } 
      // else {
      //   this.navigateToHome();
      // }
    }, (error) => {
      this.currentState.triggerSessionInitiatedEvent(false);
      //this.navigateToHome();
    });
  }

  setloginSessionForAzureAD(){
    let homeAccountId=this.authService.instance.getActiveAccount().homeAccountId;
    let idtoken=homeAccountId+'-login.windows.net-idtoken-'+environment.clientId+'-'+environment.tenantId;
    var idToken = JSON.parse(localStorage.getItem(idtoken+'-')).secret;
   this.currentState.setToken(idToken);
   sessionStorage.setItem('id_token',idToken);
   this.currentState.checkUserAccess();
  }

  navigateToHome() {
    const rootRoute = this.navigationService.getCurrentnavigationRoutes(this.subDomain).navigateLink;
    this.storageService.setItem('rootRoute', rootRoute);
    this.router.navigate([rootRoute]);
  }

  setGroups() {
    this.subDomain = this.navigationService.getSubdomainFromUrl();
    if (this.subDomain === SubGroups.All) {
      this.allGroup = true;
    } else if (this.subDomain === SubGroups.Reporting) {
      this.reportingGroup = true;
    } else if (this.subDomain === SubGroups.JDAI) {
      this.jdaiGroup = true;
    } else if (this.subDomain === SubGroups.Thrive) {
      this.thriveGroup = true;
    } else if (this.subDomain === SubGroups.FTLA) {
      this.ftlaGroup = true;
    } else if (this.subDomain === SubGroups.Jcyoi) {
      this.jcyoiGroup = true;
    } else if (this.subDomain === SubGroups.JimCasey) {
      this.jcyoiGroup = true;
    } 
    else if (this.subDomain === SubGroups.grants) {
      this.grantsGroup = true;
    } 
    else if (this.subDomain === SubGroups.JJdata) {
      this.jjdataGroup = true;
    }  else if (this.subDomain === SubGroups.aecfsearchdev || this.subDomain === SubGroups.aeckmsapp || environment.azureADAuth) { // localhost || environment.azureADAuth
      this.portalConfig = true;
      this.subDomain='aecfsearchdev';
    }
    else {
      this.defaultGroup = true;
    }
  }

  ngOnInit() {
    this.setGroups();
if(this.portalConfig){
if(this.authService.instance.getAllAccounts().length>0){
  this.setloginSessionForAzureAD();
}
}else{
  this.validateNewLogin();
}
  }
}
