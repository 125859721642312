
export class FoundationProfiles {
    id: string;
    surName: string;
    userPrincipalName: string;
    title: string;
    givenName: string;
    name:string;
    contact:string;
    // founded:number;
    // staffSize:number;
    // headquarters:string;
    website:string;
    // Endowment:string;
    foundationLead:string;
    userId:string;
    // endowment:number;
    jobTitle:string;
    organization:string;
    public uiMapper(modelData: any) {
        Object.assign(this, modelData, {
        });
        return this;
    }

    // public addProfile(id:string,email:string,contact:string,firstName:string,lastName:string,
    //     title:string,organization:string,founded:number,
    //     staffsize:number,url:string,headquarter:string,userId:string,endowment:number){

            public addProfile(id:string,email:string,contact:string,firstName:string,lastName:string,
                title:string,organization:string,
                url:string,userId:string){

Object.assign(this, {
userPrincipalName :email,
contact:contact,
// founded:founded,
givenName:firstName,
surName:lastName,
jobTitle:title,
organization:organization,
// staffsize:staffsize,
url:url,
id:id,
// headquarters:headquarter,
userId:userId
// endowment:endowment
});
return this;
}

}

export class FoundationProfilesList {
    profiles: Array<FoundationProfiles> = [];

    public uiMapper(modelData: any) {
        Object.assign(this, {
            profiles: modelData ?
                        modelData.map(data => {
                            return  Object.assign(new FoundationProfiles().uiMapper(data));
                        }) : []
        });

        return this;
    }
}
   