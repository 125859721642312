<div class="content-view-container">
    <div class="view-items">
        <div *ngIf="!showProgress" class="row header">
            <div  class="col-sm-4">
                <span *ngIf="contentData.formFilesField.length > 0">File Name</span>
                <span *ngIf="contentData.linkField.length > 0">Link</span>
            </div> 
            <div  class="col-sm-4">
                Display Title
            </div>
            <div  class="col-sm-4">
                File Type Tags
            </div>
        </div>
        <div class="row file-item"  *ngFor="let item of contentData.formFilesField; let i=index">
            <div [ngClass]="{'col-sm-6 show-progress': showProgress, 'col-sm-4 file-text-item': !showProgress}">
                <span class="file-type-icon"><em *ngIf="item.FileField.fileAttributes" class="{{ item.FileField.fileAttributes.cssClass }} fa-sm"></em></span>
                <span class="file-item-span">{{item.FileField.file.name}}</span>
            </div>
            <div *ngIf="!showProgress" class="file-display-item col-sm-4">
               <span>{{item.kcTitleField}}</span>                
            </div>
            <div [ngClass]="{'col-sm-6 show-progress': showProgress, 'col-sm-4': !showProgress}" class="tag-content-item file-type-item">                
                <label *ngFor="let fileItem of item.fileTypeField" class="Rectangle">{{ fileItem }}</label>
            </div>
            <div *ngIf="showProgress">
                <div class="progress-status col-sm-12">
                    <div class="col-sm-10">
                        <span *ngIf="item.FileField.isSuccess" class="success"><em class="fas fa-check-circle"></em></span>
                        <span *ngIf="item.FileField.isError" class="error"><em class="fas fa-times-circle"></em></span>
                        <div *ngIf="item.FileField.isUploading">
                            <span>Uploading</span>
                        </div>
                        <div *ngIf="item.FileField.isSuccess">
                            <span>Upload complete</span>
                        </div>
                        <div *ngIf="item.FileField.isError">
                            <span>Upload failed</span>
                        </div>
                    </div>
                    <div class="col-sm-2 status">
                        <span>{{item.FileField.file.size | formatbytes}}</span>                        
                    </div>
                </div>
                <div class="progress col-sm-12">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': (item.FileField.progress - 50) + '%' }" [ngClass]="{ 'success': item.FileField.isSuccess, 'error': item.FileField.isError }"></div>
                </div>
            </div>
        </div>
        <div class="row line-item"  *ngFor="let item of contentData.linkField; let i=index">
            <div [ngClass]="{'col-sm-6 show-progress': showProgress, 'col-sm-4 file-text-item': !showProgress}">
                <span class="file-type-icon"><em *ngIf="item.externalUrlField.fileAttributes" class="{{ item.externalUrlField.fileAttributes.cssClass }} fa-sm"></em></span>
                <span class="file-item-span">{{item.externalUrlField.linkValue}}</span>
            </div>
            <div *ngIf="!showProgress" class="file-display-item col-sm-4">
                <span>{{item.kcTitleField}}</span>                
            </div>
            <div [ngClass]="{'col-sm-6 show-progress': showProgress, 'col-sm-4': !showProgress}" class="tag-content-item file-type-item">                
                <label *ngFor="let fileItem of item.fileTypeField" class="Rectangle">{{ fileItem }}</label>
            </div>
            <div *ngIf="showProgress" class="col-sm-12 status">
                <span *ngIf="item.externalUrlField.isUploaded">
                    <span class="success"><em class="fas fa-check-circle"></em></span>Valid Link
                </span>
                <span *ngIf="showError && item.externalUrlField.isError && !item.externalUrlField.warning">
                    <span class="error"><em class="fas fa-times-circle"></em></span>Invalid Link
                </span>
                <span *ngIf="showError && item.externalUrlField.isError && item.externalUrlField.warning">
                    <span class="warning"><em class="fas fa-exclamation-triangle"></em></span>Invalid Link
                </span>
            </div>           
        </div>
        <div class="row-items">
            <div class="line"></div>
        </div> 
    </div>
    <div  class="moderator-content">
        <div class="moderator-content-div">
            <span class="caption">Moderator: </span><span>{{contentData.moderator && contentData.moderator.fullName}}</span>
        </div>
        <div class="line">
        </div> 
    </div>
    <div class="row" *ngIf="tabSelected != 'Upload Dataset'">
        <div class="tag-content">
            <span> Tags <i class="fa fa-tag" aria-hidden="true"></i></span>
        </div>
        <!-- <div class="tag-items" *ngIf="!isAllGroup">
            <div class="col-md-3 tag-content-item">
                <label *ngIf="contentData.accessFor">Access For</label>
            </div>
            <div class="col-md-9 tag-content-item">
                <label class="Rectangle" *ngIf="contentData.accessFor">{{accessForList[contentData.accessFor]}}</label>
            </div>
        </div> -->
        <ng-container *ngFor="let metaDataItem of tags">
            <div  class="tag-items" *ngIf="((contentData[metaDataItem] && contentData[metaDataItem].length > 0) || locationExists(metaDataItem)) && (!isAllGroup || (isAllGroup && metaDataItem != 'location' && metaDataItem != 'community'))">
                <div class="col-md-3 tag-content-item">
                    <label>{{metaTags[metaDataItem] | titlecase}}</label>
                </div>
                <div class="col-md-9 tag-content-item" *ngIf="metaDataItem !== 'location'">
                    <label *ngFor="let item of contentData[metaDataItem]" class="Rectangle">{{ item }}</label>
                </div>
                <div class="col-md-9 tag-content-item" *ngIf="metaDataItem === 'location'">
                    <ng-container *ngFor="let locationDataItem of locationTags">
                        <label *ngFor="let locationItem of contentData[locationDataItem]" class="Rectangle">{{ locationItem.item }}</label>
                    </ng-container> 
                     <label *ngIf='isFtlaGroup' class="Rectangle">Not Specified</label>
                </div>  
            </div>
        </ng-container>
        <!-- <div class="tag-items" *ngIf="isAllGroup">
            <div class="col-md-3 tag-content-item">
                <label *ngIf="contentData.publicationDate">Publication Date</label>
            </div>
            <div class="col-md-9 tag-content-item">
                <label class="Rectangle" *ngIf="contentData.publicationDate">{{contentData.publicationDate | date:'MM/dd/yyyy h:mm a'}}</label>
            </div>
        </div> -->
    </div>
    <div class="row-items" *ngIf="contentData.description">
        <div class="line">
        </div>
        <div class="comments">
            <label>{{contentData.description}}</label>
        </div>        
    </div>   
    <div *ngIf="showUploadError">
        <div *ngIf="!showRetryError && uploadError">
            <alert type="danger" [dismissible]=true (onClosed)="closeAlert()">
                <div class="col-md-1"><span class="error"><em class="fas fa-times-circle fa-2x"></em></span></div>
                <div class="col-md-10">
                    An error occurred with the upload. Please try again.
                    <button *ngIf="retryEnabled" type="button" class="btn btn-secondary button-style retry-button" (click)="retryUpload()">Retry</button>
                </div>
            </alert>
        </div>
        <div *ngIf="showRetryError && uploadError">
            <alert type="danger">
                <div class="col-md-1"><span class="error"><em class="fas fa-times-circle fa-2x"></em></span></div>
                <div class="col-md-10">
                    An error occurred with the upload due to network security at your current location, contact your local support team for assistance
                </div>                
            </alert>
        </div>
    </div>
</div>

<app-modal #confirmDialog contentStyle="valid-link-modal" [showFooter]=false class="modal-lg modal-centered">
    <ng-template #modalContentTemplate let-linkItems="dataItem.entityData.linkItems">
        <div class="content-style">
            The following links are invalid and will not be uploaded. Please go back to delete and add a valid link.
            Click retry below if you think this was a transient error.
            <div class="row heading">
                <div class="col-sm-7 align-left">
                    Link
                </div>
                <div class="col-sm-3  align-left">
                    Message
                </div>
                <div class="col-sm-2 align-role-items">
                    Retry
                </div>
            </div>            
            <ng-container *ngFor="let linkItem of linkItems; let rowIndex=index;">
                <div class="row link-items">
                    <div class="col-sm-7 align-left">
                        {{linkItem.externalUrlField.linkValue}}
                    </div>
                    <div class="col-sm-3 align-left">
                        <span *ngIf="linkItem.externalUrlField.isError && !linkItem.externalUrlField.warning" class="error"><em class="fas fa-times-circle"></em></span>
                        <span *ngIf="linkItem.externalUrlField.isError && linkItem.externalUrlField.warning" class="warning"><em class="fas fa-exclamation-triangle"></em></span>
                        {{linkItem.externalUrlField.message}} ({{linkItem.externalUrlField.status}})
                    </div>
                    <div class="col-sm-2 align-role-items">
                        <input *ngIf="!(linkItem.externalUrlField.status >= 400 && linkItem.externalUrlField.status <= 499)"  [(ngModel)]="linkItem.externalUrlField.retry" class="selectCheck" [value]="linkItem.externalUrlField.retry" name="retryItem{{rowIndex}}" id="btn-{{rowIndex}}-retry"
                        type="checkbox">
                    </div>
                </div>
            </ng-container> 
            
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal(false)">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="button" class="btn button-style" (click)="closeConfirmModal(true)">
                    <span>Retry</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>
<app-modal #fileTypeErrorDialog contentStyle="valid-link-modal" [showFooter]=false class="modal-lg modal-centered">
    <ng-template #modalContentTemplate let-items="dataItem.entityData.items" let-isLink="dataItem.entityData.isLink">
        <div class="content-style">
            <ng-container *ngIf="isLink">
                The following links are invalid and will not be uploaded. Please go back to update the valid file type.
            </ng-container>                
            <ng-container *ngIf="!isLink">
                The following files are invalid and will not be uploaded. Please go back to update the valid file type.
            </ng-container>                         
            <div class="row heading">
                <div class="col-sm-8 align-left" *ngIf="isLink">
                    Link
                </div>
                <div class="col-sm-8 align-left" *ngIf="!isLink">
                    File Name
                </div>
                <div class="col-sm-4  align-left">
                    Message
                </div>               
            </div> 
            <div *ngIf="!isLink">          
                <ng-container *ngFor="let item of items; let rowIndex=index;">
                    <div class="row link-items">
                        <div class="col-sm-8 align-left">
                            {{item._file.name}}
                        </div>
                        <div class="col-sm-4 align-left">
                            <span class="error"><em class="fas fa-times-circle"></em></span>
                            {{item.message}} ({{item.status}})
                        </div>                  
                    </div>
                </ng-container> 
            </div> 
            <div *ngIf="isLink">   
                <ng-container *ngFor="let linkItem of items; let rowIndex=index;">
                    <div class="row link-items">
                        <div class="col-sm-8 align-left">
                            {{linkItem.externalUrlField.linkValue}}
                        </div>
                        <div class="col-sm-4 align-left">
                            <span class="error"><em class="fas fa-times-circle"></em></span>
                            {{linkItem.externalUrlField.message}} ({{linkItem.externalUrlField.status}})
                        </div>
                    </div>
                </ng-container>
            </div>             
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeFileTypeErrorModal(false)">
                    <span>Cancel</span>
                </button>
            </div>            
        </div>
    </ng-template>
</app-modal>
