<app-portalconfig-header *ngIf="portalConfig"></app-portalconfig-header>
<!-- <app-header ></app-header> -->
<app-header-new *ngIf="!portalConfig"></app-header-new>
<app-top-nav></app-top-nav>
<div class="container-fluid">
  <div class="body-content">
    <router-outlet  *ngIf="!portalConfig"></router-outlet>
    <router-outlet  *ngIf="!isIframe && portalConfig"></router-outlet>
  </div>
</div>
<app-footer *ngIf="!portalConfig"></app-footer>
    <ng-http-loader  [filteredUrlPatterns]="['api/Search/autocomplete/']" [spinner]="spinkit.skWave"></ng-http-loader>
