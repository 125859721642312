<div class="search-results-container">
    <div class="row refine-row">
        <div class="filter-tag-header refine-tag" [ngClass]="[isAllGroup ? 'col-md-3' : 'col-md-2']" (click)="toggleRefineTag()"><em class='refine_icon fas fa-sliders-h'></em>Refine</div>
        <div class="searchComponentContainer" [ngClass]="[isAllGroup ? 'col-md-9' : 'col-md-10']">
        <app-search-component [resultSubmitted]=true></app-search-component>
        </div>
    </div>
    <div class="row dv-search-results-container" *ngIf="showResults">
        <div class="filter-tags" [ngClass]="{'hide': !showFilters, 'col-md-3': isAllGroup, 'col-md-2': !isAllGroup}">
        <accordion class="search_facets_accordion">
            <ng-container *ngFor="let facet of facets | keyvalue : returnZero; let i = index">
            <accordion-group [panelClass]="accordionPanelClass" *ngIf="(facet.key != 'programArea' && facet.key != 'location' && facet.key != 'community' && isAllGroup) || !isAllGroup" [isOpen]="selectedAccordion == facet.key" (isOpenChange)="isOpenChange(facet.key, $event)">
                <div class="filter-tag-header" accordion-heading *ngIf="facet.key!='jurisdiction'">
                    <span>{{ facetItemKeys[facet.key] | labeltransform | titlecase }}</span><i class='arrow_icon fas fa-angle-down'></i>
                </div>
                <div class="filter-tag-header" accordion-heading *ngIf="facet.key=='jurisdiction'">
                    <span>{{ "Location" }}</span><i class='arrow_icon fas fa-angle-down'></i>
                </div>
                <div class="refine-tags-option" id="{{ facet.key }}" [ngClass]="{'disable': isFacetDisabled}">
                <label class="clear-text" *ngIf="(!isAllGroup || (isAllGroup && facet.key !== 'topic'))"><a (click)="clearFacetSelection(facet.key + 'Facet', 'clearAllChecked')">CLEAR <i class='close_icon fas fa-times'></i></a></label>
                <label class="clear-text" *ngIf="facet.key == 'topic' && isAllGroup"><a (click)="clearTopicFacetSelection()">CLEAR <i class='close_icon fas fa-times'></i></a></label>
                <ul dropdown class="btn-group location-tags-checkbox-list locationTag" [insideClick]=true #dropdown="bs-dropdown" *ngIf="facet.key == 'topic' && isAllGroup">
                  <li dropdown *ngFor="let facetItems of facet.value; let items = index" role="menuitem" [insideClick]="true" class="pr-15" [isOpen]="selectedTopic == facetItems.topic" (onShown)="selectedTopic = facetItems.topic">
                    <input type="checkbox" (change)="getFacetedSearchResults(facet.key, facetItems.topic, $event)" [checked] ="isChecked(facetsGroup[facet.key + 'Facet'], facetItems.topic)">
                    <span *ngIf="facetItems.programs.length > 0" dropdownToggle class="dropdown-toggle"> {{ facetItems.topic }} <span *ngIf="facetItems.count">({{ facetItems.count }})</span><i class='dropDownIcon fas fa-angle-down'></i></span>
                    <span *ngIf="facetItems.programs.length == 0" class="dropdown-toggle"> {{ facetItems.topic }} <span *ngIf="facetItems.count">({{ facetItems.count }})</span></span>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li *ngFor="let program of facetItems.programs">
                        <input (change)="getFacetedSearchResults('programArea', program.program, $event)" [checked] ="isChecked(facetsGroup['programAreaFacet'], program.program)"
                         type="checkbox" value='{{ program.program }}'><span>{{ program.program }} ({{ program.count }})</span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ng-container *ngIf="(!isAllGroup || (isAllGroup && facet.key !== 'topic' && facet.key !== 'programArea')) && facet.key !== 'publisher'">
                  <ul class="refine-tags-checkbox-list" *ngIf="facet.key !== 'location'; else locationTag">
                    <ng-container *ngFor="let facetItems of facet.value; let items = index">
                    <li *ngIf="facetItems.value != 'null'"><input (change)="getFacetedSearchResults(facet.key, facetItems.value, $event)"
                        [checked] ="isChecked(facetsGroup[facet.key + 'Facet'], facetItems.value)" type="checkbox" value='{{ facetItems.value }}'><span>{{ facetItems.value }} ({{ facetItems.count }})</span></li>
                    </ng-container>
                  </ul>
                </ng-container>
                <ng-container *ngIf="facet.key == 'publisher'">
                  <ul class="refine-tags-checkbox-list">
                    <ng-container *ngFor="let facetItems of facet.value; let items = index">
                    <li *ngIf="facetItems.value != 'null'"><input (change)="getFacetedSearchResults('publisher', facetItems.value, $event)"
                        [checked] ="isChecked(facetsGroup['publisherFacet'], facetItems.value)" type="checkbox" value='{{ facetItems.value }}'><span>{{ facetItems.value }} ({{ facetItems.count }})</span></li>
                    </ng-container>
                  </ul>
                </ng-container>
              </div>
            </accordion-group>
            </ng-container>
            <accordion-group [panelClass]="accordionPanelClass">
            <div class="filter-tag-header" accordion-heading>
                <span>Publication Date</span><i class='arrow_icon fas fa-angle-down'></i>
            </div>
            <div class="refine-tags-option" id="publicationDate">
                <label class="clear-text"><a (click)="onDateClear()">CLEAR <i class='close_icon fas fa-times'></i></a></label>
                <div class="container-fluid searchDatePicker">
                <div class="datecol">
                    <input class="form-control datapicker" #drp="bsDatepicker" placement="right" [ngClass]="{'invalid': hasInvalidDate(bsStartValue) || hasDateRangeError() }" [(bsValue)]="bsStartValue" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateValueChange($event)">
                </div>
                <div class="datecolinter">
                    <span>-</span>
                </div>
                <div class="datecol">
                    <input class="form-control datapicker" #drp="bsDatepicker" placement="right" [ngClass]="{'invalid': hasInvalidDate(bsEndValue) || hasDateRangeError() }" [(bsValue)]="bsEndValue" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateValueChange($event)">
                </div>
                <span *ngIf="hasDateRangeError()" class="invalid-error">Please enter valid date range</span>
                </div>
            </div>
            </accordion-group>
        </accordion>
        </div>
        <div class="dvSearchResults" [ngClass]="{'col-md-10': showFilters && !isAllGroup, 'col-md-9': showFilters && isAllGroup, 'col-md-12': !showFilters}">
        <div class="col-md-12 selected-facets-list">
            <ng-container *ngFor="let item of facetsGroup | keyvalue;">
            <ng-container *ngIf="item.key != 'publicationDateFacet'">
                <label *ngFor="let itemlabel of item.value.split('|');" class="selectedFacet Rectangle" (click)="clearFacetSelection(item.key, itemlabel)" [tooltip]="item.key | slice:0:-5 | labeltransform | titlecase">{{ itemlabel }}
                <em class='close_icon fas fa-times'></em></label>
            </ng-container>          
            </ng-container>
        </div>
        <ng-container *ngIf="totalItems; else emptyContainer">
            <div>
            <div class="row result_count_container">
                <div class="col-md-9 result_count">
                <p>
                    {{itemPerPage}} Results shown for ‘{{ searchQuery }}’ / <b>{{ totalItems }} Results Total</b>
                </p>
                </div>
                <div class="col-md-3 recent_button_container">
                <a id="lnkrelevant" (click)="sortSearch(0)" [ngClass]="{'arrow_box':sortParam === 0}" class="revelant_button btn">RELEVANT</a>
                <label></label>
                <a id="lnkrecent" (click)="sortSearch(1)" [ngClass]="{'arrow_box':sortParam === 1}" class="btn recent_button">RECENT</a>
                </div>
            </div>
            <ng-container *ngFor="let result of results; let i = index">
                <div class="row row_padding">
                </div>
                <div class="search-results-row">
                <div *ngIf="result.document.externalUrl && result.document.externalUrl != 'Not Specified'">
                    <a href="javascript:void(0)" (click)="goToUrl(result.document.externalUrl);" >
                    <label *ngIf="result.document.fileTitle == 'Not Specified'">{{ result.document.fileName | titlecase }} </label>
                    <label *ngIf="result.document.fileTitle != 'Not Specified'">{{ result.document.fileTitle | titlecase }} </label>
                    </a>
                </div>
                <div *ngIf="result.document.externalUrl == 'Not Specified' || result.document.externalUrl === null"> 
                    <label *ngIf="result.document.fileTitle== 'Not Specified'">{{ result.document.fileName | titlecase }} </label>
                    <label *ngIf="result.document.fileTitle!= 'Not Specified'">{{ result.document.fileTitle | titlecase }} </label>
                </div>
                <div class="tags_container">
                    <ng-container *ngFor="let item of result.document.tag">   
                    <label *ngFor="let itemval of item.value" (click)="filterTag(item.key, itemval)" [hidden]="itemval == 'Not Specified' || itemval== 'false' || isHidden(item.key)" class="selectedFacet Rectangle">{{ itemval }}</label>
                    </ng-container>
                </div>
                <p class="author_details"> Uploaded {{ result.document.publicationDate | date: 'MMM d, y hh:mm a' }} by {{ result.document.uploadedBy }} </p>
                <div class="col-md-12 result-description" *ngIf="result.document.description && result.document.description != 'Not Specified'">
                    {{result.document.description}}
                </div>
                <div class="row">
                    <div class="col-md-10 search-row-buttons">
                        <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'Email' && result.document.fileAttributes['FileType'] !== 'Wmv' && result.document.fileAttributes['FileType'] !== 'WebSite';">
                        <a *ngIf="result.document.toggle == 0" href="javascript:void(0)" (click)="showPreview(result)"  class="form-control preview_button">
                            <span>Show Preview</span><em class="button-icon fas far fa-eye"></em>
                        </a>
                        <a *ngIf="result.document.toggle == 1" href="javascript:void(0)" (click)="showPreview(result)"  class="form-control preview_button">
                            <span>Hide Preview</span><em class="button-icon fas far fa-eye"></em>
                        </a>
                        </ng-container>
                        <span *ngIf="result.document.fileAttributes['FileType'] === 'Email' || result.document.fileAttributes['FileType'] === 'Wmv' || result.document.fileAttributes['FileType'] === 'WebSite';"
                        class="preview_text_button">
                        Preview Not Available
                        </span>
                        <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'WebSite'">
                        <a (click)="downloadFile(result)" class="form-control searchButton download_button"><span>Download </span><em class='button-icon fas fa-download'></em></a>
                        </ng-container>
                        <a (click)="openConfirmationModal(result)"
                            class="form-control searchButton deleteButton" [ngClass]="{ 'disable': viewAsAdmin | async }">
                            <span>Delete</span> 
                            <em class='button-icon far fa-trash-alt'></em>
                        </a>
                        <ng-container>
                            <a (click)="NavigateEditContent(result.document.fileName, result.document.containerName)" [routerLink]="['/content/edit']" class="form-control searchButton download_button"><span>Modify</span></a>
                          </ng-container>
                    </div>
                    <div class="col-md-2 search-row-file-type">
                        <span class="file_type_label {{ result.document.fileAttributes.CssIcon }}"><em class="{{ result.document.fileAttributes.CssClass }}"></em></span>
                    </div>
                </div>  
                <div id="divpreview" class="preview_container_wrapper" *ngIf="result.document.toggle && result.document.containerName != null">
                    <div class="col-md preview_container" id="dvpreviewcol">
                    <iframe *ngIf="result.document.fileAttributes['FileType'] !== 'PDF';"
                        [src]='result.document.fileURL'
                        class="preview_iframe"></iframe>
                    <object *ngIf="result.document.fileAttributes['FileType'] === 'PDF';" [data]="result.document.fileURL | safeUrl" type="application/pdf">Preview not available</object>
                    </div>
                </div>
                <div id="divpreview" class="preview_container_wrapper" *ngIf="result.document.toggle && result.document.containerName == null">
                    <div class="col-md preview_container" id="dvpreviewcol">
                    <p class="filePreviewWarning">Unable to show file preview as container name value is NULL.</p>
                    </div>
                </div>           
                </div>
            </ng-container>
            <div>
                <div class="text-right paginationContainer">
                <!-- pager -->
                <pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [maxSize]="maxSize" class="pagination"
                    [boundaryLinks]="true" [rotate]="false"  (click)="getPagedSearchResults()"></pagination>
                </div>
            </div>
            </div>
        </ng-container>
        <ng-template #emptyContainer>
            <p class="noResults">No results for <b>"{{ searchQuery }}"</b></p>
        </ng-template>        
        </div>
    </div>  
</div>
<div class="searchNote">This search feature uses Azure Cognitive Search to match files in the library. Please note that the search attempts to scan all metadata and contents of the file. Additionally, please wait for indexes to run before searching for recently uploaded files.</div>
<app-modal #confirmationModal contentStyle="delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <app-confirmation-modal [dataItem]="dataItem" (confirm)="removeFile($event)" (closeModal)="closeConfirmModal($event)"></app-confirmation-modal>
    </ng-template>
</app-modal>

<ng-template #locationTag>
    <ng-container *ngIf="isFtlaGroup; else locationTagOther">
      <ul dropdown  class="btn-group location-tags-checkbox-list locationTag" [insideClick]=true #dropdown="bs-dropdown" >
        <li dropdown *ngFor="let continentItem of facets['location']" role="menuitem" [insideClick]="true">
          <input type="checkbox" (change)="getFacetedSearchResults('continent', continentItem.continent, $event)" [checked] ="isChecked(facetsGroup['continentFacet'], continentItem.continent)">
          <span *ngIf="continentItem.countryList.length > 0" dropdownToggle class="dropdown-toggle"> {{ continentItem.continent }} <span *ngIf="continentItem.count">({{ continentItem.count }})</span><i class='dropDownIcon fas fa-angle-down'></i></span>
          <span *ngIf="continentItem.countryList.length == 0" class="dropdown-toggle"> {{ continentItem.continent }} <span *ngIf="continentItem.count">({{ continentItem.count }})</span></span>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li dropdown *ngFor="let countryItem of continentItem.countryList" role="menuitem" [insideClick]="true">
              <input type="checkbox" (change)="getFacetedSearchResults('country', countryItem.country, $event)" [checked] ="isChecked(facetsGroup['countryFacet'], countryItem.country)">
              <span *ngIf="countryItem.regionList.length == 0" class="dropdown-toggle"> {{ countryItem.country }} <span *ngIf="countryItem.count">({{ countryItem.count }})</span></span>
              <span *ngIf="countryItem.regionList.length > 0" dropdownToggle class="dropdown-toggle"> {{ countryItem.country }} <span *ngIf="countryItem.count">({{ countryItem.count }})</span><i class='dropDownIcon fas fa-angle-down'></i></span>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li dropdown *ngFor="let regionItem of countryItem.regionList" role="menuitem" [insideClick]="true">
                  <input type="checkbox" (change)="getFacetedSearchResults('region', regionItem.region, $event)" [checked] ="isChecked(facetsGroup['regionFacet'], regionItem.region)">
                  <span dropdownToggle class="dropdown-toggle">{{ regionItem.region }} <span *ngIf="regionItem.count">({{ regionItem.count }})</span><i *ngIf="regionItem.divisionList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li dropdown *ngFor="let divisionItem of regionItem.divisionList" role="menuitem" [insideClick]="true">
                      <input type="checkbox" (change)="getFacetedSearchResults('division', divisionItem.division, $event)" [checked] ="isChecked(facetsGroup['divisionFacet'], divisionItem.division)">
                      <span dropdownToggle class="dropdown-toggle">{{ divisionItem.division }} <span *ngIf="divisionItem.count">({{ divisionItem.count }})</span><i *ngIf="divisionItem.stateList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li dropdown *ngFor="let stateItem of divisionItem.stateList" role="menuitem" [insideClick]="true">
                          <input type="checkbox" (change)="getFacetedSearchResults('state', stateItem.state, $event)" [checked] ="isChecked(facetsGroup['stateFacet'], stateItem.state)">
                          <span dropdownToggle class="dropdown-toggle">{{ stateItem.state }} <span *ngIf="stateItem.count">({{ stateItem.count }})</span><i *ngIf="stateItem.countyList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li *ngFor="let countyItem of stateItem.countyList" role="menuitem">
                              <input type="checkbox" (change)="getFacetedSearchResults('jurisdiction', countyItem.county, $event)" [checked] ="isChecked(facetsGroup['jurisdictionFacet'], countyItem.county)"><span>{{ countyItem.county }} <span *ngIf="countyItem.count">({{ countyItem.count }})</span></span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </ng-container>
  </ng-template>
<ng-template #locationTagOther>
    <ul dropdown  class="btn-group location-tags-checkbox-list" [insideClick]=true #dropdown="bs-dropdown" >
      <li dropdown *ngFor="let countryItem of facets['location']" role="menuitem" [insideClick]="true">
        <input type="checkbox" (change)="getFacetedSearchResults('country', countryItem.country, $event)" [checked] ="isChecked(facetsGroup['countryFacet'], countryItem.country)">
        <span dropdownToggle class="dropdown-toggle"> {{ countryItem.country }} <span *ngIf="countryItem.count">({{ countryItem.count }})</span><i *ngIf="countryItem.regionList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li dropdown *ngFor="let regionItem of countryItem.regionList" role="menuitem" [insideClick]="true">
            <input type="checkbox" (change)="getFacetedSearchResults('region', regionItem.region, $event)" [checked] ="isChecked(facetsGroup['regionFacet'], regionItem.region)">
            <span dropdownToggle class="dropdown-toggle">{{ regionItem.region }} <span *ngIf="regionItem.count">({{ regionItem.count }})</span><i *ngIf="regionItem.divisionList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li dropdown *ngFor="let divisionItem of regionItem.divisionList" role="menuitem" [insideClick]="true">
                <input type="checkbox" (change)="getFacetedSearchResults('division', divisionItem.division, $event)" [checked] ="isChecked(facetsGroup['divisionFacet'], divisionItem.division)">
                <span dropdownToggle class="dropdown-toggle">{{ divisionItem.division }} <span *ngIf="divisionItem.count">({{ divisionItem.count }})</span><i *ngIf="divisionItem.stateList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li dropdown *ngFor="let stateItem of divisionItem.stateList" role="menuitem" [insideClick]="true">
                    <input type="checkbox" (change)="getFacetedSearchResults('state', stateItem.state, $event)" [checked] ="isChecked(facetsGroup['stateFacet'], stateItem.state)">
                    <span dropdownToggle class="dropdown-toggle">{{ stateItem.state }} <span *ngIf="stateItem.count">({{ stateItem.count }})</span><i *ngIf="stateItem.countyList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li *ngFor="let countyItem of stateItem.countyList" role="menuitem">
                        <input type="checkbox" (change)="getFacetedSearchResults('jurisdiction', countyItem.county, $event)" [checked] ="isChecked(facetsGroup['jurisdictionFacet'], countyItem.county)"><span>{{ countyItem.county }} <span *ngIf="countyItem.count">({{ countyItem.count }})</span></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
</ng-template>