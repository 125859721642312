import {  Component, OnInit } from '@angular/core';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ReportConfigurationService } from '../shared/report-config.service';
import { ReportProfile, reportmodel } from '../shared/report-profile.model';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-report-profile',
  templateUrl: './report-profile.component.html',
  styleUrls: ['./report-profile.component.scss']
})
export class ReportProfileComponent implements OnInit {
  selectedProfile:ReportProfile;
  formSubmitSuccess: string;
  formSubmitError: string;
  reportProfileList:any=[];
  viewAsAdmin = false;
  isAddOpen = false;
  departmentlist:any=[];
  jobTitleList:any=[];
  reportList:any[];
  profileForm :FormGroup;
  selectedReports:any=[];
  updatedReportProfileList:any=[];
  isEmpty:Boolean=false;
  constructor(private stateService: StateService,
              private reportConfigSrv: ReportConfigurationService,
              public fb: FormBuilder) {
                
               }

    get isAdmin() { return this.stateService.getRole() === Roles.Admin; }
    get isSuperAdmin() { return this.stateService.getRole() === Roles.SuperAdmin; }

  ngOnInit(): void {
    this.profileForm = this.fb.group({
                profileName: ['',[Validators.required]],
                departments: this.fb.array([], Validators.required),
                jobTitles: this.fb.array([], Validators.required),
                reports: this.fb.array([], Validators.required),
                });

    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    this.reportConfigSrv.getReports().subscribe(res=>{
        this.reportList = res;
        this.isEmpty = this.reportList.length===0;
    });

    this.reportConfigSrv.getAllDepartments().subscribe(res=>{
        this.departmentlist = res;
    });
    this.reportConfigSrv.getReportProfiles().subscribe(res=>{
      this.reportProfileList = res;
    });
    this.profileForm.get('departments').valueChanges.subscribe(item => {
      if(item !== '' && item !== undefined ){
          this.reportConfigSrv.getAllJobTitles(this.profileForm.get('departments').value).subscribe(res=>{
            this.jobTitleList = res;
        });
      }
    });
  }
 
  bindJobTitles(selectedDepartment: string)
  {
    this.reportConfigSrv.getAllJobTitles(selectedDepartment).subscribe(res=>{
      this.departmentlist = res;
  });
}

  addReportProfile():void {
    this.selectedProfile = new ReportProfile(0,'',[],[],[],false);
    this.isAddOpen = true;
    this.profileForm.reset();
    this.profileForm.get('departments').setValue([]);
    this.profileForm.get('jobTitles').setValue([]);
    this.profileForm.get('reports').setValue([]);
  }

  cancelAdd() {
    this.selectedProfile=null;
    this.resetAddForm();
  }

  resetAddForm() {
    this.selectedProfile = null;
    this.isAddOpen = false;
  }

  EditReportProfile(id:any): void {
    this.profileForm.reset();
    this.profileForm.get('departments').reset([]);
    this.profileForm.get('reports').reset([]);
    this.profileForm.get('jobTitles').reset([]);
    let data = this.reportProfileList.filter(x=> x.id === id)[0];
    this.profileForm.get('profileName').setValue(data.profileName);
    data.departments.forEach(element => {
      this.onTagValueChange(element,'departments',true);
    });
    data.jobTitles.forEach(element => {
      this.onTagValueChange(element,'jobTitles',true);
    });
    data.reports.forEach(element => {
      this.onTagValueChange(element.reportId,'reports',true);
    });
   
    this.selectedProfile = new ReportProfile(data.id,data.profileName, data.departments, data.jobTitles, data.reports, false);
    this.isAddOpen = true;
  }

  saveUpdateReports() {
        this.reportConfigSrv.updateReportProfiles(this.updatedReportProfileList).subscribe(x=>{
          this.formSubmitSuccess = "Report profiles are saved";
        },
        err=>{
          this.formSubmitError ="Report profiles are failed saved";
        });
  }
  onTagValueChange(value, formName, isChecked: boolean) {
    const formNameArray = this.profileForm.controls[formName] as FormArray;
      if (isChecked) {
        const index = formNameArray.controls.findIndex(x => x.value && x.value === value);
        if (index === -1) {
          formNameArray.push(new FormControl(value));
        }
      } else {
        const index = formNameArray.controls.findIndex(x => x.value === value);
        formNameArray.removeAt(index);
      }
  }
  tagSelected(value, formName) :boolean{
    const formNameArray = this.profileForm.controls[formName] as FormArray;
    return formNameArray.controls.findIndex(x => x.value === value) > -1;
  }
  
  toggleSelection(formName, data, isChecked: boolean){
    if(formName ==='reports'){
      if(isChecked)
        {
          data.forEach(element => {
            this.onTagValueChange(element.reportId,formName,true);
          });
        }
        else {
          data.forEach(element => {
            this.onTagValueChange(element.reportId,formName,false);
          });
        }
    }
    else {
        if(isChecked)
        {
          data.forEach(element => {
            this.onTagValueChange(element,formName,true);
          });
        }
        else{
          data.forEach(element => {
            this.onTagValueChange(element,formName,false);
          });
        }
      }
  }
  
  hasFieldError(fieldName) :boolean{
    return this.profileForm.controls[fieldName].status === "INVALID";
  }

  onSubmit(){
    this.selectedProfile.id = this.selectedProfile.id ===0 ?  -(this.reportProfileList.length) :this.selectedProfile.id;
    this.selectedProfile.profileName =this.profileForm.get('profileName').value;
    this.selectedProfile.departments = this.profileForm.get('departments').value;
    this.selectedProfile.jobTitles = this.profileForm.get('jobTitles').value;
    this.selectedReports.length=0;
    this.profileForm.get('reports').value.forEach(element => {
      if(!(element === null || element === undefined)){
      let report = this.reportList.find(x=>x.reportId == element);
      let reportobj: reportmodel = { reportId: report.reportId, reportName: report.reportName };
      this.selectedReports.push(reportobj);
      }
    });
    this.selectedProfile.reports = this.selectedReports;
    this.selectedProfile.isRemoved = false;
    this.isAddOpen = false;
    this.updatedReportProfileList.push(this.selectedProfile);
    const index = this.reportProfileList.findIndex((f: ReportProfile) => this.selectedProfile.id === f.id);
    //Not found, add on end.
    if (-1 === index) {
      this.reportProfileList = [...this.reportProfileList, this.selectedProfile];
    }
    else{
    //found, so return:
    //Clone of items before item being update.
    //updated item
    //Clone of items after item being updated.
    this.reportProfileList =  [...this.reportProfileList.slice(0, index), this.selectedProfile, ...this.reportProfileList.slice(index + 1)];
    }
  }

  DeleteReportProfile(id:number):void {
    let reportListIndex = this.reportProfileList.findIndex((f: ReportProfile) => id === f.id);
    //Not found, return same reference.
      if (-1 === reportListIndex) {
        return this.reportProfileList;
      }
    
     let index = this.updatedReportProfileList.findIndex((f: ReportProfile) => id === f.id);
     if(-1 === index){
      this.reportProfileList[reportListIndex].isRemoved=true;
      this,this.updatedReportProfileList.push(this.reportProfileList[reportListIndex]);
     }
     else{
       if(this.updatedReportProfileList[index].id <= -1) {
        this.updatedReportProfileList.pop(this.updatedReportProfileList[index]);
      }
       else{
        this.updatedReportProfileList[index].isRemoved=true;
       }
     }
      //Return clone of items before and clone of items after.
     this.reportProfileList = [...this.reportProfileList.slice(0, reportListIndex), ...this.reportProfileList.slice(reportListIndex + 1)];
  }
}
