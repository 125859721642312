<div class="container">
    <div class="page-title">
        <div>
            <span class="page-title"><h1>Profiles</h1></span>
        </div>
    </div>
    <div class="row">
        <div *ngIf="isFTLAGroup" style="float: left;margin-left: 20%;">
            <span style="font-size: larger;">Search by member foundation</span>
        </div>
        <div *ngIf="isFTLAGroup" style="margin-left: 45%;">
            <select (change)='onSiteSelected($event.target.value)'>
                <option value=0> Select Member</option>
                <option *ngFor="let site of memberslist; let rowIndex=index;" [ngValue]="site.website" [selected]="site.name == this"> 
                    {{site.name}} 
                </option>
            </select>
        </div>
</div>
<div class="row">
    <div class="search_icon_profile">
        <i class="fas fa-search"></i>
    </div>
    <div class="searchBoxForm_profile">
            <input type="text" id="searchTerm" name="searchTerm" placeholder="Profile Search..." autocomplete="off"
                         [(ngModel)]="searchTerm" (keyup)="search($event.target.value)"  class="searchBox_Profile"  />
    </div>
    <button type="button"  *ngIf="isFTLAGroup" id="btnAddProfile" class="btn btn-success button-style" (click)="addNewProfile()">Add New Profile</button>
</div>
<br>
<div class="card-data" *ngIf="isFTLAGroup">
        <div class="card" *ngFor="let profile of manageUsers; let rowIndex=index;">
            <div class="deleteIcondiv" *ngIf="isSuperAdmin || isAdmin">
                <div class="delete-icon">
                    <span *ngIf="(currentUser.id == profile.userId) || isSuperAdmin "  class="role-icon" (click)="removeProfile(profile)">
                        <em class="far fa-trash-alt"></em>
                    </span>  
                </div>
            </div>
         <!-- <div style="cursor: pointer;"  title='Founded:{{profile.founded}} &#13;Staff Size:{{profile.staffSize}}&#13;Headquarters:{{profile.headquarters}}' (click)="editProfile(profile);"> -->
            <div style="cursor: pointer;" (click)="editProfile(profile);">
            <div style="height: 160px;">
                <img src="{{storage}}/{{profile.userId}}.jpg?timeStamp={{timeStamp}}" alt="{{profile.foundationLead}}" height="160" style="height: 90%; width: 60%;">
            </div>
    <h3>{{profile.foundationLead}}</h3>
    <p><a href="https://www.{{profile.website}}" target="_blank" >{{profile.name}}</a></p>
    <a href=mailto:{{profile.userPrincipalName}}>{{profile.userPrincipalName}}</a>
    <p>{{profile.contact}}</p>
         </div>
    </div>
</div>
    <div class="card-data" *ngIf="isReportingGroup || isJJDataGroup">
        <div class="card" *ngFor="let profile of reportingProfiles; let rowIndex=index;">
         <div  (click)="editProfile(profile);">
            <img src="{{storage}}/{{profile.id}}.jpg" (error)="handleMissingImage($event)"
            alt="{{profile.foundationLead}}" >
                    <h3>{{profile.fullName}}</h3>
                    <h6>{{profile.jobTitle}}</h6>
                    <p>{{profile.department}}</p>
                    <a href=mailto:{{profile.userPrincipalName}}>{{profile.userPrincipalName}}</a>
                    <h6> {{profile.organization}}</h6>
         </div>
    </div>
</div>

<div class="col-md-8 no-print">
    <!-- <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)" [itemsPerPage]="10"  [boundaryLinks]="true"></pagination> -->

    <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)"  [boundaryLinks]="true" [maxSize]="10"></pagination>

  </div>  
<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <form [formGroup]="profileForm">
            <div>Organization </div>
                <div>
                    <input 
                    class="form-control"
                    autocomplete="off"
                    id="txtOrganization"
                    name="txtOrganization"
                    placeholder="Organization"
                    type="text"
                    formControlName="organization"
                    typeaheadOptionField="name"
                    [isAnimated]="true"
                    [typeahead]="profileOrgList"  
                    (typeaheadOnSelect)="onOrganizationSelect($event)"                  
                    [typeaheadOptionsLimit]="10"
                    required
                  />
                   
                </div>
            <div>Email *</div>
                <div>
                    <input id="txtemail" type="text" name="txtemail" placeholder="Email Address" 
                    [ngClass]="{'input-error': profileForm.controls.userPrincipalName.touched && profileForm.controls.userPrincipalName.status=='INVALID'}"
                     autocomplete="disabled" formControlName="userPrincipalName" class="form-control" required />
                     <span *ngIf='isUserExists' class="alert-danger">User already exists in the portal.</span>
                </div>
                <div>First Name *</div>
                    <div>
                        <input id="txtfname" type="text" name="txtfirstname" placeholder="First Name"  autocomplete="new-txtfname" 
                        [ngClass]="{'input-error': profileForm.controls.firstName.touched && profileForm.controls.firstName.status=='INVALID'}"
                        formControlName="firstName" class="form-control" required />
                    </div>
                <div>Last Name *</div>
                <div>
                    <input id="txtlname" type="text"  placeholder="Last Name"  
                    [ngClass]="{'input-error': profileForm.controls.lastName.touched && profileForm.controls.lastName.status=='INVALID'}"
                    autocomplete="disabled" formControlName="lastName" class="form-control" required />
                </div>
                <div>Title </div>
                <div>
                    <input id="txttitle" type="text"  placeholder="Title"  autocomplete="disabled" formControlName="title" class="form-control" />
                </div>
                
                <div *ngIf="isFTLAGroup">Phone Number </div>
                <div *ngIf="isFTLAGroup">
                    <input id="txtContact" type="text"  placeholder="Phone Number"  autocomplete="disabled" formControlName="contact" class="form-control" />
                </div>
                <!-- <div *ngIf="isFTLAGroup">Founded </div>
                <div *ngIf="isFTLAGroup"> 
                    <input id="txtFounded" type="number"  placeholder="Founded"  autocomplete="disabled" formControlName="founded" class="form-control" />
                </div>
                <div *ngIf="isFTLAGroup">Headquarters </div>
                <div *ngIf="isFTLAGroup">
                    <input id="txtHeadquaters" type="text"  placeholder="Headquaters"  autocomplete="disabled" formControlName="headquarters" class="form-control" />
                </div>
                <div *ngIf="isFTLAGroup">Staff Size </div>
                <div *ngIf="isFTLAGroup">
                    <input id="txtStaffSize" type="number"  placeholder="Staff Size"  autocomplete="disabled" formControlName="staffsize" class="form-control" />
                </div>
                <div *ngIf="isFTLAGroup">Endowment </div>
                <div *ngIf="isFTLAGroup">
                    <input id="txtendowment" type="number"  placeholder="Endowment"  autocomplete="disabled" formControlName="endowment" class="form-control" />
                </div> -->
                <div *ngIf="isFTLAGroup">Website Address </div>
                <div *ngIf="isFTLAGroup">
                    <input id="txtwebsite" type="url"  placeholder="Website address"  autocomplete="disabled" formControlName="url" class="form-control" />
                    <span class="alert-danger"  *ngIf="!isValidURL">URL is not valid</span>
                </div>
                <div>Profile photo</div>
                <div>
                    <input formControlName="file" id="file" type="file" class="form-control"  (change)="onFileChange($event)">
                </div>
                <div>
                    <img id="filephoto" [src]="imageSrc" >
                </div>
        </form>
    </div>
    
    <div class="col-md-4 px-5">
        <button type="button" (click) = "cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
        <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateUsers()" [ngClass]="{ 'disable-color': viewAsAdmin }" [disabled]="profileForm.invalid">Add</button>
    </div>
  </div>
  <div class="backdrop" *ngIf="isAddOpen"></div>
  
<app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
        <div class="col-sm-12 content-style">
            <div *ngIf="dataItem">
                <span>Are you sure you want to delete {{dataItem.fullname}}?</span>
            </div>
        </div>
        <div class="col-sm-12 footer-style" *ngIf="dataItem">
            <div>
                <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style" (click)="confirmMemberToRemove(dataItem.userId,dataItem.organizationId)">
                    <span>Ok</span>
                </button>
            </div>
        </div>
    </ng-template>
</app-modal>
</div>