import { Component, OnInit, ViewChild } from '@angular/core';
import { compareItems } from 'src/app/common/models/utility';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TechnologiesServices } from '../services/technologies.services';
import { FunctionList, SolutionList, VendorList, PartnerList, TechnologiesModel } from '../models/technologies.model';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { SystemPermissions } from '../../permissions';
import { debug } from 'console';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {
  @ViewChild('confirmationModal', { static: false }) confirmationModalCompoent: ModalComponent;
  @ViewChild('warningModal', { static: false }) warningModal: ModalComponent;
  @ViewChild('functionModal', { static: false }) functionModal: ModalComponent;
  @ViewChild('technologyModal', { static: false }) technologyModal: ModalComponent;
  @ViewChild('vendorModal', { static: false }) vendorModal: ModalComponent;
  @ViewChild('partnerModal', { static: false }) partnerModal: ModalComponent;
  technologiesList: Array<TechnologiesModel>;
  managedTechnologiesList: Array<TechnologiesModel>;
  removedTechnologiesList: Array<TechnologiesModel>;
  formSubmitSuccess: string;
  selectedModerator: any;
  formSubmitError: string;
  manageTechnologies: Array<TechnologiesModel>;
  selectedUser: TechnologiesModel = null;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAddOpen = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  currentPage = 1;
  perPage = 10;
  totalRows = 0;
  technologiesForm: FormGroup;
  searchTerm: string;
  currentUserRole: Roles;
  isTechnologiesExists: boolean;
  selectedTechnologies: TechnologiesModel;
  currentUser: any;
  lastSort: string;
  isAddDataCollectionOpen: boolean;
  dataCollectionForm: FormGroup;
  technologiesAsc: boolean = true;
  solutionAsc: boolean = true;
  vendorAsc: boolean = true;
  partnerAsc: boolean = true;
  productAsc: boolean = true;
  partnerExperienceAsc: boolean = true;
  partnerContactNameAsc: boolean = true;
  partnerContactEmailAsc: boolean = true;
  // lastUpdatedDateTimeAsc:boolean = true;
  buttonText: string = "Add New Item";
  permissions = SystemPermissions;
  functionList: Array<FunctionList>;
  solutionList: Array<SolutionList>;
  vendorList: Array<VendorList>;
  partnerList: Array<PartnerList>;
  isNew: boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  TechnologiesImageURL: string;
  foundationName: string;
  isFunctionLastIndex:boolean;
  functionLastIndex: number;
  functionText:string;
  solutionText:string;
  vendorText:string;
  partnerText:string;
  constructor(private technologiesService: TechnologiesServices,
    private stateService: StateService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public datepipe: DatePipe,
    private router: Router
  ) {
  }

  get isAdmin() { return this.stateService.getRole() === Roles.Admin; }  
  get isSuperAdmin() { return this.stateService.getRole() === Roles.SuperAdmin; }

  ischeckPermissions(permission: string): boolean {
    return this.stateService.permissionCheck(permission);
  }

  ngOnInit() {
    this.managedTechnologiesList = new Array<TechnologiesModel>();
    this.removedTechnologiesList = new Array<TechnologiesModel>();
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    this.isFunctionLastIndex=true;
    this.functionLastIndex=9;
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    this.dropdownList.push({ item_id: 'JDAI', item_text: 'JDAI' });
    this.dropdownList.push({ item_id: 'PT', item_text: 'PT' });
    this.dropdownList.push({ item_id: 'RJJ', item_text: 'RJJ' });
    this.dropdownList.push({ item_id: 'ELS', item_text: 'ELS' });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 4,
      allowSearchFilter: false,
      enableCheckAll: true,
      defaultOpen: true
    };
    this.getTechnologies(this.currentUser.organization);
    
    this.technologiesForm = new FormGroup({
      function: new FormControl(''),
      solution: new FormControl(''),
      vendor: new FormControl(''),
      partner: new FormControl(''),
      // product: new FormControl(''),
      partnerExperience: new FormControl("0"),
      partnerContactName: new FormControl(''),
      // partnerContactEmail: new FormControl(''),
      comments: new FormControl(''),
      currentlyUsed: new FormControl('')
    });
    this.dataCollectionForm = new FormGroup({
      dataCollection: new FormControl('', Validators.required),
    });

    this.getFunctionList();
    this.getPartnerList();
    this.getSolutionList();
    this.getVendorList();
    this.foundationName = this.currentUser.organization;
  }

  getTechnologies(foundationName: string=null) {
    this.managedTechnologiesList.length = 0;
    this.removedTechnologiesList.length = 0;
    this.technologiesService.getTechnologies(foundationName)
      .subscribe(data => {
        this.technologiesList = data;
        // if ((!this.isAdmin && !this.isSuperAdmin) || this.viewAsAdmin) {
        //   let currentUserTechnologiess = this.currentUser.Technologiess as TechnologiesModel[];
        //   let results = currentUserTechnologiess.map(s => s.id);
        //   this.technologiesList = this.technologiesList.filter(s => results.includes(s.id));
        // }
        let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
        this.technologiesList.sort((nodeA, nodeB) => compareItems(nodeA['functionName'], nodeB['functionName']));
        this.manageTechnologies = this.technologiesList.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
        //this.currentPage = 1;
        this.totalRows = this.technologiesList.length;
      });
  }

  getFunctionList(functionText?:string) {
    this.technologiesService.getFunctionList().subscribe(s => {
      this.functionList = s?.sort((nodeA, nodeB) => compareItems(nodeA["itFunction"]?.toLowerCase(), nodeB["itFunction"]?.toLowerCase()));
      if(functionText!=null || functionText!=undefined)
      {
        var currentFunctionValue = s.find(a=>a.itFunction===functionText);
        this.technologiesForm.get('function').setValue(currentFunctionValue.id);
      }
    });
  }

  getSolutionList(solutionText?: string) {
    this.technologiesService.getSolutionList().subscribe(s => {
      this.solutionList = s?.sort((nodeA, nodeB) => compareItems(nodeA["itSolution"]?.toLowerCase(), nodeB["itSolution"]?.toLowerCase()));
      if(solutionText!=null || solutionText!=undefined)
      {
        var currentSolutionValue = s.find(a=>a.itSolution===solutionText);
        this.technologiesForm.get('solution').setValue(currentSolutionValue.id);
      }
    });
  }

  getVendorList(vendorText?:string) {
    this.technologiesService.getVendorList().subscribe(s => {
      this.vendorList = s?.sort((nodeA, nodeB) => compareItems(nodeA["vendor"]?.toLowerCase(), nodeB["vendor"]?.toLowerCase()));
      if(vendorText!=null || vendorText!=undefined)
      {
        var currentVendorValue = s.find(a=>a.vendor===vendorText);
        this.technologiesForm.get('vendor').setValue(currentVendorValue.id);
      }
    });
  }

  getPartnerList(partnerText?: string) {
    this.technologiesService.getPartnerList().subscribe(s => {
      this.partnerList = s?.sort((nodeA, nodeB) => compareItems(nodeA["vendor"]?.toLowerCase(), nodeB["vendor"]?.toLowerCase()));
      if(partnerText!=null || partnerText!=undefined)
      {
        var currentVendorValue = s.find(a=>a.vendor===partnerText);
        this.technologiesForm.get('partner').setValue(currentVendorValue.id);
      }
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.currentPage = event.page;
    let skipRows = (this.currentPage - 1) * this.perPage;
    if (this.searchTerm == undefined || this.searchTerm?.length === 0) {
      this.manageTechnologies = this.technologiesList?.slice(skipRows, this.currentPage * this.perPage);
    }
    else {
      this.manageTechnologies = this.managedTechnologiesList?.slice(skipRows, this.currentPage * this.perPage);
    }
  }

  resetAddForm() {
    this.selectedTechnologies = null;
    this.isAddOpen = false;
    this.TechnologiesImageURL = null;
    this.technologiesForm.reset();
    this.technologiesForm.get('function').setValue('');
    this.technologiesForm.get('solution').setValue('');
    this.technologiesForm.get('vendor').setValue('');
    this.technologiesForm.get('partner').setValue('');
    this.technologiesForm.get('partnerExperience').setValue('');
     this.technologiesForm.get('currentlyUsed').setValue('');
  }

  confirmTechnologiesToRemove(Technologies: TechnologiesModel, rowIndex) {
    if (Technologies) {
      this.removedTechnologiesList.push(Technologies);
      this.technologiesList = this.technologiesList.filter(x => x.id !== Technologies.id);
      if (this.removedTechnologiesList.length > 0) {
        this.technologiesService.removeTechnologies(Technologies)
          .subscribe((data) => {
            this.formSubmitSuccess = "Successfully removed Technologiess";
           this.getTechnologies(this.currentUser.organization);
          });
      }
      if(this.technologiesList.length<=10)
      {
        this.currentPage = 1;
        this.totalRows=this.technologiesList.length;
      }
      let skipRows = this.currentPage==0? this.currentPage:(this.currentPage - 1) * this.perPage;
      this.manageTechnologies = this.technologiesList.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);
      this.closeConfirmModal();
    }
  }

  openConfirmationModal(Technologies: TechnologiesModel, rowindex:number) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      this.defaultConfirmationModalEntity(Technologies);
    }
  }

  defaultConfirmationModalEntity(Technologies: TechnologiesModel) {
    this.confirmationModalCompoent.entityData = {
      entityData: {
        deleteTechnologies: Technologies
      }
    };
  }

  updateConfirmationModalEntity(Technologies: TechnologiesModel, moderators: TechnologiesModel[], rowIndex: number) {
    const users = moderators.filter(x => x.id !== Technologies.id);
    this.confirmationModalCompoent.entityData = {
      entityData: {
        deleteUser: Technologies,
        manageUsers: users,
        manager: users.length === 1 ? users[0] : null,
        tasksExists: true,
        managersExists: users.length > 0,
        rowIndex: rowIndex
      }
    };
    this.selectedModerator = users.length > 0 ? users[0] : null;
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.selectedModerator = null;
      this.confirmationModalCompoent.closeModalWindow();
    }
  }

  openWarningModal() {
    if (this.warningModal) {
      this.warningModal.showModal();
    }
  }

  closeWarningModal() {
    if (this.warningModal) {
      this.warningModal.closeModalWindow();
    }
  }

  addTechnologies() {
    this.isAddOpen = true;
    this.isNew = true;
  }

  openDataCollection() {
    this.isAddDataCollectionOpen = true;
  }

  cancelAddDC() {
    this.isAddDataCollectionOpen = false;
  }

  get enableSave(): boolean {
    if (this.technologiesList.length > 0 && this.dataCollectionForm.get('dataCollection').value != '') {
      return true;
    } else if (this.managedTechnologiesList.length > 0) {
      return true;
    }
    else if (this.removedTechnologiesList.length > 0) {
      return true;
    }
    return false;
  }

  AddUpdateTechnologies(): void {

    if (this.selectedTechnologies === null || this.selectedTechnologies === undefined) {
      this.selectedTechnologies = new TechnologiesModel().addTechnologies(-(this.managedTechnologiesList.length + 1), 0, 0, 0, 0, "", "", "", "",false)
    }
    this.selectedTechnologies.itSolutionId = this.technologiesForm.get('solution').value;
    this.selectedTechnologies.itFunctionId = this.technologiesForm.get('function').value;
    this.selectedTechnologies.vendorId = this.technologiesForm.get('vendor').value;
    this.selectedTechnologies.partnerId = (this.technologiesForm.get('partner').value == '') ? null : this.technologiesForm.get('partner').value ;
    this.selectedTechnologies.partnerContactName = this.technologiesForm.get('partnerContactName').value;
    this.selectedTechnologies.partnerExperience = this.technologiesForm.get('partnerExperience').value;
    this.selectedTechnologies.comments = this.technologiesForm.get('comments').value;
    this.selectedTechnologies.currentlyUsed = (this.technologiesForm.get('currentlyUsed').value == '' || this.technologiesForm.get('currentlyUsed').value == '0' || this.technologiesForm.get('currentlyUsed').value == null ) ? false:true;
    // this.selectedTechnologies.product = this.technologiesForm.get('product').value;
    this.selectedTechnologies.foundationName = this.foundationName;
    // this.selectedTechnologies.partnerContactEmail = this.technologiesForm.get('partnerContactEmail').value;
    let item = this.manageTechnologies.find(item => item.id === this.selectedTechnologies.id);
    let mitem = this.managedTechnologiesList.find(item => item.id === this.selectedTechnologies.id);
    if (item) {
      if (item) {

        item.updatedDateTime = formatDate(Date.now().toString(), "yyyy-MM-dd HH:mm", "en-US");;
      }
      this.managedTechnologiesList.push(this.selectedTechnologies);
    }
    else {
      this.manageTechnologies.push(this.selectedTechnologies);
      this.managedTechnologiesList.push(this.selectedTechnologies);
    }

    let technology = new TechnologiesModel().addTechnologies(this.selectedTechnologies.id, this.selectedTechnologies.itSolutionId, this.selectedTechnologies.itFunctionId,  this.selectedTechnologies.vendorId, this.selectedTechnologies.partnerId,  this.selectedTechnologies.partnerContactName,
       this.selectedTechnologies.partnerExperience, this.selectedTechnologies.comments, this.selectedTechnologies.foundationName, this.selectedTechnologies.currentlyUsed);

    this.saveTechnologies(technology);
    this.resetAddForm();
  }

  onItemSelect(item: any) {
  }

  onItemDeSelect(item: any) {
  }

  onOptionsFunctionSelected(value:any){
    if(value.target.value!=undefined || value.target.value!="")
    {
      if(value.target.value=="functionLastIndex")
      {
        this.AddFunction();
      }
    }
}

onOptionsSolutionSelected(value:any){
  if(value.target.value!=undefined || value.target.value!="")
  {
    if(value.target.value=="solutionLastIndex")
    {
      this.AddTechnology();
    }
  }
}

onOptionsVendorSelected(value:any){
  if(value.target.value!=undefined || value.target.value!="")
  {
    if(value.target.value=="vendorLastIndex")
    {
      this.AddVendor();
    }
  }
}

onOptionsPartnerSelected(value:any){
  if(value.target.value!=undefined || value.target.value!="")
  {
    if(value.target.value=="partnerLastIndex")
    {
      this.AddPartner();
    }
  }
}

  saveTechnologies(Technologiesdata: any): void {
    if (this.managedTechnologiesList.length > 0 || this.removedTechnologiesList.length > 0) {
      this.managedTechnologiesList.map(s => {
        s.updatedDateTime = new Date().toJSON();
        // Technologiesdata.set('updatedDateTime',new Date().toJSON());
        return s;
      });
      if (this.managedTechnologiesList.length > 0) {
        this.technologiesService.saveTechnologies(Technologiesdata)
          .subscribe((data) => {
            this.formSubmitSuccess = "Successfully saved Technologies";
            this.getTechnologies(this.currentUser.organization);
          });
      }
      
    }
  }

  AddUpdateDataCollection(): void {
    this.isAddDataCollectionOpen = false;
  }

  removeTechnologies(Technologies: TechnologiesModel,rowindex:number):void{
    this.openConfirmationModal(Technologies, rowindex);
    
  }

  
  AddFunction(): void {
    if (this.functionModal) {
      this.functionModal.entityData = {
        headerText: 'New Function'
      };
      this.functionModal.showModal();
    }
  }
  
  saveFunction(): void {
  var functionText = this.functionText;
    if(functionText!=null)
    {
      this.technologiesService.saveFunction(functionText)
      .subscribe((data) => {
        this.formSubmitSuccess = "Successfully saved function";
        this.getFunctionList(functionText);
       
      });
      this.technologiesForm.get('function').setValue('');
      this.functionText = "";
      this.functionModal.closeModalWindow();
    }
  }


  
  savesolution(): void {
  var solutionText = this.solutionText;
    if(solutionText!=null)
    {
      this.technologiesService.saveSolution(solutionText)
      .subscribe((data) => {
        this.formSubmitSuccess = "Successfully saved technology";
        this.getSolutionList(solutionText);
        this.technologiesForm.get('solution').setValue('');
        this.solutionText = "";
        this.technologyModal.closeModalWindow();
      });
    }
  }

  savePartner(): void {
    var partnerText = this.partnerText;
      if(partnerText!=null)
      {
        this.technologiesService.savePartner(partnerText)
        .subscribe((data) => {
          this.formSubmitSuccess = "Successfully saved partner";
          this.getPartnerList(partnerText);
          this.technologiesForm.get('partner').setValue('');
          this.partnerText = "";
          this.partnerModal.closeModalWindow();
        });
      }
    }

    saveVendor(): void {
      var vendorText = this.vendorText;
        if(vendorText!=null)
        {
          this.technologiesService.savePartner(vendorText)
          .subscribe((data) => {
            this.formSubmitSuccess = "Successfully saved vendor";
            this.getVendorList(vendorText);
            this.vendorText = "";
            this.vendorModal.closeModalWindow();
          });
        }
      }







  AddTechnology(): void {
    if (this.technologyModal) {
      this.technologyModal.entityData = {
        headerText: 'New Technology'
      };
      this.technologyModal.showModal();
    }
  }

  AddVendor(): void {
    if (this.vendorModal) {
      this.vendorModal.entityData = {
        headerText: 'New Vendor'
      };
      this.vendorModal.showModal();
    }
  }

  AddPartner(): void {
    if (this.partnerModal) {
      this.partnerModal.entityData = {
        headerText: 'New Partner'
      };
      this.partnerModal.showModal();
    }
  }

  closeFunctionModal() {
    this.functionText = "";
    this.technologiesForm.get('function').setValue('');
      this.functionModal.closeModalWindow();

  }

  closePartnerModal() {
    this.partnerText = "";
    this.technologiesForm.get('partner').setValue('');
      this.partnerModal.closeModalWindow();

  }

  closeVendorModal() {
    this.vendorText = "";
    this.technologiesForm.get('vendor').setValue('');
      this.vendorModal.closeModalWindow();

  }

  closeTechnologyModal() {
    this.solutionText = "";
    this.technologiesForm.get('solution').setValue('');
      this.technologyModal.closeModalWindow();

  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }

  search(): void {
    var functionList, solutionList, vendorList, productList, searchEmailList, vendorContactList;
    if (this.searchTerm.length === 0) {
      this.currentPage = 0;
      this.manageTechnologies = this.technologiesList?.sort((nodeA, nodeB) => compareItems(nodeA["functionName"]?.toLowerCase(), nodeB["functionName"]?.toLowerCase()))
        .filter(x => x.functionName?.toLowerCase()).slice(this.currentPage, this.perPage);
      this.totalRows = this.technologiesList.length;
    }
    else {
      this.managedTechnologiesList = new Array<TechnologiesModel>;
      functionList = this.technologiesList.filter(x => x.functionName?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      solutionList = this.technologiesList.filter(x => x.solutionName?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      vendorList = this.technologiesList.filter(x => x.partnerName?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      // productList = this.technologiesList.filter(x => x.product?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      searchEmailList = this.technologiesList.filter(x => x.partnerContactEmail?.toLowerCase().includes(this.searchTerm.toLowerCase()));
      vendorContactList = this.technologiesList.filter(x => x.partnerContactName?.toLowerCase().includes(this.searchTerm.toLowerCase()));

      if (functionList != null || functionList != undefined) {
        this.managedTechnologiesList = this.searchConcat(this.managedTechnologiesList, functionList);
      }
      if (searchEmailList != null || searchEmailList != undefined) {
        this.managedTechnologiesList = this.searchConcat(this.managedTechnologiesList, searchEmailList);
      }
      if (solutionList != null || solutionList != undefined) {
        this.managedTechnologiesList = this.searchConcat(this.managedTechnologiesList, solutionList);
      }

      if (vendorList != null || vendorList != undefined) {
        this.managedTechnologiesList = this.searchConcat(this.managedTechnologiesList, vendorList);
      }

      // if (productList != null || productList != undefined) {
      //   this.managedTechnologiesList = this.searchConcat(this.managedTechnologiesList, productList);
      // }
      if (vendorContactList != null || vendorContactList != undefined) {
        this.managedTechnologiesList = this.searchConcat(this.managedTechnologiesList, vendorContactList);
      }
      const unique = [...new Set(this.managedTechnologiesList.map((item) => item?.partnerContactEmail))];

      var result = this.removeDuplicates(this.managedTechnologiesList);
      this.managedTechnologiesList = result;
      this.totalRows = result.length;
      let skipRows = this.currentPage == 0 ? this.currentPage : (this.currentPage - 1) * this.perPage;
      this.manageTechnologies = this.managedTechnologiesList.slice(0, 10);

    }
  }

  removeDuplicates(arr: TechnologiesModel[]) {
    let unique = new Array<TechnologiesModel>();
    arr.forEach(element => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
    return unique;
}

  searchConcat(technologiesTotalList: TechnologiesModel[], technologiesList: TechnologiesModel[]): TechnologiesModel[] {
    var result = technologiesTotalList;
    if (technologiesList?.length > 0) {
      technologiesList.forEach(a => {
        result = result.concat(a);
      });
    }
    return result;
  }

  toggleTag(column: string) {
    let value = "asc";
    if (column == 'functionName') {
      this.technologiesAsc = !this.technologiesAsc;
      value = this.technologiesAsc ? "asc" : "desc"
    }
    if (column == 'solutionName') {
      this.solutionAsc = !this.solutionAsc;
      value = this.solutionAsc ? "asc" : "desc"
    }
    else if (column == 'vendorName') {
      this.vendorAsc = !this.vendorAsc;
      value = this.vendorAsc ? "asc" : "desc"
    }
    else if (column == 'partnerExperience') {
      this.partnerExperienceAsc = !this.partnerExperienceAsc;
      value = this.partnerExperienceAsc ? "asc" : "desc"
    }

    else if (column == 'partnerContactName') {
      this.partnerContactNameAsc = !this.partnerContactNameAsc;
      value = this.partnerContactNameAsc ? "asc" : "desc"
    }
    this.sortOn(column, value);
  }

  sortOn(column: string, type: string): void {
    if (column !== null) {
      var totalSortTechnologies = new Array<TechnologiesModel>();
      if (this.searchTerm == undefined || this.searchTerm?.length === 0) {
        totalSortTechnologies = this.technologiesList;
      }
      else {
        totalSortTechnologies = this.managedTechnologiesList;
      }
      let skipRows = this.currentPage == 0 ? this.currentPage : (this.currentPage - 1) * this.perPage;
      if (type == "desc") {
        totalSortTechnologies = totalSortTechnologies?.sort((nodeA, nodeB) => compareItems(nodeB[column]?.toLowerCase(), nodeA[column]?.toLowerCase()));
        this.lastSort = column;
        totalSortTechnologies = this.SortNull(totalSortTechnologies, column);
      }
      else {
        totalSortTechnologies = totalSortTechnologies?.sort((nodeA, nodeB) => compareItems(nodeA[column]?.toLowerCase(), nodeB[column]?.toLowerCase()));
        totalSortTechnologies = this.SortNull(totalSortTechnologies, column);
      }
      if (this.searchTerm == undefined || this.searchTerm?.length === 0) {
        this.technologiesList = totalSortTechnologies;
      }
      else {
        this.managedTechnologiesList = totalSortTechnologies;
      }
      this.manageTechnologies = totalSortTechnologies?.slice(skipRows, this.currentPage == 0 ? 10 : this.currentPage * this.perPage);//this.sortUsersColumn(totalSortUser,column,skipRows)?.slice(skipRows, this.currentPage==0?10:this.currentPage*this.perPage);

      this.totalRows = totalSortTechnologies.length;
    }
  }

  SortNull(techTotalList: TechnologiesModel[], column: string): TechnologiesModel[] {
    var result = new Array<TechnologiesModel>();
    var notNullData = new Array<TechnologiesModel>();
    var nullData = new Array<TechnologiesModel>();

    switch (column.toLowerCase()) {
      // case "product":
      //   notNullData = techTotalList.filter(a => a.product != null && a.product != "");
      //   nullData = techTotalList.filter(a => a.product == null || a.product == "");
      //   result = notNullData.concat(nullData);
      //   break;
      case "partnerExperience":
        notNullData = techTotalList.filter(a => a.partnerExperience != null && a.partnerExperience != "");
        nullData = techTotalList.filter(a => a.partnerExperience == null || a.partnerExperience == "");
        result = notNullData.concat(nullData);
        break;
      case "partnerContactName":
        notNullData = techTotalList.filter(a => a.partnerContactName != null && a.partnerContactName != "");
        nullData = techTotalList.filter(a => a.partnerContactName == null || a.partnerContactName == "");
        result = notNullData.concat(nullData);
        break;
      // case "partnerContactEmail":
      //   notNullData = techTotalList.filter(a => a.partnerContactEmail != null && a.partnerContactEmail != "");
      //   nullData = techTotalList.filter(a => a.partnerContactEmail == null || a.partnerContactEmail == "");
      //   result = notNullData.concat(nullData);
      //   break;
      default:
        result = techTotalList;
        break;
    }
    return result;
  }

  editTechnologies(Technologies: TechnologiesModel): void {
    this.selectedTechnologies = Technologies;
    var partExpValue="0";
    if(this.selectedTechnologies.partnerExperience!='' || this.selectedTechnologies.partnerExperience !=null)
    {
      if(this.selectedTechnologies.partnerExperience?.toLowerCase()=='fair')
      partExpValue='Fair';
      else if(this.selectedTechnologies.partnerExperience?.toLowerCase()=='good')
      partExpValue="Good";
      else if(this.selectedTechnologies.partnerExperience?.toLowerCase()=='excellent')
      partExpValue="Excellent";
    }

    this.isAddOpen = true;
    this.isNew = false;

    this.technologiesForm.get('solution').setValue(this.selectedTechnologies.itSolutionId);
    this.technologiesForm.get('function').setValue(this.selectedTechnologies.itFunctionId);
    this.technologiesForm.get('vendor').setValue(this.selectedTechnologies.vendorId??'');
    this.technologiesForm.get('partner').setValue(this.selectedTechnologies.partnerId??'');
    this.technologiesForm.get('partnerContactName').setValue(this.selectedTechnologies.partnerContactName);
    this.technologiesForm.get('partnerExperience').setValue(partExpValue);
    this.technologiesForm.get('comments').setValue(this.selectedTechnologies.comments);
    this.technologiesForm.get('currentlyUsed').setValue(this.selectedTechnologies.currentlyUsed);
    // this.technologiesForm.get('product').setValue(this.selectedTechnologies.product);
    // this.technologiesForm.get('partnerContactEmail').setValue(this.selectedTechnologies.partnerContactEmail);


    this.selectedItems = [];
    this.buttonText = "Save Technologies";
  }

  getSlackTest() {
    this.technologiesService.getSlackMessage().subscribe(s => {
      //this.functionList = s;
      console.log("testing11111111");
    });
  }
}
