<div class="reportingImgcontainer">
    <div class="overcontainer">
        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-8">
                <div class="heading">
                    <div class="headingContent">
                        <div class="mainheading">
                            <h1>Management Analytics and Reporting Center
                            </h1>
                        </div>                   
                    </div>
                </div>                      
            </div>
            <div class="col-md-2">            
            </div>
        </div>   
        <div class="row">
            <div class="col-md-12">
                <div class="reportheading">
                    <div class="headingContent">
                        <div *ngIf="showReportsLink">
                            <div class="dashboard-heading">
                                <p>Management Dashboards</p>
                            </div>
                            <div class="dashboard-content">
                                <a *ngFor="let report of reportsLinks" class="report-name-tile" (click)="gotoReports(report);">
                                    <div class="dashboard-inline" >
                                        <div class="dashboard-wrap" >
                                            <p>{{report.reportName}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row dashboardLinks">
            <div class="col-md-12">
                    <div class="headingContent">
                   
                    <div class="dashboard-heading reporting-heading">
                                <p>Management Links</p>
                    </div>
                    <div class="dashboard-content">
                        <a class="dashboardLinks-tiles" href="https://aecf.okta.com/home/concur/0oa402w1mq13Vl18k0x7/615" title="Concur">
                            <img src="https://ok2static.oktacdn.com/fs/bcg/4/gfsntcbynrXoUDut00x7" alt="Concur" class="responsive"/>
                        </a> 
                        <a class="dashboardLinks-tiles" href="https://aecf.okta.com/home/intacct/0oakm65c19O6y9sbU0x7/176" title="Intacct">
                            <img src="https://ok2static.oktacdn.com/fs/bcg/4/gfsnvw41wyPUhymJ10x7" alt="Intacct Production" class="responsive" /></a>
                        <a class="dashboardLinks-tiles" href="https://aecf.okta.com/home/docusign/0oaohgkgniZBvTqXQ0x7/292" title="Docusign">
                            <img src="https://ok2static.oktacdn.com/fs/bcg/4/gfsqkfes6amh7KJiE0x7" alt="DocuSign" class="responsive" /></a>
                        <a class="dashboardLinks-tiles" href="https://aecf.okta.com/home/bookmark/0oamsurasjFjvs5au0x7/2557" title="GMS" >
                            <img src="https://ok2static.oktacdn.com/fs/bco/4/fs0q4ltr30n9DlqC30x7" alt="GMS (First Log into Office 365)" class="responsive"/></a>
                        <a class="dashboardLinks-tiles" href="https://aecf.okta.com/home/adp/0oaa5wbjtcKxxsdWq0x7/aln5nvs4b7K6u2qfZ0g7" title="ADP" >
                            <img src="https://ok2static.oktacdn.com/fs/bcg/4/gfsn1q09vs7KKRDQQ0x7" alt="ADP" class="responsive" /></a>
                    </div>
            </div>
        </div>
    </div>
    <app-news-feed-component *ngIf="false"></app-news-feed-component>
</div>
    