<div class="search-results-container">
  <div class="row refine-row">
    <div class="filter-tag-header refine-tag" [ngClass]="[(isAllGroup || isFTLA) ? 'col-md-3' : 'col-md-2']" (click)="toggleRefineTag()"><em class='refine_icon fas fa-sliders-h'></em>Refine</div>
    <div class="searchComponentContainer" [ngClass]="[(isAllGroup || isFTLA) ? 'col-md-9' : 'col-md-10']">
      <app-search-component [resultSubmitted]=true></app-search-component>
    </div>
  </div>
  <div class="row dv-search-results-container">
    <div class="filter-tags" [ngClass]="{'hide': !showFilters, 'col-md-3': (isAllGroup || isFTLA), 'col-md-2': !(isAllGroup || isFTLA)}">
      <accordion class="search_facets_accordion">
        <ng-container *ngFor="let facet of facets | keyvalue : returnZero; let i = index">
          <accordion-group [panelClass]="accordionPanelClass" *ngIf="(facet.key != 'programArea' && facet.key != 'location' && facet.key != 'community' && (isAllGroup || isFTLA)) || !(isAllGroup || isFTLA)" [isOpen]="selectedAccordion == facet.key" (isOpenChange)="isOpenChange(facet.key, $event)">
            <div class="filter-tag-header" accordion-heading *ngIf="facet.key!='jurisdiction'">
                <span>{{ facetItemKeys[facet.key] | labeltransform | titlecase }}</span><i class='arrow_icon fas fa-angle-down'></i>
            </div>
            <div class="filter-tag-header" accordion-heading *ngIf="facet.key=='jurisdiction' && !(isAllGroup||isFTLA)">
                <span>{{ "Location" }}</span><i class='arrow_icon fas fa-angle-down'></i>
            </div>
            <div class="refine-tags-option" id="{{ facet.key }}" [ngClass]="{'disable': isFacetDisabled}">
              <label class="clear-text" *ngIf="(!(isAllGroup || isFTLA) || ((isAllGroup || isFTLA) && facet.key !== 'topic'))"><a (click)="clearFacetSelection(facet.key + 'Facet', 'clearAllChecked')">CLEAR <i class='close_icon fas fa-times'></i></a></label>
              <label class="clear-text" *ngIf="facet.key == 'topic' && (isAllGroup || isFTLA)"><a (click)="clearFacetSelection(facet.key, 'clearAllChecked')">CLEAR <i class='close_icon fas fa-times'></i></a></label>
              <ul dropdown class="btn-group location-tags-checkbox-list locationTag" [insideClick]="true" #dropdown="bs-dropdown" *ngIf="facet.key == 'topic' && (isAllGroup || isFTLA)" [autoClose]="false">
                <li dropdown *ngFor="let facetItems of facet.value; let items = index" role="menuitem" [insideClick]="true" class="pr-15" [isOpen]="selectedTopic == facetItems.topic" (onShown)="selectedTopic = facetItems.topic">
                  <input type="checkbox" (change)="getFacetedSearchResults(facet.key, facetItems.topic, $event)" [checked] ="isChecked(facetsGroup[facet.key + 'Facet'], facetItems.topic)">
                  <span *ngIf="facetItems.programs.length > 0" dropdownToggle class="dropdown-toggle"> {{ facetItems.topic }} <span *ngIf="facetItems.count">({{ facetItems.count }})</span><i class='dropDownIcon fas fa-angle-down'></i></span>
                  <span *ngIf="facetItems.programs.length == 0" class="dropdown-toggle"> {{ facetItems.topic }} <span *ngIf="facetItems.count">({{ facetItems.count }})</span></span>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu pl-25" role="menu" aria-labelledby="button-basic">
                    <li *ngFor="let program of facetItems.programs">
                      <input (change)="getFacetedSearchResults('programArea', program.program, $event)" [checked] ="isChecked(facetsGroup['programAreaFacet'], program.program)"
                       type="checkbox" value='{{ program.program }}'><span>{{ program.program }} ({{ program.count }})</span>
                    </li>
                  </ul>
                </li>
              </ul>
              <ng-container *ngIf="(!(isAllGroup || isFTLA) || ((isAllGroup || isFTLA) && facet.key !== 'topic' && facet.key !== 'programArea')) && facet.key !== 'publisher'">
                <ul class="refine-tags-checkbox-list" *ngIf="facet.key !== 'location'; else locationTag">
                  <ng-container *ngFor="let facetItems of facet.value; let items = index">
                    <li *ngIf="facetItems.value != 'null'"><input (change)="getFacetedSearchResults(facet.key, facetItems.value, $event)"
                      [checked] ="isChecked(facetsGroup[facet.key + 'Facet'], facetItems.value)" type="checkbox" value='{{ facetItems.value }}'><span>{{ facetItems.value }} ({{ facetItems.count }})</span></li>
                  </ng-container>
                </ul>
              </ng-container>
              <!-- <ng-container *ngIf="facet.key == 'publisher' && false">
                <ul class="refine-tags-checkbox-list">
                  <ng-container *ngFor="let facetItems of facet.value; let items = index">
                  <li *ngIf="facetItems.value != 'null'"><input (change)="getFacetedSearchResults('publisher', facetItems.value, $event)"
                      [checked] ="isChecked(facetsGroup['publisherFacet'], facetItems.value)" type="checkbox" value='{{ facetItems.value }}'><span>{{ facetItems.value }} ({{ facetItems.count }})</span></li>
                  </ng-container>
                </ul>
              </ng-container> -->
            </div>
          </accordion-group>
        </ng-container>
        <!-- <accordion-group [panelClass]="accordionPanelClass" *ngIf="false">
          <div class="filter-tag-header" accordion-heading>
            <span>Publication Date</span><i class='arrow_icon fas fa-angle-down'></i>
          </div>
          <div class="refine-tags-option" id="publicationDate">
            <label class="clear-text"><a (click)="onDateClear()">CLEAR <i class='close_icon fas fa-times'></i></a></label>
            <div class="container-fluid searchDatePicker">
              <div class="datecol">
                <input class="form-control datapicker" #drp="bsDatepicker" placement="right" [ngClass]="{'invalid': hasInvalidDate(bsStartValue) || hasDateRangeError() }" [(bsValue)]="bsStartValue" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateValueChange($event)">
              </div>
              <div class="datecolinter">
                <span>-</span>
              </div>
              <div class="datecol">
                <input class="form-control datapicker" #drp="bsDatepicker" placement="right" [ngClass]="{'invalid': hasInvalidDate(bsEndValue) || hasDateRangeError() }" [(bsValue)]="bsEndValue" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateValueChange($event)">
              </div>
              <span *ngIf="hasDateRangeError()" class="invalid-error">Please enter valid date range</span>
            </div>
          </div>
        </accordion-group> -->
      </accordion>
    </div>
    <ng-container *ngIf="resultLoading; else resultContainer">
      <div class="dvSearchResults loading" [ngClass]="{'col-md-10': showFilters && !(isAllGroup || isFTLA), 'col-md-9': showFilters && (isAllGroup || isFTLA), 'col-md-12': !showFilters}">
      </div>
    </ng-container>
    <ng-template #resultContainer>
      <div class="dvSearchResults" [ngClass]="{'col-md-10': showFilters && !(isAllGroup || isFTLA), 'col-md-9': showFilters && (isAllGroup || isFTLA), 'col-md-12': !showFilters}">
        <div class="col-md-12 selected-facets-list">
          <ng-container *ngFor="let item of facetsGroup | keyvalue;">
            <ng-container *ngIf="item.key != 'publicationDateFacet'">
              <label *ngFor="let itemlabel of item.value.split('|');" class="selectedFacet Rectangle" (click)="clearFacetSelection(item.key, itemlabel)" [tooltip]="facetItemKeys[item.key  | slice:0:-5] | labeltransform | titlecase">{{ itemlabel }}
                <em class='close_icon fas fa-times'></em></label>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="totalItems; else emptyContainer">
          <div class="overlayContainer">
            <div class="row result_count_container">
              <div class="col-md-12 result_count">
                <p>
                  {{itemPerPage}} Results shown for ‘{{ searchQuery }}’ / <b>{{ totalItems }} Results Total</b>
                </p>
              </div>
            </div>
            <div *ngIf="showAlert">
              <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000 (onClosed)="onClosedAlert()">{{message}}</alert>
            </div>
            <div class="row result_count_container">
              <div class="col-md-8 favorites_button_container">
                <a (click)="showShareResultsModal(true, false)" id="favoritesButton" class="btn share_button" [ngClass]="{ 'disable': viewAsAdmin | async }" *ngIf="ischeckPermissions(permissions.CanSaveFavorites)"><span>Save as Favorite</span><em class="button-icon fas fa-star"></em></a>
                <a (click)="shareResults(true, true)" *ngIf="isAllGroup || isFTLA" id="copyLinkButton" class="btn share_button"><span>Copy Link</span><em class="button-icon fas fa-copy"></em></a>

              </div>
              <div class="col-md-4 recent_button_container">
                <a id="lnkrelevant" (click)="sortSearch(0)" [ngClass]="{'arrow_box':sortParam === 0}" class="revelant_button btn">RELEVANT</a>
                <label></label>
                <a id="lnkrecent" (click)="sortSearch(1)" [ngClass]="{'arrow_box':sortParam === 1}" class="btn recent_button">RECENT</a>
              </div>
            </div>
            <ng-container *ngFor="let result of results; let i = index">
              <div class="row row_padding">
              </div>
              <div class="search-results-row">
                <div *ngIf="result.document.externalUrl && result.document.externalUrl != 'Not Specified'">
                  <a href="javascript:void(0)" (click)="goToUrl(result.document.externalUrl);" >
                    <label *ngIf="result.document.fileTitle == 'Not Specified'">{{ result.document.fileName | titlecase }} </label>
                    <label *ngIf="result.document.fileTitle != 'Not Specified'">{{ result.document.fileTitle | titlecase }} </label>
                  </a>
                </div>
                <div *ngIf="result.document.externalUrl == 'Not Specified' || result.document.externalUrl === null">
                  <label *ngIf="result.document.fileTitle== 'Not Specified'">{{ result.document.fileName | titlecase }} </label>
                  <label *ngIf="result.document.fileTitle!= 'Not Specified'">{{ result.document.fileTitle | titlecase }} </label>
                </div>
                <div class="tags_container" *ngIf="ischeckPermissions(permissions.ShowHideDocumentTags)">
                  <ng-container *ngFor="let item of result.document.tag">
                    <label *ngFor="let itemval of item.value" (click)="filterTag(item.key, itemval)" [hidden]="itemval == 'Not Specified' || itemval== 'false' || isHidden(item.key)" class="selectedFacet Rectangle">{{ itemval }}</label>
                  </ng-container>
                </div>
                <p class="author_details"> Uploaded {{ result.document.uploadedDate | date: 'MMM d, y hh:mm a' }} by {{ result.document.uploadedBy }} </p>
                <div class="col-md-12 result-description" *ngIf="result.document.description && result.document.description != 'Not Specified'">
                    {{result.document.description}}
                </div>
                <div class="row">
                    <div class="col-md-11 col-xs-11 search-row-buttons">
                      <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'Email' && result.document.fileAttributes['FileType'] !== 'Wmv' && result.document.fileAttributes['FileType'] !== 'WebSite' && result.document.fileAttributes['FileType'] !== 'Archive';">
                        <a *ngIf="result.document.toggle == 0" href="javascript:void(0)" (click)="showPreview(result)"  class="form-control preview_button">
                          <span>Show Preview</span><em class="button-icon fas far fa-eye"></em>
                        </a>
                        <a *ngIf="result.document.toggle == 1" href="javascript:void(0)" (click)="showPreview(result)"  class="form-control preview_button">
                            <span>Hide Preview</span><em class="button-icon fas far fa-eye"></em>
                        </a>
                      </ng-container>
                      <span *ngIf="result.document.fileAttributes['FileType'] === 'Email' || result.document.fileAttributes['FileType'] === 'Wmv' || result.document.fileAttributes['FileType'] === 'WebSite' || result.document.fileAttributes['FileType'] === 'Archive';"
                        class="preview_text_button">
                        Preview Not Available
                      </span>
                      <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'WebSite'">
                        <a (click)="downloadFile(result)" class="form-control searchButton download_button"><span>Download </span><em class='button-icon fas fa-download'></em></a>
                      </ng-container>
                      <ng-container *ngIf="result.document.fileAttributes['FileType'] !== 'WebSite' && (ischeckPermissions(permissions.ShareToSlack))">
                        <a (click)="sendToSlack(result)" class="form-control searchButton slack_button"><span>Send To Slack </span><em class='button-icon fas fa-share-square'></em></a>
                      </ng-container>
                      <a (click)="showShareDocumentModal(result.document)" id="shareDocumentButton" class="form-control share_document_button" [ngClass]="{ 'disable': viewAsAdmin | async }" *ngIf="ischeckPermissions(permissions.ShareDocument)"><span>Share Document</span><em class="button-icon fas fa-share-square"></em></a>
                    </div>
                    <div class="col-md-1 col-xs-1 search-row-file-type">
                      <span class="file_type_label {{ result.document.fileAttributes.CssIcon }}"><em class="{{ result.document.fileAttributes.CssClass }}"></em></span>
                    </div>
                </div>
                <div id="divpreview" class="preview_container_wrapper" *ngIf="result.document.toggle && result.document.containerName != null">
                  <div class="col-md preview_container" id="dvpreviewcol">
                    <iframe *ngIf="result.document.fileAttributes['FileType'] !== 'PDF' && result.document.fileAttributes['FileType'] !== 'image'"
                      [src]='result.document.fileURL'
                      class="preview_iframe"></iframe>
                    <object *ngIf="result.document.fileAttributes['FileType'] === 'PDF';" [data]="result.document.fileURL | safeUrl" type="application/pdf">Preview not available</object>
                    <img [src]="result.document.fileURL | safeUrl"  width="100%" height="100%"
                      *ngIf="result.document.fileAttributes['FileType'] == 'image'"
                    />
                  </div>
                </div>
                <div id="divpreview" class="preview_container_wrapper" *ngIf="result.document.toggle && result.document.containerName == null">
                  <div class="col-md preview_container" id="dvpreviewcol">
                    <p class="filePreviewWarning">Unable to show file preview as container name value is NULL.</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <div>
              <div class="text-right paginationContainer">
                <!-- pager -->
                <pagination [(ngModel)]="currentPage" [totalItems]="totalItems" [maxSize]="maxSize" class="pagination"
                  [boundaryLinks]="true" [rotate]="false"  (click)="getPagedSearchResults()"></pagination>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #emptyContainer>
          <p class="noResults">No results for <b>"{{ searchQuery }}"</b></p>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #locationTag>
  <ng-container *ngIf="isFTLA; else locationTagOther">
    <ul dropdown class="btn-group location-tags-checkbox-list locationTag" [insideClick]=true #dropdown="bs-dropdown" >
      <li dropdown *ngFor="let continentItem of facets['location']" role="menuitem" [insideClick]="true">
        <input type="checkbox" (change)="getFacetedSearchResults('continent', continentItem.continent, $event)" [checked] ="isChecked(facetsGroup['continentFacet'], continentItem.continent)">
        <span *ngIf="continentItem.countryList.length > 0" dropdownToggle class="dropdown-toggle"> {{ continentItem.continent }} <span *ngIf="continentItem.count">({{ continentItem.count }})</span><i class='dropDownIcon fas fa-angle-down'></i></span>
        <span *ngIf="continentItem.countryList.length == 0" class="dropdown-toggle"> {{ continentItem.continent }} <span *ngIf="continentItem.count">({{ continentItem.count }})</span></span>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li dropdown *ngFor="let countryItem of continentItem.countryList" role="menuitem" [insideClick]="true">
            <input type="checkbox" (change)="getFacetedSearchResults('country', countryItem.country, $event)" [checked] ="isChecked(facetsGroup['countryFacet'], countryItem.country)">
            <span *ngIf="countryItem.regionList.length == 0" class="dropdown-toggle"> {{ countryItem.country }} <span *ngIf="countryItem.count">({{ countryItem.count }})</span></span>
            <span *ngIf="countryItem.regionList.length > 0" dropdownToggle class="dropdown-toggle"> {{ countryItem.country }} <span *ngIf="countryItem.count">({{ countryItem.count }})</span><i class='dropdown_arrow_icon fas fa-angle-down'></i></span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li dropdown *ngFor="let regionItem of countryItem.regionList" role="menuitem" [insideClick]="true">
                <input type="checkbox" (change)="getFacetedSearchResults('region', regionItem.region, $event)" [checked] ="isChecked(facetsGroup['regionFacet'], regionItem.region)">
                <span dropdownToggle class="dropdown-toggle">{{ regionItem.region }} <span *ngIf="regionItem.count">({{ regionItem.count }})</span><i *ngIf="regionItem.divisionList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li dropdown *ngFor="let divisionItem of regionItem.divisionList" role="menuitem" [insideClick]="true">
                    <input type="checkbox" (change)="getFacetedSearchResults('division', divisionItem.division, $event)" [checked] ="isChecked(facetsGroup['divisionFacet'], divisionItem.division)">
                    <span dropdownToggle class="dropdown-toggle">{{ divisionItem.division }} <span *ngIf="divisionItem.count">({{ divisionItem.count }})</span><i *ngIf="divisionItem.stateList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li dropdown *ngFor="let stateItem of divisionItem.stateList" role="menuitem" [insideClick]="true">
                        <input type="checkbox" (change)="getFacetedSearchResults('state', stateItem.state, $event)" [checked] ="isChecked(facetsGroup['stateFacet'], stateItem.state)">
                        <span dropdownToggle class="dropdown-toggle">{{ stateItem.state }} <span *ngIf="stateItem.count">({{ stateItem.count }})</span><i *ngIf="stateItem.countyList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li *ngFor="let countyItem of stateItem.countyList" role="menuitem">
                            <input type="checkbox" (change)="getFacetedSearchResults('jurisdiction', countyItem.county, $event)" [checked] ="isChecked(facetsGroup['jurisdictionFacet'], countyItem.county)"><span>{{ countyItem.county }} <span *ngIf="countyItem.count">({{ countyItem.count }})</span></span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>
</ng-template>
<ng-template #visionlocationTag>
</ng-template>
<ng-template #locationTagOther>
  <ng-container *ngIf="!isThriveGroup">
  <ul dropdown  class="btn-group location-tags-checkbox-list locationTagOther" [insideClick]=true #dropdown="bs-dropdown" [isDisabled]="isAllGroup" >
    <li dropdown *ngFor="let countryItem of facets['location']" role="menuitem" [insideClick]="true" [isDisabled]="isAllGroup" >
      <input type="checkbox" [disabled]="isAllGroup" (change)="getFacetedSearchResults('country', countryItem.country, $event)" [checked] ="isChecked(facetsGroup['countryFacet'], countryItem.country)">
      <span dropdownToggle class="dropdown-toggle"> {{ countryItem.country }} <span *ngIf="countryItem.count">({{ countryItem.count }})</span><i *ngIf="countryItem.regionList.length > 0 && !isAllGroup" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
        <li dropdown *ngFor="let regionItem of countryItem.regionList" role="menuitem" [insideClick]="true">
          <input type="checkbox" (change)="getFacetedSearchResults('region', regionItem.region, $event)" [checked] ="isChecked(facetsGroup['regionFacet'], regionItem.region)">
          <span dropdownToggle class="dropdown-toggle">{{ regionItem.region }} <span *ngIf="regionItem.count">({{ regionItem.count }})</span><i *ngIf="regionItem.divisionList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li dropdown *ngFor="let divisionItem of regionItem.divisionList" role="menuitem" [insideClick]="true">
              <input type="checkbox" (change)="getFacetedSearchResults('division', divisionItem.division, $event)" [checked] ="isChecked(facetsGroup['divisionFacet'], divisionItem.division)">
              <span dropdownToggle class="dropdown-toggle">{{ divisionItem.division }} <span *ngIf="divisionItem.count">({{ divisionItem.count }})</span><i *ngIf="divisionItem.stateList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li dropdown *ngFor="let stateItem of divisionItem.stateList" role="menuitem" [insideClick]="true">
                  <input type="checkbox" (change)="getFacetedSearchResults('state', stateItem.state, $event)" [checked] ="isChecked(facetsGroup['stateFacet'], stateItem.state)">
                  <span dropdownToggle class="dropdown-toggle">{{ stateItem.state }} <span *ngIf="stateItem.count">({{ stateItem.count }})</span><i *ngIf="stateItem.countyList.length > 0" class='dropdown_arrow_icon fas fa-angle-down'></i></span>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li *ngFor="let countyItem of stateItem.countyList" role="menuitem">
                      <input type="checkbox" (change)="getFacetedSearchResults('jurisdiction', countyItem.county, $event)" [checked] ="isChecked(facetsGroup['jurisdictionFacet'], countyItem.county)"><span>{{ countyItem.county }} <span *ngIf="countyItem.count">({{ countyItem.count }})</span></span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</ng-container>
</ng-template>

<app-modal #shareResultsDialog contentStyle="share-results-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate let-metaDataForm="dataItem.entityData.shareResultsForm" let-isFavouriteForm="dataItem.entityData.isFavouriteForm">
    <form [formGroup]="shareResultsForm" (ngSubmit)="shareResults(isFavouriteForm, false)">
        <div class="share-results-content">
          <div class="row">
            <div  class="col-sm-4">
                <span>Search Query Title</span>
            </div>
            <div class="col-sm-8">
              <div class="display-text">
                <input id="titleText" type="text" placeholder="Search Query Title" class="form-control" formControlName="title" [ngClass]="{ 'invalid': !validSearchQueryTitle() }" maxlength="250">
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!isFavouriteForm">
            <div  class="col-sm-4">
                <span>Email Address</span>
            </div>
            <div class="col-sm-8">
              <div class="display-text">
                <input id="emailAddressText" type="email" placeholder="Email Address" class="form-control" [(ngModel)]="shareResultEmailAddress"  [pattern]="emailPattern" #emailAddressText="ngModel" [ngModelOptions]="{standalone: true}"  [ngClass]="{ 'invalid': (shareResultsSubmitted && shareResultsFormFields['shareWith'].errors) || (!validateEmailAddress(emailAddressText)) }">
                <span (click)="addEmailAddress(emailAddressText)" ><em class="fa fa-plus"></em></span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="emailAddressAdded" formArrayName="shareWith" class="share-email-addresses">
          <div class="file-row" *ngFor="let item of emailAddressToShare.controls; let i=index">
            <div  class="col-sm-10">
                {{item.value}}
            </div>
            <div class="col-sm-2 delete-icon-col">
                <button class="btn delete-icon" (click)="removeEmailAddress(item.value)">
                    <span class="glyphicon glyphicon-trash"></span>
                </button>
            </div>
          </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" style="width: 100px;" (click)="closeShareResultsModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn" *ngIf="isFavouriteForm">
              <button type="submit" class="btn button-style">
                  <span *ngIf="!isCopyLink">Save as Favorite</span>
                  <span *ngIf="isCopyLink">Copy Link</span>
              </button>
           </div>
            <div class="savebtn" *ngIf="!isFavouriteForm">
                <button type="submit" class="btn button-style">
                    <span>Share Results</span>
                </button>
            </div>
        </div>
    </form>
 </ng-template>
</app-modal>


<app-modal #shareDocumentDialog contentStyle="share-document-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate let-document="dataItem.entityData.document">
    <form [formGroup]="shareDocumentForm" (ngSubmit)="shareDocument(document)">
        <div class="share-document-content">
          <div class="row">
            <div  class="col-sm-4">
                <span>Email Address</span>
            </div>
            <div class="col-sm-8">
              <div class="display-text">
                <input id="emailAddressText" type="email" placeholder="Email Address" class="form-control" formControlName="emailAddress" [ngClass]="{ 'invalid': (!validateEmailAddress(shareDocumentFormFields['emailAddress'])) }">
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 footer-style">
            <div>
                <button type="button" class="btn button-style cancelbtn" style="width: 100px;" (click)="closeShareDocumentModal()">
                    <span>Cancel</span>
                </button>
            </div>
            <div class="savebtn">
                <button type="submit" class="btn button-style">
                    <span>Share Document</span>
                </button>
            </div>
        </div>
    </form>
 </ng-template>
</app-modal>

<div *ngIf="showShareAlert">
  <alert type="success" class="toastr" [dismissible]=true [dismissOnTimeout]=5000 (onClosed)="onClosedAlert()">{{message}}</alert>
</div>