<div class="container role-manage">
    <div class="heading d-flex">
      <div class="d-flex left-heading">
        <div class="col-md-12">
          <label class="title">Reports Configuration</label>
        </div>
      </div>
    </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
        <div class="col-md-12">
            <div class="alert alert-danger" id="formSubmitError">
            {{ formSubmitError }}
            </div>
        </div>
    </div>
    <div class="adminRoleContainer col-md-12">
        <div class="row header m-0">
            <div class="col-sm-4 align-left">
               <span class="user-icon"><em class="fa fa-chart-line"></em></span>Report Name
            </div>
            <div class="col-sm-3 align-left">                
                <span class="header-icon"><em class="fas fa-users"></em></span>Department
            </div>
            <div class="col-sm-2 text-center">
                <span><span class="header-icon"><em class="fas fa-unlock"></em></span>Navigation</span>
            </div>   
            <div class="col-sm-1 text-center" *ngIf="(isSuperAdmin|| isAdmin)">
               
            </div>        
        </div>
        <div class="row m-0">
            <div class="row" *ngFor="let report of reportconfigurations; let rowIndex=index;">
                <div class="col-sm-4 align-left">
                   {{report.reportName}}
                 </div>
                 <div class="col-sm-3 align-left">                
                    {{report.departmentAccess}}
                 </div>
                 <div class="col-sm-2 text-center">
                    <input
                    type="checkbox"
                    [disabled]="true"
                    [checked]="report.accessEnabled"
                    [ngModelOptions]="{standalone: true}"/>
                 </div>   
                 <div class="col-sm-1 text-center" *ngIf="(isSuperAdmin|| isAdmin)">
                     <a (click)="EditReport(report.reportId,report.reportName,report.departmentAccess,report.accessEnabled)"><i class="fa fa-edit"></i> </a>
                 </div>        
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div *ngIf="formSubmitSuccess">
                <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
            </div>
        </div>
        <div class="pull-right">
            <button type="button" id="btnsave" class="btn btn-secondary button-style" (click)="saveUpdateReports()" [ngClass]="{ 'disable-color': viewAsAdmin }">Save</button>
            <button type="button" id="btncancel" class="btn btn-secondary button-style back-button buttonpadding" [routerLink]="['/']">Cancel</button>
        </div>
    </div>
</div>

<div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
        <label for="txt-user">Report Name</label>
        <input [(ngModel)]="selectedReport.reportName"
            placeholder="Edit Report Name"
            class="form-control select">
        <div *ngIf="!selectedReport.reportName" class="text-danger small">
            Report name is required.
        </div>
    </div>
    <div class="form-group">
        <label for="txt-role">Department</label>
        <div class="clearfix px-15">
            <select [(ngModel)]="selectedReport.departmentAccess"  class="form-control" >
                <option *ngFor="let dept of departmentlist; let rowIndex=index;"  [ngValue]="dept" [selected]="dept == this"  >
                   {{dept}}
                </option>
         </select>
        </div>  
        <div class="clearfix px-15">
            <label for="txt-role">Navigation</label>
            <input
            type="checkbox"
            [(ngModel)]="selectedReport.accessEnabled"
            [checked]="selectedReport.accessEnabled"
            [ngModelOptions]="{standalone: true}"/>
        </div>  
    </div>
    <div class="col-md-4 px-5">
        <button type="button" (click)="updateReport()" [disabled]="!(isAdmin || isSuperAdmin)"  class="btn btn-secondary button-style">Update</button>
    </div>
  </div>
<div class="backdrop" *ngIf="isAddOpen"></div>
