<div class="container dropbox-container">
  <div class="row">
    <div class="col-md-12">
      <div class="breadcrumbs">
        <ul>
            <li>
            <a (click)="moveToFolder('')">{{ '' ? 'Home' : ''}}<i class="fa fa-home"></i></a>
            <a *ngFor="let path of pathArray" (click)="moveToFolder(path.fullpath)" routerLink="/{{path.fullpath}}" routerLinkActive="active">{{path.path}} <span>/</span></a>
            <a *ngIf="parentFolder.length > 0" (click)="moveToFolder( parentFolder[parentFolder.length - 1].fullpath)" routerLinkActive="active">
                <i class="fa fa-level-up-alt"></i>
                </a>
            </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="searchContainer">
        <form class="form-group">
          <div class="input-group">
            <input class="form-control" type="text" name="search" placeholder="Search" 
                    required minlength="1" [(ngModel)]="query">
            <div class="input-group-append">
                <button type="submit" (click)="searchFiles($event)" class="btn btn-outline-primary"><i class="fas fa-search"></i></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-6">
        <div class="uploadContainer">
            <form class="form-group">
              <div class="fileContainer pull-right">
                Upload       
                <input type="file" id="file-upload" name="upload " (change)="storeFiles($event.target.files)" [(ngModel)]="filename.name"  />
              </div>
            </form>
        </div>
    </div>
    <div class="col-md-12" *ngIf="gotMatch"> {{'We found '+ matches + ' matches of your results' }}</div>
  </div>
  <div class="row">
    <div class="col-12 table-responsive">
      <table class="table table-hover dropbox">
        <thead>
          <tr>
            <th>Name</th>
            <th>Last Modified</th>
            <th>Size</th>
            <th><i class="fa fa-list-ul"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="entries.length < 1"> This folder is empty...</tr>
          <tr *ngFor="let item of entries">
            <ng-template *ngIf="item['.tag'] === 'folder';then thenBlock; else elseBlock"></ng-template>
            <ng-template #thenBlock>
              <td>
                <i class="fa fa-folder"></i>
                <a (click)="moveToFolder(item.path_lower)" routerLinkActive="active"> {{ item.name }}</a>
              </td>
              <td></td>
              <td></td>
              <td>
                <div class="actions">
                    <a (click)="delete($event, item.path_lower)" class="">
                        <i class="fa fa-trash-alt"></i>
                      </a>
                      <a class="" (click)="download($event, item.path_lower, item.name)">
                        <i class="fa fa-download"></i>
                      </a>
                </div>
              </td>
            </ng-template>
            <ng-template #elseBlock>
              <td>
                <div>
                  <img *ngIf="isImage(item.path_lower)" alt="bild" width="18" height="18" [id]="item.path_lower" class="thumbnail" [src]="sanitize(item.fileUrl)">
                  <i *ngIf="!isImage(item.name)" [ngClass]="item.typeClass"></i>
                  <a (click)="download($event, item.path_lower, item.name)" href="">
                    <span> {{ item.name }}</span>
                  </a>
                </div>
              </td>
              <td>{{ item.client_modified | date:'MM-dd-yyyy HH:mm' }}</td>
              <td>{{ item.formatedSize }}</td>
              <td class="actions">
                <a (click)="delete($event, item.path_lower)" class=""> <i class="fa fa-trash-alt"></i> </a>
                <a class="" (click)="download($event, item.path_lower, item.name)"> <i class="fa fa-download"></i> </a>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
