<div class="container manage-users" style="width:90%">
  <div class="heading d-flex">
    <div class="page-title">
      <div>
        <span calss="page-title"><h1>Manage Technologies - {{foundationName}}</h1></span>
      </div>
    </div>
    <div *ngIf="formSubmitError" class="row alertContainer" role="alert">
      <div class="col-md-12">
        <div class="alert alert-danger" id="formSubmitError">
          {{ formSubmitError }}
        </div>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="search_icon_profile">
      <i class="fas fa-search"></i>
    </div>
    <div class="searchBoxForm_profile">
      <input type="text" id="searchTerm" name="searchTerm" placeholder="Search Technologies..." autocomplete="off"
             [(ngModel)]="searchTerm" (keyup)="search()" class="searchBox_Profile" />
    </div>
    <button type="button" [ngClass]="{ 'disable':  false }" *ngIf="ischeckPermissions(permissions.UpdateTechnologies)" id="btnAddtechnologies" class="btn btn-success" (click)="addTechnologies()">Add New Item</button>
  </div>
  <div class="row">
    <div class="col-12 table-responsive">
      <table class="table table-striped sortable" id="printableArea">
        <thead>
          <tr>
            <!-- <th *ngIf="ischeckPermissions(permissions.manageTechnologies) && isAdmin"> </th> -->
            <th> </th>
            <th title="Edit Data"></th>

            <th title="Name of the product or solution supported by the Technology team."><div style="display:inline-flex">Technology<a (click)="toggleTag('solutionName')" [hidden]="solutionAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!solutionAsc" (click)="toggleTag('solutionName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
            <th title="Support area or functional area within the Technology team."><div style="display:inline-flex"> IT Function <a (click)="toggleTag('functionName')" [hidden]="technologiesAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!technologiesAsc" (click)="toggleTag('functionName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
            <th title="Vendor who supplied the solution, i.e. the software company."><div style="display:inline-flex"> Vendor <a (click)="toggleTag('vendorName')" [hidden]="vendorAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!vendorAsc" (click)="toggleTag('vendorName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
            <!-- <th title="Partner who designed or implemented the solution, e.g. consulting company."><div style="display:inline-flex"> Partner <a (click)="toggleTag('partnerName')" [hidden]="partnerAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!partnerAsc" (click)="toggleTag('partnerName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
            <!-- <th title="Contact info for the implementation partner."><div style="display:inline-flex"> Partner Contact <a (click)="toggleTag('partnerContactName')" [hidden]="partnerContactNameAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!partnerContactNameAsc" (click)="toggleTag('partnerContactName')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
            <th title="Satisfaction level with partner and the overall solution."><div style="display:inline-flex"> Rating <a (click)="toggleTag('partnerExperience')" [hidden]="partnerExperienceAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!partnerExperienceAsc" (click)="toggleTag('partnerExperience')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th>
            <!-- <th title="Description of Partner Experience"><div style="display:inline-flex"> Description of Partner Experience </div></th> -->
            <th><div style="display:inline-flex">In Use </div></th>

            <!-- <th><div style="display:inline-flex"> Product <a (click)="toggleTag('product')" [hidden]="productAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!productAsc" (click)="toggleTag('product')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
            <!-- <th><div style="display:inline-flex"> Contact Email <a (click)="toggleTag('partnerContactEmail')" [hidden]="partnerContactEmailAsc"><i class="fa fa-fw fa-angle-up fa-lg"></i></a><a [hidden]="!partnerContactEmailAsc" (click)="toggleTag('partnerContactEmail')"><i class="fa fa-fw fa-angle-down fa-lg"></i></a></div></th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let technologies of manageTechnologies; let rowIndex=index;">
            <td title="edit technologies">
              <div class="role-icon" [ngClass]="{ 'disabled': viewAsAdmin }">
                <span class="role-icon" (click)="removeTechnologies(technologies, rowIndex)">
                  <em class="far fa-trash-alt"></em>
                </span>
              </div>
            </td>
            <td title="edit technologies">
              <div class="delete-icon" [ngClass]="{ 'disabled': viewAsAdmin }">
                <span class="role-icon" (click)="editTechnologies(technologies, rowIndex)">
                  <em class="far fa-edit"></em>
                </span>
              </div>
            </td>

            <td>{{technologies.solutionName}}</td>
            <td>{{technologies.functionName}}</td>
            <td>{{technologies.vendorName}}</td>
            <!-- <td>{{technologies.partnerName}}</td> -->
            <td>{{technologies.partnerExperience}}</td>
            <td>{{technologies.currentlyUsed==true?"Yes":"No"}}</td>
            <!-- <td>{{technologies.comments}}</td> -->
            <!-- <td>{{technologies.updatedDateTime | timezone:'MM/DD/YYYY hh:mm A' }} </td> -->
            <!-- <td>{{technologies.product}}</td> -->
            <!-- <td>{{technologies.partnerContactEmail}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row" *ngIf="manageTechnologies?.length>0">
    <!-- Disabling Data collections button  -->
    <!-- <div class="col-md-2 padding-2p">
        <button type="button"   [ngClass]="{ 'disable':  viewAsAdmin }" *ngIf="isAdmin"  id="btnDataCollection" class="btn btn-success datacollection" (click)="openDataCollection()">Data Collection</button>
    </div> -->

    <div class="col-md-5" *ngIf="totalRows>1">
      <pagination [totalItems]="totalRows" (pageChanged)="pageChanged($event)" [boundaryLinks]="true" [maxSize]="10"></pagination>
    </div>

    <div class="col-md-3 pull-right padding-2p">

      <div class="clearfix">
        <div class="pull-right">
          <div *ngIf="formSubmitSuccess">
            <alert type="success" class="toast" [dismissible]=true [dismissOnTimeout]=5000>{{formSubmitSuccess}}</alert>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="row" *ngIf="manageTechnologies?.length===0">
    <div class="col-md-4"></div>
    <div class="col-md-4">  <p>No technologies Found.</p></div>
    <div class="col-md-4"></div>

  </div>

  <div class="add-form" *ngIf="isAddOpen">
    <a class="close" (click)="cancelAdd()"><i class="fa fa-times"></i></a>
    <div class="form-group">
      <form [formGroup]="technologiesForm">
        <div class="technologiestooltip">
          <label for="txt-role" class="required-field">Technology</label>
          <span class="technologiestooltiptext">Name of the product or solution supported by the Technology team.</span>
        </div>
        <div>
            <select formControlName="solution" class="form-control" (change)="onOptionsSolutionSelected($event)"
            [ngClass]="{'input-error': technologiesForm.controls.solution.touched && technologiesForm.controls.solution.status=='INVALID'}"
            required>
              <option value=''> Select Technology</option>
              <option *ngFor="let Solution of solutionList; let rowIndex=index;" [ngValue]="Solution.id" [selected]="Solution.itSolution == this">
                {{Solution.itSolution}}
              </option>
              <option class="btn btn-success mr-1rem" value='solutionLastIndex'> Add New Technology</option>
            </select>
          </div>
          <div class="technologiestooltip">
            <label for="txt-role" class="required-field">IT Function</label> 
            <span class="technologiestooltiptext">Support area or functional area within the Technology team.</span>
          </div>
            <!-- <button type="button" class="btn btn-success mr-1rem" (click)="AddFunction()">New Function</button> -->
        <div>
          <select formControlName="function" class="form-control" (change)="onOptionsFunctionSelected($event)"
            [ngClass]="{'input-error': technologiesForm.controls.function.touched && technologiesForm.controls.function.status=='INVALID'}"
            required>
            <option value=''> Select Function</option>
            <option *ngFor="let function of functionList; let rowIndex=index;" [ngValue]="function.id" [selected]="function.itFunction == this">
              {{function.itFunction}}
            </option>
            <option *ngIf="isFunctionLastIndex" class="btn btn-success mr-1rem" value='functionLastIndex'> Add New IT Function</option>
          </select>
        </div>
        <div class="technologiestooltip">
          <label for="txt-role" class="required-field">Vendor</label> 
          <span class="technologiestooltiptext">Vendor who supplied the solution, i.e. the software company.</span>
        </div>
        <div>
          <select formControlName="vendor" class="form-control" (change)="onOptionsVendorSelected($event)"
          [ngClass]="{'input-error': technologiesForm.controls.vendor.touched && technologiesForm.controls.vendor.status=='INVALID'}"
          required>
            <option value=''> Select Vendor</option>
            <option *ngFor="let vendor of vendorList; let rowIndex=index;" [ngValue]="vendor.id" [selected]="vendor.vendor == this">
              {{vendor.vendor}}
            </option>
            <option class="btn btn-success mr-1rem" value='vendorLastIndex'> Add New Vendor</option>
          </select>
        </div>
        <div class="technologiestooltip">
          <label for="txt-role">Partner</label> 
          <span class="technologiestooltiptext">Partner who designed or implemented the solution, e.g. consulting company.</span>
        </div>
        <div>
          <select formControlName="partner" class="form-control" (change)="onOptionsPartnerSelected($event)"
          [ngClass]="{'input-error': technologiesForm.controls.partner.touched && technologiesForm.controls.partner.status=='INVALID'}">
            <option value=''> Select Partner</option>
            <option *ngFor="let partner of partnerList; let rowIndex=index;" [ngValue]="partner.id" [selected]="partner.vendor == this">
              {{partner.vendor}}
            </option>
            <option class="btn btn-success mr-1rem" value='partnerLastIndex'> Add New Partner</option>
          </select>
        </div>
        <div class="technologiestooltip">
          <label for="txt-role">Partner Contact</label> 
          <span class="technologiestooltiptext">Contact info for the implementation partner.</span>
        </div>
        <div>
          <input id="txtPartnerContactName" name="partnerContactName" placeholder="Partner Contact" autocomplete="new-partnerContactName"
                 formControlName="partnerContactName" class="form-control" />
        </div>
        <div class="technologiestooltip">
          <label for="txt-role" class="required-field">Rating</label> 
          <span class="technologiestooltiptext">Satisfaction level with partner and the overall solution.</span>
        </div>
        <div>
          <select formControlName="partnerExperience" class="form-control"
          [ngClass]="{'input-error': technologiesForm.controls.partnerExperience.touched && technologiesForm.controls.partnerExperience.status=='INVALID'}"
          required>
            <option value=''> Select Experience</option>
            <option value='Fair'>Fair </option>
            <option value='Good'> Good</option>
            <option value='Excellent'> Excellent</option>
          </select>
        </div>
        <div class="technologiestooltip">
          <label for="txt-role">Currently in-use</label> 
          <span class="technologiestooltiptext">Currently in-use</span>
        </div>
        <div>
            <div> <input id="chkCurrentlyUsed" type="checkbox" value="currentlyUsed" formControlName="currentlyUsed" > Is In-Use</div>
        </div>
        <div class="technologiestooltip">
          <label for="txt-role">Description of Partner Experience</label> 
          <span class="technologiestooltiptext">Description of Partner Experience</span>
        </div>
        <div>
          <input id="txtcomments" name="txtcomments" placeholder="Description of Partner Experience" autocomplete="new-txtcomments"
                 formControlName="comments" class="form-control" />
        </div>
                <!-- <label for="txtProduct" class="required-field">Product</label>
        <div>
          <input id="txtProduct" name="txtProduct" placeholder="Product" autocomplete="new-txtProduct"
                 formControlName="product" class="form-control" 
                 [ngClass]="{'input-error': technologiesForm.controls.product.touched && technologiesForm.controls.product.status=='INVALID'}"
                 required
                 />
        </div> -->

                <!-- <label for="txtPartnerContactEmail">Contact Email</label>
        <div>
          <input id="txtPartnerContactEmail" name="partnerContactEmail" placeholder="Contact Email" autocomplete="new-txtPartnerContactEmail"
                 formControlName="partnerContactEmail" class="form-control" />
        </div> -->
      </form>
    </div>
    <div class="col-md-4 px-5">
      <button type="button" (click)="cancelAdd()" class="btn btn-block btn-dark">Cancel</button>
    </div>
    <div class="col-md-4 px-5">
      <button type="button" class="btn btn-success mr-1rem" (click)="AddUpdateTechnologies()" [disabled]=" technologiesForm.invalid"> {{buttonText}}</button>
    </div>
    <div *ngIf="istechnologiesExists" class="technologies-exist-error-container">
      <span class="alert alert-danger">technologies name already exists.</span>
    </div>
  </div>
  <div class="backdrop" *ngIf="isAddOpen"></div>

  <app-modal #confirmationModal contentStyle="role-delete-modal" [showFooter]=false class="modal-sg modal-centered">
    <ng-template #modalContentTemplate let-dataItem="dataItem.entityData">
      <div class="col-sm-12 content-style">
        <div *ngIf="dataItem">
          <span>Are you sure you want to delete?</span>
        </div>
      </div>
      <div class="col-sm-12 footer-style">
        <div>
          <button type="button" class="btn button-style cancelbtn" (click)="closeConfirmModal()">
            <span>Cancel</span>
          </button>
        </div>
        <div class="savebtn">
          <button type="submit" class="btn button-style" (click)="confirmTechnologiesToRemove(dataItem.deleteTechnologies, dataItem.rowIndex)">
            <span>Ok</span>
        </button>
        </div>
      </div>
    </ng-template>
  </app-modal>

  <div class="add-form" *ngIf="isAddDataCollectionOpen">
    <a class="close" (click)="cancelAddDC()"><i class="fa fa-times"></i></a>
    <div class="form-group">
      <form [formGroup]="dataCollectionForm">
        <div>Data Collection </div>
        <div>
          <textarea id="txtdataCollection" type="text" name="txtdataCollection" placeholder="Data Collection" autocomplete="new-txtfname"
                    [ngClass]="{'input-error': dataCollectionForm.controls.dataCollection.touched && dataCollectionForm.controls.dataCollection.status=='INVALID'}"
                    formControlName="dataCollection" class="form-control" required></textarea>
        </div>
      </form>
    </div>
    <div class="col-md-4 px-5">
      <button type="button" class="btn btn-success button-style mr-1rem" (click)="AddUpdateDataCollection()" [disabled]="dataCollectionForm.controls.dataCollection.status=='INVALID'">Save</button>
    </div>
  </div>

</div>

<div class="backdrop" *ngIf="isAddDataCollectionOpen"></div>
<!-- Function Modal -->

<app-modal #functionModal contentStyle="search-user-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate>
    <form >
        <div class="search-user-content">
          <div class="row">
            <div class="col-sm-9">  
              <div class="display-text">     
                <div class="searchBoxForm">
                  <input [(ngModel)]="functionText"
                    class="form-control"
                    autocomplete="off"
                    id="functionText"
                    name="functionText"
                    placeholder="Function"
                    type="text"
                  />
                </div>  
    
              </div>
            </div>         
          </div>
        </div>        
        <div class="col-sm-12" style="margin-bottom: 5%;">
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-dark" style="width: 100px;" (click)="closeFunctionModal()">
                    <span>Cancel</span>
                </button>
            </div>           
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-success" style="width: 100px;" (click)="saveFunction()">
                    <span>Add</span>
                </button>
            </div>
        </div>
    </form>
 </ng-template>  
</app-modal>

<!-- Technology Modal  -->

<app-modal #technologyModal contentStyle="search-user-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate>
    <form >
        <div class="search-user-content">
          <div class="row">
            <div class="col-sm-9">  
              <div class="display-text">     
                <div class="searchBoxForm">
                  <input [(ngModel)]="solutionText"
                    class="form-control"
                    autocomplete="off"
                    id="solutionText"
                    name="solutionText"
                    placeholder="Technology"
                    type="text"
                  />
                </div>  
    
              </div>
            </div>         
          </div>
        </div>        
        <div class="col-sm-12" style="margin-bottom: 5%;">
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-dark" style="width: 100px;" (click)="closeTechnologyModal()">
                    <span>Cancel</span>
                </button>
            </div>           
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-success" style="width: 100px;" (click)="savesolution()">
                    <span>Add</span>
                </button>
            </div>
        </div>
    </form>
 </ng-template>  
</app-modal>

<!-- Vendor Modal -->

<app-modal #vendorModal contentStyle="search-user-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate>
    <form >
        <div class="search-user-content">
          <div class="row">
            <div class="col-sm-9">  
              <div class="display-text">     
                <div class="searchBoxForm">
                  <input [(ngModel)]="vendorText"
                    class="form-control"
                    autocomplete="off"
                    id="vendorText"
                    name="vendorText"
                    placeholder="Vendor"
                    type="text"
                  />
                </div>  
    
              </div>
            </div>         
          </div>
        </div>        
        <div class="col-sm-12" style="margin-bottom: 5%;">
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-dark" style="width: 100px;" (click)="closeVendorModal()">
                    <span>Cancel</span>
                </button>
            </div>           
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-success" style="width: 100px;" (click)="saveVendor()">
                    <span>Add</span>
                </button>
            </div>
        </div>
    </form>
 </ng-template>  
</app-modal>

<!-- Partner Modal  -->

<app-modal #partnerModal contentStyle="search-user-modal" [showFooter]=false class="modal-sg modal-centered">
  <ng-template #modalContentTemplate>
    <form >
        <div class="search-user-content">
          <div class="row">
            <div class="col-sm-9">  
              <div class="display-text">     
                <div class="searchBoxForm">
                  <input [(ngModel)]="partnerText"
                    class="form-control"
                    autocomplete="off"
                    id="partnerText"
                    name="partnerText"
                    placeholder="Partner"
                    type="text"
                  />
                </div>  
    
              </div>
            </div>         
          </div>
        </div>        
        <div class="col-sm-12" style="margin-bottom: 5%;">
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-dark" style="width: 100px;" (click)="closePartnerModal()">
                    <span>Cancel</span>
                </button>
            </div>           
            <div class="col-md-4">
                <button type="button" class="btn btn-block btn-success" style="width: 100px;" (click)="savePartner()">
                    <span>Add</span>
                </button>
            </div>
        </div>
    </form>
 </ng-template>  
</app-modal>
