import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { AccountService } from '../shared/account.service';
import { UsersList, UserDetail } from '../shared/user.model';
import { Roles } from 'src/app/core/roles/role-settings.model';
import { StateService } from 'src/app/core/state-manager/appstateservice';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { compareItems } from 'src/app/common/models/utility';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google/google-analytics.service';
@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationModal', {static: false}) confirmationModalCompoent: ModalComponent;
  userList: Array<UserDetail>;
  userRoles: any;
  currentUserRole: Roles;
  formSubmitSuccess: string;
  formSubmitError: string;
  manageUsers: Array<UserDetail>;
  originalManageUsers: Array<any>;
  currentManageUsers: Array<any>;
  differentManageUsers: Array<any>;
  removeManageUsers: Array<any>;
  assignedModerator: Array<any>;
  pendingUsers: Array<UserDetail>;
  selectedUser: UserDetail = null;
  selectedUserRole: any = null;
  currentUser: any;
  addRowInvalid = false;
  selectedModerator: any;
  selectedUserName: string;
  itemsTosearch = 15;
  viewAsAdmin = false;
  isAllGroup = false;
  isAddOpen = false;
  isKMSGroup =  false;
  isEmailNotification = false;
  roleColumnSize = 4;
  nameColumnSize = 4;
  isFTLAGroup=false;
  isPortalConfig : boolean=false;
  constructor(private accountService: AccountService,
              private stateService: StateService,
              public googleAnalyticsService: GoogleAnalyticsService) {
    this.userRoles = Roles;
  }

  get isAdmin() { return this.currentUserRole === Roles.Admin; }

  get isModerator() { return this.currentUserRole === Roles.Moderator; }

  get isSuperAdmin() { return this.currentUserRole === Roles.SuperAdmin; }

  ngOnInit() {
    this.viewAsAdmin = this.stateService.isViewAsAdmin();
    if (this.viewAsAdmin) {
      this.currentUserRole = this.stateService.getViewUserRole();
      this.currentUser = this.stateService.getViewingUserData();
    } else {
      this.currentUserRole = this.stateService.getRole();
      this.currentUser = this.stateService.getUserData();
    }
    if (this.isModerator) {
      this.selectedUserRole = this.userRoles.Moderator;
    }
    if (this.stateService.isAllGroup()) {
      this.isAllGroup = true;
    }
    if (this.stateService.isKmsGroup()) {
      this.isKMSGroup = true;
    }
    this.isFTLAGroup = this.stateService.isFTLAGroup();
    this.isPortalConfig =this.stateService.isPortalConfigGroup();
    if ((this.isAdmin === true || this.isSuperAdmin === true) && this.isKMSGroup === true) {
      this.roleColumnSize = 3;
      this.nameColumnSize = 3;
    } else if (this.isModerator === true && this.isKMSGroup === false) {
      this.roleColumnSize = 8;
      this.nameColumnSize = 4;
    }
    this.getUsers();
    this.removeManageUsers = [];
    this.assignedModerator = [];
  }

  getUsers() {
    this.accountService.getAllUsers()
    .subscribe(data => {
        this.userList = new UsersList().uiMapper(data).kmsUsers;
        this.userList.sort((nodeA, nodeB) => compareItems(nodeA['fullName'], nodeB['fullName']));
        this.manageUsers = this.userList.filter(x => x.isAdmin || x.isModerator || x.isSuperAdmin);
        this.originalManageUsers = new UsersList().dataMapper(this.manageUsers);
        this.pendingUsers = this.userList.filter(x => !x.isAdmin && !x.isModerator && !x.isSuperAdmin);
        this.itemsTosearch = this.pendingUsers.length;
    });
  }

  showAdminRow(user: UserDetail) {
    if (this.isModerator && (user.isAdmin || user.isSuperAdmin)) {
      return false;
    }/* else if (this.isAdmin && user.isSuperAdmin) {
      return false;
    }*/
    return ((this.isAdmin || this.isSuperAdmin || this.isModerator) && (user.isAdmin || user.isSuperAdmin || user.isModerator));
  }

  onUserSelect(event: TypeaheadMatch): void {
    this.selectedUser = event.item;
  }

  AddUserRole() {
    if (this.selectedUser && this.selectedUserRole) {
      this.googleAnalyticsService
              .eventEmitter('Grant Role', this.selectedUserRole + ' to ' + this.selectedUser.givenName, 'Role Management');
      this.selectedUser.updateRole(this.selectedUserRole);
      this.selectedUser.isEmailNotification = this.isEmailNotification;
      this.selectedUser.isNew = true;
      this.manageUsers.push(this.selectedUser);
      this.resetAddForm();
    } else {
      this.addRowInvalid = true;
    }
  }

  resetAddForm() {
    this.selectedUser = null;
    this.selectedUserName = null;
    this.selectedUserRole = this.isModerator ? this.userRoles.Moderator : null;
    this.pendingUsers = this.userList.filter(x => !x.isAdmin && !x.isModerator && !x.isSuperAdmin);
    this.itemsTosearch = this.pendingUsers.length;
    this.isEmailNotification = false;
    this.isAddOpen = false;
  }

  updateRole() {
    this.differentManageUsers = [];
    if(this.removeManageUsers && this.removeManageUsers.length > 0) {
      this.removeManageUsers.forEach((user) => {
        let userDetail = UserDetail.addRole(user, Roles.User);
        if (this.selectedModerator) {
          this.accountService.assignTasksToModerator(user.id, this.selectedModerator.id);
        }
        this.googleAnalyticsService
                .eventEmitter('Revoke Role', Roles.User + ' from ' + user.givenName, 'Role Management');
      });
      this.assignedModerator.forEach((user) => {
        this.accountService.assignTasksToModerator(user.userId, user.moderatorId);
      });
    }
    if (this.manageUsers && this.manageUsers.length > 0) {
      this.currentManageUsers = new UsersList().dataMapper(this.manageUsers);
      for (const index in this.currentManageUsers) {
        if (JSON.stringify(this.originalManageUsers[index]) !== JSON.stringify(this.currentManageUsers[index])
             || (this.currentManageUsers[index] === null || this.currentManageUsers[index] === undefined)) {
            if(this.currentManageUsers[index].isNew != true) {
              this.currentManageUsers[index].isModified = true;
            }
            this.differentManageUsers.push(this.currentManageUsers[index]);
        }
      }
      this.accountService.UpdateUserRoles(this.differentManageUsers)
      .subscribe((data) => {
        this.formSubmitSuccess = "Successfully Updated Roles";
        this.getUsers();
      });
    }
  }

  removePendingRole(user: UserDetail, rowIndex: number) {
    if (user) {
      if (user.isPending) {
        user.updateRole(this.userRoles.User);
        this.manageUsers.splice(rowIndex, 1);
        this.pendingUsers = this.userList.filter(x => !x.isSuperAdmin && !x.isAdmin && !x.isModerator);
        this.itemsTosearch = this.pendingUsers.length;
      } else {
        this.openConfirmationModal(user, rowIndex);
      }
    }
  }

  removeRole(user: UserDetail, rowIndex) {
    if (user) {
      user.updateRole(this.userRoles.User);
      this.manageUsers.splice(rowIndex, 1);
      this.originalManageUsers.splice(rowIndex, 1);
      user.isDeleted = true;
      this.removeManageUsers.push(user);
      if (this.selectedModerator) {
        let moderator = {
          'userId': user.id,
          'moderatorId': this.selectedModerator.id
        }
        this.assignedModerator.push(moderator);
      }
      this.closeConfirmModal();
    }
  }

  openConfirmationModal(user: UserDetail, rowIndex) {
    if (this.confirmationModalCompoent) {
      this.confirmationModalCompoent.entityData = { entityData: null };
      this.confirmationModalCompoent.showModal();
      if (user.isModerator) {
        this.accountService.getPendingTask(user.id)
        .subscribe((pendingTaskResult) => {
          if (pendingTaskResult === true) {
            if (this.isModerator) {
              const moderators = this.userList.filter(x => x.isModerator && !x.isPending);
              this.updateConfirmationModalEntity(user, moderators, rowIndex);
            } else {
              this.accountService.getModerators(user.id)
              .subscribe((data) => {
                const users = new UsersList().uiMapper(data).kmsUsers;
                this.updateConfirmationModalEntity(user, users, rowIndex);
              });
            }
          } else {
            this.defaultConfirmationModalEntity(user);
          }
        });
      } else {
        this.defaultConfirmationModalEntity(user);
      }
    }
  }

  defaultConfirmationModalEntity(user: UserDetail) {
    this.confirmationModalCompoent.entityData = { entityData: {
        deleteUser: user,
        tasksExists: false
      }
    };
  }

  updateConfirmationModalEntity(userToDelete: UserDetail, moderators: UserDetail[], rowIndex: number) {
    const users = moderators.filter(x => x.id !== userToDelete.id);
    this.confirmationModalCompoent.entityData = {
      entityData: {
        deleteUser: userToDelete,
        manageUsers: users,
        manager: users.length === 1 ? users[0] : null,
        tasksExists: true,
        managersExists: users.length > 0,
        rowIndex: rowIndex
      }
    };
    this.selectedModerator = users.length > 0 ? users[0] : null;
  }

  closeConfirmModal() {
    if (this.confirmationModalCompoent) {
      this.selectedModerator = null;
      this.confirmationModalCompoent.closeModalWindow();
    }
  }

  addUser() {
    this.isAddOpen = true;
  }

  cancelAdd() {
    this.resetAddForm();
  }

  ngOnDestroy() {
    this.closeConfirmModal();
  }
}

